import React from 'react';
import { useTranslation } from 'react-i18next';
import IconPopover from 'components/Popover/IconPopover';

import BillsDetailsTextField from './BillsDetailsTextField';
import { ReactComponent as InfoIcon } from 'assets/icons/InfoIcon.svg';
import { formatDate } from 'utils/dateUtils';
import InvoiceTypeLabel from 'components/InvoiceTypeLabel/InvoiceTypeLabel';

import styles from './BillsDetails.module.scss';

const BillsDetails = ({ invoice }) => {
  const { t } = useTranslation(['translation', 'bills']);

  return (
    <div className={styles.container}>
      <BillsDetailsTextField
        content={t('bills:type')}
        euro={false}
        amount={
          <InvoiceTypeLabel
            invoiceType={invoice.invoiceType}
            css={styles.invoiceTypeText}
          />
        }
      />
      <BillsDetailsTextField
        content={t('bills:full-sum')}
        amount={invoice.grandTotal}
        amountWeight="bold"
      />
      <div className={styles['innerContainer-tab']}>
        <>
          <BillsDetailsTextField
            content={t('bills:current-bill')}
            amount={invoice.totalCurrentAmount}
            amountWeight="bold"
          />
          {invoice.ena && (
            <BillsDetailsTextField
              content={t('bills:ena-participation')}
              amount={invoice.ena}
              amountWeight="bold"
            />
          )}
          <BillsDetailsTextField
            content={t('bills:previous-balance')}
            amount={invoice.previousAmount}
            containerClass={styles.previousAmountContainer}
            Icon={
              <IconPopover
                id={`meter-${invoice?.id}-popover`}
                content={t('bills:bill-bubble')}
                icon={<InfoIcon />}
                popoverClass={styles.popover}
                buttonClass={styles.infoButton}
                placement="bottom-end"
              />
            }
            amountWeight="bold"
          />
        </>
      </div>
      <BillsDetailsTextField
        content={t('bills:invoice-date')}
        amount={formatDate(invoice.issueDate)}
        euro={false}
        amountWeight="bold"
      />
      <BillsDetailsTextField
        content={t('bills:consumption-period')}
        amount={`${formatDate(invoice.dateFrom)} - ${formatDate(
          invoice.dateTo
        )}`}
        euro={false}
        amountWeight="bold"
      />
      <BillsDetailsTextField
        content={t('translation:daysCapital')}
        amount={invoice.days}
        euro={false}
        amountWeight="bold"
      />
      <BillsDetailsTextField
        content={t('bills:pay-until')}
        amount={formatDate(invoice.dueDate)}
        euro={false}
        amountWeight="bold"
      />
    </div>
  );
};

export default BillsDetails;
