import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import trimEnd from 'lodash/trimEnd';
import useEnum from 'data/useEnum';
import { getPostalOffices } from 'utils/product/inputUtils';

import { ControlledSelect } from 'components/Selects/ControlledSelect';
import StandardSelectOption from 'components/Selects/StandardSelectOption';
import { StandardInput } from './Inputs';

const PostalCodeInput = ({
  register,
  errors,
  postalCodeName,
  postalCodeValue,
  postalCodeIdName,
  postalCodeIdValue,
  onChangeHandler,
  required = true,
}) => {
  const { t } = useTranslation(['newClient', 'inputs']);
  const { PostalOffices = [], loading } = useEnum('PostalOffices');
  const [postalCode, setPostalCode] = useState(postalCodeValue);
  const postalOffices = getPostalOffices(postalCode, PostalOffices);

  const handleChange = (e) => {
    const _id = e.target.value;
    const selectedPostalCode = postalOffices.find((x) => x.Id === _id);
    if (!selectedPostalCode) {
      onChangeHandler({ postalCodeId: null, postalCode: '', area: '' });
      return;
    }
    onChangeHandler({
      postalCodeId: _id,
      postalCode: selectedPostalCode.Code,
      area: `${selectedPostalCode.City} ${selectedPostalCode.Prefecture}`,
    });
  };

  const { ref, onChange: onPostalCodeChange } = register(postalCodeName, {
    required: { value: required, message: t(`inputs:errors.required`) },
    setValueAs: (value) => parseInt(value),
    validate: (value) => {
      if (loading) return true;
      const postalCodeStr = `${value}`;
      if (postalCodeStr.length < 5) {
        return (
          PostalOffices.find((x) => x.Code.indexOf(postalCodeStr) > -1) !==
          undefined
        );
      }
      return PostalOffices.find((x) => x.Code === postalCodeStr) !== undefined;
    },
  });
  return (
    <>
      <StandardInput
        inputRef={ref}
        label={t(`newClient:sharedDetails.postalCode`)}
        name={postalCodeName}
        type="text"
        maxLength={5}
        minLength={1}
        errorMessage={get(errors, `${postalCodeName}.message`, null)}
        error={get(errors, `${postalCodeName}`, null) !== null}
        defaultValue={postalCodeValue}
        onChange={(e) => {
          onPostalCodeChange(e);
          setPostalCode(e.target.value);
        }}
      />

      <ControlledSelect
        label={t(`newClient:sharedDetails.prefecture`)}
        value={postalCodeIdValue ?? ''}
        name={postalCodeIdName}
        errors={errors}
        onChange={handleChange}
        defaultValue={postalCodeIdValue ?? ''}
        autoWidth={true}
        loading={loading}
      >
        <StandardSelectOption
          key="default"
          value=""
          style={{ display: 'none' }}
        >
          -
        </StandardSelectOption>
        {postalOffices.length === 0 && (
          <StandardSelectOption key="empty" value={'-'} disabled>
            {t('inputs:valid-postal-code')}
          </StandardSelectOption>
        )}
        {PostalOffices.length === 0 && postalCodeIdValue !== '' && (
          <StandardSelectOption
            key="temp"
            value={postalCodeIdValue}
            style={{ display: 'none' }}
          >
            -
          </StandardSelectOption>
        )}
        {postalOffices.map((item) => {
          const text = trimEnd(`${item.City} ${item.Prefecture}`, ' ');
          return (
            <StandardSelectOption key={item.Id} value={item.Id}>
              {text}
            </StandardSelectOption>
          );
        })}
      </ControlledSelect>
    </>
  );
};

export default PostalCodeInput;
