import Big from 'big.js';
import get from 'lodash/get';
import { isEmptyString, toUpperCaseSanitized } from 'utils/stringUtils';
import { isObjectEmpty } from 'utils/objectUtils';

import accountCategories from 'utils/product/enums/accountCategories';
import addressTypes from 'utils/product/enums/addressTypes';
import { dateFormats, defaultKVA } from 'utils/product/config';
import energyTypes from 'utils/product/enums/shopEnergyTypes';
import supplyNumberTypes from 'utils/product/enums/supplyNumberTypes';
import programTypes from 'utils/product/enums/programTypes';
import ownershipTypes from 'utils/product/enums/ownershipTypes';
import { formatDate } from 'utils/dateUtils';
import {
  getDeliveryPointCategoryId,
  getGasTariffgroupId,
} from './onboardingEnums';
import accountTypes from '../enums/accountTypes';

export const _emptyContactPerson = {
  LastName: undefined,
  FirstName: undefined,
  PassportNumber: undefined,
  PlaceOfIDIssue: undefined,
  MobilePhone: undefined,
  Email: undefined,
  OtherPhone: undefined,
};

export const getSuccessor = (meterNo) => {
  if (meterNo?.length === 11) {
    const _successor = `00${new Big(meterNo.substring(9, 11)).plus(1)}`;
    return _successor.substring(_successor.length - 2);
  }
  return '99';
};

export const isClientCompany = (data) =>
  data.customerCategory === `${accountCategories.company}`;

export const getLegalRepresentative = (data) => {
  if (!isClientCompany(data)) return undefined;
  const result = { ..._emptyContactPerson, ...data.legalRepresentative };
  return isObjectEmpty(result) ? undefined : result;
};

export const getEmergencyContact = (data, isShop) => {
  if (isClientCompany(data)) return undefined;
  if (isShop) return undefined;
  const result = { ..._emptyContactPerson, ...data.emergencyContact };
  return isObjectEmpty(result) ? undefined : result;
};

export const getPrimaryContact = (data, isShop) => {
  if (isShop) return undefined;
  return {
    ..._emptyContactPerson,
    ...data.primaryContact,
    FirstName: toUpperCaseSanitized(data.primaryContact?.FirstName),
    LastName: toUpperCaseSanitized(data.primaryContact?.LastName),
  };
};

export const getContactPerson = (data, isShop) => {
  if (isShop) return undefined;
  if (isClientCompany(data)) return getPrimaryContact(data, isShop);
  return {
    LastName: toUpperCaseSanitized(data.lastName),
    FirstName: toUpperCaseSanitized(data.firstName),
    PassportNumber: data.identityNo,
    //PlaceOfIDIssue: undefined,
    MobilePhone: data.mobile,
    Email: data.email,
    OtherPhone: data.phone ?? data.mobile,
    BirthDate: data.birthDate,
  };
};

export const getAccountName = (data) => {
  const accountName = isClientCompany(data)
    ? data.companyName
    : `${data.firstName} ${data.lastName}`;
  return toUpperCaseSanitized(accountName);
};

export const getElBillingAddress = (data) => ({
  Street: toUpperCaseSanitized(data.energyProgram.billToAddress),
  StreetNo: toUpperCaseSanitized(data.energyProgram.billToAddressNo),
  PostalCodeId: data.energyProgram.billToPostalCodeId,
  AddressType: addressTypes.BillToAddress,
});

export const getGasBillingAddress = (data) => ({
  Street: toUpperCaseSanitized(data.gasProgram.billToAddress),
  StreetNo: toUpperCaseSanitized(data.gasProgram.billToAddressNo),
  PostalCodeId: data.gasProgram.billToPostalCodeId,
});

export const getElMeterAddress = (data) => ({
  Street: toUpperCaseSanitized(data.energyProgram.meterAddress),
  StreetNo: toUpperCaseSanitized(data.energyProgram.meterAddressNo),
  PostalCodeId: data.energyProgram.meterPostalCodeId,
  AddressType: addressTypes.MeterAddress,
});

export const getGasMeterAddress = (data) => ({
  HkaspAddressStreet: toUpperCaseSanitized(data.gasProgram.meterAddress),
  HkaspAddressStreetNumber: toUpperCaseSanitized(
    data.gasProgram.meterAddressNo,
  ),
  HkaspAddressZipCode: data.gasProgram.meterPostalCodeId,
  HkaspCommunity: get(data, 'gasProgram.gasCommunity', undefined),
});

export const getKva = (data) => data.energyProgram.agreedPowerKVA ?? defaultKVA;

export const showContactDialog = (isShop, step, steps, nextRouteData) =>
  !isShop &&
  (step === steps.customerDetails || step === steps.primaryContact) &&
  nextRouteData.contactConsent === null;

export const getRequestsToSend = (newData, isShop = false) => {
  const _requests =
    newData.energyType === energyTypes.combined && newData.gas === 'true'
      ? ['elRequest', 'elFiles', 'gasRequest', 'gasFiles']
      : newData.energyType === energyTypes.gas
        ? ['gasRequest', 'gasFiles']
        : ['elRequest', 'elFiles'];

  if (isShop) return _requests;
  return [..._requests, 'register'];
};

export const getSupplyNumber = (meterNo) => {
  if (!meterNo) return null;
  if (meterNo.length === 14 || meterNo.length === 8) return meterNo;
  if (meterNo.length === 11 || meterNo.length === 9)
    return meterNo.substring(1, 9);
  return null;
};

export const getSupplyNumberType = (isGasProgram, defaultValues) =>
  isGasProgram
    ? supplyNumberTypes.gas
    : defaultValues.electricityRecentBill === 'true'
      ? supplyNumberTypes.electricityWithBill
      : supplyNumberTypes.electricityNoBill;

const getElAccountAddress = (data, isCompany) => {
  if (isCompany)
    return {
      Street: toUpperCaseSanitized(data.companyStreet),
      StreetNumber: toUpperCaseSanitized(data.companyStreetNo),
      PostalCode: data.companyPostalCodeId,
    };

  const meterAddress = getElMeterAddress(data);
  return {
    Street: meterAddress.Street,
    StreetNumber: meterAddress.StreetNo,
    PostalCode: meterAddress.PostalCodeId,
  };
};

const getGasAccountAddress = (data, isCompany) => {
  if (isCompany)
    return {
      Street: toUpperCaseSanitized(data.companyStreet),
      StreetNumber: toUpperCaseSanitized(data.companyStreetNo),
      PostalCode: data.companyPostalCodeId,
    };

  const meterAddress =
    data.energyType === energyTypes.combined
      ? getElMeterAddress(data)
      : getGasMeterAddress(data);
  return {
    Street: meterAddress.HkaspAddressStreet,
    StreetNumber: meterAddress.HkaspAddressStreetNumber,
    PostalCode: meterAddress.HkaspAddressZipCode,
  };
};

export const getElAccount = (data, user) => {
  if (user)
    return {
      AccountId: user.Id,
    };

  const isCompany = isClientCompany(data);
  const addressFields = getElAccountAddress(data, isCompany);
  return {
    CustomerCategory: new Big(data.customerCategory).toNumber(),
    FirstName: isCompany ? undefined : toUpperCaseSanitized(data.firstName),
    LastName: isCompany ? undefined : toUpperCaseSanitized(data.lastName),
    AccountName: getAccountName(data),
    VatRegNo: data.taxNo,
    IdNumber: isCompany ? undefined : data.identityNo,
    ...addressFields,
    TaxOffice: data.taxOffice?.TaxOfficeId,
    MobilePhone: data.mobile,
    Phone: data.phone ?? data.mobile,
    Email: data.email,
    PersonalData: data.personalDataAgreement,
    DoNotAllowSms: data.sendSms,
    DoNotAllowViber: data.sendViber,
    DoNotAllowEmail: data.sendEmail,
  };
};

export const getGasAccount = (data, user) => {
  if (user)
    return {
      AccountId: user.Id,
    };

  const isCompany = isClientCompany(data);
  const addressFields = getGasAccountAddress(data, isCompany);
  return {
    EBill: true,
    CustomerCategory: new Big(data.customerCategory).toNumber(),
    FirstName: isCompany ? undefined : toUpperCaseSanitized(data.firstName),
    LastName: isCompany ? undefined : toUpperCaseSanitized(data.lastName),
    AccountName: getAccountName(data),
    VatRegNo: data.taxNo,
    IdNumber: data.identityNo,
    ...addressFields,
    TaxOffice: data.taxOffice?.TaxOfficeId,
    MobilePhone: data.mobile,
    Phone: data.phone ?? data.mobile,
    Email: data.email,
    PersonalData: data.personalDataAgreement,
    DoNotAllowSms: data.sendSms,
    DoNotAllowViber: data.sendViber,
    DoNotAllowEmail: data.sendEmail,
    LegalForm: data.legalForm,
    LegalOrg: data.legalOrg,
  };
};

export const getUserCategory = (user) => {
  if (!user) return `${accountCategories.private}`;

  if (
    user.Category === accountCategories.private ||
    user.Category === accountCategories.freelancer
  )
    return `${user.Category}`;
  return `${accountCategories.company}`;
};

export const getGasOwner = (isShop, user, data) => {
  if (isShop) {
    if (data.gasProgram.userCapacity === `${ownershipTypes.tenant}`) {
      return {
        LastName: get(
          data,
          'gasOwner.lastName',
          user.LastName ? user.LastName : user.CompanyName,
        ),
        FirstName: get(
          data,
          'gasOwner.firstName',
          user.FirstName ? user.FirstName : user.CompanyName,
        ),
        VATOffice: get(
          data,
          'gasOwner.taxOffice.TaxOfficeId',
          'taxOffice.TaxOfficeId',
          undefined,
        ),
        VATRegistrationNo: get(
          data,
          'gasOwner.taxNo',
          get(user, 'TaxNo', undefined),
        ),
      };
    }
    return {
      LastName: user.LastName ? user.LastName : user.CompanyName,
      FirstName: user.FirstName ? user.FirstName : user.CompanyName,
      VATRegistrationNo: get(user, 'TaxNo', undefined),
    };
  }
  if (data.gasProgram.userCapacity === `${ownershipTypes.tenant}`) {
    return {
      LastName: get(
        data,
        'gasOwner.lastName',
        data.lastName ? data.lastName : data.companyName,
      ),
      FirstName: get(
        data,
        'gasOwner.firstName',
        data.firstName ? data.firstName : data.companyName,
      ),
      VATOffice: get(
        data,
        'gasOwner.taxOffice.TaxOfficeId',
        get(data, 'taxOffice.TaxOfficeId', undefined),
      ),
      VATRegistrationNo: get(
        data,
        'gasOwner.taxNo',
        get(data, 'taxNo', undefined),
      ),
    };
  }
  return {
    LastName: data.lastName ? data.lastName : data.companyName,
    FirstName: data.firstName ? data.firstName : data.companyName,
    VATOffice: get(data, 'taxOffice.TaxOfficeId', undefined),
    VATRegistrationNo: get(data, 'taxNo', undefined),
  };
};

export const getInitialData = (isShop, user, program) => {
  if (!isShop)
    return {
      energyType: null,
      shared: 'false',
      programType: programTypes.home,
      customerCategory: `${accountCategories.private}`,
      electricity: 'true',
      electricityRecentBill: 'true',
      gas: 'true',
      gasRecentBill: 'true',
      sendEmail: false,
      sendSms: false,
      sendViber: false,
      sendMarketingMaterials: false,
      personalDataAgreement: false,
      identityType: 'identityNo',
      contactConsent: null,
      taxOffice: { TaxOfficeId: '', TaxOfficeName: '' },
      legalForm: '',
      legalOrg: '',
      energyProgram: {
        eBill: 'true',
        nightMeter: 'false',
        userCapacity: `${ownershipTypes.owner}`,
      },
      gasProgram: {
        eBill: 'true',
        nightMeter: 'false',
        userCapacity: `${ownershipTypes.owner}`,
      },
    };

  const { energyType, programType } = program || {};
  const programDetails = program
    ? {
        packageId: program.packageId,
        name: program.title,
        urlName: program.urlName,
        programName: program.title,
        programNameIgnored: program.title,
        energyType: program.energyType,
      }
    : {};

  const generousGuarantee = program
    ? program.sections?.guaranteeProgram?.choices?.find((x) => x.isSelected)
        ?.value
    : false;

  return {
    energyType: energyType,
    shared: program?.isShared ? 'true' : 'false',
    programType: programType,
    customerCategory: getUserCategory(user),
    electricity: 'true',
    generousGuarantee: `${generousGuarantee === true}`,
    electricityRecentBill: 'true',
    gas: 'true',
    gasRecentBill: 'true',
    sendEmail: false,
    sendSms: false,
    sendViber: false,
    sendMarketingMaterials: false,
    personalDataAgreement: false,
    identityType: 'identityNo',
    contactConsent: null,
    taxOffice: { TaxOfficeId: '', TaxOfficeName: '' },
    energyProgram: {
      eBill: 'true',
      nightMeter: 'false',
      userCapacity: `${ownershipTypes.owner}`,
      ...(energyType === energyTypes.electricity ||
      energyType === energyTypes.eco ||
      energyType === energyTypes.combinedElectricity
        ? programDetails
        : {}),
    },
    gasProgram: {
      eBill: 'true',
      nightMeter: 'false',
      userCapacity: `${ownershipTypes.owner}`,
      ...(energyType === energyTypes.gas || energyType === energyTypes.combined
        ? programDetails
        : {}),
    },
  };
};

export const getContactDetailsForPromotion = (isShop, user, data) => {
  if (isShop && user) {
    return getContactDetailsForPromotionShop(data, user);
  }
  return getContactDetailsForPromotionEcontract(data);
};

export const getContactDetailsForPromotionShop = (data, user) => {
  const isElectricityOrCombined =
    energyTypes?.electricity === data?.energyType ||
    energyTypes?.combinedElectricity === data?.energyType ||
    energyTypes?.combined === data?.energyType;
  const isGasOrCombined =
    energyTypes?.gas === data?.energyType ||
    energyTypes?.combined === data?.energyType;

  const accountType =
    user.AccountType === accountTypes.B2B ? 2 : user.AccountType;
  return {
    firstName: isEmptyString(user.FirstName)
      ? user.CompanyName
      : user.FirstName,
    lastName: isEmptyString(user.LastName) ? user.CompanyName : user.LastName,
    phone: user.ContactPhone,
    email: user.ContactEmail,
    type: accountType,
    existing_customer: 1,
    electricity: isElectricityOrCombined ? 1 : 0,
    gas: isGasOrCombined ? 1 : 0,
  };
};

export const getContactDetailsForPromotionEcontract = (data) => {
  const isElectricityOrCombined =
    energyTypes?.electricity === data?.energyType ||
    energyTypes?.combinedElectricity === data?.energyType ||
    energyTypes?.combined === data?.energyType;
  const isGasOrCombined =
    energyTypes?.gas === data?.energyType ||
    energyTypes?.combined === data?.energyType;

  const customerCategory =
    data.customerCategory === `${accountCategories.private}` ||
    data.customerCategory === `${accountCategories.freelancer}`
      ? 1
      : 2;

  return {
    firstName: isEmptyString(data.firstName)
      ? data.companyName
      : data.firstName,
    lastName: isEmptyString(data.lastName) ? data.companyName : data.lastName,
    phone: data.mobile,
    email: data.email,
    type: customerCategory,
    existing_customer: 0,
    electricity: isElectricityOrCombined ? 1 : 0,
    gas: isGasOrCombined ? 1 : 0,
  };
};

export const shouldGetDefaultActivityId = (baseObjectName, defaultValues) =>
  defaultValues?.energyType !== `${energyTypes.gas}` &&
  defaultValues?.programType === `${programTypes.home}` &&
  isEmptyString(defaultValues[baseObjectName].activityCodeId);

export const getElPostData = (data, isShop = false, user) => {
  const meterNo = get(data, 'energyProgram.meterNo', '');
  const meterId = getSupplyNumber(meterNo);
  const successor = getSuccessor(meterNo);
  return {
    account: getElAccount(data, user),
    Addresses: [getElBillingAddress(data), getElMeterAddress(data)],
    accountMeter: {
      MeterID: meterId,
      Successor: successor,
      Region: data.energyProgram.region,
      AgreedPower: getKva(data),
      DayIndication: !isEmptyString(data.energyProgram.initialDayCounter)
        ? new Big(data.energyProgram.initialDayCounter).toNumber()
        : 0,
      NightIndication:
        data.energyProgram.nightMeter === 'false'
          ? undefined
          : !isEmptyString(data.energyProgram.initialNightCounter)
            ? new Big(data.energyProgram.initialNightCounter).toNumber()
            : 0,
      ActivityCodeId: data.energyProgram.activityCodeId,
      ReactivationRequired: data.electricity === 'false',
      PaymentMethod: data.energyProgram.standingOrder === true ? 0 : undefined,
      EBill: data.energyProgram.eBill === 'true',
    },
    contactPerson: getContactPerson(data, isShop),
    responsibleForAction: undefined,
    legalRepresentative: getLegalRepresentative(data),
    emergencyContact: getEmergencyContact(data, isShop),
    elContract: {
      Receipt: 1, // static
      MyHeronRegistrationNo: `${isShop ? user.TaxNo : data.taxNo}-${formatDate(
        new Date(),
        dateFormats.log,
      )}`,
      ContractSignatureDate: new Date(),
      PackageFriendlyName: data.energyProgram.packageId,
      DayIndication: true,
      NightIndication: data.energyProgram.nightMeter === 'true',
      GenerousGuarantee: data.generousGuarantee === 'true',
    },
  };
};

export const getGasPostData = (data, isShop = false, user, enums) => {
  const { deliveryPointCategories, gasTariffgroups } = enums;

  const meterId = getSupplyNumber(data.gasProgram?.meterNo);
  const deliveryPointCategoryId = getDeliveryPointCategoryId(
    data,
    deliveryPointCategories,
  );
  const gasTariffgroupId = getGasTariffgroupId(data, gasTariffgroups);

  return {
    account: getGasAccount(data, user),
    gassupply: {
      DeliveryPointCategory: deliveryPointCategoryId,
      OwnershipType: data.gasProgram.userCapacity,
      TariffGroup: gasTariffgroupId,
      PaymentMethod: data.gasProgram.standingOrder === true ? 0 : undefined,
      CentralHeating: data.shared === 'true',
      BillingAddress: getGasBillingAddress(data),
      EBill: data.gasProgram.eBill === 'true',
    },
    contactPerson: getContactPerson(data, isShop),
    responsibleForAction: undefined,
    legalRepresentative: getLegalRepresentative(data),
    emergencyContact: getEmergencyContact(data),
    gasOwner: getGasOwner(isShop, user, data),
    gasHkasp: {
      Code: meterId,
      ...getGasMeterAddress(data),
    },
    gasContract: {
      Receipt: 1, // static
      MyHeronRegistrationNo: `${isShop ? user.TaxNo : data.taxNo}-${formatDate(
        new Date(),
        dateFormats.log,
      )}`,
      ContractSignatureDate: new Date(),
      PackageFriendlyName: data.gasProgram.packageId,
      ExistingInstallation: data.gas === 'true',
      ReactivationNeeded: data.gas === 'false',
    },
  };
};
