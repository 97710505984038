import { replaceAll } from 'utils/stringUtils';
import useGeneralConfig from 'data/useGeneralConfig';

export default function useEnaConfig(language = 'el') {
  const { data, error, loading } = useGeneralConfig();

  const enaCalculator =
    data && data.enaCalculatorV2
      ? {
          ...data.enaCalculatorV2,
          disclaimer: replaceAll(
            data.enaCalculatorV2?.disclaimer?.[language],
            '\n',
            '<br/>',
          ),
        }
      : null;

  return {
    loading,
    error,
    data: enaCalculator,
  };
}
