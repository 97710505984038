import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useLoyaltyPrompt } from 'data/useLoyaltyPrompt';
import { ReactComponent as RightArrow } from 'assets/icons/ArrowRightIcon.svg';
import { ReactComponent as EnergyIcon } from 'assets/icons/LoyaltyEnergyIcon.svg';
import { useAuthUser } from 'utils/AuthUser';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import IconText from 'components/IconText/IconText';
import Loader from 'components/Skeletons/Loader';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import useUniqueFlow from 'hooks/useUniqueFlow';
import LoyaltyRegistrationButton from 'components/Offers/LoyaltyRegistrationButton';
import { trackWhatsNewEvent } from 'utils/product/tracking/loyaltyEvents';
import TermsLink from 'components/Terms/TermsLink';
import { IconButton } from '@material-ui/core';
import { loyaltyPaths } from 'utils/product/loyalty/config';
import CmsHtmlField from 'components/CmsHtmlField/CmsHtmlField';

import styles from './LoyaltyPrompt.module.scss';
import GenericLoader from 'components/GenericLoader/GenericLoader';

const LoyaltyPrompt = ({ onClose, open, closeDialog, ...rest }) => {
  const { t, i18n } = useTranslation(['profile']);
  const { incrementStep } = useUniqueFlow();
  const { data, loading, error } = useLoyaltyPrompt(i18n.language);
  const { loyaltyCustomer, isLoyaltyCustomer } = useAuthUser();

  const navigate = useNavigate();
  useEffect(() => {
    if (!loading && error) {
      closeDialog();
      navigate('/');
    }
  }, [error, loading, closeDialog, navigate, loyaltyCustomer?.loyaltyStatus]);

  useEffect(() => {
    if (isLoyaltyCustomer) {
      incrementStep();
    }
  }, [isLoyaltyCustomer, incrementStep, data]);

  return loading ? (
    <GenericLoader />
  ) : (
    <DialogOverlay
      open={open}
      closeDialog={() => {
        trackWhatsNewEvent('whats_new_loyalty_exit');
        closeDialog();
        navigate('/');
        if (typeof onClose === 'function') onClose();
      }}
      scroll="body"
      dialogClass={styles.dialog}
      titleClass={styles.dialogTitle}
      contentClass={styles.dialogContent}
      actionsClass={styles.dialogActions}
      closeButtonClass={styles.closeButton}
      showCloseButton={true}
      dialogActions={
        <LoyaltyRegistrationButton
          closeDialog={closeDialog}
          onClose={onClose}
        />
      }
      topLeftIcon={
        <IconButton
          className={styles.infoButton}
          aria-label="info"
          onClick={() => {
            navigate(`/loyalty/${loyaltyPaths.faqs}`);
          }}
        >
          <HelpOutlineIcon />
        </IconButton>
      }
      showDialogHeader={false}
      alignActions="right"
      {...rest}
    >
      <>
        <Loader show={loading} height={254}>
          {data && (
            <div
              className={styles.header}
              style={{ backgroundImage: `url(${data?.image})` }}
            ></div>
          )}
        </Loader>
        <WidgetSkeleton show={loading} heightInPixels={380}>
          {data && (
            <section className={styles.content}>
              <CmsHtmlField value={data.description} />
              <div className={styles.list}>
                {data.list?.map((x) => (
                  <IconText
                    key={x}
                    content={x}
                    containerClass={styles.listItem}
                    Icon={<EnergyIcon width={24} height={24} />}
                  />
                ))}
              </div>
              <TermsLink
                url={data.terms_and_conditions_url}
                target="_blank"
                rel="noreferrer"
                linkClass={styles.termsLink}
              >
                <IconText
                  content={t('profile:terms.general')}
                  iconRight={<RightArrow />}
                />
              </TermsLink>
            </section>
          )}
        </WidgetSkeleton>
      </>
    </DialogOverlay>
  );
};

export default LoyaltyPrompt;
