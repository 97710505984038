import useData from 'data/useData';
import useAccountMeters from 'data/useAccountMeters';
import {
  meterStatusesElectricity,
  meterStatusesGas,
} from 'utils/product/enums/meterStatuses';
import { directDebitCardStatus } from 'utils/product/enums/directDebitCardStatus';
import { isValidStandingOrderManagementEntry } from 'utils/product/loyalty/recurringUtils';

export default function useStandingOrderAccountInfo(accountId) {
  const { meters: _meters, loading: metersLoading } = useAccountMeters({
    statusesElectricity: [
      meterStatusesElectricity.active,
      meterStatusesElectricity.terminated,
    ],
    statusesGas: [meterStatusesGas.active, meterStatusesGas.terminated],
  });

  const {
    data,
    error,
    loading: StandingOrderAccountLoading,
    mutate,
  } = useData(`/account/standingorder?AccountId=${accountId}`, {}, !accountId);

  const meters =
    !data?.StandingOrderByAccountInfo || !_meters
      ? []
      : _meters?.reduce((acc, currentMeter) => {
          const standingOrderInfo = data?.StandingOrderByAccountInfo?.find(
            (meter) => meter.MeterId === currentMeter.Id,
          );

          const activeRecurringCard =
            (!!standingOrderInfo?.Credit &&
              standingOrderInfo?.StatusCode === directDebitCardStatus.active) ||
            !!standingOrderInfo?.Bank;

          const StandingOrderExpired =
            !standingOrderInfo?.Credit &&
            !standingOrderInfo?.Bank &&
            standingOrderInfo?.StatusCode === directDebitCardStatus.expired;

          const StandingOrder = activeRecurringCard || StandingOrderExpired;

          const StandingOrderBankActivated = !!standingOrderInfo?.Bank;

          const meterDataWithStandingOrderInfo = {
            ...currentMeter,
            StandingOrder,
            StandingOrderExpired,
            StandingOrderBankActivated,
          };

          const isValid = isValidStandingOrderManagementEntry(
            currentMeter,
            activeRecurringCard,
          );

          isValid && acc.push(meterDataWithStandingOrderInfo);

          return acc;
        }, []);

  const standingOrderInfoMap = new Map(
    data?.StandingOrderByAccountInfo?.map((meter, index) => [
      meter?.MeterId,
      index,
    ]),
  );

  const cardDeletionMutate = (meterId) => {
    const index = standingOrderInfoMap.get(meterId);

    data.StandingOrderByAccountInfo[index] = {
      ...data.StandingOrderByAccountInfo[index],
      Credit: false,
      StatusCode: directDebitCardStatus.inactive,
    };
    mutate({ ...data });
  };

  const cardActivationMutate = (meterId) => {
    const index = standingOrderInfoMap.get(meterId);

    data.StandingOrderByAccountInfo[index] = {
      ...data.StandingOrderByAccountInfo[index],
      Credit: true,
      StatusCode: directDebitCardStatus.active,
    };
    mutate({ ...data });
  };

  return {
    cardDeletionMutate,
    cardActivationMutate,
    data: meters,
    loading: metersLoading || StandingOrderAccountLoading,
    error,
  };
}
