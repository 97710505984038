import React from 'react';
import { useTranslation } from 'react-i18next';

import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import { ReactComponent as LoyaltyCreditIcon } from 'assets/icons/LoyaltyCreditIcon.svg';
import { formatMoney } from 'utils/moneyUtils';
import IconText from 'components/IconText/IconText';
import Widget from 'components/Widget/Widget';
import { useCollectionOptions } from 'data/useCollectionOptions';
import { getPointsOfCategory } from 'utils/product/loyalty/pointsUtils';
import { collectionOptionTypes } from 'utils/product/enums/cmsEnums';
import { useAuthUser } from 'utils/AuthUser';

import styles from './ShopLoyaltyPointsWidget.module.scss';

const ShopLoyaltyPointsWidget = ({ distance = 'm' }) => {
  const { t } = useTranslation(['shop']);
  const { loyaltyLoading } = useAuthUser();
  const { collectionOptionsByType, loading } = useCollectionOptions();
  const shopPoints = getPointsOfCategory(
    collectionOptionsByType,
    collectionOptionTypes.SHOP
  );

  return (
    <WidgetSkeleton
      heightInPixels={70}
      show={loading || loyaltyLoading}
      distance={distance}
      hideTitle
      containerClass={styles['widget-container']}
    >
      <Widget distance={distance} widgetClass={styles.widget}>
        <div className={styles[`banner-info`]}>
          <p>{t('shop:loyalty.reward')}</p>
          <IconText
            content={formatMoney(Math.floor(shopPoints ?? 0), false)}
            Icon={<LoyaltyCreditIcon />}
            containerClass={styles.iconTextClass}
            contentClass={styles.points}
            gap={'xs'}
          />
        </div>
      </Widget>
    </WidgetSkeleton>
  );
};

export default ShopLoyaltyPointsWidget;
