const expirationLabelTypes = {
  countdown: 'countdown',
  date: 'date',
};

const expirationLabelThemes = {
  dark: 'DARK',
  light: 'LIGHT',
};

export { expirationLabelTypes, expirationLabelThemes };
