import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  CustomButton,
  ExternalLinkButton,
  InternalLinkButton,
  OfferDescription,
  OfferTitle,
} from './OfferElements';
import { trackOfferEvent } from 'utils/product/tracking/events';
import {
  mutateLoyaltyPoints,
  useLoyaltyPoints,
} from 'data/useRewardSchemeAccounts';
import { RedeemPointsLabel } from 'components/Loyalty/LoyaltyLabels';
import { LocalisedErrorDialog } from 'components/PromptDialogs/PromptDialog';
import { useAuthUser } from 'utils/AuthUser';
import { buttonActions } from 'utils/product/enums/cmsEnums';
import { trackLoyaltyOfferEvent } from 'utils/product/tracking/loyaltyEvents';
import useDialog from 'hooks/useDialog';
import VibesAcquiredDialog from 'components/Loyalty/VibesAcquiredDialog';
import OfferImage from './OfferImage';
import OfferAcquisitionSuccess from './OfferAcquisitionSuccess';
import OfferAcquisitionConfirm from './OfferAcquisitionConfirm';
import useError from 'hooks/useError';
import LoyaltyRegistrationButton from './LoyaltyRegistrationButton';
import Widget from 'components/Widget/Widget';
import { isVibesPromoOffer } from 'utils/product/offerUtils';

import styles from './LoyaltyOffer.module.scss';

const LoyaltyOffer = ({ offer }) => {
  const { t } = useTranslation(['translation', 'offers']);
  const confirmationDialog = useDialog();
  const successDialog = useDialog();
  const { error, handleError, clearError } = useError();
  const errorDialog = useDialog();
  const { loyaltyCustomerId, isLoyaltyCustomer } = useAuthUser();
  const { data } = useLoyaltyPoints(loyaltyCustomerId, !isLoyaltyCustomer);
  const [redemptionResult, setRedemptionResult] = useState(null);
  const navigate = useNavigate();

  const vibesDialog = useDialog(false);

  const {
    imageUrl,
    largeImageUrl,
    endDate,
    detailsTitle,
    description,
    button: { buttonTitle: ctaText, buttonAction: ctaType, url: ctaValue } = {},
    expirationLabelColor,
    redeemPoints,
  } = offer || {};

  const isLoyaltyRegistration = isVibesPromoOffer(offer);

  if (!offer) return null;

  if (isLoyaltyRegistration && isLoyaltyCustomer)
    return <Navigate to="/" replace={true} />;

  const onRedeemOffer = () => {
    clearError();
    setRedemptionResult(null);

    trackLoyaltyOfferEvent('offer_loyalty_get_the_offer_selected', '', {
      offer_name: detailsTitle,
    });

    if (!!data && data?.availableRemainingAmount < redeemPoints) {
      data?.remainingAmount !== data?.availableRemainingAmount &&
      data?.remainingAmount >= redeemPoints
        ? handleError('CLIENT_PENDING_POINTS', 'wave')
        : handleError('CLIENT_INSUFFICIENT_POINTS', 'wave');

      errorDialog.show();
      return;
    }
    confirmationDialog.show();
  };

  const onRedeemSuccess = (data) => {
    mutateLoyaltyPoints(loyaltyCustomerId);
    setRedemptionResult(data);
    successDialog.show();
  };

  const onRedeemError = (error) => {
    handleError(error?.response?.data?.code, 'wave');
    errorDialog.show();
  };

  return (
    <>
      <Widget widgetClass={styles.offerContainer}>
        <OfferImage
          imageUrl={imageUrl}
          largeImageUrl={largeImageUrl}
          expirationDate={endDate}
          containerClass={styles.image}
          labelTheme={expirationLabelColor}
        />
        <section className={styles.section}>
          <OfferTitle title={detailsTitle} />
          <OfferDescription description={description} />
          {(ctaType || redeemPoints > 0) && (
            <div className={styles.ctaContainer}>
              {redeemPoints > 0 ? (
                <>
                  <p>{t('offers:acquisition-flow.redeem-one')}</p>
                  <RedeemPointsLabel
                    points={redeemPoints}
                    labelClass={styles.points}
                  />
                  <CustomButton text={ctaText} clickHandler={onRedeemOffer} />
                </>
              ) : (
                <>
                  {(ctaType === buttonActions.externalUrl ||
                    ctaType === buttonActions.externalUrlInApp) && (
                    <ExternalLinkButton
                      clickHandler={() => {
                        trackOfferEvent('offer_button_selected', detailsTitle);
                      }}
                      url={ctaValue}
                      text={ctaText}
                    />
                  )}
                  {ctaType === buttonActions.internalUrl &&
                    (isLoyaltyRegistration ? (
                      <LoyaltyRegistrationButton
                        offer={offer}
                        ctaText={ctaText}
                      />
                    ) : (
                      <InternalLinkButton
                        clickHandler={() => {
                          trackOfferEvent(
                            'offer_button_selected',
                            detailsTitle,
                          );
                        }}
                        url={ctaValue}
                        text={ctaText}
                      />
                    ))}
                </>
              )}
            </div>
          )}
        </section>
      </Widget>
      <OfferAcquisitionConfirm
        offer={offer}
        open={confirmationDialog.isOpen}
        closeDialog={confirmationDialog.close}
        onSuccess={onRedeemSuccess}
        onError={onRedeemError}
      />
      <OfferAcquisitionSuccess
        offer={offer}
        coupon={redemptionResult?.coupon}
        open={successDialog.isOpen}
        closeDialog={() => {
          successDialog.close();
          vibesDialog.show();
        }}
        clickHandler={() => {
          trackLoyaltyOfferEvent('offer_loyalty_success_my_coupons_selected');
          navigate('/profile/my-coupons', {
            state: { displayVibes: true },
          });
        }}
      />
      <LocalisedErrorDialog
        open={errorDialog.isOpen}
        closeDialog={errorDialog.close}
        showCloseButton={false}
        showPrimaryAction={true}
        primaryActionText={t('translation:ok')}
        primaryActionHandler={errorDialog.close}
        {...(error || {})}
      />
      {vibesDialog.isOpen && (
        <VibesAcquiredDialog
          open={vibesDialog.isOpen}
          closeDialog={() => {
            vibesDialog.close();
          }}
          type="redemption"
        />
      )}
    </>
  );
};

export default LoyaltyOffer;
