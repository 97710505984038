import Plus from 'assets/icons/PlusIcon.png';
import Minus from 'assets/icons/MinusIcon.png';

function ZoomIn(controlDiv, map) {
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = 'transparent';
  controlUI.style.borderRadius = '50%';
  controlUI.style.cursor = 'pointer';
  controlUI.style.marginBottom = '10px';
  controlUI.style.marginRight = '24px';
  controlDiv.appendChild(controlUI);
  const controlZoonIn = document.createElement('div');
  controlZoonIn.style.background = `url(${Plus}) no-repeat top left`;
  controlZoonIn.style.backgroundSize = `contain`;
  controlZoonIn.style.width = '32px';
  controlZoonIn.style.height = '32px';
  controlUI.appendChild(controlZoonIn);
  controlUI.addEventListener('click', () => {
    map.setZoom(map.getZoom() + 1);
  });
}

function ZoomOut(controlDiv, map) {
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = 'transparent';
  controlUI.style.borderRadius = '50%';
  controlUI.style.cursor = 'pointer';
  controlUI.style.marginBottom = '24px';
  controlUI.style.marginRight = '24px';
  controlDiv.appendChild(controlUI);
  const controlZoomOut = document.createElement('div');
  controlZoomOut.style.background = `url(${Minus}) no-repeat top left`;
  controlZoomOut.style.backgroundSize = `contain`;
  controlZoomOut.style.width = '32px';
  controlZoomOut.style.height = '32px';
  controlUI.appendChild(controlZoomOut);
  controlUI.addEventListener('click', () => {
    map.setZoom(map.getZoom() - 1);
  });
}

export { ZoomOut, ZoomIn };
