import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Widget from 'components/Widget/Widget';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import { Divider } from '@material-ui/core';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import { ReactComponent as RecurringEmptyIcon } from 'assets/icons/EmptyEbillIcon.svg';
import { ReactComponent as RecurringIcon } from 'assets/icons/RecurringLoyaltyIcon.svg';
import EmptyStateWidget from 'components/Widget/EmptyStateWidget';
import useError from 'hooks/useError';
import RewardDialog from 'components/Loyalty/RewardDialog';
import useDialog from 'hooks/useDialog';
import { getWaveId, getRewardPoints } from 'utils/product/loyalty/pointsUtils';
import { useCollectionOptions } from 'data/useCollectionOptions';
import { collectionOptionTypes } from 'utils/product/enums/cmsEnums';
import { receivePoints } from 'api/loyalty';
import { useAuthUser } from 'utils/AuthUser';
import commodities from 'utils/product/enums/commodities';
import useAccountMeters from 'data/useAccountMeters';
import MeterIconText from 'components/Labels/MeterIconText';
import useLoyaltyHistory from 'data/useLoyaltyHistory';
import {
  findMetersWithoutRecurringReward,
  isMeterEligibleForReward,
} from 'utils/product/loyalty/recurringUtils';
import { getRecurringPaymentStatus } from 'api/meters';
import paymentTypes from 'utils/product/enums/paymentTypes';
import PaymentWidget from 'components/Payment/PaymentWidget';
import { trackStandingOrderEvent } from 'utils/product/tracking/loyaltyEvents';
import {
  meterStatusesElectricity,
  meterStatusesGas,
} from 'utils/product/enums/meterStatuses';
import { sortMetersForManagementPage } from 'utils/product/meterUtils';
import { directDebitCardStatus } from 'utils/product/enums/directDebitCardStatus';

import styles from './LoyaltyDebitPaymentWidget.module.scss';

const LoyaltyDebitPaymentWidget = () => {
  const { loyaltyCustomerId } = useAuthUser();
  const { t, i18n } = useTranslation(['loyalty']);
  const { handleError } = useError();
  const rewardDialog = useDialog();
  const [rewardPoints, setRewardPoints] = useState();
  const [loadingMeterIdButton, setLoadingMeterIdButton] = useState();
  const [meter, setMeter] = useState();
  const paymentDialog = useDialog();

  const { loading: collectionOptionsLoading, collectionOptionsByType } =
    useCollectionOptions(i18n.language);

  const waveId = getWaveId(
    collectionOptionsByType,
    collectionOptionTypes.DEBIT_PAYMENT,
  );

  const { meters, loading } = useAccountMeters({
    statusesElectricity: [
      meterStatusesElectricity.active,
      meterStatusesElectricity.terminated,
    ],
    statusesGas: [meterStatusesGas.active, meterStatusesGas.terminated],
  });

  const {
    data: historyData,
    loading: historyLoading,
    mutate,
  } = useLoyaltyHistory(loyaltyCustomerId, waveId);

  const metersEligibleForActivation = meters?.filter((meter) =>
    isMeterEligibleForReward(meter),
  );
  const metersWithoutDebitPayment = sortMetersForManagementPage(
    findMetersWithoutRecurringReward(metersEligibleForActivation, historyData),
  );

  const debitPaymentAvailable = metersEligibleForActivation?.length > 0;

  const handleRecurring = (meter) => {
    setLoadingMeterIdButton(meter?.Id);
    setMeter(meter);
    trackStandingOrderEvent('standing_order_activate');

    getRecurringPaymentStatus(meter?.Id, meter?.Commodity)
      .then(({ data: { Bank, Credit, StatusCode } }) => {
        const activeRecurringCard =
          (Credit && StatusCode === directDebitCardStatus.active) || Bank;
        if (activeRecurringCard) {
          return receiveLoyaltyPoints(meter?.Id);
        } else {
          paymentDialog.show();
          return;
        }
      })
      .catch((error) => {
        setLoadingMeterIdButton();
        handleError(error, 'recurring');
      });
  };

  const receiveLoyaltyPoints = async (meterId) => {
    const postData = {
      customer: {
        customerId: loyaltyCustomerId,
      },
      type: 'CUSTOM',
      ruleId: waveId,
      items: [{ id: meterId }],
    };

    return receivePoints(postData)
      .then((loyaltyResponse) => {
        mutate();
        setRewardPoints(getRewardPoints(loyaltyResponse?.data, waveId));
        rewardDialog.show();
        setLoadingMeterIdButton();
        trackStandingOrderEvent('standing_order_already_activated');
      })
      .catch((error) => {
        setLoadingMeterIdButton();
        return Promise.reject(error);
      });
  };

  return (
    <>
      <WidgetSkeleton heightInPixels="300" show={loading || historyLoading}>
        {metersWithoutDebitPayment.length > 0 ? (
          <>
            <Widget
              distance="xs"
              widgetClass={styles['debit-widget']}
              containerClass={styles.widgetContainer}
            >
              <h3>
                {t('loyalty:collection-options.DEBIT_PAYMENT.widget-title')}
              </h3>
              <div className={styles.container}>
                <div className={styles.debitActivation}>
                  {metersWithoutDebitPayment.map((meter) => {
                    return (
                      <React.Fragment key={meter.Id}>
                        <div className={styles.textAndButton}>
                          <MeterIconText
                            meterNo={meter?.MeterNo}
                            commodity={meter?.Commodity}
                            friendlyName={meter?.FriendlyName}
                          />
                          <PrimaryButtonLoader
                            onClick={() => handleRecurring(meter)}
                            loading={loadingMeterIdButton === meter.Id}
                            disabled={!!loadingMeterIdButton}
                          >
                            {t(
                              'loyalty:collection-options.DEBIT_PAYMENT.activatePaymentButton',
                            )}
                          </PrimaryButtonLoader>
                        </div>
                        <Divider className={styles.divider} />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </Widget>
            <p className={styles.subtext}>
              {t('loyalty:collection-options.DEBIT_PAYMENT.info')}
            </p>
          </>
        ) : (
          <EmptyStateWidget
            icon={
              debitPaymentAvailable ? (
                <RecurringIcon
                  width="72"
                  height="72"
                  alt="empty already activated"
                />
              ) : (
                <RecurringEmptyIcon
                  width="72"
                  height="72"
                  alt="empty nothing to activate"
                />
              )
            }
            text={
              debitPaymentAvailable
                ? t(
                    'loyalty:collection-options.DEBIT_PAYMENT.completeDebit-message',
                  )
                : t('loyalty:collection-options.DEBIT_PAYMENT.empty-message')
            }
            isFullWidth={false}
          />
        )}
      </WidgetSkeleton>
      <RewardDialog
        open={rewardDialog.isOpen}
        closeDialog={rewardDialog.close}
        points={rewardPoints}
        icon={
          <RecurringIcon
            width="60"
            height="60"
            alt="success"
            className={styles.successIcon}
          />
        }
        header={t(`loyalty:collection-success.DEBIT_PAYMENT.header`)}
        message={t(
          `loyalty:collection-success.DEBIT_PAYMENT.alreadyActivatedMessage`,
        )}
        loading={collectionOptionsLoading}
      />
      <PaymentWidget
        open={paymentDialog.isOpen}
        closeDialog={() => {
          paymentDialog.close();
          setLoadingMeterIdButton();
        }}
        openDialog={paymentDialog.show}
        paymentType={paymentTypes.recurring}
        meterId={meter?.Id}
        meter={meter}
        commodity={commodities.energy}
        onPaymentSuccess={() => {
          trackStandingOrderEvent('standing_order_success_with_loyalty_points');
        }}
      />
    </>
  );
};

export default LoyaltyDebitPaymentWidget;
