import React from 'react';
import { useTranslation } from 'react-i18next';

import ThreeColumnsLayout from 'layouts/ThreeColumnsLayout';
import EnaHomeWidget from './EnaHomeWidget';

const EnaDetailedList = () => {
  const { t } = useTranslation(['ena']);

  return (
    <ThreeColumnsLayout
      head={{
        title: t('ena:home.title'),
        metaTags: [{ name: 'title', content: t('ena:home.title') }],
      }}
      main={<EnaHomeWidget detailed={true} />}
    />
  );
};
export default EnaDetailedList;
