import { useTranslation } from 'react-i18next';
import accountCategoryTypes from 'utils/product/enums/accountCategoryTypes';
import { requestTypes } from 'utils/product/enums/requests';

const useRequestFormData = (type, accountType) => {
  const { t } = useTranslation(['requests', 'newClient']);

  if (type === requestTypes.newGasSupply) {
    return {
      FirstName: t(`newClient:sharedDetails.firstName`),
      LastName: t(`newClient:sharedDetails.lastName`),
      TaxNo: t(`newClient:sharedDetails.taxNo`),
      Phone: t(`newClient:newGasSupply.contact-number`),
      Address: t(`newClient:sharedDetails.street`),
      AddressNo: t(`newClient:sharedDetails.streetNo`),
      PostalCode: t(`newClient:sharedDetails.postalCode`),
      PostalCodeArea: t(`newClient:sharedDetails.prefecture`),
      PackageId: t('newClient:meterDetails.programName'),
    };
  }
  const personalDataHome = {
    title_personalData: t(`requests:personal-data.title`),
    fullName: t(`requests:personal-data.fullname`),
    address: t(`requests:personal-data.address`),
    idPassport: t(`requests:personal-data.personal-id`),
    placeOfIssue: t(`requests:personal-data.personal-id-issuer`),
    taxId: t(`requests:personal-data.tax-id`),
    taxOffice: t(`requests:personal-data.tax-office`),
    phone: t(`requests:personal-data.phone`),
    mobile: t(`requests:personal-data.mobile`),
    email: t(`requests:personal-data.login-email`),
  };

  const personalDataBusiness = {
    title_personalData: t(`requests:personal-data.title`),
    companyName: t(`requests:personal-data.company-name`),
    companyAddress: t(`requests:personal-data.company-address`),
    idPassport: t(`requests:personal-data.personal-id`),
    placeOfIssue: t(`requests:personal-data.personal-id-issuer`),
    taxId: t(`requests:personal-data.tax-id`),
    taxOffice: t(`requests:personal-data.tax-office`),
    phone: t(`requests:personal-data.phone`),
    mobile: t(`requests:personal-data.mobile`),
    email: t(`requests:personal-data.login-email`),
    legalContact: t(`requests:personal-data.legal-contact`),
  };

  if (type === requestTypes.personalDataChange) {
    if (accountType === accountCategoryTypes.home) {
      return {
        ...personalDataHome,
        title_contactPerson: t(`requests:personal-data.add-contact-title`),
        contactFullName: t(`requests:personal-data.fullname`),
        contactPhone: t(`requests:personal-data.phone`),
        contactEmail: t(`requests:personal-data.email`),
      };
    } else {
      return {
        ...personalDataBusiness,
      };
    }
  } else if (type === requestTypes.billingQuestions) {
    const billingFields = {
      title_meterDetails: t(`requests:billing.meter-details`),
      meterName: t(`requests:billing.meter`),
      meterAddress: t(`requests:billing.meter-address`),
      title_invoiceDetails: t(`requests:billing.invoice-details`),
      invoiceNumber: t(`requests:billing.invoice-number`),
      invoiceDate: t('requests:billing.invoice-date'),
      title_energyBilling: t(`requests:billing.title-1`),
      consumption: t(`requests:billing.consumption`),
      powerBilling: t(`requests:billing.powerBilling`),
      energyBilling: t(`requests:billing.energyBilling`),
      againstInvoice: t(`requests:billing.againstInvoice`),
      title_municipalityBilling: t(`requests:billing.title-2`),
      squareMeters: t(`requests:billing.squareMeters`),
      areaPrice: t(`requests:billing.areaPrice`),
      oldness: t(`requests:billing.oldness`),
      tapFactor: t(`requests:billing.tapFactor`),
      daysFactor: t(`requests:billing.daysFactor`),
      title_reason: '',
      reason: t(`requests:billing.reason`),
      comments: t(`requests:billing.comments`),
    };
    if (accountType === accountCategoryTypes.home) {
      return {
        ...personalDataHome,
        ...billingFields,
      };
    } else {
      return {
        ...personalDataBusiness,
        ...billingFields,
      };
    }
  } else if (type === requestTypes.movingRequest) {
    const movingFields = {
      title_meterDetails: t(`requests:billing.meter-details-cut`),
      meterNo: t(`requests:billing.meter`),
      meterAddress: t(`requests:moving.meter-address`),
      title_newMeter: t(`requests:moving.new-meter`),
      newSupplyNumber: t(`requests:moving.new-supply`),
      newSupplyAddress: t(`requests:moving.street`),
      newSupplyAddressNo: t(`requests:moving.street-no`),
      newSupplyPostalCode: t(`requests:moving.postal-code`),
      newSupplyArea: t(`requests:moving.area`),
      keepSameProgram: t('requests:moving.disconnect-power-title'),
    };
    if (accountType === accountCategoryTypes.home) {
      return {
        ...personalDataHome,
        ...movingFields,
      };
    } else {
      return {
        ...personalDataBusiness,
        ...movingFields,
      };
    }
  } else if (type === requestTypes.powerDisconnect) {
    const powerDisconnectFields = {
      title_meterDetails: t(`requests:billing.meter-details-cut`),
      meterNo: t(`requests:billing.meter`),
      meterAddress: t(`requests:moving.meter-address`),
      title_movingDate: t(`requests:moving.moving-date-title`),
      movingDate: t('requests:moving.date'),
      iban: t('requests:moving.iban'),
    };
    if (accountType === accountCategoryTypes.home) {
      return {
        ...personalDataHome,
        ...powerDisconnectFields,
      };
    } else {
      return {
        ...personalDataBusiness,
        ...powerDisconnectFields,
      };
    }
  } else if (type === requestTypes.usageChange) {
    const usageFields = {
      title_usageChange: t(`requests:usage-change.section-title`),
      meterName: t(`requests:billing.meter`),
      currentUsage: t(`requests:usage-change.current-usage`),
      newUsage: t(`requests:usage-change.new-usage`),
    };
    if (accountType === accountCategoryTypes.home) {
      return {
        ...personalDataHome,
        ...usageFields,
      };
    } else {
      return {
        ...personalDataBusiness,
        ...usageFields,
      };
    }
  }

  return {};
};

export default useRequestFormData;
