import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import get from 'lodash/get';
import useDialog from 'hooks/useDialog';
import { isEmptyString } from 'utils/stringUtils';
import { contactHeron } from 'api/contact';
import { trackShopServiceEvent } from 'utils/product/tracking/events';
import {
  PrimaryButtonLoader,
  SecondaryButtonLoader,
} from 'components/Buttons/Buttons';
import {
  SuccessDialog,
  ErrorDialog,
} from 'components/PromptDialogs/PromptDialog';

import styles from './ProgramActions.module.scss';

const ContactButton = ({ isEna = false, title, leadSrc, user, packageId }) => {
  const { t } = useTranslation(['shop']);
  const [isContactLoading, setIsContactLoading] = useState(false);
  const contactSuccessDialog = useDialog();
  const contactErrorDialog = useDialog();

  const contact = () => {
    trackShopServiceEvent('shop_service_create_lead', title);

    setIsContactLoading(true);
    const accountType =
      get(user, 'AccountType', null) === 0 ? 2 : get(user, 'AccountType', null);
    contactHeron(
      {
        firstName: isEmptyString(user.FirstName)
          ? user.CompanyName
          : user.FirstName,
        lastName: isEmptyString(user.LastName)
          ? user.CompanyName
          : user.LastName,
        phone: get(user, 'ContactPhone', null),
        email: get(user, 'ContactEmail', null),
        type: accountType,
        existing_customer: 1,
        heron_ena: isEna ? 1 : 0,
        ecoroof: packageId === 'ECOROOF' ? 1 : 0,
        ecoheat: packageId === 'ECO HEAT' ? 1 : 0,
      },
      leadSrc
    )
      .then((result) => {
        const isSuccess =
          result?.data && result?.data.length > 0 && result?.data[0] === 0;
        if (isSuccess) {
          trackShopServiceEvent('shop_service_lead_success', title);
          contactSuccessDialog.show();
          return;
        }
        trackShopServiceEvent('shop_service_lead_fail', title);
        contactErrorDialog.show();
      })
      .catch((error) => {
        contactErrorDialog.show();
        trackShopServiceEvent('shop_service_lead_fail', title);
      })
      .then((res) => {
        setIsContactLoading(false);
      });
  };
  return (
    <>
      {isEna ? (
        <SecondaryButtonLoader
          className={styles.actionButton}
          onClick={() => contact()}
          loading={isContactLoading}
          disabled={isContactLoading}
        >
          {t('shop:program.contact')}
        </SecondaryButtonLoader>
      ) : (
        <PrimaryButtonLoader
          className={styles.actionButton}
          onClick={() => contact()}
          loading={isContactLoading}
          disabled={isContactLoading}
        >
          {t('shop:program.interest')}
        </PrimaryButtonLoader>
      )}
      <SuccessDialog
        open={contactSuccessDialog.isOpen}
        closeDialog={contactSuccessDialog.close}
        header={t(`shop:program.contact-success-title`)}
        message={t(`shop:program.contact-success-message`, {
          program: title,
        })}
      />
      <ErrorDialog
        open={contactErrorDialog.isOpen}
        closeDialog={contactErrorDialog.close}
      />
    </>
  );
};

export default ContactButton;
