import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';

import { trackAppBannerEvent } from 'utils/product/tracking/events';
import { AppContext } from 'utils/AppContext';
import { PrimaryButton } from 'components/Buttons/Buttons';
import { ReactComponent as CancelIcon } from 'assets/icons/CancelIcon.svg';
import { ReactComponent as AppIcon } from 'assets/icons/AppIcon.svg';
import styles from './AppBanner.module.scss';

const AppBanner = () => {
  const { t } = useTranslation(['translation']);
  const { showAppBanner, hideAppBanner, osName } = React.useContext(AppContext);

  return showAppBanner ? (
    <section className={styles.container}>
      <IconButton
        name="close-banner"
        className={styles.closeButton}
        onClick={() => {
          trackAppBannerEvent('exit', '', osName);
          hideAppBanner();
        }}
      >
        <CancelIcon width="8px" height="8px" />
      </IconButton>
      <AppIcon width="64px" height="64px" alt="app-logo" />
      <div className={styles.titles}>
        <p className={styles.title}>{t('app-banner.title')}</p>
        <p>{t(`app-banner.subtitle-${osName}`)}</p>
      </div>
      <a
        href={
          osName === 'android'
            ? process.env.REACT_APP_ANDROID_APP
            : process.env.REACT_APP_IOS_APP
        }
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          trackAppBannerEvent('selection', '', osName);
          hideAppBanner();
        }}
      >
        <PrimaryButton>{t('app-banner.cta')}</PrimaryButton>{' '}
      </a>
    </section>
  ) : null;
};

export default AppBanner;
