import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuthUser } from 'utils/AuthUser';

import { requestTypes } from 'utils/product/enums/requests';
import { EmailInput } from 'components/Inputs/EmailInput';
import { TextInput } from 'components/Inputs/TextInput';
import { TelephoneInput } from 'components/Inputs/TelephoneInput';
import { HiddenFormInput } from 'components/Inputs/HiddenFormInput';
import { IbanInput } from 'components/Inputs/IbanInput';

import styles from './PersonalDataBusiness.module.scss';

const getInputFields = (editable, requestType, user) => {
  const result = [
    {
      key: 'companyName',
      name: 'companyName',
      label: `requests:personal-data.company-name`,
      disabled: true,
    },
    {
      key: 'companyAddress',
      name: 'companyAddress',
      label: `requests:personal-data.company-address`,
      disabled: true,
    },
    {
      key: 'idPassport',
      name: editable ? 'idPassport' : 'idPassportIgnored',
      label: `requests:personal-data.personal-id`,
      disabled: !editable,
      hide: editable,
    },
    {
      key: 'placeOfIssue',
      name: editable ? 'placeOfIssue' : 'placeOfIssueIgnored',
      label: `requests:personal-data.personal-id-issuer`,
      disabled: !editable,
      hide: editable,
    },
    {
      key: 'taxId',
      name: 'taxId',
      label: `requests:personal-data.tax-id`,
      disabled: true,
      cssClass:
        requestType === requestTypes.personalDataChange
          ? styles['taxId-info']
          : styles.taxId,
      helperText:
        requestType === requestTypes.personalDataChange
          ? `requests:personal-data.tax-id-info`
          : null,
      FormHelperTextProps: {
        classes: {
          root: styles.helper,
        },
      },
    },
    {
      key: 'taxOffice',
      name: editable ? 'taxOffice' : 'taxOfficeIgnored',
      label: `requests:personal-data.tax-office`,
      disabled: !editable,
    },
    {
      key: 'phone',
      name: editable ? 'phone' : 'phoneIgnored',
      label: `requests:personal-data.phone`,
      disabled: !editable,
      type: 'phone',
    },
    {
      key: 'mobile',
      name: editable ? 'mobile' : 'mobileIgnored',
      label: `requests:personal-data.mobile`,
      disabled: !editable,
      type: 'phone',
      mobileOnly: true,
    },
    {
      key: 'email',
      name: editable ? 'email' : 'emailIgnored',
      label: `requests:personal-data.login-email`,
      disabled: !editable,
      type: 'email',
      hide: !user?.IsParent,
    },
    {
      key: 'legalContact',
      name: editable ? 'legalContact' : 'legalContactIgnored',
      label: `requests:personal-data.legal-contact`,
      disabled: !editable,
    },
  ];

  if (requestType === requestTypes.movingRequest)
    result.push({
      key: 'iban',
      name: 'iban',
      label: `requests:moving.iban`,
      disabled: false,
      type: 'iban',
      required: false,
    });
  return result;
};

const PersonalDataBusiness = ({
  editable = false,
  account,
  defaultValues,
  requestType,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation(['requests']);
  const { user } = useAuthUser();

  const inputFields = getInputFields(editable, requestType, user);

  return (
    account && (
      <article className={styles.container}>
        <h3 className={styles.title}>{t(`requests:personal-data.title`)}</h3>
        {inputFields.map((item) => {
          const inputProps = {
            name: item.name,
            label: t(item.label),
            defaultValue: defaultValues[item.key],
            required:
              item.required !== undefined ? item.required : !item.disabled,
            register: item.disabled ? undefined : register,
            errors: item.disabled ? undefined : errors,
            FormHelperTextProps: item.FormHelperTextProps,
            disabled: item.disabled,
          };
          //setting this separately only in case it is defined, otherwise setting it null caused error messages to not be shown
          if (item.helperText) {
            inputProps.helperText = t(item.helperText);
          }
          if (item.mobileOnly) {
            inputProps.mobileOnly = item.mobileOnly;
          }
          return (
            <div className={item.cssClass ?? styles[item.key]} key={item.key}>
              {item.hide ? (
                <HiddenFormInput {...inputProps} type={'hidden'} />
              ) : (
                <>
                  {item.type === 'phone' && <TelephoneInput {...inputProps} />}
                  {item.type === 'email' && <EmailInput {...inputProps} />}
                  {item.type === 'iban' && <IbanInput {...inputProps} />}
                  {item.type === undefined && <TextInput {...inputProps} />}
                </>
              )}
            </div>
          );
        })}
      </article>
    )
  );
};

export default PersonalDataBusiness;
