const benefitTypes = {
  COUPON: 'COUPON',
  DISCOUNT_FIXED: 'DISCOUNT_FIXED',
  DISCOUNT_PERCENTAGE: 'DISCOUNT_PERCENTAGE',
  DISCOUNT_VAULT: 'DISCOUNT_VAULT',
  PRODUCT: 'PRODUCT',
  SERVICE: 'SERVICE',
  REWARD_SCHEME_CURRENCY_FIXED: 'REWARD_SCHEME_CURRENCY_FIXED',
  REWARD_SCHEME_CURRENCY_RATE: 'REWARD_SCHEME_CURRENCY_RATE',
  TICKET: 'TICKET',
  CONTEST: 'CONTEST',
  MINI_GAME: 'MINI_GAME',
};

const gameResult = {
  win: 'SUCCESS',
  lose: 'FAILURE',
};

const registration = {
  PREREGISTER: 'FIRST_CONTRACT',
};

const historyEventStatuses = {
  COMPLETED: 'COMPLETED',
  PENDING: 'PENDING',
};

const historyEventTypes = {
  DEBIT: 'DEBIT',
  CREDIT: 'CREDIT',
};

const ruleTypes = {
  LIMIT__NOT_WITHIN_TOTAL_LIMITS_PER_ITEM_PER_CUSTOMER_LIMIT: 'RULE0005',
};

export {
  ruleTypes,
  benefitTypes,
  gameResult,
  registration,
  historyEventStatuses,
  historyEventTypes,
};
