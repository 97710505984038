const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export default function downloadExcel(data, name) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name ? name : 'myHeron.xlsx');
  document.body.appendChild(link);
  link.click();
}

const saveFile = (data, fileName, fileType) => {
  const fileData = new Blob([data], {
    type: fileType,
  });
  const downloadUrl = URL.createObjectURL(fileData);
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(downloadUrl);
};

export { convertToBase64, downloadExcel, saveFile };
