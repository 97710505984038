import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { scrollTo } from 'utils/scroll';
import { useAuthUser } from 'utils/AuthUser';
import useDialog from 'hooks/useDialog';
import useQuestionnaireFlow from 'hooks/useQuestionnaireFlow';
import useLoyaltyQuestionnaire from 'data/useLoyaltyQuestionnaire';
import { getObjectEntries } from 'utils/objectUtils';
import { submitQuestionnaire } from 'api/loyalty';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import QuestionnaireStepper from './QuestionnaireStepper';
import Question from './Question';
import VibesAcquiredDialog from './VibesAcquiredDialog';
import QuestionnaireSuccessDialog from './QuestionnaireSuccessDialog';
import Loader from 'components/Skeletons/Loader';
import { ErrorDialog } from 'components/PromptDialogs/PromptDialog';
import { trackQuestionnaireEvent } from 'utils/product/tracking/loyaltyEvents';

import styles from './QuestionnaireDialog.module.scss';

const QuestionnaireDialog = ({ isOffers = false }) => {
  const { t, i18n } = useTranslation(['translation']);
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(true);
  const vibesDialog = useDialog(false);
  const successDialog = useDialog();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    loyaltyCustomerId,
    mutateLoyaltyCustomer,
    isLoyaltyCustomer,
    loyaltyLoading,
  } = useAuthUser();
  const {
    data: questionnaire,
    loading,
    isError,
  } = useLoyaltyQuestionnaire(i18n.language);
  const errorDialog = useDialog(isError && !questionnaire);

  const { step, goForward, goBack, nextRouteData, setNextRouteData } =
    useQuestionnaireFlow(questionnaire, isOffers);

  const [defaultValues, setDefaultValues] = useState(nextRouteData?.data);

  const stepsTotal = questionnaire?.questions?.length;

  const onSubmit = () => {
    if (step === stepsTotal - 1) {
      send(nextRouteData?.data);
      return;
    }
    trackQuestionnaireEvent('whats_new_loyalty_questionnaire_continue', '', {
      loyalty_questionaire_step: question?.header,
    });
    goForward(nextRouteData?.data);
  };

  const send = (data) => {
    setIsSubmitting(true);
    const postData = {
      templateId: questionnaire._id,
      customerId: loyaltyCustomerId,
      answers: getObjectEntries(data).map(([key, value]) => ({
        questionId: key,
        answer: value,
      })),
    };
    submitQuestionnaire(postData)
      .then((x) => {
        setOpen(false);
        vibesDialog.show();
        trackQuestionnaireEvent('whats_new_loyalty_questionnaire_success');
      })
      .catch((error) => {})
      .then((x) => setIsSubmitting(false));
  };

  const onUpdate = (data) => {
    const { answer: userAnswer } = data || {};
    if (userAnswer && userAnswer.answer?.length > 0) {
      setNextRouteData((previousValue) => ({
        ...previousValue,
        data: {
          ...previousValue.data,
          [userAnswer.questionId]: userAnswer.answer,
        },
      }));
    }
  };

  useEffect(() => {
    if (isError) {
      setOpen(false);
    }
  }, [isError]);

  useEffect(() => {
    setDefaultValues(nextRouteData?.data);
  }, [nextRouteData?.data]);

  useEffect(() => {
    const unlisten = () => {
      scrollTo(0, 0, document.querySelector('div.MuiDialog-scrollBody'));
    };
    return () => {
      unlisten();
    };
  }, [location]);
  const question = questionnaire?.questions[step];

  // if (isLoyaltyCustomer) {
  //   navigate('/', { replace: true });
  //   return;
  // }
  const navigateTo = window.location.pathname.replace(
    '/loyalty/registration',
    '',
  );

  if (
    !loyaltyLoading &&
    !vibesDialog.isOpen &&
    !successDialog.isOpen &&
    isLoyaltyCustomer
  ) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <>
      {!loyaltyLoading && (
        <DialogOverlay
          open={open}
          closeDialog={() => {
            setOpen(false);
            navigate(navigateTo === '' ? '/' : navigateTo, {
              state: nextRouteData,
            });
          }}
          contentClass={styles.dialogContent}
          actionsClass={styles.dialogActions}
          scroll="body"
          showBackButton={true}
          goBack={goBack}
          dialogHeader={question?.header}
          alignActions="right"
          dialogActions={
            <PrimaryButtonLoader
              loading={isSubmitting}
              disabled={
                isSubmitting || !nextRouteData?.data[question?.clientId]
              }
              type="button"
              onClick={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              {step < stepsTotal - 1
                ? t('translation:continue')
                : t('translation:completion')}
            </PrimaryButtonLoader>
          }
        >
          <Loader show={loading} height={604}>
            {questionnaire && (
              <>
                <QuestionnaireStepper
                  step={step}
                  steps={questionnaire?.questions}
                  stepperClass={styles.stepper}
                />
                <Question
                  question={question}
                  onUserAnswer={onUpdate}
                  answers={defaultValues}
                />
              </>
            )}
          </Loader>
        </DialogOverlay>
      )}

      <ErrorDialog
        open={errorDialog.isOpen}
        closeDialog={() => {
          errorDialog.close();
          navigate('/', { replace: true });
        }}
      />

      {vibesDialog.isOpen && (
        <VibesAcquiredDialog
          type="register"
          open={vibesDialog.isOpen}
          closeDialog={() => {
            vibesDialog.close();
            successDialog.show();
          }}
        />
      )}
      <QuestionnaireSuccessDialog
        open={successDialog.isOpen}
        closeDialog={() => {
          mutateLoyaltyCustomer();
          successDialog.close();
        }}
        onClose={() => {
          navigate('/');
        }}
      />
    </>
  );
};

export default QuestionnaireDialog;
