import cloneDeep from 'lodash/cloneDeep';
import {
  sortByDateFieldDescending,
  orderBy,
  sortByFieldDescending,
} from 'utils/sortUtils';
import commodities from 'utils/product/enums/commodities';
import {
  meterStatusesElectricity,
  meterStatusesGas,
} from 'utils/product/enums/meterStatuses';
import { largeAccountMetersThreshold } from 'utils/product/config';
import { isB2BConsumption } from './consumptionUtils';
import { isEmptyString } from 'utils/stringUtils';
import commodityNames from './enums/commodityNames';
import { transformLastInvoiceFields } from './billUtils';

export const mergeMeters = (
  metersResponse,
  statusesElectricity,
  statusesGas
) => {
  const totalMeters =
    (metersResponse?.MetersInfo ? metersResponse?.MetersInfo.length : 0) +
    (metersResponse?.GasMetersInfo ? metersResponse?.GasMetersInfo.length : 0);
  const isLargeAccount = totalMeters > largeAccountMetersThreshold;

  const energyBillsToDisplay = metersResponse?.MetersInfo
    ? metersResponse?.MetersInfo.filter(
        (x) =>
          statusesElectricity.length === 0 ||
          statusesElectricity.indexOf(x.MeterStatus) > -1
      ).map((meter) => ({
        ...meter,
        type: 'energy',
        Commodity: commodities.energy,
        FullAddress: meter.AddressInfo
          ? addressInfoText(meter.AddressInfo)
          : '',
        IsLargeAccount: isLargeAccount,
        IsActive: meter.MeterStatus === meterStatusesElectricity.active,
        IsActiveOrTerminated:
          meter.MeterStatus === meterStatusesElectricity.active ||
          meter.MeterStatus === meterStatusesElectricity.terminated,
        LastInvoice: transformLastInvoiceFields(meter.LastInvoice),
      }))
    : [];
  const gasBillsToDisplay = metersResponse?.GasMetersInfo
    ? metersResponse?.GasMetersInfo.filter(
        (x) =>
          statusesGas.length === 0 || statusesGas.indexOf(x.MeterStatus) > -1
      ).map((meter) => ({
        ...meter,
        type: 'gas',
        Commodity: commodities.gas,
        FullAddress: meter.AddressInfo
          ? addressInfoText(meter.AddressInfo)
          : '',
        IsLargeAccount: isLargeAccount,
        IsActive: meter.MeterStatus === meterStatusesGas.active,
        IsActiveOrTerminated:
          meter.MeterStatus === meterStatusesGas.active ||
          meter.MeterStatus === meterStatusesGas.terminated,
        LastInvoice: transformLastInvoiceFields(meter.LastInvoice),
      }))
    : [];

  return energyBillsToDisplay.concat(gasBillsToDisplay);
};

export const addressInfoText = (data) => {
  const streetNumber = `${data.Street ? `${data.Street}`.trim() : ''}${
    data.StreetNo ? ` ${data.StreetNo}` : ''
  }`.trim();
  const cityPostal = `${data.City ? `${data.City}`.trim() : ''} ${
    data.PostalCode ? data.PostalCode : ''
  }`.trim();

  const comma = streetNumber.length > 0 && cityPostal.length > 0 ? `, ` : '';

  return streetNumber || cityPostal
    ? `${streetNumber}${comma}${cityPostal}`
    : '';
};

export const getSortedMetersForHomePage = (meters) => {
  if (!meters) return [];

  const _metersCopy = cloneDeep(meters).filter((x) => x.IsActiveOrTerminated);
  sortByDateFieldDescending(_metersCopy, 'LastInvoice.issueDate');
  sortByFieldDescending(_metersCopy, 'Commodity');

  return _metersCopy.sort((a, b) => {
    return isB2BConsumption(a) ? (isB2BConsumption(b) ? 0 : -1) : 1;
  });
};

export const sortMetersForManagementPage = (meters) => {
  return orderBy(
    meters,
    [(meter) => meter?.FriendlyName || meter?.MeterNo],
    ['asc']
  );
};

export const getPendingMeters = (meters, includeCreditBills = true) => {
  if (!meters) return [];
  return meters?.filter(
    (meter) =>
      meter.LastInvoice !== null &&
      (includeCreditBills
        ? meter.CurrentBalance !== 0
        : meter.CurrentBalance > 0)
  );
};

export const getMetersWithSettlement = (meters) => {
  if (!meters) return [];
  return meters?.filter((meter) => meter.HasSettlement === true);
};

export const hasNoInvoices = (allMeters) => {
  return (
    allMeters.filter(
      (meter) => meter.IsActiveOrTerminated && meter.LastInvoice != null
    ).length === 0
  );
};

export const getEnergyMeters = (meters) =>
  meters ? meters.filter((x) => x.Commodity === commodities.energy) : [];

export const getGasMeters = (meters) =>
  meters ? meters.filter((x) => x.Commodity === commodities.gas) : [];

export const getMeterNightIndicationDictionary = (meters) => {
  if (!meters || meters?.length === 0) return {};

  return meters.reduce((previousValue, currentValue) => {
    previousValue[currentValue.Id] = !isEmptyString(
      currentValue?.NightMeasurementCode
    );
    return previousValue;
  }, {});
};

export const getCommodityName = (commodity) => {
  if (commodity === commodities.energy) return commodityNames.energy;
  if (commodity === commodities.gas) return commodityNames.gas;

  return '';
};
