import React from 'react';
import { useTranslation } from 'react-i18next';

import MeterIconText from 'components/Labels/MeterIconText';
import HistoryRecordWrapper from 'components/History/HistoryRecordWrapper';
import HistoryRecordHeader from 'components/History/HistoryRecordHeader';
import HistoryRecordItem from 'components/History/HistoryRecordItem';
import { formatDate } from 'utils/dateUtils';
import { dateFormats } from 'utils/product/config';

const OwnMeasurementsHistoryElement = ({
  meterNo,
  commodity,
  friendlyName,
  dayMeasurement,
  nightMeasurement,
  dateFrom,
  hasNightIndication,
}) => {
  const { t } = useTranslation(['translation', 'consumptions']);

  return (
    <HistoryRecordWrapper>
      <HistoryRecordHeader
        meterName={
          <MeterIconText
            meterNo={meterNo}
            commodity={commodity}
            friendlyName={friendlyName}
          />
        }
        text={formatDate(dateFrom, dateFormats.dateHalfYear)}
      />
      <HistoryRecordItem
        text={
          hasNightIndication
            ? t('consumptions:daily')
            : t('consumptions:indication')
        }
        value={dayMeasurement}
      />
      {hasNightIndication && (
        <HistoryRecordItem
          text={t('consumptions:nightly')}
          value={nightMeasurement}
        />
      )}
    </HistoryRecordWrapper>
  );
};
export default OwnMeasurementsHistoryElement;
