import { formatDate } from 'utils/dateUtils';
import { dateFormats } from 'utils/product/config';

export const getUserCouponsData = (couponsData, couponsExtraInfo) => {
  const coupons = couponsData?.reduce(
    (accumulator, current) => {
      const activeCoupon = current?.canBeRedeemed;

      const extraInfo = couponsExtraInfo.find(
        (coupon) => coupon?.content?.waveId === current?.couponGroupId,
      );

      const coupon = {
        title: extraInfo?.content?.title,
        description: extraInfo?.content?.couponDescription,
        link: extraInfo?.content?.couponLink,
        code: current?.coupon?.code,
        pin: current?.coupon?.password,
        userId: current?.userId,
        createdAt: formatDate(current?.createdAt, dateFormats.date),
        expiresAt: formatDate(current?.coupon?.expiresAt, dateFormats.date),
        lastRedeemedAt: formatDate(current?.lastRedeemedAt, dateFormats.date),
        active: activeCoupon,
      };
      if (activeCoupon) accumulator.active.push(coupon);
      else accumulator.expired.push(coupon);

      return accumulator;
    },
    {
      active: [],
      expired: [],
    },
  );
  return coupons;
};
