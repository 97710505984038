import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import accountCategoryTypes from 'utils/product/enums/accountCategoryTypes';
import useAccountMeters from 'data/useAccountMeters';
import useRequestFormData from 'hooks/useRequestFormData';
import generateRequestData from 'utils/product/requests/generateRequestData';
import { requestTypes } from 'utils/product/enums/requests';
import { uploadRequestFiles } from 'api/requests';
import { createRequest } from 'api/requests';
import getPostData from 'utils/product/requests/getPostData';
import commodities from 'utils/product/enums/commodities';
import { scrollTo } from 'utils/scroll';
import { trackRequestsDetailViewEvent } from 'utils/product/tracking/events';
import useError from 'hooks/useError';
import { getObjectValues } from 'utils/objectUtils';

import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import PersonalDataHome from 'components/Requests/Shared/PersonalDataHome';
import PersonalDataBusiness from 'components/Requests/Shared/PersonalDataBusiness';
import UploadWidget from 'components/UploadWidget/UploadWidget';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import MeterUsage from './MeterUsage';
import {
  SuccessBox,
  LocalisedErrorBox,
} from 'components/MessageBox/MessageBox';

import styles from './UsageChangeRequest.module.scss';

const UsageChangeRequest = ({
  account,
  accountType,
  defaultValues,
  loading,
}) => {
  const { t } = useTranslation(['requests', 'inputs']);
  const formMethods = useForm();
  const { meters, loading: metersLoading } = useAccountMeters({
    onlyActive: true,
  });
  const formLabels = useRequestFormData(requestTypes.usageChange, accountType);

  const [initialValues, setInitialValues] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { error, handleError, clearError } = useError();
  const {
    error: fileError,
    handleError: handleFileError,
    clearError: clearFileError,
  } = useError();
  const [uploadedFiles, setUploadedFiles] = useState({});
  /* keeps the value of requestId, in case fileupload fails to upload again for same request */
  const [requestIdForFileUpload, setRequestIdForFileUpload] = useState(null);

  const onFileChange = (files, type) => {
    if (files) {
      setUploadedFiles({
        ...uploadedFiles,
        [`${type}`]: files[0],
      });
    }
  };

  const uploadFileRequest = (requestId, filesToUpload, data) => {
    uploadRequestFiles(requestId, filesToUpload, true)
      .then(() => {
        setIsSuccess(true);
        if (data) setInitialValues(data);
        setRequestIdForFileUpload(null);
      })
      .catch((error) => {
        handleFileError(error, 'requestFiles');
      })
      .then(() => {
        setIsLoading(false);
        scrollTo(0, 0);
      });
  };

  const onSubmit = (data) => {
    setIsSuccess(false);
    clearError();
    clearFileError();
    setIsLoading(true);
    const postData = getPostData({
      requestType: requestTypes.usageChange,
      accountType,
      data,
      defaultValues,
    });
    const meter = meters ? meters.find((x) => x.Id === postData.meterId) : null;
    const initialValuesString = generateRequestData({
      labels: formLabels,
      data: initialValues,
      accountId: account.Id,
      requestType: requestTypes.usageChange,
    });
    const postString = generateRequestData({
      labels: formLabels,
      data: postData,
      accountId: account.Id,
      requestType: requestTypes.usageChange,
      meterId: meter?.Id,
      commodity: meter?.Commodity,
    });

    const filesToUpload = getObjectValues(uploadedFiles);

    if (requestIdForFileUpload !== null) {
      if (filesToUpload.length > 0) {
        uploadFileRequest(requestIdForFileUpload, filesToUpload);
        return;
      } else setRequestIdForFileUpload(null);
    }

    if (initialValuesString.RequestText === postString.RequestText) {
      handleError('form-is-same', 'forms');
      setIsLoading(false);
      return;
    }

    createRequest(postString)
      .then((result) => {
        if (result.data.ResponseCode === 200) {
          trackRequestsDetailViewEvent(
            'request_activation_success',
            t(`requests:types.type-${requestTypes.usageChange}`)
          );
          if (result.data.RequestId && filesToUpload.length > 0) {
            setRequestIdForFileUpload(result.data.RequestId);
            uploadFileRequest(result.data.RequestId, filesToUpload, postData);
            return;
          }
          setIsSuccess(true);
          setIsLoading(false);
          setInitialValues(postData);
          scrollTo(0, 0);
          return;
        }
        handleError('general', 'requests');
        setIsLoading(false);
        scrollTo(0, 0);
        trackRequestsDetailViewEvent(
          'request_activation_fail',
          t(`requests:types.type-${requestTypes.usageChange}`)
        );
      })
      .catch((error) => {
        handleError(error, 'requests');
        setIsLoading(false);
        trackRequestsDetailViewEvent(
          'request_activation_fail',
          t(`requests:types.type-${requestTypes.usageChange}`)
        );
      });
  };
  const electricityMeters = meters?.filter(
    (x) => x.Commodity === commodities.energy
  );
  return (
    <Widget
      size="m"
      title={
        <WidgetHeader
          title={t(`requests:types.type-${requestTypes.usageChange}`)}
        />
      }
    >
      <SuccessBox
        open={isSuccess}
        closeBox={() => setIsSuccess(false)}
        header={t(`requests:submit-success-title`)}
        message={t(`requests:submit-success-subtitle`)}
      />
      <LocalisedErrorBox
        open={error !== null}
        closeBox={clearError}
        {...(error || {})}
        distanceTop=""
      />

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
          {accountType === accountCategoryTypes.home ? (
            <PersonalDataHome
              account={account}
              editable={false}
              defaultValues={defaultValues}
              requestType={requestTypes.usageChange}
            />
          ) : (
            <PersonalDataBusiness
              account={account}
              editable={false}
              defaultValues={defaultValues}
              requestType={requestTypes.usageChange}
            />
          )}
          <MeterUsage
            meters={electricityMeters}
            loading={metersLoading}
            defaultMeter={
              electricityMeters.length === 1 ? electricityMeters[0] : null
            }
          />
          <article className={styles.uploads}>
            <p>{t(`requests:usage-change.upload`)}</p>
            <LocalisedErrorBox
              open={fileError !== null}
              closeBox={clearFileError}
              {...(fileError || {})}
            />

            <UploadWidget
              label={t(`requests:usage-change.tap-file`)}
              required={true}
              register={formMethods.register}
              name="tap"
              errors={formMethods.formState.errors}
              loading={false}
              onFileChange={(file) => onFileChange(file, 'tap')}
              clearOnCancel={true}
            />
          </article>
          <article className={styles.buttonContainer}>
            <PrimaryButtonLoader
              loading={isLoading}
              disabled={isLoading || metersLoading}
              onClick={formMethods.handleSubmit(onSubmit)}
              type="submit"
            >
              {t('requests:requests.submit')}
            </PrimaryButtonLoader>
          </article>
        </form>
      </FormProvider>
    </Widget>
  );
};

export default UsageChangeRequest;
