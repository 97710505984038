export const data = {
  Activities: [
    {
      Id: '9f5fd8bd-730f-e511-80c0-000d3a207cb6',
      Name: '0000',
      Description: 'Χωρίς Δραστηριότητα',
    },
    {
      Id: 'a15fd8bd-730f-e511-80c0-000d3a207cb6',
      Name: '0100',
      Description:
        'Φυτική και ζωική παραγωγή, θήρα και συναφείς δραστηριότητες',
    },
    {
      Id: 'a35fd8bd-730f-e511-80c0-000d3a207cb6',
      Name: '0110',
      Description: 'Καλλιέργεια μη πολυετών φυτών',
    },
    {
      Id: 'a55fd8bd-730f-e511-80c0-000d3a207cb6',
      Name: '0111',
      Description:
        'Καλλιέργεια δημητριακών (εκτός ρυζιού), οσπρίων και ελαιούχων σπόρων',
    },
    {
      Id: 'd164d8bd-730f-e511-80c0-000d3a207cb6',
      Name: '7092',
      Description: 'Οικία',
    },
  ],
  PostalOffices: [
    {
      Id: '32482b34-6e0f-e511-80c0-000d3a207cb6',
      Code: '11744',
      City: 'ΑΘΗΝΑ',
      Prefecture: 'ΑΤΤΙΚΗΣ',
      NotConnected: false,
    },
    {
      Id: 'b6fff865-7028-e911-80ce-000d3a2e5fb2',
      Code: '11744',
      City: 'ΝΕΟΣ ΚΟΣΜΟΣ',
      Prefecture: 'ΑΤΤΙΚΗΣ',
      NotConnected: false,
    },
    {
      Id: '023deea2-6a4a-e911-80ce-000d3a2e5fb2',
      Code: '11744',
      City: 'ΑΘΗΝΑ',
      Prefecture: 'ΑΤΤΙΚΗΣ',
      NotConnected: false,
    },
    {
      Id: 'ac3beea2-6a4a-e911-80ce-000d3a2e5fb2',
      Code: '10020',
      City: 'ΑΘΗΝΑ',
      Prefecture: 'ΑΤΤΙΚΗΣ',
      NotConnected: false,
    },
    {
      Id: '215dbd0e-6b85-eb11-80d8-000d3a2e5fb2',
      Code: '10021',
      City: 'ΑΘΗΝΑ',
      Prefecture: '',
      NotConnected: false,
    },
    {
      Id: 'ae3beea2-6a4a-e911-80ce-000d3a2e5fb2',
      Code: '10023',
      City: 'ΑΘΗΝΑ',
      Prefecture: 'ΑΤΤΙΚΗΣ',
      NotConnected: false,
    },
    {
      Id: 'b03beea2-6a4a-e911-80ce-000d3a2e5fb2',
      Code: '10024',
      City: 'ΑΘΗΝΑ',
      Prefecture: 'ΑΤΤΙΚΗΣ',
      NotConnected: false,
    },
    {
      Id: '92f3fa7b-6b85-eb11-80d8-000d3a2e5fb2',
      Code: '10027',
      City: 'ΑΘΗΝΑ',
      Prefecture: 'ΑΤΤΙΚΗΣ',
      NotConnected: false,
    },
    {
      Id: '94f3fa7b-6b85-eb11-80d8-000d3a2e5fb2',
      Code: '10028',
      City: 'ΑΘΗΝΑ',
      Prefecture: 'ΑΤΤΙΚΗΣ',
      NotConnected: false,
    },
  ],
  Offices: [
    {
      TaxOfficeId: 'bce34523-740f-e511-80c0-000d3a207cb6',
      TaxOfficeName: 'Α ΑΘΗΝΩΝ',
    },
    {
      TaxOfficeId: 'bee34523-740f-e511-80c0-000d3a207cb6',
      TaxOfficeName: 'Α ΒΟΛΟΥ',
    },
    {
      TaxOfficeId: 'c0e34523-740f-e511-80c0-000d3a207cb6',
      TaxOfficeName: 'Α ΘΕΣΣΑΛΟΝΙΚΗΣ',
    },
    {
      TaxOfficeId: 'c2e34523-740f-e511-80c0-000d3a207cb6',
      TaxOfficeName: 'Α ΙΩΑΝΝΙΝΩΝ',
    },
    {
      TaxOfficeId: 'c4e34523-740f-e511-80c0-000d3a207cb6',
      TaxOfficeName: 'Α ΚΑΒΑΛΑΣ',
    },
    {
      TaxOfficeId: 'c6e34523-740f-e511-80c0-000d3a207cb6',
      TaxOfficeName: 'Α ΚΑΛΛΙΘΕΑΣ',
    },
    {
      TaxOfficeId: 'bb1c70c6-a46a-eb11-80d8-000d3a2e5fb2',
      TaxOfficeName: 'Α ΚΑΛΛΙΘΕΑΣ',
    },
    {
      TaxOfficeId: 'c8e34523-740f-e511-80c0-000d3a207cb6',
      TaxOfficeName: 'Α ΚΑΤΕΡΙΝΗΣ',
    },
    {
      TaxOfficeId: 'cae34523-740f-e511-80c0-000d3a207cb6',
      TaxOfficeName: 'Α ΚΕΡΚΥΡΑΣ',
    },
  ],
  Regions: [
    {
      RegionId: '119a1414-7c0f-e511-80c0-000d3a207cb6',
      RegionCode: 1,
      RegionName: 'Περιφέρειας Αττικής',
    },
    {
      RegionId: '139a1414-7c0f-e511-80c0-000d3a207cb6',
      RegionCode: 2,
      RegionName: 'Περιφέρεια Μακεδονίας Θράκης',
    },
    {
      RegionId: '159a1414-7c0f-e511-80c0-000d3a207cb6',
      RegionCode: 3,
      RegionName: 'Περιφέρεια Πελ/σου Ηπείρου',
    },
    {
      RegionId: '179a1414-7c0f-e511-80c0-000d3a207cb6',
      RegionCode: 4,
      RegionName: 'Περιφέρεια Κεντρικής Ελλάδας',
    },
  ],
  CustomerTypes: [
    {
      CustomerTypeId: '6f21a111-7e8c-e911-80d0-000d3a2e5fb2',
      CustomerTypeName: 'Εταιρεία',
    },
    {
      CustomerTypeId: '92962424-7e8c-e911-80d0-000d3a2e5fb2',
      CustomerTypeName: 'Ιδιώτης',
    },
  ],
  ConsumptionPurposes: [
    {
      ConsumptionPurposeId: '62546a1a-7819-e811-80c9-000d3a2e5fb2',
      ConsumptionPurposeName: 'ΑΤΟΜΙΚΗ ΘΕΡΜ.+ΖΕΣΤΟ ΝΕΡΟ',
    },
    {
      ConsumptionPurposeId: '66546a1a-7819-e811-80c9-000d3a2e5fb2',
      ConsumptionPurposeName: 'ΑΤΟΜΙΚΗ ΘΕΡΜ.+ΚΛΙΜΑΤΙΣΜΟΣ',
    },
    {
      ConsumptionPurposeId: '60546a1a-7819-e811-80c9-000d3a2e5fb2',
      ConsumptionPurposeName: 'ΑΤΟΜΙΚΗ ΘΕΡΜ.+ΚΟΥΖ.+ΖΕΣΤΟ ΝΕΡΟ',
    },
    {
      ConsumptionPurposeId: '5e546a1a-7819-e811-80c9-000d3a2e5fb2',
      ConsumptionPurposeName: 'ΑΤΟΜΙΚΗ ΘΕΡΜ.+ΚΟΥΖΙΝΑ',
    },
    {
      ConsumptionPurposeId: '5c546a1a-7819-e811-80c9-000d3a2e5fb2',
      ConsumptionPurposeName: 'ΖΕΣΤΟ ΝΕΡΟ',
    },
  ],
  GasTariffgroups: [
    {
      GasTariffgroupId: '5b62c4cd-c386-e911-80cf-000d3a2e5fb2',
      GasTariffgroupName: 'DOUBLE ΟΙΚΙΑΚΗ ΑΥΤΟΝΟΜΗ',
    },
    {
      GasTariffgroupId: '94f010e8-99c0-e811-80cb-000d3a2e5fb2',
      GasTariffgroupName: 'OIKIAKH ΑΥΤΟΝΟΜΗ',
    },
    {
      GasTariffgroupId: '2223f8c9-99c0-e811-80cb-000d3a2e5fb2',
      GasTariffgroupName: 'OIKIAKH ΚΕΝΤΡΙΚΗ',
    },
    {
      GasTariffgroupId: 'cb362124-9ac0-e811-80cb-000d3a2e5fb2',
      GasTariffgroupName: 'ΒΙΟΜΗΧΑΝΙΚΗ',
    },
    {
      GasTariffgroupId: 'a0db0aee-99c0-e811-80cb-000d3a2e5fb2',
      GasTariffgroupName: 'ΕΜΠΟΡΙΚΗ (<200 MWh/y)',
    },
    {
      GasTariffgroupId: '8ad501fa-99c0-e811-80cb-000d3a2e5fb2',
      GasTariffgroupName: 'ΕΜΠΟΡΙΚΗ (>200 MWh/y)',
    },
    {
      GasTariffgroupId: '6e692836-9ac0-e811-80cb-000d3a2e5fb2',
      GasTariffgroupName: 'ΚΛΙΜΑΤΙΣΜΟΣ/ΣΥΜΠΑΡΑΓΩΓΗ',
    },
  ],
  GasCommunities: [
    {
      GasCommunityId: 'a3b6c5bb-7919-e811-80c9-000d3a2e5fb2',
      GasCommunityName: '--',
    },
    {
      GasCommunityId: 'f1b6c5bb-7919-e811-80c9-000d3a2e5fb2',
      GasCommunityName: 'EΛΑΣΣΟΝΑ',
    },
    {
      GasCommunityId: '1d8b33c4-37c2-e811-80cb-000d3a2e5fb2',
      GasCommunityName: 'ΑΒΔΗΡΩΝ',
    },
    {
      GasCommunityId: '91b6c5bb-7919-e811-80c9-000d3a2e5fb2',
      GasCommunityName: 'ΑΓ.ΠΑΥΛΟΣ',
    },
    {
      GasCommunityId: '1f8b33c4-37c2-e811-80cb-000d3a2e5fb2',
      GasCommunityName: 'ΑΓΑΘΟΝΗΣΙΟΥ',
    },
    {
      GasCommunityId: '218b33c4-37c2-e811-80cb-000d3a2e5fb2',
      GasCommunityName: 'ΑΓΙΑ ΠΑΡΑΣΚΕΥΗ',
    },
    {
      GasCommunityId: 'b5a0e372-e71c-ea11-80d2-000d3a2e5fb2',
      GasCommunityName: 'ΑΓΙΑ ΠΑΡΑΣΚΕΥΗ',
    },
    {
      GasCommunityId: '44aa9597-e71c-ea11-80d2-000d3a2e5fb2',
      GasCommunityName: 'ΑΓΙΑΣ',
    },
    {
      GasCommunityId: '238b33c4-37c2-e811-80cb-000d3a2e5fb2',
      GasCommunityName: 'ΑΓΙΑΣ ΑΝΝΑΣ',
    },
  ],
  DeliveryPointCategories: [
    {
      DeliveryPointCategoryId: '7984ed01-7619-e811-80c9-000d3a2e5fb2',
      DeliveryPointCategoryName: 'Eμπορικός',
    },
    {
      DeliveryPointCategoryId: '7784ed01-7619-e811-80c9-000d3a2e5fb2',
      DeliveryPointCategoryName: 'Βιομηχανικός',
    },
    {
      DeliveryPointCategoryId: '7d84ed01-7619-e811-80c9-000d3a2e5fb2',
      DeliveryPointCategoryName: 'Οικιακός',
    },
    {
      DeliveryPointCategoryId: '7384ed01-7619-e811-80c9-000d3a2e5fb2',
      DeliveryPointCategoryName: 'Συμπαραγωγή/Κλιματισμός',
    },
  ],
  LegalOrgs: [
    {
      LegalOrgId: '81b610c5-7f8c-e911-80d0-000d3a2e5fb2',
      LegalOrgName: 'Catering',
    },
    {
      LegalOrgId: '83b610c5-7f8c-e911-80d0-000d3a2e5fb2',
      LegalOrgName: 'Eίδη ένδυσης',
    },
    {
      LegalOrgId: '85b610c5-7f8c-e911-80d0-000d3a2e5fb2',
      LegalOrgName: 'Eξοπλ (τηλε)επι',
    },
    {
      LegalOrgId: '87b610c5-7f8c-e911-80d0-000d3a2e5fb2',
      LegalOrgName: 'Kομμωτήριο',
    },
    {
      LegalOrgId: '89b610c5-7f8c-e911-80d0-000d3a2e5fb2',
      LegalOrgName: 'ΑΘ',
    },
    {
      LegalOrgId: '8bb610c5-7f8c-e911-80d0-000d3a2e5fb2',
      LegalOrgName: 'ΑΘ-ΖΝΧ',
    },
    {
      LegalOrgId: '8db610c5-7f8c-e911-80d0-000d3a2e5fb2',
      LegalOrgName: 'Αθλητικές εγκαταστάσεις',
    },
    {
      LegalOrgId: '8fb610c5-7f8c-e911-80d0-000d3a2e5fb2',
      LegalOrgName: 'Αθλητικό κέντρο',
    },
    {
      LegalOrgId: '91b610c5-7f8c-e911-80d0-000d3a2e5fb2',
      LegalOrgName: 'ΑΘ-ΜΑΓ-ΖΝΧ',
    },
    {
      LegalOrgId: '93b610c5-7f8c-e911-80d0-000d3a2e5fb2',
      LegalOrgName: 'Ακίνητη περιουσ',
    },
  ],
  Legalforms: [
    {
      LegalformId: 'f0f440cf-7e8c-e911-80d0-000d3a2e5fb2',
      LegalformName: 'A.E.',
    },
    {
      LegalformId: 'f2f440cf-7e8c-e911-80d0-000d3a2e5fb2',
      LegalformName: 'Δημόσιος Οργανισμός',
    },
    {
      LegalformId: 'f4f440cf-7e8c-e911-80d0-000d3a2e5fb2',
      LegalformName: 'Ε.Ε.',
    },
    {
      LegalformId: 'f6f440cf-7e8c-e911-80d0-000d3a2e5fb2',
      LegalformName: 'Ε.Π.Ε.',
    },
    {
      LegalformId: 'f8f440cf-7e8c-e911-80d0-000d3a2e5fb2',
      LegalformName: 'Επαγγελματίας',
    },
    {
      LegalformId: 'faf440cf-7e8c-e911-80d0-000d3a2e5fb2',
      LegalformName: 'Ιδιώτης',
    },
    {
      LegalformId: 'fcf440cf-7e8c-e911-80d0-000d3a2e5fb2',
      LegalformName: 'Ιδιωτική Κεφαλαιουχική Εταιρεία',
    },
    {
      LegalformId: 'fef440cf-7e8c-e911-80d0-000d3a2e5fb2',
      LegalformName: 'Κοινωνική Συνεταιριστική Επιχείρηση',
    },
    {
      LegalformId: '00f540cf-7e8c-e911-80d0-000d3a2e5fb2',
      LegalformName: 'ΝΠΔΔ',
    },
    {
      LegalformId: '02f540cf-7e8c-e911-80d0-000d3a2e5fb2',
      LegalformName: 'Ο.Ε.',
    },
    {
      LegalformId: '04f540cf-7e8c-e911-80d0-000d3a2e5fb2',
      LegalformName: 'Πρεσβεία',
    },
  ],
  ResponseCode: 200,
  ResponseMessage: 'OK',
};
