import React from 'react';

import styles from './CouponDate.module.scss';

const CouponDate = ({ statusCircle, description, date }) => {
  return (
    <div className={styles.descriptionAndDateContainer}>
      {statusCircle ? (
        <div className={styles.circleDateContainer}>
          <span className={styles[`couponStatusCircle-${statusCircle}`]} />
          <p className={styles.dateCaption}>{description}</p>
        </div>
      ) : (
        <p className={styles.dateCaption}>{description}</p>
      )}

      <p className={styles.date}>{date || '-'}</p>
    </div>
  );
};

export default CouponDate;
