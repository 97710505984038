import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import { usePromotion } from 'data/usePromotion';
import { buttonActions, cmsSchemas } from 'utils/product/enums/cmsEnums';
import { trackOfferEvent } from 'utils/product/tracking/events';
import {
  ExternalLinkButton,
  InternalLinkButton,
} from 'components/Offers/OfferElements';

import styles from './PromotionWidget.module.scss';

const PromotionWidget = ({ promotionType, closeDialog }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const {
    data: { content: promotionInfo } = {},
    forYouItem,
    loading,
  } = usePromotion(i18n.language, promotionType);

  const {
    button: { buttonTitle: ctaText, buttonAction: ctaType, url: ctaValue } = {},
  } = promotionInfo || {};

  return (
    <WidgetSkeleton
      show={loading}
      background="selected"
      heightInPixels={304}
      distance=""
    >
      <section
        className={`${styles.promotion} ${
          promotionInfo?.isCardClickable && styles['promotion-clickable']
        }`}
        onClick={() => {
          if (promotionInfo?.isCardClickable) {
            const path =
              promotionInfo?.discoveryItem?.schema === cmsSchemas.offers
                ? `/offers`
                : promotionInfo?.discoveryItem?.schema === cmsSchemas.contests
                ? `/contests`
                : promotionInfo?.discoveryItem?.schema === cmsSchemas.games
                ? `/games`
                : '';
            if (path) {
              navigate(
                `${path}/${forYouItem?.content?.slug || forYouItem?.id}`
              );
              if (typeof closeDialog === 'function') closeDialog();
            }
          }
        }}
      >
        <img
          src={promotionInfo?.image?.url}
          alt="promo"
          width="100%"
          height="224"
        />
        <article>
          <h3>{promotionInfo?.title}</h3>
        </article>

        {ctaType === buttonActions.externalUrl ||
        ctaType === buttonActions.externalUrlInApp ? (
          <div className={styles.ctaContainer}>
            <ExternalLinkButton
              clickHandler={() => {
                trackOfferEvent('offer_button_selected', promotionInfo?.title);
                if (typeof closeDialog === 'function') closeDialog();
              }}
              url={ctaValue}
              text={ctaText}
            />
          </div>
        ) : (
          ctaType === buttonActions.internalUrl && (
            <div className={styles.ctaContainer}>
              <InternalLinkButton
                clickHandler={() => {
                  trackOfferEvent(
                    'offer_button_selected',
                    promotionInfo?.title
                  );
                  if (typeof closeDialog === 'function') closeDialog();
                }}
                url={ctaValue}
                text={ctaText}
              />
            </div>
          )
        )}
      </section>
    </WidgetSkeleton>
  );
};

export default PromotionWidget;
