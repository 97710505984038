import React from 'react';
import { useTranslation } from 'react-i18next';
import IBAN from 'iban';
import get from 'lodash/get';
import { isEmptyString } from 'utils/stringUtils';

import { StandardInput } from './Inputs';

import styles from './IbanInput.module.scss';

const IbanInput = ({
  register,
  errors,
  name = 'iban',
  label,
  required = false,
  ...rest
}) => {
  const { t } = useTranslation(['inputs']);
  const baseName = 'inputs:errors';

  const { ref, ...registerRest } =
    register(name, {
      required: { value: required, message: t(`${baseName}.required`) },
      validate: (value) => {
        if (!required && isEmptyString(value)) return true;
        return IBAN.isValid(value);
      },
    }) || {};

  return (
    <section>
      <StandardInput
        inputRef={ref}
        label={label}
        type="text"
        errorMessage={get(errors, `${name}.message`, null)}
        error={get(errors, `${name}`, null) !== null}
        {...rest}
        {...registerRest}
      />
      <p className={styles.info}>{t(`inputs:iban`)}</p>
    </section>
  );
};

export { IbanInput };
