import React from 'react';

import styles from './ConsumptionLayouts.module.scss';

const RegisterOwnReadingsLayout = ({ children }) => {
  return (
    <section className={styles.rowLayout}>
      <section className={styles.upper}>
        {children.info && (
          <section className={styles['upper-right']}>{children.info}</section>
        )}
        <section
          className={styles[children.info ? 'upper-left' : 'upper-full']}
        >
          {children.message}
        </section>
      </section>
      <section className={styles.bottom}>
        <section className={styles['bottom-left']}>
          {children.secondaryButton}
        </section>
        <section className={styles['bottom-right']}>
          {children.primaryButton}
        </section>
      </section>
    </section>
  );
};

const DefaultLayout = ({ children }) => {
  return (
    <section className={styles.columnLayout}>
      <section className={styles.right}>{children.info}</section>
      <section className={styles.left}>
        {children.message}
        {children.secondaryButton}
      </section>
    </section>
  );
};

export { RegisterOwnReadingsLayout, DefaultLayout };
