import { useTranslation } from 'react-i18next';
import { historyEventTypes } from 'utils/product/enums/waveEnums';

const HistoryEventDescription = ({ item }) => {
  const { t } = useTranslation(['translation', 'loyalty']);

  return item.transaction.type === historyEventTypes.CREDIT
    ? item.eventDescription
    : t(`loyalty:history.offerType.${item.offerType}`, {
        title: item.eventDescription,
      });
};

export default HistoryEventDescription;
