import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useError from 'hooks/useError';
import { setContactInfo } from 'api/account';
import { trackProfileInfoEvent } from 'utils/product/tracking/events';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import { EmailInput } from 'components/Inputs/EmailInput';
import { TextInput } from 'components/Inputs/TextInput';
import { TelephoneInput } from 'components/Inputs/TelephoneInput';
import IconPopover from 'components/Popover/IconPopover';
import { ReactComponent as InfoIconFilled } from 'assets/icons/InfoCircleIcon.svg';
import {
  SuccessBox,
  LocalisedErrorBox,
} from 'components/MessageBox/MessageBox';
import { useAuthUser } from 'utils/AuthUser';

import styles from './PersonalInfoBody.module.scss';

const prepareObject = (data, contactId, accountId) => {
  const { email, telephone } = data;
  return {
    AccountId: accountId,
    ContactId: contactId,
    Salutation: '-',
    FirstName: '-',
    LastName: '-',
    MobilePhone: telephone,
    OtherPhone: '-',
    Email: email,
  };
};

const PersonalInfoBody = ({ primaryContact }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstname: primaryContact?.FirstName,
      lastname: primaryContact?.LastName,
      email: primaryContact?.Email,
      telephone: primaryContact?.MobilePhone,
    },
    values: {
      firstname: primaryContact?.FirstName,
      lastname: primaryContact?.LastName,
      email: primaryContact?.Email,
      telephone: primaryContact?.MobilePhone,
    },
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
  });

  const { t } = useTranslation(['management', 'onboarding']);
  const baseName = 'management:personalInfo';
  const { accountId, mutateAccount } = useAuthUser();
  const [defaultValues, setDefaultValues] = useState({
    email: primaryContact?.Email,
    telephone: primaryContact?.MobilePhone,
  });
  const [loading, setLoading] = useState(false);
  const { error, handleError, clearError } = useError();

  const [isSuccess, setIsSuccess] = useState(false);

  const onSubmit = (data) => {
    const formValues = { email: data.email, telephone: data.telephone };
    setIsSuccess(false);
    setLoading(true);
    const object = prepareObject(data, primaryContact?.Id, accountId);
    if (checkValuesChanged(formValues)) {
      handleError('form-is-same', 'forms');
      setLoading(false);
      return;
    }
    setContactInfo(object)
      .then(() => {
        setDefaultValues(formValues);
        setIsSuccess(true);
        clearError();
        mutateAccount();
      })
      .catch(() => {
        handleError('general', 'personalInfo');
        setIsSuccess(false);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const checkValuesChanged = (values) =>
    JSON.stringify(values) === JSON.stringify(defaultValues);

  return (
    <>
      <h2 className={styles.header}>{t(`${baseName}.edit-personal-info`)}</h2>
      <SuccessBox
        open={isSuccess}
        closeBox={() => setIsSuccess(false)}
        message={t(`${baseName}.submit-change-success`)}
      />
      <LocalisedErrorBox
        open={error !== null}
        closeBox={clearError}
        {...(error || {})}
        distanceTop=""
      />
      <form
        className={styles.form}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <TextInput
          register={register}
          errors={errors}
          name="firstname"
          label={t(`${baseName}.firstname`)}
          disabled
        />
        <TextInput
          register={register}
          errors={errors}
          name="lastname"
          label={t(`${baseName}.lastname`)}
          disabled
        />
        <EmailInput
          register={register}
          errors={errors}
          label={t('onboarding:general.contact-email')}
          endAdornment={
            <IconPopover
              id={`email-popover`}
              content={t(`${baseName}.email-info`)}
              icon={<InfoIconFilled />}
              popoverClass={styles.popover}
              placement="bottom-end"
              buttonClass={styles.infoIcon}
            />
          }
        />
        <TelephoneInput
          register={register}
          errors={errors}
          name="telephone"
          label={t(`onboarding:createUserContent.telephone`)}
          mobileOnly={true}
        />
        <section className={styles.submit}>
          <PrimaryButtonLoader
            loading={loading}
            disabled={loading}
            type="submit"
            onClick={() =>
              trackProfileInfoEvent('profile_personal_info_submit')
            }
          >
            {t(`${baseName}.submit-changes`)}
          </PrimaryButtonLoader>
        </section>
      </form>
    </>
  );
};

export default PersonalInfoBody;
