import axios from 'axios';

export const fetcher = (url, lang) =>
  api
    .get(url, {
      headers: { 'Accept-Language': lang === 'en' ? 'en-US' : 'el-GR' },
    })
    .then((res) => res.data);

const api = axios.create({
  baseURL: process.env.REACT_APP_CMS_URL,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_CMS_KEY}`,
    'Lucent-Channel': process.env.REACT_APP_CMS_CHANNEL,
  },
});

export default api;
