import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import WhatsNewNewClient from 'components/WhatsNew/WhatsNewNewClient';
import Login from 'pages/onboarding/Login/Login';
import OnboardingFormProvider from './OnboardingFormProvider';
import OnboardingFlowDialog from './OnboardingFlowDialog';

const OnboardingRouter = () => {
  return (
    <Login
      dialogComponent={
        <Routes>
          <Route path="/" element={<Navigate to="whats-new" />} />
          <Route path="whats-new" element={<WhatsNewNewClient />} />
          <Route
            path="*"
            element={
              <OnboardingFormProvider>
                <OnboardingFlowDialog />
              </OnboardingFormProvider>
            }
          />
        </Routes>
      }
      head={{
        title: 'e-contract',
        metaTags: [{ name: 'title', content: 'e-contract' }],
      }}
    />
  );
};

export default OnboardingRouter;
