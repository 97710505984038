import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'utils/AppContext';
import cookiesStorage from 'utils/storage/cookiesStorage';

import { trackProfileEvent } from 'utils/product/tracking/events';
import { PrimaryButton } from 'components/Buttons/Buttons';
import SwitchButton from 'components/Inputs/Switch';
import { SuccessDialog } from 'components/PromptDialogs/PromptDialog';
import styles from './CookiesManagement.module.scss';
import useDialog from 'hooks/useDialog';

export default function CookiesManagement() {
  const { t } = useTranslation(['cookies']);
  const { saveCookies, acceptCookies, rejectCookies } =
    React.useContext(AppContext);

  const successDialog = useDialog();
  const [cookiesConsent, setCookiesConsent] = useState(
    cookiesStorage.getCookiesConsent()
  );

  const updatePerformanceConsent = (value) => {
    setCookiesConsent({ ...cookiesConsent, performance: value });
  };

  const updateAdvertisingConsent = (value) => {
    setCookiesConsent({ ...cookiesConsent, ads: value });
  };

  const acceptAll = () => {
    setCookiesConsent({ ...cookiesConsent, performance: true, ads: true });
    acceptCookies();
    successDialog.show();
    trackProfileEvent('profile_cookies_accept', '', {}, true);
  };
  const rejectAll = () => {
    trackProfileEvent('profile_cookies_deny');
    setCookiesConsent({ ...cookiesConsent, performance: false, ads: false });
    rejectCookies();
    successDialog.show();
  };

  const save = () => {
    saveCookies({
      performance: cookiesConsent.performance,
      ads: cookiesConsent.ads,
    });
    successDialog.show();
    trackProfileEvent(
      'profile_cookies_save',
      '',
      {},
      cookiesConsent.performance
    );
  };

  return (
    <section className={styles.container} id="cookies_management">
      <SwitchButton
        containerClass={styles.item}
        name="functional"
        isChecked={true}
        isDisabled={true}
        label={t(`cookies:functional.title`)}
      />
      <SwitchButton
        containerClass={styles.item}
        name="performance"
        isChecked={cookiesConsent.performance}
        label={t(`cookies:performance.title`)}
        onChange={updatePerformanceConsent}
      />
      <SwitchButton
        containerClass={styles.item}
        name="ads"
        isChecked={cookiesConsent.ads}
        label={t(`cookies:advertise.title`)}
        onChange={updateAdvertisingConsent}
      />
      <div className={styles.buttonContainer}>
        <PrimaryButton className={styles.actionButton} onClick={acceptAll}>
          {t('cookies:accept')}
        </PrimaryButton>
        <PrimaryButton className={styles.actionButton} onClick={rejectAll}>
          {t('cookies:reject')}
        </PrimaryButton>
        <PrimaryButton className={styles.actionButton} onClick={save}>
          {t('cookies:save')}
        </PrimaryButton>
      </div>
      <SuccessDialog
        open={successDialog.isOpen}
        closeDialog={successDialog.close}
        header={t('translation:completion')}
        message={t('cookies:changes-saved')}
      />
    </section>
  );
}
