import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorDialog } from 'components/PromptDialogs/PromptDialog';
import paymentResultTypes from 'utils/product/enums/paymentResultTypes';

const PaymentError = ({ open, closeDialog, paymentResult, ...rest }) => {
  const { t } = useTranslation(['bills']);

  const { paymentResultType } = paymentResult || {};

  const messageKey =
    paymentResultType === paymentResultTypes.recurringFailure
      ? 'recurring-fail-message'
      : paymentResultType === paymentResultTypes.recurringError
        ? 'recurring-error-message'
        : 'fail-message';

  return (
    <ErrorDialog
      open={open}
      closeDialog={closeDialog}
      header={t(`bills:payment-flow.fail-title`)}
      message={t(`bills:payment-flow.${messageKey}`)}
      {...rest}
    />
  );
};

export default PaymentError;
