import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getObjectValues } from 'utils/objectUtils';

import UploadWidget from 'components/UploadWidget/UploadWidget';
import {
  getRequiredFileTypes,
  documents,
} from 'utils/product/onboarding/getRequiredFileTypes';

import styles from './Documents.module.scss';

const Documents = ({ defaultValues, onFileChange, ...props }) => {
  const { t } = useTranslation(['newClient']);
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const [filesNeeded] = useState(getRequiredFileTypes(defaultValues));

  useEffect(() => {
    getObjectValues(documents).forEach((docName) => {
      if (defaultValues[docName.name] !== null)
        setValue(docName.name, defaultValues[docName.name]);
    });
  }, [defaultValues, setValue]);

  return (
    <article className={styles.container}>
      <h3 className={styles.header}>{t(`newClient:documents.header`)}</h3>
      {filesNeeded &&
        filesNeeded.map(({ name: filename, type, isRequired }) => (
          <UploadWidget
            key={filename}
            label={t(`newClient:documents.${filename}`)}
            existingFileName={
              defaultValues[filename]?.length > 0
                ? defaultValues[filename][0].name
                : ''
            }
            required={isRequired}
            register={register}
            name={filename}
            errors={errors}
            onFileChange={(files) => onFileChange(files, filename, type)}
          />
        ))}
    </article>
  );
};

export default Documents;
