import React from 'react';

import EuroContainer from 'components/EuroContainer/EuroContainer';
import styles from './BillsDetailsTextField.module.scss';

const BillsDetailsTextField = ({
  content,
  Icon,
  amount,
  weight = 'regular',
  amountWeight = 'regular',
  euro = true,
  containerClass = '',
}) => {
  return (
    <div className={`${styles[`container-${weight}`]} ${containerClass}`}>
      <div className={styles.content}>{content}</div>
      {Icon}
      {euro && (
        <EuroContainer
          content={amount}
          outterClass={styles[`amount-${amountWeight}`]}
        />
      )}
      {!euro && (
        <div className={styles[`amount-${amountWeight}`]}> {amount}</div>
      )}
    </div>
  );
};

export default BillsDetailsTextField;
