import Big from 'big.js';
import { getDifferenceInDays } from 'utils/dateUtils';
import commodities from './enums/commodities';

export const transformLastInvoiceFields = (invoice) => {
  if (!invoice) return null;
  return {
    Id: invoice.Id,
    id: invoice.Id,
    invoiceNo: invoice?.InvoiceNo,
    issueDate: invoice?.InvoiceDate,
    dateFrom: invoice?.BillingPeriodStart,
    dateTo: invoice?.BillingPeriodEnd,
    days: getDifferenceInDays(
      invoice?.BillingPeriodStart,
      invoice?.BillingPeriodEnd,
      { includeLastDay: true },
    ),
    previousAmount: invoice?.PreviousUnpaidInvoices,
    totalCurrentAmount: new Big(invoice?.PaymentAmount ?? 0)
      .minus(new Big(invoice?.PreviousUnpaidInvoices ?? 0))
      .toNumber(),
    dueDate: invoice?.PaymentDueDate,
    invoiceType: invoice?.InvoiceType,
    grandTotal: invoice?.PaymentAmount,
    paymentCode: invoice.PaymentCode,
    PaymentDate: invoice.PaymentDate,
    LvmeasuremenActive: invoice.LvmeasuremenActive,
  };
};

export const invoiceCRMPreprocessing = (invoiceOverview) => {
  if (!invoiceOverview) return null;

  return {
    Commodity: invoiceOverview.Commodity,
    FriendlyName: invoiceOverview.FriendlyName,
    MeterId: invoiceOverview.MeterId,
    MeterNo: invoiceOverview.MeterNo,
    id: invoiceOverview?.Id,
    invoiceNo: invoiceOverview?.InvoiceNo,
    meterId: invoiceOverview?.MeterId,
    invoiceType: invoiceOverview?.InvoiceType,
    grandTotal: invoiceOverview?.PaymentAmount,
    totalCurrentAmount: new Big(invoiceOverview?.PaymentAmount ?? 0)
      .minus(new Big(invoiceOverview?.PreviousUnpaidInvoices ?? 0))
      .toNumber(),
    previousAmount: invoiceOverview?.PreviousUnpaidInvoices,
    issueDate: invoiceOverview?.InvoiceDate,
    dateFrom: invoiceOverview?.BillingPeriodStart,
    dateTo: invoiceOverview?.BillingPeriodEnd,
    days: getDifferenceInDays(
      invoiceOverview?.BillingPeriodStart,
      invoiceOverview?.BillingPeriodEnd,
      { includeLastDay: true },
    ),
    dueDate: invoiceOverview?.PaymentDueDate,
    ena: invoiceOverview?.SolarisParticipationEuro,
    paymentCode: invoiceOverview?.PaymentCode,
  };
};

export const transformIDocsInvoice = (invoice, meter) => {
  if (!invoice || !meter) return null;

  return {
    ...invoice,
    id: invoice.invoiceNo,
    MeterNo: meter?.MeterNo,
    MeterId: meter?.Id,
    Commodity: meter?.Commodity,
    FriendlyName: meter?.FriendlyName,
  };
};

export const transformToIDocsMeterNo = (meterSupplyNo) => {
  if (!meterSupplyNo || typeof meterSupplyNo !== 'string') return '';

  return meterSupplyNo?.slice(0, -2)?.replace(/ /g, '-') ?? '';
};

export const checkIfInvoiceBelongsToMeter = (invoice, commodity, meter) => {
  const invoiceMeterNo = invoice?.meterNo?.replace(/-/g, ' ');

  return commodity === commodities.gas
    ? meter?.Id === invoice?.meterId
    : meter?.MeterSupplyNo?.indexOf(invoiceMeterNo) > -1;
};
