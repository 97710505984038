import React, { useState, useEffect, createElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import useNewClientChoices from 'hooks/useNewClientChoices';
import { logOnboardingError } from 'utils/product/logs';
import { getObjectKeys } from 'utils/objectUtils';
import useRequestFormData from 'hooks/useRequestFormData';
import {
  newClientSteps,
  existingClientSteps,
} from 'utils/product/onboardingConfig';
import energyTypes from 'utils/product/enums/shopEnergyTypes';
import { getComponents } from 'utils/product/onboarding/componentsMapping';
import {
  saveElectricityRequest,
  uploadFiles,
  newGasSupplyRequest,
  saveGasRequest,
} from 'api/onboarding';
import {
  showContactDialog,
  getRequestsToSend,
  getContactDetailsForPromotion,
} from 'utils/product/onboarding/helpers';
import { getSupplyNumber } from 'utils/product/onboarding/helpers';
import { setUserRegister } from 'api/user';
import { requestTypes } from 'utils/product/enums/requests';
import {
  trackNewContractEvent,
  trackOnboardingEvent,
} from 'utils/product/tracking/events';
import { trackEContractResultEvent } from 'utils/product/tracking/onboardingEvents';
import { trackEcontractCompleteFbEvent } from 'utils/product/tracking/fbEvents';
import { trackEcontractCompleteAdEvent } from 'utils/product/tracking/adEvents';
import {
  getCustomerCategory,
  getShopOrOnboardingType,
} from 'utils/product/tracking/trackingLabels';
import { scrollTo } from 'utils/scroll';
import useDialog from 'hooks/useDialog';
import useError from 'hooks/useError';
import { getRequiredFilesToSend } from 'utils/product/onboarding/getRequiredFileTypes';

import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import ShopSelector from 'components/OnboardingNewClient/FindProgram/ShopSelector';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import FindProgramBody from 'components/OnboardingNewClient/FindProgram/FindProgramBody';
import ProgramDetails from 'components/OnboardingNewClient/FindProgram/ProgramDetails';
import OnboardingSuccessDialog from 'components/OnboardingNewClient/Shared/OnboardingSuccessDialog';
import OnboardingStepper from './OnboardingStepper';
import ConsentDialog from './Shared/ConsentDialog';
import {
  FileUploadErrorDialog,
  RegistrationErrorDialog,
  CreateRequestErrorDialog,
} from './OnboardingFlowErrorDialogs';
import { useAuthUser } from 'utils/AuthUser';
import { useCollectionOptions } from 'data/useCollectionOptions';
import { getWaveId } from 'utils/product/loyalty/pointsUtils';
import { collectionOptionTypes } from 'utils/product/enums/cmsEnums';
import { receivePoints } from 'api/loyalty';
import { getRewardPoints } from 'utils/product/loyalty/pointsUtils';
import { trackShopEvent } from 'utils/product/tracking/loyaltyEvents';
import commodities from 'utils/product/enums/commodities';

import styles from './OnboardingFlowDialog.module.scss';

const isShopStep = (step, steps) =>
  step === steps.energyShop || step === steps.gasShop;

const isProgramStep = (step, steps) =>
  step === steps.energyProgram || step === steps.gasProgram;

const getClassPrefix = (step, steps) =>
  isShopStep(step, steps)
    ? step === steps.energyShop
      ? 'shop-energy'
      : 'shop'
    : isProgramStep(step, steps)
      ? 'program'
      : 'general';

const getChoices = (choices, steps, step) =>
  choices[getObjectKeys(steps)[step]] || {};

const OnboardingFlowDialog = ({
  isShop = false,
  user = null,
  step,
  goForward,
  goBack,
  nextRouteData,
  setNextRouteData,
  defaultValues,
  onChecked,
  onUpdate,
}) => {
  const { t } = useTranslation('newClient');
  const navigate = useNavigate();
  const formMethods = useFormContext();

  const choices = useNewClientChoices(nextRouteData);
  const formLabels = useRequestFormData(requestTypes.newGasSupply);
  const {
    programType,
    energyType: programEnergyType,
    programName,
  } = useParams();

  const location = useLocation();
  const steps = isShop ? existingClientSteps : newClientSteps;
  const [open, setOpen] = useState(true);
  const [contractId, setContractId] = useState(null);
  const [contractΝο, setContractΝο] = useState(null);
  const {
    error: requestError,
    handleError: handleRequestError,
    clearError: clearRequestError,
  } = useError();
  const requestErrorDialog = useDialog();
  const registrationErrorDialog = useDialog();
  const uploadErrorDialog = useDialog();
  const consentDialog = useDialog();
  const [isLoading, setIsLoading] = useState(false);
  const {
    error: gasRequestError,
    handleError: handleGasRequestError,
    clearError: clearGasRequestError,
  } = useError();

  const [requestsToSend, setRequestsToSend] = useState([]);
  const onboardingSuccessDialog = useDialog();
  const [contactDetails, setContactDetails] = useState({});

  const _isProgramStep = isProgramStep(step, steps);
  const _classPrefix = getClassPrefix(step, steps);
  const _isShopStep = isShopStep(step, steps);

  const {
    title,
    combinedTitle,
    cta,
    items,
    info,
    image,
    components,
    componentProps = {},
  } = getChoices(choices, steps, step);

  const { isLoyaltyCustomer, loyaltyCustomer } = useAuthUser();
  const [pointsEarned, setPointsEarned] = useState(0);

  const { collectionOptionsByType } = useCollectionOptions();
  const waveId = getWaveId(collectionOptionsByType, collectionOptionTypes.SHOP);

  const getUpdatedData = (data) => {
    const _copy = cloneDeep(nextRouteData);
    return merge(_copy, data);
  };

  const sendNewGasSupplyRequest = (data) => {
    setIsLoading(true);
    newGasSupplyRequest(data, formLabels)
      .then((result) => {
        if (result.data.ResponseCode === 200) {
          goForward(nextRouteData);
          return;
        }
      })
      .catch((error) => {
        handleGasRequestError(error);
        logOnboardingError(error, 'new gas supply');
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const onSubmit = (data) => {
    const newData = getUpdatedData(data);
    if (step > steps.combinedInfo) setNextRouteData(newData);

    if (showContactDialog(isShop, step, steps, nextRouteData)) {
      setContactDetails(getContactDetailsForPromotion(isShop, user, newData));
      consentDialog.show();
      return;
    }

    if (step === steps.newGasSupply) {
      sendNewGasSupplyRequest(newData);
      return;
    }

    if (step === steps.documents) {
      setNextRouteData(newData);
      setIsLoading(true);
      trackNewContractEvent('onboarding_extra_documents_continue', '', {
        shop_or_onboarding_type: getShopOrOnboardingType(isShop),
        energy_type: newData.energyType,
        customer_type: getCustomerCategory(newData.customerCategory),
      });

      const _requests = getRequestsToSend(newData, isShop);
      setRequestsToSend(_requests);
      send(newData, _requests);
      return;
    }

    goForward(newData);
  };

  const send = (newData, _requests, _requestId, _requestNo) => {
    if (_requests?.length === 0) {
      setOpen(false);
      setContactDetails(getContactDetailsForPromotion(isShop, user, newData));
      trackEcontractCompleteFbEvent();
      trackEcontractCompleteAdEvent();
      if (isLoyaltyCustomer) {
        receiveLoyaltyPoints(newData)
          .then((loyaltyResponse) => {
            const _pointsEarned = getRewardPoints(
              loyaltyResponse?.data,
              waveId,
            );
            setPointsEarned(_pointsEarned);
            onboardingSuccessDialog.show();
            trackShopEvent('shop_program_success_with_points');
          })
          .catch((error) => {});
        return;
      }
      onboardingSuccessDialog.show();
      return;
    }
    const nextRequest = _requests[0];

    if (nextRequest === 'elRequest') {
      sendElRequest(newData, _requests);
      return;
    }
    if (nextRequest === 'elFiles') {
      uploadElFiles(newData, _requests, _requestId, _requestNo);
      return;
    }
    if (nextRequest === 'gasRequest') {
      sendGasRequest(newData, _requests);
      return;
    }
    if (nextRequest === 'gasFiles') {
      uploadGasFiles(newData, _requests, _requestId, _requestNo);
      return;
    }

    if (nextRequest === 'register') {
      register(newData, _requests);
    }
  };

  const receiveLoyaltyPoints = (newData) => {
    setPointsEarned(0);
    // items: [meterNo]
    // send 2 items for combo programs in order to get double points
    const items =
      newData.energyType === energyTypes.combined && newData.gas === 'true'
        ? [
            {
              id: getSupplyNumber(newData?.energyProgram?.meterNo),
            },
            {
              id: getSupplyNumber(newData?.gasProgram?.meterNo),
            },
          ]
        : newData.energyType === energyTypes.electricity ||
            newData.energyType === energyTypes.combinedElectricity
          ? [
              {
                id: getSupplyNumber(newData?.energyProgram?.meterNo),
              },
            ]
          : [
              {
                id: getSupplyNumber(newData?.gasProgram?.meterNo),
              },
            ];

    const postData = {
      customer: {
        customerId: loyaltyCustomer.customerId,
      },
      type: 'CUSTOM',
      ruleId: waveId,
      items: items,
    };

    return receivePoints(postData);
  };

  const sendElRequest = (newData, _requestsToSend) => {
    saveElectricityRequest(newData, isShop, user)
      .then((result) => {
        if (
          result.data.ResponseCode === 200 &&
          result.data.CrmContractId &&
          result.data.CrmContractNo
        ) {
          setContractId(result.data.CrmContractId);
          setContractΝο(result.data.CrmContractNo);
          const _requests = _requestsToSend.filter((x) => x !== 'elRequest');
          setRequestsToSend(_requests);
          send(
            newData,
            _requests,
            result.data.CrmContractId,
            result.data.CrmContractNo,
          );
          trackEContractResultEvent(true, newData, isShop);
          return;
        }
        setIsLoading(false);
        handleRequestError(500, 'onboardingEl');
        requestErrorDialog.show();
        trackEContractResultEvent(false, newData, isShop);
        return;
      })
      .catch((error) => {
        setIsLoading(false);
        logOnboardingError(error, 'electricity');
        handleRequestError(
          error.response?.data ?? error.response,
          'onboardingEl',
        );
        requestErrorDialog.show();
        trackEContractResultEvent(false, newData, isShop);
      });
  };

  const sendGasRequest = (newData, _requestsToSend) => {
    saveGasRequest(newData, isShop, user)
      .then((result) => {
        if (
          result.data.ResponseCode === 200 &&
          result.data.CrmContractId &&
          result.data.CrmContractNo
        ) {
          setContractId(result.data.CrmContractId);
          setContractΝο(result.data.CrmContractNo);
          const _requests = _requestsToSend.filter((x) => x !== 'gasRequest');
          setRequestsToSend(_requests);
          send(
            newData,
            _requests,
            result.data.CrmContractId,
            result.data.CrmContractNo,
          );
          trackEContractResultEvent(true, newData, isShop);
          return;
        }
        setIsLoading(false);

        handleRequestError(500, 'onboardingGas');
        requestErrorDialog.show();
        trackEContractResultEvent(false, newData, isShop);

        return;
      })
      .catch((error) => {
        handleRequestError(
          error.response?.data ?? error.response,
          'onboardingGas',
        );
        requestErrorDialog.show();
        setIsLoading(false);
        logOnboardingError(error, 'gas');
        trackEContractResultEvent(false, newData, isShop);
      });
  };

  const uploadElFiles = (
    newData,
    _requestsToSend,
    _contractId,
    _contractNo,
  ) => {
    uploadFileRequest(
      newData,
      _requestsToSend,
      _contractId,
      _contractNo,
      false,
    );
  };

  const uploadGasFiles = (
    newData,
    _requestsToSend,
    _contractId,
    _contractNo,
  ) => {
    uploadFileRequest(newData, _requestsToSend, _contractId, _contractNo, true);
  };

  const handleFilesUploadError = (error, data) => {
    uploadErrorDialog.show();
    logOnboardingError(error, 'upload files');
    trackNewContractEvent('onboarding_documents_upload_fail', '', {
      shop_or_onboarding_type: getShopOrOnboardingType(isShop),
      energy_type: data.energyType,
      customer_type: getCustomerCategory(data.customerCategory),
    });
  };

  const uploadFileRequest = (
    newData,
    _requestsToSend,
    _contractId,
    _contractNo,
    isGas,
  ) => {
    const requiredFiles = getRequiredFilesToSend(newData);
    const filesToUpload = requiredFiles
      .filter(
        (x) =>
          x.type === energyTypes.combined ||
          x.type === (isGas ? energyTypes.gas : energyTypes.electricity),
      )
      .map((x) => {
        const uploadedFile =
          newData[x.name] && newData[x.name].length > 0
            ? newData[x.name][0]
            : null;
        if (x.isRequired && !uploadElFiles) {
          console.log(`missing file for ${x.name}`);
        }
        const extension = uploadedFile?.name?.split('.').pop();
        const newFileName = `${t(`documents.${x.name}`)}.${extension}`;

        const renamedFile = new File([uploadedFile], newFileName, {
          type: uploadedFile.type,
        });

        return renamedFile;
      })
      .filter((x) => x !== null && x !== undefined);

    const commodity = isGas ? commodities.gas : commodities.energy;

    setIsLoading(true);

    uploadFiles(_contractId, _contractNo, commodity, filesToUpload, isShop)
      .then((res) => {
        if (!res?.data?.allfilesUploaded) {
          throw res;
        }
        setContractId(null);
        setContractΝο(null);
        const _requests = _requestsToSend.filter(
          (x) => x !== (isGas ? 'gasFiles' : 'elFiles'),
        );
        setRequestsToSend(_requests);
        send(newData, _requests);
      })
      .catch((error) => {
        setIsLoading(false);
        handleFilesUploadError(error, newData);
      });
  };

  const register = (newData, _requestsToSend) => {
    const postData = {
      email: newData.email,
      password: newData.password,
      phone: newData.mobile,
      taxId: newData.taxNo,
      supplyNumber: getSupplyNumber(
        newData.energyProgram?.meterNo
          ? newData.energyProgram?.meterNo
          : newData.gasProgram?.meterNo,
      ),
      setEmailAsPrimary: true,
    };

    setUserRegister(postData)
      .then((res) => {
        const _requests = _requestsToSend.filter((x) => x !== 'register');
        setRequestsToSend(_requests);
        send(newData, _requests);
      })
      .catch((error) => {
        registrationErrorDialog.show();
        logOnboardingError(error, 'register');
        trackNewContractEvent('onboarding_app_registration_fail', '', {
          shop_or_onboarding_type: getShopOrOnboardingType(isShop),
          energy_type: newData.energyType,
          customer_type: getCustomerCategory(newData.customerCategory),
        });
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const onFileChange = (files, fileName, type) => {
    //in case user clicked cancel on file upload, keep previous file - do not clear input

    const newFile = [
      ...(files
        ? files
        : defaultValues[fileName]
          ? defaultValues[fileName]
          : null),
    ];
    if (newFile) {
      formMethods.setValue(fileName, newFile, { shouldValidate: true });
      onUpdate({ [fileName]: newFile });
    }
  };

  const updateAndForward = (updatedData) => {
    const newData = getUpdatedData(updatedData);
    setNextRouteData(newData);
    goForward(newData);
  };

  useEffect(() => {
    const unlisten = () => {
      scrollTo(0, 0, document.querySelector('div.MuiDialog-scrollBody'));
    };
    return () => {
      unlisten();
    };
  }, [location]);

  const shouldShowOnboardingDialog = () =>
    open &&
    !requestErrorDialog.isOpen &&
    !uploadErrorDialog.isOpen &&
    !registrationErrorDialog.isOpen;

  return (
    <>
      <DialogOverlay
        open={shouldShowOnboardingDialog()}
        closeDialog={() => {
          setOpen(false);
          navigate(
            isShop
              ? `/shop/${programType}/${programEnergyType}/${programName}`
              : '/login',
            {
              state: nextRouteData,
            },
          );
        }}
        dialogClass={styles[`${_classPrefix}-dialog`]}
        contentClass={styles[`${_classPrefix}-content`]}
        titleClass={_isProgramStep ? styles.programTitle : styles.dialogTitle}
        actionsClass={
          step === steps.newGasSupplySuccess
            ? styles.centeredActions
            : styles.actions
        }
        scroll="body"
        showBackButton={
          step === steps.newGasSupplySuccess || step === steps.electricitySupply
            ? false
            : true
        }
        goBack={goBack}
        dialogHeader={
          defaultValues.energyType === energyTypes.combined && combinedTitle
            ? combinedTitle
            : title
        }
        dialogActions={
          cta && (
            <PrimaryButtonLoader
              loading={isLoading}
              disabled={isLoading}
              onClick={formMethods.handleSubmit(onSubmit)}
              type="submit"
            >
              {cta}
            </PrimaryButtonLoader>
          )
        }
      >
        <OnboardingStepper
          step={step}
          energyType={nextRouteData?.energyType}
          customerCategory={nextRouteData?.customerCategory}
          hasGasSupply={nextRouteData?.gas}
          isShop={isShop}
        />
        <section className={styles.container}>
          {_isShopStep && (
            <ShopSelector
              step={step}
              defaultValues={defaultValues}
              onSelectProgram={(value) => {
                updateAndForward(value);
              }}
              isShop={isShop}
            />
          )}
          {_isProgramStep && (
            <ProgramDetails
              step={step}
              nextRouteData={defaultValues}
              isShop={isShop}
              onUpdate={onUpdate}
            />
          )}
          {(items || info) && (
            <FindProgramBody
              items={items}
              info={info}
              image={image}
              nextRouteData={defaultValues}
              onChecked={onChecked}
            />
          )}
          {components &&
            components.map((component) => {
              return getComponents(defaultValues)[component]
                ? createElement(getComponents(defaultValues)[component], {
                    key: component,
                    defaultValues: defaultValues,
                    onUpdate: onUpdate,
                    onFileChange: onFileChange,
                    ...componentProps,
                    gasRequestError,
                    clearGasRequestError,
                    isShop: isShop,
                    updateAndForward: (value) => updateAndForward(value),
                  })
                : null;
            })}
        </section>
      </DialogOverlay>

      <FileUploadErrorDialog
        open={uploadErrorDialog.isOpen}
        closeDialog={uploadErrorDialog.close}
        clickHandler={() => {
          uploadErrorDialog.close();
          trackNewContractEvent(
            'onboarding_documents_upload_fail_try_again',
            '',
            {
              shop_or_onboarding_type: getShopOrOnboardingType(isShop),
              energy_type: nextRouteData.energyType,
              customer_type: getCustomerCategory(
                nextRouteData.customerCategory,
              ),
            },
          );
          send(nextRouteData, requestsToSend, contractId, contractΝο);
        }}
      />
      <RegistrationErrorDialog
        open={registrationErrorDialog.isOpen}
        closeDialog={() => {
          registrationErrorDialog.close();
          navigate('/login', { state: nextRouteData });
        }}
        clickHandler={() => {
          registrationErrorDialog.close();
          trackNewContractEvent('onboarding_registration_fail_try_again', '', {
            shop_or_onboarding_type: getShopOrOnboardingType(isShop),
            energy_type: nextRouteData.energyType,
            customer_type: getCustomerCategory(nextRouteData.customerCategory),
          });
          navigate('/login', {
            state: nextRouteData,
          });
        }}
      />
      {consentDialog.isOpen && (
        <ConsentDialog
          open={consentDialog.isOpen}
          closeDialog={consentDialog.close}
          phone={nextRouteData?.mobile}
          onActionClicked={(value) => {
            consentDialog.close();
            updateAndForward({ contactConsent: value });
            trackOnboardingEvent(
              value === true
                ? 'onboarding_lead_consent'
                : 'onboarding_lead_deny',
              '',
              {
                energy_type: nextRouteData.energyType,
                customer_type: getCustomerCategory(
                  nextRouteData.customerCategory,
                ),
              },
            );
          }}
          nextRouteData={nextRouteData}
          {...contactDetails}
        />
      )}
      <OnboardingSuccessDialog
        open={onboardingSuccessDialog.isOpen}
        closeDialog={onboardingSuccessDialog.close}
        openDialog={onboardingSuccessDialog.show}
        isGas={nextRouteData.energyType === energyTypes.gas}
        isShop={isShop}
        programType={programType}
        programEnergyType={programEnergyType}
        programName={programName}
        isLoyaltyCustomer={isLoyaltyCustomer}
        points={pointsEarned}
        {...contactDetails}
      />
      <CreateRequestErrorDialog
        open={requestErrorDialog.isOpen}
        closeDialog={() => {
          requestErrorDialog.close();
          clearRequestError();
        }}
        error={requestError}
      />
    </>
  );
};

export default OnboardingFlowDialog;
