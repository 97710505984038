import { useNavigate, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import storage from 'utils/storage/storage';

import { useAuthUser } from 'utils/AuthUser';
import uniqueFlowsConfig, {
  uniqueFlowsNames,
} from 'utils/product/onboardingConfig';

const getLocalStorageVariable = (localStorageVariable) => {
  try {
    const storedFlow = storage.getItem({
      key: localStorageVariable,
      storage: storage.STORAGE_TYPE.LocalStorage,
    });
    return storedFlow;
  } catch (e) {
    return undefined;
  }
};

export default function useUniqueFlow(
  uniqueFlowName = uniqueFlowsNames.existingUser,
) {
  const getPageStep = () => {
    try {
      const states = uniqueFlowsConfig[uniqueFlowName].states;
      for (let i = 0; i < states.length; i++) {
        if (states[i].path === location.pathname) {
          return i;
        }
      }
      return -1;
    } catch (error) {
      return undefined;
    }
  };
  const navigate = useNavigate();

  const redirectToPageOfStep = useCallback(
    (step) => {
      navigate(uniqueFlowsConfig[uniqueFlowName].states[step].path, {
        replace: true,
      });
    },
    [navigate, uniqueFlowName],
  );

  const { accountId } = useAuthUser();
  const { isLoggedIn } = useAuthUser();
  const location = useLocation();
  const [step, setStep] = useState(() => getPageStep());
  const [flowActive, setFlowActive] = useState(true);

  const redirectToCurrentStep = useCallback(() => {
    try {
      const uniqueFlowLocalStorageObject =
        getLocalStorageVariable(uniqueFlowName);
      if (
        uniqueFlowLocalStorageObject !== undefined &&
        uniqueFlowLocalStorageObject &&
        uniqueFlowLocalStorageObject[accountId]
      ) {
        const userUniqueFlowStates = uniqueFlowLocalStorageObject[accountId];
        const states = uniqueFlowsConfig[uniqueFlowName].states;
        for (let i = 0; i < states.length; i++) {
          if (!userUniqueFlowStates.hasOwnProperty(states[i].stepLabel)) {
            redirectToPageOfStep(i);
            return;
          }
        }
        redirectToPageOfStep(0);

        return;
      }
      redirectToPageOfStep(step);
    } catch (error) {
      return;
    }
  }, [redirectToPageOfStep, uniqueFlowName, step, accountId]);

  useEffect(() => {
    if (accountId && flowActive) {
      redirectToCurrentStep();
    }
  }, [step, accountId, redirectToCurrentStep, flowActive]);

  useEffect(() => {
    if (isLoggedIn && flowActive && step === -1) {
      setStep(parseInt(step) + 1);
    }
  }, [isLoggedIn, step, flowActive]);

  const setStepComplete = () => {
    try {
      const uniqueFlowLocalStorageObject =
        getLocalStorageVariable(uniqueFlowName) || {};

      const userFlow = {
        ...uniqueFlowLocalStorageObject[accountId],
        [uniqueFlowsConfig[uniqueFlowName].states[step].stepLabel]: new Date(),
      };

      storage.setItem({
        key: uniqueFlowName,
        value: JSON.stringify({
          ...uniqueFlowLocalStorageObject,
          [accountId]: userFlow,
        }),
        storage: storage.STORAGE_TYPE.LocalStorage,
      });
    } catch (e) {
      return;
    }
  };

  const incrementStep = () => {
    if (step >= 0) setStepComplete();
    setStep(parseInt(step) + 1);
  };
  const decrementStep = () => {
    setStep(parseInt(step) - 1);
  };

  return {
    incrementStep,
    decrementStep,
    setFlowActive,
  };
}
