import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from 'utils/AppContext';

import { trackGuestEvent } from 'utils/product/tracking/events';
import { PrimaryButton, SecondaryButton } from 'components/Buttons/Buttons';

import styles from './CookiesConsent.module.scss';

const CookiesConsent = ({ isLoggedIn = false }) => {
  const { t } = useTranslation(['onboarding']);
  const baseName = 'onboarding:cookies';
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const isInCookiesPage = location.pathname.indexOf('/cookies') > -1;
  const { cookiesSaved, acceptCookies, rejectCookies } =
    React.useContext(AppContext);

  const accceptAllCookies = () => {
    setIsVisible(false);
    acceptCookies();
    trackGuestEvent('loginscreen_cookies_accept', null, true);
  };

  const rejectAllCookies = () => {
    setIsVisible(false);
    rejectCookies();
  };

  useEffect(() => {
    setIsVisible(!cookiesSaved);
  }, [cookiesSaved]);

  return (
    <>
      {!isInCookiesPage && isVisible && (
        <section className={styles.container}>
          <article className={styles.details}>
            <h3>{t(`${baseName}.title`)}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: t(`${baseName}.consent-message`),
              }}
            ></div>
          </article>
          <article className={styles.buttons}>
            <PrimaryButton
              className={styles.primary}
              onClick={accceptAllCookies}
            >
              {t(`${baseName}.accept`)}
            </PrimaryButton>
            <SecondaryButton
              className={styles.secondary}
              onClick={rejectAllCookies}
            >
              {t(`${baseName}.reject`)}
            </SecondaryButton>
            <Link
              className={styles.link}
              to={isLoggedIn ? '/profile/cookies' : '/cookies'}
            >
              {t(`onboarding:cookies-more`)}
            </Link>
          </article>
        </section>
      )}
    </>
  );
};

export default CookiesConsent;
