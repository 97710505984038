import React from 'react';
import { useTranslation } from 'react-i18next';

import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import CookiesManagement from './CookiesManagement';

import styles from './Cookies.module.scss';

const Cookies = () => {
  const { t } = useTranslation(['cookies']);
  const baseName = 'cookies';

  return (
    <section className={styles.container}>
      <Widget
        size="m"
        distance="m"
        title={<WidgetHeader title={t(`${baseName}:info.title`)} />}
      >
        <p
          dangerouslySetInnerHTML={{
            __html: t(`${baseName}:info.message`),
          }}
        ></p>
      </Widget>
      <Widget
        size="m"
        distance="m"
        title={<WidgetHeader title={t(`${baseName}:extra.title`)} />}
      >
        <p
          dangerouslySetInnerHTML={{
            __html: t(`${baseName}:extra.message`),
          }}
        ></p>
      </Widget>
      <Widget
        size="m"
        distance="m"
        title={<WidgetHeader title={t(`${baseName}:categories.title`)} />}
      >
        <p
          dangerouslySetInnerHTML={{
            __html: t(`${baseName}:categories.message`),
          }}
        ></p>
      </Widget>
      <Widget
        size="m"
        distance="m"
        title={<WidgetHeader title={t(`${baseName}:functional.title`)} />}
      >
        <p
          dangerouslySetInnerHTML={{
            __html: t(`${baseName}:functional.message`),
          }}
        ></p>
        <div className={styles.responsiveTable}>
          <table className={styles['table']}>
            <thead>
              <tr>
                <th>{t(`${baseName}:cookie-name`)}</th>
                <th>{t(`${baseName}:supplier`)}</th>
                <th>{t(`${baseName}:function`)}</th>
                <th>{t(`${baseName}:duration`)}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>myHeron:privacy</td>
                <td>ΗΡΩΝ</td>
                <td>{t(`${baseName}:table-items.privacy`)}</td>
                <td>{t(`${baseName}:table-items.persistent`)}</td>
              </tr>
              <tr>
                <td>token</td>
                <td>ΗΡΩΝ</td>
                <td>{t(`${baseName}:table-items.token`)}</td>
                <td>{t(`${baseName}:table-items.persistent`)}</td>
              </tr>
              <tr>
                <td>refresh_token</td>
                <td>ΗΡΩΝ</td>
                <td>{t(`${baseName}:table-items.refresh_token`)}</td>
                <td>{t(`${baseName}:table-items.persistent`)}</td>
              </tr>
              <tr>
                <td>acc_id</td>
                <td>ΗΡΩΝ</td>
                <td>{t(`${baseName}:table-items.refresh_token`)}</td>
                <td>{t(`${baseName}:table-items.persistent`)}</td>
              </tr>
              <tr>
                <td>lang</td>
                <td>ΗΡΩΝ</td>
                <td>{t(`${baseName}:table-items.lang`)}</td>
                <td>{t(`${baseName}:table-items.persistent`)}</td>
              </tr>
              <tr>
                <td>flow_existing</td>
                <td>ΗΡΩΝ</td>
                <td>{t(`${baseName}:table-items.flow_existing`)}</td>
                <td>{t(`${baseName}:table-items.persistent`)}</td>
              </tr>
              <tr>
                <td>heron_settlements</td>
                <td>ΗΡΩΝ</td>
                <td>{t(`${baseName}:table-items.heron_settlements`)}</td>
                <td>{t(`${baseName}:table-items.persistent`)}</td>
              </tr>
              <tr>
                <td>app_banner</td>
                <td>ΗΡΩΝ</td>
                <td>{t(`${baseName}:table-items.app_banner`)}</td>
                <td>
                  {t(`${baseName}:table-items.app_banner_duration`, {
                    days: 30,
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Widget>
      <Widget
        size="m"
        distance="m"
        title={<WidgetHeader title={t(`${baseName}:performance.title`)} />}
      >
        <p
          dangerouslySetInnerHTML={{
            __html: t(`${baseName}:performance.message`),
          }}
        ></p>
        <div className={styles.responsiveTable}>
          <table className={styles['table']}>
            <thead>
              <tr>
                <th>{t(`${baseName}:cookie-name`)}</th>
                <th>{t(`${baseName}:supplier`)}</th>
                <th>{t(`${baseName}:function`)}</th>
                <th>{t(`${baseName}:duration`)}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>_gat</td>
                <td>GOOGLE</td>
                <td>{t(`${baseName}:table-items.analytics_gat`)}</td>
                <td>{t(`${baseName}:table-items.analytics_gat_duration`)}</td>
              </tr>
              <tr>
                <td>_gid</td>
                <td>GOOGLE</td>
                <td>{t(`${baseName}:table-items.analytics_gid`)}</td>
                <td>{t(`${baseName}:table-items.analytics_gid_duration`)}</td>
              </tr>
              <tr>
                <td>_ga</td>
                <td>GOOGLE</td>
                <td>{t(`${baseName}:table-items.analytics_ga`)}</td>
                <td>{t(`${baseName}:table-items.analytics_ga_duration`)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Widget>
      <Widget
        size="m"
        distance="m"
        title={<WidgetHeader title={t(`${baseName}:advertise.title`)} />}
      >
        <p
          dangerouslySetInnerHTML={{
            __html: t(`${baseName}:advertise.message`),
          }}
        ></p>
        <div className={styles.responsiveTable}>
          <table className={styles['table']}>
            <thead>
              <tr>
                <th>{t(`${baseName}:cookie-name`)}</th>
                <th>{t(`${baseName}:supplier`)}</th>
                <th>{t(`${baseName}:function`)}</th>
                <th>{t(`${baseName}:duration`)}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>_fbp</td>
                <td>FACEBOOK</td>
                <td>{t(`${baseName}:table-items.facebook_fbp`)}</td>
                <td>{t(`${baseName}:table-items.facebook_fbp_duration`)}</td>
              </tr>
              <tr>
                <td>_fbc</td>
                <td>FACEBOOK</td>
                <td>{t(`${baseName}:table-items.facebook_fbc`)}</td>
                <td>{t(`${baseName}:table-items.facebook_fbc_duration`)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Widget>
      <Widget
        size="m"
        distance="m"
        title={<WidgetHeader title={t(`${baseName}:delete.title`)} />}
      >
        <p
          dangerouslySetInnerHTML={{ __html: t(`${baseName}:delete.message`) }}
        ></p>
      </Widget>
      <CookiesManagement />
    </section>
  );
};

export default Cookies;
