import React from 'react';

import RequestsWidget from 'components/RequestsWidget/RequestsWidget';
import CustomerCareWidget from 'components/CustomerCareWidget/CustomerCareWidget';

const RightSidebar = () => {
  return (
    <>
      <RequestsWidget />
      <CustomerCareWidget />
    </>
  );
};

export default RightSidebar;
