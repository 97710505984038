import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate } from 'utils/dateUtils';
import { dateFormats } from 'utils/product/config';

import consumptionStates from 'utils/product/enums/consumptionStates';
import { getLVConsumptionInfo } from 'utils/product/consumptionUtils';
import { RegisterOwnReadingsLayout } from 'components/Consumptions/HomeStates/ConsumptionLayouts';
import {
  ConsumptionAnalysisButton,
  RegisterOwnReadingsButton,
  PredictionIndications,
  ConsumptionMessage,
} from 'components/Consumptions/Home/ConsumptionInfoElements';

const StateCountAndWin = ({ meter, refreshData, meterIsNew }) => {
  const { t } = useTranslation(['consumptions']);

  const consumptionInfo = getLVConsumptionInfo(meter);

  return (
    <RegisterOwnReadingsLayout>
      {{
        message: (
          <ConsumptionMessage
            text={t(`consumptions:count-and-win.no-loyalty-message`, {
              dateFrom: formatDate(
                consumptionInfo.allowedReadingDateFrom,
                dateFormats.dayMonthNoDayPad
              ),
              dateTo: formatDate(
                consumptionInfo.allowedReadingDateTo,
                dateFormats.dayMonthNoDayPad
              ),
            })}
          />
        ),
        info: !meterIsNew ? (
          <PredictionIndications
            {...consumptionInfo}
            type={consumptionStates.register}
          />
        ) : null,
        secondaryButton: <ConsumptionAnalysisButton meterNo={meter.MeterNo} />,
        primaryButton: (
          <RegisterOwnReadingsButton meter={meter} refreshData={refreshData} />
        ),
      }}
    </RegisterOwnReadingsLayout>
  );
};

export default StateCountAndWin;
