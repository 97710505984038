import useLoyaltyHistory from './useLoyaltyHistory';
import useDocumentByWaveId from './useDocumentByWaveId';
import { uniqueWaveIds } from 'utils/product/loyalty/historyUtils';

const useLoyaltyHistoryWithDescription = (customerId, language) => {
  const { data, error, loading } = useLoyaltyHistory(customerId);

  const { data: documentsData, loading: documentsLoading } =
    useDocumentByWaveId(uniqueWaveIds(data), language);

  return {
    loading: documentsLoading || loading,
    error,
    data,
    associatedData: documentsData?.data,
  };
};

export default useLoyaltyHistoryWithDescription;
