import { useEffect, useState } from 'react';
import UAParser from 'ua-parser-js';

const useDeviceDetect = () => {
  const [isMobile, setMobile] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  const [isIos, setIsIos] = useState(false);
  const [isPC, setIsPC] = useState(false);

  useEffect(() => {
    try {
      const parser = new UAParser(
        navigator.userAgent || navigator.vendor || window.opera
      );
      const { type: deviceType = '' } = parser.getDevice();
      if (deviceType === 'mobile' || deviceType === 'tablet') {
        setMobile(true);
        const { name: osName = '' } = parser.getOS();
        if (osName === 'Android') {
          setIsAndroid(true);
        } else if (osName === 'iOS') {
          setIsIos(true);
        }
      } else {
        setIsPC(true);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  return { isMobile, isAndroid, isIos, isPC };
};

export default useDeviceDetect;
