import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { replaceAll } from 'utils/stringUtils';
import { getQueryParams, isTestUrl } from 'utils/urlUtils';
import {
  getPaymentFailureUrlsMapping,
  getPaymentOverlayInitialState,
  getPaymentSuccessUrlsMapping,
} from 'utils/product/paymentUtils';
import authStorage from 'utils/storage/authStorage';
import paymentTypes from 'utils/product/enums/paymentTypes';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';

import { useAuthUser } from 'utils/AuthUser';
import useGeneralConfig from 'data/useGeneralConfig';
import { getObjectKeys } from 'utils/objectUtils';

import styles from './PaymentOverlay.module.scss';

const PaymentOverlay = ({
  open = false,
  closeDialog,
  paymentAmount,
  paymentCode,
  meterId,
  invoiceNo,
  commodity,
  onSuccess,
  onError,
  paymentType,
  isCardUpdate,
}) => {
  const { t, i18n } = useTranslation(['bills', 'translation']);
  const [iframeUrl, setIframeUrl] = useState('');
  /* needed to adjust scrolling for bank iframe */
  const [isPaymentGatewayShown, setIsPaymentGatewayShown] = useState(true);
  const token = authStorage.getToken();
  const { accountId } = useAuthUser();
  const { data: config } = useGeneralConfig() || {};

  const [postData] = useState(
    getPaymentOverlayInitialState(
      paymentType,
      token,
      paymentCode,
      paymentAmount,
      meterId,
      commodity,
      invoiceNo,
      accountId,
    ),
  );

  const handleSuccess = (url, paymentResultType) => {
    if (!url) onError();
    const result = getQueryParams(new URL(url).search);

    const parameters = get(result, 'Parameters', '');
    const [customerName = '', paymentAmount = '', paymentCode = ''] =
      replaceAll(parameters, '+', ' ')?.split('|') ?? [];

    onSuccess({
      paymentResultType,
      customerName,
      paymentAmount,
      paymentCode,
      approvalCode: get(result, 'ApprovalCode', ''),
      yellowsSpent: get(result, 'yellowsSpent', null),
      yellowsEarned: get(result, 'yellowsEarned', null),
      crmResult: get(result, 'crmResult', 'true') === 'false' ? false : true,
    });
  };

  const paymentUrl =
    paymentType === paymentTypes.recurring
      ? config.recurringPaymentGatewayUrl
      : config.paymentGatewayUrl;

  useEffect(() => {
    setIframeUrl(
      open === true
        ? `${paymentUrl}?lang=${i18n.language === 'en' ? 'en' : 'el'}`
        : '',
    );
  }, [open, i18n.language, paymentUrl]);

  useEffect(() => {
    const handleMessage = (event) => {
      const paymentUrlOrigin = new URL(paymentUrl)?.origin ?? paymentUrl;
      if (event.origin && event.origin.startsWith(paymentUrlOrigin)) {
        if (
          typeof event.data === 'string' &&
          event.data.indexOf('Payment gateway initialised.') > -1
        ) {
          setIsPaymentGatewayShown(true);
          let iframe = document.getElementById('gateway_frame');
          if (iframe) {
            iframe.contentWindow.postMessage(postData, paymentUrl);
          }
        }
      }
      //this is a "hack" for non prod and stage envs, so that redirection happens to parent url and payment flow can be completed

      if (
        isTestUrl(window.location.origin) &&
        typeof event.data === 'string' &&
        event.data.indexOf('Payment result loaded.') > -1
      ) {
        let iframe = document.getElementById('gateway_frame');
        if (iframe) {
          iframe.contentWindow.postMessage(
            { pageOrigin: window.location.origin },
            '*',
          );
        }
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [postData, paymentUrl]);

  const onIframeLoaded = (event, evt) => {
    let iframeWindow = event.currentTarget.contentWindow;
    setIsPaymentGatewayShown(false);
    if (iframeWindow) {
      let iFrameUrl = '';
      try {
        iFrameUrl = iframeWindow.location?.href;
        const successUrlsMapping = getPaymentSuccessUrlsMapping(
          config,
          window.location.origin,
        );

        let found = false;
        getObjectKeys(successUrlsMapping).every((key) => {
          if (iFrameUrl?.indexOf(key) > -1) {
            found = true;
            handleSuccess(iFrameUrl, successUrlsMapping[key]);
            return false;
          }
          return true;
        });

        if (!found) {
          const errorUrlsMapping = getPaymentFailureUrlsMapping(
            config,
            window.location.origin,
          );
          getObjectKeys(errorUrlsMapping).every((key) => {
            if (iFrameUrl?.indexOf(key) > -1) {
              onError({ paymentResultType: errorUrlsMapping[key] });
              return false;
            }
            return true;
          });
        }
      } catch (err) {}
    }
  };

  const onIframeError = (err) => {
    onError();
  };
  return (
    <DialogOverlay
      open={open}
      closeDialog={closeDialog}
      dialogClass={styles['paymentDialog']}
      titleClass={styles['paymentDialog-title']}
      contentClass={
        isPaymentGatewayShown ? '' : styles['paymentDialog-content-scroll']
      }
      dialogHeader={t(
        `bills:payment-dialog.title${
          paymentType === paymentTypes.recurring
            ? `-recurring${isCardUpdate ? '-card-update' : ''}`
            : paymentType === paymentTypes.settlement
              ? '-settlement'
              : paymentType === paymentTypes.ena
                ? '-ena'
                : ''
        }`,
      )}
      maxWidth="md"
      scroll="paper"
    >
      <iframe
        id="gateway_frame"
        data-testid="payment-frame"
        className={styles['payment-iFrame']}
        src={iframeUrl}
        title={`myΗΡΩΝ - ${
          paymentType === paymentTypes.recurring
            ? t('bills:recurring-payment.title')
            : t(`bills:payment-dialog.title`)
        }`}
        width="800px"
        height="455px"
        onLoad={onIframeLoaded}
        onError={onIframeError}
        sandbox="allow-same-origin allow-forms allow-scripts allow-popups allow-forms allow-popups-to-escape-sandbox"
        referrerPolicy="origin"
      />
    </DialogOverlay>
  );
};

export default PaymentOverlay;
