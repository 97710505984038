import React from 'react';
import { Link } from 'react-router-dom';

import Widget from 'components/Widget/Widget';
import TextArrow from 'components/TextArrow/TextArrow';
import { ReactComponent as RightArrow } from 'assets/icons/ArrowRightIcon.svg';

import styles from './DetailedListWidget.module.scss';

const InnerContainer = ({ text, clickHandler }) => {
  return (
    <Widget
      widgetClass={styles.widget}
      size="m"
      distance="xs"
      onClick={clickHandler ? clickHandler : null}
    >
      <TextArrow
        Icon={<RightArrow />}
        weight="bold"
        content={text}
        contentClass={styles.ctaContainer}
      />
    </Widget>
  );
};

const DetailedListWidget = ({
  link,
  show = true,
  text,
  clickHandler,
  ...rest
}) => {
  return show ? (
    link ? (
      <Link to={link} {...rest}>
        <InnerContainer text={text} clickHandler={clickHandler} />
      </Link>
    ) : (
      <InnerContainer text={text} clickHandler={clickHandler} />
    )
  ) : null;
};

export default DetailedListWidget;
