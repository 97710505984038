import React from 'react';
import { Link } from 'react-router-dom';

import LanguageSelector from 'components/LanguageSelector/LanguageSelector';
import { ReactComponent as Logo } from 'assets/icons/LogoIcon.svg';
import { ReactComponent as LogoSmall } from 'assets/icons/LogoSmallIcon.svg';

import styles from './UnauthorizedPrimaryHeader.module.scss';

const UnauthorizedPrimaryHeader = ({ children }) => {
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <Link to="/" className={styles.logo} aria-label="homepage">
          <Logo className={styles.bigLogo} />
          <LogoSmall className={styles.smallLogo} />
        </Link>
        <section className={styles.containerRight}>
          {children} <LanguageSelector />
        </section>
      </div>
    </header>
  );
};

export default UnauthorizedPrimaryHeader;
