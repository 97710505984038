import React from 'react';
import { ReactComponent as HandIconWheel } from 'assets/icons/HandIconWheel.svg';
import { ReactComponent as HandIconScratch } from 'assets/icons/HandIconScratch.svg';
import { gameTypes } from 'utils/product/enums/gameEnums';

import styles from './GamesInstructions.module.scss';

const WheelHandInstructionIcon = ({ ...rest }) => <HandIconWheel {...rest} />;
const ScratchHandInstructionIcon = ({ ...rest }) => (
  <HandIconScratch {...rest} />
);

const GamesInstructions = ({ gameType, hideInstruction, ...rest }) => {
  switch (gameType) {
    case gameTypes.wheel:
      return (
        <WheelHandInstructionIcon
          alt="game instruction"
          className={`${styles[`game-handIcon-wheel`]} ${
            hideInstruction ? styles['hide-instruction'] : ''
          }`}
          {...rest}
        />
      );
    case gameTypes.scratch:
      return (
        <ScratchHandInstructionIcon
          alt="game instruction"
          className={`${styles[`game-handIcon-scratch`]} ${
            hideInstruction ? styles['hide-instruction'] : ''
          }`}
          {...rest}
        />
      );

    default:
      break;
  }
};

export default GamesInstructions;
