import React from 'react';

import styles from './WhatsNewDots.module.scss';

const DotElement = ({ active = false }) => {
  let className = active ? `${styles.div} ${styles.divActive}` : styles.div;
  return (
    <li className={styles.li}>
      <div className={className}></div>
    </li>
  );
};

const calculateDots = (numberOfDots, activeDotIndex) => {
  let dots = [];
  for (let i = 0; i < numberOfDots; i++) {
    let dotIsActive = i === activeDotIndex;
    dots.push(<DotElement key={i} active={dotIsActive} />);
  }
  return dots;
};

const WhatsNewDots = ({ dotsCount = 4, pageIndex = 0 }) => {
  let dots = calculateDots(dotsCount, pageIndex);

  return (
    <>
      <ul className={styles.ul}>{dots}</ul>
    </>
  );
};

export default WhatsNewDots;
