import { useCmsContent } from './useCmsContent';

const useDocumentByWaveId = (waveIds, language) => {
  const { data, error, loading } = useCmsContent(
    waveIds,
    language,
    !waveIds,
    '[content.waveId.el-GR_in]',
  );

  return {
    loading,
    error,
    data,
  };
};

export default useDocumentByWaveId;
