const stripLeadingZeros = (str) => str.replace(new RegExp('^0+(?!$)', 'g'), '');

const replaceAll = (text, value = '', replaceValue = '') => {
  return text ? text.split(value).join(replaceValue) : text;
};

const isEmptyString = (str) => {
  return !str || (typeof str === 'string' && str.trim().length === 0);
};

const isEmptyGuid = (data) => {
  if (isEmptyString(data)) return true;
  const _replacedString = replaceAll(replaceAll(data, '0', ''), '-', '');
  return isEmptyString(_replacedString);
};

const diacriticsMap = [
  { find: 'άἀἁἂἃἄἅἆἇὰάᾀᾁᾂᾃᾄᾅᾆᾇᾰᾱᾲᾳᾴᾶᾷ', replace: 'α' },
  { find: 'ΆἈἉἊἋἌἍἎἏᾈᾉᾊᾋᾌᾍᾎᾏᾸᾹᾺΆᾼ', replace: 'Α' },
  { find: 'έἐἑἒἓἔἕὲέ', replace: 'ε' },
  { find: 'ΈἘἙἚἛἜἝ', replace: 'Ε' },
  { find: 'ήἠἡἢἣἤἥἦἧῆὴῇ', replace: 'η' },
  { find: 'ΉἨἩἪἫἬἭἮἯ', replace: 'Η' },
  { find: 'ίἰἱἲἳἴἵὶῖ', replace: 'ι' },
  { find: 'ΊἶἷἸἹἺἻἼἽἾἿ', replace: 'Ι' },
  { find: 'όὀὁὂὃὄὅὸ', replace: 'ο' },
  { find: 'ΌὈὉὊὋὌὍ', replace: 'Ο' },
  { find: 'ύὐὑὒὓὔὕὖὗ', replace: 'υ' },
  { find: 'ΎὙὛὝὟ', replace: 'Υ' },
  { find: 'ώὠὡὢὣὤὥὦὧῶ', replace: 'ω' },
  { find: 'ΏὨὩὪὫὬὭὮὯ', replace: 'Ω' },
];

export const toUpperCaseSanitized = (text, ignoreCharacters) => {
  const san = sanitizeDiacritics(text, ignoreCharacters);
  const res = san.toUpperCase();
  return res;
};

const sanitizeDiacritics = (
  text,
  ignoreCharacters = '',
  regExOptions = 'g'
) => {
  if (typeof text === 'string' && !isEmptyString(text)) {
    const sanitizedText = diacriticsMap.reduce(
      (previousValue, currentValue) => {
        const regexString = `${
          isEmptyString(ignoreCharacters) ? '' : `(?![${ignoreCharacters}])`
        }[${currentValue.find}]`;

        const regex = new RegExp(regexString, regExOptions);

        return previousValue.replace(regex, currentValue.replace);
      },
      text
    );
    return sanitizedText;
  }

  return '';
};

const isStringEqual = (text1, text2) => {
  const _text1 = typeof text1 === 'string' ? text1.toLowerCase() : text1;
  const _text2 = typeof text2 === 'string' ? text2.toLowerCase() : text2;

  return _text1 === _text2;
};

const formatGuid = (guid) => {
  return !isEmptyGuid(guid) ? guid.toLowerCase() : null;
};

export {
  replaceAll,
  isEmptyString,
  isEmptyGuid,
  isStringEqual,
  stripLeadingZeros,
  formatGuid,
};
