const meterUsageTypes = {
  home: 1, // Οικιακή
  business: 2, // Επαγγελματική
  agricultural: 3, // Αγροτική<-
  industrial: 4, // Βιομηχανική<-
  public: 5, // Δημόσια
  fop: 6, // ΦΟΠ
  traction: 7, // Έλξη
  npdd: 8, // ΝΠΔΔ
};

export default meterUsageTypes;
