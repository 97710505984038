import useData from './useData';

const useHeronNotifications = (customerId) => {
  const { data, loading, error, mutate } = useData(
    `/account/notificationsHistory?accountId=${customerId}`,
    { dedupingInterval: 300000 }
  );

  return {
    loading,
    error,
    data: data?.notifications,
    mutate,
  };
};

export default useHeronNotifications;
