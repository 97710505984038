import React from 'react';

import styles from './MyVibeLabel.module.scss';

const MyVibeLabel = ({ vibe }) => {
  return (
    <div className={styles.container}>
      <b>{vibe.title}</b>
      <div className={styles.info}>
        <img src={vibe.imageUrl} alt={vibe.key} width={32} height={32} />
        <span>{vibe.percentage}%</span>
      </div>
    </div>
  );
};
export default MyVibeLabel;
