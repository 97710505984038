import React from 'react';

import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import Widget from 'components/Widget/Widget';
import { ReactComponent as Trophy } from 'assets/icons/ThophyIconColored.svg';
import CmsHtmlField from 'components/CmsHtmlField/CmsHtmlField';

import styles from './ContestWinnersWidget.module.scss';

const ContestWinnersWidget = ({ contest, loading }) => {
  const widgetTitle = contest?.winners?.winnersTitle;
  const winners = contest?.winners?.results;

  return (
    <article className={styles.winnersContainer}>
      <WidgetSkeleton heightInPixels={340} distance="s" show={loading}>
        <Widget
          title={<WidgetHeader title={widgetTitle} />}
          widgetClass={styles.winnersWidget}
        >
          <Trophy width="72" height="72" />
          <div className={styles['winnersContainer-names']}>
            <CmsHtmlField value={winners} />
          </div>
        </Widget>
      </WidgetSkeleton>
    </article>
  );
};

export default ContestWinnersWidget;
