import React from 'react';
import { Controller } from 'react-hook-form';
import get from 'lodash/get';
import StandardSelect from 'components/Selects/StandardSelect';
import SelectLabel from 'components/Selects/SelectLabel';
import SelectFormControl from 'components/Selects/SelectFormControl';
import { ReactComponent as DownArrow } from 'assets/icons/ArrowDownIcon.svg';
import { CircularProgress } from '@material-ui/core';

const ControlledSelect = ({
  label,
  value,
  defaultValue,
  control,
  name,
  children,
  required = true,
  errors,
  onChange = () => {},
  MenuProps = {},
  autoWidth = false,
  rules,
  renderValue,
  loading = false,
  disabled = false,
  ...rest
}) => {
  const menuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    getContentAnchorEl: null,
    ...MenuProps,
  };
  return (
    <SelectFormControl>
      <SelectLabel>{label}</SelectLabel>
      <Controller
        render={({
          field: { onChange: onInputChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <StandardSelect
            MenuProps={menuProps}
            onChange={(e) => {
              onInputChange(e);
              onChange(e);
            }}
            value={value}
            name={name}
            data-testid={`select-${name}`}
            ref={ref}
            defaultValue={defaultValue}
            autoWidth={autoWidth}
            error={get(errors, name, null)}
            renderValue={renderValue}
            IconComponent={(props) =>
              loading ? (
                <CircularProgress
                  style={{ color: '#FFFFFF' }}
                  size={20}
                  title={`${name}_loader`}
                  {...props}
                />
              ) : (
                <DownArrow
                  fontSize="large"
                  {...props}
                  color="#fff"
                  title={`${name}_selector`}
                />
              )
            }
            disabled={disabled}
          >
            {children}
          </StandardSelect>
        )}
        name={name}
        autoWidth={autoWidth}
        control={control}
        defaultValue={defaultValue}
        rules={{ required: required }}
      />
    </SelectFormControl>
  );
};
export { ControlledSelect };
