import React from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Skeletons/Loader';

import commodities from 'utils/product/enums/commodities';
import { getEnergyMeters, getGasMeters } from 'utils/product/meterUtils';
import { trackFriendlyNameEvent } from 'utils/product/tracking/events';
import OverlaySection from './OverlaySection/OverlaySection';
import { PrimaryButton } from 'components/Buttons/Buttons';
import MeterNamingElement from 'components/MeterNaming/MeterNamingElement';

import styles from './MeterNamingContent.module.scss';

const MeterNamingContent = ({
  meters,
  metersLoading,
  handleError,
  clearError,
  closeDialog,
}) => {
  const { t } = useTranslation(['onboarding', 'translation']);
  const baseName = 'onboarding:meterNaming';

  const energyMeters = getEnergyMeters(meters);
  const gasMeters = getGasMeters(meters);

  return (
    <section className={styles.containerSection}>
      <section className={styles.section}>
        <Loader show={metersLoading} height={154} className={styles.skeleton}>
          {energyMeters?.length > 0 && (
            <OverlaySection
              content={t(`${baseName}.power-supplies`)}
              labelType={commodities.energy}
            >
              {energyMeters.map((meter) => (
                <MeterNamingElement
                  key={meter.MeterNo}
                  meterNo={meter.MeterNo}
                  meterId={meter.Id}
                  friendlyName={meter.FriendlyName}
                  commodity={meter.Commodity}
                  handleError={handleError}
                  clearError={clearError}
                  extraMessageForError={t(`${baseName}.extraMessageForError`)}
                />
              ))}
            </OverlaySection>
          )}
          {gasMeters?.length > 0 && (
            <OverlaySection
              content={t(`${baseName}.gas-supplies`)}
              labelType={commodities.gas}
            >
              {gasMeters.map((meter) => (
                <MeterNamingElement
                  key={meter.MeterNo}
                  meterNo={meter.MeterNo}
                  meterId={meter.Id}
                  friendlyName={meter.FriendlyName}
                  commodity={meter.Commodity}
                  handleError={handleError}
                  clearError={clearError}
                  extraMessageForError={t(`${baseName}.extraMessageForError`)}
                />
              ))}
            </OverlaySection>
          )}
        </Loader>
      </section>
      <section className={styles.buttonSection}>
        <PrimaryButton
          onClick={() => {
            closeDialog();
            trackFriendlyNameEvent('login_friendly_name_submit');
          }}
          className={styles.nextButton}
        >
          {t('translation:next')}
        </PrimaryButton>
      </section>
    </section>
  );
};

export default MeterNamingContent;
