import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import CheckedIcon from 'assets/icons/RadioChecked.svg';
import UncheckedIcon from 'assets/icons/RadioUnchecked.svg';
import { eventNames, questionnaireGame } from '@desquared/mini-games-web';

const Questionnaire = ({
  questions,
  onCompleted,
  onUserAnswer,
  icon = UncheckedIcon,
  iconSelected = CheckedIcon,
  iconNotSelected = UncheckedIcon,

  borderRadius,
  answerFontSize,
  answerGap,
  answerPadding,
  answerBorderColor,
  answerBorderWidth,
  answerColor,
  answerBg,
  questionFontSize,
  questionColor,
  questionsDistance,
  iconWidth,
  iconTextColor,
  pagePadding,
  completed,
  fontName,
}) => {
  questionnaireGame();
  const coolInput = useRef();
  useEffect(() => {
    const questionnaire =
      document.getElementsByTagName('questionnaire-game')[0];
    if (questionnaire) {
      questionnaire.questions = questions;
    }

    const handleMessage = (event) => {
      if (event.data) {
        const { eventName, eventData } = event.data || {};

        if (typeof eventName === 'string') {
          if (eventName.indexOf(eventNames.completed) > -1) {
            if (typeof onCompleted === 'function') {
              onCompleted(eventData);
            }
          }
          if (eventName.indexOf(eventNames.answer) > -1) {
            if (typeof onUserAnswer === 'function') {
              onUserAnswer(eventData);
            }
          }
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [onCompleted, onUserAnswer, questions]);

  return React.createElement('questionnaire-game', {
    ref: coolInput,
    'border-radius': borderRadius,
    'answer-font-size': answerFontSize,
    'answer-gap': answerGap,
    'answer-padding': answerPadding,
    'answer-border-color': answerBorderColor,
    'answer-border-width': answerBorderWidth,
    'answer-color': answerColor,
    'answer-bg': answerBg,
    'question-font-size': questionFontSize,
    'question-color': questionColor,
    'questions-distance': questionsDistance,
    'icon-width': iconWidth,
    icon: icon,
    'icon-selected': iconSelected,
    'icon-not-selected': iconNotSelected,
    'icon-text-color': iconTextColor,
    'page-padding': pagePadding,
    font: fontName,
    completed: completed,
  });
};

Questionnaire.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      clientId: PropTypes.string.isRequired,
      header: PropTypes.string,
      title: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          clientId: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  borderRadius: PropTypes.number,
  answerFontSize: PropTypes.number,
  answerGap: PropTypes.number,
  answerPadding: PropTypes.number,
  answerBorderColor: PropTypes.string,
  answerBorderWidth: PropTypes.number,
  answerColor: PropTypes.string,
  answerBg: PropTypes.string,
  questionFontSize: PropTypes.number,
  questionColor: PropTypes.string,
  questionsDistance: PropTypes.number,
  iconWidth: PropTypes.number,
  icon: PropTypes.string,
  iconSelected: PropTypes.string,
  iconNotSelected: PropTypes.string,
  iconTextColor: PropTypes.string,
  completed: PropTypes.bool,
  onCompleted: PropTypes.func,
  onUserAnswer: PropTypes.func,
};

export default Questionnaire;
