import React from 'react';
import IconText from 'components/IconText/IconText';
import { ReactComponent as WarningTriangleIcon } from 'assets/icons/WarningTriangleIcon.svg';
import useLocalisedError from 'hooks/useLocalisedError';

import styles from './GameWarningMessage.module.scss';

export const GameWarningMessage = ({ error }) => {
  const limit = error?.params?.limit;
  const { message } = useLocalisedError(error?.code, 'games', {
    times:
      limit?.daily ||
      limit?.weekly ||
      limit?.monthly ||
      limit?.yearly ||
      limit?.total,
  });

  return (
    <IconText
      Icon={<WarningTriangleIcon width={24} height={24} alt="warning" />}
      content={message}
      iconClass={styles.warningIcon}
      contentClass={styles.warningText}
      gap="xs"
    />
  );
};
