import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EmailInput } from 'components/Inputs/EmailInput';
import { TextInput } from 'components/Inputs/TextInput';
import { TelephoneInput } from 'components/Inputs/TelephoneInput';

import styles from './ContactPerson.module.scss';

const ContactPerson = ({ account, defaultValues = {} }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation(['requests']);

  return (
    <>
      {account ? (
        <article className={styles.container}>
          <h3 className={styles.title}>
            {t(`requests:personal-data.add-contact-title`)}
          </h3>
          <p className={styles.subtitle}>
            {t(`requests:personal-data.add-contact-subtitle`)}
          </p>
          <TextInput
            register={register}
            errors={errors}
            name="contactFullName"
            label={t(`requests:personal-data.fullname`)}
            defaultValue={defaultValues?.contactFullName}
            required={false}
          />

          <TelephoneInput
            register={register}
            errors={errors}
            name="contactPhone"
            label={t(`requests:personal-data.phone`)}
            defaultValue={defaultValues?.contactPhone}
            required={false}
          />
          <EmailInput
            register={register}
            errors={errors}
            name="contactEmail"
            label={t(`requests:personal-data.email`)}
            defaultValue={defaultValues?.contactEmail}
            required={false}
          />
        </article>
      ) : null}
    </>
  );
};

export default ContactPerson;
