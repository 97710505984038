import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmptyString } from 'utils/stringUtils';
import get from 'lodash/get';

import { StandardInput } from './Inputs';
import { validateTaxId } from 'utils/validators';

const TaxpayerIdInput = ({
  register,
  errors,
  label,
  name = 'taxId',
  required = true,
  ...rest
}) => {
  const { t } = useTranslation(['inputs']);
  const baseName = 'inputs:errors';
  const { ref, ...registerRest } =
    register(name, {
      required: { value: required, message: t(`${baseName}.required`) },
      validate: (value) => {
        if (!required && isEmptyString(value)) return true;
        return validateTaxId(value) || t(`${baseName}.taxpayerId`);
      },
    }) || {};

  return (
    <StandardInput
      inputRef={ref}
      label={label}
      name={name}
      type="text"
      errorMessage={get(errors, `${name}.message`, null)}
      error={get(errors, `${name}`, null) !== null}
      {...rest}
      {...registerRest}
    />
  );
};

export { TaxpayerIdInput };
