import React from 'react';
import { useTranslation } from 'react-i18next';
import shopEnergyTypes from 'utils/product/enums/shopEnergyTypes';
import { PrimaryButton, SecondaryButton } from 'components/Buttons/Buttons';

import styles from './ExistingElectricitySupply.module.scss';

const ExistingElectricitySupply = ({ updateAndForward = () => {} }) => {
  const { t } = useTranslation(['newClient']);

  const continueWithGasRequest = () => {
    updateAndForward({
      energyType: shopEnergyTypes.gas,
    });
  };
  const continueWithCombinedRequest = () => {
    updateAndForward({
      energyType: shopEnergyTypes.combined,
    });
  };
  return (
    <>
      <article className={styles.container}>
        <h3 className={styles.header}>
          {t(`newClient:electricitySupply.header`)}
        </h3>
        <h3 className={styles.subheader}>
          {t(`newClient:electricitySupply.message`)}
        </h3>
        <article className={styles.btnContainer}>
          <SecondaryButton
            onClick={continueWithGasRequest}
            className={styles.button}
          >
            {t(`newClient:electricitySupply.button-existing`)}
          </SecondaryButton>
          <PrimaryButton onClick={continueWithCombinedRequest}>
            {t(`newClient:electricitySupply.button-new`)}
          </PrimaryButton>
        </article>
      </article>
    </>
  );
};

export default ExistingElectricitySupply;
