import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { ReactComponent as DownArrow } from 'assets/icons/ArrowDownIcon.svg';

import WidgetLine from 'components/WidgetLine/WidgetLine';
import styles from './Accordion.module.scss';

export default function SimpleAccordion({
  summary,
  details,
  onChange,
  classes = {},
  summaryClasses = { root: styles['accordion-summary'] },
  detailsClasses = {},
  horizontalLine = true,
  horizontalLineClass,
}) {
  return (
    <>
      <Accordion onChange={onChange} classes={classes} square={true}>
        <AccordionSummary expandIcon={<DownArrow />} classes={summaryClasses}>
          {summary}
        </AccordionSummary>
        <AccordionDetails classes={detailsClasses}>{details}</AccordionDetails>
      </Accordion>
      {horizontalLine && (
        <WidgetLine distance="" lineClass={horizontalLineClass} />
      )}
    </>
  );
}
