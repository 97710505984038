import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Controller } from 'react-hook-form';
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar';
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import elLocale from 'date-fns/locale/el';
import enLocale from 'date-fns/locale/en-US';

import { dateFormats } from 'utils/product/config';
import {
  endOfDay,
  formatDate,
  isAfter,
  isBefore,
  startOfDay,
} from 'utils/dateUtils';
import { ReactComponent as DownArrow } from 'assets/icons/ArrowDownIcon.svg';

//control used: https://material-ui-pickers.dev/api/KeyboardDatePicker

const CustomToolbar = function (props) {
  const {
    date,
    isLandscape,
    openView,
    setOpenView,
    title,
    displayMonth,
    displayYear,
  } = props;

  const handleChangeViewClick = (view) => (e) => {
    setOpenView(view);
  };

  return openView !== 'date' ? null : (
    <PickerToolbar title={title} isLandscape={isLandscape}>
      <ToolbarButton
        onClick={handleChangeViewClick('month')}
        variant="h5"
        selected={openView === 'month'}
        label={displayMonth ?? formatDate(date, dateFormats.monthName)}
      />
      <ToolbarButton
        onClick={handleChangeViewClick('year')}
        variant="h5"
        label={displayYear ?? formatDate(date, dateFormats.year)}
        selected={openView === 'year'}
      />
    </PickerToolbar>
  );
};

const DatePicker = React.forwardRef(
  (
    {
      errors,
      control,
      label,
      name = 'date',
      value = null,
      onChange,
      allowKeyboardControl = true,
      acceptOnSelect = true,
      disableFuture = false,
      disablePast = false,
      disableToolbar = true,
      format = 'dd/MM/yyyy',
      animateYearScrolling = true,
      maxDate,
      minDate,
      readOnly = false,
      disabled = false,
      variant = 'inline',
      required = true,
      customToolbar = true,
      icon = <DownArrow />,
      validator,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation(['inputs']);
    const { language = 'el' } = i18n || {};
    const [locale, setLocale] = useState(language);
    const [displayMonth, setDisplayMonth] = useState(null);
    const [displayYear, setDisplayYear] = useState(null);
    const localeMap = {
      en: enLocale,
      el: elLocale,
    };

    const onDateChange = (value) => {
      if (onChange) onChange(value);
    };

    useEffect(() => {
      setLocale(language);
    }, [language]);

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
        <Controller
          render={({ field: { onChange, value, name, ref } }) => (
            <KeyboardDatePicker
              name={name}
              inputProps={{
                'aria-label': name,
              }}
              onChange={(val) => {
                onChange(val);
                onDateChange(val);
              }}
              value={value}
              label={label}
              allowKeyboardControl={allowKeyboardControl}
              autoOk={acceptOnSelect}
              disablePast={disablePast}
              disableFuture={disableFuture}
              disableToolbar={disableToolbar}
              format={format}
              //animateYearScrolling={animateYearScrolling}
              keyboardIcon={icon}
              variant={variant}
              maxDate={maxDate}
              minDate={minDate}
              disabled={disabled}
              readOnly={readOnly}
              invalidDateMessage={t(`inputs:errors.dateInvalid`)}
              maxDateMessage={t(`inputs:errors.maxDateError`)}
              minDateMessage={t(`inputs:errors.minDateError`)}
              error={!!errors[name]}
              helperText={get(errors, `${name}.message`, null)}
              PopoverProps={{
                anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                transformOrigin: { horizontal: 'right', vertical: 'top' },
              }}
              ToolbarComponent={(props) =>
                customToolbar ? (
                  <CustomToolbar
                    {...props}
                    displayMonth={displayMonth}
                    displayYear={displayYear}
                  />
                ) : null
              }
              onMonthChange={(date) => {
                setDisplayMonth(formatDate(date, dateFormats.monthName));
                setDisplayYear(formatDate(date, dateFormats.year));
              }}
              onYearChange={(date) => {
                setDisplayYear(formatDate(date, dateFormats.year));
              }}
              {...rest}
            />
          )}
          defaultValue={value}
          control={control}
          error={!!errors[name]}
          name={name}
          rules={{
            required: {
              value: required,
              message: t(`inputs:errors.required`),
            },
            validate: {
              invalidDate: (value) => {
                const isValid =
                  value && value instanceof Date && !isNaN(value.valueOf());

                if (isValid) {
                  if (typeof validator === 'function') {
                    return validator(value);
                  }

                  if (
                    typeof minDate !== 'undefined' &&
                    isBefore(value, startOfDay(minDate))
                  ) {
                    return t(`inputs:errors.minDateError`);
                  }

                  if (
                    typeof maxDate !== 'undefined' &&
                    isAfter(value, maxDate)
                  ) {
                    return t(`inputs:errors.maxDateError`);
                  }

                  const now = new Date();
                  if (disablePast && isBefore(value, startOfDay(now))) {
                    return t(`inputs:errors.minDateError`);
                  }

                  if (disableFuture && isAfter(value, endOfDay(now))) {
                    return t(`inputs:errors.maxDateError`);
                  }
                }

                if (!isValid) {
                  return t(`inputs:errors.dateInvalid`);
                }
                return isValid;
              },
            },
          }}
        />
      </MuiPickersUtilsProvider>
    );
  }
);

export { DatePicker };
