import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { useDiscoveryItem } from 'data/useDiscoveryItem';
import { useContestParticipation } from 'data/useContestParticipations';
import { useAuthUser } from 'utils/AuthUser';
import ContestWidget from 'components/Contests/ContestWidget';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';

import SingleColumnLayout from 'layouts/SingleColumnLayout';

const Contest = () => {
  const { t, i18n } = useTranslation(['offers']);
  const language = i18n.language;

  const { slug } = useParams();

  const { accountId } = useAuthUser();
  const { data: contest, loading } = useDiscoveryItem(slug, language);

  const {
    data: participations,
    participationsCount,
    loading: participationsLoading,
    mutate: mutateParticipations,
  } = useContestParticipation(accountId, contest?.contestId);

  if (!loading && !contest) {
    return <Navigate to="/offers" />;
  }

  const pageTitle = t(`offers:details.contest-title`);
  return (
    <SingleColumnLayout
      head={{
        title: contest ? contest.detailsTitle : pageTitle,
        metaTags: [
          {
            name: 'title',
            content: contest ? contest.detailsTitle : pageTitle,
          },
        ],
      }}
    >
      <WidgetSkeleton
        heightInPixels={540}
        distance="s"
        show={loading || participationsLoading}
        title={<WidgetHeader title={t(`offers:details.contest-title`)} />}
      >
        {contest && (
          <ContestWidget
            contest={contest}
            participations={participations}
            participationsCount={participationsCount}
            mutateParticipations={mutateParticipations}
          />
        )}
      </WidgetSkeleton>
    </SingleColumnLayout>
  );
};

export default Contest;
