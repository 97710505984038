import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';

import Chip from 'components/Chip/Chip';
import MeterChipContainer from 'components/MeterChipContainer/MeterChipContainer';
import orderBy from 'lodash/orderBy';
import MetersSearchDialog from 'components/MetersSearchDialog/MetersSearchDialog';
import useDialog from 'hooks/useDialog';

import styles from './HistoryMeterChips.module.scss';

const HistoryMeterChips = ({
  meters,
  chipKeys = null,
  handlechosenMeter,
  chosenMeter,
  meterKey = 'Id',
  containerClass = '',
}) => {
  const { t } = useTranslation(['consumptions', 'translation']);
  const metersDialog = useDialog();
  const [selectedMeters, setSelectedMeters] = useState([]);

  const chipMeters = useMemo(
    () =>
      meters.length > 1
        ? meters.filter((x) => {
            const shouldRemove =
              chipKeys !== null && chipKeys?.indexOf(x.Id) === -1;
            return x.IsActiveOrTerminated && !shouldRemove;
          })
        : [],
    [meters, chipKeys]
  );

  const showMeterFilters = chipMeters.length < 4;

  useEffect(() => {
    if (showMeterFilters) {
      setSelectedMeters(
        orderBy(
          chipMeters,
          [(x) => (x.IsActive ? 0 : 1), 'Commodity', 'MeterNo'],
          ['asc', 'desc', 'asc']
        )
      );
      return;
    }

    setSelectedMeters((_previousMeters) => {
      if (_previousMeters.find((meter) => meter[meterKey] === chosenMeter))
        return _previousMeters.slice(0, 3);

      const selectedMeter = chipMeters.find(
        (meter) => meter[meterKey] === chosenMeter
      );

      if (selectedMeter) _previousMeters.unshift(selectedMeter);

      const results = _previousMeters.filter((value, index, array) => {
        const indx = value
          ? array.findIndex((x) => x[meterKey] === value[meterKey])
          : -1;
        return indx === index;
      });
      return results.slice(0, 3);
    });
  }, [chosenMeter, chipMeters, showMeterFilters, meterKey]);

  const metersList = showMeterFilters
    ? []
    : sortBy(
        chipMeters,
        (x) => {
          return x.FriendlyName ?? x.MeterNo;
        },
        ['asc']
      );

  if (chipMeters.length <= 1) return null;

  const handleMeterFilter = (selectedMeter) => {
    handlechosenMeter(selectedMeter);
  };

  return (
    <>
      <section className={`${styles.container} ${containerClass}`}>
        <Chip
          color="primary"
          label={t('translation:All')}
          isSelected={chosenMeter === 'all'}
          onClick={() => handlechosenMeter('all')}
        />
        {selectedMeters.map((meter) => {
          return (
            <MeterChipContainer
              key={`chip-${meter[meterKey]}`}
              meter={meter}
              isSelected={chosenMeter === meter[meterKey]}
              onClick={() => handlechosenMeter(meter)}
            />
          );
        })}
        {!showMeterFilters && (
          <Chip
            color="outlined"
            label={t('history.consumptionSelection')}
            onClick={metersDialog.show}
            variant="outlined"
            textOverflow={false}
          />
        )}
      </section>
      {!showMeterFilters && (
        <MetersSearchDialog
          open={metersDialog.isOpen}
          closeDialog={metersDialog.close}
          meters={metersList}
          handleMeterSelection={handleMeterFilter}
        />
      )}
    </>
  );
};

export default HistoryMeterChips;
