import React from 'react';
import { useTranslation } from 'react-i18next';

import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import Map from 'components/Map/Map';
import Pin from 'assets/icons/PinIcon.svg';

import 'styles/overrides/googlemaps.scss';
import styles from './MainArea.module.scss';

var prev_infowindow = false;

function addMarkers(map, links) {
  links.forEach((link) => {
    const infowindow = new window.google.maps.InfoWindow({
      maxWidth: 328,
      content: `
      <div class=details>
        <h4 class=header> ${link.name} </h4>
        <p>${link.phoneNum} </p>
        <hr>
        <p class=link><a href="mailto:${link.email}">${link.email}</a></p>
        <hr>
        <p class=multiline> ${link.address} </p>
        <a class=directions-button
        href="https://www.google.gr/maps/dir/current+location/${link.latitude},${link.longitude}" target="_blank">Οδηγίες</a>
        
        </div>
        `,
    });
    const marker = new window.google.maps.Marker({
      map,
      icon: Pin,
      position: { lat: link.latitude, lng: link.longitude },
      name: link.name,
    });
    marker.addListener('click', function () {
      if (prev_infowindow) {
        prev_infowindow.close();
      }
      prev_infowindow = infowindow;
      infowindow.open(map, marker);
    });
  });
}

const MainArea = ({ loading, markers }) => {
  const { t } = useTranslation(['FAQs']);
  if (!loading) {
    const mapProps = {
      options: {
        center: { lat: 37.9838, lng: 23.7275 },
        zoom: 6,
        zoomControl: false,
        disableDefaultUI: true,
      },
      onMount: addMarkers,
      onMountProps: markers ? markers.stores : [],
    };
    return (
      <Widget
        widgetClass={styles['widget-map']}
        title={<WidgetHeader title={t('homepage:physical-stores.title')} />}
      >
        <Map {...mapProps} />
      </Widget>
    );
  } else {
    return (
      <Widget
        widgetClass={styles['widget-map']}
        title={<WidgetHeader title={t('homepage:physical-stores.title')} />}
      ></Widget>
    );
  }
};

export default MainArea;
