import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useGeneralConfig from 'data/useGeneralConfig';

import styles from './Terms.module.scss';

const Terms = ({ containerClass = '' }) => {
  const { t, i18n } = useTranslation(['profile']);
  const {
    data: { generalTermsUrl, gdprTermsUrl },
  } = useGeneralConfig(i18n.language) || {};

  return (
    <section className={cn(styles.container, containerClass)}>
      <Link to={generalTermsUrl} target="_blank" rel="noreferrer">
        {t('profile:terms.general')}
      </Link>
      <Link to={gdprTermsUrl} target="_blank" rel="noreferrer">
        {t('profile:terms.gdpr')}
      </Link>
    </section>
  );
};

export default Terms;
