import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckboxMaterial from '@material-ui/core/Checkbox';
import { ReactComponent as CheckboxCheckedIcon } from 'assets/icons/CheckboxCheckedIcon.svg';
import { ReactComponent as CheckboxIcon } from 'assets/icons/CheckboxIcon.svg';

import styles from './Checkbox.module.scss';

const Checkbox = ({
  value,
  name,
  changeHandler,
  label,
  labelPlacement = 'end',
  register = () => {},
  labelClass = '',
  checkboxClass = '',
  registerRules = {},
}) => {
  const {
    ref,
    name: inputName,
    onChange = () => {},
  } = register(name, { ...registerRules }) || {};

  const labelClasses = { label: `${styles.label}` };
  const checkboxClasses = {
    root: checkboxClass,
  };
  return (
    <FormControlLabel
      label={label}
      labelPlacement={labelPlacement}
      classes={labelClasses}
      className={labelClass}
      control={
        <CheckboxMaterial
          checked={value}
          onChange={(e) => {
            onChange(e);
            changeHandler(e);
          }}
          checkedIcon={<CheckboxCheckedIcon />}
          icon={<CheckboxIcon />}
          inputRef={ref}
          name={inputName}
          classes={checkboxClasses}
        />
      }
    />
  );
};
export default Checkbox;
