import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import RadioButton from 'components/RadioButtonsGroup/RadioButton';
import RadioGroup from 'components/RadioButtonsGroup/RadioGroup';
import shopEnergyTypes from 'utils/product/enums/shopEnergyTypes';

import styles from './Ebill.module.scss';

const Ebill = ({ defaultValues = {}, onUpdate, isGasProgram = false }) => {
  const { register, setValue } = useFormContext();
  const { t } = useTranslation(['newClient']);

  const baseObjectName = isGasProgram ? 'gasProgram' : 'energyProgram';

  return (
    <article className={styles.container}>
      <h3 className={styles.header}>{t(`newClient:eBill.header`)}</h3>
      <RadioGroup
        name={`${baseObjectName}.eBill`}
        value={defaultValues[`${baseObjectName}`].eBill || ''}
        handleChange={(event) => {
          setValue(`${baseObjectName}.eBill`, event.target.value);
          onUpdate({
            [baseObjectName]: {
              ...defaultValues[baseObjectName],
              eBill: event.target.value,
            },
          });
        }}
      >
        <RadioButton
          value={`true`}
          label={t('newClient:eBill.ebill-true')}
          register={register}
          className={styles.radio}
        />
        {defaultValues[`${baseObjectName}`].energyType !==
          shopEnergyTypes.eco && (
          <RadioButton
            value={`false`}
            label={t('newClient:eBill.ebill-false')}
            register={register}
            className={styles.radio}
          />
        )}
      </RadioGroup>
    </article>
  );
};

export default Ebill;
