import React from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import {
  trackShopProgramEvent,
  trackShopServiceEvent,
} from 'utils/product/tracking/events';
import shopEnergyTypes from 'utils/product/enums/shopEnergyTypes';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import styles from './ShopCard.module.scss';

const CardItem = ({ program }) => {
  const energyTypeKey = program.energyType.toLowerCase();

  return (
    <Card
      className={cn({
        [styles[`card-${energyTypeKey}`]]: !program.skipCss,
        [styles[`card-default`]]: program.skipCss,
      })}
    >
      <CardMedia
        className={styles.image}
        image={program.smallImageUrl}
        title={program.packageId}
      />
      <CardContent className={styles.content}>
        <p>{program.shortDescription}</p>
        <div className={styles.tips}>
          {program?.info1 && <p>{program.info1}</p>}
          {program?.info2 && <p>{program.info2}</p>}
          {program?.info3 && <p>{program.info3}</p>}
        </div>
      </CardContent>
    </Card>
  );
};
const ShopCard = ({ program, clickHandler }) => {
  const programTypeKey = program.programType?.toLowerCase();
  const energyTypeKey = program.energyType.toLowerCase();
  const navigate = useNavigate();
  return (
    <>
      {clickHandler ? (
        <div
          className={styles.cardContainer}
          onClick={() => {
            clickHandler(program);
          }}
        >
          <CardItem program={program} />
        </div>
      ) : (
        <div
          className={styles.cardContainer}
          aria-label={program ? program.title : ''}
          onClick={() => {
            if (program.energyType === shopEnergyTypes.service)
              trackShopServiceEvent('shop_service_details', program.title);
            else trackShopProgramEvent('shop_program_details', program.title);
            navigate(
              program.isEna
                ? '/shop/ena'
                : `/shop/${programTypeKey}/${energyTypeKey}/${program.urlName}`,
            );
          }}
        >
          <CardItem program={program} />
        </div>
      )}
    </>
  );
};

export default ShopCard;
