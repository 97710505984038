//import { acceptedRequestStatuses } from 'utils/product/enums/requests';
import { sortByField, sortByDateFieldDescending } from 'utils/sortUtils';
import { getObjectValues } from 'utils/objectUtils';

export const getAvailableRequestsWithStatuses = (
  requests,
  requestsToBeShown,
  requestStatuses
) => {
  const orderedRequests = [...requests];

  sortByDateFieldDescending(orderedRequests, 'RequestDate');

  const result = getObjectValues(requestsToBeShown).map((item) => {
    //logic commented for Phase 1
    // const existingRequest = requests.find(
    //   (x) =>
    //     x.RequestType === item.code &&
    //     acceptedRequestStatuses.includes(x._status)
    // );

    // const requestStatus = existingRequest
    //   ? requestStatuses[existingRequest._status]
    //   : null;

    return {
      ...item,
      // requestStatus: requestStatus?.code,
      // requestStatusText: requestStatus?.message,
    };
  });

  sortByField(result, 'order');

  return result;
};
