import { useTranslation } from 'react-i18next';

const useLocalizedFriendlyName = () => {
  const { t } = useTranslation(['onboarding']);

  const baseName = 'onboarding:meterNaming';

  const selectOptions = [
    { value: 1, text: t(`${baseName}.home`) },
    { value: 2, text: t(`${baseName}.cottage`) },
    { value: 3, text: t(`${baseName}.work`) },
    { value: 4, text: t(`${baseName}.other`) },
  ];

  const defaultValuesEl = {
    [t(`${baseName}.home`, { lng: 'el' })]: 1,
    [t(`${baseName}.cottage`, { lng: 'el' })]: 2,
    [t(`${baseName}.work`, { lng: 'el' })]: 3,
    [t(`${baseName}.other`, { lng: 'el' })]: 4,
  };
  const defaultValuesEn = {
    [t(`${baseName}.home`, { lng: 'en' })]: 1,
    [t(`${baseName}.cottage`, { lng: 'en' })]: 2,
    [t(`${baseName}.work`, { lng: 'en' })]: 3,
    [t(`${baseName}.other`, { lng: 'en' })]: 4,
  };

  const serverValues = {
    1: t(`${baseName}.home`, { lng: 'el' }),
    2: t(`${baseName}.cottage`, { lng: 'el' }),
    3: t(`${baseName}.work`, { lng: 'el' }),
    4: t(`${baseName}.other`, { lng: 'el' }),
  };

  const isDefaultValue = (name) => {
    const defaultValue = defaultValuesEl[name] || defaultValuesEn[name];
    return defaultValue && defaultValue !== 4;
  };

  const getFriendlyName = (name, forceGreek = false) => {
    if (!name) return null;

    const value = { ...defaultValuesEl, ...defaultValuesEn }[name];
    if (value)
      return forceGreek
        ? serverValues[value]
        : selectOptions.find((x) => x.value === value).text || '';

    return name;
  };

  const getDisplayName = (friendlyName, meterNo) => {
    const _friendlyName = getFriendlyName(friendlyName);
    return _friendlyName ?? meterNo ?? '';
  };

  return {
    getFriendlyName,
    getDisplayName,
    isDefaultValue,
    selectOptions,
  };
};

export default useLocalizedFriendlyName;
