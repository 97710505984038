import accountCategories from 'utils/product/enums/accountCategories';
import ownershipTypes from 'utils/product/enums/ownershipTypes';
import energyTypes from 'utils/product/enums/shopEnergyTypes';

const documents = {
  recentElectricityBillFront: {
    name: 'electricityBillFront',
    type: energyTypes.electricity,
    isRequired: true,
  },
  recentElectricityBillBack: {
    name: 'electricityBillBack',
    type: energyTypes.electricity,
    isRequired: false,
  },
  recentGasBillFront: {
    name: 'gasBillFront',
    type: energyTypes.gas,
    isRequired: true,
  },
  recentGasBillBack: {
    name: 'gasBillBack',
    type: energyTypes.gas,
    isRequired: false,
  },
  lease: {
    name: 'lease',
    type: energyTypes.electricity,
    isRequired: true,
  },
  carLicense: {
    name: 'carLicense',
    type: energyTypes.electricity,
    isRequired: true,
  },
  studentId: {
    name: 'studentId',
    type: energyTypes.electricity,
    isRequired: true,
  },
  freelanceDocument: {
    name: 'freelanceDocument',
    type: energyTypes.combined,
    isRequired: true,
  },
  publicity: {
    name: 'publicity',
    type: energyTypes.combined,
    isRequired: true,
  },
  eda: {
    name: 'eda',
    type: energyTypes.gas,
    isRequired: true,
  },
  ownerAffirmation: {
    name: 'owner-affirmation',
    type: energyTypes.gas,
    isRequired: true,
  },
  meetingRecord: {
    name: 'meeting-record',
    type: energyTypes.gas,
    isRequired: true,
  },
};

const ecoPackages = [
  'EcoDrive Home',
  'EcoDrive HomeCharge',
  'ECODRIVE BUSINESS ELECTRIC',
  'ECODRIVE BUSINESS PLUG IN HYBRID',
];

const hasElectricity = (data) =>
  data.energyType === energyTypes.electricity ||
  data.energyType === energyTypes.combined ||
  data.energyType === energyTypes.combinedElectricity ||
  data.energyType === energyTypes.eco;

const hasGas = (data) =>
  data.energyType === energyTypes.gas ||
  (data.energyType === energyTypes.combined && data.gas === 'true');

const isElectricityBillRequired = (data) =>
  hasElectricity(data) && data.electricityRecentBill === 'true';

const isGasBillRequired = (data) =>
  hasGas(data) && data.gasRecentBill === 'true';

const isLeaseRequired = (data) =>
  hasElectricity(data) && data.electricityRecentBill === 'false';

const isEdaRequired = (data) => hasGas(data) && data.gasRecentBill === 'false';

const isCarLicenseRequired = (data) =>
  data.energyProgram &&
  data.energyProgram.packageId &&
  ecoPackages.indexOf(data.energyProgram.packageId) > -1;

const isStudentIdRequired = (data) =>
  data.energyProgram &&
  data.energyProgram.packageId &&
  data.energyProgram.packageId.includes('STUDENT');

const isOwnerAffirmationRequired = (data) =>
  hasGas(data) && data.gasProgram.userCapacity === `${ownershipTypes.owner}`;

const isMeetingRecordRequired = (data) =>
  hasGas(data) &&
  data.shared === 'true' &&
  data.gasProgram.userCapacity === `${ownershipTypes.manager}`;

const isFreelanceDocumentRequired = (data) =>
  data.customerCategory === `${accountCategories.freelancer}`;

const isPublicityRequired = (data) =>
  data.customerCategory === `${accountCategories.company}`;

const getRequiredFileTypes = (data) => {
  const filesNeeded = [];

  if (isElectricityBillRequired(data)) {
    filesNeeded.push(documents.recentElectricityBillFront);
    filesNeeded.push(documents.recentElectricityBillBack);
  }

  if (isGasBillRequired(data)) {
    filesNeeded.push(documents.recentGasBillFront);
    filesNeeded.push(documents.recentGasBillBack);
  }

  if (isPublicityRequired(data)) filesNeeded.push(documents.publicity);

  if (isLeaseRequired(data)) filesNeeded.push(documents.lease);

  if (isEdaRequired(data)) filesNeeded.push(documents.eda);

  if (isCarLicenseRequired(data)) filesNeeded.push(documents.carLicense);

  if (isStudentIdRequired(data)) filesNeeded.push(documents.studentId);

  if (isOwnerAffirmationRequired(data))
    filesNeeded.push(documents.ownerAffirmation);
  if (isMeetingRecordRequired(data)) filesNeeded.push(documents.meetingRecord);

  if (isFreelanceDocumentRequired(data))
    filesNeeded.push(documents.freelanceDocument);

  return filesNeeded;
};

const getRequiredFilesToSend = (data) => {
  const filesNeeded = getRequiredFileTypes(data);

  if (data.identityType === 'passportNo') {
    return [
      {
        name: 'passport',
        type: energyTypes.combined,
        isRequired: true,
      },
      ...filesNeeded,
    ];
  }

  if (data.identityType === 'identityNo') {
    return [
      {
        name: 'identityFront',
        type: energyTypes.combined,
        isRequired: true,
      },
      {
        name: 'identityBack',
        type: energyTypes.combined,
        isRequired: false,
      },
      ...filesNeeded,
    ];
  }
  return filesNeeded;
};

export { getRequiredFileTypes, getRequiredFilesToSend, documents };
