import React from 'react';
import Chip from '@material-ui/core/Chip';

import styles from './Chip.module.scss';

/**
 * A chip component based on the Material UI chip component https://mui.com/components/chips/
 */
export default function Chips({
  color = 'primary',
  Icon,
  label,
  textOverflow = true,
  isSelected,
  ...rest
}) {
  const labelText =
    textOverflow && label.length > 11 ? `${label.slice(0, 9)}...` : label;
  const chipClasses = `${styles[`chip-${color}`]}
  ${isSelected ? styles[`chip-${color}-isSelected`] : ''}
`;

  return (
    <div className={styles.root}>
      <Chip
        avatar={Icon}
        color={color === 'primary' ? color : 'default'}
        label={labelText}
        className={chipClasses}
        {...rest}
      />
    </div>
  );
}
