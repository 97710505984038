import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { trackHomeConsumptionsB2BEvent } from 'utils/product/tracking/events';
import { useAuthUser } from 'utils/AuthUser';
import { getEnergyReportsToken } from 'api/account';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import { ErrorDialog } from 'components/PromptDialogs/PromptDialog';
import useDialog from 'hooks/useDialog';

const EnergyReportsCta = ({
  meter,
  containerClass = '',
  buttonClass = '',
  isHome = false,
}) => {
  const { t } = useTranslation(['consumptions', 'errors/generic']);
  const { accountId } = useAuthUser();
  const [loading, setLoading] = useState(null);
  const errorDialog = useDialog();

  const goToEnergyReports = () => {
    if (isHome)
      trackHomeConsumptionsB2BEvent('home_consumption_energy_reports');
    if (meter) {
      setLoading(true);
      const meterSupplyNo = meter?.MeterSupplyNo.replace(/\s+/g, '')?.slice(
        0,
        -1
      );
      getEnergyReportsToken(accountId)
        .then((res) => {
          if (res.data.cordia_token) {
            window
              .open(
                `${process.env.REACT_APP_ENERGYREPORTS}&token=${res.data.cordia_token}&meterid=${meterSupplyNo}`,
                '_blank'
              )
              .focus();
            return;
          }
          errorDialog.show();
        })
        .catch((error) => {
          errorDialog.show();
        })
        .then(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <section className={containerClass}>
        <PrimaryButtonLoader
          onClick={goToEnergyReports}
          loading={loading}
          disabled={loading}
          className={buttonClass}
        >
          {t('consumptions:b2b.energy-reports')}
        </PrimaryButtonLoader>
      </section>
      <ErrorDialog open={errorDialog.isOpen} closeDialog={errorDialog.close} />
    </>
  );
};

export default EnergyReportsCta;
