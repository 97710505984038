import React from 'react';

import Slider from 'components/Slider/Slider';

const WidgetsSlider = ({ children, sliderClass = '', ...rest }) => {
  const settings = {
    autoplay: false,
    width: '100%',
    arrows: false,
    className: `slider variable-width bills-slider ${sliderClass} }`,
    variableWidth: true,
    infinite: false,

    responsive: [
      {
        breakpoint: 99999,
        settings: 'unslick',
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings} {...rest}>
      {children}
    </Slider>
  );
};

export default WidgetsSlider;
