import { useCmsContent } from './useCmsContent';
import useWaveData from './useWaveData';
import endpoints from 'utils/product/enums/endpoints';

const useLoyaltyQuestionnaire = (language) => {
  const { data, error, isError } = useWaveData(
    `${endpoints.loyalty.gameQuestionnaire}?type=ONBOARDING`
  );

  const { data: questionnairesContent } = useCmsContent(
    'games-questionnaires',
    language
  );
  const loading = !data && !error;

  const questionnaire = data && data?.data?.length > 0 ? data.data[0] : null;

  if (questionnaire) {
    const content = questionnairesContent?.data.find(
      (x) => x.content?.waveId === questionnaire._id
    );

    const steps = questionnaire
      ? questionnaire.questions.map((x) => x.header)
      : [];
    if (content) {
      const fullQuestionnaire = {
        ...questionnaire,
        questions: questionnaire.questions.map((q) => {
          const qContent = content?.subdocs?.questions.find(
            (x) => x.content.clientId === q.clientId
          );
          return {
            ...q,
            header: qContent?.content?.header ?? q.header,
            title: qContent?.content?.questionTitle ?? q.title,
            options: qContent?.content?.options ?? q.options,
            backgroundImage: qContent?.content?.backgroundImage?.image,
          };
        }),
      };
      return {
        loading,
        error,
        isError,
        data: fullQuestionnaire,
        steps,
      };
    }
  }

  return {
    loading,
    error,
    isError,
    data: null,
  };
};

export default useLoyaltyQuestionnaire;
