import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { setUserPassword } from 'api/user';
import { trackProfileInfoEvent } from 'utils/product/tracking/events';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import { PasswordInput } from 'components/Inputs/PasswordInput';
import { ConfirmPasswordInput } from 'components/Inputs/ConfirmPasswordInput';
import { NewPasswordInput } from 'components/Inputs/NewPasswordInput';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import authStorage from 'utils/storage/authStorage';
import { decodeToken } from 'utils/product/accountUtils';
import useDialog from 'hooks/useDialog';

import styles from './ChangePasswordDialog.module.scss';
import {
  ErrorDialog,
  SuccessDialog,
} from 'components/PromptDialogs/PromptDialog';

const prepareObject = (data, email) => {
  const { password, newPassword } = data;
  return {
    email,
    password,
    newPassword,
  };
};

const ChangePasswordDialog = ({ open, closeDialog }) => {
  const { t } = useTranslation(['translation', 'profile']);
  const baseName = 'profile:personalInfo';
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [registrationEmail, setRegistrationEmail] = useState();
  const successDialog = useDialog();
  const errorDialog = useDialog();

  useEffect(() => {
    if (open === false) {
      setRegistrationEmail(null);
      return;
    }
    const token = authStorage.getToken();
    const { email } = decodeToken(token);
    setRegistrationEmail(email);
  }, [open]);

  useEffect(() => {
    if (!open)
      reset({
        password: '',
        newPassword: '',
        confirmPassword: '',
      });
  }, [open, reset]);

  const onSubmit = (data) => {
    trackProfileInfoEvent('profile_personal_info_change_password');
    setIsLoading(true);

    const object = prepareObject(data, registrationEmail);
    setUserPassword(object)
      .then(() => {
        closeDialog();
        successDialog.show();
      })
      .catch(() => {
        closeDialog();
        errorDialog.show();
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <SuccessDialog
        open={successDialog.isOpen}
        closeDialog={() => {
          successDialog.close();
        }}
        header={t(`translation:completion`)}
        message={t(`${baseName}.password-change-success`)}
      />
      <ErrorDialog
        open={errorDialog.isOpen}
        closeDialog={() => {
          errorDialog.close();
        }}
        header={t(`translation:completion-fail`)}
        message={t(`${baseName}.password-change-failure`)}
      />

      <DialogOverlay
        open={open}
        closeDialog={closeDialog}
        contentClass={styles.dialogContent}
        dialogActions={
          <PrimaryButtonLoader
            loading={isLoading}
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
            type="submit"
          >
            {t('translation:continue')}
          </PrimaryButtonLoader>
        }
      >
        <h2 className={styles.header}>{t(`${baseName}.change-password`)}</h2>
        <p
          className={styles.paragraph}
          dangerouslySetInnerHTML={{
            __html: t(`${baseName}.add-new-password`, {
              email: registrationEmail,
            }),
          }}
        ></p>
        <p className={styles.muted}>{t(`${baseName}.password-info`)}</p>
        <PasswordInput
          register={register}
          errors={errors}
          name="password"
          label={t(`${baseName}.current-password`)}
        />
        <NewPasswordInput
          register={register}
          errors={errors}
          name="newPassword"
          label={t(`${baseName}.new-password`)}
        />
        <ConfirmPasswordInput
          register={register}
          errors={errors}
          name="confirmPassword"
          comparedInputName="newPassword"
          label={t(`${baseName}.confirm-password`)}
          watch={watch}
        />
      </DialogOverlay>
    </>
  );
};

export default ChangePasswordDialog;
