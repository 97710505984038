import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthUser } from 'utils/AuthUser';

import { SecondaryButton } from 'components/Buttons/Buttons';

import useSettlement from 'data/useSettlement';
import useDialog from 'hooks/useDialog';
import CreateSettlementDialog from './CreateSettlementDialog';

import styles from './BillsSettlement.module.scss';

const BillsSettlement = ({ meterId, totalAmount, isLastInvoice = false }) => {
  const { t } = useTranslation(['bills']);
  const { accountId } = useAuthUser();
  const settlementCreationDialog = useDialog();
  const onSettlementCreated = () => {
    mutate(null, true);
  };
  const {
    data: settlementResponse,
    loading,
    mutate,
  } = useSettlement(accountId, meterId);

  return (
    <>
      {!loading && settlementResponse && (
        <>
          {isLastInvoice && settlementResponse.AllowSettlement && (
            /*|| hasSettlement*/
            <SecondaryButton
              className={styles.button}
              onClick={settlementCreationDialog.show}
            >
              <p>{t('bills:settlements.selltement-want')}</p>
            </SecondaryButton>
          )}

          {/* {hasSettlement && (
            <SettlementOverviewDialog
              open={isExistingSettlementOpen}
              closeDialog={() => setIsExistingSettlementOpen(false)}
              settlementResponse={settlementResponse}
              settlementLoading={loading}
              meter={meterId}
              onPaymentSuccess={(meterId, installmentNo) => {
                TODO: find what needs to be done
              }}
            />
          )} */}
          <CreateSettlementDialog
            open={settlementCreationDialog.isOpen}
            closeDialog={settlementCreationDialog.close}
            settlementResponse={settlementResponse}
            accountId={accountId}
            meterId={meterId}
            totalAmount={totalAmount}
            onSettlementCreated={onSettlementCreated}
          />
        </>
      )}
    </>
  );
};
export default BillsSettlement;
