import React from 'react';
import HomeBillWidgetContent from 'components/HomeBillWidget/HomeBillWidgetContent';
import OffersOverviewWidget from 'components/HomeOffersSection/OffersSectionWidget';

import ConsumptionsOverview from 'components/Consumptions/Home/ConsumptionsOverview';
import EnaHomeWidget from 'components/Ena/EnaHomeWidget';
import NewClientContractStatus from 'components/HomeNewClient/NewClientContractStatus';
import HomeSettlementWidget from 'components/HomeBillWidget/HomeSettlementWidget';
import HomeNotifications from 'components/HomeNotifications/HomeNotifications';

const Main = () => {
  return (
    <>
      <HomeNotifications />
      <NewClientContractStatus />
      <OffersOverviewWidget />
      <HomeBillWidgetContent />
      <HomeSettlementWidget />
      <EnaHomeWidget />
      <ConsumptionsOverview />
    </>
  );
};
export default Main;
