import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as WarningTriangleIcon } from 'assets/icons/WarningTriangleIcon.svg';
import { PromptDialog } from 'components/PromptDialogs/PromptDialog';

import styles from './GameExitDialog.module.scss';

const GameExitDialog = ({ open, closeDialog, openGameAgain }) => {
  const { t } = useTranslation(['loyalty']);

  return (
    <PromptDialog
      open={open}
      closeDialog={closeDialog}
      showCloseButton={false}
      icon={<WarningTriangleIcon width="72" height="72" />}
      primaryActionText={t(`loyalty:games.exit.buttonPlay`)}
      primaryActionHandler={openGameAgain}
      showSecondaryAction={true}
      secondaryActionText={t(`loyalty:games.exit.buttonLeave`)}
      secondaryActionHandler={closeDialog}
      header={t(`loyalty:games.exit.header`)}
      message={t(`loyalty:games.exit.confirmMessage`)}
    >
      <p className={styles.extraMessage}>
        {t(`loyalty:games.exit.pointsLost`)}
      </p>
    </PromptDialog>
  );
};

export default GameExitDialog;
