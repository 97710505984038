export const contractStatusElectricity = {
  undefined: -1,
  received: 0, // Προσωρινή Υποβολή
  toBeSigned: 1, // Προς Υπογραφή Πελάτη
  submitted: 2, // Οριστική Υποβολή
  beingEdited: 3, // Υπό Επεξεργασία
  forRepresentation: 4, // Προς Εκπροσώπηση
  represented: 5, // Εκπροσωπούμενη -> Δεν εμφανίζουμε κάρτα
  representationEnded: 6, //Λήξη Εκπροσώπησης
  rejected: 7, // Απορρίπτεται
  representationFailed: 8, // Αποτυχία Εκπροσώπησης
  userWithdrawal: 9, // Υπαναχώρηση Πελάτη
  pending: 10, // Εκκρεμότητα
  pendingResolved: 11, // Παραλαβή Εκκρεμοτήτων
};

export const contractStatusGas = {
  undefined: -1,
  toBeSigned: 0, // Προς Υπογραφή
  submitted: 1, // Οριστική Υποβολή
  pending: 2, // Εκκρεμότητα
  pendingResolved: 3, // Παραλαβή Εκκρεμοτήτων
  beingEdited: 4, // Υπό Επεξεργασία
  represented: 5, // Εκπροσωπούμενo -> Δεν εμφανίζουμε κάρτα
  rejected: 6, // Απορριφθέν
  userWithdrawal: 7, // Υπαναχώρηση Πελάτη
  representationEnded: 8, //Λήξη Εκπροσώπησης
  canceled: 9, // Ακυρωμένο
};

export const contractStatusElectricityTimeLimits = {
  undefined: -1,
  received: 30, // Προσωρινή Υποβολή
  toBeSigned: 30, // Προς Υπογραφή Πελάτη
  submitted: 30, // Οριστική Υποβολή
  beingEdited: 30, // Υπό Επεξεργασία
  forRepresentation: 30, // Προς Εκπροσώπηση
  represented: 7, // Εκπροσωπούμενη -> Δεν εμφανίζουμε κάρτα
  representationEnded: 6, //Λήξη Εκπροσώπησης
  rejected: 30, // Απορρίπτεται
  representationFailed: 30, // Αποτυχία Εκπροσώπησης
  userWithdrawal: 30, // Υπαναχώρηση Πελάτη
  pending: 60, // Εκκρεμότητα
  pendingResolved: 30, // Παραλαβή Εκκρεμοτήτων
};

export const contractStatusGasTimeLimits = {
  undefined: -1,
  toBeSigned: 30, // Προς Υπογραφή
  submitted: 30, // Οριστική Υποβολή
  pending: 60, // Εκκρεμότητα
  pendingResolved: 30, // Παραλαβή Εκκρεμοτήτων
  beingEdited: 30, // Υπό Επεξεργασία
  represented: 7, // Εκπροσωπούμενo -> Δεν εμφανίζουμε κάρτα
  rejected: 30, // Απορριφθέν
  userWithdrawal: 30, // Υπαναχώρηση Πελάτη
  representationEnded: 30, //Λήξη Εκπροσώπησης
  canceled: 30, // Ακυρωμένο
};
