export const scrollTo = (top = 0, left = 0, element, behavior = 'smooth') => {
  const scrollElement =
    element && typeof element.scrollTo === 'function' ? element : window;

  if (scrollElement) {
    const isSmoothScrollSupported =
      'scrollBehavior' in document.documentElement.style;

    if (isSmoothScrollSupported) {
      scrollElement.scrollTo({
        top,
        left,
        behavior,
      });
    } else {
      scrollElement.scrollTo(top, left);
    }
  }
};
