import React, { useState, useEffect } from 'react';
import cookiesStorage from 'utils/storage/cookiesStorage';
import appBannerStorage from 'utils/storage/appBannerStorage';
import UAParser from 'ua-parser-js';

const AppContext = React.createContext({
  cookiesSaved: false,
  saveCookies: () => {},
});

function AppContextProvider({ children }) {
  const [cookiesSaved, setCookiesSaved] = useState(
    cookiesStorage.getCookiesConsent()?.ts !== null
  );
  const [appBannerVisible, setAppBannerVisible] = useState(false);
  const [osName, setOsName] = useState('');
  const [loyaltyPointsAnimated, setLoyaltyPointsAnimated] = useState({
    home: 0,
    profile: 0,
    loyalty: 0,
  });

  const getLoyaltyPointsAnimated = (page) => {
    return loyaltyPointsAnimated[page] ?? 0;
  };

  const updateLoyaltyPointsAnimated = (page, points) => {
    setLoyaltyPointsAnimated((data) => {
      //this way the animation will play once the 1st time I enter each page
      //and will count up once in the page I visit first after my points change
      return {
        home: page === 'home' || data['home'] > 0 ? points : 0,
        profile: page === 'profile' || data['profile'] > 0 ? points : 0,
        loyalty: page === 'loyalty' || data['loyalty'] > 0 ? points : 0,
      };
    });
  };

  const saveCookies = (data) => {
    setCookiesSaved(true);
    cookiesStorage.setCookiesConsent(data);
  };

  const acceptCookies = () => {
    setCookiesSaved(true);
    cookiesStorage.acceptAll();
  };

  const rejectCookies = () => {
    setCookiesSaved(true);
    cookiesStorage.rejectAll();
  };

  useEffect(() => {
    const hasSeenBanner = appBannerStorage.getBanner();
    if (hasSeenBanner) return;

    try {
      const parser = new UAParser(
        navigator.userAgent || navigator.vendor || window.opera
      );

      const { type: deviceType = '' } = parser.getDevice();
      if (deviceType === 'mobile') {
        const { name: browserName = '' } = parser.getBrowser();
        if (browserName.indexOf('Safari') > -1) return;

        const { name: osName = '' } = parser.getOS();
        if (osName === 'Android' || osName === 'iOS') {
          setOsName(osName.toLowerCase());
          setAppBannerVisible(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const hideAppBanner = () => {
    setAppBannerVisible(false);
    appBannerStorage.setBanner(true);
  };

  return (
    <>
      <AppContext.Provider
        value={{
          cookiesSaved,
          saveCookies,
          acceptCookies,
          rejectCookies,
          showAppBanner: appBannerVisible,
          hideAppBanner,
          osName,
          getLoyaltyPointsAnimated,
          setLoyaltyPointsAnimated: updateLoyaltyPointsAnimated,
        }}
      >
        {children}
      </AppContext.Provider>
    </>
  );
}
export { AppContext, AppContextProvider };
