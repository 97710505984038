import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWatch, useForm } from 'react-hook-form';

import useFriendlyNameOptions from 'hooks/useFriendlyNameOptions';
import { setMeterFriendlyName } from 'api/meters';
import { getTrackingEnergyType } from 'utils/product/tracking/trackingLabels';
import { trackFriendlyNameEvent } from 'utils/product/tracking/events';

import { FriendlyNameInput } from 'components/Inputs/FriendlyNameInput';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import { ControlledSelect } from 'components/Selects/ControlledSelect';
import StandardSelectOption from 'components/Selects/StandardSelectOption';
import { useAuthUser } from 'utils/AuthUser';
import styles from './MeterNamingElement.module.scss';

const MeterNamingElement = ({
  meterNo,
  meterId,
  friendlyName,
  commodity,
  handleError,
  clearError,
  supplyNumberVisible = true,
  containerClass = '',
  extraMessageForError,
  mutate = () => {},
}) => {
  const { t, i18n } = useTranslation(['onboarding']);
  const baseName = 'onboarding:meterNaming';
  const { accountId } = useAuthUser();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitVisible, setSubmitVisible] = useState(false);

  const { selectOptions, getDefaultValue, isDefaultValue } =
    useFriendlyNameOptions(i18n.language);

  const [selectValue, setSelectValue] = useState(
    friendlyName ? getDefaultValue(friendlyName) : ''
  );
  const [inputValue, setInputValue] = useState(
    friendlyName && !isDefaultValue(selectValue) ? friendlyName : ''
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm();

  const onSubmit = (data) => {
    trackFriendlyNameEvent(
      'login_friendly_name',
      getTrackingEnergyType(commodity)
    );
    setSubmitLoading(true);
    const preparedData = {
      FriendlyName: isDefaultValue(data.select) ? data.select : data.input,
      MeterId: meterId,
      Commodity: commodity,
    };
    if (selectValue === preparedData?.FriendlyName) {
      handleError('form-is-same-friendly-name', 'forms', {
        meterNo,
        extraMessageForError,
      });
      setSubmitLoading(false);
      return;
    }
    setMeterFriendlyName([preparedData], accountId)
      .then((res) => {
        if (res.data.Errors && res.data.Errors.length > 0) {
          const errorForMeter = res.data.Errors.find((error) => {
            return error.MeterId === meterId;
          });
          handleError(errorForMeter, 'friendlyName', {
            meterNo,
            extraMessageForError,
          });

          return;
        }
        clearError();
        setSelectValue(data.select);
        setInputValue(
          data.input && !isDefaultValue(data.input) ? data.input : ''
        );
        mutate();
        setSubmitVisible(false);
      })
      .catch((error) => {
        handleError(error, 'friendlyName', {
          meterNo,
          extraMessageForError,
        });
      })
      .then(() => {
        setSubmitLoading(false);
      });
  };

  const currentSelectValue = useWatch({
    control,
    name: 'select',
    defaultValue: selectValue,
  });
  const currentInputValue = watch('input', inputValue) ?? '';

  useEffect(() => {
    setSubmitVisible(
      currentSelectValue !== selectValue || currentInputValue !== inputValue
    );
  }, [currentSelectValue, currentInputValue, selectValue, inputValue]);

  return (
    <form
      className={`${styles.container} ${containerClass}`}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      {supplyNumberVisible ? (
        <section className={styles.section}>
          <div className={styles.span}>{t(`${baseName}.meter-number`)}</div>
          <div className={styles.supplyNumberSpan}>{meterNo}</div>
        </section>
      ) : null}
      <section className={styles.sectionSelect}>
        <ControlledSelect
          label={t(`${baseName}.friendly-name`)}
          name="select"
          errors={errors}
          control={control}
          onChange={(value) => {
            //setSelectValue(value);
          }}
          defaultValue={selectValue}
        >
          {selectOptions.map((option) => {
            return (
              <StandardSelectOption key={option.value} value={option.text}>
                {option.text}
              </StandardSelectOption>
            );
          })}
        </ControlledSelect>

        {currentSelectValue && !isDefaultValue(currentSelectValue) ? (
          <section className={styles.inputSection}>
            <FriendlyNameInput
              register={register}
              errors={errors}
              label={t(`${baseName}.give-friendly-name`)}
              name="input"
              defaultValue={inputValue}
            />
          </section>
        ) : null}
      </section>
      {submitVisible ? (
        <section className={styles.buttonSection}>
          <PrimaryButtonLoader
            loading={submitLoading}
            disabled={submitLoading}
            type="submit"
          >
            {t('translation:submit')}
          </PrimaryButtonLoader>
        </section>
      ) : null}
    </form>
  );
};

export default MeterNamingElement;
