import React from 'react';

import useAccountMeters from 'data/useAccountMeters';
import ThreeColumnsLayout from 'layouts/ThreeColumnsLayout';
import RightSidebar from './RightSidebar';
import Main from './Main';
import FixedElementForBubble from 'components/HowToWizardBubble/FixedElementForBubble';
import LeftSidebar from './LeftSidebar';

const HomepageWizard = () => {
  const { meters, loading: metersLoading } = useAccountMeters({
    statusesGas: [],
    statusesElectricity: [],
  });
  return (
    <>
      <FixedElementForBubble />
      <ThreeColumnsLayout
        left={<LeftSidebar />}
        main={<Main meters={meters} metersLoading={metersLoading} />}
        right={<RightSidebar meters={meters} />}
      />
    </>
  );
};

export default HomepageWizard;
