import { trackEvent } from 'utils/analytics';
import discoveryItemTypes from '../enums/discoveryItemTypes';
import offerEntryPoints from '../enums/offerEntryPoints';

export const trackWhatsNewEvent = (action, label, customDimensions) => {
  trackEvent('Loyalty Whats New', action, label, customDimensions);
};

export const trackHomeEvent = (action, label, customDimensions) => {
  trackEvent('Home', action, label, customDimensions);
};

export const trackQuestionnaireEvent = (action, label, customDimensions) => {
  trackEvent('Questionnaire', action, label, customDimensions);
};

export const trackLoyaltyOfferEvent = (action, label, customDimensions) => {
  trackEvent('Loyalty Offer', action, label, customDimensions);
};

export const trackLoyaltyPageVibesEvent = (action, label, customDimensions) => {
  trackEvent('Loyalty Page - Vibes Card', action, label, customDimensions);
};

export const trackLoyaltyPageEvent = (action, label, customDimensions) => {
  trackEvent('Loyalty Page', action, label, customDimensions);
};

export const trackLoyaltyBillPaymentEvent = (
  action,
  label,
  customDimensions
) => {
  trackEvent('Loyalty Bill Payment', action, label, customDimensions);
};

export const trackGameEvent = (action, label, customDimensions) => {
  trackEvent('Game Overview', action, label, customDimensions);
};
export const trackGamesEvent = (action, label, customDimensions) => {
  trackEvent('Games', action, label, customDimensions);
};

export const trackNotificationsEvent = (action, label, customDimensions) => {
  trackEvent('Notifications', action, label, customDimensions);
};

export const trackBirthdayEvent = (action, label, customDimensions) => {
  trackEvent('Birthday', action, label, customDimensions);
};

export const trackMMSEvent = (action, label, customDimensions) => {
  trackEvent('MMS Loyalty', action, label, customDimensions);
};

export const trackEbillEvent = (action, label, customDimensions) => {
  trackEvent('Ebill Loyalty', action, label, customDimensions);
};

export const trackStandingOrderEvent = (action, label, customDimensions) => {
  trackEvent('Standing orders Loyalty', action, label, customDimensions);
};

export const trackShopEvent = (action, label, customDimensions) => {
  trackEvent('Shop', action, label, customDimensions);
};

export const trackLoyaltyDiscoveryItemSelection = (discoveryItem, page) => {
  const prefix = page === offerEntryPoints.home ? 'home' : 'offer_section';

  if (discoveryItem._type === discoveryItemTypes.offer) {
    trackLoyaltyOfferEvent(`${prefix}_loyalty_offer_selected`, '', {
      offer_name: discoveryItem.title,
    });
    return;
  }

  if (discoveryItem._type === discoveryItemTypes.contest) {
    trackLoyaltyOfferEvent(`${prefix}_loyalty_contest_selected`, '', {
      contest_name: discoveryItem.title,
    });
    return;
  }
  if (discoveryItem._type === discoveryItemTypes.game) {
    trackLoyaltyOfferEvent(`${prefix}_loyalty_game_selected`, '', {
      loyalty_game_name: discoveryItem.title,
    });
    return;
  }
};
