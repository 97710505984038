import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthUser } from 'utils/AuthUser';
import { setAccountEbilling } from 'api/account';
import { receivePoints } from 'api/loyalty';
import { getRewardPoints } from 'utils/product/loyalty/pointsUtils';
import { mutateLoyaltyHistory } from 'data/useLoyaltyHistory';
import { mutateLoyaltyPoints } from 'data/useRewardSchemeAccounts';
import { logWaveServiceFailure } from 'utils/product/logs';
import {
  SuccessDialog,
  PromptDialog,
} from 'components/PromptDialogs/PromptDialog';
import { LocalisedErrorBox } from 'components/MessageBox/MessageBox';
import useDialog from 'hooks/useDialog';
import commodities from 'utils/product/enums/commodities';
import useError from 'hooks/useError';
import RewardDialog from 'components/Loyalty/RewardDialog';

import { EbillSuccessMessage } from './EbillSuccessMessage';

const MultipleEbillsActivationDialog = ({
  open,
  closeDialog,
  metersEligibleForLoyaltyReward,
  waveId,
  mutate,
}) => {
  const { t } = useTranslation(['translation', 'bills', 'management']);

  const { loyaltyCustomerId, accountId, isLoyaltyCustomer, user } =
    useAuthUser();
  const { error, handleError, clearError } = useError();
  const [rewardPoints, setRewardPoints] = useState();
  const [loading, setLoading] = useState(false);
  const rewardDialog = useDialog();
  const successDialog = useDialog();

  const receiveLoyaltyPoints = () => {
    const items = metersEligibleForLoyaltyReward?.map((meter) => ({
      id: meter?.Id,
    }));

    const postData = {
      customer: {
        customerId: loyaltyCustomerId,
      },
      type: 'CUSTOM',
      ruleId: waveId,
      items: [...items],
    };

    if (waveId) {
      return receivePoints(postData)
        .then((res) => {
          setRewardPoints(getRewardPoints(res?.data, waveId));
          mutate();
          mutateLoyaltyHistory(loyaltyCustomerId);
          mutateLoyaltyPoints(loyaltyCustomerId);
          closeDialog();
          rewardDialog.show();
        })
        .catch((error) => {
          closeDialog();
        });
    }
    logWaveServiceFailure(
      'Loyalty | Ebill reward failed - empty waveId',
      loyaltyCustomerId,
      waveId,
      postData
    );
    closeDialog();
  };

  const handleAllEbills = async () => {
    setLoading(true);
    setAccountEbilling(true, commodities.energyGas, accountId)
      .then((res) => {
        mutate();
        if (isLoyaltyCustomer && metersEligibleForLoyaltyReward.length > 0) {
          return receiveLoyaltyPoints();
        } else {
          closeDialog();
          successDialog.show();
        }
      })
      .catch((error) => {
        handleError(error, 'ebill');
      })
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <PromptDialog
        open={open}
        closeDialog={closeDialog}
        header={t('management:ebill.activation-header')}
        message={t('management:ebill.activateAll-confirmation-message')}
        primaryActionText={t('translation:confirm')}
        primaryActionHandler={handleAllEbills}
        primaryActionLoading={loading}
        showSecondaryAction={true}
        showCloseButton={false}
        secondaryActionHandler={closeDialog}
      >
        <LocalisedErrorBox
          open={error !== null}
          closeBox={clearError}
          {...(error || {})}
          distanceBottom=""
        />
      </PromptDialog>
      <RewardDialog
        open={rewardDialog.isOpen}
        closeDialog={rewardDialog.close}
        points={rewardPoints}
        icon={null}
        header={t(`loyalty:collection-success.E_BILL.header`)}
        message={t(`loyalty:collection-success.E_BILL.messageAll`)}
        subMessage={
          <EbillSuccessMessage
            email={user?.ContactEmail}
            phone={user?.ContactPhone}
          />
        }
      ></RewardDialog>
      <SuccessDialog
        open={successDialog.isOpen}
        closeDialog={successDialog.close}
        header={t(`loyalty:collection-success.E_BILL.header`)}
      >
        <EbillSuccessMessage
          email={user?.ContactEmail}
          phone={user?.ContactPhone}
        />
      </SuccessDialog>
    </>
  );
};

export default MultipleEbillsActivationDialog;
