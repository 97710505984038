import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { InvoiceNumberInput } from 'components/Inputs/InvoiceNumberInput';
import { DatePicker } from 'components/Inputs/DatePicker';

import styles from './InvoiceData.module.scss';

const InvoiceData = () => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();
  const { t } = useTranslation(['requests']);
  const [selectedDate, setSelectedDate] = useState(null);

  return (
    <article className={styles.container}>
      <h3 className={styles.title}>{t(`requests:billing.invoice-details`)}</h3>
      <InvoiceNumberInput
        register={register}
        errors={errors}
        name="invoiceNumber"
        label={t(`requests:billing.invoice-number`)}
      />
      <DatePicker
        label={t('requests:billing.invoice-date')}
        value={selectedDate}
        onChange={setSelectedDate}
        disableFuture={true}
        errors={errors}
        required={true}
        control={control}
        name="invoiceDate"
        disableToolbar={false}
        customToolbar={true}
      />
    </article>
  );
};

export default InvoiceData;
