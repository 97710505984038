import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { trackHomeConsumptionsEvent } from 'utils/product/tracking/events';
import useDialog from 'hooks/useDialog';

import { SecondaryButton, PrimaryButton } from 'components/Buttons/Buttons';
import MeasurementRegistration from 'components/MeasurementRegistration/MeasurementRegistration';
import Divider from '@material-ui/core/Divider';
import WattContainer from 'components/WattContainer/WattContainer';
import PeriodSpan from 'components/PeriodSpan/PeriodSpan';
import EnergyReportsCta from 'components/Consumptions/MediumVoltage/EnergyReportsCta';
import { ReactComponent as RenewableSourcesIcon } from 'assets/icons/RenewableSourcesIcon.svg';

import styles from './ConsumptionInfoElements.module.scss';

const ConsumptionAmount = ({
  amount,
  size,
  unitSize,
  weight = 'bold',
  containerClass,
}) => {
  return (
    <WattContainer
      size={size}
      unitSize={unitSize}
      weight={weight}
      amount={amount}
      containerClass={containerClass}
    />
  );
};

const ConsumptionMessage = ({ text, isRenewable = false, size = 'm' }) => {
  const { t } = useTranslation(['consumptions']);
  return (
    <div className={styles.message}>
      {isRenewable && (
        <figure>
          <RenewableSourcesIcon />
        </figure>
      )}
      <section>
        <p className={styles[`message-${isRenewable ? 'm' : size}`]}>
          {isRenewable ? t(`consumptions:renewable.message`) : text}
        </p>
      </section>
    </div>
  );
};

const ConsumptionCaption = ({ text }) => {
  return <span className={styles.caption}>{text}</span>;
};

const DividedIndications = ({
  day,
  night,
  hasNightConsumption = false,
  dayCaption,
  nightCaption,
  size = 'xxxl',
  unitSize = 'xl',
  dividedSize = 'xxl',
  dividedUnitSize = 's',
}) => {
  return (
    <>
      {hasNightConsumption ? (
        <>
          <section className={styles.dividedIndications}>
            <ConsumptionAmount
              amount={day}
              size={dividedSize}
              unitSize={dividedUnitSize}
            />
            <Divider orientation="vertical" className={styles.divider} />
            <ConsumptionAmount
              amount={night}
              size={dividedSize}
              unitSize={dividedUnitSize}
            />
          </section>
          <ConsumptionCaption text={nightCaption} />
        </>
      ) : (
        <>
          <ConsumptionAmount amount={day} size={size} unitSize={unitSize} />
          <ConsumptionCaption text={dayCaption} />
        </>
      )}
    </>
  );
};

const PredictionIndications = ({
  dayEstimation,
  nightEstimation,
  hasNightConsumption = false,

  type,
  size,
  unitSize,
  weight = 'bold',
}) => {
  const { t } = useTranslation(['consumptions']);

  return (
    <DividedIndications
      hasNightConsumption={hasNightConsumption}
      day={dayEstimation}
      night={nightEstimation}
      dayCaption={t(`consumptions:prediction`)}
      nightCaption={t(`consumptions:day-night-prediction`)}
    />
  );
};

const ConsumptionIndications = ({
  dayTimeConsumption,
  nightTimeConsumption,
  hasNightConsumption = false,
  periodStart,
  periodEnd,
  size = 'xxxl',
  unitSize = 'xl',
  dividedSize = 'xxl',
  dividedUnitSize = 's',
}) => {
  const { t } = useTranslation(['consumptions']);

  return (
    <>
      <DividedIndications
        hasNightConsumption={hasNightConsumption}
        day={dayTimeConsumption}
        night={nightTimeConsumption}
        dayCaption={t(`consumptions:clearing-consumption`)}
        nightCaption={t(`consumptions:day-night-consumption`)}
        size={size}
        unitSize={unitSize}
        dividedSize={dividedSize}
        dividedUnitSize={dividedUnitSize}
      />
      <PeriodSpan
        periodStart={periodStart}
        periodEnd={periodEnd}
        spanClass={styles.period}
      />
    </>
  );
};

const RegisteredIndications = ({
  dayEstimation,
  nightEstimation,
  hasNightConsumption = false,
  userRegistrationDay,
  userRegistrationNight,
  userRegistrationDate,
  type,
  size,
  unitSize,
  weight = 'bold',
}) => {
  const { t } = useTranslation(['consumptions']);
  return (
    <>
      {hasNightConsumption ? (
        <>
          <section className={styles.dividedIndications}>
            <ConsumptionAmount
              amount={userRegistrationDay}
              size="xxl"
              unitSize="s"
            />
            {dayEstimation > 0 && (
              <ConsumptionAmount
                amount={dayEstimation}
                size="s"
                unitSize="s"
                weight="bold"
                containerClass={styles.deleted}
              />
            )}
          </section>
          <ConsumptionCaption text={t(`consumptions:measurement-day`)} />

          <section className={styles.dividedIndications}>
            <ConsumptionAmount
              amount={userRegistrationNight}
              size="xxl"
              unitSize="s"
            />
            {nightEstimation > 0 && (
              <ConsumptionAmount
                amount={nightEstimation}
                size="s"
                unitSize="s"
                weight="bold"
                containerClass={styles.deleted}
              />
            )}
          </section>
          <ConsumptionCaption text={t(`consumptions:measurement-night`)} />
        </>
      ) : (
        <>
          {dayEstimation > 0 && (
            <ConsumptionAmount
              amount={dayEstimation}
              size="xl"
              unitSize="xl"
              containerClass={styles.deleted}
            />
          )}
          <ConsumptionAmount
            amount={userRegistrationDay}
            size="xxxl"
            unitSize="xl"
          />
          {userRegistrationDay ? (
            <ConsumptionCaption text={t(`consumptions:measurement`)} />
          ) : null}
        </>
      )}
      <PeriodSpan
        periodStart={userRegistrationDate}
        spanClass={styles.period}
      />
    </>
  );
};

const ConsumptionAnalysisButton = ({ meterNo }) => {
  const { t } = useTranslation(['translation']);
  return (
    <Link
      className={styles.link}
      to={meterNo ? `/consumptions/${meterNo}` : ''}
      onClick={() => {
        trackHomeConsumptionsEvent('home_consumption_analysis');
      }}
    >
      <SecondaryButton className={styles.button}>
        {t('translation:analysis')}
      </SecondaryButton>
    </Link>
  );
};

const RegisterOwnReadingsButton = ({ meter, refreshData }) => {
  const { t } = useTranslation(['translation']);
  const registrationDialog = useDialog();

  return (
    <>
      <PrimaryButton
        className={styles.primaryButton}
        onClick={() => {
          registrationDialog.show();
          trackHomeConsumptionsEvent('home_consumption_count');
        }}
      >
        {t('translation:measure-yourself')}
      </PrimaryButton>
      {meter && (
        <MeasurementRegistration
          open={registrationDialog.isOpen}
          closeDialog={registrationDialog.close}
          meter={meter}
          refreshData={refreshData}
        />
      )}
    </>
  );
};

const EnergyReportsButton = ({ meter, isHome = true }) => {
  return (
    <EnergyReportsCta
      buttonClass={styles.primaryButton}
      meter={meter}
      isHome={isHome}
    />
  );
};

export {
  ConsumptionMessage,
  ConsumptionAnalysisButton,
  RegisterOwnReadingsButton,
  EnergyReportsButton,
  PredictionIndications,
  ConsumptionIndications,
  RegisteredIndications,
};
