import { useAuthUser } from 'utils/AuthUser';

import { mergeDiscounts } from 'utils/product/enaUtils';
import useData from './useData';
//import solarisDiscountHistory from 'data/testData/solarisPaymentHistory';

export default function useSolarisDiscountHistory() {
  const { accountId } = useAuthUser();

  const { data, error, loading } = useData(
    `/solaris/discountHistory?accountId=${accountId}`
  );
  const discounts = mergeDiscounts(data);

  return {
    loading,
    error,
    discounts,
    data: data?.MeterDiscountHistories ?? [],
  };
}
