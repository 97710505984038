import React from 'react';
import { useTranslation } from 'react-i18next';

import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import { useLoyaltyPoints } from 'data/useRewardSchemeAccounts';
import { useAuthUser } from 'utils/AuthUser';
import MyEnergyPointsWidget from './MyEnergyPointsWidget';

import styles from './MyEnergyPoints.module.scss';

const MyEnergyPoints = ({ type = 'banner-small', distance = 'xs', page }) => {
  const { t } = useTranslation(['loyalty']);
  const {
    loading: userLoading,
    loyaltyLoading,
    loyaltyCustomerId,
    isLoyaltyCustomer,
  } = useAuthUser();
  const { data, loading } = useLoyaltyPoints(
    loyaltyCustomerId,
    !isLoyaltyCustomer
  );
  const loyaltyPoints = Math.floor(data?.remainingAmount ?? 0);

  return (
    <WidgetSkeleton
      heightInPixels={type === 'banner' ? 104 : 96}
      show={loading || userLoading || loyaltyLoading}
      title={
        type === 'banner-small' && (
          <WidgetHeader title={t('loyalty:vibes-profile.header')} />
        )
      }
      distance={distance}
      hideTitle={true}
      containerClass={styles['widget-container']}
    >
      <MyEnergyPointsWidget
        points={loyaltyPoints}
        type={type}
        distance={distance}
        page={page}
      />
    </WidgetSkeleton>
  );
};

export default MyEnergyPoints;
