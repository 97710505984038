import commodities from 'utils/product/enums/commodities';
import {
  meterStatusesElectricity,
  meterStatusesGas,
} from '../enums/meterStatuses';

export const findMetersWithoutRecurringReward = (
  meters,
  rewardsLoyaltyHistory,
) => {
  return meters?.filter(
    (meter) =>
      !rewardsLoyaltyHistory?.find((reward) => {
        return reward?.items?.find((item) => item.id === meter.Id);
      }),
  );
};

export const isRecurringAvailable = (meter) => {
  return (
    (commodities.gas === meter.Commodity &&
      (meter?.MeterStatus === meterStatusesGas.active ||
        meter?.MeterStatus === meterStatusesGas.terminated)) ||
    (commodities.energy === meter.Commodity &&
      (meter?.MeterStatus === meterStatusesElectricity.active ||
        meter?.MeterStatus === meterStatusesElectricity.terminated))
  );
};

export const isValidStandingOrderManagementEntry = (
  meter,
  hasActiveRecurringCard,
) => {
  return (
    (meter.Commodity === commodities.gas &&
      (meter?.MeterStatus === meterStatusesGas.active ||
        (meter?.MeterStatus === meterStatusesGas.terminated &&
          hasActiveRecurringCard))) ||
    (meter.Commodity === commodities.energy &&
      (meter?.MeterStatus === meterStatusesElectricity.active ||
        (meter?.MeterStatus === meterStatusesElectricity.terminated &&
          hasActiveRecurringCard)))
  );
};

export const isMeterEligibleForReward = (meter) => {
  return (
    meter &&
    ((meter.Commodity === commodities.energy &&
      meter?.MeterStatus === meterStatusesElectricity.active) ||
      (meter.Commodity === commodities.gas &&
        meter?.MeterStatus === meterStatusesGas.active))
  );
};
