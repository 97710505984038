import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthUser } from 'utils/AuthUser';
import Widget from 'components/Widget/Widget';
import DiscoveryItemOverview from 'components/Discovery/ItemOverview';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import Slider from 'components/Slider/Slider';
import WidgetHeaderLink from 'components/WidgetHeader/WidgetHeaderLink';
import { trackOffersEvent } from 'utils/product/tracking/events';
import { useDiscovery } from 'data/useDiscovery';
import useGeneralConfig from 'data/useGeneralConfig';
import offerEntryPoints from 'utils/product/enums/offerEntryPoints';

import styles from './OffersSectionWidget.module.scss';

const OffersSectionWidget = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const {
    data: { offersHomeLimit = 12 },
  } = useGeneralConfig() || {};
  const { data: results, loading } = useDiscovery(language);
  const { loyaltyLoading } = useAuthUser();

  const { t } = useTranslation([
    'translation',
    'homepage',
    /* those are not used here. however they are added so as to be pre-loaded and not show loader again on Home */
    'consumptions',
    'measurementRegistration',
  ]);
  const onSwipe = () => {
    trackOffersEvent('home_offer_swipe');
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    width: '100%',
    dotsClass: `slick-dots`,
  };

  const title = (
    <WidgetHeaderLink
      title={t(`homepage:my-offers.title`)}
      secondTitle={t('homepage:all.title')}
      linkTo={'/offers'}
      onLinkClicked={() => {
        trackOffersEvent('home_view_all_offers');
      }}
    />
  );

  return (
    <WidgetSkeleton
      heightInPixels={314}
      show={loading || loyaltyLoading}
      title={title}
    >
      <Widget
        background="white"
        distance="m"
        widgetClass={styles['widget-slider']}
        title={title}
      >
        {results.length > 0 && (
          <Slider arrows={false} onSwipe={onSwipe} settings={settings}>
            {results.slice(0, offersHomeLimit).map((item) => (
              <DiscoveryItemOverview
                item={item}
                key={item._key}
                size="m"
                page={offerEntryPoints.home}
              />
            ))}
          </Slider>
        )}
      </Widget>
    </WidgetSkeleton>
  );
};

export default OffersSectionWidget;
