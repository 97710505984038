import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Checkbox from 'components/Checkbox/Checkbox';

import styles from './ContactPreferences.module.scss';

const ContactPreferences = ({ defaultValues = {}, onUpdate }) => {
  const { register, setValue } = useFormContext();
  const { t } = useTranslation(['newClient']);

  const handleChange = (event) => {
    const isChecked = event.target.checked;
    if (event.target.name === 'sendMarketingMaterials') {
      onUpdate({
        sendMarketingMaterials: isChecked,
        sendEmail: isChecked ? true : defaultValues.sendEmail,
        sendViber: isChecked ? true : defaultValues.sendViber,
        sendSms: isChecked ? true : defaultValues.sendSms,
      });
      setValue('sendMarketingMaterials', isChecked);
      setValue('sendEmail', isChecked ? true : defaultValues.sendEmail);
      setValue('sendViber', isChecked ? true : defaultValues.sendViber);
      setValue('sendSms', isChecked ? true : defaultValues.sendSms);
    } else {
      onUpdate({
        [event.target.name]: event.target.checked,
        sendMarketingMaterials:
          isChecked === false ? false : defaultValues.sendMarketingMaterials,
      });
      setValue(
        'sendMarketingMaterials',
        isChecked === false ? false : defaultValues.sendMarketingMaterials
      );
      setValue(event.target.name, event.target.checked);
    }
  };

  return (
    <article className={styles.container}>
      <h3 className={styles.header}>
        {t(`newClient:contactPreferences.header`)}
      </h3>
      <p className={styles.subheader}>
        {t(`newClient:contactPreferences.subheader`)}
      </p>
      <Checkbox
        value={defaultValues.sendEmail ?? false}
        changeHandler={handleChange}
        name="sendEmail"
        label={t(`newClient:contactPreferences.email`)}
        register={register}
        labelClass={styles.checkbox}
      />
      <Checkbox
        value={defaultValues.sendSms ?? false}
        changeHandler={handleChange}
        name="sendSms"
        label={t(`newClient:contactPreferences.sms`)}
        register={register}
        labelClass={styles.checkbox}
      />
      <Checkbox
        value={defaultValues.sendViber ?? false}
        changeHandler={handleChange}
        name="sendViber"
        label={t(`newClient:contactPreferences.viber`)}
        register={register}
        labelClass={styles.checkbox}
      />
      <Checkbox
        value={defaultValues.sendMarketingMaterials ?? false}
        changeHandler={handleChange}
        name="sendMarketingMaterials"
        label={t(`newClient:contactPreferences.none`)}
        register={register}
        labelClass={styles.checkbox}
      />
    </article>
  );
};

export default ContactPreferences;
