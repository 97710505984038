import React from 'react';

import Widget from 'components/Widget/Widget';

import styles from './WidgetSkeleton.module.scss';

const CreditCardSkeleton = ({
  show = false,
  children,
  heightInPixels = 196,
  widthInPixels,
  distance = 'm',
  size = 'm',
  hideTitle = false,
  background,
  ...rest
}) => {
  return show ? (
    <Widget
      size={size}
      distance={distance}
      widgetClass={styles.widgetCard}
      containerClass={styles.widgetCardContainer}
      background={background}
      {...rest}
      style={{
        width: widthInPixels ? `${widthInPixels}px` : '100%',
        height: `${heightInPixels}px`,
      }}
    >
      <div className={styles.content} />
    </Widget>
  ) : (
    children ?? null
  );
};
export default CreditCardSkeleton;
