import React from 'react';
import { useTranslation } from 'react-i18next';

import useLocalisedError from 'hooks/useLocalisedError';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import { ReactComponent as SuccessIcon } from 'assets/icons/CheckGreen.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/ErrorCircle.svg';
import { ReactComponent as WarningTriangleIcon } from 'assets/icons/WarningTriangleIcon.svg';

import {
  PrimaryButtonLoader,
  SecondaryButtonLoader,
} from 'components/Buttons/Buttons';

import styles from './PromptDialog.module.scss';

const SuccessDialog = ({ showPrimaryAction = false, ...props }) => {
  return (
    <PromptDialog
      icon={<SuccessIcon width={24} height={24} />}
      showPrimaryAction={showPrimaryAction}
      {...props}
    />
  );
};

const ErrorDialog = ({
  showPrimaryAction = false,
  header,
  message,
  ...props
}) => {
  const { t } = useTranslation(['errors/generic']);
  return (
    <PromptDialog
      icon={<ErrorIcon />}
      showPrimaryAction={showPrimaryAction}
      header={header ? header : t('errors/generic:general.title')}
      message={message ? message : t('errors/generic:general.message')}
      {...props}
    />
  );
};

const WarningDialog = ({
  showPrimaryAction = true,
  showCloseButton = false,
  header,
  message,
  ...props
}) => {
  const { t } = useTranslation(['translation']);
  return (
    <PromptDialog
      icon={<WarningTriangleIcon />}
      showPrimaryAction={showPrimaryAction}
      showCloseButton={showCloseButton}
      header={header ? header : t('translation:attention')}
      message={message}
      {...props}
    />
  );
};

const LocalisedErrorDialog = ({
  errorCode,
  errorScope,
  errorParams = {},
  showPrimaryAction = false,
  ...props
}) => {
  const { title, message } = useLocalisedError(
    errorCode,
    errorScope,
    errorParams,
  );

  return (
    <PromptDialog
      icon={<ErrorIcon />}
      showPrimaryAction={showPrimaryAction}
      header={title}
      message={message}
      {...props}
    />
  );
};

const PromptDialog = ({
  open = true,
  closeDialog,
  icon,
  header,
  message,
  children,
  showCloseButton = true,
  disableBackdropClick = true,
  showPrimaryAction = true,
  primaryActionButton = null,
  primaryActionText,
  primaryActionLoading = false,
  primaryActionHandler,
  showSecondaryAction = false,
  secondaryActionButton = null,
  secondaryActionText,
  secondaryActionLoading = false,
  secondaryActionHandler,
  iconClass = '',
  ...rest
}) => {
  const { t } = useTranslation(['translation']);

  const primaryButton = showPrimaryAction ? (
    primaryActionButton ? (
      primaryActionButton
    ) : (
      <PrimaryButtonLoader
        className={styles.dialogButton}
        onClick={() =>
          typeof primaryActionHandler === 'function'
            ? primaryActionHandler()
            : null
        }
        loading={primaryActionLoading}
        disabled={primaryActionLoading}
      >
        {primaryActionText ?? t('translation:ok')}
      </PrimaryButtonLoader>
    )
  ) : null;

  const secondaryButton = showSecondaryAction ? (
    secondaryActionButton ? (
      secondaryActionButton
    ) : (
      <SecondaryButtonLoader
        className={styles.dialogButton}
        onClick={() =>
          typeof secondaryActionHandler === 'function'
            ? secondaryActionHandler()
            : null
        }
        loading={secondaryActionLoading}
        disabled={secondaryActionLoading}
      >
        {secondaryActionText ?? t('translation:cancel')}
      </SecondaryButtonLoader>
    )
  ) : null;

  return (
    <DialogOverlay
      open={open}
      closeDialog={closeDialog}
      disableBackdropClick={disableBackdropClick}
      dialogClass={styles.dialog}
      contentClass={styles.dialogContent}
      showCloseButton={showCloseButton}
      actionsClass={styles.dialogActions}
      dialogActions={
        (primaryButton || secondaryButton) && (
          <>
            {secondaryButton}
            {primaryButton}
          </>
        )
      }
      {...rest}
    >
      {icon &&
        React.cloneElement(icon, {
          ...icon.props,
          className: `${styles.icon} ${iconClass}`,
        })}
      {header && <p className={styles.header}>{header}</p>}
      <p className={styles.message}>{message}</p>
      {children}
    </DialogOverlay>
  );
};

export {
  PromptDialog,
  SuccessDialog,
  ErrorDialog,
  WarningDialog,
  LocalisedErrorDialog,
};
