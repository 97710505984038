import React from 'react';
import { useTranslation } from 'react-i18next';

import ThreeColumnsLayout from 'layouts/ThreeColumnsLayout';
import RightSidebar from './RightSidebar';
import LeftSidebar from './LeftSidebar';
import Main from './Main';
import HomeFooter from 'components/HomeFooter/HomeFooter';

const Homepage = ({ component = null }) => {
  const { t } = useTranslation(['homepage']);

  return (
    <>
      {component}
      <ThreeColumnsLayout
        head={{
          title: t('homepage:home.title'),
          metaTags: [{ name: 'title', content: t('homepage:home.title') }],
        }}
        left={<LeftSidebar />}
        main={<Main />}
        right={<RightSidebar />}
      />
      <HomeFooter />
    </>
  );
};

export default Homepage;
