import React from 'react';

import ImageLoader from 'components/Skeletons/ImageLoader';
import Questionnaire from 'components/WebComponentWrappers/Questionnaire';

const Question = ({ question, onUserAnswer, answers }) => {
  const transformedQuestion = {
    ...question,
    options: question?.options?.map((x) => {
      return {
        ...x,
        isUserAnswer:
          !!answers &&
          answers[question.clientId]?.length > 0 &&
          answers[question.clientId].indexOf(x.clientId) > -1,
      };
    }),
  };
  return (
    question && (
      <section>
        <ImageLoader
          src={transformedQuestion?.backgroundImage}
          alt={transformedQuestion?.title}
        />
        <Questionnaire
          questions={[transformedQuestion]}
          questionFontSize={20}
          questionColor="FFFFFF"
          answerBorderWidth={0}
          answerColor="FFFFFF"
          answerBg="transparent"
          answerPadding={0}
          answerGap={24}
          borderRadius={0}
          pagePadding={0}
          onUserAnswer={onUserAnswer}
        />
      </section>
    )
  );
};

export default Question;
