import commodities from 'utils/product/enums/commodities';
import meterUsageTypes from 'utils/product/enums/meterUsageTypes';
import shopEnergyTypes from 'utils/product/enums/shopEnergyTypes';
import programTypes from 'utils/product/enums/programTypes';
import supplyTypes from 'utils/product/enums/supplyTypes';
import {
  meterStatusesElectricity,
  meterStatusesGas,
} from './enums/meterStatuses';

export const getAvailableMetersForEnaActivation = (meters) => {
  if (!meters) return [];

  return meters.filter(
    (meter) =>
      meter.Commodity === commodities.energy &&
      meter.solarisInfo?.CanActivateSolaris === true,
  );
};

export const isMeterEligibleForActivation = (meter) => {
  return meter.IsActive;
};

export const isMeterEligibleForEnergyType = (meter, energyType, tariffGroups) =>
  ((energyType === shopEnergyTypes.gas ||
    energyType === shopEnergyTypes.combined) &&
    meter.Commodity === commodities.gas &&
    tariffGroups?.includes(meter?.Tariffgroup)) ||
  ((energyType === shopEnergyTypes.electricity ||
    energyType === shopEnergyTypes.eco ||
    energyType === shopEnergyTypes.combinedElectricity) &&
    meter.Commodity === commodities.energy &&
    meter.SupplyType === supplyTypes.LowVoltage);

const isAccountEligibleForTheSelectedCombinedProgram = (
  metersData,
  energyType,
) => {
  if (
    energyType !== shopEnergyTypes.combined &&
    energyType !== shopEnergyTypes.combinedElectricity
  )
    return true;

  if (energyType === shopEnergyTypes.combined) {
    const hasActiveElMeter = metersData?.some(
      (meter) =>
        meter.MeterStatus === meterStatusesElectricity.active &&
        meter.Commodity === commodities.energy,
    );

    return hasActiveElMeter;
  }

  if (energyType === shopEnergyTypes.combinedElectricity) {
    const hasActiveGasMeter = metersData?.some(
      (meter) =>
        meter.MeterStatus === meterStatusesGas.active &&
        meter.Commodity === commodities.gas,
    );
    return hasActiveGasMeter;
  }
};

const comparisonOperatorsMapper = {
  eq: (a, b) => a === b,
  lt: (a, b) => a < b,
  lte: (a, b) => a <= b,
  gt: (a, b) => a > b,
  gte: (a, b) => a >= b,
};

const isMeterEligibleForAgreedPower = (meter, agreedPower) => {
  if (!agreedPower) return true;
  if (!meter?.AgreedPower) return false;

  const { condition: operator, value } = agreedPower;
  const compare = comparisonOperatorsMapper?.[operator];

  if (!compare) return true;

  return compare(meter?.AgreedPower, value);
};

const isMeterEligibleForPpcType = (meter, programsPpcTypes) => {
  if (!programsPpcTypes || programsPpcTypes?.length === 0) return true;

  return programsPpcTypes?.includes(meter?.PPCType);
};

export const isMeterEligibleForProgramType = (meter, programType) => {
  if (programType === programTypes.home) {
    return meter.MeterUsage === meterUsageTypes.home;
  }

  if (programType === programTypes.business) {
    const _businessUsageTypes =
      meter.Commodity === commodities.gas
        ? [meterUsageTypes.business, meterUsageTypes.industrial]
        : [meterUsageTypes.business];
    return _businessUsageTypes.indexOf(meter.MeterUsage) > -1;
  }

  return false;
};

export const getAvailableMetersForProgramChange = (
  meters,
  energyType,
  programType,
  tariffGroups,
  agreedPower,
  ppcTypes,
) => {
  if (
    !meters ||
    !isAccountEligibleForTheSelectedCombinedProgram(meters, energyType)
  )
    return [];

  return meters?.filter(
    (meter) =>
      isMeterEligibleForActivation(meter) &&
      isMeterEligibleForEnergyType(meter, energyType, tariffGroups) &&
      isMeterEligibleForAgreedPower(meter, agreedPower) &&
      isMeterEligibleForPpcType(meter, ppcTypes) &&
      isMeterEligibleForProgramType(meter, programType),
  );
};
