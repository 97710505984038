import api from 'api/wave';
import endpoints from 'utils/product/enums/endpoints';

export const getCustomer = (customerId) => {
  return api.get(`${endpoints.loyalty.customer}/?customerId=${customerId}`);
};

export const addCustomer = (data) => {
  return api.post(endpoints.loyalty.customers, data);
};

export const patchCustomer = (customerId, data) => {
  return api.patch(
    `${endpoints.loyalty.customers}/?customerId=${customerId}`,
    data
  );
};

export const submitQuestionnaire = (data) => {
  return api.post(endpoints.loyalty.submitQuestionnaire, data);
};

export const rewardPayment = (data) => {
  return api.post(endpoints.loyalty.rewardPayment, data);
};

export const redeemRedemptionOption = (data) => {
  return api.post(endpoints.loyalty.redeemOffer, data);
};

export const completeMiniGameSession = (miniGameId, data) => {
  return api.post(
    `${endpoints.loyalty.gameSession}/${miniGameId}/complete`,
    data
  );
};

export const openMiniGameSession = (miniGameId, customerId) => {
  return api.get(
    `${endpoints.loyalty.games}/${miniGameId}/sessions/open/?customerId=${customerId}`
  );
};

export const receivePoints = (data) => {
  return api.post(endpoints.loyalty.receivePoints, data);
};

export const acceptWinningParticipation = (contestId, data) => {
  return api.post(
    `${endpoints.loyalty.contests}/${contestId}/winners/response`,
    data
  );
};

export const getGooglePayPassJWT = (couponCode, customerId) => {
  return api.get(
    `${endpoints.loyalty.addToGoogleWallet}?code=${couponCode}&customerId=${customerId}`
  );
};
export const downloadApplePass = (couponCode, customerId) => {
  return api.get(
    `${endpoints.loyalty.addToAppleWallet}?code=${couponCode}&customerId=${customerId}`,
    {
      responseType: 'arraybuffer',
    }
  );
};

export const setLoyaltyNotificationAsRead = (notificationId) => {
  return api.patch(`${endpoints.loyalty.notifications}/${notificationId}`, {
    hasBeenViewed: true,
  });
};
