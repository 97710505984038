import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ShopWidget from 'components/Shop/Overview/ShopWidget';
import FullWidthLayout from 'layouts/FullWidthLayout';

const Shop = () => {
  const { t } = useTranslation(['header']);
  const { programType = 'home' } = useParams();

  return (
    <FullWidthLayout
      head={{
        title: t('header:shop'),
        metaTags: [{ name: 'title', content: t('header:shop') }],
      }}
    >
      <ShopWidget programType={programType} />
    </FullWidthLayout>
  );
};

export default Shop;
