import useSWR from 'swr';
import { fetcher } from 'api/wave';
import endpoints from 'utils/product/enums/endpoints';

const useLoyaltyNotifications = (loyaltyCustomerId, lang) => {
  const { data, error, loading, mutate } = useSWR(
    !loyaltyCustomerId
      ? null
      : `${
          endpoints.loyalty.notifications
        }/?deliveryMethod=IN_APP&customerId=${loyaltyCustomerId}&locale=${
          lang === 'en' ? 'en-US' : 'el-GR'
        }`,
    fetcher
  );

  return {
    loading,
    error,
    data: data?.data,
    mutate,
  };
};

export default useLoyaltyNotifications;
