import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDiscovery } from 'data/useDiscovery';
import SingleColumnLayout from 'layouts/SingleColumnLayout';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import DiscoveryItemOverview from 'components/Discovery/ItemOverview';
import offerEntryPoints from 'utils/product/enums/offerEntryPoints';

import styles from './NonLoyaltyOffers.module.scss';

const NonLoyaltyOffers = () => {
  const { t, i18n } = useTranslation(['offers']);
  const baseName = 'offers:general';
  const language = i18n.language;

  const { data: results, loading } = useDiscovery(language);

  return (
    <SingleColumnLayout
      head={{
        title: t(`${baseName}.my-offers`),
        metaTags: [
          {
            name: 'title',
            content: t(`${baseName}.my-offers`),
          },
        ],
      }}
    >
      <section>
        <h3 className={styles.header}>{t(`${baseName}.my-offers`)}</h3>
        <WidgetSkeleton heightInPixels={548} distance="s" show={loading}>
          {!loading &&
            results.length > 0 &&
            results.map((result) => (
              <React.Fragment key={result._key}>
                <DiscoveryItemOverview
                  item={result}
                  hideVibeLabel={true}
                  size="l"
                  page={offerEntryPoints.nonLoyaltyList}
                />
              </React.Fragment>
            ))}
        </WidgetSkeleton>
      </section>
    </SingleColumnLayout>
  );
};

export default NonLoyaltyOffers;
