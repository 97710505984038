import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useDiscoveryItem } from 'data/useDiscoveryItem';
import SingleColumnLayout from 'layouts/SingleColumnLayout';
import GameWidget from 'components/Games/GameWidget';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';

const Game = () => {
  const { t, i18n } = useTranslation(['offers']);
  const language = i18n.language;

  const { slug } = useParams();

  const { data: game, loading } = useDiscoveryItem(slug, language);
  const pageTitle = t(`offers:details.game-title`);
  return (
    <SingleColumnLayout
      head={{
        title: game ? game.detailsTitle : pageTitle,
        metaTags: [
          {
            name: 'title',
            content: game ? game.detailsTitle : pageTitle,
          },
        ],
      }}
    >
      <WidgetSkeleton
        heightInPixels={540}
        distance="s"
        show={loading}
        title={<WidgetHeader title={t(`offers:details.game-title`)} />}
      >
        {game && <GameWidget game={game} />}
      </WidgetSkeleton>
    </SingleColumnLayout>
  );
};

export default Game;
