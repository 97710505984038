import React, { useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import styles from './ImageLoader.module.scss';

const ImageLoader = ({
  src,
  width = '100%',
  height = 240,
  alt,
  variant = 'rect',
  containerClass = '',
  ...rest
}) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div className={`${styles.container} ${containerClass}`}>
      {loaded ? null : (
        <Skeleton variant={variant} width="100%" height={height} {...rest} />
      )}
      <img
        style={loaded ? {} : { display: 'none' }}
        src={src}
        onLoad={() => setLoaded(true)}
        alt={alt}
        width="auto"
        height={height}
        {...rest}
      />
    </div>
  );
};

export default ImageLoader;
