import endpoints from 'utils/product/enums/endpoints';
import { useAuthUser } from 'utils/AuthUser';
import useSWR from 'swr';
import { postFetcher } from 'api/wave';
import { getUserCouponsData } from 'utils/product/couponUtils';
import { useCmsContent } from './useCmsContent';

const useMyCouponsInfo = (language) => {
  const { accountId } = useAuthUser();

  const {
    data,
    error,
    mutate,
    isLoading: loading,
  } = useSWR(
    accountId && [endpoints.loyalty.coupons, accountId],
    ([url, _customerId]) =>
      postFetcher(url, {
        customerId: _customerId,
      })
  );

  const {
    data: coupons,
    loading: couponsLoading,
    error: couponsError,
  } = useCmsContent('coupons', language);

  const results =
    loading || couponsLoading ? [] : getUserCouponsData(data, coupons?.data);

  return {
    loading: loading || couponsLoading,
    error: error || couponsError,
    data: results,
    mutate,
  };
};

export { useMyCouponsInfo };
