import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import Helmet from 'utils/Helmet';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import EmptyStateWidget from 'components/Widget/EmptyStateWidget';
import MetersManagementWidget from './MetersManagementWidget';
import { ReactComponent as EmptyStateIcon } from 'assets/icons/RecurringEmptyIcon.svg';
import PaymentWidget from 'components/Payment/PaymentWidget';
import paymentTypes from 'utils/product/enums/paymentTypes';
import useDialog from 'hooks/useDialog';
import { useAuthUser } from 'utils/AuthUser';
import useStandingOrderAccountInfo from 'data/useStandingOrderAccountInfo';
import LoyaltyBanner from 'components/Loyalty/LoyaltyBanner';
import { useCollectionOptions } from 'data/useCollectionOptions';
import {
  getPointsOfCategory,
  getWaveId,
} from 'utils/product/loyalty/pointsUtils';
import useLoyaltyHistory from 'data/useLoyaltyHistory';
import { collectionOptionTypes } from 'utils/product/enums/cmsEnums';
import { findMetersWithoutRecurringReward } from 'utils/product/loyalty/recurringUtils';

import styles from './ManagementEbill.module.scss';

const ManagementStandingOrder = () => {
  const { t } = useTranslation(['management', 'translation']);
  const baseName = 'management:managementRightSidebar';

  const location = useLocation();
  const navigate = useNavigate();

  const paymentDialog = useDialog();

  const { isLoyaltyCustomer, accountId, loyaltyCustomerId } = useAuthUser();
  const { collectionOptionsByType, loading: collectionOptionsLoading } =
    useCollectionOptions();

  const standingOrderPoints = getPointsOfCategory(
    collectionOptionsByType,
    collectionOptionTypes.DEBIT_PAYMENT,
  );

  const waveId = getWaveId(
    collectionOptionsByType,
    collectionOptionTypes.DEBIT_PAYMENT,
  );

  const { data: loyaltyHistory, loading: historyLoading } = useLoyaltyHistory(
    loyaltyCustomerId,
    waveId,
  );

  const [loadingMeterIdButton, setLoadingMeterIdButton] = useState(null);
  const [selectedMeter, setSelectedMeter] = useState(null);

  const {
    data: standingOrderMetersInfo,
    loading: standingOrderInfoLoading,
    cardActivationMutate,
  } = useStandingOrderAccountInfo(accountId);

  const metersEligibleForActivation = standingOrderMetersInfo?.filter(
    (meter) => !meter?.StandingOrder,
  );

  const metersEligibleForReward = findMetersWithoutRecurringReward(
    metersEligibleForActivation,
    loyaltyHistory,
  );

  const showEmptyState = standingOrderMetersInfo?.length === 0;
  const hasMeters =
    !standingOrderInfoLoading && standingOrderMetersInfo.length > 0;

  const handleStandingOrderActivation = (meter) => {
    setLoadingMeterIdButton(meter?.Id);
    setSelectedMeter(meter);
    paymentDialog.show();
  };

  const handleCloseStandingOrderDialog = () => {
    paymentDialog.close();
    setLoadingMeterIdButton();
  };

  const handleCardEdit = (meter) => {
    navigate('/management/direct-debit-payment/edit-card', {
      state: {
        meter,
        bankActivated: meter?.StandingOrderBankActivated,
        previousPath: location?.pathname,
      },
    });
  };

  return (
    <>
      <Helmet
        head={{
          title: t(`${baseName}.standing-order`),
          metaTags: [
            { name: 'title', content: t(`${baseName}.standing-order`) },
          ],
        }}
      />
      <WidgetSkeleton
        show={
          standingOrderInfoLoading || collectionOptionsLoading || historyLoading
        }
        heightInPixels={56}
        hideTitle
        title={<WidgetHeader title={t(`${baseName}.standing-order`)} />}
        distance="xs"
      >
        <WidgetHeader title={t(`${baseName}.standing-order`)} />
        {isLoyaltyCustomer && metersEligibleForReward?.length > 0 && (
          <LoyaltyBanner
            points={standingOrderPoints}
            text={t('management:standing-order.loyalty-bonus')}
            distance="xs"
          />
        )}
      </WidgetSkeleton>
      <WidgetSkeleton
        distance=""
        show={standingOrderInfoLoading}
        heightInPixels={240}
      >
        {hasMeters && (
          <Widget distance="" size="m" widgetClass={styles['meters-container']}>
            <h3>{t('management:ebill.meters-title')}</h3>
            <MetersManagementWidget
              meters={standingOrderMetersInfo}
              loadingMeterId={loadingMeterIdButton}
              handlePrimaryAction={handleStandingOrderActivation}
              propertyKey="StandingOrder"
              deactivatedPropertyKey="StandingOrderExpired"
              secondaryButtonLabel={t('management:standing-order.active')}
              handleSecondaryAction={handleCardEdit}
              showArrow
              showWarning
            />
            <p className={styles.disclaimer}>
              {t('management:standing-order.bill-payment-disclaimer')}
            </p>
          </Widget>
        )}

        {showEmptyState && (
          <EmptyStateWidget
            icon={<EmptyStateIcon width="72px" height="72px" />}
            text={t('management:myMeters.empty-state-recurring')}
            isGray
          />
        )}
      </WidgetSkeleton>
      <PaymentWidget
        open={paymentDialog.isOpen}
        closeDialog={handleCloseStandingOrderDialog}
        openDialog={paymentDialog.show}
        paymentType={paymentTypes.recurring}
        meterId={selectedMeter?.Id}
        meter={selectedMeter}
        commodity={selectedMeter?.Commodity}
        onPaymentSuccess={() => cardActivationMutate(selectedMeter?.Id)}
      />
    </>
  );
};

export default ManagementStandingOrder;
