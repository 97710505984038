import React from 'react';
import { useTranslation } from 'react-i18next';

import { getNonAcquiredVibes } from 'utils/product/loyalty/vibesUtils';
import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import RemainingVibes from 'components/LoyaltyVibes/RemainingVibes';
import MyVibes from './MyVibes';
import { useVibes } from 'data/useRewardSchemeAccounts';
import { useAuthUser } from 'utils/AuthUser';

import styles from './VibesCard.module.scss';

const VibesCard = () => {
  const { t, i18n } = useTranslation(['translation', 'loyalty']);
  const language = i18n.language;
  const { loyaltyCustomerId, isLoyaltyCustomer } = useAuthUser();
  const { data, loading } = useVibes(
    loyaltyCustomerId,
    language,
    !isLoyaltyCustomer
  );
  const otherVibes = getNonAcquiredVibes(data);

  return (
    <WidgetSkeleton
      distance="s"
      show={loading}
      title={<WidgetHeader title={t('loyalty:landing.my-vibes')} />}
    >
      {!loading && (
        <Widget
          size="xs"
          distance="m"
          title={<WidgetHeader title={t('loyalty:landing.my-vibes')} />}
        >
          <MyVibes
            vibes={data}
            showExplanation={true}
            containerClass={styles.vibesContainer}
          />
          <RemainingVibes vibes={otherVibes} />
        </Widget>
      )}
    </WidgetSkeleton>
  );
};
export default VibesCard;
