import { useLocation } from 'react-router-dom';
import { getQueryParams } from 'utils/urlUtils';

const useQueryParams = () => {
  const location = useLocation();
  const result = getQueryParams(location.search ?? '');

  return result;
};

export default useQueryParams;
