import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';
import Contracts from 'components/Management/Contracts';
import PersonalInfo from 'components/Management/PersonalInfo';
import SolarMeters from 'components/Management/SolarMeters';
import MyMeters from 'components/Management/MyMeters';
import ManagementEbill from 'components/Management/ManagementEbill';
import ManagementStandingOrder from 'components/Management/ManagementStandingOrder';
import { useAuthUser } from 'utils/AuthUser';
import { accountHasSolarMeters } from 'utils/product/accountUtils';
import ManagementStandingOrderEditCard from 'components/Management/ManagementStandingOrderEditCard';

const ManagementMain = () => {
  const { user } = useAuthUser();
  const hasSolarMeters = accountHasSolarMeters(user);
  return (
    <Routes>
      <Route path="/" element={<Navigate to="contact-details" replace />} />
      <Route path="/my-meters" element={<Navigate to="meters" replace />} />
      <Route
        path="/my-photovoltaic"
        element={<Navigate to="photovoltaic" replace />}
      />
      <Route
        path="/my-contracts"
        element={<Navigate to="contracts" replace />}
      />
      <Route
        path="/personal-details"
        element={<Navigate to="contact-details" replace />}
      />

      <Route path="contact-details" element={<PersonalInfo />} />
      <Route path="meters" element={<MyMeters />} />
      {hasSolarMeters && (
        <Route path="photovoltaic" element={<SolarMeters />} />
      )}
      <Route path="contracts" element={<Contracts />} />
      <Route path="ebill" element={<ManagementEbill />} />
      <Route
        path="direct-debit-payment"
        element={<ManagementStandingOrder />}
      />
      <Route
        path="direct-debit-payment/edit-card"
        element={<ManagementStandingOrderEditCard />}
      />
      <Route path="/*" element={<Navigate to="contact-details" replace />} />
    </Routes>
  );
};

export default ManagementMain;
