import React from 'react';
import visaIcon from 'assets/icons/visa.png';
import mastercardIcon from 'assets/icons/mastercard.png';
import maestroIcon from 'assets/icons/maestro.png';

import styles from './BankIcons.module.scss';

const BankIcons = () => {
  return (
    <section className={styles.container}>
      <img src={visaIcon} alt="visa" width="32px" height="24px" />
      <img src={mastercardIcon} alt="mastercard" width="32px" height="24px" />
      <img src={maestroIcon} alt="maestro" width="32px" height="24px" />
    </section>
  );
};

export default BankIcons;
