import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { trackHomeBillsEvent } from 'utils/product/tracking/events';
import { getTrackingEnergyType } from 'utils/product/tracking/trackingLabels';
import MeterLabelWrapper from 'components/Labels/MeterLabelWrapper';
import Widget from 'components/Widget/Widget';
import ActionsContainer from 'components/ActionsContainer/ActionsContainer';
import { PrimaryButton, SecondaryButton } from 'components/Buttons/Buttons';
import BillOverview from 'components/Bills/BillOverview';
import { EarnPointsLabel } from 'components/Loyalty/LoyaltyLabels';
import { useAuthUser } from 'utils/AuthUser';
import {
  getBillPointsReward,
  getPointsOfCategory,
} from 'utils/product/loyalty/pointsUtils';
import { useCollectionOptions } from 'data/useCollectionOptions';
import { collectionOptionTypes } from 'utils/product/enums/cmsEnums';
import LabelsWrapper from 'components/LabelsWrapper/LabelsWrapper';
import { getCommodityName } from 'utils/product/meterUtils';

import styles from './HomeBillWidget.module.scss';

const HomeBillWidget = ({ meter, onPaymentInvoked }) => {
  const { t } = useTranslation(['translation', 'homepage']);
  const { isLoyaltyCustomer } = useAuthUser();
  const { collectionOptionsByType } = useCollectionOptions();

  const billPaymentPoints = getPointsOfCategory(
    collectionOptionsByType,
    collectionOptionTypes.BILL_PAYMENT
  );

  const points = getBillPointsReward(meter?.CurrentBalance, billPaymentPoints);
  return (
    <Widget distance="xs" widgetClass={styles['bills-widget']}>
      <LabelsWrapper>
        <MeterLabelWrapper
          type={meter.type}
          friendlyName={meter.FriendlyName}
          meterNo={meter.MeterNo}
          meter={meter}
          size="m"
          labelClass={styles.meterLabel}
        />
        {isLoyaltyCustomer && meter.CurrentBalance > 0 && (
          <EarnPointsLabel points={points} />
        )}
      </LabelsWrapper>
      <BillOverview
        amount={meter.CurrentBalance}
        strikedAmount={meter.LastInvoice.grandTotal}
        invoice={meter.LastInvoice}
        meter={meter}
        containerClass={styles.billOverview}
        messageClass={styles['billOverview-message']}
        amountClass={styles['billOverview-amount']}
        billAmountType="invoiceType"
      />

      <ActionsContainer>
        <Link
          className={styles.action}
          to={`/bills/${getCommodityName(meter.Commodity)}/${
            meter.LastInvoice.invoiceNo
          }`}
          state={{
            isLastInvoice: true,
          }}
          onClick={() => {
            trackHomeBillsEvent(
              'home_bill_analysis',
              getTrackingEnergyType(meter.Commodity)
            );
          }}
        >
          <SecondaryButton className={styles.actionButton}>
            {t('translation:analysis')}
          </SecondaryButton>
        </Link>
        {meter.CurrentBalance > 0 && (
          <div className={styles.action}>
            <PrimaryButton
              className={styles.actionButton}
              onClick={() => {
                trackHomeBillsEvent(
                  'home_bill_payment',
                  getTrackingEnergyType(meter.Commodity)
                );
                onPaymentInvoked(meter);
              }}
            >
              {t('translation:payment')}
            </PrimaryButton>
          </div>
        )}
      </ActionsContainer>
    </Widget>
  );
};

export default HomeBillWidget;
