import React from 'react';
import * as Sentry from '@sentry/react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { DefaultFallback } from './FallbackViews';

const ErrorBoundary = ({
  fallbackComponent = DefaultFallback,
  onError,
  children,
  ...rest
}) => {
  const logToSentry = (error, info) => {
    Sentry.withScope((scope) => {
      scope.setExtra('info', info);
      scope.setTag('ErrorBoundary', true);
      Sentry.captureException(error);
    });
  };

  return (
    <ReactErrorBoundary
      FallbackComponent={fallbackComponent}
      onError={(error, info) => {
        logToSentry(error, info);
        if (typeof onError === 'function') onError(error, info);
      }}
      {...rest}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
