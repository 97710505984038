import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { trackHomeENAEvent } from 'utils/product/tracking/events';
import useDialog from 'hooks/useDialog';
import paymentTypes from 'utils/product/enums/paymentTypes';
import { homeCardsLimit } from 'utils/product/config';
import { getMetersWithEna } from 'utils/product/enaUtils';
import useAccountMeters from 'data/useAccountMeters';

import Widget from 'components/Widget/Widget';
import WidgetHeaderLink from 'components/WidgetHeader/WidgetHeaderLink';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import MeterLabelWrapper from 'components/Labels/MeterLabelWrapper';
import { PrimaryButton, SecondaryButton } from 'components/Buttons/Buttons';
import EnaOverview from './EnaOverview';
import PaymentWidget from 'components/Payment/PaymentWidget';
import DetailedListWidget from 'components/DetailedListWidget/DetailedListWidget';

import styles from './EnaHomeWidget.module.scss';

const EnaHomeWidget = ({ detailed = false }) => {
  const { t } = useTranslation(['ena', 'homepage', 'translation']);
  const { meters, loading } = useAccountMeters();

  const [paymentInfo, setPaymentInfo] = useState(null);
  const paymentDialog = useDialog();
  const increaseParticipation = (meter) => {
    trackHomeENAEvent(
      meter.solarisInfo.FirstSolarisDepositHasOccured
        ? 'home_ENA_payment'
        : 'home_ENA_1st_payment'
    );
    const _paymentCode = get(meter, 'solarisInfo.SolarisPaymentCode', null);
    setPaymentInfo({
      PaymentCode: _paymentCode,
      MeterId: meter?.Id,
      Commodity: meter?.Commodity,
    });
    paymentDialog.show();
  };

  const metersWithEna = getMetersWithEna(meters);
  const metersWithActiveEna = metersWithEna.filter(
    (x) => x.solarisInfo.FirstSolarisDepositHasOccured === true
  );

  const WidgetHeader = () => (
    <WidgetHeaderLink
      title={t('ena:home.title')}
      secondTitle={t('homepage:record')}
      linkTo={
        metersWithActiveEna.length > 0
          ? `/ena/${metersWithActiveEna[0].MeterNo}`
          : '/ena'
      }
      onLinkClicked={() => trackHomeENAEvent('home_ENA_history')}
    />
  );
  return (
    <WidgetSkeleton distance="s" show={loading} title={<WidgetHeader />}>
      {metersWithEna && metersWithEna.length > 0 && (
        <>
          <Widget
            distance="s"
            background="transparent"
            title={<WidgetHeader />}
          >
            {metersWithEna
              .slice(0, detailed ? metersWithEna.length : homeCardsLimit)
              .map((meter) => (
                <Widget
                  key={`ena_${meter.Id}`}
                  size="m"
                  distance="xs"
                  widgetClass={styles['ena-widget']}
                >
                  <MeterLabelWrapper
                    type={meter.type}
                    friendlyName={meter.FriendlyName}
                    meterNo={meter.MeterNo}
                    meter={meter}
                  />
                  <EnaOverview enaDetails={meter.solarisInfo} />
                  <section className={styles.buttonsContainer}>
                    {meter.solarisInfo.FirstSolarisDepositHasOccured && (
                      <Link
                        to={`/ena/${meter.MeterNo}`}
                        className={styles.button}
                        onClick={() => trackHomeENAEvent('home_ENA_analysis')}
                      >
                        <SecondaryButton>
                          {t('translation:analysis')}
                        </SecondaryButton>
                      </Link>
                    )}
                    <PrimaryButton
                      onClick={() => increaseParticipation(meter)}
                      className={styles.button}
                    >
                      {t(
                        `ena:home.${
                          meter.solarisInfo.FirstSolarisDepositHasOccured
                            ? 'increase-participation'
                            : 'add-participation'
                        }`
                      )}
                    </PrimaryButton>
                  </section>
                </Widget>
              ))}
            {!detailed && (
              <DetailedListWidget
                show={metersWithEna.length > homeCardsLimit}
                text={t('homepage:ena-list-max')}
                link="/all-ena-meters"
              />
            )}
          </Widget>
          {paymentDialog.isOpen && (
            <PaymentWidget
              open={paymentDialog.isOpen}
              closeDialog={paymentDialog.close}
              openDialog={paymentDialog.show}
              paymentCode={paymentInfo?.PaymentCode}
              paymentType={paymentTypes.ena}
              meterId={paymentInfo?.MeterId}
              commodity={paymentInfo?.Commodity}
            />
          )}
        </>
      )}
    </WidgetSkeleton>
  );
};

export default EnaHomeWidget;
