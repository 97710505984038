import React from 'react';
import cn from 'classnames';
import styles from './ProgramImage.module.scss';

const ProgramImage = ({ imageUrl, packageId, isEna }) => {
  return (
    <section className={cn(styles.container, { [styles.containerEna]: isEna })}>
      <img src={imageUrl} alt={packageId} />
    </section>
  );
};
export default ProgramImage;
