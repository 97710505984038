import React from 'react';
import { useTranslation } from 'react-i18next';

import { getLVConsumptionInfo } from 'utils/product/consumptionUtils';
import consumptionStates from 'utils/product/enums/consumptionStates';
import { DefaultLayout } from 'components/Consumptions/HomeStates/ConsumptionLayouts';
import {
  ConsumptionAnalysisButton,
  ConsumptionMessage,
  RegisteredIndications,
} from 'components/Consumptions/Home/ConsumptionInfoElements';

const StateCountSuccess = ({ meter }) => {
  const { t } = useTranslation(['consumptions']);

  const consumptionInfo = getLVConsumptionInfo(meter);

  return (
    <DefaultLayout>
      {{
        message: (
          <ConsumptionMessage
            text={t(`consumptions:count-success.no-loyalty-message`)}
          />
        ),
        info: (
          <RegisteredIndications
            {...consumptionInfo}
            type={consumptionStates.registered}
          />
        ),
        secondaryButton: <ConsumptionAnalysisButton meterNo={meter.MeterNo} />,
      }}
    </DefaultLayout>
  );
};

export default StateCountSuccess;
