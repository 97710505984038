import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import RequestsMainArea from 'pages/requests/RequestsMainArea';
import useRequests from 'data/useRequests';

const RequestsMain = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const {
    requests,
    loading: requestsLoading,
    requestStatuses,
    requestCategories,
  } = useRequests(language);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequestsMainArea
            requests={requests}
            loading={requestsLoading}
            requestCategories={requestCategories}
            requestStatuses={requestStatuses}
          />
        }
      />
      <Route
        path="moving-meter"
        element={
          <RequestsMainArea
            requests={requests}
            loading={requestsLoading}
            requestCategories={requestCategories}
            requestStatuses={requestStatuses}
            showMeterDialog={true}
          />
        }
      />
    </Routes>
  );
};

export default RequestsMain;
