import React, { cloneElement } from 'react';
import useDialog from 'hooks/useDialog';
import useUniqueFlow from 'hooks/useUniqueFlow';
import useCounter from 'hooks/useCounter';
import { trackWhatsNewEvent } from 'utils/product/tracking/events';
import { whatsNewSlidesCount } from 'utils/product/config';

import WhatsNewDialogOverlay from 'components/WhatsNew/WhatsNewDialogOverlay';

const WhatsNewWrapper = ({ children }) => {
  const {
    number: currentSlideIndex,
    increaseNumber: nextIndex,
    checkIfNumberIsMax: isLastSlideShown,
  } = useCounter(0, whatsNewSlidesCount - 1);

  const { incrementStep } = useUniqueFlow();

  const dialog = useDialog(true);

  const handleClick = () => {
    trackWhatsNewEvent('login_whats_new', currentSlideIndex + 1);
    if (isLastSlideShown()) {
      dialog.close();
      incrementStep();
      return;
    }
    nextIndex();
  };

  return (
    <WhatsNewDialogOverlay
      open={dialog.isOpen}
      closeDialog={dialog.close}
      handleClick={handleClick}
    >
      {children &&
        children.map((child, index) => {
          return cloneElement(child, {
            key: `image_${index}`,
            pageIndex: currentSlideIndex,
            dotsCount: whatsNewSlidesCount,
          });
        })}
    </WhatsNewDialogOverlay>
  );
};

export default WhatsNewWrapper;
