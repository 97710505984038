import { requestStatuses as requestStatusCodes } from 'utils/product/enums/requests';
import orderBy from 'lodash/orderBy';

const getRequestsHistory = (requests, requestStatuses, requestCategories) => {
  if (!requests) return [];

  const results = requests.filter((request) => {
    const { RequestStatus, RequestType } = request || {};

    if (request.RequestStatus !== requestStatusCodes.completed) return false;
    const statusDescription =
      RequestStatus && requestStatuses[RequestStatus]
        ? requestStatuses[RequestStatus]
        : null;
    const typeDescription =
      RequestType && requestCategories[RequestType]
        ? requestCategories[RequestType]
        : null;
    return statusDescription && typeDescription;
  });
  return orderBy(results, 'RequestDate', 'desc');
};

export default getRequestsHistory;
