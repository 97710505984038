import React from 'react';
import { useTranslation } from 'react-i18next';

import vibesPin from 'assets/icons/vibes-pin.jpg';
import { useAuthUser } from 'utils/AuthUser';
import { useVibes } from 'data/useRewardSchemeAccounts';
import { getAcquiredVibes } from 'utils/product/loyalty/vibesUtils';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import MyVibeLabel from 'components/LoyaltyVibes/MyVibeLabel';
import Loader from 'components/Skeletons/Loader';

import styles from './VibesAcquiredDialog.module.scss';

const VibesAcquiredDialogWrapper = ({ open, closeDialog, onClose, type }) => {
  const { t, i18n } = useTranslation(['loyalty']);
  const { loyaltyCustomerId, accountId } = useAuthUser();

  const { data: vibes, loading } = useVibes(
    loyaltyCustomerId ?? accountId,
    i18n.language
  );
  const myVibes = getAcquiredVibes(vibes);

  const vibesToDisplay = loading ? [] : myVibes;

  const labelKeys = {
    register: {
      header: `loyalty:vibesDialog.header-register`,
      message: `loyalty:vibesDialog.title-register`,
      subtext: `loyalty:vibesDialog.info`,
    },
    redemption: {
      header: `loyalty:vibesDialog.header-redemption`,
      message: `loyalty:vibesDialog.title-redemption`,
      subtext: `loyalty:vibesDialog.info`,
    },
  };

  return (
    <VibesAcquiredDialog
      open={open}
      closeDialog={closeDialog}
      onClose={onClose}
      header={t(labelKeys[type].header)}
      message={t(labelKeys[type].message)}
      subtext={t(labelKeys[type].subtext)}
      vibes={vibesToDisplay}
      loading={loading}
    />
  );
};

const VibesAcquiredDialog = ({
  open,
  closeDialog,
  onClose,
  header,
  message,
  subtext,
  vibes,
  loading,
}) => {
  return (
    <>
      <DialogOverlay
        open={open}
        closeDialog={() => {
          closeDialog();
          if (typeof onClose === 'function') onClose();
        }}
        scroll="body"
        dialogHeader={null}
        titleClass={styles.dialogTitle}
        maxWidth="sm"
        dialogClass={styles.dialog}
      >
        <img src={vibesPin} alt="vibes" className={styles.pin} />
        <div className={styles.container}>
          <p className={styles.title}>{header}</p>
          <p>{message}</p>
          <section className={styles.myVibesPercentages}>
            <Loader show={loading} height={70} width={85}>
              {vibes?.map((x) => (
                <MyVibeLabel vibe={x} key={x.key} />
              ))}
            </Loader>
          </section>
          <p className={styles.info}>{subtext}</p>
        </div>
      </DialogOverlay>
    </>
  );
};

export default VibesAcquiredDialogWrapper;
