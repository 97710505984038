export const invoiceTypes = {
  clearing: 0,
  against: 1,
  //fixing: 2,
  exceptional: 2,
  final: 3,
  againstClient: 4,
  cancelling: 5,
};

export const invoiceTypeIDocs = {
  clearing: 'ΕΚΚΑΘΑΡΙΣΤΙΚΟΣ',
  against: 'ΕΝΑΝΤΙ',
  fixing: 'ΔΙΟΡΘΩΤΙΚΟΣ ΤΕΛΕΥΤΑΙΟΥ ΛΟΓΑΡΙΑΣΜΟΥ',
  final: 'ΤΕΛΙΚΟΣ ΕΚΚΑΘΑΡΙΣΤΙΚΟΣ ΛΟΓΑΡΙΑΣΜΟΣ',
  againstClient: 'ΕΝΑΝΤΙ ΑΠΟ ΕΝΔΕΙΞΗ ΠΕΛΑΤΗ',
  exceptional: 'ΕΚΤΑΚΤΟΣ ΛΟΓΑΡΙΑΣΜΟΣ',
  cancelling: 'ΑΚΥΡΩΤΙΚΟΣ',
};
