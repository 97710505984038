import api from './api';

const getReceiptPdf = (approvalCode) => {
  return api.get(`/guest/receiptPdf?approvalCode=${approvalCode}`, {
    responseType: 'blob',
  });
};

const createRequest = (requestData) => {
  return api.post('/guest/create', requestData);
};

export { getReceiptPdf, createRequest };
