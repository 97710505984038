import React from 'react';
import PropTypes from 'prop-types';

import { OfferLabels, OfferTitle } from 'components/Offers/OfferElements';
import OfferImage from 'components/Offers/OfferImage';
import { Link } from 'react-router-dom';
import discoveryItemTypes from 'utils/product/enums/discoveryItemTypes';
import { trackLoyaltyDiscoveryItemSelection } from 'utils/product/tracking/loyaltyEvents';
import { trackDiscoveryItemSelection } from 'utils/product/tracking/events';
import { isEmptyString } from 'utils/stringUtils';
import { useAuthUser } from 'utils/AuthUser';

import styles from './ItemOverview.module.scss';

const ItemOverview = ({
  item,
  hideVibeLabel = false,
  hideRedeemPoints = false,
  size = 's',
  page = '',
}) => {
  const {
    imageUrl,
    largeImageUrl,
    endDate,
    title,
    expirationLabelColor,
    vibe,
    redeemPoints,
    slug,
  } = item || {};
  const { isLoyaltyCustomer } = useAuthUser();

  const maxLength = 54;
  const offerTitle =
    size !== 's'
      ? title
      : `${title?.substring(0, maxLength)}${
          title?.length > maxLength ? '...' : ''
        }`;

  const trackAnalyticsEvent = (item) => {
    if (isLoyaltyCustomer) {
      trackLoyaltyDiscoveryItemSelection(item, page);
      return;
    }
    trackDiscoveryItemSelection(item, page);
  };

  return (
    <>
      <Link
        className={`${styles.link} ${styles[`link-${size}`]}`}
        to={
          item._type === discoveryItemTypes.offer
            ? `/offers/${slug}`
            : item._type === discoveryItemTypes.contest
              ? `/contests/${slug}`
              : item._type === discoveryItemTypes.game
                ? `/games/${slug}`
                : ''
        }
        aria-label={title}
        key={title}
        onClick={() => trackAnalyticsEvent(item)}
      >
        <OfferLabels
          vibe={hideVibeLabel ? null : vibe}
          redeemPoints={hideRedeemPoints ? null : redeemPoints}
        />
        <OfferImage
          imageUrl={imageUrl}
          largeImageUrl={
            size !== 's' && !isEmptyString(largeImageUrl)
              ? largeImageUrl
              : imageUrl
          }
          expirationDate={endDate}
          labelTheme={expirationLabelColor}
          containerClass={styles.image}
        />
        <section className={styles.textContainer}>
          <OfferTitle title={offerTitle} headerClass={styles.itemTitle} />
        </section>
      </Link>
    </>
  );
};

ItemOverview.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l']),
};

export default ItemOverview;
