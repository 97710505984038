import React from 'react';
import EnaHistoryWidget from 'components/Ena/EnaHistoryWidget';

const RightSidebar = ({ meters, metersLoading }) => {
  return (
    <section>
      <EnaHistoryWidget meters={meters} metersLoading={metersLoading} />
    </section>
  );
};
export default RightSidebar;
