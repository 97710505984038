import React from 'react';

import styles from './EnaAnalysisTextField.module.scss';

const EnaAnalysisTextField = ({
  content,
  Icon,
  amount,
  containerClass = '',
}) => {
  return (
    <div className={`${styles.container} ${containerClass}`}>
      <div className={styles.content}>{content}</div>
      {Icon}
      <div className={styles.amount}>{amount}</div>
    </div>
  );
};

export default EnaAnalysisTextField;
