import React from 'react';
import { useTranslation } from 'react-i18next';
import useEnum from 'data/useEnum';
import { AutocompleteInput } from './AutocompleteInput';

const TaxOfficeInput = ({
  errors,
  name,
  defaultValue,
  onChangeHandler,
  register,
  required = true,
}) => {
  const { t } = useTranslation(['newClient']);
  const { Offices: taxOffices = [], loading } = useEnum('Offices');

  return (
    <AutocompleteInput
      options={taxOffices}
      labelKey="TaxOfficeName"
      valueKey="TaxOfficeId"
      loading={loading}
      label={t(`newClient:sharedDetails.taxOffice`)}
      errors={errors}
      name={name}
      defaultValue={defaultValue}
      onChangeHandler={onChangeHandler}
      register={register}
      required={required}
    />
  );
};

export { TaxOfficeInput };
