import { requestTypes } from 'utils/product/enums/requests';
import accountCategoryTypes from 'utils/product/enums/accountCategoryTypes';

const getPostData = ({ requestType, accountType, data, defaultValues }) => {
  const isEditable = requestType === requestTypes.personalDataChange;
  const accountData =
    accountType === accountCategoryTypes.home
      ? {
          fullName: defaultValues.fullName,
          address: defaultValues.address,
          taxId: defaultValues.taxId,
        }
      : {
          companyName: defaultValues.companyName,
          companyAddress: defaultValues.companyAddress,
          taxId: defaultValues.taxId,
          legalContact: isEditable
            ? data.legalContact
            : defaultValues.legalContact,
        };

  const movingDetails =
    requestType === requestTypes.movingRequest ||
    requestType === requestTypes.powerDisconnect
      ? {
          meterName: defaultValues.meterName,
          meterNo: defaultValues.meterNo,
          meterAddress: defaultValues.meterAddress,
        }
      : {};

  const postData = {
    ...data,
    ...accountData,
    idPassport: isEditable ? data.idPassport : defaultValues.idPassport,
    placeOfIssue: isEditable ? data.placeOfIssue : defaultValues.placeOfIssue,
    taxOffice: isEditable ? data.taxOffice : defaultValues.taxOffice,
    phone: isEditable ? data.phone : defaultValues.phone,
    mobile: isEditable ? data.mobile : defaultValues.mobile,
    email: isEditable ? data.email : defaultValues.email,
    ...movingDetails,
  };
  return postData;
};

export default getPostData;
