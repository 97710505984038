import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import GenericLoader from 'components/GenericLoader/GenericLoader';
import { setUserConfirm } from 'api/user';
import { useAuthUser } from 'utils/AuthUser';
import useQueryParams from 'hooks/useQueryParams';
import { isEmptyString } from 'utils/stringUtils';

const UserRegistrationBlank = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuthUser();
  const { mail: email, token } = useQueryParams();

  useEffect(() => {
    if (isEmptyString(token) || isEmptyString(email) || isLoggedIn) {
      navigate('/');
      return;
    }
    if (token && email && !isLoggedIn) {
      setUserConfirm({ token, email })
        .then(() => {
          navigate('/login');
        })
        .catch((error) => {
          navigate('/login', { state: { error: error.response?.data?.error } });
        });
    }
  }, [isLoggedIn, navigate, email, token]);

  return <GenericLoader />;
};

export default UserRegistrationBlank;
