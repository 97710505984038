import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useDialog from 'hooks/useDialog';
import paymentTypes from 'utils/product/enums/paymentTypes';
import { trackGuestEvent } from 'utils/product/tracking/events';
import pages from 'utils/product/enums/pages';

import LoginWidget from 'pages/onboarding/LoginWidget';
import BottomSection from './BottomSection';
import OnboardingLayout from 'layouts/OnboardingLayout';
import LoginWidgetContent from './LoginWidgetContent';
import { LinkButton } from 'components/Buttons/Buttons';
import PaymentWidget from 'components/Payment/PaymentWidget';
import Divider from '@material-ui/core/Divider';
import AppDownloadButtons from 'components/AppDownload/AppDownloadButtons';
import useGeneralConfig from 'data/useGeneralConfig';

import styles from './Login.module.scss';

const Login = ({ dialogComponent, head }) => {
  const { t, i18n } = useTranslation(['onboarding']);
  const paymentDialog = useDialog();

  const {
    data: { generalTermsUrl, gdprTermsUrl },
  } = useGeneralConfig(i18n.language) || {};

  return (
    <OnboardingLayout alignContainerCenter={false} head={head}>
      <LoginWidget containerClass={styles.widgetContainer}>
        <LoginWidgetContent />
      </LoginWidget>
      <LoginWidget containerClass={styles.widgetContainer}>
        <BottomSection />
      </LoginWidget>

      <LinkButton
        extraClasses={styles.paymentLink}
        onClick={() => {
          paymentDialog.show();
          trackGuestEvent('loginscreen_pay_without_login');
        }}
        data-testid="home-link"
      >
        {t(`onboarding:guest-payment`)}
      </LinkButton>
      <AppDownloadButtons
        containerClass={styles.storesContainer}
        source={pages.login}
      />
      <section className={styles.termsContainer}>
        <Link
          to={generalTermsUrl}
          onClick={() => {
            trackGuestEvent('loginscreen_terms_of_use_selection');
          }}
          target="_blank"
          rel="noreferrer"
        >
          {t(`onboarding:terms`)}
        </Link>
        <Divider orientation="vertical" flexItem className={styles.vertical} />

        <Link
          to={gdprTermsUrl}
          onClick={() => {
            trackGuestEvent('loginscreen_privacy_policy_selection');
          }}
          target="_blank"
          rel="noreferrer"
        >
          {t(`onboarding:privacy`)}
        </Link>
        <Divider orientation="vertical" flexItem className={styles.vertical} />

        <Link
          to="/cookies"
          onClick={() => {
            trackGuestEvent('loginscreen_cookies_selection');
          }}
        >
          {t(`onboarding:cookies-policy`)}
        </Link>
      </section>
      <PaymentWidget
        open={paymentDialog.isOpen}
        closeDialog={paymentDialog.close}
        openDialog={paymentDialog.show}
        paymentType={paymentTypes.guest}
      />
      {dialogComponent}
    </OnboardingLayout>
  );
};

export default Login;
