import { useTranslation } from 'react-i18next';

/* Fallback mechanism
 * First look in specific file
 * then look in generic errors file
 * finally show generic error [/errors/generic: general]

 start using this instead of useLocalizedError
*/

const useLocalisedError = (
  error = 'general',
  scope = 'generic',
  params = {}
) => {
  const filePath = 'errors/';

  const translations = [`${filePath}generic`];
  const titleKeys = [`${error}.title`, `general.title`];
  const messageKeys = [`${error}.message`, `general.message`];

  if (scope !== 'generic') {
    translations.unshift(`${filePath}${scope}`);
    titleKeys.unshift(`${error}.title`);
    messageKeys.unshift(`${error}.message`);
  }

  const { t } = useTranslation(translations);

  const title = t(titleKeys, { ns: translations, ...params });
  const message = t(messageKeys, { ns: translations, ...params });

  return { title, message };
};

export default useLocalisedError;
