import React from 'react';
import { useTranslation } from 'react-i18next';

import UnauthorizedLayout from 'layouts/UnauthorizedLayout';

import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';

import { useTerms } from 'data/useTerms';
import Loader from 'components/Skeletons/Loader';
import { useParams } from 'react-router-dom';
import HtmlRenderer from 'components/CmsHtmlField/CmsHtmlField';
import styles from './CmsTerms.module.scss';

const CmsTerms = () => {
  const { i18n: { language = 'el' } = {} } = useTranslation('offers');

  const { slug } = useParams();

  const { data, loading } = useTerms(slug, language, false);

  return (
    <UnauthorizedLayout>
      <WidgetSkeleton
        heightInPixels={700}
        distance="m"
        show={loading}
        title={<Loader variant="text" height={40} width={200} />}
      >
        {data && (
          <Widget
            size="m"
            distance="m"
            title={<WidgetHeader title={data.title} />}
            widgetClass={styles.widget}
          >
            <HtmlRenderer value={data.terms} />
          </Widget>
        )}
      </WidgetSkeleton>
    </UnauthorizedLayout>
  );
};

export default CmsTerms;
