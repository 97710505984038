import React from 'react';
import { useTranslation } from 'react-i18next';

import MeterIconText from 'components/Labels/MeterIconText';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import WidgetLine from 'components/WidgetLine/WidgetLine';
import { ReactComponent as ArrowRight } from 'assets/icons/ArrowRightIcon.svg';
import { ReactComponent as WarningCircleIcon } from 'assets/icons/WarningCircleIcon.svg';
import { sortMetersForManagementPage } from 'utils/product/meterUtils';

import styles from './ManagementBillsAndPaymentsContent.module.scss';

const MetersManagementWidget = ({
  meters,
  loadingMeterId,
  handlePrimaryAction,
  primaryButtonLabel,
  handleSecondaryAction,
  secondaryButtonLabel,
  propertyKey,
  deactivatedPropertyKey,
  showArrow = false,
  showWarning = false,
  deactivatedLabel,
}) => {
  const { t } = useTranslation(['management', 'translation']);

  const _primaryButtonLabel =
    primaryButtonLabel ?? t('management:ebill.activate-button');

  const _secondaryLabel = secondaryButtonLabel ?? t('management:ebill.active');
  const _deactivatedLabel =
    deactivatedLabel ?? t('management:standing-order.inactive');

  const secondaryActionHandler = (meter) => {
    return typeof handleSecondaryAction === 'function'
      ? handleSecondaryAction(meter)
      : null;
  };

  const sortedMeters = sortMetersForManagementPage(meters);
  return sortedMeters?.map((meter) => (
    <React.Fragment key={meter.Id}>
      <div className={styles.supplyItem}>
        <MeterIconText
          meterNo={meter?.MeterNo}
          commodity={meter?.Commodity}
          friendlyName={meter?.FriendlyName}
        />
        {meter?.[propertyKey] && (
          <div
            className={showArrow ? styles.activatedSupply : ''}
            onClick={() => secondaryActionHandler(meter)}
          >
            {showWarning && meter?.[deactivatedPropertyKey] && (
              <WarningCircleIcon />
            )}
            <span
              className={`${styles['activatedSupply-label']} ${
                meter?.[deactivatedPropertyKey] ? styles['expired-label'] : ''
              }`}
            >
              {meter?.[deactivatedPropertyKey]
                ? _deactivatedLabel
                : _secondaryLabel}
            </span>
            {showArrow && <ArrowRight />}
          </div>
        )}
        {!meter?.[propertyKey] && (
          <PrimaryButtonLoader
            loading={loadingMeterId === meter?.Id}
            disabled={!!loadingMeterId}
            onClick={() => handlePrimaryAction(meter)}
            className={styles['activate-button']}
          >
            {_primaryButtonLabel}
          </PrimaryButtonLoader>
        )}
      </div>
      <WidgetLine lineClass={styles.line} />
    </React.Fragment>
  ));
};
export default MetersManagementWidget;
