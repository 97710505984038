const accountCategories = {
  private: 1, //Ιδιώτης
  freelancer: 2, //Ελεύθερος Επαγγελματίας
  company: 3, //Εταιρεία
  companyAE: 4, //Εταιρεία ΑΕ
  companyOE_EE: 5, //Εταιρεία ΟΕ & ΕΕ
  companyEPE: 6, //Εταιρεία ΕΠΕ
  companyABEE: 7, //Εταιρεία ΑΒΕΕ
  companyIKE: 8, //Εταιρεία ΙΚΕ
  companyAEBEE: 9, //Εταιρεία ΑΕΒΕΕ
  legalEntity: 10, //Νομικό Πρόσωπο Ιδιωτικού Δικαίου
  foreignCompany: 11, //ΕΤΑΙΡΕΙΑ ΑΛΛΟΔΑΠΗΣ
};

export default accountCategories;
