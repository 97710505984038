import Big from 'big.js';
import { formatClearingMeasurements } from 'utils/product/ownMeasurementUtils';
import { sortByDateFieldDescending } from 'utils/sortUtils';
import commodities from 'utils/product/enums/commodities';
import supplyTypes from 'utils/product/enums/supplyTypes';
import { getDifferenceInDays, isDateBetween } from 'utils/dateUtils';
import {
  clearingBillIntervalInDays,
  consumptionForecastSpan,
} from 'utils/product/config';
import consumptionStates from 'utils/product/enums/consumptionStates';
import { getMeterNightIndicationDictionary } from './meterUtils';

export const hasRecentClearingBill = (meter) => {
  if (meter.latestActualMeasurement) {
    if (meter.Commodity === commodities.gas) return true;
    if (meter.latestActualMeasurement.date) {
      const daysDifference = getDifferenceInDays(
        meter.latestActualMeasurement.date,
        new Date()
      );
      return daysDifference <= clearingBillIntervalInDays;
    }
  }

  return false;
};

export const meterOwnReadings = (meter) => {
  if (meter.Commodity === commodities.gas)
    return {
      canRegister: false,
      hasRegistered: false,
    };

  const now = new Date();
  if (meter.allowedReadingDates) {
    const allowedDateFrom = meter.allowedReadingDates.AllowedReadingDateFrom;
    const allowedDateTo = meter.allowedReadingDates.AllowedReadingDateTo;

    const canRegister = isDateBetween(now, allowedDateFrom, allowedDateTo);
    const latestRegisterDate = meter.latestUserRegisteredReading?.Date;
    const hasRegistered =
      latestRegisterDate !== undefined &&
      latestRegisterDate !== null &&
      isDateBetween(latestRegisterDate, allowedDateFrom, allowedDateTo);

    return {
      canRegister,
      hasRegistered,
    };
  }
  return {
    canRegister: false,
    hasRegistered: false,
  };
};

export const hasNightConsumption = (meter) => {
  return meter?.allowedReadingDates?.NightReadingIsRequired; //&&
  // meter.latestActualMeasurement &&
  // meter.latestActualMeasurement.nightTimeConsumption > 0
};

export const isNewMeter = (meter) => {
  return meter.latestActualMeasurement === null;
};

export const isB2BConsumption = (meter) =>
  meter.Commodity === commodities.energy &&
  meter.SupplyType !== supplyTypes.LowVoltage;

export const getLVConsumptionInfo = (meter) => {
  const meterHasNightConsumption = hasNightConsumption(meter);
  const hasNightMeasurementCode = !!meter.NightMeasurementCode;

  const {
    latestActualMeasurement,
    allowedReadingDates,
    latestUserRegisteredConsumption,
    isRenewable,
    type,
  } = meter || {};

  const {
    dayTimeConsumption,
    nightTimeConsumption,
    periodStart,
    periodEnd,
    consumptionPerDay,
    consumptionPerNight,
  } = latestActualMeasurement || {};
  const {
    AllowedReadingDateFrom: allowedReadingDateFrom,
    AllowedReadingDateTo: allowedReadingDateTo,
  } = allowedReadingDates || {};

  const {
    Day: userRegistrationDay,
    Night: userRegistrationNight,
    Date: userRegistrationDate,
  } = latestUserRegisteredConsumption || {};

  return {
    isRenewable,
    type,
    hasNightMeasurementCode,
    hasNightConsumption: meterHasNightConsumption,
    periodStart,
    periodEnd,
    duration: getDifferenceInDays(periodStart, periodEnd, {
      includeLastDay: true,
    }),
    consumptionPerDay: consumptionPerDay
      ? new Big(consumptionPerDay).toFixed(1)
      : null,
    consumptionPerNight: consumptionPerNight
      ? new Big(consumptionPerNight).toFixed(1)
      : null,
    dayEstimation: consumptionPerDay
      ? Math.trunc(consumptionPerDay * consumptionForecastSpan)
      : null,
    nightEstimation: consumptionPerNight
      ? Math.trunc(consumptionPerNight * consumptionForecastSpan)
      : null,
    dayTimeConsumption,
    nightTimeConsumption,

    allowedReadingDateFrom,
    allowedReadingDateTo,

    userRegistrationDay: userRegistrationDay
      ? Math.trunc(userRegistrationDay)
      : 0,
    userRegistrationNight: userRegistrationNight
      ? Math.trunc(userRegistrationNight)
      : 0,
    userRegistrationDate,
  };
};

export const getConsumptionState = (meter) => {
  if (isB2BConsumption(meter)) return consumptionStates.mediumVoltage;
  const meterIsNew = isNewMeter(meter);

  if (meter.Commodity === commodities.gas)
    return meterIsNew ? null : consumptionStates.clearingGas;

  const { canRegister, hasRegistered } = meterOwnReadings(meter);

  if (canRegister)
    return hasRegistered
      ? consumptionStates.registered
      : consumptionStates.register;

  if (meterIsNew) return null;

  if (hasRecentClearingBill(meter)) return consumptionStates.clearing;

  return consumptionStates.prediction;
};

export const prepareMetersList = (meters) => {
  return meters
    .filter((meter) => {
      if (!meter.IsActive) return false;
      if (isB2BConsumption(meter)) return true;
      const { canRegister } = meterOwnReadings(meter);
      if (canRegister) return true;

      if (hasRecentClearingBill(meter)) return true;
      if (!isNewMeter(meter)) return true;
      return false;
    })
    .sort((a, b) => (isB2BConsumption(a) ? (isB2BConsumption(b) ? 0 : -1) : 1));
};

export const consumptionsPreprocessing = (data, meters) => {
  if (!data || !data.MeterConsumptionsResponses) return [];
  const nightMeterDictionary = getMeterNightIndicationDictionary(meters);

  const consumptions = data.MeterConsumptionsResponses.map((x) =>
    x.Consumptions.map((consumption, index) => ({
      ...consumption,
      innerId: index,
      MeterId: x.MeterId,
      FriendlyName: x.FriendlyName,
      MeterNo: x.MeterNo,
      Commodity: x.Commodity,
      DayConsumptionString: formatClearingMeasurements(
        consumption.DayMeterConsumption || 0
      ),
      NightConsumptionString: formatClearingMeasurements(
        consumption.NightMeterConsumption || 0
      ),
      hasNightIndication: nightMeterDictionary[x.MeterId] || false,
    }))
  );

  const results = consumptions.reduce(
    (accumulator, currentValue) => accumulator.concat(currentValue),
    []
  );
  sortByDateFieldDescending(results, 'BillTo');

  return results;
};
