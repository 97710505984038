import React from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Skeletons/Loader';

import { TextInput } from 'components/Inputs/TextInput';

import styles from './MeterData.module.scss';

const MeterData = ({ defaultValues, loading = false }) => {
  const { t } = useTranslation(['requests']);

  return (
    <>
      <article className={styles.container}>
        <h3 className={styles.title}>
          {t(`requests:billing.meter-details-cut`)}
        </h3>
        <Loader show={loading} height={108}>
          <>
            <TextInput
              name="meterName"
              label={t(`requests:billing.meter`)}
              disabled
              defaultValue={defaultValues?.meterName}
              required={false}
            />
            <TextInput
              name="meterAddressIgnored"
              label={t(`requests:moving.meter-address`)}
              disabled
              defaultValue={defaultValues?.meterAddress}
              required={false}
            />
          </>
        </Loader>
      </article>
    </>
  );
};

export default MeterData;
