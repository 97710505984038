import React from 'react';
import { useTranslation } from 'react-i18next';

import useAccountMeters from 'data/useAccountMeters';
import { getAvailableRequestsWithStatuses } from 'utils/product/requests/availableRequests';
import { meterStatusesElectricity } from 'utils/product/enums/meterStatuses';

import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import AvailableRequestsWidget from 'components/RequestsHome/AvailableRequestsWidget';
import CertificatesWidget from 'components/RequestsHome/CertificatesWidget';
import {
  getMetersForRepresentationCertificate,
  getMetersForTerminationCertificate,
} from 'utils/product/requestUtils';

const RequestsMainArea = ({
  requests,
  loading,
  requestCategories,
  requestStatuses,
  showMeterDialog = false,
}) => {
  const { t } = useTranslation(['requests']);

  const { meters, loading: metersLoading } = useAccountMeters({
    statusesElectricity: [
      meterStatusesElectricity.active,
      meterStatusesElectricity.terminated,
    ],
  });
  const availableRequests = getAvailableRequestsWithStatuses(
    requests,
    requestCategories,
    requestStatuses
  );

  const elegibleMeters = getMetersForRepresentationCertificate(meters);
  const terminatedMeters = getMetersForTerminationCertificate(meters);

  return (
    <>
      <AvailableRequestsWidget
        requests={availableRequests}
        meters={meters}
        loading={loading || metersLoading}
        showMeterDialog={showMeterDialog}
        title={<WidgetHeader title={t(`requests:requests.requests`)} />}
      />
      {(elegibleMeters.length > 0 || terminatedMeters.length > 0) && (
        <CertificatesWidget
          elegibleMeters={elegibleMeters}
          terminatedMeters={terminatedMeters}
          meters={meters}
          loading={metersLoading}
          title={<WidgetHeader title={t(`requests:requests.certificates`)} />}
        />
      )}
    </>
  );
};

export default RequestsMainArea;
