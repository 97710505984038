import get from 'lodash/get';
import { deliveryPointCategories } from 'utils/product/enums/deliveryPointCategories';
import { gasTariffGroups } from 'utils/product/enums/gasTariffGroups';
import programTypes from 'utils/product/enums/programTypes';
import shopEnergyTypes from 'utils/product/enums/shopEnergyTypes';

const getDeliveryPointCategoryId = (data, deliveryPointCaterogiesList) => {
  const deliveryPointCategoryCode =
    data.programType === programTypes.home
      ? deliveryPointCategories.home
      : deliveryPointCategories.business;

  const deliveryPointCategory = deliveryPointCaterogiesList.find(
    (x) => x.Code === deliveryPointCategoryCode
  );

  return get(deliveryPointCategory, 'DeliveryPointCategoryId', null);
};

const getGasTariffgroupId = (data, gasTariffgroupsList) => {
  const gasTariffgroupCode =
    data.programType === programTypes.home
      ? data.shared === 'true'
        ? gasTariffGroups.homeShared
        : data.energyType === shopEnergyTypes.combined
        ? gasTariffGroups.doubleHome
        : gasTariffGroups.home
      : gasTariffGroups.business;

  const gasTariffgroup = gasTariffgroupsList.find(
    (x) => x.Code === gasTariffgroupCode
  );

  return get(gasTariffgroup, 'GasTariffgroupId', null);
};

export { getDeliveryPointCategoryId, getGasTariffgroupId };
