import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import { ReactComponent as SearchIcon } from 'assets/icons/SearchIcon.svg';
import { ReactComponent as SearchIconEmptyState } from 'assets/icons/SearchIconEmptyState.svg';
import { ReactComponent as EnergyCircle } from 'assets/icons/EnergyCircleIcon.svg';
import { ReactComponent as EnergyGasCircle } from 'assets/icons/EnergyGasCircleIcon.svg';
import { StandardInput } from 'components/Inputs/Inputs';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconText from 'components/IconText/IconText';
import commodities from 'utils/product/enums/commodities';
import useLocalizedFriendlyName from 'hooks/useLocalizedFriendlyName';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import { arrayFiltering } from 'utils/product/inputUtils';
import { isEmptyString } from 'utils/stringUtils';

import styles from './MetersSearchDialog.module.scss';

const MetersSearchDialog = ({
  open,
  closeDialog,
  meters,
  handleMeterSelection,
}) => {
  const { t } = useTranslation(['consumptions']);
  const { getDisplayName } = useLocalizedFriendlyName();

  const [searchValue, setSearchValue] = useState('');

  const metersFiltered = isEmptyString(searchValue)
    ? meters
    : arrayFiltering(meters, ['FriendlyName', 'MeterNo'], searchValue);

  const metersViewList = metersFiltered?.map((meter) => {
    return (
      <div
        key={meter.Id}
        className={styles.panel}
        onClick={() => {
          handleMeterSelection(meter);
          setSearchValue('');
          closeDialog();
        }}
      >
        <IconText
          Icon={
            meter.Commodity === commodities.energy ? (
              <EnergyCircle />
            ) : (
              <EnergyGasCircle />
            )
          }
          gap="s"
          weight="ellipsis"
          content={getDisplayName(meter.FriendlyName, meter.MeterNo)}
        />
      </div>
    );
  });

  const emptyState = (
    <div className={styles['metersContainer-emptyState']}>
      <SearchIconEmptyState />
      <p>{t('searchDialog.searchEmptyState')}</p>
    </div>
  );

  return (
    <DialogOverlay
      open={open}
      closeDialog={() => {
        setSearchValue('');
        closeDialog();
      }}
      scroll="body"
      showCloseButton
      dialogHeader={t('searchDialog.searchHeader')}
    >
      <StandardInput
        autoFocus
        type="text"
        placeholder={t('searchDialog.searchPlaceholder')}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        className={styles.searchInput}
        onChange={(event) => {
          setSearchValue(event.target.value);
        }}
      />
      <WidgetSkeleton heightInPixels={448} distance="s" show={!meters.length}>
        <div className={styles.metersContainer}>
          {metersViewList.length ? metersViewList : emptyState}
        </div>
      </WidgetSkeleton>
    </DialogOverlay>
  );
};

export default MetersSearchDialog;
