import React from 'react';
import { useTranslation } from 'react-i18next';

import { RedeemPointsLabel } from 'components/Loyalty/LoyaltyLabels';
import { trackOfferEvent } from 'utils/product/tracking/events';

import {
  CustomButton,
  ExternalLinkButton,
  InternalLinkButton,
} from 'components/Offers/OfferElements';
import ContestParticipation from './ContestParticipation';
import { buttonActions } from 'utils/product/enums/cmsEnums';

import styles from './ContestParticipationOptions.module.scss';

const ContestParticipationOptions = ({
  contest,
  participationsCount,
  onParticipationInvoked,
  mutateParticipations,
}) => {
  const { t } = useTranslation(['translation', 'offers']);

  const {
    title,
    button: { buttonTitle: ctaText, buttonAction: ctaType, url: ctaValue } = {},
    redeemPoints,
    isExpired,
    participationsPerParticipantLimit,
  } = contest;

  const onlyOneParticipation = participationsPerParticipantLimit === 1;

  if (isExpired) return;

  return ctaType === buttonActions.default ||
    ctaType === buttonActions.custom ? (
    participationsCount === 0 &&
      (redeemPoints > 0 ? (
        <>
          <p>
            {t(
              `offers:acquisition-flow.${
                onlyOneParticipation ? 'redeem-one' : 'redeem-at-least-one'
              }`
            )}
          </p>
          <RedeemPointsLabel points={redeemPoints} labelClass={styles.points} />
          <CustomButton text={ctaText} clickHandler={onParticipationInvoked} />
        </>
      ) : (
        <ContestParticipation
          contest={contest}
          mutateParticipations={mutateParticipations}
        />
      ))
  ) : ctaType === buttonActions.externalUrl ||
    ctaType === buttonActions.externalUrlInApp ? (
    <ExternalLinkButton
      clickHandler={() => {
        trackOfferEvent('offer_button_selected', title);
      }}
      url={ctaValue}
      text={ctaText}
    />
  ) : (
    ctaType === buttonActions.internalUrl && (
      <InternalLinkButton
        clickHandler={() => {
          trackOfferEvent('offer_button_selected', title);
        }}
        url={ctaValue}
        text={ctaText}
      />
    )
  );
};

export default ContestParticipationOptions;
