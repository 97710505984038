import React from 'react';
import { useTranslation } from 'react-i18next';
import useEnaConfig from 'data/useEnaConfig';

import EnergyBenefitsWidget from 'components/Ena/EnaCalculator/EnergyBenefitsWidget';

import styles from './ProgramCalculator.module.scss';

const ProgramCalculator = ({ calculator }) => {
  const { t, i18n } = useTranslation(['shop']);
  const { data: config } = useEnaConfig(i18n.language);

  return (
    config && (
      <section>
        <h2 className={styles.description}>{calculator.description}</h2>
        <h2 className={styles.title}>{t('shop:ena.energy-benefits')}</h2>
        <EnergyBenefitsWidget config={config} isShop={true} />
      </section>
    )
  );
};
export default ProgramCalculator;
