import useSWR from 'swr';
import { fetcher } from 'api/cms';
import { fetcher as shopFetcher } from 'api/cms-shop';
import { isEmptyString } from 'utils/stringUtils';

const useCmsContent = (
  value,
  language,
  skip = false,
  filter = '[schema]',
  {
    sort = 'tree.order',
    includeFormat = 'bySchemaId',
    limit = 500,
    includeSchemas = [],
    query,
  } = {},
  options = {
    dedupingInterval: 300000,
    revalidateOnFocus: false,
  },
  isShop = false,
) => {
  const params = [
    `filter${filter}=${value}`,
    `limit=${limit}`,
    `include=${includeSchemas?.length > 0 ? includeSchemas.join(',') : '*'}`,
    `includeFormat=${includeFormat}`,
  ];
  if (!isEmptyString(sort)) params.push(`sort=${sort}`);
  if (!isEmptyString(query)) params.push(query);

  const { data, error } = useSWR(
    skip ? null : [`/documents?${params.join('&')}`, language],
    ([url, language]) =>
      isShop ? shopFetcher(url, language) : fetcher(url, language),
    options,
  );
  if (skip) {
    return {
      loading: false,
      error: null,
      data: null,
    };
  }
  const loading = !data && !error;

  return {
    loading,
    error,
    data,
  };
};

export { useCmsContent };
