import React from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorDialog } from 'components/PromptDialogs/PromptDialog';
import ParticipationConfirm from './ParticipationConfirm';
import ContestMultipleParticipationsSliderDialog from './ContestMultipleParticipationsSliderDialog';

const ContestParticipationWithPoints = ({
  contest = {},
  open,
  userPendingLoyaltyPoints,
  userAvailableLoyaltyPoints,
  closeDialog,
  participationsCount,
  onSuccess,
  onError,
}) => {
  const { t } = useTranslation(['translation', 'offers']);

  const { redeemPoints, participationsPerParticipantLimit } = contest;

  const canOnlyMakeOneParticipation = participationsPerParticipantLimit === 1;

  const hasInsufficientPoints =
    userPendingLoyaltyPoints !== userAvailableLoyaltyPoints &&
    userPendingLoyaltyPoints >= redeemPoints;

  if (redeemPoints > userAvailableLoyaltyPoints) {
    const errorMessage = hasInsufficientPoints
      ? t(`offers:acquisition-flow.insufficient-points-error-message`)
      : t(`offers:acquisition-flow.points-error-message`);
    return (
      <ErrorDialog
        open={open}
        closeDialog={closeDialog}
        header={t(`offers:acquisition-flow.points-error-title`)}
        message={errorMessage}
      />
    );
  }

  return canOnlyMakeOneParticipation ? (
    <ParticipationConfirm
      contest={contest}
      open={open}
      closeDialog={closeDialog}
      pointsToRedeem={redeemPoints}
      participations={1}
      onSuccess={onSuccess}
      onError={onError}
    />
  ) : (
    <ContestMultipleParticipationsSliderDialog
      open={open}
      closeDialog={closeDialog}
      availableLoyaltyPoints={userAvailableLoyaltyPoints}
      hasInsufficientPoints={hasInsufficientPoints}
      participationsCount={participationsCount}
      contestInfo={contest}
      onSuccess={onSuccess}
      onError={onError}
    />
  );
};

export default ContestParticipationWithPoints;
