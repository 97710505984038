import useLoyaltyHistory from './useLoyaltyHistory';
import { useCollectionOptions } from 'data/useCollectionOptions';
import { getWaveId } from 'utils/product/loyalty/pointsUtils';
import { useAuthUser } from 'utils/AuthUser';

const useLoyaltyHistoryByCollectionType = (type, language) => {
  const { loyaltyCustomerId } = useAuthUser();
  const { collectionOptionsByType, loading: collectionOptionsLoading } =
    useCollectionOptions(language);

  const waveId = getWaveId(collectionOptionsByType, type);

  const { data: rewardsLoyaltyHistory, loading: historyLoading } =
    useLoyaltyHistory(loyaltyCustomerId, waveId);

  return {
    loading: collectionOptionsLoading || historyLoading,
    data: rewardsLoyaltyHistory,
  };
};

export default useLoyaltyHistoryByCollectionType;
