import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Helmet from 'utils/Helmet';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import Widget from 'components/Widget/Widget';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import { Tab, Tabs } from 'components/Tabs/Tabs';
import TabPanel from 'components/Tabs/TabPanel';
import { useMyContestsInfo } from 'data/useMyContestsInfo';
import ContestsTab from './ContestsTab';

const Contests = () => {
  const { t, i18n } = useTranslation(['profile']);
  const [value, setValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data: userContestsData, loading } = useMyContestsInfo(i18n.language);

  return (
    <>
      <Helmet
        head={{
          title: t(`profile:my-contests.title`),
          metaTags: [
            { name: 'title', content: t(`profile:my-contests.title`) },
          ],
        }}
      />

      <WidgetSkeleton
        heightInPixels={352}
        show={loading}
        title={<WidgetHeader title={t(`profile:my-contests.title`)} />}
      >
        <Widget
          background="transparent"
          title={<WidgetHeader title={t(`profile:my-contests.title`)} />}
        >
          <Tabs
            value={value}
            onChange={handleTabChange}
            centered
            variant="fullWidth"
          >
            <Tab label={t('profile:my-contests.active')} />
            <Tab label={t('profile:my-contests.completed')} />
          </Tabs>
          <TabPanel value={value} index={0} idPrefix="contests">
            <ContestsTab
              data={userContestsData?.live}
              emptyStateText={t('profile:my-contests.live-empty-state')}
            />
          </TabPanel>
          <TabPanel value={value} index={1} idPrefix="contests">
            <ContestsTab
              data={userContestsData?.expired}
              emptyStateText={t('profile:my-contests.expired-empty-state')}
            />
          </TabPanel>
        </Widget>
      </WidgetSkeleton>
    </>
  );
};

export default Contests;
