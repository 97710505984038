import get from 'lodash/get';
import {
  newClientSteps,
  existingClientSteps,
} from 'utils/product/onboardingConfig';
import { getCustomerCategory, getShopOrOnboardingType } from './trackingLabels';
import {
  trackNewContractEvent,
  trackOnboardingEvent,
  trackShopProgramEvent,
} from './events';

export const trackEContractResultEvent = (success, data, isShop) => {
  const shopOrOnboardingType = getShopOrOnboardingType(isShop);
  const customerCategory = getCustomerCategory(data.customerCategory);
  if (isShop === true) {
    trackShopProgramEvent(
      `shop_program_activation_${success ? 'success' : 'fail'}`,
      '',
      {
        shop_or_onboarding_type: shopOrOnboardingType,
        energy_type: data.energyType,
        customer_type: customerCategory,
      },
    );
  }
  trackNewContractEvent(
    `onboarding_application_${success ? 'success' : 'fail'}`,
    '',
    {
      shop_or_onboarding_type: shopOrOnboardingType,
      energy_type: data.energyType,
      customer_type: customerCategory,
    },
  );
};

const trackEnergyTypeStep = (data, isShop) => {
  trackOnboardingEvent('onboarding_energy_type_continue', data.energyType);
};

const trackProgramTypeStep = (data, isShop) => {
  trackOnboardingEvent('onboarding_program_type_continue', data.programType, {
    energy_type: data?.energyType,
  });
};

const trackSharedStep = (data, isShop) => {
  const shopOrOnboardingType = getShopOrOnboardingType(isShop);
  trackNewContractEvent('onboarding_gas_supply_type_continue', data.shared, {
    shop_or_onboarding_type: shopOrOnboardingType,
    energy_type: data?.energyType,
  });
};

const trackEnergyShopStep = (data, isShop) => {
  const shopOrOnboardingType = getShopOrOnboardingType(isShop);
  trackOnboardingEvent(
    'onboarding_program_select',
    get(data, `energyProgram.packageId`, ''),
    {
      shop_or_onboarding_type: shopOrOnboardingType,
      energy_type: data?.energyType,
    },
  );
};

const trackGasShopStep = (data, isShop) => {
  const shopOrOnboardingType = getShopOrOnboardingType(isShop);
  trackOnboardingEvent(
    'onboarding_program_select',
    get(data, `gasProgram.packageId`, ''),
    {
      shop_or_onboarding_type: shopOrOnboardingType,
      energy_type: data?.energyType,
    },
  );
};

const trackEnergyProgramStep = (data, isShop) => {
  const shopOrOnboardingType = getShopOrOnboardingType(isShop);
  trackOnboardingEvent(
    'onboarding_program_activate',
    get(data, `energyProgram.packageId`, ''),
    {
      shop_or_onboarding_type: shopOrOnboardingType,
      energy_type: data?.energyType,
    },
  );
};
const trackGasProgramStep = (data, isShop) => {
  const shopOrOnboardingType = getShopOrOnboardingType(isShop);
  trackOnboardingEvent(
    'onboarding_program_activate',
    get(data, `gasProgram.packageId`, ''),
    {
      shop_or_onboarding_type: shopOrOnboardingType,
      energy_type: data?.energyType,
    },
  );
};

const trackCustomerCategoryStep = (data, isShop) => {
  const shopOrOnboardingType = getShopOrOnboardingType(isShop);
  const customerCategory = getCustomerCategory(data.customerCategory);
  trackOnboardingEvent(
    'onboarding_home_customer_type_continue',
    customerCategory,
    {
      shop_or_onboarding_type: shopOrOnboardingType,
      energy_type: data?.energyType,
    },
  );
};

const trackBusinessCustomerCategoryStep = (data, isShop) => {
  const shopOrOnboardingType = getShopOrOnboardingType(isShop);
  const customerCategory = getCustomerCategory(data.customerCategory);
  trackOnboardingEvent(
    'onboarding_business_customer_type_continue',
    customerCategory,
    {
      shop_or_onboarding_type: shopOrOnboardingType,
      energy_type: data?.energyType,
    },
  );
};

const trackElectricityStep = (data, isShop) => {
  const shopOrOnboardingType = getShopOrOnboardingType(isShop);
  trackNewContractEvent(
    'onboarding_electricity_bill_continue',
    data.electricity,
    {
      shop_or_onboarding_type: shopOrOnboardingType,
      energy_type: data?.energyType,
    },
  );
};

const trackGasStep = (data, isShop) => {
  const shopOrOnboardingType = getShopOrOnboardingType(isShop);
  trackNewContractEvent('onboarding_gas_bill_continue', data.gas, {
    shop_or_onboarding_type: shopOrOnboardingType,
    energy_type: data?.energyType,
  });
};

const trackNewGasSupplyStep = (data, isShop) => {
  const shopOrOnboardingType = getShopOrOnboardingType(isShop);
  trackNewContractEvent('onboarding_new_gas_supply_interest', '', {
    shop_or_onboarding_type: shopOrOnboardingType,
    energy_type: data?.energyType,
  });
};

const trackInfoStep = (data, isShop) => {
  const shopOrOnboardingType = getShopOrOnboardingType(isShop);
  trackNewContractEvent('onboarding_what_you_need_continue', '', {
    shop_or_onboarding_type: shopOrOnboardingType,
    energy_type: data.energyType,
  });
};

const trackAccountInfoStep = (data, isShop) => {
  const shopOrOnboardingType = getShopOrOnboardingType(isShop);
  const customerCategory = getCustomerCategory(data.customerCategory);
  trackOnboardingEvent('onboarding_account_info_continue', '', {
    shop_or_onboarding_type: shopOrOnboardingType,
    energy_type: data.energyType,
    customer_type: customerCategory,
  });
};

const trackPrimaryContactStep = (data, isShop) => {
  const shopOrOnboardingType = getShopOrOnboardingType(isShop);
  const customerCategory = getCustomerCategory(data.customerCategory);
  trackNewContractEvent('onboarding_contact_person_continue', '', {
    shop_or_onboarding_type: shopOrOnboardingType,
    energy_type: data.energyType,
    customer_type: customerCategory,
  });
  return;
};

const trackMeterDetailsStep = (data, isShop) => {
  const shopOrOnboardingType = getShopOrOnboardingType(isShop);
  const customerCategory = getCustomerCategory(data.customerCategory);
  trackNewContractEvent('onboarding_supply_info_continue', '', {
    shop_or_onboarding_type: shopOrOnboardingType,
    energy_type: data.energyType,
    customer_type: customerCategory,
  });
  return;
};

const trackUserCapacityStep = (data, isShop) => {
  const shopOrOnboardingType = getShopOrOnboardingType(isShop);
  const customerCategory = getCustomerCategory(data.customerCategory);
  trackNewContractEvent('onboarding_gas_supply_user_type_continue', '', {
    shop_or_onboarding_type: shopOrOnboardingType,
    energy_type: data.energyType,
    customer_type: customerCategory,
  });
  return;
};

const trackEbillStep = (data, isShop) => {
  const shopOrOnboardingType = getShopOrOnboardingType(isShop);
  const customerCategory = getCustomerCategory(data.customerCategory);
  trackNewContractEvent('onboarding_bill_info_continue', '', {
    shop_or_onboarding_type: shopOrOnboardingType,
    energy_type: data.energyType,
    customer_type: customerCategory,
  });
  return;
};

const trackContactPreferencesStep = (data, isShop) => {
  const shopOrOnboardingType = getShopOrOnboardingType(isShop);
  const customerCategory = getCustomerCategory(data.customerCategory);
  trackNewContractEvent('onboarding_marketing_materials_continue', '', {
    shop_or_onboarding_type: shopOrOnboardingType,
    energy_type: data.energyType,
    customer_type: customerCategory,
  });
  return;
};

const trackIdentificationStep = (data, isShop) => {
  const shopOrOnboardingType = getShopOrOnboardingType(isShop);
  const customerCategory = getCustomerCategory(data.customerCategory);
  trackNewContractEvent('onboarding_documents_verify_continue', '', {
    shop_or_onboarding_type: shopOrOnboardingType,
    energy_type: data.energyType,
    customer_type: customerCategory,
  });
  return;
};

const getEvent = (step, isShop) => {
  const steps = isShop ? existingClientSteps : newClientSteps;
  const mapper = {
    [steps.energyType]: trackEnergyTypeStep,
    [steps.programType]: trackProgramTypeStep,
    [steps.shared]: trackSharedStep,
    [steps.energyShop]: trackEnergyShopStep,
    [steps.gasShop]: trackGasShopStep,
    [steps.energyProgram]: trackEnergyProgramStep,
    [steps.gasProgram]: trackGasProgramStep,
    [steps.customerCategory]: trackCustomerCategoryStep,
    [steps.businessCustomerCategory]: trackBusinessCustomerCategoryStep,
    [steps.electricity]: trackElectricityStep,
    [steps.gas]: trackGasStep,
    [steps.newGasSupply]: trackNewGasSupplyStep,
    [steps.electricityInfo]: trackInfoStep,
    [steps.gasInfo]: trackInfoStep,
    [steps.combinedInfo]: trackInfoStep,
    [steps.customerDetails]: trackAccountInfoStep,
    [steps.companyDetails]: trackAccountInfoStep,
    [steps.primaryContact]: trackPrimaryContactStep,
    [steps.meterDetails]: trackMeterDetailsStep,
    [steps.gasMeterDetails]: trackMeterDetailsStep,
    [steps.userCapacity]: trackUserCapacityStep,
    [steps.eBill]: trackEbillStep,
    [steps.gasEBill]: trackEbillStep,
    [steps.contactPreferences]: trackContactPreferencesStep,
    [steps.identification]: trackIdentificationStep,
  };

  return mapper[step];
};

export const trackEContractEvent = (step, data, isShop) => {
  const _trackEventMethod = getEvent(step, isShop);
  if (typeof _trackEventMethod === 'function') _trackEventMethod(data, isShop);
};
