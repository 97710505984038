import storage from './storage';

const APP_BANNER_KEY = 'app_banner';

const getBanner = () => {
  return storage.getItem({ key: APP_BANNER_KEY });
};

const setBanner = (value) => {
  return storage.setItem({
    key: APP_BANNER_KEY,
    value: value,
    expiresInDays: 30,
  });
};

const removeBanner = () => {
  return storage.removeItem({ key: APP_BANNER_KEY });
};

const appBannerStorage = {
  getBanner,
  setBanner,
  removeBanner,
};
export default appBannerStorage;
