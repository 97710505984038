import { getObjectValues } from 'utils/objectUtils';

const getLocalizedEnumData = (enumData, language = 'el') => {
  return enumData
    ? getObjectValues(enumData).reduce((accumulator, current) => {
        const { code, message, ...rest } = current;
        accumulator[code] = { ...rest, code, message: message[language] };
        return accumulator;
      }, {})
    : {};
};

export default getLocalizedEnumData;
