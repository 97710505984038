export const clearingBillIntervalInDays = 10;

export const billsExpirationInterval = 5;

export const billsDisconnectInterval = 2;

export const homeCardsLimit = 5;

export const homeRequestsLimit = 5;

export const largeAccountMetersThreshold = 10;

export const defaultKVA = 8;

export const consumptionForecastSpan = 30;

export const onboardingPromotion = 'ena';

export const whatsNewSlidesCount = 3;

export const whatsNewOnboardingSlidesCount = 4;

export const uniqueFlowSteps = 5;
export const allBillsPageSize = 12; //50;
export const meterBillsPageSize = 12; //13;

export const kvaList = [
  { value: 8, text: '8 kVA' },
  { value: 12, text: '12 kVA' },
  { value: 15, text: '15 kVA' },
  { value: 25, text: '25 kVA' },
  { value: 35, text: '35 kVA' },
  { value: 55, text: '55 kVA' },
  { value: 85, text: '85 kVA' },
  { value: 135, text: '135 kVA' },
  { value: 250, text: '250 kVA' },
];

export const enumUrls = {
  Offices: '/onBoarding/taxOffices',
  PostalOffices: '/onBoarding/postalOffices',
  Activities: '/onBoarding/accountActivities',
  LegalOrgs: '/onBoarding/legalOrgs',
  Legalforms: '/onBoarding/legalForms',
  GasTariffgroups: '/onBoarding/gasTariffGroups',
  DeliveryPointCategories: '/onBoarding/deliveryPointCategories',
  GasCommunities: '/onBoarding/gasCommunities',
  Regions: '/onBoarding/regions',
};

export const numberRegexPattern = /^[0-9]*$/;

export const contactEmailB2B = 'b2b.sales@heron.gr';

export const meterPaymentMethods = [
  {
    value: 0,
    text: 'Πάγια Τραπεζική Εντολή',
    icon: 'cash',
  },
  {
    value: 1,
    text: 'Bank Deposit',
    icon: 'cash',
  },
  {
    value: 2,
    text: 'Χρέωση Τραπεζικού Λογαριασμού',
    icon: 'card',
  },
  {
    value: 3,
    text: 'e-banking',
    icon: 'card',
  },
  {
    value: 4,
    text: 'EasyPay',
    icon: 'card',
  },
  {
    value: 5,
    text: 'Credit Card',
    icon: 'card',
  },
  {
    value: 6,
    text: 'Phone banking',
    icon: 'card',
  },
  {
    value: 7,
    text: 'Electronic',
    icon: 'cash',
  },
  {
    value: 8,
    text: 'Standing Order',
    icon: 'cash',
  },
  {
    value: 9,
    text: 'Other',
    icon: 'cash',
  },
  {
    value: 10,
    text: 'Autopay',
    icon: 'cash',
  },
  {
    value: 11,
    text: 'Check',
    icon: 'cash',
  },
  {
    value: 12,
    text: 'DIAS Direct',
    icon: 'cash',
  },
  {
    value: 13,
    text: 'DIAS Debit',
    icon: 'card',
  },
  {
    value: 16,
    text: 'NBG 0151/47082612',
    icon: 'cash',
  },
  {
    value: 17,
    text: 'NBG 0151/47088221',
    icon: 'cash',
  },
  {
    value: 18,
    text: 'ALPHA 0101002002242506',
    icon: 'cash',
  },
  {
    value: 19,
    text: 'ALPHA 0101002002060690',
    icon: 'card',
  },
  {
    value: 20,
    text: 'PIREAUS 5011049973951',
    icon: 'cash',
  },
  {
    value: 21,
    text: 'PIREAUS 5011059379372',
    icon: 'cash',
  },
  {
    value: 22,
    text: 'PIREAUS 6901132980402',
    icon: 'cash',
  },
  {
    value: 23,
    text: 'ΕUROBANK',
    icon: 'cash',
  },
  {
    value: 24,
    text: 'EasyPay CC',
    icon: 'cash',
  },
  {
    value: 25,
    text: 'Accounting Dpt Payment - NBG 0151/47082612',
    icon: 'cash',
  },
  {
    value: 26,
    text: 'Accounting Dpt Payment - NBG 0151/47088221',
    icon: 'cash',
  },
  {
    value: 27,
    text: 'Accounting Dpt Payment - ALPHA 0101002002242506',
    icon: 'cash',
  },
  {
    value: 28,
    text: 'Accounting Dpt Payment - ALPHA 0101002002060690',
    icon: 'card',
  },
  {
    value: 29,
    text: 'Accounting Dpt Payment - PIREAUS 5011049973951',
    icon: 'card',
  },
  {
    value: 30,
    text: 'Accounting Dpt Payment - PIREAUS 5011059379372',
    icon: 'card',
  },
  {
    value: 31,
    text: 'Accounting Dpt Payment - PIREAUS 6901132980402',
    icon: 'card',
  },
  {
    value: 32,
    text: 'Accounting Dpt Payment - ΕUROBANK',
    icon: 'card',
  },
  {
    value: 33,
    text: 'CreditCard (batch)',
    icon: 'card',
  },
  {
    value: 34,
    text: 'CreditCard (site)',
    icon: 'cash',
  },
  {
    value: 35,
    text: 'CreditCard (POS Peiraios ΘΗΒΑ)',
    icon: 'card',
  },
  {
    value: 36,
    text: 'CreditCard (POS Alpha ΘΗΒΑ)',
    icon: 'card',
  },
  {
    value: 37,
    text: 'CreditCard (POS Peiraios ΑΘΗΝΑ)',
  },
  {
    value: 38,
    text: 'CreditCard (POS Alpha ΑΘΗΝΑ)',
  },
  {
    value: 39,
    text: 'Reward (Yellow)',
  },
];

export const dateFormats = {
  year: 'yyyy',
  monthName: 'MMMM',
  dayName: 'EEEE',
  time: 'HH:mm',
  log: 'yyyyMMddHHmmss',
  file: 'ddMMyy',
  dateDash: 'dd-MM-yyyy',
  dateDashReverse: 'yyyy-MM-dd',
  full: "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",

  //formats displayed to user
  date: 'dd/MM/yyyy',
  dateWithDay: 'EEEE dd/MM/yyyy',
  dateHalfYear: 'dd/MM/yy',
  dayMonthNoPad: 'd/M',
  dayMonthNoDayPad: 'd/MM',
  dayMonth: 'dd/MM',
  dateTimeNoYear: 'dd/MM HH:mm',

  dayFullMonthYear: 'd MMMM yyyy',
  dayFullMonth: 'd MMMM',
};
