import React from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

import { isEmptyString } from 'utils/stringUtils';
import {
  validateGreekPhone,
  validateGreekMobilePhoneNoPrefix,
} from 'utils/validators';
import { StandardInput } from './Inputs';

const TelephoneInput = ({
  register = () => {},
  errors = {},
  label,
  name = 'phone',
  required = true,
  mobileOnly = false,
  ...rest
}) => {
  const { t } = useTranslation(['inputs']);
  const baseName = 'inputs:errors';
  const { ref, ...registerRest } =
    register(name, {
      required: { value: required, message: t(`${baseName}.required`) },
      validate: {
        greekPhone: (value) => {
          if (!required && isEmptyString(value)) return true;
          if (mobileOnly) {
            return (
              validateGreekMobilePhoneNoPrefix(value) ||
              t(`${baseName}.telephone`)
            );
          }
          return validateGreekPhone(value) || t(`${baseName}.telephone`);
        },
      },
    }) || {};
  return (
    <>
      <StandardInput
        inputRef={ref}
        label={label}
        type="text"
        errorMessage={get(errors, `${name}.message`, null)}
        error={get(errors, `${name}`, null) !== null}
        {...rest}
        {...registerRest}
      />
    </>
  );
};

export { TelephoneInput };
