import React from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { numberRegexPattern } from 'utils/product/config';
import { StandardInput } from './Inputs';

const InvoiceNumberInput = ({
  register,
  errors,
  label,
  name = 'invoiceNumber',
  required = true,
  ...rest
}) => {
  const { t } = useTranslation(['inputs']);
  const baseName = 'inputs:errors';
  const { ref, ...registerRest } =
    register(name, {
      required: { value: required, message: t(`${baseName}.required`) },
      pattern: {
        value: numberRegexPattern,
        message: t(`${baseName}.isNotNumber`),
      },
    }) || {};

  return (
    <>
      <StandardInput
        inputRef={ref}
        label={label}
        type="text"
        errorMessage={get(errors, `${name}.message`, null)}
        error={get(errors, `${name}`, null) !== null}
        {...rest}
        {...registerRest}
      />
    </>
  );
};

export { InvoiceNumberInput };
