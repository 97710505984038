import React from 'react';
import { useLocation, Navigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthUser } from 'utils/AuthUser';

import { replaceAll } from 'utils/stringUtils';
import SingleColumnLayout from 'layouts/SingleColumnLayout';
import ProgramWidget from 'components/Shop/Programs/ProgramWidget';
import OnboardingFlowDialog from 'components/OnboardingNewClient/OnboardingFlowDialog';
import OnboardingFormProvider from 'components/OnboardingNewClient/OnboardingFormProvider';
import { useShop } from 'data/useShop';

const Program = ({ isEna = false, isDialogOpen = false }) => {
  const location = useLocation();
  const { user } = useAuthUser();
  const { programType, energyType, programName } = useParams();
  const { packageId = null } = location.state || {};

  const { i18n, t } = useTranslation(['header']);
  const language = i18n.language;

  const { data: programs, loading: isLoading } = useShop(language);

  const program = programs.find((program) =>
    isEna
      ? program.isEna
      : packageId
        ? program.packageId === packageId
        : program.programType?.toLowerCase() === programType?.toLowerCase() &&
          program.energyType?.toLowerCase() === energyType?.toLowerCase() &&
          program.urlName === programName,
  );
  if (isLoading === false && !program) {
    return (
      <Navigate
        to={`/shop/${
          programType?.toLowerCase() === 'business' ? 'business' : 'home'
        }`}
      />
    );
  }
  return (
    <SingleColumnLayout
      head={{
        title: programName
          ? replaceAll(`${programName}`, '-', ' ').toUpperCase()
          : t('header:shop'),
        metaTags: [
          {
            name: 'title',
            content: programName
              ? replaceAll(`${programName}`, '-', ' ').toUpperCase()
              : t('header:shop'),
          },
        ],
      }}
    >
      {program && (
        <OnboardingFormProvider isShop={true} user={user} program={program}>
          {isDialogOpen && (
            <OnboardingFlowDialog isShop={true} user={user} program={program} />
          )}
          <ProgramWidget program={program} loading={isLoading} />
        </OnboardingFormProvider>
      )}
    </SingleColumnLayout>
  );
};

export default Program;
