import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useDialog from 'hooks/useDialog';
import useAccountMeters from 'data/useAccountMeters';
import { activateEna } from 'api/shop';
import { getAvailableMetersForEnaActivation } from 'utils/product/shopUtils';
import { trackShopEnaEvent } from 'utils/product/tracking/events';

import MetersListDialog from 'components/RequestsHome/MetersListDialog';
import { PrimaryButton } from 'components/Buttons/Buttons';
import { SuccessDialog } from 'components/PromptDialogs/PromptDialog';
import styles from './ProgramActions.module.scss';

const ActivateProgramButton = ({ title }) => {
  const { t } = useTranslation(['shop']);
  const {
    meters,
    loading: metersLoading,
    mutate,
  } = useAccountMeters({
    statusesElectricity: [],
    statusesGas: [],
  });
  const availableMeters = !metersLoading
    ? getAvailableMetersForEnaActivation(meters)
    : [];

  const activationDialog = useDialog();
  const activationSuccessDialog = useDialog();
  const canActivate = availableMeters.length > 0;

  const [selectedMeterId, setSelectedMeterId] = useState(
    availableMeters.length === 1 ? availableMeters[0].Id : ''
  );

  useEffect(() => {
    if (activationDialog.isOpen === false) {
      activationDialog.clearError();
    }
  }, [activationDialog]);

  const handleEnaActivationError = (code, message) => {
    activationDialog.handleError(
      code === 500 && message.indexOf('Προσωρινή Υποβολή') > -1 ? code : null,
      'ena'
    );

    trackShopEnaEvent('shop_detailed_view_ENA_activation_fail', title);
  };

  const activateEnaProgram = () => {
    activationDialog.startLoading();
    activateEna(selectedMeterId)
      .then((result) => {
        if (result.data.ResponseCode === 200 && result.data.Result === true) {
          activationDialog.close();
          activationSuccessDialog.show();
          trackShopEnaEvent('shop_detailed_view_ENA_activation_success', title);
          return;
        }
        handleEnaActivationError(
          result.data.ResponseCode,
          result.data.ResponseMessage
        );
      })
      .catch((error) => {
        handleEnaActivationError(
          error.response.data?.statusCode,
          error.response.data?.message
        );
      })
      .then((res) => {
        activationDialog.stopLoading();
      });
  };

  return (
    canActivate && (
      <>
        <PrimaryButton
          className={styles.actionButton}
          onClick={() => {
            activationDialog.show();
            trackShopEnaEvent('shop_detailed_view_ENA_activation', title);
          }}
        >
          {t('shop:program.activate')}
        </PrimaryButton>

        <MetersListDialog
          headerText={t(`shop:ena.activate-title`)}
          open={activationDialog.isOpen}
          closeDialog={activationDialog.close}
          clickHandler={activateEnaProgram}
          setSelectedMeter={setSelectedMeterId}
          meters={availableMeters}
          buttonLoading={metersLoading || activationDialog.isLoading}
          error={activationDialog.error}
          clearError={activationDialog.clearError}
        />
        <SuccessDialog
          open={activationSuccessDialog.isOpen}
          closeDialog={() => {
            mutate();
            activationSuccessDialog.close();
          }}
          header={t(`shop:program.activate-success-title`)}
          message={t(`shop:program.activate-ena-success-message`, {
            program: title,
          })}
        />
      </>
    )
  );
};

export default ActivateProgramButton;
