import commodities from 'utils/product/enums/commodities';
import accountCategories from 'utils/product/enums/accountCategories';
import shopEnergyTypes from 'utils/product/enums/shopEnergyTypes';

export const getTrackingEnergyType = (commodity) => {
  if (!commodity) return '';
  return commodity === commodities.gas
    ? shopEnergyTypes.gas
    : shopEnergyTypes.electricity;
};

export const getTrackingConsumptionMeterType = (type) => {
  switch (type) {
    case 'day':
      return 'day';
    case 'night':
      return 'night';
    default:
      return 'without night meter';
  }
};

export const getShopOrOnboardingType = (isShop) => {
  return isShop ? 'SHOP ACTIVATION' : 'ONBOARDING';
};

export const getCustomerCategory = (category) => {
  if (category === `${accountCategories.private}`) return 'ΙΔΙΩΤΗΣ';
  if (category === `${accountCategories.freelancer}`)
    return 'ΕΛ. ΕΠΑΓΓΕΛΜΑΤΙΑΣ';
  return 'ΕΤΑΙΡΕΙΑ';
};
