import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import useAccountMeters from 'data/useAccountMeters';
import { isMeterEligibleForActivation } from 'utils/product/shopUtils';
import shopEnergyTypes from 'utils/product/enums/shopEnergyTypes';
import commodities from 'utils/product/enums/commodities';
import { trackShopProgramEvent } from 'utils/product/tracking/events';
import { PrimaryButton } from 'components/Buttons/Buttons';
import styles from './ProgramActions.module.scss';

const BuyProgramButton = ({ energyType, title }) => {
  const { t } = useTranslation(['shop']);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { meters } = useAccountMeters({
    statusesElectricity: [],
    statusesGas: [],
  });

  const buyProgram = () => {
    trackShopProgramEvent('shop_program_activation', title);

    if (energyType === shopEnergyTypes.combined) {
      const electricityMeters = meters?.filter(
        (x) =>
          x.Commodity === commodities.energy && isMeterEligibleForActivation(x)
      );

      navigate(
        `${pathname}/${
          electricityMeters.length > 0
            ? 'electricity-request'
            : 'electricity-programs'
        }`
      );
      return;
    }
    navigate(
      `${pathname}/${
        energyType === shopEnergyTypes.gas ? 'gas-supply' : 'electricity-supply'
      }`
    );
  };

  return (
    <PrimaryButton className={styles.actionButton} onClick={buyProgram}>
      {t('shop:program.buy-program')}
    </PrimaryButton>
  );
};

export default BuyProgramButton;
