import React from 'react';
import { useTranslation } from 'react-i18next';

import LoginWidget from 'pages/onboarding/LoginWidget';
import OnboardingLayout from 'layouts/OnboardingLayout';
import CreateUserContent from './CreateUserContent';

import styles from './CreateUser.module.scss';

const CreateUser = () => {
  const { t } = useTranslation(['onboarding']);
  return (
    <OnboardingLayout
      alignContainerCenter={false}
      head={{
        title: t('onboarding:loginWidgetContent.create-user'),
        metaTags: [
          {
            name: 'title',
            content: t('onboarding:loginWidgetContent.create-user'),
          },
        ],
      }}
    >
      <LoginWidget containerClass={styles.widgetContainer}>
        <CreateUserContent />
      </LoginWidget>
    </OnboardingLayout>
  );
};

export default CreateUser;
