import React from 'react';

import styles from './ProgramLabel.module.scss';
import { isEmptyString } from 'utils/stringUtils';

const ProgramLabel = ({ label }) => {
  if (!label) return null;
  const { bgColor, bgColorEnd, text, textColor } = label || {};
  const gradient = `linear-gradient(90deg, ${bgColor} 0%, ${
    !isEmptyString(bgColorEnd) ? bgColorEnd : bgColor
  } 100%)`;
  return (
    <section
      className={styles.container}
      style={{
        backgroundColor: bgColor,
        background: gradient,
      }}
    >
      {text && (
        <p
          style={{ color: textColor }}
          className={styles.labelText}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
    </section>
  );
};
export default ProgramLabel;
