import React from 'react';
import { useTranslation } from 'react-i18next';
import useEnum from 'data/useEnum';
import get from 'lodash/get';

import { StandardInput } from './Inputs';
import IconPopover from 'components/Popover/IconPopover';
import { ReactComponent as InfoIconFilled } from 'assets/icons/InfoCircleIcon.svg';

import styles from './ActivityCodeInput.module.scss';

const ActivityCodeInput = ({
  register = () => {},
  errors,
  name,
  label,
  value,
  onActivityChange,
  hidden = false,
}) => {
  const { t } = useTranslation(['newClient', 'inputs']);
  const { Activities = [] } = useEnum('Activities');

  const onChange = (e) => {
    const {
      target: { value },
    } = e;
    const activity =
      Activities.length > 0 && Activities.find((x) => x.Name === `${value}`);
    onActivityChange(activity?.Id);
  };

  const {
    ref,
    onChange: onInputChange = () => {},
    ...registerRest
  } = register(hidden ? `${name}Ignored` : name, {
    required: { value: !hidden, message: t(`inputs:errors.required`) },
    setValueAs: (value) => parseInt(value),
    validate: (value) => {
      const isValid =
        hidden ||
        (Activities.length > 0 &&
          Activities.find((x) => x.Name === `${value}`) !== undefined);
      return isValid;
    },
  }) || {};

  return (
    <StandardInput
      inputRef={ref}
      label={label}
      type="text"
      maxLength={4}
      minLength={4}
      errorMessage={get(errors, `${name}.message`, null)}
      error={get(errors, `${name}`, null) !== null}
      defaultValue={value}
      onChange={(e) => {
        onInputChange(e);
        onChange(e);
      }}
      endAdornment={
        <IconPopover
          id={`kad-popover`}
          content={t(`newClient:meterDetails.profession-info`)}
          icon={<InfoIconFilled />}
          popoverClass={styles.popover}
          placement="bottom-end"
          buttonClass={styles.infoIcon}
        />
      }
      {...registerRest}
    />
  );
};

export default ActivityCodeInput;
