import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useError from 'hooks/useError';
import { setAccountDeletion } from 'api/account';
import useDialog from 'hooks/useDialog';
import { useAuthUser } from 'utils/AuthUser';

import Helmet from 'utils/Helmet';
import { LocalisedErrorBox } from 'components/MessageBox/MessageBox';
import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import { PromptDialog } from 'components/PromptDialogs/PromptDialog';
import { trackProfileEvent } from 'utils/product/tracking/events';
import styles from './AccountDeletion.module.scss';

const AccountDeletion = () => {
  const { t } = useTranslation(['translation', 'profile']);
  const { accountId, logout } = useAuthUser();

  const [loading, setLoading] = useState(false);
  const { error, handleError, clearError } = useError();
  const confirmDialog = useDialog();

  const deleteAccount = () => {
    clearError();
    setLoading(true);

    setAccountDeletion(accountId)
      .then(() => {
        trackProfileEvent('profile_account_deletion_confirm_success');
        confirmDialog.close();
        logout();
      })
      .catch(() => {
        trackProfileEvent('profile_account_deletion_confirm_fail');
        confirmDialog.close();
        handleError('general');
      })
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet
        head={{
          title: t(`profile:user-management.cta`),
          metaTags: [
            { name: 'title', content: t(`profile:user-management.cta`) },
          ],
        }}
      />

      <Widget
        size="m"
        title={
          <WidgetHeader
            title={t(`profile:profileRightSidebar.user-management`)}
          />
        }
      >
        <h2 className={styles.header}>
          {t(`profile:user-management.message`)}
        </h2>
        <LocalisedErrorBox
          open={error !== null}
          closeBox={clearError}
          {...(error || {})}
          distanceTop=""
        />
        <PromptDialog
          open={confirmDialog.isOpen}
          closeDialog={confirmDialog.close}
          showCloseButton={false}
          primaryActionText={t(`translation:confirm`)}
          primaryActionHandler={deleteAccount}
          primaryActionLoading={loading}
          showSecondaryAction={true}
          secondaryActionText={t(`translation:cancel`)}
          secondaryActionHandler={confirmDialog.close}
          header={t(`profile:user-management.cta`)}
          message={t(`profile:user-management.confirm`)}
        ></PromptDialog>
        <section className={styles.submit}>
          <PrimaryButtonLoader onClick={confirmDialog.show}>
            {t(`profile:user-management.cta`)}
          </PrimaryButtonLoader>
        </section>
      </Widget>
    </>
  );
};

export default AccountDeletion;
