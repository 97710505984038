import React from 'react';
import { Link } from 'react-router-dom';

import { trackHomeEvent } from 'utils/product/tracking/events';
import LanguageSelector from 'components/LanguageSelector/LanguageSelector';
import { ReactComponent as Logo } from 'assets/icons/LogoIcon.svg';
import { ReactComponent as LogoSmall } from 'assets/icons/LogoSmallIcon.svg';

import styles from './AuthorizedPrimaryHeader.module.scss';

const AuthorizedPrimaryHeader = ({
  children,
  hideLanguageSelector = false,
}) => {
  const onLogoClicked = () => {
    trackHomeEvent('home_tab_selected');
  };

  return (
    <header className={`mui-fixed ${styles.header}`}>
      <div className={styles.container}>
        <Link
          to="/"
          className={styles.logo}
          aria-label="home"
          onClick={onLogoClicked}
        >
          <Logo className={styles.bigLogo} />
          <LogoSmall className={styles.smallLogo} />
        </Link>
        <section className={styles.containerRight}>
          {children}
          {!hideLanguageSelector && <LanguageSelector isLoggedIn={true} />}
        </section>
      </div>
    </header>
  );
};

export default AuthorizedPrimaryHeader;
