const paymentCardBannerTypes = {
  DETAIL_VIEW: 'detailView',
  VIEW_MORE: 'viewMore',
  SELECT: 'select',
};

const creditCardTypes = {
  VISA: 'visa',
  MASTERCARD: 'mastercard',
  MAESTRO: 'maestro',
};

export { paymentCardBannerTypes, creditCardTypes };
