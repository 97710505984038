import useGasInvoices from './useGasInvoices';
import commodities from 'utils/product/enums/commodities';
import { useInvoiceDetails as useElectricityInvoice } from './iDocs';
import { isEmptyString } from 'utils/stringUtils';

export default function useInvoiceDetails(invoiceNo, commodity, skip = false) {
  const skipElectricityInvoicesLoading =
    isEmptyString(invoiceNo) || commodity === commodities.gas;
  const electricityInvoiceResponse = useElectricityInvoice(
    invoiceNo,
    skipElectricityInvoicesLoading
  );

  const skipGasInvoicesLoading =
    isEmptyString(invoiceNo) || commodity === commodities.energy;
  const gasInvoiceResponse = useGasInvoices(skipGasInvoicesLoading);

  if (commodity === commodities.energy) {
    return electricityInvoiceResponse;
  }

  const { invoices, ...rest } = gasInvoiceResponse;
  const gasInvoiceOverview = invoices?.find((x) => x.invoiceNo === invoiceNo);

  return {
    ...rest,
    data: gasInvoiceOverview,
  };
}
