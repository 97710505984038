import get from 'lodash/get';
import lodashOrberBy from 'lodash/orderBy';
import { parseDate, isEqual } from './dateUtils';

export const sortByDateFieldDescending = (array, sortProperty) => {
  array.sort((a, b) => {
    const dateA = get(a, sortProperty);
    const dateB = get(b, sortProperty);
    return parseDate(dateA) > parseDate(dateB) ? -1 : 1;
  });
};

export const sortByDateFieldAscending = (array, sortProperty) => {
  array.sort((a, b) => {
    const dateA = get(a, sortProperty);
    const dateB = get(b, sortProperty);
    return parseDate(dateA) < parseDate(dateB) ? -1 : 1;
  });
};

export const sortByField = (array, field, showEmptyLast = true) => {
  array.sort((a, b) => {
    const fieldA = get(a, field, null);
    const fieldB = get(b, field, null);

    if (showEmptyLast) {
      if (fieldA === null && fieldB === null) return 0;
      if (fieldA === null) return 1;
      if (fieldB === null) return -1;
    }

    if (fieldA === fieldB) return 0;
    return fieldA <= fieldB ? -1 : 1;
  });
};

export const sortByFieldDescending = (array, field) => {
  array.sort((a, b) => {
    const fieldA = get(a, field);
    const fieldB = get(b, field);
    return fieldA > fieldB ? -1 : fieldA < fieldB ? 1 : 0;
  });
};

export const customHistorySortByFieldDescendingWithSameDate = (
  array,
  field,
  dateField
) => {
  sortByDateFieldDescending(array, dateField);

  array.sort((a, b) => {
    const fieldA = Number(get(a, field)) || get(a, field);
    const fieldB = Number(get(b, field)) || get(b, field);
    const dateFieldA = get(a, dateField);
    const dateFieldB = get(b, dateField);

    if (!isEqual(dateFieldA, dateFieldB)) return 0;

    if (isNaN(fieldA) && !isNaN(fieldB)) return 1;
    if (isNaN(fieldB) && !isNaN(fieldA)) return -1;

    return fieldA > fieldB ? -1 : fieldA < fieldB ? 1 : 0;
  });
};

/* just a wrapper for lodash order function - only added to be easily replaced if need be. */
export const orderBy = (array, fields, orders) => {
  if (!array || array?.length === 0) return [];

  return lodashOrberBy(array, fields, orders);
};
