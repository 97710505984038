import React from 'react';
import { useTranslation } from 'react-i18next';

import useDialog from 'hooks/useDialog';
import { useAuthUser } from 'utils/AuthUser';
import { EarnPointsLabel } from 'components/Loyalty/LoyaltyLabels';
import { getWaveId } from 'utils/product/loyalty/pointsUtils';
import { useCollectionOptions } from 'data/useCollectionOptions';
import { collectionOptionTypes } from 'utils/product/enums/cmsEnums';
import {
  isEbillRewarded,
  isMeterEligibleForReward,
} from 'utils/product/loyalty/ebillUtils';
import { getPointsOfCategory } from 'utils/product/loyalty/pointsUtils';
import useLoyaltyHistory from 'data/useLoyaltyHistory';
import Widget from 'components/Widget/Widget';
import EbillDialog from 'components/EbillDialogs/EbillDialog';

import styles from './EbillWidget.module.scss';

const EbillWidget = ({ meter, handleEbillChange }) => {
  const { t } = useTranslation(['bills', 'homepage']);
  const ebillDialog = useDialog();
  const { isLoyaltyCustomer, loyaltyCustomerId } = useAuthUser();
  const { collectionOptionsByType } = useCollectionOptions();

  const ebillPoints = getPointsOfCategory(
    collectionOptionsByType,
    collectionOptionTypes.E_BILL
  );

  const waveId = getWaveId(
    collectionOptionsByType,
    collectionOptionTypes.E_BILL
  );

  const { data: rewardsLoyaltyHistory, loading: historyLoading } =
    useLoyaltyHistory(loyaltyCustomerId, waveId);

  return (
    <>
      <div className={styles.container} onClick={ebillDialog.show}>
        <Widget
          size="s"
          background={'transparent'}
          containerClass={styles.banner}
          widgetClass={styles.content}
          //onClick={ebillDialog.show}
        >
          <p>{t('ebill.enviroment')}</p>
        </Widget>
        {isLoyaltyCustomer &&
          !historyLoading &&
          isMeterEligibleForReward(meter) &&
          !isEbillRewarded(meter?.Id, rewardsLoyaltyHistory) && (
            <EarnPointsLabel
              points={ebillPoints}
              labelClass={styles.loyaltyPoints}
              distance=""
            />
          )}
      </div>
      <EbillDialog
        open={ebillDialog.isOpen}
        closeDialog={ebillDialog.close}
        mutate={handleEbillChange}
        meter={meter}
        loyaltyHistory={rewardsLoyaltyHistory}
      />
    </>
  );
};

export default EbillWidget;
