import cookiesStorage from 'utils/storage/cookiesStorage';

export const shouldTrack = () => {
  //if (process.env.REACT_APP_ENV === 'development') return false;

  const consent = cookiesStorage.getCookiesConsent();
  return consent && consent.ads === true;
};

export const initializeFbPixel = () => {
  const isTrackingEnabled = shouldTrack();

  isTrackingEnabled ? enableFbPixel() : disableFbPixel();
};

export const enableFbPixel = () => {
  window.fbq('consent', 'grant');
  window.fbq('track', 'PageView');
};

export const disableFbPixel = () => {
  window.fbq('consent', 'revoke');
};

export const trackFbEvent = (name) => {
  if (shouldTrack()) {
    window.fbq('track', name);
  }
};

export const trackFbPageview = () => {
  if (shouldTrack()) {
    window.fbq('track', 'PageView');
  }
};
