import React from 'react';
import { useTranslation } from 'react-i18next';

import { prepareMetersList } from 'utils/product/consumptionUtils';
import {} from 'utils/product/tracking/events';
import useAccountMeters from 'data/useAccountMeters';
import Widget from 'components/Widget/Widget';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import ConsumptionsStates from 'components/Consumptions/HomeStates/ConsumptionsStates';
import { getConsumptionState } from 'utils/product/consumptionUtils';
import consumptionStates from 'utils/product/enums/consumptionStates';
import { ReactComponent as Consumption } from 'assets/icons/ConsumptionsHistory.svg';
import EmptyStateWidget from 'components/Widget/EmptyStateWidget';

const ConsumptionsOverviewLoyalty = () => {
  const { t } = useTranslation(['translation', 'homepage']);

  const { meters: meterData, loading, mutate } = useAccountMeters();

  const meters =
    meterData && !loading
      ? prepareMetersList(meterData).filter(
          (meter) => getConsumptionState(meter) === consumptionStates.register
        )
      : [];

  const refreshData = () => {
    mutate();
  };

  return (
    <WidgetSkeleton distance="76" show={loading} data-testid="skeleton">
      <Widget background="transparent">
        {!loading && meters && meters.length > 0 ? (
          <>
            {meters.map((meter) => {
              return (
                <ConsumptionsStates
                  key={`${meter.MeterNo}-energyElement`}
                  meter={meter}
                  refreshData={refreshData}
                />
              );
            })}
          </>
        ) : (
          <EmptyStateWidget
            icon={<Consumption width="72" height="72" />}
            text={t(
              'loyalty:collection-options.REGISTER_CONSUMPTION.empty-message'
            )}
            subText={t(
              'loyalty:collection-options.REGISTER_CONSUMPTION.empty-submessage'
            )}
            isFullWidth={false}
          />
        )}
      </Widget>
    </WidgetSkeleton>
  );
};

export default ConsumptionsOverviewLoyalty;
