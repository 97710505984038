import React from 'react';

import Main from './Main';
import FullWidthLayout from 'layouts/FullWidthLayout';

import styles from './Main.module.scss';

const LoyaltyLanding = ({ component = null }) => {
  return (
    <>
      {component}
      <FullWidthLayout containerClass={styles['fullWidth-container']}>
        <Main />
      </FullWidthLayout>
    </>
  );
};

export default LoyaltyLanding;
