import * as React from 'react';

import { requestStatuses } from 'utils/product/enums/requests';
import { ReactComponent as InProgress } from 'assets/icons/InProgressIcon.svg';
import { ReactComponent as Completed } from 'assets/icons/CheckGreen.svg';

const RequestStatus = ({ val, ...rest }) =>
  val === requestStatuses.completed ? (
    <Completed {...rest} />
  ) : (
    <InProgress {...rest} />
  );

export default RequestStatus;
