import api from './api';
import get from 'lodash/get';

import { enumUrls } from 'utils/product/config';

import {
  getElPostData,
  getGasPostData,
} from 'utils/product/onboarding/helpers';
import generateRequestData from 'utils/product/requests/generateRequestData';
import { requestTypes } from 'utils/product/enums/requests';
import commodities from 'utils/product/enums/commodities';

const saveElectricityRequest = (data, isShop = false, user = null) => {
  const postData = getElPostData(data, isShop, user);
  return api.post(`/${isShop ? 'account' : 'guest'}/saveElRequest`, postData);
};

const getEnumValues = () => {
  return Promise.all([
    api.get(enumUrls.DeliveryPointCategories),
    api.get(enumUrls.GasTariffgroups),
  ]);
};

const saveGasRequest = (data, isShop = false, user = null) => {
  return getEnumValues().then((result) => {
    const deliveryPointCategoriesResponse = result[0];
    const gasTariffgroupsResponse = result[1];
    if (
      deliveryPointCategoriesResponse.data.ResponseCode === 200 &&
      gasTariffgroupsResponse.data.ResponseCode === 200
    ) {
      return sendGasRequest(data, isShop, user, {
        deliveryPointCategories:
          deliveryPointCategoriesResponse.data.DeliveryPointCategories,
        gasTariffgroups: gasTariffgroupsResponse.data.GasTariffgroups,
      });
    }
    return result;
  });
};

const sendGasRequest = (
  data,
  isShop,
  user,
  { deliveryPointCategories, gasTariffgroups },
) => {
  const postData = getGasPostData(data, isShop, user, {
    deliveryPointCategories,
    gasTariffgroups,
  });
  return api.post(`/${isShop ? 'account' : 'guest'}/saveGasRequest`, postData);
};

const uploadFiles = (
  requestId,
  requestNo,
  commodity,
  files,
  isShop = false,
) => {
  var formData = new FormData();

  files.forEach((file, index) => {
    formData.append(`file-${index}`, file);
  });

  const endpoint = isShop
    ? '/pdf/uploadfileasyncaccount'
    : '/guest/uploadfileasyncguest';

  return api.post(
    `${endpoint}?crmContractId=${requestId}&crmContractNo=${requestNo}&Commodity=${commodity}`,
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    },
  );
};

const newGasSupplyRequest = (data, formLabels) => {
  const postString = generateRequestData({
    labels: formLabels,
    commodity: commodities.gas,
    data: {
      ...get(data, 'newGasSupply', {}),
      PackageId: data.gasProgram.packageId,
    },
    requestType: requestTypes.newGasSupply,
  });
  return api.post('/guest/create', postString);
};

export {
  saveElectricityRequest,
  saveGasRequest,
  uploadFiles,
  newGasSupplyRequest,
};
