import * as Sentry from '@sentry/react';
import get from 'lodash/get';

export const stripDataFromOnboardingRequest = (postData, requestType) => {
  try {
    const data = typeof postData === 'string' ? JSON.parse(postData) : postData;
    switch (requestType) {
      case 'electricity':
        return {
          account: {
            AccountId: get(data, 'account.AccountId', undefined),
            CustomerCategory: get(data, 'account.CustomerCategory', undefined),
          },
          accountMeter: {
            AgreedPower: get(data, 'accountMeter.AgreedPower', undefined),
            Region: get(data, 'accountMeter.Region', undefined),
            ActivityCodeId: get(data, 'accountMeter.ActivityCodeId', undefined),
          },
          elContract: {
            PackageFriendlyName: get(
              data,
              'elContract.PackageFriendlyName',
              undefined
            ),
            DayIndication: get(data, 'elContract.DayIndication', undefined),
            NightIndication: get(data, 'elContract.NightIndication', undefined),
          },
        };
      case 'gas':
        return {
          account: {
            AccountId: get(data, 'account.AccountId', undefined),
            CustomerCategory: get(data, 'account.CustomerCategory', undefined),
          },
          gassupply: {
            DeliveryPointCategory: get(
              data,
              'gassupply.DeliveryPointCategory',
              undefined
            ),
            OwnershipType: get(data, 'gassupply.OwnershipType', undefined),
            TariffGroup: get(data, 'gassupply.TariffGroup', undefined),
            CentralHeating: get(data, 'gassupply.CentralHeating', undefined),
          },
          gasContract: {
            PackageFriendlyName: get(
              data,
              'gasContract.PackageFriendlyName',
              undefined
            ),
            ExistingInstallation: get(
              data,
              'gasContract.ExistingInstallation',
              undefined
            ),
            ReactivationNeeded: get(
              data,
              'gasContract.ReactivationNeeded',
              undefined
            ),
          },
        };
      case 'register':
        return { supplyNumber: data?.supplyNumber };
      case 'new gas supply':
        return { PackageId: get(data, 'PackageId', undefined) };
      default:
        return null;
    }
  } catch (error) {
    console.error(error);
  }
};

export const logOnboardingError = (error, requestType) => {
  // Sentry.withScope((scope) => {
  //   const postData = get(
  //     error,
  //     'config.data',
  //     get(error, 'response.config.data', '-')
  //   );
  //   scope.setExtra(
  //     'postData',
  //     stripDataFromOnboardingRequest(postData, requestType)
  //   );
  //   scope.setExtra('response', get(error, 'response.data', '-'));
  //   scope.setLevel(Sentry.Severity.Log);
  //   Sentry.captureMessage(`Onboarding ${requestType} request failed.`);
  // });
};

export const logPaymentResult = (isSuccess, data) => {
  return;
  // try {
  //   Sentry.withScope((scope) => {
  //     const {
  //       ApprovalCode,
  //       Parameters = '',
  //       yellowsEarned,
  //       yellowsSpent,
  //     } = data || {};
  //     const [
  //       ,
  //       amount,
  //       paymentCode,
  //       meterId,
  //       commodity,
  //       trackUnconfirmedPayment,
  //       invoiceNumber,
  //     ] = Parameters.split('|');
  //     scope.setExtra('params', {
  //       ApprovalCode,
  //       yellowsEarned,
  //       yellowsSpent,
  //       amount,
  //       paymentCode,
  //       meterId,
  //       commodity,
  //       trackUnconfirmedPayment,
  //       invoiceNumber,
  //     });

  //     scope.setLevel(Sentry.Severity.Log);
  //     Sentry.captureMessage(
  //       `Payment ${isSuccess ? 'success' : 'failure'} page loaded`
  //     );
  //   });
  // } catch (error) {
  //   console.error(error);
  // }
};

export const logServiceFailure = (error) => {
  try {
    const {
      name,
      message,
      stack,
      config: { url, data, method, headers } = {},
    } = error || {};

    Sentry.withScope((scope) => {
      scope.setExtra('url', url);
      scope.setExtra('error', { name, message, stack, data, method, headers });

      scope.setLevel('log');
      Sentry.captureMessage(`Service failed: ${url}`);
    });
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setExtra('error', e);
      scope.setLevel('log');
      Sentry.captureMessage(`logServiceFailure threw exception`);
    });
  }
};

export const logWaveServiceFailure = (
  logMessage,
  customerId,
  waveId,
  apiData,
  extraData,
  error
) => {
  try {
    const { config: { url } = {} } = error || {};

    Sentry.withScope((scope) => {
      scope.setExtra('customerId', customerId);
      scope.setExtra('waveId', waveId);
      scope.setExtra('data', apiData ? JSON.stringify(apiData) : '');
      if (url) {
        scope.setExtra('url', url);
      }
      if (extraData) {
        for (const key in extraData) {
          scope.setExtra(key, extraData[key]);
        }
      }

      scope.setLevel('log');
      Sentry.captureMessage(logMessage);
    });
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setExtra('error', e);
      scope.setLevel('log');
      Sentry.captureMessage(`logWaveServiceFailure threw exception`);
    });
  }
};

export const sentryMessage = (message, extra, tags = []) => {
  Sentry.withScope((scope) => {
    if (extra) {
      scope.setContext('Extra info', extra);
    }
    if (tags?.length > 0) {
      tags.forEach((tag) => {
        scope.setTag(tag?.name, tag?.value);
      });
    }

    Sentry.captureMessage(message, 'log');
  });
};
