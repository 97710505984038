import React from 'react';
import { useTranslation } from 'react-i18next';

import useAccountMeters from 'data/useAccountMeters';
import {
  getSortedMetersForHomePage,
  getPendingMeters,
  hasNoInvoices,
} from 'utils/product/meterUtils';
import { homeCardsLimit } from 'utils/product/config';
import { trackHomeBillsEvent } from 'utils/product/tracking/events';
import {
  meterStatusesElectricity,
  meterStatusesGas,
} from 'utils/product/enums/meterStatuses';

import Widget from 'components/Widget/Widget';
import IconText from 'components/IconText/IconText';
import { ReactComponent as Checked } from 'assets/icons/CheckGreen.svg';
import { ReactComponent as EmptyBills } from 'assets/icons/BillIcon.svg';
import WidgetHeaderLink from 'components/WidgetHeader/WidgetHeaderLink';
import DetailedListWidget from 'components/DetailedListWidget/DetailedListWidget';
import HomeBillsList from './HomeBillsList';
import { mutateLoyaltyPoints } from 'data/useRewardSchemeAccounts';
import { useAuthUser } from 'utils/AuthUser';

import styles from './HomeBillWidgetContent.module.scss';

const HomeBillWidgetContent = ({ detailed = false }) => {
  const { t } = useTranslation(['homepage', 'requests']);
  const { loyaltyCustomerId } = useAuthUser();

  const {
    meters,
    loading: metersLoading,
    mutate: mutateMeters,
  } = useAccountMeters({
    statusesElectricity: [
      meterStatusesElectricity.active,
      meterStatusesElectricity.terminated,
    ],
    statusesGas: [meterStatusesGas.active, meterStatusesGas.terminated],
  });

  const allMeters = metersLoading ? [] : getSortedMetersForHomePage(meters);

  const pendingMeters = getPendingMeters(allMeters);

  const hasNoBills = hasNoInvoices(allMeters);

  return (
    <section className={styles.container}>
      <Widget
        background="transparent"
        title={
          <WidgetHeaderLink
            title={t('homepage:my-bills.title')}
            secondTitle={t('homepage:record')}
            linkTo={`/bills`}
            onLinkClicked={() => {
              trackHomeBillsEvent('home_bill_history');
            }}
          />
        }
      >
        {!metersLoading && pendingMeters.length === 0 && (
          <IconText
            Icon={
              hasNoBills ? <EmptyBills width={32} height={32} /> : <Checked />
            }
            iconClass={styles['noPending-icon']}
            containerClass={styles['noPending-container']}
            content={
              hasNoBills
                ? t('homepage:bills-empty.title')
                : t('homepage:bills-no-more.title')
            }
          />
        )}
        <HomeBillsList
          limit={detailed === true ? 0 : homeCardsLimit}
          loading={metersLoading}
          meters={pendingMeters}
          onPaymentSuccess={() => {
            mutateMeters(undefined);
            mutateLoyaltyPoints(loyaltyCustomerId);
          }}
        />
        {!metersLoading && pendingMeters.length > 0 && !detailed && (
          <DetailedListWidget
            show={pendingMeters.length > homeCardsLimit}
            text={t('homepage:bills-list-max')}
            link="/all-bills"
            clickHandler={() => {
              trackHomeBillsEvent('home_bill_view_all');
            }}
          />
        )}
      </Widget>
    </section>
  );
};
export default HomeBillWidgetContent;
