import React from 'react';

import useDialog from 'hooks/useDialog';
import { meterOwnReadings } from 'utils/product/consumptionUtils';
import { trackConsumptionCountEvent } from 'utils/product/tracking/events';
import RegisterOwnReadingsFirstTime from './RegisterOwnReadingsFirstTime';
import RegisterOwnReadingsAgain from './RegisterOwnReadingsAgain';
import MeasurementRegistration from 'components/MeasurementRegistration/MeasurementRegistration';

import styles from './RegisterOwnReadingsWidget.module.scss';

const RegisterOwnReadingsWidget = ({ meter, containerClass, refreshData }) => {
  const allowedDateFrom = meter.allowedReadingDates?.AllowedReadingDateFrom;
  const allowedDateTo = meter.allowedReadingDates?.AllowedReadingDateTo;
  const {
    canRegister: isAllowedToRegister,
    hasRegistered: hasRegisteredAgain,
  } = meterOwnReadings(meter);

  const registrationDialog = useDialog();

  return (
    <>
      {isAllowedToRegister && (
        <>
          <MeasurementRegistration
            open={registrationDialog.isOpen}
            closeDialog={registrationDialog.close}
            meter={meter}
            refreshData={refreshData}
            isTheFirstTimeOfRegistration={!hasRegisteredAgain}
          />
          <section
            className={containerClass ? containerClass : styles.container}
          >
            {hasRegisteredAgain ? (
              <RegisterOwnReadingsAgain
                allowedDateTo={allowedDateTo}
                registerReading={() => {
                  registrationDialog.show();
                  trackConsumptionCountEvent(
                    'consumption_analysis_count_again'
                  );
                }}
              />
            ) : (
              <RegisterOwnReadingsFirstTime
                allowedDateFrom={allowedDateFrom}
                allowedDateTo={allowedDateTo}
                registerReading={() => {
                  registrationDialog.show();
                  trackConsumptionCountEvent('consumption_analysis_count');
                }}
              />
            )}
          </section>
        </>
      )}
    </>
  );
};

export default RegisterOwnReadingsWidget;
