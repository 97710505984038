import Big from 'big.js';
import cloneDeep from 'lodash/cloneDeep';
import { sortByDateFieldDescending } from 'utils/sortUtils';
import { isEmptyString } from 'utils/stringUtils';

export const mergeDiscounts = (discountsResponse) => {
  if (!discountsResponse?.MeterDiscountHistories) return [];

  const mergedDiscounts = discountsResponse.MeterDiscountHistories.reduce(
    (newArray, meter) => {
      const discounts = meter.solarisDiscounts?.map((discount) => {
        return { ...discount, MeterId: meter.MeterId };
      });
      newArray.push(...discounts);
      return newArray;
    },
    []
  );

  sortByDateFieldDescending(mergedDiscounts, 'DiscountDate');
  return mergedDiscounts;
};

export const mergePayments = (paymentsResponse) => {
  if (!paymentsResponse?.solarisMeterPaymentHistories) return [];

  const mergedPayments = paymentsResponse.solarisMeterPaymentHistories.reduce(
    (newArray, meter) => {
      const payments = meter.solarisPayments?.map((payment) => {
        return { ...payment, MeterId: meter.MeterId };
      });
      newArray.push(...payments);
      return newArray;
    },
    []
  );

  sortByDateFieldDescending(mergedPayments, 'Date');
  return mergedPayments;
};

export const getParticipation = (enaDetails) => {
  const payments = new Big(enaDetails.TotalPaymentAmmount ?? 0);
  const fundings = new Big(enaDetails.Funding ?? 0);
  return payments.plus(fundings).toNumber();
};

export const getYearlyConsumption = (yearlyConsumption) => {
  if (isEmptyString(yearlyConsumption)) return '';
  return new Big(yearlyConsumption).round(2, 2).toFixed(2);
};

export const getMetersWithEna = (meters) => {
  const metersWithEna = meters
    ? cloneDeep(
        meters.filter(
          (x) =>
            x.IsActive &&
            x.solarisInfo &&
            !isEmptyString(x.solarisInfo.SolarisPaymentCode)
        )
      )
    : [];

  metersWithEna.sort((a, b) =>
    Math.abs(a.solarisInfo.TotalDiscount) >=
    Math.abs(b.solarisInfo.TotalDiscount)
      ? -1
      : 1
  );
  return metersWithEna;
};
