import { useTranslation } from 'react-i18next';
import { getObjectEntries, getObjectValues } from 'utils/objectUtils';

export default function useFriendlyNameOptions(lang) {
  const { t } = useTranslation(['onboarding']);
  const baseName = 'onboarding:meterNaming';

  const selectOptions = [
    { value: 1, text: t(`${baseName}.home`), isDefault: true },
    { value: 2, text: t(`${baseName}.cottage`), isDefault: true },
    { value: 3, text: t(`${baseName}.work`), isDefault: true },
    { value: 4, text: t(`${baseName}.other`), isDefault: false },
  ];

  const el = {
    home: 'Σπίτι',
    cottage: 'Εξοχικό',
    work: 'Εργασία',
  };
  const eng = {
    home: 'Home',
    cottage: 'Cottage',
    work: 'Workplace',
  };

  const isDefaultValue = (friendlyName) => {
    return (
      getObjectValues(el).indexOf(friendlyName) > -1 ||
      getObjectValues(eng).indexOf(friendlyName) > -1
    );
  };

  const getDefaultValue = (friendlyName) => {
    const defaultElValue = [...getObjectEntries(el)].find(
      ([key, value]) => value === friendlyName
    );

    if (defaultElValue) {
      const [key, value] = defaultElValue;
      return lang === 'el' ? value : t(`${baseName}.${key}`);
    }
    const defaultEnValue = [...getObjectEntries(eng)].find(
      ([key, value]) => value === friendlyName
    );
    if (defaultEnValue) {
      const [key, value] = defaultEnValue;
      return lang === 'en' ? value : t(`${baseName}.${key}`);
    }

    return t(`${baseName}.other`);
  };

  return {
    selectOptions: selectOptions,
    isDefaultValue,
    getDefaultValue,
  };
}
