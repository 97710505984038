import useLoyaltyNotificationsStatus from './useLoyaltyNotificationsStatus';
import useHeronNotifications from './useHeronNotifications';

const useNotificationsStatus = (loyaltyCustomerId, customerId) => {
  const { data, error, loading, mutate } =
    useLoyaltyNotificationsStatus(loyaltyCustomerId);

  const {
    data: heronNotifications,
    loading: heronNotificationsLoading,
    mutate: mutateHeronNotifications,
  } = useHeronNotifications(customerId);

  const hasUnviewed =
    data?.hasUnviewedNotifications === true ||
    heronNotifications?.find(
      (notification) => notification.IsRead === false
    ) !== undefined;

  const mutateNotifications = () => {
    mutate();
    mutateHeronNotifications();
  };

  return {
    loading: loading || heronNotificationsLoading,
    error,
    hasUnviewedNotifications: hasUnviewed,
    mutate: mutateNotifications,
  };
};

export default useNotificationsStatus;
