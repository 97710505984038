import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import useRequestFormData from 'hooks/useRequestFormData';
import generateRequestData from 'utils/product/requests/generateRequestData';
import { requestTypes } from 'utils/product/enums/requests';
import { uploadRequestFiles } from 'api/requests';
import { createRequest } from 'api/requests';
import getPostData from 'utils/product/requests/getPostData';
import { scrollTo } from 'utils/scroll';
import { trackRequestsDetailViewEvent } from 'utils/product/tracking/events';
import useError from 'hooks/useError';

import { getObjectValues } from 'utils/objectUtils';
import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import UploadWidget from 'components/UploadWidget/UploadWidget';
import PersonalDataHome from 'components/Requests/Shared/PersonalDataHome';
import ContactPerson from 'components/Requests/PersonalData/ContactPerson';
import PersonalDataBusiness from 'components/Requests/Shared/PersonalDataBusiness';
import accountCategoryTypes from 'utils/product/enums/accountCategoryTypes';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import {
  SuccessBox,
  LocalisedErrorBox,
} from 'components/MessageBox/MessageBox';

import styles from './PersonalDataRequest.module.scss';

const PersonalDataRequest = ({ account, accountType, defaultValues }) => {
  const { t } = useTranslation(['requests']);
  const formMethods = useForm();
  const {
    formState: { errors },
  } = formMethods;
  const formLabels = useRequestFormData(
    requestTypes.personalDataChange,
    accountType
  );

  const [initialValues, setInitialValues] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isIdRequired, setIsIdRequired] = useState(false);
  const [isFekRequired, setIsFekRequired] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);
  const { error, handleError, clearError } = useError();
  const {
    error: fileError,
    handleError: handleFileError,
    clearError: clearFileError,
  } = useError();
  const [uploadedFiles, setUploadedFiles] = useState({});
  /* keeps the value of requestId, in case fileupload fails to upload again for same request */
  const [requestIdForFileUpload, setRequestIdForFileUpload] = useState(null);

  const onFileChange = (files, type) => {
    if (files) {
      setUploadedFiles({
        ...uploadedFiles,
        [`${type}`]: files[0],
      });
    }
  };

  const uploadFileRequest = (requestId, filesToUpload, data) => {
    uploadRequestFiles(requestId, filesToUpload, true)
      .then(() => {
        setIsSuccess(true);
        if (data) setInitialValues(data);
        setRequestIdForFileUpload(null);
      })
      .catch((error) => {
        handleFileError(error, 'requestFiles');
      })
      .then(() => {
        setIsLoading(false);
        scrollTo(0, 0);
      });
  };

  const onSubmit = (data) => {
    setIsSuccess(false);
    clearError();
    clearFileError();
    setIsLoading(true);

    const postData = getPostData({
      requestType: requestTypes.personalDataChange,
      accountType,
      data,
      defaultValues,
    });
    const initialValuesString = generateRequestData({
      labels: formLabels,
      data: initialValues,
      accountId: account.Id,
      requestType: requestTypes.personalDataChange,
    });
    const postString = generateRequestData({
      labels: formLabels,
      data: postData,
      accountId: account.Id,
      requestType: requestTypes.personalDataChange,
      defaultValues,
    });
    const filesToUpload = getObjectValues(uploadedFiles);

    if (requestIdForFileUpload !== null) {
      if (filesToUpload.length > 0) {
        uploadFileRequest(requestIdForFileUpload, filesToUpload);
        return;
      } else setRequestIdForFileUpload(null);
    }

    if (initialValuesString.RequestText === postString.RequestText) {
      handleError('form-is-same', 'forms');
      setIsLoading(false);
      scrollTo(0, 0);
      return;
    }

    createRequest(postString)
      .then((result) => {
        if (result.data.ResponseCode === 200) {
          trackRequestsDetailViewEvent(
            'request_activation_success',
            t(`requests:types.type-${requestTypes.personalDataChange}`)
          );
          if (result.data.RequestId && filesToUpload.length > 0) {
            setRequestIdForFileUpload(result.data.RequestId);
            uploadFileRequest(result.data.RequestId, filesToUpload, postData);
            return;
          }
          setIsSuccess(true);
          setIsLoading(false);
          setInitialValues(postData);
          scrollTo(0, 0);

          return;
        }
        handleError('general', 'requests');
        setIsLoading(false);
        scrollTo(0, 0);
        trackRequestsDetailViewEvent(
          'request_activation_fail',
          t(`requests:types.type-${requestTypes.personalDataChange}`)
        );
      })
      .catch((error) => {
        handleError(error, 'requests');
        setIsLoading(false);
        trackRequestsDetailViewEvent(
          'request_activation_fail',
          t(`requests:types.type-${requestTypes.personalDataChange}`)
        );
      });
  };

  const watchIdPassport = formMethods.watch(
    'idPassport',
    account?.IdOrPassportNo
  );

  const watchPlaceOfIssue = formMethods.watch(
    'placeOfIssue',
    account?.PlaceOfIssue
  );
  const watchLegalContact = formMethods.watch(
    'legalContact',
    account?.LegalContact ?? ''
  );

  useEffect(() => {
    setInitialValues(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (accountType === accountCategoryTypes.home) {
      const watchedIdValue = watchIdPassport ?? '';
      const originalIdValue = account?.IdOrPassportNo ?? '';
      const watchedPlaceValue = watchPlaceOfIssue ?? '';
      const originalPlaceValue = account?.PlaceOfIssue ?? '';
      setIsIdRequired(
        watchedIdValue !== originalIdValue ||
          watchedPlaceValue !== originalPlaceValue
      );
    }
  }, [
    watchIdPassport,
    account?.IdOrPassportNo,
    watchPlaceOfIssue,
    account?.PlaceOfIssue,
    accountType,
  ]);

  useEffect(() => {
    if (accountType === accountCategoryTypes.business) {
      const watchedValue = watchLegalContact ?? '';
      const originalValue = account?.LegalContact ?? '';
      const feqRequired = watchedValue !== originalValue;

      setIsIdRequired(feqRequired);
      setIsFekRequired(feqRequired);
    }
  }, [watchLegalContact, account?.LegalContact, accountType]);

  return (
    <Widget
      size="m"
      title={
        <WidgetHeader
          title={t(`requests:types.type-${requestTypes.personalDataChange}`)}
        />
      }
    >
      <SuccessBox
        open={isSuccess}
        closeBox={() => setIsSuccess(false)}
        header={t(`requests:submit-success-title`)}
        message={t(`requests:submit-success-subtitle`)}
        distanceTop=""
      />
      <LocalisedErrorBox
        open={error !== null}
        closeBox={clearError}
        {...(error || {})}
        distanceTop=""
      />

      {account && (
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
            {accountType === accountCategoryTypes.home ? (
              <>
                <PersonalDataHome
                  account={account}
                  defaultValues={defaultValues}
                  editable={true}
                  requestType={requestTypes.personalDataChange}
                />
                <ContactPerson
                  account={account}
                  defaultValues={defaultValues}
                />
              </>
            ) : (
              <PersonalDataBusiness
                account={account}
                defaultValues={defaultValues}
                editable={true}
                requestType={requestTypes.personalDataChange}
              />
            )}
            <article className={styles.uploads}>
              <p className={styles.uploadsText}>
                {accountType === accountCategoryTypes.home
                  ? t(`requests:personal-data.upload-home`)
                  : t(`requests:personal-data.upload-business`)}
              </p>
              <LocalisedErrorBox
                open={fileError !== null}
                closeBox={clearFileError}
                {...(fileError || {})}
              />
              {accountType === accountCategoryTypes.business && (
                <UploadWidget
                  label={t(`requests:personal-data.fek`)}
                  required={isFekRequired}
                  register={formMethods.register}
                  name="fek"
                  errors={errors}
                  onFileChange={(file) => onFileChange(file, 'fek')}
                  clearOnCancel={true}
                />
              )}
              <UploadWidget
                label={t(`requests:personal-data.id-front`)}
                required={isIdRequired}
                register={formMethods.register}
                name="idFront"
                errors={errors}
                onFileChange={(file) => onFileChange(file, 'idFront')}
                clearOnCancel={true}
              />
              <UploadWidget
                label={t(`requests:personal-data.id-back`)}
                required={isIdRequired}
                register={formMethods.register}
                name="idBack"
                errors={errors}
                onFileChange={(file) => onFileChange(file, 'idBack')}
                clearOnCancel={true}
              />
            </article>

            <article className={styles.buttonContainer}>
              <PrimaryButtonLoader
                loading={isLoading}
                disabled={isLoading}
                onClick={formMethods.handleSubmit(onSubmit)}
                type="submit"
              >
                {t('requests:requests.submit')}
              </PrimaryButtonLoader>
            </article>
          </form>
        </FormProvider>
      )}
    </Widget>
  );
};

export default PersonalDataRequest;
