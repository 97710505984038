import React, { useState } from 'react';
import cn from 'classnames';

import { useTranslation } from 'react-i18next';
import { isInputValid } from 'utils/product/enaCalculator/inputValidators';
import { calculateBenefits } from 'utils/product/enaCalculator/energyBenefits';
import { fixDecimalString } from 'utils/numberUtils';
import { trackShopEnaEvent } from 'utils/product/tracking/events';
import { formatMoneyWithCurrency } from 'utils/moneyUtils';

import { SecondaryButton } from 'components/Buttons/Buttons';
import { StandardInput } from 'components/Inputs/Inputs';
import SolarisCalculatorResult from './SolarisCalculatorResult';

import styles from './EnergyBenefitsWidget.module.scss';

const EnergyBenefitsWidget = ({
  config,
  onEnergyBenefitsClick = () => {},
  isShop = false,
}) => {
  const { t } = useTranslation(['ena']);
  const [benefits, setBenefits] = useState(null);

  const [amount, setAmount] = useState('');
  const handleAmountChange = (e) => {
    setError(false);
    setBenefits(null);
    setAmount(e.target.value);
  };

  const [error, setError] = useState(false);
  const showBenefits = () => {
    onEnergyBenefitsClick();
    trackShopEnaEvent('ENA_analysis_calculator');

    if (!config) {
      return;
    }
    const amountEntered = fixDecimalString(amount);
    const { isValid, message: errorMessage } = isInputValid(
      amountEntered,
      config
    );
    if (isValid) {
      setError(false);
      setBenefits(calculateBenefits(amountEntered, config));
    } else {
      errorMessage === 'max-value'
        ? setError(
            t(`ena:max-value`, {
              max: formatMoneyWithCurrency(config?.maxAmount, false),
            })
          )
        : setError(true);
      setBenefits(null);
    }
  };

  return (
    <article className={styles.calculatorContainer}>
      <section
        className={cn({
          [styles.topContainerShop]: isShop,
          [styles.topContainer]: !isShop,
        })}
      >
        <article className={styles.inputsContainer}>
          <StandardInput
            className={styles.input}
            name="amount"
            label={t('ena:placeholders.monthly-discount')}
            maxLength={7}
            value={amount}
            error={!!error}
            errorMessage={error}
            onChange={handleAmountChange}
            onKeyUp={(e) => {
              if (e.key === 'Enter' || e.keyCode === 13) showBenefits();
            }}
          />
        </article>
        <article className={styles.btnContainer}>
          <SecondaryButton onClick={showBenefits}>
            {t('ena:see-benefits')}
          </SecondaryButton>
        </article>
      </section>
      <SolarisCalculatorResult
        label={t(`ena:placeholders.yearly-discount`)}
        value={benefits?.yearlyDiscount}
        containerClass={styles.faded}
      />
      <SolarisCalculatorResult
        label={t(`ena:placeholders.total-discount`, {
          years: config?.sumMultiplier,
        })}
        value={benefits?.totalDiscount}
        containerClass={styles.faded}
      />
      <SolarisCalculatorResult
        label={t(`ena:placeholders.contribution`)}
        value={benefits?.contribution}
      />
      {isShop && (
        <p
          className={styles.info}
          dangerouslySetInnerHTML={{ __html: config?.disclaimer }}
        ></p>
      )}
    </article>
  );
};

export default EnergyBenefitsWidget;
