import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import classNames from 'classnames';

import { AppContext } from 'utils/AppContext';
import AuthorizedPrimaryHeader from 'components/AuthorizedPrimaryHeader/AuthorizedPrimaryHeader';
import { BubbleProvider } from 'components/HowToWizardBubble/BubbleContext';
import { useAuthUser } from 'utils/AuthUser';
import accountTypes from 'utils/product/enums/accountTypes';
import Offers from 'pages/offers/Offers';
import Requests from 'pages/requests/Requests';
import Homepage from 'pages/homepage/Homepage';
import Bills from 'pages/bills/Bills';
import FAQs from 'pages/customerCare/faqs/FAQs';
import Stores from 'pages/customerCare/stores/Stores';
import Profile from 'pages/profile/Profile';
import HomepageWizard from 'pages/homepage/HomepageWizard';
import Consumptions from 'pages/consumptions/Consumptions';
import Ena from 'pages/ena/Ena';
import ShopRouter from 'pages/shop/ShopRouter';
import MeterNaming from 'components/MeterNaming/MeterNaming';
import MovingTerms from 'components/RequestsHome/Terms';
import WhatsNew from 'components/WhatsNew/WhatsNew';
import WhatsNewB2B from 'components/WhatsNew/WhatsNewB2B';
import BillsDetailedList from 'components/HomeBillWidget/BillsDetailedList';
import ConsumptionsDetailedList from 'components/Consumptions/Home/ConsumptionsDetailedList';
import CookiesConsent from 'components/Cookies/CookiesConsent';
import TermsDialog from 'components/Terms/TermsDialog';
import EnaDetailedList from 'components/Ena/EnaDetailedList';
import AppBanner from 'components/AppDownload/AppBanner';
import RequestsDetailedList from 'components/RequestsWidget/RequestsDetailedList';
import ErrorBoundary from 'components/ErrorBoundaries/ErrorBoundary';
import LoyaltyRouter from 'components/Loyalty/LoyaltyRouter';
import Contest from 'pages/contest/Contest';
import OfferRouter from 'pages/offer/OfferRouter';
import Game from 'pages/game/Game';
import ContestResults from 'pages/contest/ContestResults';
import NotificationsRouter from 'pages/notifications/NotificationsRouter';
import ChatWidget from 'components/ChatWidget/ChatWidget';
import Management from 'pages/management/Management';
import commodities from 'utils/product/enums/commodities';
import AuthorizedHeaderContentWrapper from 'components/AuthorizedHeaderContent/AuthorizedHeaderContentWrapper';

import styles from './AuthorizedLayout.module.scss';

const AuthorizedLayout = () => {
  const { user } = useAuthUser();
  const [isB2BAccount] = useState(() => user.AccountType === accountTypes.B2B);
  const { showAppBanner } = React.useContext(AppContext);
  const _wizardType = isB2BAccount ? 'howToWizardB2B' : 'howToWizard';

  return (
    <ErrorBoundary>
      <BubbleProvider>
        <section
          className={classNames(styles.container, {
            [styles.hasBanner]: showAppBanner,
          })}
        >
          <AppBanner />
          <AuthorizedPrimaryHeader>
            <Routes>
              <Route
                path="how-to-wizard"
                element={
                  <AuthorizedHeaderContentWrapper
                    linksDisabled
                    showBubbleContent
                    wizardType={_wizardType}
                  />
                }
              />
              <Route path="*" element={<AuthorizedHeaderContentWrapper />} />
            </Routes>
          </AuthorizedPrimaryHeader>
          <ErrorBoundary>
            <Routes>
              <Route index path="/" element={<Homepage />} />
              <Route
                path="whats-new"
                element={
                  <Homepage
                    component={isB2BAccount ? <WhatsNewB2B /> : <WhatsNew />}
                  />
                }
              />
              <Route
                path="web-app-terms"
                element={<Homepage component={<TermsDialog />} />}
              />
              <Route
                path="meter-naming"
                element={<Homepage component={<MeterNaming />} />}
              />
              <Route path="how-to-wizard" element={<HomepageWizard />} />
              <Route path="loyalty/*" element={<LoyaltyRouter />} />
              <Route path="bills" element={<Bills />} />
              <Route
                path="bills/electricity/:invoiceNo"
                element={<Bills commodity={commodities.energy} />}
              />
              <Route
                path="bills/gas/:invoiceNo"
                element={<Bills commodity={commodities.gas} />}
              />
              <Route path="all-bills" element={<BillsDetailedList />} />
              <Route path="consumptions" element={<Consumptions />} />
              <Route path="consumptions/:meterNo" element={<Consumptions />} />
              <Route
                path="all-consumptions"
                element={<ConsumptionsDetailedList />}
              />
              <Route path="all-ena-meters" element={<EnaDetailedList />} />
              <Route index path="offers" element={<Offers />} />
              <Route path="offers/:slug/*" element={<OfferRouter />} />
              <Route path="contests/:slug" element={<Contest />} />
              <Route
                path="/contests/results/:slug"
                element={<ContestResults />}
              />
              <Route path="games/:slug" element={<Game />} />
              <Route path="requests/*" element={<Requests />} />
              <Route path="all-requests" element={<RequestsDetailedList />} />
              <Route path="faqs" element={<FAQs />} />
              <Route path="stores" element={<Stores />} />
              yar
              <Route path="profile/*" element={<Profile />} />
              <Route path="shop/*" element={<ShopRouter />} />
              <Route path="management/*" element={<Management />} />
              <Route path="ena" element={<Ena />} />
              <Route path="ena/:meterNo" element={<Ena />} />
              <Route
                path="notifications/:flow/:waveId?"
                element={<NotificationsRouter />}
              />
              <Route path="moving-terms" element={<MovingTerms />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <CookiesConsent isLoggedIn={true} />
            <ChatWidget />
          </ErrorBoundary>
        </section>
      </BubbleProvider>
    </ErrorBoundary>
  );
};

export default AuthorizedLayout;
