import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ShopCategory from 'components/Shop/Overview/ShopCategory';
import { useShop } from 'data/useShop';
import energyTypes from 'utils/product/enums/shopEnergyTypes';
import programTypes from 'utils/product/enums/programTypes';
import {
  newClientSteps,
  existingClientSteps,
} from 'utils/product/onboardingConfig';

import styles from './ShopSelector.module.scss';

const ShopSelector = ({
  defaultValues,
  step,
  onSelectProgram,
  isShop = false,
}) => {
  const { t, i18n } = useTranslation(['shop']);
  const language = i18n.language;
  const { setValue } = useFormContext();

  const { programType, energyType, shared = 'false' } = defaultValues;
  const isShared = shared === 'true';
  const steps = isShop ? existingClientSteps : newClientSteps;

  const { data: programs, loading: isLoading } = useShop(language);

  const selectedEnergyType =
    energyType === energyTypes.combined
      ? step === steps.energyShop
        ? energyTypes.electricity
        : programType === programTypes.home && !isShared
          ? energyTypes.combined
          : energyTypes.gas
      : energyType;

  const selectProgram = (program) => {
    const baseObjectName =
      step === steps.energyShop ? 'energyProgram' : 'gasProgram';
    const baseObject =
      step === steps.energyShop
        ? defaultValues.energyProgram
        : defaultValues.gasProgram;

    const eBillSelection =
      program.energyType === energyTypes.eco
        ? 'true'
        : baseObject.eBill ?? 'true';
    const generousGuarantee = program?.sections?.guaranteeProgram
      ? typeof defaultValues.generousGuarantee !== 'undefined'
        ? defaultValues?.generousGuarantee
        : program?.sections?.guaranteeProgram?.choices?.find(
            (x) => x.isSelected,
          )?.value
      : false;
    const updatedData = {
      [baseObjectName]: {
        ...baseObject,
        name: program.title,
        packageId: program.packageId,
        urlName: program.urlName,
        energyType: program.energyType,
        eBill: eBillSelection,
      },
      generousGuarantee: `${generousGuarantee}`,
    };

    setValue(`${baseObject}.name`, program.title);
    setValue(`${baseObject}.urlName`, program.urlName);
    setValue(`${baseObject}.energyType`, program.energyType);
    setValue(`${baseObject}.eBill`, eBillSelection);
    setValue(`${baseObject}.packageId`, program.packageId);
    setValue(`generousGuarantee`, `${generousGuarantee}`);
    onSelectProgram(updatedData);
  };

  return (
    <section className={styles.container}>
      {selectedEnergyType === energyTypes.electricity && (
        <>
          <ShopCategory
            title={t(`shop:shop.electricity`)}
            programs={programs.filter(
              (item) =>
                item.programType === programType &&
                (item.energyType === selectedEnergyType ||
                  item.energyType === energyTypes.combinedElectricity),
            )}
            loading={isLoading}
            clickHandler={(program) => selectProgram(program)}
          />
          <ShopCategory
            title={t(`shop:shop.eco`)}
            programs={programs.filter(
              (item) =>
                item.programType === programType &&
                item.energyType === energyTypes.eco,
            )}
            loading={isLoading}
            clickHandler={(program) => selectProgram(program)}
          />
        </>
      )}
      {selectedEnergyType === energyTypes.gas && (
        <ShopCategory
          title={t(`shop:shop.gas`)}
          programs={programs.filter(
            (item) =>
              item.programType === programType &&
              item.energyType === selectedEnergyType &&
              (programType === programTypes.business ||
                !!item.isShared === isShared),
          )}
          loading={isLoading}
          clickHandler={(program) => selectProgram(program)}
        />
      )}
      {selectedEnergyType === energyTypes.combined && (
        <ShopCategory
          title={t(`shop:shop.gas`)}
          programs={programs.filter(
            (item) =>
              item.programType === programType &&
              (item.energyType === selectedEnergyType ||
                item.energyType === energyTypes.gas) &&
              !!item.isShared === isShared,
          )}
          loading={isLoading}
          clickHandler={(program) => selectProgram(program)}
        />
      )}
    </section>
  );
};

export default ShopSelector;
