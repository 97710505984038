import useSWR from 'swr';
import { postFetcher } from 'api/api';
import { isEmptyString } from 'utils/stringUtils';
import {
  transformToIDocsMeterNo,
  transformIDocsInvoice,
} from 'utils/product/billUtils';
import commodities from 'utils/product/enums/commodities';
import idocsCommodities from 'utils/product/enums/idocsCommodities';
import { allBillsPageSize, meterBillsPageSize } from 'utils/product/config';
import { mergeInvoices } from 'utils/product/invoiceUtils';

const idocsConfig = {
  shouldRetryOnError: false,
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

const useInvoiceDetails = (invoiceNo, skip = false) => {
  const payload = {
    invoicenumbers: [`${invoiceNo}`],
    commodity: '',
    pageFrom: 0,
    pageTo: 1,
    resultType: 'myheron',
  };
  const _skip = skip || isEmptyString(invoiceNo);

  const {
    data,
    error,
    mutate,
    isLoading: loading,
  } = useSWR(
    !_skip && ['/idoc/getInvoiceInfo', payload],
    ([_url, _payload]) => postFetcher(_url, _payload),
    { ...idocsConfig }
  );
  const invoiceData = data?.invoices?.[0];

  return {
    loading,
    mutate,
    error,
    data: invoiceData,
  };
};

const useInvoices = (
  taxNo,
  meterSupplyNo,
  supplyType,
  meters,
  skip = false
) => {
  const _skip =
    skip ||
    (isEmptyString(taxNo) &&
      (isEmptyString(meterSupplyNo) || isEmptyString(supplyType)));

  const {
    data,
    error,
    isLoading: loading,
  } = useSWR(
    !_skip && ['/idoc/getInvoiceInfo', taxNo, meterSupplyNo, supplyType],
    ([_url, taxNo, meterSupplyNo, supplyType]) => {
      const payload = {
        pageFrom: 0,
        resultType: 'basic',
      };

      if (!isEmptyString(taxNo)) {
        payload.taxNos = [taxNo];
        payload.commodity = '';
        payload.pageTo = allBillsPageSize;
      }

      if (!isEmptyString(meterSupplyNo)) {
        payload.meters = [transformToIDocsMeterNo(meterSupplyNo)];
        payload.commodity = idocsCommodities[supplyType] ?? '';
        payload.pageTo = meterBillsPageSize;
      }

      return postFetcher(_url, payload);
    },
    { ...idocsConfig }
  );

  //TODO change when idocs is supported for gas too
  const electricityMeters = meters?.filter(
    (x) =>
      x.Commodity === commodities.energy &&
      (isEmptyString(meterSupplyNo) || meterSupplyNo === x.MeterSupplyNo)
  );
  const metersDictionary = electricityMeters
    ? meters.reduce((previous, current) => {
        const key =
          current.Commodity === commodities.energy
            ? transformToIDocsMeterNo(current.MeterSupplyNo)
            : current.MeterSupplyNo;

        previous[key] = current;
        return previous;
      }, {})
    : {};

  if (!loading) {
    const mergedInvoices = mergeInvoices(
      electricityMeters,
      data?.invoices ? data?.invoices : []
    );

    const invoicesData = mergedInvoices.reduce((accumulator, invoice) => {
      const transformedInvoice = {
        ...invoice,
        id: invoice.invoiceNo,
        Commodity: commodities.energy,
      };
      const associatedMeter = metersDictionary[invoice.meterNo];

      const finalInvoice = transformIDocsInvoice(
        transformedInvoice,
        associatedMeter
      );

      if (finalInvoice) accumulator.push(finalInvoice);

      return accumulator;
    }, []);
    return {
      loading,
      error,
      data: invoicesData,
    };
  }
  return {
    loading,
    error,
    data: [],
  };
};

export { useInvoiceDetails, useInvoices };
