import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Big from 'big.js';
import { getReceiptPdf } from 'api/guest';
import { sendReceiptByEmail } from 'api/invoices';
import { downloadPDF } from 'utils/pdfUtils';
import { formatMoneyWithCurrency } from 'utils/moneyUtils';
import { isEmptyString } from 'utils/stringUtils';
import { isReceiptEmailAvailable } from 'utils/product/paymentUtils';
import useDialog from 'hooks/useDialog';

import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import IconTextLarge from 'components/IconText/IconTextLarge';
import { StandardInput } from 'components/Inputs/Inputs';
import {
  ErrorDialog,
  SuccessDialog,
} from 'components/PromptDialogs/PromptDialog';
import { useAuthUser } from 'utils/AuthUser';
import paymentTypes from 'utils/product/enums/paymentTypes';
import PaymentRewards from './PaymentRewards';
import { useCollectionOptions } from 'data/useCollectionOptions';
import { getBillPaymentRewards } from 'utils/product/loyalty/pointsUtils';
import AlertWidget from 'components/AlertWidget/AlertWidget';

import styles from './CardPaymentsSuccess.module.scss';

const CardPaymentsSuccess = ({
  open = false,
  closeDialog,
  loading,
  openDialog,
  paymentResult,
  paymentType,
  loyaltyResponse,
  alertMessages,
}) => {
  const { t } = useTranslation(['bills', 'translation']);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const { accountId } = useAuthUser();
  const pdfUnavailableDialog = useDialog();
  const pdfSuccessDialog = useDialog();
  const pdfErrorDialog = useDialog();
  const emailSuccessDialog = useDialog();
  const emailErrorDialog = useDialog();

  const { eventTypeIds, loading: collectionOptionsLoading } =
    useCollectionOptions();

  const { points, billPoints, bonusPoints } = getBillPaymentRewards(
    loyaltyResponse,
    eventTypeIds,
  );
  const savePdf = () => {
    setIsPdfLoading(true);
    getReceiptPdf(paymentResult.approvalCode)
      .then((res) => {
        pdfSuccessDialog.show();
        downloadPDF(res.data, `myHeron-${paymentResult.approvalCode}.pdf`);
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          pdfUnavailableDialog.show();
          return;
        }
        pdfErrorDialog.show();
      })
      .then(() => {
        closeDialog();
        setIsPdfLoading(false);
      });
  };
  const sendReceiptToEmail = () => {
    setIsEmailLoading(true);
    sendReceiptByEmail(paymentResult.approvalCode, accountId)
      .then((res) => {
        emailSuccessDialog.show();
      })
      .catch((error) => {
        emailErrorDialog.show();
      })
      .then(() => {
        closeDialog();
        setIsEmailLoading(false);
      });
  };

  return (
    <>
      <DialogOverlay
        open={open}
        closeDialog={closeDialog}
        maxWidth="md"
        actionsClass={styles['dialogActions']}
        contentClass={styles['dialogContent']}
        dialogHeader={t(
          `bills:payment-dialog.title${
            paymentType === paymentTypes.settlement
              ? '-settlement'
              : paymentType === paymentTypes.ena
                ? '-ena'
                : ''
          }`,
        )}
        dialogActions={
          <>
            {isReceiptEmailAvailable(paymentType) && (
              <PrimaryButtonLoader
                onClick={sendReceiptToEmail}
                loading={isEmailLoading}
                disabled={isEmailLoading}
              >
                {t(`bills:payment-flow.sendViaEmail`)}
              </PrimaryButtonLoader>
            )}
            <PrimaryButtonLoader
              onClick={savePdf}
              loading={isPdfLoading}
              disabled={isPdfLoading}
            >
              {t(`bills:payment-flow.savePdf`)}
            </PrimaryButtonLoader>
          </>
        }
      >
        <>
          <IconTextLarge
            success={true}
            header={t(`bills:payment-flow.success-title`)}
          />
          <div className={styles['paymentRewardsAndMessages-container']}>
            <PaymentRewards
              yellows={paymentResult?.yellowsEarned}
              points={points}
              billPoints={billPoints}
              bonusPoints={bonusPoints}
              loading={loading || collectionOptionsLoading}
              distance={
                paymentType === paymentTypes.settlement ||
                alertMessages?.length > 0
                  ? 'xxs'
                  : ''
              }
            />
            {paymentType === paymentTypes.settlement && (
              <AlertWidget
                message={t(`bills:payment-flow.settlement-information-message`)}
                altText="settlement info"
                type="info"
                distance={alertMessages?.length > 0 ? 'xxs' : ''}
              />
            )}
            {alertMessages?.map(
              (message) =>
                message && (
                  <AlertWidget key={message} message={message} distance="" />
                ),
            )}
          </div>
          <p className={styles.receiptAvailableText}>
            {isReceiptEmailAvailable(paymentType)
              ? t(`bills:payment-flow.success-message`)
              : ''}
          </p>
          <article className={styles.paymentDetails}>
            <StandardInput
              name="billCustomer"
              label={t(`bills:payment-flow.fullName`)}
              readOnly={true}
              defaultValue={paymentResult?.customerName}
              disabled
            />
            <StandardInput
              name="billNo"
              label={t(`bills:payment-flow.billNo`)}
              readOnly={true}
              defaultValue={paymentResult?.paymentCode}
              disabled
            />
            <StandardInput
              name="billAmount"
              label={t(`bills:payment-flow.billAmount`)}
              readOnly={true}
              defaultValue={formatMoneyWithCurrency(
                new Big(
                  isEmptyString(paymentResult?.paymentAmount)
                    ? 0
                    : paymentResult?.paymentAmount,
                ),
              )}
              disabled
            />
            {paymentResult?.yellowsSpent && (
              <StandardInput
                name="yellowsSpent"
                label={t(`bills:payment-flow.yellowsSpent`)}
                readOnly={true}
                defaultValue={paymentResult?.yellowsSpent}
                disabled
              />
            )}
          </article>
        </>
      </DialogOverlay>
      <ErrorDialog
        open={pdfUnavailableDialog.isOpen}
        closeDialog={() => {
          pdfUnavailableDialog.close();
          openDialog();
        }}
        header={t(`bills:payment-flow.pdfNotReady_title`)}
        message={t(`bills:payment-flow.pdfNotReady_message`)}
        primaryActionHandler={() => {
          pdfUnavailableDialog.close();
          openDialog();
          savePdf();
        }}
        primaryActionText={t(`translation:try-again`)}
      />
      <SuccessDialog
        open={pdfSuccessDialog.isOpen}
        closeDialog={() => {
          pdfSuccessDialog.close();
          openDialog();
        }}
        header={t(`translation:completion`)}
        message={t(`bills:payment-flow.pdfSaved`)}
        disableBackdropClick={false}
      />
      <ErrorDialog
        open={pdfErrorDialog.isOpen}
        closeDialog={() => {
          pdfErrorDialog.close();
          openDialog();
        }}
        disableBackdropClick={false}
      />
      <SuccessDialog
        open={emailSuccessDialog.isOpen}
        closeDialog={() => {
          emailSuccessDialog.close();
          openDialog();
        }}
        header={t(`translation:completion`)}
        message={t(`bills:payment-flow.emailSent`)}
        disableBackdropClick={false}
      />
      <ErrorDialog
        open={emailErrorDialog.isOpen}
        closeDialog={() => {
          emailErrorDialog.close();
          openDialog();
        }}
        disableBackdropClick={false}
      />
    </>
  );
};

export default CardPaymentsSuccess;
