import useData from 'data/useData';
import { mutate } from 'swr';
import { getCreditCardType } from 'utils/product/paymentUtils';

export const mutateStandingOrderMeterInfo = (meterId, commodity) => {
  meterId &&
    commodity &&
    mutate(`meter/standingorder?MeterId=${meterId}&Commodity=${commodity}`);
};

export default function useStandingOrderMeterInfo(
  meterId,
  commodity,
  skip = false,
) {
  const {
    data: cardDetails,
    error,
    loading,
    mutate,
    validating,
  } = useData(
    `meter/standingorder?MeterId=${meterId}&Commodity=${commodity}`,
    { revalidateOnMount: true, dedupingInterval: 0 },
    !meterId || !commodity || skip,
  );

  const data =
    loading || !cardDetails
      ? null
      : {
          ...cardDetails,
          CardNumber: cardDetails?.Cardnbr,
          ExpiryDate:
            cardDetails?.Expirymonth && cardDetails?.Expiryyear
              ? `${cardDetails?.Expirymonth}/${cardDetails?.Expiryyear?.slice(
                  -2,
                )}`
              : '-',
          CardType: getCreditCardType(cardDetails?.Cardnbr),
        };

  return {
    data,
    loading: loading || validating,
    error,
    mutate,
  };
}
