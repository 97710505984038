const atLeastOneCategorySelected = (data) =>
  data.consumption === true ||
  data.powerBilling === true ||
  data.energyBilling === true ||
  data.againstInvoice === true ||
  data.squareMeters === true ||
  data.areaPrice === true ||
  data.oldness === true ||
  data.tapFactor === true ||
  data.daysFactor === true;

export default atLeastOneCategorySelected;
