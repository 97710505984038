import React from 'react';

import { trackBillAnalysisEvent } from 'utils/product/tracking/events';
import BillsDetailsTextField from 'components/BillsDetails/BillsDetailsTextField';
import Accordion from 'components/Accordion/Accordion';

import styles from './BillsAnalysisAccordion.module.scss';

const BillsAnalysisAccordion = ({
  content,
  Icon,
  amount,
  weight = 'regular',
  euro = true,
  details,
}) => {
  const accordionClasses = {
    root: styles['accordion'],
  };
  const accordionSummaryClasses = {
    root: styles['accordion-summary'],
    expanded: styles['accordion-summary-expanded'],
    content: styles['accordion-summaryContent'],
    expandIcon: styles['accordion-expandIcon'],
  };

  const accordionDetailsClasses = {
    root: styles['accordion-details'],
  };
  return (
    <Accordion
      classes={accordionClasses}
      summaryClasses={accordionSummaryClasses}
      detailsClasses={accordionDetailsClasses}
      horizontalLine={false}
      onChange={() => {
        trackBillAnalysisEvent('bill_analysis_description', content);
      }}
      summary={
        <BillsDetailsTextField
          content={content}
          amount={amount}
          weight={weight}
          euro={euro}
          Icon={Icon}
        />
      }
      details={<div className={styles.container}>{details}</div>}
    />
  );
};

export default BillsAnalysisAccordion;
