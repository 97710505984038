import React from 'react';

import { useFormContext } from 'react-hook-form';
import RadioGroup from 'components/RadioButtonsGroup/RadioGroup';
import RadioButton from 'components/RadioButtonsGroup/RadioButton';

import styles from './PlanSelector.module.scss';

const PlanSelector = ({ planSelection, defaultValues = {}, onUpdate }) => {
  const { register, setValue } = useFormContext();

  return (
    <section>
      <h2 className={styles.title}>{planSelection.title}</h2>
      <RadioGroup
        name={`generousGuarantee`}
        value={defaultValues[`generousGuarantee`] || ''}
        handleChange={(event) => {
          const _newValue = event.target.value;
          setValue(`generousGuarantee`, _newValue);
          onUpdate({
            generousGuarantee: event.target.value,
            // [baseObjectName]: {
            //   ...defaultValues[baseObjectName],
            //   userCapacity: event.target.value,
            // },
          });
        }}
      >
        {planSelection.choices.map((choice) => (
          <RadioButton
            value={`${choice.value}`}
            label={choice.title}
            register={register}
            className={styles.radio}
            key={choice.title}
          />
        ))}
      </RadioGroup>
    </section>
  );
};
export default PlanSelector;
