import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { trackEbillEvent } from 'utils/product/tracking/events';
import { getTrackingEnergyType } from 'utils/product/tracking/trackingLabels';
import { setMeterEbilling } from 'api/meters';
import useError from 'hooks/useError';
import { SuccessDialog } from 'components/PromptDialogs/PromptDialog';
import { PromptDialog } from 'components/PromptDialogs/PromptDialog';
import { LocalisedErrorBox } from 'components/MessageBox/MessageBox';
import useDialog from 'hooks/useDialog';

const EbillDeactivationModal = ({
  open,
  closeDialog,
  onSuccess = () => {},
  meterId,
  commodity,
}) => {
  const { t } = useTranslation(['translation', 'bills', 'management']);
  const { error, handleError, clearError } = useError();
  const successDialog = useDialog();

  const [loading, setLoading] = useState(false);

  const handleEbill = () => {
    setLoading(true);
    trackEbillEvent('bill_ebill_deactivate', getTrackingEnergyType(commodity));

    setMeterEbilling(meterId, false, commodity)
      .then((res) => {
        successDialog.show();
        onSuccess();
        trackEbillEvent(
          'bill_ebill_deactivate_success',
          getTrackingEnergyType(commodity)
        );
      })
      .catch((error) => {
        handleError(error, 'ebill');
        trackEbillEvent(
          'bill_ebill_deactivate_fail',
          getTrackingEnergyType(commodity)
        );
      })
      .then(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <PromptDialog
        open={open}
        closeDialog={closeDialog}
        header={t('bills:ebill.disable-ebill')}
        message={t('bills:ebill.more-paper')}
        primaryActionText={t('translation:confirm')}
        primaryActionHandler={handleEbill}
        primaryActionLoading={loading}
        showSecondaryAction={true}
        showCloseButton={false}
        secondaryActionHandler={closeDialog}
      >
        <LocalisedErrorBox
          open={error !== null}
          closeBox={clearError}
          {...(error || {})}
          distanceBottom=""
        />
      </PromptDialog>
      <SuccessDialog
        open={successDialog.isOpen}
        closeDialog={successDialog.close}
        header={t('management:ebill.success-deactivation-header')}
        message={t('management:ebill.success-deactivation-message')}
      />
    </>
  );
};

export default EbillDeactivationModal;
