import React from 'react';
import Helmet from 'utils/Helmet';
import ErrorBoundary from 'components/ErrorBoundaries/ErrorBoundary';

import UnauthorizedPrimaryHeader from 'components/UnauthorizedPrimaryHeader/UnauthorizedPrimaryHeader';

import styles from './UnauthorizedLayout.module.scss';

const UnauthorizedLayout = ({ children, head }) => {
  return (
    <ErrorBoundary>
      <Helmet head={head} />
      <div className={styles.outterContainer}>
        <UnauthorizedPrimaryHeader />
        <div className={styles.container}>
          <main className={styles.column}>{children}</main>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default UnauthorizedLayout;
