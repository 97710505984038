import React from 'react';
import { useTranslation } from 'react-i18next';

import BillsDetailsTextField from 'components/BillsDetails/BillsDetailsTextField';
import BillsAnalysisAccordion from './BillsAnalysisAccordion';
import Widget from 'components/Widget/Widget';
import BillAmounts from 'components/Bills/BillAmounts';
import commodities from 'utils/product/enums/commodities';

import styles from './BillsAnalysis.module.scss';

const BillsAnalysis = ({ invoice, commodity }) => {
  const { t } = useTranslation(['translation', 'bills']);

  return (
    commodity === commodities.energy &&
    invoice.isSynced !== false && (
      <Widget size="m" distance="xs">
        <h3 className={styles.headerText}>{t('bills:bill-analysis-title')}</h3>
        <article className={styles.billContainer}>
          <section className={styles['billAnalysis-container']}>
            <div className={styles.innerContainer}>
              {invoice.guarantee && invoice.guarantee !== 0 && (
                <BillsDetailsTextField
                  content={t('bills:deposit')}
                  amount={invoice.guarantee}
                  euro={true}
                  weight={'regular-noanalysis'}
                />
              )}
              <BillsAnalysisAccordion
                content={t('bills:comission-charge.title')}
                amount={invoice?.total_table_a}
                details={t('bills:comission-charge.details')}
              />
              <BillsAnalysisAccordion
                content={t('bills:adjusted-charges.title')}
                amount={invoice?.total_table_b}
                details={t('bills:adjusted-charges.details')}
              />
              <BillsAnalysisAccordion
                content={t('bills:additional-charges.title')}
                amount={invoice?.total_table_c}
                details={t('bills:additional-charges.details')}
              />
              <BillsAnalysisAccordion
                content={t('bills:dei-tasks.title')}
                amount={invoice?.total_table_d}
                details={t('bills:dei-tasks.details')}
              />
              <BillsAnalysisAccordion
                content={t('bills:municipal-charges.title')}
                amount={invoice?.total_table_e}
                details={t('bills:municipal-charges.details')}
              />
              <BillsAnalysisAccordion
                content={t('bills:ert-charges.title')}
                amount={invoice?.total_table_f}
                details={t('bills:ert-charges.details')}
              />
              <BillsDetailsTextField
                content={t('bills:vat')}
                amount={invoice?.vat}
                weight={'regular-noanalysis'}
              />
            </div>
          </section>
          <section className={styles['billAmount-container']}>
            <BillAmounts
              amount={invoice?.totalCurrentAmount}
              containerClass={styles.billAmounts}
              type="analysis"
            />
          </section>
        </article>
      </Widget>
    )
  );
};

export default BillsAnalysis;
