import React from 'react';

import { ReactComponent as WhatsNewWelcome } from 'assets/icons/WhatsNewWelcome.svg';
import { ReactComponent as WhatsNewConsumption } from 'assets/icons/WhatsNewConsumption.svg';
import { ReactComponent as WhatsNewCustomerCare } from 'assets/icons/WhatsNewCustomerCare.svg';
import { ReactComponent as WhatsNewPresent } from 'assets/icons/WhatsNewPresent.svg';
import whatsNewTypes from 'utils/product/enums/whatsNewTypes';

import styles from './WhatsNewImage.module.scss';

const svgImages = [
  <WhatsNewWelcome />,
  <WhatsNewConsumption />,
  <WhatsNewCustomerCare />,
];

const newClientImages = [
  <WhatsNewPresent />,
  <WhatsNewWelcome />,
  <WhatsNewConsumption />,
  <WhatsNewCustomerCare />,
];

const WhatsNewImage = ({ pageIndex = 0, type = whatsNewTypes.default }) => {
  const _images =
    type === whatsNewTypes.newClient ? newClientImages : svgImages;
  return (
    <>
      <section className={styles.section}>
        <figure className={styles.figure}>{_images[pageIndex]}</figure>
      </section>
    </>
  );
};

export default WhatsNewImage;
