import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import RadioButton from 'components/RadioButtonsGroup/RadioButton';
import RadioGroup from 'components/RadioButtonsGroup/RadioGroup';
import UploadWidget from 'components/UploadWidget/UploadWidget';
import energyTypes from 'utils/product/enums/shopEnergyTypes';

import styles from './Identification.module.scss';

const Identification = ({
  defaultValues,
  onUpdate,
  onFileChange,
  ...props
}) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { t } = useTranslation(['newClient', 'translation']);

  useEffect(() => {
    if (defaultValues.identityFront !== null)
      setValue(`identityFront`, defaultValues.identityFront);
    if (defaultValues.identityBack !== null)
      setValue(`identityBack`, defaultValues.identityBack);
    if (defaultValues.passport !== null)
      setValue(`passport`, defaultValues.passport);
  }, [defaultValues, setValue]);

  return (
    <article className={styles.container}>
      <h3 className={styles.header}>{t(`newClient:identification.header`)}</h3>

      <RadioGroup
        name="identityType"
        value={defaultValues.identityType || ''}
        handleChange={(event) => {
          setValue(`identityType`, event.target.value);
          onUpdate({
            identityType: event.target.value,
          });
        }}
      >
        <RadioButton
          name="identityType"
          value={`identityNo`}
          label={t('newClient:identification.identity')}
          register={register}
          className={styles.radio}
        />
        {defaultValues.identityType === 'identityNo' && (
          <article className={styles.identityFiles}>
            <UploadWidget
              label={t('newClient:identification.identity-front')}
              existingFileName={
                defaultValues['identityFront']?.length > 0
                  ? defaultValues['identityFront'][0].name
                  : ''
              }
              required={defaultValues.identityType === 'identityNo'}
              register={register}
              name="identityFront"
              errors={errors}
              loading={false}
              onFileChange={(files) =>
                onFileChange(files, 'identityFront', energyTypes.combined)
              }
            />
            <UploadWidget
              label={t('newClient:identification.identity-back')}
              existingFileName={
                defaultValues['identityBack']?.length > 0
                  ? defaultValues['identityBack'][0].name
                  : ''
              }
              required={false}
              register={register}
              name="identityBack"
              errors={errors}
              loading={false}
              onFileChange={(files) =>
                onFileChange(files, 'identityBack', energyTypes.combined)
              }
            />
          </article>
        )}
        <RadioButton
          name="identityType"
          value={`passportNo`}
          label={t('newClient:identification.passport')}
          register={register}
          className={styles.radio}
        />
        {defaultValues.identityType === 'passportNo' && (
          <article>
            <UploadWidget
              label={t('newClient:identification.passport')}
              existingFileName={
                defaultValues['passport']?.length > 0
                  ? defaultValues['passport'][0].name
                  : ''
              }
              required={defaultValues.identityType === 'passportNo'}
              register={register}
              name="passport"
              errors={errors}
              loading={false}
              onFileChange={(files) =>
                onFileChange(files, 'passport', energyTypes.combined)
              }
            />
          </article>
        )}
      </RadioGroup>
    </article>
  );
};

export default Identification;
