import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import RadioButton from 'components/RadioButtonsGroup/RadioButton';
import RadioGroup from 'components/RadioButtonsGroup/RadioGroup';
import { MeterIndication } from 'components/Inputs/MeterIndication';

import styles from './NightMeter.module.scss';
import { trackNewContractEvent } from 'utils/product/tracking/events';
import {
  getCustomerCategory,
  getShopOrOnboardingType,
} from 'utils/product/tracking/trackingLabels';

const NightMeter = ({
  defaultValues = {},
  onUpdate,
  isGasProgram = false,
  isShop = false,
}) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { t } = useTranslation(['newClient', 'translation']);
  const baseObjectName = isGasProgram ? 'gasProgram' : 'energyProgram';

  return (
    <article className={styles.container}>
      <h3 className={styles.header}>{t(`newClient:nightMeter.header`)}</h3>

      <RadioGroup
        name={`${baseObjectName}.nightMeter`}
        value={defaultValues[`${baseObjectName}`].nightMeter || ''}
        handleChange={(event) => {
          setValue(`${baseObjectName}.nightMeter`, event.target.value);
          onUpdate({
            [baseObjectName]: {
              ...defaultValues[baseObjectName],
              nightMeter: event.target.value,
            },
          });
        }}
      >
        <RadioButton
          value={`true`}
          label={t('translation:yes')}
          register={register}
          className={styles.radio}
          name={`${baseObjectName}.nightMeterYes`}
        />
        {defaultValues[`${baseObjectName}`].nightMeter === 'true' &&
          defaultValues.electricity === 'true' && (
            <article className={styles.measurements}>
              <MeterIndication
                register={register}
                errors={errors}
                label={t(`newClient:nightMeter.initialDayCounter`)}
                name={`${baseObjectName}.initialDayCounter`}
                defaultValue={
                  defaultValues[baseObjectName]?.initialDayCounter ?? ''
                }
                onInfoClicked={trackNewContractEvent(
                  'onboarding_info_icon_day_meter_indication',
                  '',
                  {
                    shop_or_onboarding_type: getShopOrOnboardingType(isShop),
                    energy_type: defaultValues.energyType,
                    customer_type: getCustomerCategory(
                      defaultValues.customerCategory
                    ),
                  }
                )}
              />

              <MeterIndication
                register={register}
                errors={errors}
                label={t(`newClient:nightMeter.initialNightCounter`)}
                name={`${baseObjectName}.initialNightCounter`}
                defaultValue={
                  defaultValues[baseObjectName]?.initialNightCounter ?? ''
                }
                isNight={true}
                onInfoClicked={trackNewContractEvent(
                  'onboarding_info_icon_night_meter_indication',
                  '',
                  {
                    shop_or_onboarding_type: getShopOrOnboardingType(isShop),
                    energy_type: defaultValues.energyType,
                    customer_type: getCustomerCategory(
                      defaultValues.customerCategory
                    ),
                  }
                )}
              />
            </article>
          )}
        <RadioButton
          value={`false`}
          label={t('translation:no')}
          register={register}
          className={styles.radio}
          name={`${baseObjectName}.nightMeterNo`}
        />
        {defaultValues[`${baseObjectName}`].nightMeter === 'false' &&
          defaultValues.electricity === 'true' && (
            <article>
              <MeterIndication
                register={register}
                errors={errors}
                label={t(`newClient:nightMeter.initialDayCounter`)}
                name={`${baseObjectName}.initialDayCounter`}
                defaultValue={
                  defaultValues[baseObjectName]?.initialDayCounter ?? ''
                }
                onInfoClicked={trackNewContractEvent(
                  'onboarding_info_icon_meter_indication',
                  '',
                  {
                    shop_or_onboarding_type: getShopOrOnboardingType(isShop),
                    energy_type: defaultValues.energyType,
                    customer_type: getCustomerCategory(
                      defaultValues.customerCategory
                    ),
                  }
                )}
              />
            </article>
          )}
      </RadioGroup>
    </article>
  );
};

export default NightMeter;
