import React from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Skeletons/Loader';
import Big from 'big.js';

import { useLiveStats } from 'data/liveStats';

import styles from './LiveStats.module.scss';

const LiveStats = ({ powerFactor }) => {
  const { t } = useTranslation(['ena', 'translation']);
  const { data, loading } = useLiveStats(powerFactor);

  return (
    <section>
      <h3 className={styles.header}>{t('ena:live-stats.energy-production')}</h3>
      <Loader show={loading} height={92}>
        {data && (
          <article>
            <p className={styles.paragraph}>
              <span>{t('ena:live-stats.day')} (kWh): </span>
              <span>{data.day ? new Big(data.day).toFixed(2) : '-'}</span>
            </p>
            <p className={styles.paragraph}>
              <span>{t('ena:live-stats.week')} (kWh): </span>
              <span>{data.week ? new Big(data.week).toFixed(2) : '-'}</span>
            </p>
            <p className={styles.paragraph}>
              <span>{t('ena:live-stats.month')} (kWh): </span>
              <span>{data.month ? new Big(data.month).toFixed(2) : '-'}</span>
            </p>
          </article>
        )}
      </Loader>
    </section>
  );
};

export default LiveStats;
