import React from 'react';

import BankIcons from 'components/BankIcons/BankIcons';
import AppDownloadButtons from 'components/AppDownload/AppDownloadButtons';
import pages from 'utils/product/enums/pages';

import styles from './HomeFooter.module.scss';

const HomeFooter = () => {
  return (
    <section className={styles.container}>
      <AppDownloadButtons
        width="68px"
        height="20px"
        containerClass={styles.stores}
        source={pages.home}
      />
      <BankIcons className={styles.bank} />
    </section>
  );
};

export default HomeFooter;
