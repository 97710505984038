import React from 'react';
import { useAuthUser } from 'utils/AuthUser';

import NonLoyaltyOffers from 'components/Offers/NonLoyaltyOffers';
import LoyaltyOffers from 'components/Offers/LoyaltyOffers';

const Offers = (props) => {
  const { isLoyaltyCustomer } = useAuthUser();

  return isLoyaltyCustomer === false ? (
    <NonLoyaltyOffers {...props} />
  ) : (
    <LoyaltyOffers {...props} />
  );
};

export default Offers;
