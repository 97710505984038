import { useTranslation } from 'react-i18next';

import energyTypeImage from 'assets/icons/3DEnergyType.png';
import programTypeImage from 'assets/icons/3DProgramType.png';
import electricityImage from 'assets/icons/3DElectricity.png';
import gasImage from 'assets/icons/3DGas.png';
import fileImage from 'assets/icons/3DFile.png';
import folderImage from 'assets/icons/3DFolder.png';
import switchImage from 'assets/icons/3DSwitch.png';

import shopEnergyTypes from 'utils/product/enums/shopEnergyTypes';
import programTypes from 'utils/product/enums/programTypes';
import accountCategories from 'utils/product/enums/accountCategories';

const useNewClientChoices = (nextRouteData) => {
  const { t } = useTranslation(['translation', 'newClient']);
  const baseName = 'newClient:';

  const questions = {
    energyType: {
      title: t(`${baseName}energyType.title`),
      cta: t(`translation:next`),
      items: [
        {
          name: 'energyType',
          choices: [
            {
              value: shopEnergyTypes.electricity,
              label: t(`${baseName}energyType.electricity`),
            },
            {
              value: shopEnergyTypes.gas,
              label: t(`${baseName}energyType.gas`),
            },
            {
              value: shopEnergyTypes.combined,
              label: t(`${baseName}energyType.combined`),
            },
          ],
          description: t(`${baseName}energyType.description`),
        },
      ],
      image: <img src={energyTypeImage} alt="energy type" />,
    },
    programType: {
      title: t(`${baseName}energyType.title`),
      cta: t(`translation:next`),
      items: [
        {
          name: 'programType',
          choices: [
            {
              value: programTypes.home,
              label: t(`${baseName}programType.home`),
            },
            {
              value: programTypes.business,
              label: t(`${baseName}programType.business`),
            },
          ],
          description: t(`${baseName}programType.description`),
        },
      ],
      image: <img src={programTypeImage} alt="program type" />,
    },
    shared: {
      title: t(`${baseName}energyType.title`),
      cta: t(`translation:next`),
      items: [
        {
          name: 'shared',
          choices: [
            { value: 'false', label: t(`${baseName}shared.false`) },
            { value: 'true', label: t(`${baseName}shared.true`) },
          ],
          description: t(`${baseName}shared.description`),
        },
      ],
      image: <img src={switchImage} alt="switch" />,
    },
    energyShop: {
      title: t(`${baseName}energyType.title`),
    },
    energyProgram: { title: '', cta: t(`${baseName}shop.activate`) },
    gasShop: {
      title: t(`${baseName}energyType.title`),
    },
    gasProgram: { title: '', cta: t(`${baseName}shop.activate`) },
    customerCategory: {
      title: '',
      cta: t(`translation:next`),
      items: [
        {
          name: 'customerCategory',
          choices: [
            {
              value: accountCategories.private,
              label: t(`${baseName}customerCategory.private`),
            },
            {
              value: accountCategories.freelancer,
              label: t(`${baseName}customerCategory.freelancer`),
            },
            {
              value: accountCategories.company,
              label: t(`${baseName}customerCategory.company`),
            },
          ],
          description: t(`${baseName}customerCategory.description`),
        },
      ],
      image: <img src={fileImage} alt="file" />,
    },
    businessCustomerCategory: {
      title: '',
      cta: t(`translation:next`),
      items: [
        {
          name: 'customerCategory',
          choices: [
            {
              value: accountCategories.freelancer,
              label: t(`${baseName}customerCategory.freelancer`),
            },
            {
              value: accountCategories.company,
              label: t(`${baseName}customerCategory.company`),
            },
          ],
          description: t(`${baseName}customerCategory.businessDescription`),
        },
      ],
      image: <img src={fileImage} alt="file" />,
    },
    electricity: {
      title: '',
      cta: t(`translation:next`),
      items: [
        {
          name: 'electricity',
          choices: [
            { value: 'true', label: t(`translation:yes`) },
            { value: 'false', label: t(`translation:no`) },
          ],
          description: t(`${baseName}electricity.description`),
        },
        {
          name: 'electricityRecentBill',
          choices: [
            { value: 'true', label: t(`translation:yes`) },
            { value: 'false', label: t(`translation:no`) },
          ],
          description: t(`${baseName}electricity.recent-description`),
        },
      ],
      image: <img src={electricityImage} alt="electricity" />,
    },
    gas: {
      title: '',
      cta: t(`translation:next`),
      items: [
        {
          name: 'gas',
          choices: [
            { value: 'true', label: t(`translation:yes`) },
            { value: 'false', label: t(`translation:no`) },
          ],
          description: t(`${baseName}gas.description`),
        },
        {
          name: 'gasRecentBill',
          choices: [
            { value: 'true', label: t(`translation:yes`) },
            { value: 'false', label: t(`translation:no`) },
          ],
          description: t(`${baseName}gas.recent-description`),
        },
      ],
      image: <img src={gasImage} alt="gas" />,
    },
    newGasSupply: {
      title: t(`${baseName}newGasSupply.title`),
      cta: t(`${baseName}newGasSupply.cta`),
      components: ['newGasSupply'],
    },
    electricitySupply: {
      title: null,
      cta: null,
      components: ['existingElectricitySupply'],
    },
    newGasSupplySuccess: {
      title: null,
      cta:
        nextRouteData.energyType === shopEnergyTypes.combined
          ? t(`${baseName}newGasSupply.success-cta`)
          : null,
      components: ['newGasSupplySuccess'],
    },
    electricityInfo: {
      title: t(`${baseName}files.description`),
      cta: t(`translation:next`),
      info: [
        t(`${baseName}files.id`),
        t(`${baseName}files.recent-electricity`),
      ],
      image: <img src={folderImage} alt="folder" />,
    },
    gasInfo: {
      title: t(`${baseName}files.description`),
      cta: t(`translation:next`),
      info: [t(`${baseName}files.id`), t(`${baseName}files.recent-gas`)],
      image: <img src={folderImage} alt="folder" />,
    },
    combinedInfo: {
      title: t(`${baseName}files.description`),
      cta: t(`translation:next`),
      info: [
        t(`${baseName}files.id`),
        t(`${baseName}files.recent-electricity`),
        t(`${baseName}files.recent-gas`),
      ],
      image: <img src={folderImage} alt="folder" />,
    },
    customerDetails: {
      title: t(`${baseName}customerDetails.title`),
      cta: t(`translation:next`),
      components: ['customerDetails', 'emergencyContact'],
    },
    companyDetails: {
      title: t(`${baseName}companyDetails.title`),
      cta: t(`translation:next`),
      components: ['companyDetails', 'legalRepresentative'],
    },
    primaryContact: {
      title: t(`${baseName}primaryContact.title`),
      cta: t(`translation:next`),
      components: ['primaryContact'],
    },
    meterDetails: {
      title: t(`${baseName}meterDetails.title`),
      combinedTitle: t(`${baseName}meterDetails.energy-title`),
      cta: t(`translation:next`),
      components: ['meterDetails', 'nightMeter', 'meterAddress'],
    },
    gasMeterDetails: {
      title: t(`${baseName}meterDetails.title`),
      combinedTitle: t(`${baseName}meterDetails.gas-title`),
      cta: t(`translation:next`),
      components: ['meterDetails', 'meterAddress'],
      componentProps: {
        isGasProgram: true,
      },
    },
    userCapacity: {
      title: t(`${baseName}userCapacity.title`),
      cta: t(`translation:next`),
      components: ['userCapacity', 'companyLegalDetails'],
      componentProps: {
        isGasProgram: true,
      },
    },
    eBill: {
      title: t(`${baseName}eBill.title`),
      combinedTitle: t(`${baseName}eBill.energy-title`),
      cta: t(`translation:next`),
      components: ['eBill', 'billAddress'],
      componentProps: {
        isGasProgram: false,
      },
    },
    gasEBill: {
      title: t(`${baseName}eBill.title`),
      combinedTitle: t(`${baseName}eBill.gas-title`),
      cta: t(`translation:next`),
      components: ['eBill', 'billAddress'],
      componentProps: {
        isGasProgram: true,
      },
    },
    contactPreferences: {
      title: t(`${baseName}contactPreferences.title`),
      cta: t(`translation:next`),
      components: ['contactPreferences', 'personalDataPreferences'],
    },
    identification: {
      title: t(`${baseName}identification.title`),
      cta: t(`translation:next`),
      components: ['identification'],
    },
    documents: {
      title: t(`${baseName}documents.title`),
      cta: t(`translation:next`),
      components: ['documents'],
    },
  };

  return questions;
};

export default useNewClientChoices;
