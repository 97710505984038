import axios from 'axios';
import { requestInterceptor, responseInterceptor } from './interceptors';
import { sentryMessage } from 'utils/product/logs';

export const fetcher = (url) => api.get(url).then((res) => res.data);

export const postFetcher = (url, params) => {
  return api.post(url, params).then((res) => res.data);
};

const api = axios.create({
  baseURL: process.env.REACT_APP_WAVE_URL,
  headers: {
    'x-tenant-id': process.env.REACT_APP_WAVE_TENANTID,
    'x-channel': 'WEB',
  },
});

const unauthorizedServices = [];

api.interceptors.request.use(
  function (config) {
    return requestInterceptor(config, unauthorizedServices, true);
  },
  function (error) {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const data = error.config?.data;
    const url = error.config?.url;

    const isUnauthorizedError = error?.response?.status === 401;
    const isNetworkError = error?.code === 'ERR_NETWORK';
    if (!isUnauthorizedError && !isNetworkError) {
      const code = error?.response?.data?.code ?? error?.code;
      const errorCodesToIgnore = [
        //skip logging already handled error codes
        'REDEMPTION_OPTION0005',
        'REDEMPTION_OPTION0003',
        //request aborted codes
        'ECONNABORTED',
      ];
      if (errorCodesToIgnore.indexOf(code) === -1) {
        sentryMessage(
          `Loyalty | Wave service ${
            error?.response?.status === 503 ? 'timeout' : 'failure'
          }`,
          {
            url: url,
            payload: data,
            status: error?.response?.status,
            errorCode: error?.response?.data?.code ?? error?.code,
            userMessage: error?.response?.data?.userMessage ?? error?.message,
          },
          [{ name: 'error-code', value: code }],
        );
      }
    }
    return responseInterceptor(error, api, true);
  },
);

export default api;
