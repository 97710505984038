import useData from 'data/useData';
import { useAuthUser } from 'utils/AuthUser';
import {
  requestStatuses as requestStatusEnums,
  requestTypes,
} from 'utils/product/enums/requests';
import { mutate } from 'swr';
import { transformHistoryCasesStatusesToRequests } from 'utils/product/requestUtils';
import { sortByDateFieldDescending } from 'utils/sortUtils';
import useGeneralConfig from 'data/useGeneralConfig';

export const mutateRequests = (accountId) => {
  mutate(`/account/requestHistory?accountId=${accountId}`);
};

export default function useRequests(language, skip = false) {
  const { accountId } = useAuthUser();
  const { data, error, loading, mutate } = useData(
    `/account/requestHistory?accountId=${accountId}`,
    {},
    skip,
  );

  const {
    data: {
      availableRequestStatuses: requestStatuses,
      availableRequestCategories: requestCategories,
      requestHistoryCategories,
    },
    loading: configLoading,
  } = useGeneralConfig(language) || {};

  const historyForCase = transformHistoryCasesStatusesToRequests(
    data?.HistoryForCase,
  );

  const requestHistory = (data?.History ?? [])?.concat(historyForCase ?? []);
  sortByDateFieldDescending(requestHistory, 'RequestDate');

  const requests = requestHistory?.map((request) => {
    const statusDisplayed =
      request.RequestStatus === requestStatusEnums.draft
        ? requestStatusEnums.pending
        : request.RequestStatus;

    const RequestType =
      request?.RequestType === requestTypes.electricityProgramChange
        ? requestTypes.electricityProgramChangeConfigSide
        : request?.RequestType;

    return {
      ...request,
      RequestType,
      _typeDescription: requestHistoryCategories?.[RequestType]?.message ?? '',
      _statusDescription: requestStatuses?.[statusDisplayed]?.message ?? '',
      _status: statusDisplayed,
    };
  });

  return {
    loading: loading || configLoading,
    error,
    requests,
    requestStatuses,
    requestCategories,
    requestHistoryCategories,
    mutate,
  };
}
