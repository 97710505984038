import React from 'react';

import TextArrow from 'components/TextArrow/TextArrow';

import styles from './SeeMoreWidget.module.scss';

const SeeMoreWidget = ({ Icon, content, containerClass, onClick }) => {
  return (
    <div className={`${styles.container} ${containerClass}`} onClick={onClick}>
      <TextArrow weight="mid-distance" Icon={Icon} content={content} />
    </div>
  );
};

export default SeeMoreWidget;
