import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput } from 'components/Inputs/TextInput';
import { TelephoneInput } from 'components/Inputs/TelephoneInput';
import Terms from 'components/OnboardingNewClient/Shared/Terms';

import styles from './LegalRepresentative.module.scss';

const LegalRepresentative = ({ defaultValues = {} }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation(['newClient']);

  return (
    <>
      <article className={styles.container}>
        <h3 className={styles.header}>
          {t(`newClient:legal-representative.header`)}
        </h3>

        <TextInput
          register={register}
          errors={errors}
          name="legalRepresentative.FirstName"
          label={t(`newClient:sharedDetails.firstName`)}
          defaultValue={defaultValues?.legalRepresentative?.FirstName}
        />
        <TextInput
          register={register}
          errors={errors}
          name="legalRepresentative.LastName"
          label={t(`newClient:sharedDetails.lastName`)}
          defaultValue={defaultValues?.legalRepresentative?.LastName}
        />
        <TextInput
          register={register}
          errors={errors}
          name="legalRepresentative.PassportNumber"
          label={t(`newClient:sharedDetails.identityNo`)}
          defaultValue={defaultValues?.legalRepresentative?.PassportNumber}
        />

        <TelephoneInput
          register={register}
          errors={errors}
          name="legalRepresentative.MobilePhone"
          label={t(`newClient:sharedDetails.phone`)}
          defaultValue={defaultValues?.legalRepresentative?.MobilePhone}
        />
      </article>
      <Terms />
    </>
  );
};

export default LegalRepresentative;
