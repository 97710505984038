import { useCmsContent } from 'data/useCmsContent';

const useStoreLocations = (language) => {
  const { data, loading, error } = useCmsContent('stores', language);

  const storesData = data?.data?.[0]?.content || {};

  return {
    loading: loading,
    data: storesData,
    error: error,
  };
};
export { useStoreLocations };
