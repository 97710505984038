import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import CookiesPolicy from 'components/Cookies/Cookies';
import AccountDeletion from 'components/Profile/AccountDeletion';
import Coupons from 'components/Profile/Coupons/Coupons';
import Contests from 'components/Profile/Contests';
import AuthorizedLoyalty from 'components/Loyalty/AuthorizedLoyalty';
import Notifications from 'pages/notifications/Notifications';

const ProfileMain = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="notifications" replace />} />
      <Route
        path="my-coupons"
        element={<AuthorizedLoyalty component={<Coupons />} />}
      />
      <Route path="my-contests" element={<Contests />} />
      <Route path="notifications" element={<Notifications />} />
      <Route path="cookies" element={<CookiesPolicy />} />
      <Route path="user-management" element={<AccountDeletion />} />
      <Route path="/*" element={<Navigate to="notifications" replace />} />
    </Routes>
  );
};

export default ProfileMain;
