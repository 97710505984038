import React from 'react';
import { useTranslation } from 'react-i18next';

import Widget from 'components/Widget/Widget';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import { useVibes } from 'data/useRewardSchemeAccounts';
import { useAuthUser } from 'utils/AuthUser';
import MyVibes from '../LoyaltyVibes/MyVibes';
import MyEnergyPoints from './MyEnergyPoints';
import LoyaltyVibesLogo from 'assets/icons/LoyaltyVibesLogo.png';
import SidebarLinkElement from 'components/SidebarElements/SidebarLinkElement';
import { trackHomeEvent } from 'utils/product/tracking/loyaltyEvents';

const LoyaltyCard = ({ page }) => {
  const { t, i18n } = useTranslation(['loyalty']);
  const {
    isLoyaltyCustomer,
    loading: userLoading,
    loyaltyLoading,
    loyaltyCustomerId,
  } = useAuthUser();
  const language = i18n.language;

  const { data: vibes, loading: vibesLoading } = useVibes(
    loyaltyCustomerId,
    language,
    !isLoyaltyCustomer
  );

  return (
    (userLoading || loyaltyLoading || isLoyaltyCustomer) && (
      <>
        <MyEnergyPoints page={page} />
        <WidgetSkeleton
          heightInPixels={150}
          show={vibesLoading || userLoading || loyaltyLoading}
          distance="xs"
        >
          {vibes && (
            <Widget size="m" distance="xs">
              <MyVibes vibes={vibes} />
            </Widget>
          )}
        </WidgetSkeleton>
        <WidgetSkeleton
          heightInPixels={56}
          show={userLoading || loyaltyLoading}
          hideTitle={true}
        >
          <Widget>
            <SidebarLinkElement
              target={`/loyalty/my-vibes`}
              Icon={<img src={LoyaltyVibesLogo} width={24} alt="vibes" />}
              content={t(`loyalty:vibes-profile.cta`)}
              onClick={() => {
                if (page === 'home')
                  trackHomeEvent('vibes_view_more_loyalty_user_selected');
              }}
            />
          </Widget>
        </WidgetSkeleton>
      </>
    )
  );
};

export default LoyaltyCard;
