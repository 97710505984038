import React from 'react';
import { useTranslation } from 'react-i18next';

import HomeBillWidgetContent from 'components/HomeBillWidget/HomeBillWidgetContent';
import ThreeColumnsLayout from 'layouts/ThreeColumnsLayout';

const BillsDetailedList = () => {
  const { t } = useTranslation(['homepage']);

  return (
    <ThreeColumnsLayout
      head={{
        title: t('homepage:my-bills.title'),
        metaTags: [{ name: 'title', content: t('homepage:my-bills.title') }],
      }}
      main={<HomeBillWidgetContent detailed={true} />}
    />
  );
};
export default BillsDetailedList;
