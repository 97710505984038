import { useCmsContent } from './useCmsContent';
import { getBaseProperties } from 'utils/product/offerUtils';

const useContest = (slug, language) => {
  const { data, loading, error } = useCmsContent(
    slug,
    language,
    false,
    '[content.slug.el-GR_eq]'
  );

  const result =
    loading || !data?.data?.length || !data?.included
      ? null
      : getBaseProperties(data?.data[0], data?.included);

  return {
    loading,
    error,
    data: result,
  };
};
export { useContest };
