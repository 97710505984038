import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import useDialog from 'hooks/useDialog';

import paymentTypes from 'utils/product/enums/paymentTypes';
import { formatDate } from 'utils/dateUtils';
import { formatMoneyWithCurrency } from 'utils/moneyUtils';
import { dateFormats } from 'utils/product/config';

import MeterLabelWrapper from 'components/Labels/MeterLabelWrapper';
import PaymentWidget from 'components/Payment/PaymentWidget';
import { PrimaryButton } from 'components/Buttons/Buttons';
import InstallmentStatusIcon from 'components/BillsSettlement/InstallmentStatusIcon';
import { trackBillSettlementEvent } from 'utils/product/tracking/events';
import { getTrackingEnergyType } from 'utils/product/tracking/trackingLabels';

import styles from './SettlementInstallments.module.scss';

const SettlementInstallments = ({ meter, installments, onPaymentSuccess }) => {
  const { t } = useTranslation(['translation', 'bills']);

  const [installment, setInstallment] = useState(null);

  const paymentDialog = useDialog();

  const payInstallment = (_installment) => {
    setInstallment(_installment);
    paymentDialog.show();

    trackBillSettlementEvent(
      'bill_settlement_payment_dose',
      getTrackingEnergyType(meter?.Commodity),
    );
  };

  const onInstallmentPaid = (_installment) => {
    const installmentCopy = cloneDeep(_installment);
    setInstallment(null);
    onPaymentSuccess(meter.Id, installmentCopy);
  };

  return (
    <section className={styles.container}>
      {meter && (
        <MeterLabelWrapper
          type={meter.Commodity}
          friendlyName={meter.FriendlyName}
          meterNo={meter.MeterNo}
          meter={meter}
          labelClass={styles.meterLabel}
        />
      )}
      {installments && (
        <>
          {installments.map((item, i) => (
            <Fragment key={item.InstallmentDueDate}>
              <article className={styles['settlement-item']}>
                <div className={styles.row}>
                  <InstallmentStatusIcon installment={item} />
                  <div className={styles.info}>
                    <label>
                      {t(`bills:settlements.installment-number.${i}`)}
                    </label>
                    <p>
                      {t(`bills:settlements.expiration-date`)}{' '}
                      {formatDate(
                        item.InstallmentDueDate,
                        dateFormats.dateHalfYear,
                      )}
                    </p>
                  </div>
                  <div className={styles.amount}>
                    {formatMoneyWithCurrency(item.InstallmentAmmount)}
                  </div>
                </div>
                {item.IsPayable === true && (
                  <PrimaryButton
                    className={styles.button}
                    onClick={() => payInstallment(item)}
                  >
                    {t(`translation:payment`)}
                  </PrimaryButton>
                )}
              </article>
            </Fragment>
          ))}
          <p className={styles.warning}>
            {t(`bills:settlements.expiration-info`)}
          </p>
          <p className={styles.warningFaded}>
            {t(`bills:settlements.payment-info`)}
          </p>
        </>
      )}
      <PaymentWidget
        open={paymentDialog.isOpen}
        closeDialog={paymentDialog.close}
        openDialog={paymentDialog.show}
        paymentAmount={installment?.InstallmentAmmount}
        paymentCode={meter?.LastInvoice?.paymentCode}
        paymentType={paymentTypes.settlement}
        meterId={meter?.Id}
        meter={meter}
        installment={installment}
        commodity={meter?.Commodity}
        paymentDueDate={installment?.InstallmentDueDate}
        onPaymentSuccess={({ installment: _installment }) =>
          onInstallmentPaid(_installment)
        }
      />
    </section>
  );
};
export default SettlementInstallments;
