import useSWR from 'swr';
import { fetcher } from 'api/wave';
import endpoints from 'utils/product/enums/endpoints';

const useContestParticipations = (userId, skip = false) => {
  const url = `${endpoints.loyalty.participations}?customerId=${userId}`;
  const {
    data,
    error,
    mutate,
    isLoading: loading,
  } = useSWR(userId && !skip ? url : null, fetcher);

  if (skip) {
    return {
      loading: false,
      error: null,
      data: [],
      mutate,
    };
  }

  return {
    loading,
    error,
    data,
    mutate,
  };
};

const useContestParticipation = (userId, contestId, skip = false) => {
  const shouldSkip = skip || !contestId;
  const { data, error, mutate, loading } = useContestParticipations(
    userId,
    shouldSkip,
  );

  if (shouldSkip) {
    return {
      loading: false,
      error: null,
      data: [],
      mutate,
    };
  }

  const participationsCount =
    data?.find((participation) => participation.contest === contestId)
      ?.participationsCount || 0;

  return {
    loading,
    error,
    data,
    mutate,
    participationsCount,
  };
};

export { useContestParticipations, useContestParticipation };
