import React from 'react';

import ThreeColumnsLayout from 'layouts/ThreeColumnsLayout';
import ProfileMain from './ProfileMain';
import ProfileRightSidebar from './ProfileRightSidebar';
import LeftSidebar from './LeftSidebar';

const Profile = () => {
  return (
    <ThreeColumnsLayout
      left={<LeftSidebar />}
      main={<ProfileMain />}
      right={<ProfileRightSidebar />}
    />
  );
};

export default Profile;
