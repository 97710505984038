import React from 'react';

import Widget from 'components/Widget/Widget';
import RequestBody from './RequestBody';
import MeterLabelWrapper from 'components/Labels/MeterLabelWrapper';
import commodities from 'utils/product/enums/commodities';

import styles from './RequestsList.module.scss';

const RequestsList = ({ requests, limit }) => {
  const _requests = limit ? requests?.slice(0, limit) : requests;
  return (
    <>
      {_requests?.map((request) => {
        const { _typeDescription, _statusDescription, _status } = request || {};

        return (
          <Widget
            key={request.RequestId}
            distance="xs"
            widgetClass={
              request.MeterNo
                ? styles['meter-requests-widget']
                : styles['requests-widget']
            }
          >
            <section className={styles.container}>
              {request.MeterNo && (
                <MeterLabelWrapper
                  size="s"
                  type={
                    request.Commodity === commodities.gas
                      ? commodities.gas
                      : commodities.energy
                  }
                  friendlyName={
                    request.meter
                      ? request.meter.FriendlyName
                      : request.FriendlyName
                  }
                  meterNo={
                    request.meter ? request.meter.MeterNo : request.MeterNo
                  }
                  meter={request.meter}
                />
              )}
              <RequestBody
                typeDescription={_typeDescription}
                statusDescription={_statusDescription}
                status={_status}
              />
            </section>
          </Widget>
        );
      })}
    </>
  );
};

export default RequestsList;
