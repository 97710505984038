import React from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { StandardInput } from './Inputs';
import IconPopover from 'components/Popover/IconPopover';
import { ReactComponent as InfoIconFilled } from 'assets/icons/InfoCircleIcon.svg';
import { numberRegexPattern } from 'utils/product/config';

import styles from './SupplyNumberInput.module.scss';

const MeterIndication = ({
  register,
  errors,
  label,
  name = 'supplyNumber',
  adornmentPosition,
  required = false,
  supplyNumberType,
  showInfo = true,
  isNight = false,
  onInfoClicked,
  ...rest
}) => {
  const { t } = useTranslation(['inputs']);
  const baseName = 'inputs:errors';

  const { ref, ...registerRest } =
    register(name, {
      required: { value: required, message: t(`${baseName}.required`) },
      pattern: {
        value: numberRegexPattern,
        message: t(`inputs:errors.onlyDigits`),
      },
    }) || {};

  return (
    <>
      <StandardInput
        inputRef={ref}
        label={label}
        type="text"
        errorMessage={get(errors, `${name}.message`, null)}
        error={get(errors, `${name}`, null) !== null}
        endAdornment={
          showInfo ? (
            <IconPopover
              id={`energyProgram-initial${
                isNight ? 'Night' : 'Day'
              }Counter-popover`}
              content={t(
                `newClient:nightMeter.initial${
                  isNight ? 'Night' : 'Day'
                }Counter-info`
              )}
              icon={<InfoIconFilled />}
              popoverClass={styles.popover}
              placement="bottom-end"
              buttonClass={styles.infoIcon}
              clickHandler={() => {
                if (typeof onInfoClicked === 'function') onInfoClicked();
              }}
            />
          ) : null
        }
        {...rest}
        {...registerRest}
      />
    </>
  );
};

export { MeterIndication };
