import useData from 'data/useData';

export default function useContactsForMeter(meterId) {
  const { data, error, loading, mutate } = useData(
    `/meter/contactsFromMeter?meterId=${meterId}`,
    {},
    !meterId
  );

  return {
    data,
    loading,
    error,
    mutate,
  };
}
