import React, { useEffect } from 'react';
import Big from 'big.js';
import cn from 'classnames';

import { ReactComponent as LoyaltyDebitIcon } from 'assets/icons/LoyaltyDebitIcon.svg';
import Slider from 'components/Inputs/Slider';
import { formatMoney } from 'utils/moneyUtils';

import styles from './ParticipationSlider.module.scss';

const ParticipationSlider = ({
  participationPoints,
  loyaltyPoints,
  remainingParticipations,
  selectedPoints,
  onChange = () => {},
  sliderSteps = 5,
}) => {
  useEffect(() => {
    typeof onChange === 'function' && onChange(participationPoints);
  }, [onChange, participationPoints]);

  const maxValue = new Big(participationPoints).times(sliderSteps).toNumber();
  const maxSliderStep = new Big(maxValue).div(participationPoints).toNumber();

  const stepMarks = [1, 2, 3, 4, 5].filter((number) => number <= maxSliderStep);

  const marks = stepMarks.map((index) => {
    const _mark = index * participationPoints;
    return {
      value: _mark,
      number: index,
    };
  });

  const costOfAvailableParticipations =
    remainingParticipations * participationPoints;

  const maxSliderValue =
    costOfAvailableParticipations &&
    costOfAvailableParticipations < loyaltyPoints
      ? costOfAvailableParticipations
      : loyaltyPoints;

  return (
    <article className={styles['slider-container']}>
      <section className={styles['slider-points']}>
        <LoyaltyDebitIcon />
        {marks.map((mark) => (
          <span
            className={cn({
              [styles.sliderDisabledArea]: mark.value > maxSliderValue,
              [styles.sliderSelectedArea]: mark.value === selectedPoints,
            })}
            key={mark.value}
            style={{
              left: `${new Big(100).div(maxSliderStep).times(mark.number)}%`,
            }}
          >
            {formatMoney(mark.value, false)}
          </span>
        ))}
      </section>
      <Slider
        defaultValue={participationPoints}
        maxValue={maxSliderValue}
        step={null}
        marks={marks}
        min={0}
        max={maxValue}
        onChange={onChange}
        preventZero={true}
        color="secondary"
        classes={{ markLabel: styles.markLabel }}
      />
    </article>
  );
};

export default ParticipationSlider;
