import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomButton } from 'components/Offers/OfferElements';
import { RedeemPointsLabel } from 'components/Loyalty/LoyaltyLabels';
import useChainingAnimation from 'hooks/useChainingAnimation';
import { getInfoBoxContainerClass } from 'utils/product/contestsUtils';

import styles from './ContestInfoBoxWidget.module.scss';

const InfoBox = ({
  header,
  headerClass = '',
  message,
  messageClass = '',
  children,
}) => (
  <div className={styles.content}>
    <h3 className={headerClass}>{header}</h3>
    {children}
    {message && <p className={messageClass}>{message}</p>}
  </div>
);

const ContestInfoBoxWidget = ({
  contestStatus,
  participationsCount,
  isPrizeUnclaimed,
  claimUntil,
  redeemPoints,
  participationsPerParticipantLimit,
  prizeDescription,
  onParticipationInvoked = () => {},
  onCouponButtonClick = () => {},
}) => {
  const { t } = useTranslation(['translation', 'offers']);

  const {
    expiredWithoutParticipation,
    hasReachedParticipationLimit,
    canMakeMoreParticipations,
    expiredContestPendingDraw,
    userIsWinner,
    isWinnerWithServiceOrProductPrize,
    isWinnerWithCouponPrize,
    isRejectedWinner,
    isLoser,
  } = contestStatus || {};

  const containerClass = getInfoBoxContainerClass(
    canMakeMoreParticipations,
    userIsWinner,
  );

  const [target, setTarget] = useState(null);

  useEffect(() => {
    if (!canMakeMoreParticipations) return;

    setTarget(document?.querySelector(`.${styles[containerClass]}`));
  }, [containerClass, canMakeMoreParticipations]);

  useChainingAnimation(target, [
    styles['makeMoreEntries-animation-in'],
    styles['makeMoreEntries-animation-out'],
  ]);

  return (
    <div className={styles[containerClass]}>
      {expiredWithoutParticipation && (
        <InfoBox
          header={t(
            'offers:contest-infoBox.expired-without-participation-header',
          )}
          message={t(
            'offers:contest-infoBox.expired-without-participation-subheader',
          )}
          headerClass={styles.secondaryHeader}
        />
      )}
      {hasReachedParticipationLimit && (
        <InfoBox
          header={t('offers:contest-infoBox.entries-sum-header', {
            count: participationsCount,
          })}
          message={t('offers:contest-infoBox.entries-limit', {
            count: participationsPerParticipantLimit,
          })}
          messageClass={styles.disclaimerText}
        />
      )}
      {canMakeMoreParticipations && (
        <>
          <InfoBox
            header={t('offers:contest-infoBox.get-more-entries-header', {
              count: participationsCount,
            })}
            message={
              !!participationsPerParticipantLimit &&
              t('offers:contest-infoBox.entries-limit', {
                count: participationsPerParticipantLimit,
              })
            }
            messageClass={styles.disclaimerText}
          >
            <div className={styles['points-container']}>
              <p>{t(`offers:acquisition-flow.redeem-at-least-one`)}</p>
              <RedeemPointsLabel
                points={redeemPoints}
                labelClass={styles.points}
              />
            </div>
          </InfoBox>
          <div className={styles.ctaContainer}>
            <CustomButton
              text={t('offers:contest-infoBox.get-more-entries-cta')}
              clickHandler={onParticipationInvoked}
            />
          </div>
        </>
      )}
      {expiredContestPendingDraw && (
        <InfoBox
          header={t('offers:contest-infoBox.entries-sum-header', {
            count: participationsCount,
          })}
          message={t('offers:contest-infoBox.pending-draw-subheader')}
        />
      )}
      {isWinnerWithServiceOrProductPrize && (
        <InfoBox
          header={t('offers:contest-infoBox.winner-header')}
          message={t('offers:contest-infoBox.winner-contact-disclaimer')}
          messageClass={styles.disclaimer}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: prizeDescription,
            }}
          />
        </InfoBox>
      )}
      {isWinnerWithCouponPrize && (
        <>
          <InfoBox
            header={t('offers:contest-infoBox.winner-header')}
            message={
              isPrizeUnclaimed &&
              claimUntil &&
              t('offers:contest-infoBox.voucher-claim-date', {
                date: claimUntil,
              })
            }
            messageClass={styles.disclaimer}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: prizeDescription,
              }}
            />
          </InfoBox>
          <div className={styles.ctaContainer}>
            <CustomButton
              text={t(
                `offers:contest-infoBox.${
                  isPrizeUnclaimed ? 'voucher-claim-cta' : 'voucher-goto-cta'
                }`,
              )}
              clickHandler={onCouponButtonClick}
            />
          </div>
        </>
      )}
      {isRejectedWinner && (
        <InfoBox
          header={t('offers:contest-infoBox.rejected-winner-header')}
          message={
            claimUntil &&
            t('offers:contest-infoBox.rejected-winner-subheader', {
              date: claimUntil,
            })
          }
          headerClass={styles.secondaryHeader}
        />
      )}
      {isLoser && (
        <InfoBox
          header={t('offers:contest-infoBox.loose-header')}
          message={t('offers:contest-infoBox.loose-subheader')}
          headerClass={styles.secondaryHeader}
        />
      )}
    </div>
  );
};

export default ContestInfoBoxWidget;
