import useSWR from 'swr';
import { postFetcher } from 'api/api';
import { transformSettlement } from 'utils/product/settlementUtils';

export default function useSettlement(accountId, meterId) {
  const {
    data,
    error,
    mutate,
    isLoading: loading,
  } = useSWR(
    [accountId && meterId ? '/account/check' : null, meterId, accountId],
    ([url, _meterId, _accountId]) =>
      postFetcher(url, {
        AccountId: _accountId,
        MeterId: _meterId,
      })
  );

  const settlement = transformSettlement(meterId, data);
  return {
    loading,
    error,
    data,
    settlement,
    installments: settlement?.Installments,
    mutate,
  };
}
