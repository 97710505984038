import React from 'react';

import styles from './ProgressBar.module.scss';

const ProgressBar = ({ values = [] }) => {
  const _values = values
    .reduce((previousValue, currentValue) => {
      const newItem = {
        ...currentValue,
        combinedPercentage:
          currentValue.percentage +
          (previousValue.length > 0
            ? previousValue[previousValue.length - 1].combinedPercentage
            : 0),
      };
      previousValue.push(newItem);
      return previousValue;
    }, [])
    .reverse();

  return (
    <div className={styles.bar}>
      {_values.map((x) => (
        <div
          style={{
            backgroundColor: x.color,
            width: `${x.combinedPercentage}%`,
          }}
          className={styles.inner}
          key={x.color}
        ></div>
      ))}
    </div>
  );
};
export default ProgressBar;
