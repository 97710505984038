import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  invoiceTypes,
  invoiceTypeIDocs,
} from 'utils/product/enums/invoiceTypes';
import styles from './InvoiceTypeLabel.module.scss';

const InvoiceTypeLabel = ({ invoiceType, css }) => {
  const { t } = useTranslation(['homepage']);
  const invoiceLabel = (() => {
    switch (invoiceType) {
      case invoiceTypes.clearing:
      case invoiceTypeIDocs.clearing:
        return t('homepage:clearing');
      case invoiceTypes.against:
      case invoiceTypeIDocs.against:
        return t('homepage:against');
      case invoiceTypeIDocs.fixing:
        return t('homepage:against');
      case invoiceTypes.final:
      case invoiceTypeIDocs.final:
        return t('homepage:final');
      case invoiceTypes.againstClient:
      case invoiceTypeIDocs.againstClient:
        return t('homepage:againstClient');
      case invoiceTypes.exceptional:
      case invoiceTypeIDocs.exceptional:
        return t('homepage:exceptional');
      case invoiceTypes.cancelling:
      case invoiceTypeIDocs.cancelling:
        return t('homepage:cancelling');

      default:
        return '';
    }
  })();

  return <p className={css ?? styles['text-default']}>{invoiceLabel}</p>;
};

export default InvoiceTypeLabel;
