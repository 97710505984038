import React from 'react';

import { useAuthUser } from 'utils/AuthUser';
import ContactButton from './ContactButton';
import BuyProgramButton from './BuyProgramButton';
import ActivateProgramButton from './ActivateProgramButton';
import ChangeProgramButton from './ChangeProgramButton';

import styles from './ProgramActions.module.scss';

const ProgramActions = ({ program, generousGuarantee }) => {
  const { user } = useAuthUser();

  const {
    isEna = false,
    title,
    energyType,
    programType,
    leadSrc,
    packageId,
    sections: { actions } = {},
  } = program || {};

  return (
    <section className={styles.buttonsContainer}>
      {actions.interested === true && (
        <ContactButton
          isEna={isEna}
          title={title}
          leadSrc={leadSrc}
          user={user}
          packageId={packageId}
        />
      )}
      {!isEna && actions.buy === true && (
        <BuyProgramButton energyType={energyType} title={title} />
      )}
      {isEna && actions.buy === true && <ActivateProgramButton title={title} />}
      {actions.change === true && (
        <ChangeProgramButton
          program={program}
          title={title}
          energyType={energyType}
          programType={programType}
          generousGuarantee={generousGuarantee}
        />
      )}
    </section>
  );
};
export default ProgramActions;
