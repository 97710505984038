import useData from 'data/useData';
import { useAuthUser } from 'utils/AuthUser';
import { mergeOwnMeasurements } from 'utils/product/ownMeasurementUtils';

export default function useAccountOwnMeasurements(meters, skip = false) {
  const { accountId } = useAuthUser();
  const { data, error, loading } = useData(
    skip ? null : `/account/ownMeasurements?accountId=${accountId}`,
  );
  const results = data?.MeterOwnMeasurements
    ? mergeOwnMeasurements(data, meters)
    : [];

  return {
    loading,
    error,
    data,
    ownMeasurements: results,
  };
}
