import React from 'react';
import { useTranslation } from 'react-i18next';

import WidgetHeader from 'components/WidgetHeader/WidgetHeader';

import { ReactComponent as CreditCardIcon } from 'assets/icons/CreditCardIcon.svg';
import { ReactComponent as CookiesIcon } from 'assets/icons/Cookies.svg';
import { ReactComponent as TermsIcon } from 'assets/icons/TermsIcon.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/LogoutIcon.svg';
import { ReactComponent as UserIcon } from 'assets/icons/User.svg';
import { ReactComponent as UserDataIcon } from 'assets/icons/UserData.svg';
import { ReactComponent as LockIcon } from 'assets/icons/LockIcon.svg';
import { ReactComponent as BellIcon } from 'assets/icons/Bell.svg';
import { ReactComponent as CouponIcon } from 'assets/icons/CouponIcon.svg';
import { ReactComponent as ContestIcon } from 'assets/icons/ContestIcon.svg';
import WidgetLine from 'components/WidgetLine/WidgetLine';

import SidebarLinkElement from 'components/SidebarElements/SidebarLinkElement';
import SidebarButtonElement from 'components/SidebarElements/SidebarButtonElement';
import SidebarLinkMenu from 'components/SidebarElements/SidebarLinkMenu';
import LogoutDialog from 'components/LogoutDialog/LogoutDialog';
import {
  ButtonDropdown,
  ButtonDropdownElement,
} from 'components/Buttons/ButtonDropdown';
import useDialog from 'hooks/useDialog';
import { trackProfileEvent } from 'utils/product/tracking/events';
import ChangePasswordDialog from 'components/Profile/ChangePasswordDialog';
import { useAuthUser } from 'utils/AuthUser';
import useNotificationsStatus from 'data/useNotificationsStatus';
import useGeneralConfig from 'data/useGeneralConfig';

import styles from './ProfileRightSidebar.module.scss';

const ProfileRightSidebar = () => {
  const { t, i18n } = useTranslation(['profile']);
  const baseName = 'profile:profileRightSidebar';
  const logoutDialog = useDialog();
  const passwordDialog = useDialog();
  const { isLoyaltyCustomer, loyaltyCustomerId, accountId } = useAuthUser();
  const { hasUnviewedNotifications } = useNotificationsStatus(
    loyaltyCustomerId,
    accountId,
  );

  const {
    data: { generalTermsUrl, paymentTermsUrl, gdprTermsUrl },
  } = useGeneralConfig(i18n.language) || {};

  return (
    <>
      <SidebarLinkMenu
        distance="s"
        title={<WidgetHeader title={t(`${baseName}.activity`)} />}
      >
        <SidebarLinkElement
          target={`notifications`}
          Icon={<BellIcon width="24" height="24" alt="notifications" />}
          content={t(`${baseName}.notifications`)}
          onClick={() => trackProfileEvent('profile_notifications')}
          iconRight={
            hasUnviewedNotifications && <div className={styles.dot}></div>
          }
        />
      </SidebarLinkMenu>
      <SidebarLinkMenu distance="s">
        {isLoyaltyCustomer && (
          <>
            <SidebarLinkElement
              target={`my-coupons`}
              Icon={<CouponIcon />}
              content={t(`${baseName}.my-coupons`)}
              onClick={() => trackProfileEvent('profile_my_coupons_selected')}
            />
            <WidgetLine />
          </>
        )}
        <SidebarLinkElement
          target={`my-contests`}
          Icon={<ContestIcon />}
          content={t(`${baseName}.my-contests`)}
          onClick={() => trackProfileEvent('profile_my_contests_selected')}
          disabled
        />
      </SidebarLinkMenu>
      <SidebarLinkMenu
        distance="xs"
        title={<WidgetHeader title={t(`${baseName}.settings`)} />}
      >
        <SidebarButtonElement
          Icon={<LockIcon />}
          content={t(`profile:personalInfo.change-password`)}
          onClick={() => {
            passwordDialog.show();
          }}
        />
      </SidebarLinkMenu>
      <SidebarLinkMenu distance="xs" widgetClass={styles.widget}>
        <SidebarLinkElement
          target={generalTermsUrl}
          Icon={<TermsIcon />}
          content={t(`${baseName}.terms`)}
          onClick={() => trackProfileEvent('profile_consent')}
        />
        <WidgetLine />
        <SidebarLinkElement
          target={gdprTermsUrl}
          Icon={<TermsIcon />}
          content={t(`${baseName}.gdprTerms`)}
          onClick={() => trackProfileEvent('profile_consent')}
        />
        <WidgetLine />
        <SidebarLinkElement
          target={paymentTermsUrl}
          Icon={<CreditCardIcon />}
          content={t(`${baseName}.payment-terms`)}
          onClick={() => trackProfileEvent('profile_payment_terrms')}
        />
        <WidgetLine />
        <ButtonDropdown
          label={t(`${baseName}.user-data`)}
          icon={<UserDataIcon />}
          buttonClass={styles['menu-button']}
        >
          <ButtonDropdownElement
            target={`cookies`}
            content={t(`${baseName}.cookies`)}
            icon={<CookiesIcon />}
            clickHandler={() => trackProfileEvent('profile_cookies')}
          />
          <WidgetLine />
          <ButtonDropdownElement
            target={`user-management`}
            content={t(`${baseName}.user-management`)}
            icon={<UserIcon />}
            clickHandler={() => {
              trackProfileEvent('profile_manage_user');
            }}
          />
        </ButtonDropdown>
      </SidebarLinkMenu>
      <SidebarLinkMenu>
        <SidebarButtonElement
          Icon={<LogoutIcon />}
          content={t(`${baseName}.logout`)}
          onClick={() => {
            logoutDialog.show();
            trackProfileEvent('profile_logout');
          }}
        />
      </SidebarLinkMenu>
      <LogoutDialog
        open={logoutDialog.isOpen}
        closeDialog={logoutDialog.close}
      />
      <ChangePasswordDialog
        open={passwordDialog.isOpen}
        closeDialog={passwordDialog.close}
      />
    </>
  );
};

export default ProfileRightSidebar;
