import { useState } from 'react';

export default function useCounter(defaultNumber = 0, maxValue = Infinity) {
  const [number, setNumber] = useState(defaultNumber);

  const increaseNumber = (step = 1) => {
    setNumber(number + step);
  };

  const checkIfNumberIsMax = () => number === maxValue;

  return { number, increaseNumber, checkIfNumberIsMax, setNumber };
}
