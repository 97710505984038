import axios from 'axios';
import useSWR from 'swr';
import { formatDate, addDays } from 'utils/dateUtils';
import { dateFormats } from 'utils/product/config';

const baseUrl = process.env.REACT_APP_LIVESTATS;

const api = axios.create({
  baseURL: baseUrl,
});
export const fetcher = (url) => api.get(url).then((res) => res.data);

const useLiveStats = (powerFactor) => {
  const {
    data,
    error,
    isLoading: loading,
  } = useSWR(
    powerFactor
      ? `/index.php?route=heron/normalized_energy&power=${powerFactor}`
      : null,
    fetcher
  );

  return {
    loading,
    error: error || data?.status !== 'success',
    data: data?.status === 'success' ? data.data : {},
  };
};

const useLiveStatsGraph = (powerFactor, from, to) => {
  const fromDate = from ? formatDate(from, dateFormats.dateDashReverse) : null;
  const toDate = formatDate(addDays(to, 1), dateFormats.dateDashReverse);
  const {
    data,
    error,
    isLoading: loading,
  } = useSWR(
    powerFactor && fromDate && toDate
      ? `/index.php?route=heron/radiation&power=${powerFactor}&start=${fromDate}&end=${toDate}`
      : null,
    fetcher
  );

  const hasResults =
    data?.status === 'success' && data.data && data.data.length > 0;
  return {
    loading,
    error: error || data?.status !== 'success',
    data: hasResults
      ? data.data
      : [
          { time: fromDate, value: 0 },
          { time: toDate, value: 0 },
        ],
    maxValue: hasResults ? undefined : 0.25,
  };
};

export { useLiveStats, useLiveStatsGraph };
