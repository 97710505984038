import React from 'react';

import consumptionStates from 'utils/product/enums/consumptionStates';
import {
  isNewMeter,
  getConsumptionState,
} from 'utils/product/consumptionUtils';
import StateClearing from 'components/Consumptions/HomeStates/StateClearing';
import StateClearingGas from 'components/Consumptions/HomeStates/StateClearingGas';
import StatePrediction from 'components/Consumptions/HomeStates/StatePrediction';
import StateCountAndWin from 'components/Consumptions/HomeStates/StateCountAndWin';
import StateCountSuccess from 'components/Consumptions/HomeStates/StateCountSuccess';
import StateMediumVoltage from 'components/Consumptions/HomeStates/StateMediumVoltage';
import ConsumptionWidget from 'components/Consumptions/Home/ConsumptionWidget';
import { useAuthUser } from 'utils/AuthUser';

const ConsumptionsStates = ({ meter, refreshData }) => {
  const meterIsNew = isNewMeter(meter);
  const { user } = useAuthUser();
  const state = getConsumptionState(meter);

  if (state === null) return null;
  return (
    <ConsumptionWidget meter={meter}>
      {state === consumptionStates.register && (
        <StateCountAndWin
          meter={meter}
          refreshData={refreshData}
          meterIsNew={meterIsNew}
        />
      )}
      {state === consumptionStates.registered && (
        <StateCountSuccess meter={meter} />
      )}
      {state === consumptionStates.clearing && (
        <StateClearing type={state} meter={meter} />
      )}
      {state === consumptionStates.prediction && (
        <StatePrediction type={state} meter={meter} />
      )}
      {state === consumptionStates.clearingGas && (
        <StateClearingGas type={state} meter={meter} />
      )}
      {user?.HasMVMeter && state === consumptionStates.mediumVoltage && (
        <StateMediumVoltage meter={meter} />
      )}
    </ConsumptionWidget>
  );
};

export default ConsumptionsStates;
