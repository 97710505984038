import React from 'react';
import { useTranslation } from 'react-i18next';

import { getLVConsumptionInfo } from 'utils/product/consumptionUtils';
import { DefaultLayout } from 'components/Consumptions/HomeStates/ConsumptionLayouts';
import { ConsumptionAnalysisButton } from 'components/Consumptions/Home/ConsumptionInfoElements';
import {
  ConsumptionIndications,
  ConsumptionMessage,
} from 'components/Consumptions/Home/ConsumptionInfoElements';

const StateClearingGas = ({ meter }) => {
  const { t } = useTranslation(['consumptions']);

  const consumptionInfo = getLVConsumptionInfo(meter);

  return (
    <DefaultLayout>
      {{
        message: (
          <ConsumptionMessage text={t(`consumptions:clearing-consumption`)} />
        ),
        info: <ConsumptionIndications {...consumptionInfo} />,
        secondaryButton: <ConsumptionAnalysisButton meterNo={meter.MeterNo} />,
      }}
    </DefaultLayout>
  );
};

export default StateClearingGas;
