import React from 'react';

import Widget from 'components/Widget/Widget';
import IconText from 'components/IconText/IconText';
import { ReactComponent as RightArrow } from 'assets/icons/ArrowRightIcon.svg';
import WidgetLine from 'components/WidgetLine/WidgetLine';
import HeronNotificationsIcon from 'pages/notifications/HeronNotificationsIcon';

import styles from './NotificationWidget.module.scss';

const NotificationWidget = ({ notification, handleClick }) => {
  return (
    <Widget
      containerClass={styles.widget}
      onClick={() => handleClick(notification)}
    >
      <IconText
        gap="s"
        Icon={
          notification?.Image ? (
            <img
              src={notification?.Image}
              width="32px"
              height="32px"
              alt="notification"
            />
          ) : (
            <HeronNotificationsIcon
              iconType={notification.Type}
              width="32"
              height="32"
              alt="notification"
            />
          )
        }
        iconRight={<RightArrow />}
        containerClass={styles.container}
        content={
          <>
            <p className={styles.title}>{notification.Title}</p>
            <div className={styles.textElement}>
              <p>{notification?.Message}</p>
            </div>
          </>
        }
      />
      <WidgetLine distance="" />
    </Widget>
  );
};

export default NotificationWidget;
