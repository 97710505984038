import { useCmsContent } from './useCmsContent';
import { cmsTransformation } from 'utils/product/shopCmsTransformation';

const useShop = (language) => {
  const { data, loading, error } = useCmsContent(
    'shop_programs',
    language,
    ...Array(4),
    true,
  );

  const shopData = cmsTransformation(data);

  return {
    loading: loading,
    data: shopData,
    error: error,
  };
};
export { useShop };
