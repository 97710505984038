import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthUser } from 'utils/AuthUser';
import { trackProfileLogoutEvent } from 'utils/product/tracking/events';
import { PromptDialog } from 'components/PromptDialogs/PromptDialog';

const LogoutDialog = ({ open = true, closeDialog }) => {
  const { t } = useTranslation(['profile', 'translation']);
  const { logout } = useAuthUser();
  const [loading, setLoading] = useState(false);

  const invokeLogout = () => {
    trackProfileLogoutEvent('profile_logout_confirm');
    setLoading(true);
    logout();
  };

  return (
    <PromptDialog
      open={open}
      closeDialog={closeDialog}
      header={t(`profile:logoutDialog.header`)}
      message={t(`profile:logoutDialog.description`)}
      primaryActionText={t(`translation:confirm`)}
      primaryActionHandler={invokeLogout}
      primaryActionLoading={loading}
      showSecondaryAction={true}
      secondaryActionText={t(`translation:cancel`)}
      secondaryActionHandler={closeDialog}
    />
  );
};

export default LogoutDialog;
