import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import FullWidthLayout from 'layouts/FullWidthLayout';
import ContestWinnersWidget from 'components/Contests/ContestWinnersWidget';
import { useContest } from 'data/useContest';

const ContestResults = () => {
  const { i18n } = useTranslation(['offers']);
  const language = i18n.language;

  const { slug } = useParams();

  const { data: contest, loading } = useContest(slug, language);

  if (!loading && !contest) {
    return <Navigate to="/" />;
  }

  const pageTitle = contest?.winners?.winnersTitle;
  return (
    <FullWidthLayout
      head={{
        title: pageTitle,
        metaTags: [
          {
            name: 'title',
            content: pageTitle,
          },
        ],
      }}
    >
      <ContestWinnersWidget contest={contest} loading={loading} />
    </FullWidthLayout>
  );
};

export default ContestResults;
