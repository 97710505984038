import React from 'react';
import { useTranslation } from 'react-i18next';

import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import winnerImage from 'assets/icons/Winner.jpg';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import { ContestReward, CouponReward } from './GamesRewardWidget';
import { benefitTypes } from 'utils/product/enums/waveEnums';
import { useBenefit } from 'data/useBenefit';
import {
  gameResultStatuses,
  gameCategories,
} from 'utils/product/enums/gameEnums';

import styles from './GamesRewardDialog.module.scss';
import { trackGamesEvent } from 'utils/product/tracking/loyaltyEvents';

const GamesRewardDialog = ({
  open,
  closeDialog,
  playAgainHandler,
  gameResult,
  playAgainRedeemPoints,
  gameInfo,
}) => {
  const { t, i18n } = useTranslation(['loyalty']);
  const benefit = gameResult.benefits.find(
    (_benefit) =>
      _benefit.type === benefitTypes.CONTEST ||
      _benefit.type === benefitTypes.COUPON
  );
  const { data: gameReward, loading: gameRewardLoading } = useBenefit(
    benefit?.type === benefitTypes.COUPON ? 'coupons' : 'contests',
    i18n?.language,
    {
      query: `filter[content.waveId.el-GR]=${
        benefit?.coupon?.groupId || benefit?.contest?.id
      }`,
    },
    !benefit
  );

  const showFailureScreen =
    gameInfo?.category === gameCategories.skill &&
    gameResult?.result === gameResultStatuses.failure;

  return (
    <DialogOverlay
      open={open}
      closeDialog={() => {
        closeDialog();
        trackGamesEvent('loyalty_game_play_again_reject', '', {
          game_name: gameInfo?.title,
        });
      }}
      dialogHeader={
        showFailureScreen
          ? t(`loyalty:games.reward.header-lost`)
          : t(`loyalty:games.reward.header`)
      }
      dialogClass={styles.dialog}
      contentClass={styles.content}
      scroll="body"
      maxWidth="sm"
    >
      {showFailureScreen ? (
        <section className={styles['times-up-container']}>
          <img
            className={styles.gameOverIcon}
            src={gameInfo?.failImage?.url}
            height="72px"
            width="72px"
            alt="game over"
          />
          <p>{gameInfo?.failText}</p>
        </section>
      ) : (
        <img src={winnerImage} className={styles.image} alt="winner" />
      )}

      <WidgetSkeleton
        heightInPixels={204}
        distance="s"
        show={gameRewardLoading}
      >
        <div className={styles.widgetContainer}>
          {benefit.type === benefitTypes.CONTEST && !gameRewardLoading && (
            <ContestReward
              benefit={gameReward}
              gameResult={gameResult}
              playAgainHandler={() => {
                closeDialog();
                playAgainHandler();
              }}
              redeemPoints={playAgainRedeemPoints}
              playAgainPrompt={gameInfo?.playAgainPrompt}
            />
          )}
          {benefit.type === benefitTypes.COUPON && !gameRewardLoading && (
            <CouponReward
              benefit={gameReward}
              gameResult={gameResult}
              playAgainHandler={() => {
                closeDialog();
                playAgainHandler();
              }}
              redeemPoints={playAgainRedeemPoints}
              playAgainPrompt={gameInfo?.playAgainPrompt}
            />
          )}
        </div>
      </WidgetSkeleton>
    </DialogOverlay>
  );
};

export default GamesRewardDialog;
