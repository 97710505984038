import React from 'react';

import Widget from 'components/Widget/Widget';
import IconText from 'components/IconText/IconText';
import { ReactComponent as RightArrow } from 'assets/icons/ArrowRightIcon.svg';

import styles from './CollectionOptionWidget.module.scss';

const CollectionOptionWidget = ({
  title,
  type,
  image,
  description,
  handleClick,
}) => {
  return (
    <Widget
      distance="xs"
      size="s"
      containerClass={styles.widget}
      onClick={() => handleClick(type)}
    >
      <IconText
        Icon={<img src={image} alt={title} width={24} />}
        iconRight={<RightArrow />}
        containerClass={styles.container}
        content={
          <>
            <p className={styles.title}>{title}</p>
            <p>{description}</p>
          </>
        }
      />
    </Widget>
  );
};

export default CollectionOptionWidget;
