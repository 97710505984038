import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scrollTo } from 'utils/scroll';

const useScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    const unlisten = () => {
      scrollTo(0, 0);
    };
    return () => {
      unlisten();
    };
  }, [location]);
};

export default useScrollToTop;
