import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  getWinnerInfo,
  getContestStatusInfo,
  getPrizeDescription,
} from 'utils/product/contestsUtils';
import useDocumentByWaveId from 'data/useDocumentByWaveId';
import ContestInfoBoxWidget from './ContestInfoBoxWidget';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';

const ContestInfoBox = ({
  contest = {},
  participationsCount,
  participations,
  onParticipationInvoked,
  onClaimVoucher,
}) => {
  const navigate = useNavigate();

  const { redeemPoints, participationsPerParticipantLimit } = contest || {};

  const {
    userIsWinner,
    winnerRejected,
    isPrizeUnclaimed,
    claimUntil,
    prizeTypeAndWaveId,
  } = getWinnerInfo(participations, contest);

  const contestStatus = getContestStatusInfo({
    contest,
    participationsCount,
    userIsWinner,
    winnerRejected,
    prizeType: prizeTypeAndWaveId?.type,
  });

  const { data: prize, loading: prizeLoading } = useDocumentByWaveId(
    prizeTypeAndWaveId?.waveId,
  );
  const prizeDescription =
    getPrizeDescription(prize, prizeTypeAndWaveId?.type) || '-';

  const handleGoToVouchersPage = () => {
    navigate('/profile/my-coupons');
  };

  const onCouponButtonClick = isPrizeUnclaimed
    ? onClaimVoucher
    : handleGoToVouchersPage;

  return (
    <WidgetSkeleton distance="m" show={prizeLoading} heightInPixels="84">
      <ContestInfoBoxWidget
        contestStatus={contestStatus}
        participationsCount={participationsCount}
        isPrizeUnclaimed={isPrizeUnclaimed}
        claimUntil={claimUntil}
        redeemPoints={redeemPoints}
        participationsPerParticipantLimit={participationsPerParticipantLimit}
        prizeDescription={prizeDescription}
        onParticipationInvoked={onParticipationInvoked}
        onCouponButtonClick={onCouponButtonClick}
      />
    </WidgetSkeleton>
  );
};

export default ContestInfoBox;
