import React from 'react';

import { getLVConsumptionInfo } from 'utils/product/consumptionUtils';
import { ConsumptionIndications } from 'components/Consumptions/Home/ConsumptionInfoElements';

import styles from './ConsumptionClearingGas.module.scss';

const ConsumptionClearingGas = ({ meter }) => {
  const consumptionInfo = getLVConsumptionInfo(meter);
  return (
    <section className={styles.info}>
      <ConsumptionIndications
        {...consumptionInfo}
        dividedSize="xxxl"
        dividedUnitSize="xxl"
      />
    </section>
  );
};
export default ConsumptionClearingGas;
