import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as RightArrow } from 'assets/icons/ArrowRightIcon.svg';
import TextArrow from 'components/TextArrow/TextArrow';

import styles from './WidgetHeaderLink.module.scss';

const WidgetHeaderLink = ({
  title,
  secondTitle,
  linkTo,
  linkToState,
  onLinkClicked,
}) => {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{title}</h3>
      {linkTo && (
        <Link
          className={styles.link}
          to={linkTo}
          state={linkToState}
          aria-label={secondTitle}
          onClick={() => {
            if (typeof onLinkClicked === 'function') onLinkClicked();
          }}
        >
          <TextArrow
            Icon={<RightArrow />}
            weight="bold"
            content={<p className={styles.secondTitle}>{secondTitle}</p>}
          />
        </Link>
      )}
    </div>
  );
};

export default WidgetHeaderLink;
