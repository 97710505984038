import React from 'react';

import { ReactComponent as Energy } from 'assets/icons/EnergyIcon.svg';
import { ReactComponent as EnergyGas } from 'assets/icons/EnergyGasIcon.svg';
import { ReactComponent as Eco } from 'assets/icons/SolarPanelIcon.svg';
import commodities from 'utils/product/enums/commodities';
import useLocalizedFriendlyName from 'hooks/useLocalizedFriendlyName';
import WidgetLabel from 'components/WidgetLabel/WidgetLabel';

import styles from './MeterLabel.module.scss';

const MeterLabel = ({
  type,
  friendlyName,
  meterNo,
  prefix,
  suffix,
  size = 'm',
  labelClass = '',
}) => {
  const { getDisplayName } = useLocalizedFriendlyName();
  const meterType =
    type === commodities.energy
      ? 'energy'
      : type === commodities.gas
      ? 'gas'
      : type;

  const icons =
    meterType === 'gas' ? (
      <EnergyGas />
    ) : meterType === 'eco' ? (
      <Eco />
    ) : (
      <Energy />
    );

  const text = `${prefix ?? ''} ${getDisplayName(friendlyName, meterNo)} ${
    suffix ?? ''
  }`;
  return (
    <WidgetLabel
      icon={icons}
      content={text}
      labelClass={`${styles.widgetLabel} ${
        meterType ? styles[`widgetLabel-${meterType}`] : ''
      } ${size ? styles[`widgetLabel-${size}`] : ''} ${labelClass}`}
    />
  );
};

export default MeterLabel;
