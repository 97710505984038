import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tab, Tabs } from 'components/Tabs/Tabs';
import { trackBillPaymentHistoryEvent } from 'utils/product/tracking/events';
import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import MyBillsHistoryTabPanel from './MyBillsHistoryTabPanel';
import MyPaymentsTabPanel from './MyPaymentsTabPanel';

import styles from './MyBillsHistoryWidget.module.scss';

const MyBillsHistoryWidget = ({ meters, metersLoading }) => {
  const { t } = useTranslation(['translation', 'bills']);
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (event, newValue) => {
    if (newValue === tabIndex) return;
    setTabIndex(newValue);
    if (newValue === 1) {
      trackBillPaymentHistoryEvent('bill_history_payments');
    }
  };

  return (
    <Widget
      distance="s"
      title={<WidgetHeader title={t('bills:history.title')} />}
    >
      <section className={styles['tabs-container']}>
        <Tabs value={tabIndex} onChange={handleChange}>
          <Tab label={t('bills:bills.title')} />
          <Tab label={t('bills:payments.title')} />
        </Tabs>
      </section>
      <section className={styles['tabsPanel-container']}>
        <MyBillsHistoryTabPanel
          value={tabIndex}
          meters={meters}
          metersLoading={metersLoading}
        />
        <MyPaymentsTabPanel
          value={tabIndex}
          meters={meters}
          metersLoading={metersLoading}
          skipAccountPaymentsService={tabIndex !== 1}
        />
      </section>
    </Widget>
  );
};
export default MyBillsHistoryWidget;
