import React from 'react';

import MeterLabelWrapper from 'components/Labels/MeterLabelWrapper';
import ContractsBody from 'components/Management/ContractsBody';
import Widget from 'components/Widget/Widget';

import styles from './ContractsList.module.scss';

const ContractsList = ({ contracts }) => {
  return contracts && contracts.length > 0
    ? contracts.map((contract) => {
        const {
          Package,
          MeterFriendlyName,
          ContractSignatureDate,
          ContractNo,
          MeterNo,
          Meterid,
          meter,
        } = contract;
        const { PackageTitle, DaysDuration } = Package || {};

        return (
          <Widget
            key={Meterid}
            widgetClass={styles['contract-widget']}
            distance="xs"
          >
            <MeterLabelWrapper
              size="m"
              type={meter?.Commodity}
              friendlyName={MeterFriendlyName}
              meterNo={MeterNo}
              meter={meter}
            />
            <ContractsBody
              supplyNumber={MeterNo}
              programName={PackageTitle}
              contractDurationInDays={DaysDuration}
              contractSignatureDate={ContractSignatureDate}
              contractNumber={ContractNo}
            />
          </Widget>
        );
      })
    : null;
};

export default ContractsList;
