import { trackEvent } from 'utils/analytics';
import paymentTypes from 'utils/product/enums/paymentTypes';
import pages from 'utils/product/enums/pages';
import { isVibesPromoOffer } from '../offerUtils';
import discoveryItemTypes from '../enums/discoveryItemTypes';
import offerEntryPoints from '../enums/offerEntryPoints';

export const trackHomeEvent = (action, label, customDimensions) => {
  trackEvent('Ηome', action, label, customDimensions);
};

export const trackOffersEvent = (action, label, customDimensions) => {
  trackEvent('Ηome - offers', action, label, customDimensions);
};

export const trackContestsEvent = (action, label, customDimensions) => {
  trackEvent('Contest', action, label, customDimensions);
};

export const trackEbillContestsEvent = (action, label, customDimensions) => {
  trackEvent('Contest ebill modal ', action, label, customDimensions);
};

export const trackHomeBillsEvent = (action, label, customDimensions) => {
  trackEvent('Ηome - bills', action, label, customDimensions);
};

export const trackHomeConsumptionsEvent = (action, label, customDimensions) => {
  trackEvent('Ηome - consumptions', action, label, customDimensions);
};

export const trackHomeConsumptionsB2BEvent = (
  action,
  label,
  customDimensions,
) => {
  trackEvent('Ηome - consumptions - B2B', action, label, customDimensions);
};

export const trackHomeENAEvent = (action, label, customDimensions) => {
  trackEvent('Ηome - ENA', action, label, customDimensions);
};

export const trackHomeRequestsEvent = (action, label, customDimensions) => {
  trackEvent('Ηome - Requests', action, label, customDimensions);
};

export const trackHomeCustomerSupportEvent = (
  action,
  label,
  customDimensions,
) => {
  trackEvent('Home - Customer Support', action, label, customDimensions);
};

export const trackOfferEvent = (action, label, customDimensions) => {
  trackEvent('Offer', action, label, customDimensions);
};

export const trackBillPdfEvent = (action, label, customDimensions) => {
  trackEvent('Bill - pdf', action, label, customDimensions);
};

export const trackEbillEvent = (action, label, customDimensions) => {
  trackEvent('Bill - ebill', action, label, customDimensions);
};

export const trackBillPaymentEvent = (action, label, customDimensions) => {
  trackEvent('Bill - payment', action, label, customDimensions);
};

export const trackBillAnalysisEvent = (action, label, customDimensions) => {
  trackEvent('Bill - analysis', action, label, customDimensions);
};

export const trackBillSettlementEvent = (action, label, customDimensions) => {
  trackEvent('Bill - settlement', action, label, customDimensions);
};

export const trackBillHistoryEvent = (action, label, customDimensions) => {
  trackEvent('Bill history - bills', action, label, customDimensions);
};

export const trackBillPaymentHistoryEvent = (
  action,
  label,
  customDimensions,
) => {
  trackEvent('Bill history - payments', action, label, customDimensions);
};

export const trackBillEvent = (action, label, customDimensions) => {
  trackEvent('Bill', action, label, customDimensions);
};

export const trackPaymentSuccessEvent = (paymentType, label) => {
  if (paymentType === paymentTypes.bill)
    trackEvent('Bill - payment', 'bill_payment_success', label);
  if (paymentType === paymentTypes.guest)
    trackEvent(
      'Login screen',
      'loginscreen_pay_without_login_pay_success',
      label,
    );
  if (paymentType === paymentTypes.thirdParty)
    trackEvent('Bill', 'bill_payment_3rd_success', label);
  if (paymentType === paymentTypes.ena)
    trackEvent('ENA', 'ENA_participation_payment_success', label);

  if (paymentType === paymentTypes.settlement)
    trackEvent(
      'Bill - settlement',
      'bill_settlement_payment_dose_success',
      label,
    );
};

export const trackPaymentFailureEvent = (paymentType, label) => {
  if (paymentType === paymentTypes.bill)
    trackEvent('Bill - payment', 'bill_payment_fail', label);
  if (paymentType === paymentTypes.guest)
    trackEvent(
      'Login screen',
      'loginscreen_pay_without_login_pay_error',
      label,
    );
  if (paymentType === paymentTypes.thirdParty)
    trackEvent('Bill', 'bill_payment_3rd_fail', label);
  if (paymentType === paymentTypes.ena)
    trackEvent('ENA', 'ENA_participation_paymentfail', label);

  if (paymentType === paymentTypes.settlement)
    trackEvent(
      'Bill - settlement',
      'bill_settlement_payment_dose_error',
      label,
    );
};

export const trackRequestsEvent = (action, label, customDimensions) => {
  trackEvent('Requests', action, label, customDimensions);
};

export const trackRequestsDetailViewEvent = (
  action,
  label,
  customDimensions,
) => {
  trackEvent('Request detailed view', action, label, customDimensions);
};

export const trackCertificateEvent = (action, label, customDimensions) => {
  trackEvent('Certificate detailed view', action, label, customDimensions);
};

export const trackNotificationEvent = (action, label, customDimensions) => {
  trackEvent('Notification', action, label, customDimensions);
};

export const trackShopEvent = (action, label, customDimensions) => {
  trackEvent('Shop', action, label, customDimensions);
};

export const trackShopProgramEvent = (action, label, customDimensions) => {
  trackEvent('Program detailed view', action, label, customDimensions);
};

export const trackShopServiceEvent = (action, label, customDimensions) => {
  trackEvent('Service detailed view', action, label, customDimensions);
};

export const trackShopEnaEvent = (action, label, customDimensions) => {
  trackEvent('ENA detailed view', action, label, customDimensions);
};

export const trackProfileEvent = (
  action,
  label,
  customDimensions,
  force = false,
) => {
  trackEvent('Profile', action, label, customDimensions, force);
};

export const trackProfileCouponsEvent = (
  action,
  label,
  customDimensions,
  force = false,
) => {
  trackEvent('Profile - Coupons', action, label, customDimensions, force);
};

export const trackProfileInfoEvent = (action, label, customDimensions) => {
  trackEvent('Personal info', action, label, customDimensions);
};

export const trackProfileLogoutEvent = (action, label, customDimensions) => {
  trackEvent('Profile logout', action, label, customDimensions);
};

export const trackConsumptionHistoryEvent = (
  action,
  label,
  customDimensions,
) => {
  trackEvent('Consumption history', action, label, customDimensions);
};

export const trackConsumptionCountHistoryEvent = (
  action,
  label,
  customDimensions,
) => {
  trackEvent('Count history', action, label, customDimensions);
};

export const trackConsumptionCountEvent = (action, label, customDimensions) => {
  trackEvent('Count', action, label, customDimensions);
};

export const trackConsumptionEvent = (action, label, customDimensions) => {
  trackEvent('Consumption analysis ', action, label, customDimensions);
};

export const trackEnaEvent = (action, label, customDimensions) => {
  trackEvent('ENA', action, label, customDimensions);
};

export const trackGuestEvent = (action, label, customDimensions) => {
  trackEvent('Login screen', action, label, customDimensions);
};

export const trackLoginEvent = (action, label, customDimensions) => {
  trackEvent('Login', action, label, customDimensions);
};

export const trackForgotPasswordEvent = (action, label, customDimensions) => {
  trackEvent('Forgot password', action, label, customDimensions);
};

export const trackWhatsNewEvent = (action, label, customDimensions) => {
  trackEvent('Whats new', action, label, customDimensions);
};

export const trackAcceptTermsEvent = (action, label, customDimensions) => {
  trackEvent('Accept terms', action, label, customDimensions);
};

export const trackFriendlyNameEvent = (action, label, customDimensions) => {
  trackEvent('Friendly name', action, label, customDimensions);
};

export const trackWizardEvent = (action, label, customDimensions) => {
  trackEvent('Wizard', action, label, customDimensions);
};

export const trackOnboardingEvent = (action, label, customDimensions) => {
  trackEvent('Onboarding', action, label, customDimensions);
};

export const trackEcontractEvent = (action, label, customDimensions) => {
  trackEvent('econtract', action, label, customDimensions);
};

export const trackNewContractEvent = (action, label, customDimensions) => {
  trackEvent('New contract', action, label, customDimensions);
};

export const trackDownloadAppEvent = (source, platform) => {
  if (source === pages.home) {
    trackEvent('Home', `home_download_app_${platform}`);
    return;
  }
  if (source === pages.onboarding) {
    trackEvent('Onboarding', `onboarding_whatsnew_download_app_${platform}`);
    return;
  }
  if (source === pages.login) {
    trackEvent('Login screen', `loginscreen_download_app_${platform}`);
    return;
  }
};

export const trackAppBannerEvent = (action, label, platform) => {
  trackEvent('General', `general_download_app_popup_${platform}_${action}`);
};

export const trackDiscoveryItemSelection = (discoveryItem, page) => {
  if (page === offerEntryPoints.home) {
    const isVibesOffer = isVibesPromoOffer(discoveryItem);

    if (isVibesOffer) {
      trackHomeEvent('offer_new_loyalty_user_home_selected');
      return;
    }

    if (discoveryItem._type === discoveryItemTypes.contest) {
      trackOffersEvent(`home_contest_selected`, '', {
        contest_name: discoveryItem.title,
      });
      return;
    }

    if (discoveryItem._type === discoveryItemTypes.offer) {
      trackOffersEvent(`home_offer_selected`, '', {
        offer_name: discoveryItem.title,
      });
      return;
    }
    return;
  }
};
