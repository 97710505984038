import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import { EmailInput } from 'components/Inputs/EmailInput';
import { TelephoneInput } from 'components/Inputs/TelephoneInput';
import { PrimaryButtonLoader, LinkButton } from 'components/Buttons/Buttons';
import useContactsForMeter from 'data/useContactsForMeter';
import MeterIconText from 'components/Labels/MeterIconText';
import Checkbox from 'components/Checkbox/Checkbox';
import { setContactToMeter } from 'api/account';
import { LocalisedErrorBox } from 'components/MessageBox/MessageBox';
import useError from 'hooks/useError';
import EbillDeactivationModal from 'components/EbillModal/EbillDeactivationModal';
import useDialog from 'hooks/useDialog';
import Loader from 'components/Skeletons/Loader';
import { useAuthUser } from 'utils/AuthUser';

import styles from './EbillElectricityDialog.module.scss';

const EbillElectricityDialog = ({
  open,
  closeDialog,
  onSuccessActivation,
  onSuccessDeactivation,
  meter,
}) => {
  const { mutateAccount, loading: userLoading } = useAuthUser();
  const { t } = useTranslation([
    'requests',
    'translation',
    'management',
    'onboarding',
  ]);
  const {
    data: { ContactId, email, mobile, IsPrimary } = {},
    loading,
    mutate,
  } = useContactsForMeter(meter?.Id);

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm();

  const isManageMode = meter?.Ebill;

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateContactAccepted, setIsUpdateContactAccepted] = useState(false);
  const { error, handleError, clearError } = useError();
  const ebillDeactivationDialog = useDialog();

  const dialogHeader = !isManageMode
    ? t('management:ebill.activation-header')
    : t('management:ebill.edit-header');

  const instructionsText = !isManageMode
    ? t('management:ebill.activation-message')
    : t('management:ebill.edit-message');

  const ctaButton = !isManageMode
    ? t('translation:completion')
    : t('management:personalInfo.submit-changes');

  const onSubmit = (data) => {
    setIsLoading(true);

    const contactMeterPayload = {
      MeterId: meter.Id,
      ContactId:
        (IsPrimary && data.termsAccepted) || !IsPrimary ? ContactId : '',
      Email: data.email,
      Phone: data.mobile,
    };

    clearError();

    setContactToMeter(contactMeterPayload)
      .then(() => {
        setIsLoading(false);
        closeDialog();
        onSuccessActivation({
          isEdit: isManageMode,
          email: data.email,
          phone: data.mobile,
        });
        mutateAccount();
        mutate();
      })
      .catch((errorResponse) => {
        handleError(errorResponse.response, 'ebill');
      });
  };

  const handleEBillDeactivation = () => {
    closeDialog();
    ebillDeactivationDialog.show();
  };

  const handleOnSuccessEbillDeactivation = () => {
    ebillDeactivationDialog.close();
    onSuccessDeactivation();
  };

  const handleCloseDialog = () => {
    closeDialog();
  };

  useEffect(() => {
    if (!open) {
      setIsUpdateContactAccepted(false);
      resetField('email');
      resetField('mobile');
    }
  }, [open, resetField]);

  return (
    <>
      <DialogOverlay
        open={open}
        closeDialog={handleCloseDialog}
        dialogHeader={dialogHeader}
        contentClass={styles.dialogContent}
        alignActions="right"
        dialogActions={
          <div className={styles['dialogsActions-container']}>
            <PrimaryButtonLoader
              loading={isLoading}
              disabled={isLoading || loading}
              onClick={handleSubmit(onSubmit)}
              type="submit"
            >
              {ctaButton}
            </PrimaryButtonLoader>
            {isManageMode && meter && (
              <LinkButton
                extraClasses={styles.eBillDeactivationButton}
                onClick={handleEBillDeactivation}
              >
                {t('management:ebill.deactivate-cta')}
              </LinkButton>
            )}
          </div>
        }
      >
        <p className={styles.instructions}>{instructionsText}</p>
        <LocalisedErrorBox
          open={error !== null}
          closeBox={clearError}
          {...(error || {})}
          distanceTop=""
        />
        <div className={styles['meterLabel-container']}>
          <MeterIconText
            meterNo={meter?.MeterNo}
            commodity={meter?.Commodity}
            friendlyName={meter?.FriendlyName}
          />
        </div>
        <Loader show={loading || !meter || userLoading} height={196}>
          <EmailInput
            register={register}
            errors={errors}
            name="email"
            label={t(`requests:personal-data.email`)}
            defaultValue={email || ''}
          />
          <TelephoneInput
            register={register}
            required={false}
            errors={errors}
            name="mobile"
            label={t(`onboarding:createUserContent.telephone`)}
            defaultValue={mobile || ''}
            mobileOnly={true}
          />
          {IsPrimary && (
            <Checkbox
              value={isUpdateContactAccepted}
              changeHandler={(event) => {
                setIsUpdateContactAccepted(event.target.checked);
              }}
              name={'termsAccepted'}
              label={t('management:ebill.checkbox')}
              register={register}
              labelClass={styles.checkbox}
            />
          )}
        </Loader>
      </DialogOverlay>
      <EbillDeactivationModal
        open={ebillDeactivationDialog.isOpen}
        closeDialog={ebillDeactivationDialog.close}
        onSuccess={handleOnSuccessEbillDeactivation}
        meterId={meter?.Id}
        commodity={meter?.Commodity}
      />
    </>
  );
};

export default EbillElectricityDialog;
