import { Input } from '@material-ui/core';
import React from 'react';

const styles = { display: 'none' };
const HiddenFormInput = ({ name, defaultValue, register = () => {} }) => {
  const { ref, ...registerRest } = register(name) || {};
  return (
    <Input
      inputRef={ref}
      defaultValue={defaultValue}
      style={styles}
      {...registerRest}
    />
  );
};

export { HiddenFormInput };
