import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput } from 'components/Inputs/TextInput';
import PostalCodeInput from 'components/Inputs/PostalCodeInput';
import styles from './MeterAddress.module.scss';

const MeterAddress = ({
  defaultValues = {},
  onUpdate = () => {},
  isGasProgram = false,
}) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { t } = useTranslation(['newClient']);
  const baseObjectName = isGasProgram ? 'gasProgram' : 'energyProgram';

  const handleChange = ({ postalCodeId }) => {
    setValue(`${baseObjectName}.meterPostalCodeId`, postalCodeId);
    onUpdate({
      [baseObjectName]: {
        ...defaultValues[baseObjectName],
        meterPostalCodeId: postalCodeId,
      },
    });
  };
  return (
    <article className={styles.container}>
      <h3 className={styles.header}>{t(`newClient:meterAddress.header`)}</h3>
      <p className={styles.subheader}>
        {t(`newClient:meterAddress.subheader`)}
      </p>
      <TextInput
        register={register}
        errors={errors}
        name={`${baseObjectName}.meterAddress`}
        label={t(`newClient:sharedDetails.street`)}
        defaultValue={defaultValues[baseObjectName]?.meterAddress ?? ''}
      />
      <TextInput
        register={register}
        errors={errors}
        name={`${baseObjectName}.meterAddressNo`}
        label={t(`newClient:sharedDetails.streetNo`)}
        defaultValue={defaultValues[baseObjectName]?.meterAddressNo ?? ''}
      />
      <PostalCodeInput
        register={register}
        errors={errors}
        postalCodeName={`${baseObjectName}.meterPostalCode`}
        postalCodeValue={defaultValues[baseObjectName]?.meterPostalCode}
        postalCodeIdName={`${baseObjectName}.meterPostalCodeId`}
        postalCodeIdValue={defaultValues[baseObjectName]?.meterPostalCodeId}
        onChangeHandler={handleChange}
      />
    </article>
  );
};

export default MeterAddress;
