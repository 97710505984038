import useSWR, { mutate } from 'swr';
import { fetcher } from 'api/wave';
import { conventFromCents } from 'utils/moneyUtils';
import endpoints from 'utils/product/enums/endpoints';

export const mutateLoyaltyHistory = (customerId) => {
  mutate(
    `${endpoints.loyalty.historyEvents}?customerId=${customerId}&pageSize=25&rewardSchemeId=${process.env.REACT_APP_LOYALTY_ENERGYPOINTS_ID}`
  );
};

const useLoyaltyHistory = (
  customerId,
  ruleId,
  pageNumber = 0,
  pageSize = 25,
  rewardSchemeId = process.env.REACT_APP_LOYALTY_ENERGYPOINTS_ID // display energy points only (not vibes)
) => {
  const queryParams = [];
  if (customerId) queryParams.push(`customerId=${customerId}`);
  if (pageNumber) queryParams.push(`pageNumber=${pageNumber}`);
  if (pageSize) queryParams.push(`pageSize=${pageSize}`);
  if (rewardSchemeId) queryParams.push(`rewardSchemeId=${rewardSchemeId}`);
  if (ruleId) queryParams.push(`ruleId=${ruleId}`);

  const query = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

  const {
    data,
    error,
    isLoading: loading,
    mutate,
  } = useSWR(
    !customerId ? null : `${endpoints.loyalty.historyEvents}${query}`,
    fetcher
  );

  const transactions = [];

  if (data && data?.data?.length > 0) {
    data?.data?.forEach((event) => {
      if (event.transactions?.length > 0) {
        event.transactions?.forEach((transaction, index) => {
          transactions.push({
            ...event,
            amount: conventFromCents(transaction.amount),
            transaction,
            key: `${event._id}_${index}`,
          });
        });
      }
    });
  }

  return {
    loading,
    error,
    data: transactions,
    mutate,
  };
};

export default useLoyaltyHistory;
