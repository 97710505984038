import storage from './storage';

const TOKEN_KEY = 'token';
const REFRESHTOKEN_KEY = 'refresh_token';
const ACCOUNT_ID = 'acc_id';
const COMMODITY = 'commodity';

const getCommodity = () => {
  return storage.getItem({ key: COMMODITY });
};

const setCommodity = ({ commodity }) => {
  return storage.setItem({
    key: COMMODITY,
    value: commodity,
  });
};

const removeCommodity = () => {
  return storage.removeItem({ key: COMMODITY });
};

const getToken = () => {
  return storage.getItem({ key: TOKEN_KEY });
};

const setToken = ({ token }) => {
  return storage.setItem({ key: TOKEN_KEY, value: token, expiresInDays: 365 });
};

const removeToken = () => {
  return storage.removeItem({ key: TOKEN_KEY });
};

const getRefreshToken = () => {
  return storage.getItem({ key: REFRESHTOKEN_KEY });
};

const setRefreshToken = ({ refreshToken }) => {
  return storage.setItem({
    key: REFRESHTOKEN_KEY,
    value: refreshToken,
    expiresInDays: 365,
  });
};

const removeRefreshToken = () => {
  return storage.removeItem({ key: REFRESHTOKEN_KEY });
};

const getAccountId = () => {
  return storage.getItem({
    key: ACCOUNT_ID,
    storage: storage.STORAGE_TYPE.LocalStorage,
  });
};

const setAccountId = (accountId) => {
  return storage.setItem({
    key: ACCOUNT_ID,
    value: accountId,
    storage: storage.STORAGE_TYPE.LocalStorage,
    expiresInDays: 365,
  });
};

const removeAccountId = () => {
  return storage.removeItem({
    key: ACCOUNT_ID,
    storage: storage.STORAGE_TYPE.LocalStorage,
  });
};

const clearAuthTokens = () => {
  removeToken();
  removeRefreshToken();
  removeAccountId();
  removeCommodity();
};

const authStorage = {
  getToken,
  getRefreshToken,
  setToken,
  setRefreshToken,
  removeToken,
  removeRefreshToken,
  clearAuthTokens,
  getAccountId,
  setAccountId,
  removeAccountId,
  getCommodity,
  setCommodity,
  keys: { ACCOUNT_ID, TOKEN_KEY, REFRESHTOKEN_KEY },
};
export default authStorage;
