import React, { useEffect, useState } from 'react';
import MuiStepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Tooltip from '@material-ui/core/Tooltip';
import cn from 'classnames';

import styles from './Stepper.module.scss';

function StepperConnector({ index, activeStep }) {
  return (
    <div
      className={cn(styles.connector, {
        [styles.active]: activeStep >= index,
      })}
    ></div>
  );
}

export default function Stepper({
  steps,
  onStepClicked = () => {},
  initialStep = 0,
  viewOnly = true,
  handleActiveStepInternally = true /* pass true when activeStep will only be determined by initalStep passed and handled on parent */,
  stepperClass = styles.stepper,
}) {
  const [activeStep, setActiveStep] = useState(initialStep);
  const [maxStep, setMaxStep] = useState(initialStep);
  const handleStep = (step, index, activeStep) => () => {
    if (viewOnly) return;
    if (index !== activeStep) {
      if (handleActiveStepInternally) setActiveStep(activeStep);
      onStepClicked(step, index, activeStep);
    }
  };

  useEffect(() => {
    setMaxStep((_maxStep) => (activeStep > _maxStep ? activeStep : _maxStep));
  }, [activeStep]);

  useEffect(() => {
    setActiveStep(initialStep);
  }, [initialStep]);

  return (
    <div className={styles.container}>
      <MuiStepper
        nonLinear
        activeStep={activeStep}
        classes={{ root: stepperClass }}
        connector={<StepperConnector activeStep={activeStep} />}
      >
        {steps.map((step, index) => {
          return (
            <Tooltip title={step.title} key={step.title}>
              <Step
                disabled={index > maxStep}
                className={cn(
                  styles.step,
                  { [styles.leftStep]: index === 0 },
                  { [styles.rightStep]: index === steps.length - 1 },
                  { [styles.visitedStep]: activeStep >= index }
                )}
                completed={false}
                active={activeStep === index}
              >
                <StepButton
                  className={cn(styles.stepNumber, {
                    [styles.nocursor]: viewOnly,
                  })}
                  onClick={handleStep(step, index, activeStep)}
                />
              </Step>
            </Tooltip>
          );
        })}
      </MuiStepper>
    </div>
  );
}
