import React from 'react';

import WhatsNewWrapper from 'components/WhatsNew/WhatsNewWrapper';
import WhatsNewImage from 'components/WhatsNew/WhatsNewImage';
import WhatsNewInfo from 'components/WhatsNew/WhatsNewInfo';
import WhatsNewDots from 'components/WhatsNew/WhatsNewDots';
import whatsNewTypes from 'utils/product/enums/whatsNewTypes';

const WhatsNew = () => (
  <WhatsNewWrapper>
    <WhatsNewImage type={whatsNewTypes.default} />
    <WhatsNewDots />
    <WhatsNewInfo type={whatsNewTypes.default} />
  </WhatsNewWrapper>
);

export default WhatsNew;
