import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { useCollectionOptions } from 'data/useCollectionOptions';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import { patchCustomer, receivePoints } from 'api/loyalty';
import { useAuthUser } from 'utils/AuthUser';
import { ReactComponent as CalendarIcon } from 'assets/icons/CalendarIcon.svg';
import { DatePicker } from 'components/Inputs/DatePicker';
import { mutateLoyaltyPoints } from 'data/useRewardSchemeAccounts';
import { mutateLoyaltyHistory } from 'data/useLoyaltyHistory';
import { LocalisedErrorDialog } from 'components/PromptDialogs/PromptDialog';
import {
  getPointsOfCategory,
  getWaveId,
} from 'utils/product/loyalty/pointsUtils';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import Loader from 'components/Skeletons/Loader';
import useDialog from 'hooks/useDialog';
import EnergyPointsLabel from 'components/Loyalty/EnergyPointsLabel';
import RewardDialog from 'components/Loyalty/RewardDialog';
import birthdayImage from 'assets/icons/LoyaltyBirthdayImage.jpg';
import useError from 'hooks/useError';
import { getRewardPoints } from 'utils/product/loyalty/pointsUtils';
import { trackBirthdayEvent } from 'utils/product/tracking/loyaltyEvents';

import styles from './BirthdayDialog.module.scss';

const BirthdayDialog = ({ open, closeDialog, type }) => {
  const { t, i18n } = useTranslation(['loyalty', 'translation']);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const { loyaltyCustomerId } = useAuthUser();
  const [selectedDate, handleDateChange] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [rewardPoints, setRewardPoints] = useState();

  const birthdayDialog = useDialog(open);
  const rewardDialog = useDialog();

  const { collectionOptionsByType, loading } = useCollectionOptions(
    i18n.language
  );
  const birthdayPoints = getPointsOfCategory(collectionOptionsByType, type);
  const waveId = getWaveId(collectionOptionsByType, type);

  const { error, handleError, clearError } = useError();
  const errorDialog = useDialog();

  const onSubmit = ({ dateOfBirth }) => {
    setIsSaving(true);
    const patchData = {
      dateOfBirth: dateOfBirth,
    };

    patchCustomer(loyaltyCustomerId, patchData)
      .then(() => {
        const postData = {
          customer: {
            customerId: loyaltyCustomerId,
          },
          type: 'CUSTOM',
          ruleId: waveId,
        };

        return receivePoints(postData);
      })
      .then((loyaltyResponse) => {
        setRewardPoints(getRewardPoints(loyaltyResponse?.data, waveId));
        setIsSaving(false);
        birthdayDialog.close();
        rewardDialog.show();
        trackBirthdayEvent('birthday_submit_success');
      })
      .catch((error) => {
        handleError('general', 'generic');
        birthdayDialog.close();
        errorDialog.show();
      });
  };

  return (
    <>
      <DialogOverlay
        open={birthdayDialog.isOpen}
        closeDialog={() => {
          birthdayDialog.close();
          closeDialog();
        }}
        dialogHeader={t(`loyalty:collection-options.${type}.header`)}
        dialogClass={styles.dialog}
        contentClass={styles.content}
        alignActions="right"
        scroll="body"
        dialogActions={
          <>
            <PrimaryButtonLoader
              loading={isSaving}
              disabled={isSaving}
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              {t(`loyalty:collection-options.${type}.submit`)}
            </PrimaryButtonLoader>
          </>
        }
      >
        <Loader show={loading} height={268}>
          <img src={birthdayImage} className={styles.image} alt="birthday" />
          <p className={styles.message}>
            {t(`loyalty:collection-options.${type}.message`)}
          </p>
          <p className={styles.subtext}>
            {t(`loyalty:collection-options.${type}.subtext`)}
          </p>
          <EnergyPointsLabel points={birthdayPoints} />
          <div className={styles.datePicker}>
            <DatePicker
              disableFuture
              label={t(
                `loyalty:collection-options.${type}.calendar-placeholder`
              )}
              value={selectedDate}
              onChange={handleDateChange}
              errors={errors}
              control={control}
              required
              autoOk
              name="dateOfBirth"
              disableToolbar={false}
              customToolbar
              animateYearScrolling
              icon={<CalendarIcon />}
            />
          </div>
        </Loader>
      </DialogOverlay>

      <RewardDialog
        open={rewardDialog.isOpen}
        closeDialog={() => {
          mutateLoyaltyPoints(loyaltyCustomerId);
          mutateLoyaltyHistory(loyaltyCustomerId);
          closeDialog();
          rewardDialog.close();
        }}
        points={rewardPoints}
        icon={null}
        header={t(`loyalty:collection-success.${type}.header`)}
        message={t(`loyalty:collection-success.${type}.succeed`)}
        loading={loading}
      />

      {errorDialog.isOpen && (
        <LocalisedErrorDialog
          open={errorDialog.isOpen}
          closeDialog={() => {
            errorDialog.close();
            clearError();
            closeDialog();
          }}
          showCloseButton={false}
          showPrimaryAction={true}
          primaryActionText={t('translation:ok')}
          primaryActionHandler={() => {
            errorDialog.close();
            clearError();
            closeDialog();
          }}
          {...(error || {})}
        />
      )}
    </>
  );
};

export default BirthdayDialog;
