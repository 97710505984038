import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StandardInput } from 'components/Inputs/Inputs';
import { addressInfoText } from 'utils/product/meterUtils';

import styles from './MyMetersWrapperElement.module.scss';

const MyMetersWrapperElement = ({
  AddressInfo,
  DayMeasurementCode,
  NightMeasurementCode,
  children,
}) => {
  const { t } = useTranslation(['management']);
  const baseName = 'management:myMeters';
  const [addressText] = useState(() => addressInfoText(AddressInfo));

  return (
    <>
      {children}
      {addressText ? (
        <StandardInput
          value={addressText}
          readOnly={true}
          label={t(`${baseName}.meter-address`)}
          disabled
        />
      ) : null}
      {DayMeasurementCode || NightMeasurementCode ? (
        <>
          <h2 className={styles.header}>{t(`${baseName}.meters`)}</h2>
          <section>
            {DayMeasurementCode ? (
              <StandardInput
                value={DayMeasurementCode}
                readOnly={true}
                label={t(
                  `${baseName}.${NightMeasurementCode ? 'daily-' : ''}meter`
                )}
                disabled
              />
            ) : null}
            {NightMeasurementCode ? (
              <StandardInput
                value={NightMeasurementCode}
                readOnly={true}
                label={t(`${baseName}.nightly-meter`)}
                disabled
              />
            ) : null}
          </section>
        </>
      ) : null}
    </>
  );
};

export default MyMetersWrapperElement;
