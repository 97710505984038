import React from 'react';

import styles from './AvatarInitials.module.scss';

const AvatarInitials = ({ name, lastname, filled, isLoyaltyCustomer }) => {
  const classBase = isLoyaltyCustomer ? 'figureLoyalty' : 'figure';

  return (
    <figure
      className={
        filled ? styles[`${classBase}-filled`] : styles[`${classBase}-empty`]
      }
    >
      <span className={styles.span}>
        {`${name?.trim().substring(0, 1)}${lastname?.trim().substring(0, 1)}`}
      </span>
    </figure>
  );
};

export default AvatarInitials;
