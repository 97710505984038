import { useCmsContent } from './useCmsContent';

const useBenefit = (id, language, query, skip = false) => {
  const { data, loading, error } = useCmsContent(
    id,
    language,
    skip,
    '[schema]',
    query
  );

  const result = loading || !data?.data?.length ? null : data?.data[0];

  return {
    loading,
    error,
    data: result,
  };
};
export { useBenefit };
