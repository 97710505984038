import useData from 'data/useData';
import { useAuthUser } from 'utils/AuthUser';
import { mergeMeters } from 'utils/product/meterUtils';
//import { testMeters as data } from 'data/testData/homeBillCards';
//import { testMeters as data } from 'data/testData/consumptions';
import {
  meterStatusesElectricity,
  meterStatusesGas,
} from 'utils/product/enums/meterStatuses';

export default function useAccountMeters({
  statusesElectricity = [meterStatusesElectricity.active],
  statusesGas = [meterStatusesGas.active],
  onlyActive = false,
} = {}) {
  const { accountId } = useAuthUser();
  const { data, error, loading, mutate } = useData(
    `/account/meters?accountId=${accountId}&onlyActive=${onlyActive}`
  );

  const mergedMeters = mergeMeters(data, statusesElectricity, statusesGas);

  return {
    meters: mergedMeters,
    loading,
    error,
    mutate,
    data,
  };
}
