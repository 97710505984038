import React from 'react';
import { useTranslation } from 'react-i18next';

import { PromptDialog } from 'components/PromptDialogs/PromptDialog';

const RegisterEmailModal = ({
  open = true,
  closeDialog,
  handleButtonClick,
  email,
  yesLoading = false,
  noLoading = false,
}) => {
  const { t } = useTranslation(['onboarding', 'translation']);

  return (
    <PromptDialog
      open={open}
      closeDialog={closeDialog}
      message={t(`onboarding:createUserContent.question-modal`, {
        email: email,
      })}
      primaryActionHandler={() => handleButtonClick(true)}
      primaryActionLoading={yesLoading}
      showSecondaryAction={true}
      secondaryActionText={t(`translation:no`)}
      secondaryActionHandler={() => handleButtonClick(false)}
      secondaryActionLoading={noLoading}
      showCloseButton={false}
    />
  );
};

export default RegisterEmailModal;
