import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { addDays } from 'utils/dateUtils';
import { DatePicker } from 'components/Inputs/DatePicker';

import styles from './MovingDate.module.scss';

const MovingDate = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const { t } = useTranslation(['requests']);
  const [selectedDate, setSelectedDate] = useState(null);

  const now = new Date();
  return (
    <article className={styles.container}>
      <h3 className={styles.title}>{t(`requests:moving.moving-date-title`)}</h3>
      <DatePicker
        label={t('requests:moving.date')}
        value={selectedDate}
        onChange={setSelectedDate}
        minDate={addDays(now, 1)}
        errors={errors}
        //register={register}
        required={true}
        control={control}
        name="movingDate"
        disableToolbar={false}
        customToolbar={true}
      />
    </article>
  );
};

export default MovingDate;
