import React from 'react';
import { useTranslation } from 'react-i18next';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import Widget from 'components/Widget/Widget';
import EnergyPointsWidget from 'components/Loyalty/EnergyPointsWidget';
import styles from './RewardsWidget.module.scss';

const RewardsWidget = ({
  loading,
  points,
  children,
  title,
  show = true,
  heightInPixels = 136,
  distance = 'xs',
  ...rest
}) => {
  const { t } = useTranslation(['bills', 'translation']);

  return (
    <WidgetSkeleton
      show={loading}
      background="selected"
      distance={distance}
      heightInPixels={heightInPixels}
      containerClass={styles.pointsContainer}
    >
      {show && (
        <Widget
          widgetClass={styles.pointsWidget}
          containerClass={styles.pointsContainer}
          background="selected"
          distance={distance}
        >
          <h3>{title ? title : t(`loyalty:register.success.winTitle`)}</h3>
          {points > 0 && (
            <EnergyPointsWidget
              points={points}
              distance="xxs"
              containerClass={styles.totalPointsWidget}
              {...rest}
            />
          )}
          {children}
        </Widget>
      )}
    </WidgetSkeleton>
  );
};

export default RewardsWidget;
