import { useAuthUser } from 'utils/AuthUser';
import useData from './useData';
import { mergePayments } from 'utils/product/enaUtils';

export default function useSolarisPaymentHistory(skip = false) {
  const { accountId } = useAuthUser();

  const { data, error, loading } = useData(
    skip ? null : `/solaris/paymentHistory?accountId=${accountId}`,
  );
  const payments = mergePayments(data);

  return {
    loading,
    error,
    payments,
    data: data?.solarisMeterPaymentHistories ?? [],
  };
}
