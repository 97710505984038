import React from 'react';
import { useTranslation } from 'react-i18next';

import WidgetPrice from 'components/WidgetPrice/WidgetPrice';
import StrikedAmount from 'components/StrikedAmount/StrikedAmount';
import InvoiceTypeLabel from 'components/InvoiceTypeLabel/InvoiceTypeLabel';

const BillAmounts = ({
  amount,
  strikedAmount,
  containerClass = '',
  descriptionClass = '',
  invoice,
  type,
}) => {
  const { t } = useTranslation(['bills', 'consumptions']);
  const showStrikedAmount = strikedAmount && strikedAmount !== amount;

  const billAmountDescription = (() => {
    switch (type) {
      case 'lastInvoice':
        return <p>{t('bill-balance')}</p>;
      case 'analysis':
        return <p>{t('bills:current-bill')}</p>;
      case 'invoiceType':
        return <InvoiceTypeLabel invoiceType={invoice.invoiceType} />;

      default:
        return <p>{t('full-sum')}</p>;
    }
  })();

  return (
    <div className={containerClass}>
      {!!showStrikedAmount && <StrikedAmount number={strikedAmount} />}
      <WidgetPrice number={amount} type={showStrikedAmount ? 'm' : 'b'} />
      <div className={descriptionClass}>{billAmountDescription}</div>
    </div>
  );
};

export default BillAmounts;
