import React from 'react';

import EnergyReports from './EnergyReports';
// import TelemetryDetails from './TelemetryDetails';

const MVConsumption = ({ meter, loading }) => {
  return (
    <>
      <EnergyReports meter={meter} />
      {/* <TelemetryDetails meter={meter} loading={loading} /> */}
    </>
  );
};

export default MVConsumption;
