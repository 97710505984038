import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AppleWalletIconGR } from 'assets/icons/AppleWalletIconGR.svg';
import { ReactComponent as AppleWalletIconEN } from 'assets/icons/AppleWalletIconEN.svg';
import { downloadApplePass } from 'api/loyalty';
import { saveFile } from 'utils/fileUtils';

import styles from './AppleWallet.module.scss';

const AppleWalletIcon = ({ ...rest }) => {
  const { i18n } = useTranslation();

  return i18n.language === 'el' ? (
    <AppleWalletIconGR {...rest} />
  ) : (
    <AppleWalletIconEN {...rest} />
  );
};

const AppleWallet = ({ couponCode, customerId }) => {
  return (
    <AppleWalletIcon
      alt="Add to Apple Wallet"
      className={styles['addToWallet-button']}
      onClick={() => {
        downloadApplePass(couponCode, customerId)
          .then(({ data }) => {
            const fileType = 'application/vnd.apple.pkpass';
            const fileName = 'heron_coupon.pkpass';
            saveFile(data, fileName, fileType);
          })
          .catch((err) => {});
      }}
    />
  );
};

export default AppleWallet;
