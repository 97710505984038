import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import Loader from 'components/Skeletons/Loader';

import { addressInfoText } from 'utils/product/meterUtils';
import { TextInput } from 'components/Inputs/TextInput';
import useLocalizedFriendlyName from 'hooks/useLocalizedFriendlyName';
import { HiddenFormInput } from 'components/Inputs/HiddenFormInput';
import MetersListDialogSelect from 'components/RequestsHome/MetersListDialogSelect';

import styles from './MeterData.module.scss';

const MeterData = ({ meters, loading = false, defaultMeter }) => {
  const { t } = useTranslation(['requests']);
  const { getFriendlyName } = useLocalizedFriendlyName();

  const { register, setValue } = useFormContext();
  const [currentMeterId, setCurrentMeterId] = useState(
    defaultMeter ? `${defaultMeter.Id}` : ''
  );
  const [meterAddress, setMeterAddress] = useState(
    defaultMeter ? addressInfoText(defaultMeter?.AddressInfo) : ''
  );
  const getMeterName = (meter) => {
    return meter && meter.FriendlyName
      ? `${getFriendlyName(meter.FriendlyName)} - ${meter.MeterNo}`
      : meter?.MeterNo;
  };
  const handleChange = (meterId) => {
    setValue('meterId', meterId);
    setCurrentMeterId(meterId);

    const meter = meters.find((x) => x.Id === meterId);

    const address = addressInfoText(meter?.AddressInfo);
    setMeterAddress(address);
    setValue('meterAddress', address);
    setValue('meterAddressIgnored', address);

    const meterName = getMeterName(meter);
    setValue('meterName', meterName);
  };

  return (
    <article className={styles.container}>
      <h3 className={styles.title}>{t(`requests:billing.meter-details`)}</h3>
      <Loader show={loading} height={108}>
        <>
          <MetersListDialogSelect
            value={currentMeterId}
            meters={meters}
            handleChange={(e) => handleChange(e.target.value)}
            label={t('requests:billing.meter')}
          />
          <HiddenFormInput
            name={'meterName'}
            register={register}
            defaultValue={getMeterName(defaultMeter)}
          />
          <HiddenFormInput
            name={'meterAddress'}
            register={register}
            defaultValue={
              defaultMeter
                ? addressInfoText(defaultMeter?.AddressInfo)
                : meterAddress
            }
          />
          <TextInput
            name="meterAddressIgnored"
            label={t(`requests:billing.meter-address`)}
            value={
              defaultMeter
                ? addressInfoText(defaultMeter?.AddressInfo)
                : meterAddress
            }
            disabled={currentMeterId != null}
            required={false}
          />
        </>
      </Loader>
    </article>
  );
};

export default MeterData;
