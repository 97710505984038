import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { isEmptyString } from 'utils/stringUtils';
import styles from './EbillSuccessMessage.module.scss';

export const EbillSuccessMessage = ({ isEdit = false, email, phone }) => {
  const { t } = useTranslation(['translation', 'bills', 'management']);
  if (isEdit)
    return <p>{t('management:personalInfo.submit-change-success')}</p>;

  if (isEmptyString(email) && isEmptyString(phone)) {
    return <p>{t('management:ebill.success-activation-message')}</p>;
  }

  return (
    <>
      <p>
        <Trans
          i18nKey={'management:ebill.success-activation-message-with-details'}
          values={{
            context: !isEmptyString(email) && !isEmptyString(phone) ? 2 : 1,
            count: !isEmptyString(email) && !isEmptyString(phone) ? 2 : 1,
          }}
        />
      </p>
      <section className={styles['contact-details']}>
        <p>{email}</p>
        <p>{phone}</p>
      </section>
      <p>{t('management:ebill.success-activation-message-submessage')}</p>
    </>
  );
};
