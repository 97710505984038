import React from 'react';

import RadioButton from 'components/RadioButtonsGroup/RadioButton';
import { ReactComponent as MasterCard } from 'assets/icons/MasterCardIcon.svg';
import { ReactComponent as Visa } from 'assets/icons/VisaIcon.svg';
import { ReactComponent as MaestroCard } from 'assets/icons/MaestroCardIcon.svg';
import { cardNumberAdjustSpacing } from 'utils/numberUtils';
import { creditCardTypes } from 'utils/product/enums/paymentCardEnums';

import styles from './PaymentCardSelectContent.module.scss';

const PaymentCardSelectContent = ({
  friendlyName,
  creditCardType,
  number,
  radioButtonRegister = () => {},
  radioButtonValue,
  radioButtonName,
}) => {
  return (
    <>
      <RadioButton
        register={radioButtonRegister}
        radioButtonValue={radioButtonValue}
        name={radioButtonName}
      />
      <div className={styles.container}>
        {friendlyName && <p>{friendlyName}</p>}
        <p>{cardNumberAdjustSpacing(number)}</p>
      </div>
      {creditCardType === creditCardTypes.MASTERCARD && (
        <MasterCard alt="mastercard" />
      )}
      {creditCardType === creditCardTypes.VISA && <Visa alt="visa" />}
      {creditCardType === creditCardTypes.MAESTRO && (
        <MaestroCard alt="maestro" />
      )}
    </>
  );
};

export default PaymentCardSelectContent;
