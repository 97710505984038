import api from './api';

const setAccountPrimaryContact = (email, accountId) => {
  return api.post('/account/primaryContactEmail', {
    Email: email,
    AccountId: accountId,
  });
};

const setAccountEbilling = (ebill, commodity, accountId) => {
  return api.get(
    `/account/ebilling?ebill=${ebill}&commodity=${commodity}&accountId=${accountId}`
  );
};

const setContactInfo = (contactInfo) => {
  return api.post('/account/update', contactInfo);
};

const setContactToMeter = (contactInfo) => {
  return api.post('/contact/updateAndAssigntoMeter', contactInfo);
};

const getEnergyReportsToken = (accountId) => {
  return api.get(`/account/cordia?accountId=${accountId}`);
};

const getAccountInfo = (accountId) => {
  return api.get(`/account?accountId=${accountId}`);
};

const setAccountDeletion = (accountId) => {
  return api.post(`/user/deleteUserAccount?accountId=${accountId}`);
};

export {
  setAccountPrimaryContact,
  setAccountEbilling,
  setContactInfo,
  getEnergyReportsToken,
  getAccountInfo,
  setAccountDeletion,
  setContactToMeter,
};
