import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPageview } from 'utils/analytics';

const usePageviews = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageview(location.pathname);
  }, [location]);
};

export default usePageviews;
