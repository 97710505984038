import useSWRImmutable from 'swr';
import { fetcher } from 'api/wave';
import endpoints from 'utils/product/enums/endpoints';

const useLoyaltyUser = (customerId) => {
  const { data, error, mutate } = useSWRImmutable(
    !customerId
      ? null
      : `${endpoints.loyalty.customer}/?customerId=${customerId}`,
    fetcher,
  );

  //specifically check for undefined because for non-loyalty user data will equal to null
  const loading = data === undefined && !error;

  return {
    loading,
    error,
    data,
    mutate,
  };
};

export default useLoyaltyUser;
