import React from 'react';
import { useTranslation } from 'react-i18next';

import { trackConsumptionEvent } from 'utils/product/tracking/events';
import { getTrackingConsumptionMeterType } from 'utils/product/tracking/trackingLabels';
import { consumptionForecastSpan } from 'utils/product/config';

import IconPopover from 'components/Popover/IconPopover';
import { ReactComponent as InfoIconFilled } from 'assets/icons/InfoCircleIcon.svg';
import WattContainer from 'components/WattContainer/WattContainer';

import styles from './ConsumptionDetailElements.module.scss';

const ConsumptionDetailsField = ({
  content,
  icon,
  value,
  weight = 'regular',
}) => {
  return (
    <div className={styles[`container-${weight}`]}>
      <div className={styles.content}>{content}</div>
      {icon}
      <div className={styles.value}>{value}</div>
    </div>
  );
};

const ConsumptionDivider = () => {
  return <hr className={styles.divider} />;
};

const ConsumptionMeasurement = ({ type, value }) => {
  const { t } = useTranslation(['consumptions']);

  return (
    <ConsumptionDetailsField
      weight="bold"
      content={t(`consumptions:overview.latest-consumption-${type}`)}
      value={<WattContainer amount={value} size="m" weight="bold" />}
    />
  );
};

const ConsumptionForecast = ({
  meterId,
  consumptionPerDay,
  consumptionEstimation,
  consumptionTotal,
  duration,
  type = 'default',
}) => {
  const { t } = useTranslation(['consumptions']);

  const explanation = t(`consumptions:overview.forecast-explanation`, {
    days: duration,
    forecastConsumption: consumptionEstimation,
    dailyConsumption: consumptionPerDay,
    totalConsumption: consumptionTotal,
    forecastDays: consumptionForecastSpan,
  });
  return (
    <ConsumptionDetailsField
      weight="bold"
      content={t(`consumptions:overview.forecast-${type}`)}
      value={
        <WattContainer amount={consumptionEstimation} size="m" weight="bold" />
      }
      icon={
        <IconPopover
          id={`meter-${meterId}-popover`}
          content={explanation}
          icon={<InfoIconFilled />}
          popoverClass={styles.popover}
          placement="bottom-start"
          buttonClass={styles.infoButton}
          clickHandler={() => {
            trackConsumptionEvent(
              'consumption_analysis_info_selected',
              getTrackingConsumptionMeterType(type)
            );
          }}
        />
      }
    />
  );
};

const ConsumptionClearingDetails = ({ duration, consumptionTotal, type }) => {
  const { t } = useTranslation(['consumptions', 'translation']);

  return (
    <>
      <ConsumptionDetailsField
        content={t(`consumptions:overview.clearing-consumption-${type}`)}
        value={<WattContainer amount={consumptionTotal} size="m" />}
      />
      <ConsumptionDetailsField
        content={t(`consumptions:overview.consumption-days`)}
        value={`${duration} ${t(`translation:daysAbbr`)}`}
      />
    </>
  );
};

export {
  ConsumptionMeasurement,
  ConsumptionForecast,
  ConsumptionClearingDetails,
  ConsumptionDivider,
};
