import React from 'react';
import { useTranslation } from 'react-i18next';

import useUniqueFlow from 'hooks/useUniqueFlow';
import useDialog from 'hooks/useDialog';
import { trackAcceptTermsEvent } from 'utils/product/tracking/events';
import Terms from 'components/OnboardingNewClient/Shared/Terms';
import { PromptDialog } from 'components/PromptDialogs/PromptDialog';

import styles from './TermsDialog.module.scss';

const TermsDialog = () => {
  const { t } = useTranslation(['translation', 'onboarding']);
  const { incrementStep } = useUniqueFlow();

  const termsDialog = useDialog(true);

  return (
    <PromptDialog
      open={termsDialog.isOpen}
      closeDialog={termsDialog.close}
      dialogHeader={t('onboarding:terms-dialog.title')}
      message={t('onboarding:terms-dialog.message')}
      showCloseButton={false}
      primaryActionText={t('translation:continue')}
      primaryActionHandler={() => {
        trackAcceptTermsEvent('login_accept_terms');
        termsDialog.close();
        incrementStep();
      }}
      alignActions="right"
    >
      <Terms containerClass={styles.terms} />
    </PromptDialog>
  );
};

export default TermsDialog;
