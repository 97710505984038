import commodities from 'utils/product/enums/commodities';
import { meterStatusesElectricity } from 'utils/product/enums/meterStatuses';
import {
  channelElementTypes,
  channelCategoryTypes,
  channelSubCategoryTypes,
  requestCasesStatuses,
  requestStatuses,
} from './enums/requests';
import { isAfter, isEqual } from 'utils/dateUtils';

export const getMetersForRepresentationCertificate = (meters) => {
  if (!meters) return [];
  return meters?.filter(
    (meter) =>
      meter.Commodity === commodities.energy &&
      meter.MeterStatus === meterStatusesElectricity.active,
  );
};

export const getMetersForTerminationCertificate = (meters) => {
  if (!meters) return [];
  return meters?.filter(
    (meter) =>
      meter.Commodity === commodities.energy &&
      meter.MeterStatus === meterStatusesElectricity.terminated,
  );
};

export const findObjectionRequestFromChannels = (requests) => {
  if (!requests) return null;

  const validFromObjectionDate = '2023-11-30T10:00:00.000Z';
  const elementTypes = Object.values(channelElementTypes);

  const isAfterOrEqual = (data, dateToCompare) =>
    isAfter(data, dateToCompare) || isEqual(data, dateToCompare);

  const activeRequestCondition = (request) =>
    request?.Category === channelCategoryTypes?.contract &&
    request?.Subcategory === channelSubCategoryTypes?.newMeasures &&
    !elementTypes?.includes(request?.Element);

  const resolvedRequestCondition = (request) =>
    request?.Category === channelCategoryTypes?.changePackageRequest &&
    (isAfterOrEqual(request?.NewPackageValidFrom, validFromObjectionDate) ||
      isAfterOrEqual(request?.Createdon, validFromObjectionDate));

  const objectionRequest = requests?.find(
    (request) =>
      activeRequestCondition(request) || resolvedRequestCondition(request),
  );

  return objectionRequest;
};

const historyCasesMapper = {
  [requestCasesStatuses.pending]: requestStatuses.draft,
  [requestCasesStatuses.completed]: requestStatuses.completed,
  [requestCasesStatuses.canceled]: requestStatuses.canceled,
};

export const transformHistoryCasesStatusesToRequests = (historyCases) => {
  return historyCases?.map((historyCase) => ({
    ...historyCase,
    RequestStatus: historyCasesMapper[historyCase?.RequestStatus],
  }));
};
