import React from 'react';
import { useTranslation } from 'react-i18next';

import ConsumptionsOverview from 'components/Consumptions/Home/ConsumptionsOverview';
import ThreeColumnsLayout from 'layouts/ThreeColumnsLayout';

const ConsumptionsDetailedList = () => {
  const { t } = useTranslation(['homepage']);
  return (
    <ThreeColumnsLayout
      head={{
        title: t('homepage:my-consumptions.title'),
        metaTags: [
          { name: 'title', content: t('homepage:my-consumptions.title') },
        ],
      }}
      main={<ConsumptionsOverview detailed={true} />}
    />
  );
};

export default ConsumptionsDetailedList;
