import React from 'react';

import MeterLabelWrapper from 'components/Labels/MeterLabelWrapper';
import Widget from 'components/Widget/Widget';
import { EarnPointsLabel } from 'components/Loyalty/LoyaltyLabels';
import { useAuthUser } from 'utils/AuthUser';
import { getConsumptionState } from 'utils/product/consumptionUtils';
import consumptionStates from 'utils/product/enums/consumptionStates';
import { getPointsOfCategory } from 'utils/product/loyalty/pointsUtils';
import { collectionOptionTypes } from 'utils/product/enums/cmsEnums';
import { useCollectionOptions } from 'data/useCollectionOptions';
import LabelsWrapper from 'components/LabelsWrapper/LabelsWrapper';

import styles from './ConsumptionWidget.module.scss';

const ConsumptionWidget = ({ meter, children }) => {
  const { Id, MeterNo, FriendlyName } = meter;
  const { isLoyaltyCustomer } = useAuthUser();
  const state = getConsumptionState(meter);

  const { collectionOptionsByType } = useCollectionOptions();
  const registerConsumptionPoints = getPointsOfCategory(
    collectionOptionsByType,
    collectionOptionTypes.REGISTER_CONSUMPTION
  );

  return (
    <Widget key={Id} widgetClass={styles['consumption-widget']} distance="xs">
      <LabelsWrapper>
        <MeterLabelWrapper
          size="m"
          type={meter.type}
          friendlyName={FriendlyName}
          meter={meter}
          meterNo={MeterNo}
          labelClass={styles.meterLabel}
        />
        {isLoyaltyCustomer && state === consumptionStates.register && (
          <EarnPointsLabel points={registerConsumptionPoints} />
        )}
      </LabelsWrapper>
      {children}
    </Widget>
  );
};

export default ConsumptionWidget;
