import { getObjectKeys } from 'utils/objectUtils';
import { loyaltyPaths } from './loyalty/config';

const uniqueFlowsNames = {
  newClient: 'flow_new',
  existingClient: 'flow_shop',
  existingUser: 'flow_existing',
};

const basePath = `/e-contract`;
const newClientFlow = {
  whatsNew: `${basePath}/whats-new`,
  energyType: `${basePath}/select-energy`,
  programType: `${basePath}/select-program`,
  shared: `${basePath}/select-gas-supply`,
  energyShop: `${basePath}/electricity-programs`,
  energyProgram: `${basePath}/electricity-program`,
  gasShop: `${basePath}/gas-programs`,
  gasProgram: `${basePath}/gas-program`,
  customerCategory: `${basePath}/customer-category`,
  businessCustomerCategory: `${basePath}/business-category`,
  electricity: `${basePath}/electricity-supply`,
  gas: `${basePath}/gas-supply`,
  newGasSupply: `${basePath}/gas-supply-request`,
  newGasSupplySuccess: `${basePath}/gas-supply-request/success`,
  login: `/login`,
  electricityInfo: `${basePath}/electricity-info`,
  gasInfo: `${basePath}/gas-info`,
  combinedInfo: `${basePath}/combined-info`,
  customerDetails: `${basePath}/customer-details`,
  companyDetails: `${basePath}/company-details`,
  primaryContact: `${basePath}/primary-contact`,
  meterDetails: `${basePath}/electricity-meter`,
  gasMeterDetails: `${basePath}/gas-meter`,
  userCapacity: `${basePath}/gas-user`,
  eBill: `${basePath}/e-bill`,
  gasEBill: `${basePath}/gas-e-bill`,
  contactPreferences: `${basePath}/contact-preferences`,
  identification: `${basePath}/identification`,
  documents: `${basePath}/documents`,
  // completed: `${basePath}/success`,
};

const baseShopPath = ``;
const existingClientFlow = {
  energyShop: `${baseShopPath}/electricity-programs`,
  energyProgram: `${baseShopPath}/electricity-program`,
  electricity: `${baseShopPath}/electricity-supply`,
  gas: `${baseShopPath}/gas-supply`,
  newGasSupply: `${baseShopPath}/gas-supply-request`,
  electricitySupply: `${baseShopPath}/electricity-request`,
  newGasSupplySuccess: `${baseShopPath}/gas-supply-request/success`,
  login: `/shop`,
  electricityInfo: `${baseShopPath}/electricity-info`,
  gasInfo: `${baseShopPath}/gas-info`,
  combinedInfo: `${baseShopPath}/combined-info`,
  customerDetails: `${baseShopPath}/customer-details`,
  companyDetails: `${baseShopPath}/company-details`,
  primaryContact: `${baseShopPath}/primary-contact`,
  meterDetails: `${baseShopPath}/electricity-meter`,
  gasMeterDetails: `${baseShopPath}/gas-meter`,
  userCapacity: `${baseShopPath}/gas-user`,
  eBill: `${baseShopPath}/e-bill`,
  gasEBill: `${baseShopPath}/gas-e-bill`,
  identification: `${baseShopPath}/identification`,
  documents: `${baseShopPath}/documents`,
};

const newClientSteps = getObjectKeys(newClientFlow).reduce(function (
  accumulator,
  current,
  index
) {
  accumulator[current] = index;
  return accumulator;
},
{});

const existingClientSteps = getObjectKeys(existingClientFlow).reduce(function (
  accumulator,
  current,
  index
) {
  accumulator[current] = index;
  return accumulator;
},
{});

export {
  uniqueFlowsNames,
  newClientSteps,
  newClientFlow,
  existingClientFlow,
  existingClientSteps,
};

const config = {
  [uniqueFlowsNames.existingUser]: {
    states: [
      { stepLabel: 'whatsNew', path: '/whats-new' },
      { stepLabel: 'terms', path: '/web-app-terms' },
      { stepLabel: 'meterNaming', path: '/meter-naming' },
      { stepLabel: 'howToWizard', path: '/how-to-wizard' },
      {
        stepLabel: 'loyaltyPrompt',
        path: `/loyalty/${loyaltyPaths.prompt}`,
      },
      { stepLabel: 'homepage', path: '/' },
    ],
  },

  [uniqueFlowsNames.newClient]: {
    states: [
      ...getObjectKeys(newClientFlow).map((x) => {
        return { stepLabel: x, path: newClientFlow[x] };
      }),
    ],
  },
  [uniqueFlowsNames.existingClient]: {
    states: [
      ...getObjectKeys(existingClientFlow).map((x) => {
        return { stepLabel: x, path: existingClientFlow[x] };
      }),
    ],
  },
};

export default config;
