import { getDiscoveryItem } from 'utils/product/offerUtils';
import { useAuthUser } from 'utils/AuthUser';
import { useVibesConfig } from './useVibesConfig';
import { useForYouSchema } from './useForYouSchema';

const useDiscoveryItem = (slug, language) => {
  const { isLoyaltyCustomer } = useAuthUser();

  const {
    data: discoveryItems,
    loading: discoveryLoading,
    error,
  } = useForYouSchema(language);

  const {
    data: vibes,
    loading: vibesLoading,
    error: vibesError,
  } = useVibesConfig(language);

  const loading = discoveryLoading || vibesLoading;

  const result = loading
    ? undefined
    : getDiscoveryItem(slug, discoveryItems, isLoyaltyCustomer, vibes);

  return {
    loading: loading,
    error: error || vibesError,
    data: result,
  };
};
export { useDiscoveryItem };
