import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { ThemeProvider } from '@material-ui/styles';
import { StylesProvider } from '@material-ui/core/styles';
import { initializeTracking } from 'utils/analytics';
import { initializeFbPixel } from 'utils/fbPixel';
import { initializeAdTracking } from 'utils/googleAds';
import { initializeLanguage } from 'utils/localizationUtils';
import { initializeChatScript } from 'utils/product/chatUtils';

import { theme } from './utils/materialTheme';
import Router from './utils/Router';
import { AuthUserProvider } from './utils/AuthUser';
import { AppContextProvider } from 'utils/AppContext';
import GenericLoader from 'components/GenericLoader/GenericLoader';

import Tracking from 'components/Tracking/Tracking';
import Helmet from 'utils/Helmet';
import SWRConfig from 'utils/product/SWRConfig';

import './utils/i18n';

// Load manrope font
import '@fontsource/manrope/400.css';
import '@fontsource/manrope/700.css';
import './styles/index.scss';

import * as serviceWorker from './serviceWorker';

process.env.REACT_APP_ENV !== 'development' &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
    environment: process.env.REACT_APP_ENV || process.env.NODE_ENV,
    ignoreErrors: [
      'Non-Error promise rejection captured',
      'Request aborted',
      'Error: Network Error',
      'TypeError: Cancelled',
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: Cannot read properties of undefined (reading "_avast_submit")',
      'AxiosError: Request failed with status code 401',
      'AxiosError: Request failed with status code 500',
      'AxiosError: Request failed with status code 503',
    ],
  });

initializeTracking();
initializeFbPixel();
initializeAdTracking();
initializeLanguage();
initializeChatScript();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <SWRConfig>
      <Suspense fallback={<GenericLoader />}>
        <StylesProvider injectFirst>
          <ThemeProvider theme={theme}>
            <AuthUserProvider>
              <AppContextProvider>
                <Router>
                  {process.env.REACT_APP_ENV !== 'production' && (
                    <Helmet
                      head={{
                        metaTags: [{ name: 'robots', content: 'noindex' }],
                      }}
                    />
                  )}
                  <Tracking />
                </Router>
              </AppContextProvider>
            </AuthUserProvider>
          </ThemeProvider>
        </StylesProvider>
      </Suspense>
    </SWRConfig>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
