import React from 'react';
import Helmet from 'utils/Helmet';

import styles from './SingleColumnLayout.module.scss';

const SingleColumnLayout = ({
  children,
  head,
  containerClass = '',
  mainClass = '',
}) => {
  return (
    <>
      <Helmet head={head} />
      <section className={`${styles.container} ${containerClass}`}>
        <main className={`${styles.column} ${mainClass}`}>{children}</main>
      </section>
    </>
  );
};

export default SingleColumnLayout;
