import React from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { StandardInput } from './Inputs';
import { validateEmail } from 'utils/validators';
import { isEmptyString } from 'utils/stringUtils';

const EmailInput = ({
  register = () => {},
  errors = {},
  label,
  name = 'email',
  required = true,
  ...rest
}) => {
  const { t } = useTranslation(['inputs']);
  const baseName = 'inputs:errors';
  const { ref, ...registerRest } =
    register(name, {
      required: { value: required, message: t(`${baseName}.required`) },
      validate: {
        emailValidity: (v) =>
          (!required && isEmptyString(v)) ||
          validateEmail(v) ||
          t(`${baseName}.email`),
      },
    }) || {};

  return (
    <>
      <StandardInput
        inputRef={ref}
        label={label}
        type="email"
        errorMessage={get(errors, `${name}.message`, null)}
        error={get(errors, `${name}`, null) !== null}
        {...rest}
        {...registerRest}
      />
    </>
  );
};

export { EmailInput };
