import useSWR from 'swr';
import { fetcher } from 'api/wave';
import endpoints from 'utils/product/enums/endpoints';

const useLoyaltyNotificationsStatus = (loyaltyCustomerId) => {
  const { data, error, loading, mutate } = useSWR(
    !loyaltyCustomerId
      ? null
      : `${endpoints.loyalty.notificationsStatus}?deliveryMethod=IN_APP&customerId=${loyaltyCustomerId}`,
    fetcher,
    { dedupingInterval: 60000 }
  );

  return {
    loading,
    error,
    hasUnviewedNotifications: data?.hasUnviewedNotifications === true,
    mutate,
  };
};

export default useLoyaltyNotificationsStatus;
