import { conventFromCents } from 'utils/moneyUtils';
import { benefitTypes } from 'utils/product/enums/waveEnums';
import { useCmsContent } from './useCmsContent';

const useCollectionOptions = (language) => {
  const { data, loading, error } = useCmsContent(
    'collection_options',
    language
  );

  const collectionOptions =
    data && data?.data?.length > 0
      ? data?.data.map((x) => ({
          ...x.content,
          id: x?.id,
          image: x?.content?.image?.image,
          benefits: x?.content.benefits
            ? x?.content.benefits?.map((benefit) => ({
                ...benefit,
                value:
                  benefit.type === benefitTypes.REWARD_SCHEME_CURRENCY_FIXED
                    ? conventFromCents(benefit.value)
                    : benefit.value,
              }))
            : [],
        }))
      : [];

  const eventTypeIds = collectionOptions.reduce((previous, current) => {
    previous[current.type] = current.waveId;
    return previous;
  }, {});

  return {
    loading,
    error,
    data: collectionOptions,
    eventTypeIds,
    collectionOptionsByType: collectionOptions.reduce((previous, current) => {
      previous[current.type] = current;
      return previous;
    }, {}),
  };
};

export { useCollectionOptions };
