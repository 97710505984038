import React from 'react';
import { useTranslation } from 'react-i18next';

import Widget from 'components/Widget/Widget';
import { ReactComponent as BankIcon } from 'assets/icons/BankIcon.svg';
import IconText from 'components/IconText/IconText';

import styles from './StandingOrderBankActivatedContent.module.scss';

const StandingOrderBankActivatedContent = () => {
  const { t } = useTranslation(['management', 'translation']);
  const baseName = 'management:standing-order';

  return (
    <>
      <Widget size="s" containerClass={styles.widgetBank} background="selected">
        <IconText
          Icon={<BankIcon />}
          content={t(`${baseName}.edit.bank-info-message`)}
          gap="s"
        />
      </Widget>
      <p className={styles.bankDisclaimer}>
        {t(`${baseName}.edit.bank-info-disclaimer`)}
      </p>
    </>
  );
};

export default StandingOrderBankActivatedContent;
