import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as RightArrow } from 'assets/icons/ArrowRightIcon.svg';
import { NoStylesButton } from 'components/Buttons/Buttons';

import styles from './RightArrowLinkElement.module.scss';

const RightArrowLinkElement = ({ message, description, link, onClick }) => {
  return link ? (
    <Link
      to={link}
      className={styles.link}
      aria-label={message}
      onClick={onClick}
    >
      <section className={styles.section}>
        {message} <RightArrow />
      </section>
      {description ? (
        <section className={styles.description}>{description}</section>
      ) : null}
    </Link>
  ) : (
    <NoStylesButton onClick={onClick} extraClasses={styles.button}>
      {message} <RightArrow />
    </NoStylesButton>
  );
};

export default RightArrowLinkElement;
