import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageStorage from 'utils/storage/languageStorage';

import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: languageStorage.getLanguage(),
    fallbackLng: false,
    supportedLngs: ['el', 'en'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      requestOptions: {
        cache: 'no-store',
      },
    },
  });

export default i18n;
