import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import Big from 'big.js';

import useDialog from 'hooks/useDialog';
import RewardDialog from 'components/Loyalty/RewardDialog';
import {
  findAlreadyActivatedEbillWithoutReward,
  getLengthOfMetersEligibleForEbill,
  getIdsOfAlreadyActivatedEbillWithoutReward,
} from 'utils/product/loyalty/ebillUtils';
import { getRewardPoints } from 'utils/product/loyalty/pointsUtils';
import { ReactComponent as Tree } from 'assets/icons/EbillLoyaltyTree.svg';
import { receivePoints } from 'api/loyalty';
import { trackEbillEvent } from 'utils/product/tracking/loyaltyEvents';
import { mutateLoyaltyHistory } from 'data/useLoyaltyHistory';
import { mutateLoyaltyPoints } from 'data/useRewardSchemeAccounts';

import styles from './RewardAlreadyActivatedEbillsDialog.module.scss';

const RewardAlreadyActivatedEbillsDialog = ({
  meters,
  historyData,
  customerId,
  waveId,
  ebillPoints,
}) => {
  const rewardDialog = useDialog(true);
  const [rewardPoints, setRewardPoints] = useState();
  const [closeDialog, setCloseDialog] = useState(false);
  const [rewardedEbills, setRewardedEbills] = useState(0);
  const { t } = useTranslation(['loyalty']);
  const oneTimeOnly = useRef(false);
  const enrichedMeters = findAlreadyActivatedEbillWithoutReward(
    meters,
    historyData,
  );

  useEffect(() => {
    if (!oneTimeOnly.current) {
      oneTimeOnly.current = true;
      const items = getIdsOfAlreadyActivatedEbillWithoutReward(enrichedMeters);
      if (items?.length > 0) {
        const postData = {
          customer: {
            customerId: customerId,
          },
          type: 'CUSTOM',
          ruleId: waveId,
          items: [...items],
        };
        receivePoints(postData).then((loyaltyResponse) => {
          mutateLoyaltyHistory(customerId);
          mutateLoyaltyPoints(customerId);
          const pointsEarned = getRewardPoints(loyaltyResponse?.data, waveId);
          setRewardedEbills(new Big(pointsEarned).div(ebillPoints).toNumber());
          setRewardPoints(pointsEarned);
          setCloseDialog(true);
          trackEbillEvent('ebill_already_activated');
        });
      }
    }
  }, [customerId, ebillPoints, enrichedMeters, waveId]);

  return (
    closeDialog && (
      <RewardDialog
        open={rewardDialog.isOpen}
        closeDialog={() => setCloseDialog(false)}
        points={rewardPoints}
        icon={
          getLengthOfMetersEligibleForEbill(meters) ===
          rewardedEbills ? null : (
            <Tree
              width="72"
              height="72"
              alt="success"
              className={styles.successIcon}
            />
          )
        }
        header={t(`loyalty:collection-success.E_BILL.header`)}
        message={
          <Trans
            i18nKey={
              'loyalty:collection-success.E_BILL.alreadyActivatedMessage'
            }
            values={{
              number:
                getLengthOfMetersEligibleForEbill(meters) === rewardedEbills
                  ? t(`loyalty:collection-success.E_BILL.all`)
                  : rewardedEbills,
            }}
            components={{
              text: <span />,
            }}
          />
        }
      />
    )
  );
};

export default RewardAlreadyActivatedEbillsDialog;
