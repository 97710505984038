import React from 'react';
import { useTranslation } from 'react-i18next';

import LabelValue from './LabelValue';
import { ReactComponent as MasterCard } from 'assets/icons/MasterCardIcon.svg';
import { ReactComponent as Visa } from 'assets/icons/VisaIcon.svg';
import { ReactComponent as MaestroCard } from 'assets/icons/MaestroCardIcon.svg';
import { creditCardTypes } from 'utils/product/enums/paymentCardEnums';
import { cardNumberAdjustSpacing } from 'utils/numberUtils';

import styles from './PaymentCardDetailViewContent.module.scss';

const PaymentCardDetailViewContent = ({
  showFriendlyName,
  friendlyName,
  number,
  date,
  creditCardType,
}) => {
  const { t } = useTranslation('translation');

  return (
    <>
      <div className={styles.container}>
        {showFriendlyName && (
          <LabelValue
            label={t('payment-card.friendlyName')}
            value={friendlyName || '-'}
          />
        )}
        <LabelValue
          label={t('payment-card.cardNumber')}
          value={number ? cardNumberAdjustSpacing(number) : '-'}
        />
        <LabelValue label={t('payment-card.activeUntil')} value={date} />
      </div>
      {creditCardType === creditCardTypes.MASTERCARD && (
        <MasterCard alt="mastercard" className={styles.cardIcon} />
      )}
      {creditCardType === creditCardTypes.VISA && (
        <Visa alt="visa" className={styles.cardIcon} />
      )}
      {creditCardType === creditCardTypes.MAESTRO && (
        <MaestroCard alt="maestro" className={styles.cardIcon} />
      )}
    </>
  );
};

export default PaymentCardDetailViewContent;
