import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { contestUserStatus } from 'utils/product/enums/contests';
import OfferImage from 'components/Offers/OfferImage';

import styles from './ContestWidget.module.scss';

const ContestWidget = ({
  url,
  imageUrl,
  title,
  status,
  endDate,
  expirationLabelColor,
  participations,
}) => {
  const { t } = useTranslation(['translation', 'profile']);
  const isWinner = status === contestUserStatus.winner;
  const container = `container${isWinner ? '-winner' : ''}`;

  return (
    <article className={styles[container]}>
      <OfferImage
        userIsWinner={status === contestUserStatus.winner}
        imageUrl={imageUrl}
        largeImageUrl={imageUrl}
        expirationDate={!isWinner && endDate}
        containerClass={styles.widgetImage}
        labelTheme={expirationLabelColor}
      />
      <section className={styles.content}>
        <p className={styles.title}>{title}</p>
        <p className={styles.message}>
          {t(`profile:my-contests.${status}`, { count: participations })}
        </p>
        <Link className={styles.link} to={`/contests/${url}`}>
          {t(`profile:my-contests.more`)}
        </Link>
      </section>
    </article>
  );
};

export default ContestWidget;
