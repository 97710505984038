import React from 'react';

import StandardSelect from 'components/Selects/StandardSelect';
import SelectFormControl from 'components/Selects/SelectFormControl';

import styles from './LanguageSelect.module.scss';

const modalUnderSelectInputProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  getContentAnchorEl: null,
};

const menuProps = {
  ...modalUnderSelectInputProps,
};

export default function LanguageSelect({
  lang,
  handleChange,
  children,
  rootClass = '',
}) {
  const classes = {
    root: rootClass ?? styles.root,
    icon: styles.icon,
  };

  return (
    <SelectFormControl>
      <StandardSelect
        disableUnderline={true}
        displayEmpty
        value={lang}
        onChange={handleChange}
        MenuProps={menuProps}
        classes={classes}
      >
        {children}
      </StandardSelect>
    </SelectFormControl>
  );
}
