import { formatDate } from 'utils/dateUtils';
import { getObjectEntries } from 'utils/objectUtils';
import { isEmptyString } from 'utils/stringUtils';

const generateRequestString = (labels, data) => {
  const fields = getObjectEntries(labels).map(([key, label]) => {
    if (key.indexOf('title_') > -1) return `${label}`;

    const formValue = data[key];
    if (formValue === undefined) return `${label}: -`;

    if (typeof formValue === 'boolean')
      return formValue === true ? label : null;

    if (typeof formValue === 'object')
      return `${label}: ${formatDate(formValue)}`;

    return `${label}: ${isEmptyString(formValue) ? '-' : formValue}`;
  });

  return fields.filter((x) => x !== null).join('\n\r');
};

const generateRequestData = ({
  accountId,
  labels,
  data,
  commodity = null,
  meterId = '00000000-0000-0000-0000-000000000000',
  requestType,
}) => {
  return {
    AccountId: accountId,
    MeterId: meterId,
    Commodity: commodity,
    RequestCategory: requestType,
    RequestText: generateRequestString(labels, data),
  };
};
export default generateRequestData;
