import React, { useState, useRef } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { SemiTransparentButton } from './Buttons';
import { ReactComponent as DownArrow } from 'assets/icons/ArrowDownIcon.svg';
import { ReactComponent as UpArrow } from 'assets/icons/ArrowUpIcon.svg';
import IconText from 'components/IconText/IconText';
import SidebarLinkElement from 'components/SidebarElements/SidebarLinkElement';
import SidebarButtonElement from 'components/SidebarElements/SidebarButtonElement';
import styles from './ButtonDropdown.module.scss';

export const ButtonDropdown = ({ label, children, icon, buttonClass = '' }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <section className={styles.container}>
      <SemiTransparentButton
        aria-expanded={open ? 'true' : undefined}
        aria-label={label}
        aria-haspopup="menu"
        onClick={handleToggle}
        className={`${styles.button} ${buttonClass}`}
        ref={anchorRef}
      >
        <IconText
          Icon={icon}
          iconRight={open ? <UpArrow /> : <DownArrow />}
          content={label}
        />
      </SemiTransparentButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={styles.popper}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'window',
          },
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList className={styles.menu}>
                  {React.Children.map(children, (child) =>
                    React.cloneElement(child, {
                      ...child.props,
                      setOpen: setOpen,
                    })
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </section>
  );
};

export const ButtonDropdownElement = ({
  icon,
  content,
  target,
  clickHandler,
  setOpen,
  ...rest
}) => {
  return (
    <MenuItem
      onClick={(event) => {
        setOpen(false);
        clickHandler(event);
      }}
      className={styles['menu-item']}
      {...rest}
    >
      {target ? (
        <SidebarLinkElement
          target={target}
          Icon={icon}
          content={content}
          onClick={clickHandler}
        />
      ) : (
        <SidebarButtonElement
          Icon={icon}
          content={content}
          onClick={clickHandler}
        />
      )}
    </MenuItem>
  );
};
