import React from 'react';
import { useTranslation } from 'react-i18next';
import IconTextLarge from 'components/IconText/IconTextLarge';

import styles from './NewGasSupplySuccess.module.scss';

const NewGasSupplySuccess = () => {
  const { t } = useTranslation(['newClient']);

  return (
    <section className={styles.container}>
      <IconTextLarge
        showIcon={true}
        success={true}
        header={t(`newClient:success.header`)}
        message={t(`newClient:success.gas-subtitle`)}
      />
    </section>
  );
};

export default NewGasSupplySuccess;
