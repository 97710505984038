import React from 'react';

import { ReactComponent as SuccessIcon } from 'assets/icons/LoyaltySuccessIcon.svg';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import RewardsWidget from 'components/Loyalty/RewardsWidget';

import styles from './RewardDialog.module.scss';

const RewardDialog = ({
  open,
  closeDialog,
  onClose,
  points,
  icon,
  header,
  subMessage,
  message,
  rewardMessage,
  loading = false,
  children,
  animateLoyaltyPoints = true,
}) => {
  return (
    <DialogOverlay
      open={open}
      closeDialog={() => {
        closeDialog();
        if (typeof onClose === 'function') onClose();
      }}
      scroll="body"
      dialogHeader={header}
      maxWidth="sm"
    >
      <div className={styles.container}>
        {icon ? (
          icon
        ) : (
          <SuccessIcon
            width="72"
            height="72"
            alt="success"
            className={styles.successIcon}
          />
        )}
        <p className={styles.title}>{message}</p>
        {subMessage && <p className={styles.subMessage}>{subMessage}</p>}
        {!!points && (
          <RewardsWidget
            title={rewardMessage}
            loading={loading}
            points={points}
            distance=""
            animateLoyaltyPoints={animateLoyaltyPoints}
          />
        )}
        {children}
      </div>
    </DialogOverlay>
  );
};

export default RewardDialog;
