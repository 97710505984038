import React from 'react';
import { useTranslation } from 'react-i18next';

import { expirationLabelThemes } from 'utils/product/enums/expirationLabels';

import styles from './ExpirationLabel.module.scss';

const MaxedOutLabel = ({ labelTheme }) => {
  const { t } = useTranslation(['offers']);
  return (
    <p
      className={
        styles[
          `label-${(labelTheme ?? expirationLabelThemes.dark).toLowerCase()}`
        ]
      }
    >
      {t('offers:maxedOutLabel.label')}
    </p>
  );
};

export default MaxedOutLabel;
