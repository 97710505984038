import { useEffect } from 'react';

const useChainingAnimation = (target, animationClasses = []) => {
  useEffect(() => {
    if (!target) return; // Don't do anything if target is null

    const animate = (elem, animation) => {
      return new Promise((resolve) => {
        const handleAnimationEnd = () => {
          elem.removeEventListener('animationend', handleAnimationEnd);
          elem.classList.remove(animation); // Remove the class after animation
          resolve(elem);
        };
        elem.addEventListener('animationend', handleAnimationEnd, {
          once: true,
        });
        elem.classList.add(animation);
      });
    };

    const chainAnimations = async () => {
      for (const animation of animationClasses) {
        await animate(target, animation);
      }
    };

    chainAnimations();

    return () => {
      animationClasses?.forEach((animation) => {
        target.classList.remove(animation);
      });
    };
  }, [target, animationClasses]);
};

export default useChainingAnimation;
