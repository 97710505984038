import { useCmsContent } from './useCmsContent';

const useLoyaltyPrompt = (language) => {
  const { data, loading, error } = useCmsContent('loyalty_prompt', language);
  const prompt =
    data && data?.data?.length > 0
      ? {
          ...data?.data[0]?.content,
          image: data?.data[0]?.content?.image?.image,
        }
      : null;

  return {
    loading,
    error,
    data: prompt,
  };
};

export { useLoyaltyPrompt };
