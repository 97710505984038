import React from 'react';
import MaterialTabs from '@material-ui/core/Tabs';
import MaterialTab from '@material-ui/core/Tab';

import styles from './TabPanel.module.scss';

const Tabs = ({ value, onChange, children, ...rest }) => {
  return (
    <MaterialTabs
      value={value}
      onChange={onChange}
      className={styles.container}
      {...rest}
    >
      {children}
    </MaterialTabs>
  );
};

const Tab = ({ label, value, ...rest }) => {
  return <MaterialTab label={label} value={value} {...rest} />;
};

export { Tabs, Tab };
