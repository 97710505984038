import React from 'react';

import styles from './HistoryRecord.module.scss';

const HistoryRecordWrapper = ({ wrapperClassName, children }) => {
  return (
    <article className={`${styles.wrapper} ${wrapperClassName}`}>
      {children}
    </article>
  );
};

export default HistoryRecordWrapper;
