// import { conventFromCents } from 'utils/moneyUtils';
import { benefitTypes } from '../enums/waveEnums';

export const getRedemptionResult = (data) => {
  if (!data || data.benefits?.length === 0) return null;

  const couponBenefit = data.benefits.find(
    (x) => x.type === benefitTypes.COUPON
  );

  // const vibeBenefit = data.benefits.find(
  //   (x) => x.type === benefitTypes.REWARD_SCHEME_CURRENCY_FIXED
  // );

  return {
    coupon: couponBenefit?.coupon,
    // vibe: vibeBenefit
    //   ? {
    //       ...vibeBenefit.rewardSchemeCurrencyFixed,
    //       amount: conventFromCents(
    //         vibeBenefit.rewardSchemeCurrencyFixed.amount
    //       ),
    //     }
    //   : null,
  };
};

export const getRedemptionVibeResult = (vibe, myVibes) => {
  if (!vibe) return null;

  const vibeToDisplay = myVibes.find(
    (x) => x.rewardSchemeId === vibe.rewardSchemeId
  );

  return {
    vibe: vibeToDisplay,
    isUnlocked: vibeToDisplay && vibeToDisplay.remainingAmount === vibe.amount,
  };
};
