import React from 'react';
import { useAuthUser } from 'utils/AuthUser';
import { getAllGasInvoicesUnsorted } from 'utils/product/invoiceUtils';
import useData from './useData';

export default function useGasInvoices(skip = false) {
  const { accountId } = useAuthUser();

  const { data, error, loading } = useData(
    `/account/invoices?accountId=${accountId}`,
    {},
    skip
  );

  const invoiceData = React.useMemo(() => {
    return getAllGasInvoicesUnsorted(data);
  }, [data]);

  return {
    loading,
    error,
    invoices: invoiceData,
  };
}
