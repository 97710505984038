import { useCmsContent } from './useCmsContent';

const useLoyaltyPersonalData = (language) => {
  const { data, loading, error } = useCmsContent(
    'loyalty_personal_data',
    language
  );

  return {
    loading,
    error,
    data: data?.data?.[0]?.content,
  };
};

export { useLoyaltyPersonalData };
