const paymentTypes = {
  bill: 0,
  guest: 1,
  settlement: 2,
  ena: 3,
  thirdParty: 4,
  recurring: 5,
};

export default paymentTypes;
