import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { trackBillPaymentHistoryEvent } from 'utils/product/tracking/events';
import Pager from 'components/Pager/Pager';
import { ReactComponent as EmptyBillsHistory } from 'assets/icons/EmptyBillsHistory.svg';
import MyBillsHistoryPaymentsTabContent from 'components/MyBillsHistoryPaymentsTabContent/MyBillsHistoryPaymentsTabContent';
import EmptyState from 'components/History/EmptyState';
import HistoryMeterChips from 'components/Chip/HistoryMeterChips';
import TabPanel from 'components/Tabs/TabPanel';
import useAccountPayments from 'data/useAccountPayments';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';

import styles from './MyBillsHistoryWidget.module.scss';

const MyPaymentsTabPanel = ({
  value,
  meters,
  metersLoading,
  skipAccountPaymentsService,
}) => {
  const pagerLimit = 8;
  const { t } = useTranslation(['translation', 'bills']);
  const [paymentsLimit, setPaymentsLimit] = React.useState(pagerLimit);
  const [chosenMeter, setchosenMeter] = React.useState('all');
  const { loading: paymentsLoading, groupedPayments } = useAccountPayments(
    skipAccountPaymentsService,
  );

  useEffect(() => {
    setchosenMeter('all');
  }, [value]);

  const handlePaymentsLimitChange = (limit) => {
    setPaymentsLimit(limit);
    trackBillPaymentHistoryEvent('bill_history_payments_view_more');
  };

  const handlechosenMeter = (meter) => {
    const tempMeter = meter !== 'all' ? meter.Id : 'all';
    setchosenMeter(tempMeter);
  };

  return (
    <TabPanel
      value={value}
      index={1}
      idPrefix="payments"
      innerClass={styles['tabPanel-innerContainer']}
    >
      <HistoryMeterChips
        meters={meters}
        handlechosenMeter={handlechosenMeter}
        chosenMeter={chosenMeter}
      />
      <WidgetSkeleton distance="s" show={metersLoading || paymentsLoading}>
        {groupedPayments[chosenMeter]?.length === 0 && (
          <EmptyState
            Icon={<EmptyBillsHistory />}
            text={t('bills:payments-history-empty')}
          />
        )}
        {groupedPayments[chosenMeter] &&
          groupedPayments[chosenMeter]?.length > 0 && (
            <>
              {groupedPayments[chosenMeter]
                .slice(0, paymentsLimit)
                .map((payment, index) => (
                  <section
                    className={styles.link}
                    key={`${payment.Paymentno}-${index * Math.random()}`}
                  >
                    <MyBillsHistoryPaymentsTabContent
                      key={payment.Paymentno}
                      payment={payment}
                    />
                  </section>
                ))}

              <Pager
                totalCount={groupedPayments[chosenMeter]?.length}
                onClick={handlePaymentsLimitChange}
                containerClass={styles['moreButton-container']}
              />
            </>
          )}
      </WidgetSkeleton>
    </TabPanel>
  );
};
export default MyPaymentsTabPanel;
