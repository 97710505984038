import { useCmsContent } from './useCmsContent';

const useVibesConfig = (language, skip = false) => {
  const { data, loading, error } = useCmsContent('vibes', language, skip);

  const vibes =
    data && data?.data?.length > 0
      ? data?.data.map((x) => ({
          ...x.content,
          id: x.id,
          imageUrl: x?.content?.icon?.image,
        }))
      : [];

  return {
    loading,
    error,
    data: vibes,
  };
};

export { useVibesConfig };
