import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress } from '@material-ui/core';

import { ReactComponent as DownArrow } from 'assets/icons/ArrowDownIcon.svg';
import { StandardInput } from './Inputs';
import { isEmptyString } from 'utils/stringUtils';

const AutocompleteInput = ({
  errors,
  name,
  label,
  defaultValue = '',
  register,
  required = true,
  options = [],
  labelKey = 'text',
  valueKey = 'value',
  loading = false,
}) => {
  const { t } = useTranslation(['inputs']);
  const emptyOption = { [labelKey]: '', [valueKey]: '' };
  const innerOptions = [emptyOption, ...options];

  return (
    <div>
      <Controller
        render={({ field: { onChange, ...fieldProps } }) => (
          <Autocomplete
            {...fieldProps}
            id={`combo-box-${name}`}
            options={innerOptions}
            clearOnEscape={true}
            disableClearable={
              false
            } /* clear icon is hidden manually through the materialTheme.jsx to match Design */
            getOptionLabel={(option) => option[labelKey] ?? ''}
            getOptionSelected={(option, _value) =>
              option[valueKey] === _value[valueKey]
            }
            loading={loading}
            disabled={loading}
            noOptionsText={t(`inputs:no-results`)}
            popupIcon={
              loading ? (
                <CircularProgress
                  style={{ color: '#FFFFFF' }}
                  size={20}
                  title={`${name}-loader`}
                />
              ) : (
                <DownArrow />
              )
            }
            renderInput={(params) => (
              <StandardInput
                label={label}
                type="text"
                name={`${name}Input`}
                errorMessage={get(errors, `${name}.message`, null)}
                error={get(errors, `${name}`, null) !== null}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
            onChange={(_, data) => {
              onChange(data);
            }}
            name={name}
          />
        )}
        name={name}
        defaultValue={defaultValue}
        rules={{
          required: {
            value: required,
            message: t(`inputs:errors.required`),
          },
          validate: {
            emptyValue: (_value) => {
              return isEmptyString(_value[valueKey])
                ? t(`inputs:errors.required`)
                : true;
            },
          },
        }}
      />
    </div>
  );
};

export { AutocompleteInput };
