import { trackFbEvent } from 'utils/fbPixel';

export const trackEcontractFbEvent = () => {
  trackFbEvent('e-contract');
};

export const trackEcontractCompleteFbEvent = () => {
  trackFbEvent('eContract_complete');
};

export const trackRegistrationFbEvent = () => {
  trackFbEvent('CompleteRegistration');
};

export const trackViewContentFbEvent = () => {
  trackFbEvent('ViewContent');
};
