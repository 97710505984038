import api from './api';
import commodities from 'utils/product/enums/commodities';

const getInvoiceFileGasCRM = (invoiceId, commodity, accountId) => {
  return api.get(
    `/invoice/file?invoiceId=${invoiceId}&commodity=${commodity}&accountId=${accountId}`,
    {
      responseType: 'blob',
    }
  );
};

const getInvoiceFileElectricityIDocs = (data) => {
  return api.post(`/idoc/getPDF`, data, {
    responseType: 'blob',
  });
};

const getInvoiceFile = (invoice, meter, accountId) => {
  return meter?.Commodity === commodities.gas
    ? getInvoiceFileGasCRM(invoice?.id, meter?.Commodity, accountId)
    : getInvoiceFileElectricityIDocs({
        invoicenumbers: [`${invoice?.invoiceNo}`],
        doctype: 'electricity',
      });
};

const sendReceiptByEmail = (approvalCode, accountId) => {
  return api.get(
    `/invoice/receiptByEmail?approvalCode=${approvalCode}&accountId=${accountId}`
  );
};

export { getInvoiceFile, sendReceiptByEmail };
