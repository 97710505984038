import React from 'react';
import { useTranslation } from 'react-i18next';

import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import useLoyaltyHistoryWithDescription from 'data/useLoyaltyHistoryWithDesciption';
import HistoryWidgetContent from './HistoryWidgetContent';
import { useAuthUser } from 'utils/AuthUser';
import { addEventDescriptionToPointsHistory } from 'utils/product/loyalty/historyUtils';

const HistoryWidget = () => {
  const { t, i18n } = useTranslation(['translation', 'loyalty']);
  const { loyaltyCustomer } = useAuthUser();
  const { data, associatedData, loading } = useLoyaltyHistoryWithDescription(
    loyaltyCustomer?.customerId,
    i18n.language
  );

  const dataWithEventDescription = addEventDescriptionToPointsHistory(
    data,
    associatedData
  );

  return (
    <WidgetSkeleton
      distance="s"
      show={loading}
      title={<WidgetHeader title={t('loyalty:landing.history.title')} />}
    >
      <HistoryWidgetContent
        historyData={dataWithEventDescription}
        historyDataLength={data?.length}
      />
    </WidgetSkeleton>
  );
};
export default HistoryWidget;
