import React from 'react';
import { useTranslation } from 'react-i18next';
import useAccountMeters from 'data/useAccountMeters';
import {
  meterStatusesElectricity,
  meterStatusesGas,
} from 'utils/product/enums/meterStatuses';

import TwoColumnsLayout from 'layouts/TwoColumnsLayout';
import RightSidebar from './RightSidebar';
import MainArea from './MainArea';
import commodities from 'utils/product/enums/commodities';

const Bills = ({ commodity = commodities.energy }) => {
  const { t } = useTranslation(['homepage']);

  const {
    meters,
    loading: metersLoading,
    mutate,
  } = useAccountMeters({
    statusesElectricity: [
      meterStatusesElectricity.active,
      meterStatusesElectricity.terminated,
    ],
    statusesGas: [meterStatusesGas.active, meterStatusesGas.terminated],
  });

  const loading = metersLoading;

  const handleEbillChange = () => {
    mutate();
  };

  return (
    <TwoColumnsLayout
      head={{
        title: t('homepage:my-bills.title'),
        metaTags: [{ name: 'title', content: t('homepage:my-bills.title') }],
      }}
      main={
        <MainArea
          commodity={commodity}
          meters={meters}
          loading={loading}
          handleEbillChange={handleEbillChange}
        />
      }
      right={<RightSidebar meters={meters} metersLoading={metersLoading} />}
    />
  );
};

export default Bills;
