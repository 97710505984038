import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import useError from 'hooks/useError';
import { forgotUserPassword } from 'api/user';
import { trackForgotPasswordEvent } from 'utils/product/tracking/events';
import { EmailInput } from 'components/Inputs/EmailInput';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import { LocalisedErrorBox } from 'components/MessageBox/MessageBox';

import styles from './ForgotPasswordContent.module.scss';

const ForgotPasswordContent = () => {
  const { t } = useTranslation(['onboarding']);
  const baseName = 'onboarding:forgotPasswordContent';
  const generalName = 'onboarding:general';

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { error, handleError, clearError } = useError();
  const [emailSentMessage, setEmailSentMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    trackForgotPasswordEvent('login_forgot_password_continue');
    const { email } = data;
    setLoading(true);
    forgotUserPassword(email)
      .then(() => {
        clearError();
        setIsEmailSent(true);
        setEmailSentMessage(t(`${baseName}.email-sent-message`, { email }));
      })
      .catch((error) => {
        handleError(error, 'forgotPassword');
      })
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {!isEmailSent && (
        <>
          <h3 className={styles.header}>{t(`${baseName}.header`)}</h3>
          <div className={styles.description}>
            {t(`${baseName}.description`)}
          </div>
          <LocalisedErrorBox
            open={error !== null}
            closeBox={clearError}
            {...(error || {})}
          />
          <form
            className={styles.container}
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <EmailInput
              register={register}
              errors={errors}
              label={t(`${generalName}.email`)}
            />
            <section className={styles.submit}>
              <PrimaryButtonLoader
                loading={loading}
                disabled={loading}
                type="submit"
              >
                {t(`${baseName}.submit`)}
              </PrimaryButtonLoader>
            </section>
          </form>
          <Link to="/login" className={styles.link}>
            {t(`${baseName}.return`)}
          </Link>
        </>
      )}
      {isEmailSent && (
        <p className={styles['success-message']}>{emailSentMessage}</p>
      )}
    </>
  );
};

export default ForgotPasswordContent;
