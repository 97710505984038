import cookiesStorage from 'utils/storage/cookiesStorage';

export const shouldTrack = () => {
  if (process.env.REACT_APP_ENV === 'development') return false;

  const consent = cookiesStorage.getCookiesConsent();
  return consent && consent.ads === true;
};

export const initializeAdTracking = () => {
  const isTrackingEnabled = shouldTrack();

  isTrackingEnabled ? enableAdTracking() : disableAdTracking();
};

export const enableAdTracking = () => {
  if (typeof window.gtag === 'function') {
    window.gtag('consent', 'update', {
      ad_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
    });
  }
};

export const disableAdTracking = () => {
  if (typeof window.gtag === 'function') {
    window.gtag('consent', 'update', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
    });
  }
};

export const trackAdEvent = () => {
  if (shouldTrack()) {
    typeof window.gtag === 'function' &&
      window.gtag('event', 'conversion', {
        send_to: `${process.env.REACT_APP_GOOGLEADS_ID}/TmiYCPP9rcUDEL-nndoo`,
      });
  }
};
