import React from 'react';
import { useTranslation } from 'react-i18next';

import useRequests from 'data/useRequests';
import getHomepageRequests from 'utils/product/requests/getHomepageRequests';
import useAccountMeters from 'data/useAccountMeters';
import { trackHomeRequestsEvent } from 'utils/product/tracking/events';
import { homeRequestsLimit } from 'utils/product/config';
import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import RequestsList from './RequestsList';
import SidebarLinkElement from 'components/SidebarElements/SidebarLinkElement';
import SidebarLinkMenu from 'components/SidebarElements/SidebarLinkMenu';
import WidgetLine from 'components/WidgetLine/WidgetLine';
import { ReactComponent as NewRequestIcon } from 'assets/icons/NewRequest.svg';
import { ReactComponent as PendingRequestIcon } from 'assets/icons/PendingRequest.svg';

const RequestsWidget = ({ detailed = false }) => {
  const { t, i18n } = useTranslation(['homepage', 'requests']);
  const { meters, loading: metersLoading } = useAccountMeters({
    statusesGas: [],
    statusesElectricity: [],
  });
  const { requests, loading, requestHistoryCategories } = useRequests(
    i18n.language
  );

  const requestsToDisplay = getHomepageRequests(
    requests,
    meters,
    requestHistoryCategories
  );

  return (
    <WidgetSkeleton
      distance="s"
      show={loading || metersLoading}
      title={<WidgetHeader title={t('homepage:my-requests.title')} />}
    >
      <Widget
        background="transparent"
        distance="s"
        title={<WidgetHeader title={t('homepage:my-requests.title')} />}
      >
        {!loading && requestsToDisplay.length > 0 && (
          <RequestsList
            requests={requestsToDisplay}
            limit={detailed ? null : homeRequestsLimit}
          />
        )}
        <SidebarLinkMenu>
          {!detailed && requestsToDisplay.length > homeRequestsLimit && (
            <>
              <SidebarLinkElement
                target="/all-requests"
                Icon={<PendingRequestIcon width="24" />}
                content={t('requests:requestWidgetElement.pending')}
                onClick={() => {}}
              />
              <WidgetLine />
            </>
          )}
          <SidebarLinkElement
            target="/requests"
            Icon={<NewRequestIcon width="24" />}
            content={t('requests:requestWidgetElement.new')}
            onClick={() => trackHomeRequestsEvent('home_request_overview')}
          />
        </SidebarLinkMenu>
      </Widget>
    </WidgetSkeleton>
  );
};

export default RequestsWidget;
