import React, { useEffect, useRef, useState } from 'react';

import { ZoomIn, ZoomOut } from 'utils/MapZoomfunctions';

import styles from './Map.module.scss';

const Map = ({ options, onMount, onMountProps }) => {
  const ref = useRef();
  const [map, setMap] = useState();

  useEffect(() => {
    const onLoad = () =>
      setMap(new window.google.maps.Map(ref.current, options));
    if (window.google && window.google.maps) {
      onLoad();
      return;
    }
    const script = document.createElement(`script`);
    script.src = `https://maps.google.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
    document.head.appendChild(script);
    script.addEventListener(`load`, onLoad);
    return () => script.removeEventListener(`load`, onLoad);
  }, [options]);

  useEffect(() => {
    if (map && typeof onMount === `function`) {
      onMount(map, onMountProps);
    }
    if (map) {
      const zoomOut = document.createElement('div');
      ZoomOut(zoomOut, map);
      map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
        zoomOut
      );
      const zoomIn = document.createElement('div');
      ZoomIn(zoomIn, map);
      map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
        zoomIn
      );
    }
  }, [map, onMount, onMountProps]);

  return <div {...{ ref }} className={styles['container']} />;
};

Map.defaultProps = {
  options: {
    center: { lat: 39.0742, lng: 21.8243 },
    zoom: 5,
  },
};

export default Map;
