import React from 'react';
import { useTranslation } from 'react-i18next';

import MeterLabelWrapper from 'components/Labels/MeterLabelWrapper';
import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import EnergyReportsCta from 'components/Consumptions/MediumVoltage/EnergyReportsCta';

import styles from './EnergyReports.module.scss';

const EnergyReports = ({ meter }) => {
  const { t } = useTranslation(['consumptions']);

  return (
    <Widget
      key={`er_${meter.MeterId}`}
      widgetClass={styles.widget}
      size="m"
      distance="m"
      title={<WidgetHeader title={t('consumptions:b2b.energy-reports')} />}
    >
      <MeterLabelWrapper
        type={meter.type}
        friendlyName={meter.FriendlyName}
        meterNo={meter.MeterNo}
        meter={meter}
      />
      <section className={styles.container}>
        <p className={styles.content}>
          {t('consumptions:b2b.energy-reports-message')}
        </p>
        <EnergyReportsCta meter={meter} containerClass={styles.button} />
      </section>
    </Widget>
  );
};

export default EnergyReports;
