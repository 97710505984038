import { getDifferenceInDays } from 'utils/dateUtils';
import addMetersToRequests from 'utils/product/requests/addMetersToRequests';
import { requestStatuses as requestStatusEnums } from 'utils/product/enums/requests';

const getHomepageRequests = (requests, meters, requestCategories) => {
  const today = new Date();

  const filteredRequests =
    requests && requests.length > 0
      ? requests.filter((request) => {
          if (!requestCategories[request.RequestType]) return false;
          if (
            request.RequestStatus === requestStatusEnums.pending ||
            request.RequestStatus === requestStatusEnums.draft
          )
            return true;
          if (
            request.RequestStatus === requestStatusEnums.completed &&
            request.LastUpdateOn !== null
          ) {
            const daysSinceUpdate = getDifferenceInDays(
              request.LastUpdateOn,
              today
            );

            return daysSinceUpdate < 7;
          }
          return false;
        })
      : [];

  const requestsToDisplay = addMetersToRequests(filteredRequests, meters);
  return requestsToDisplay;
};

export default getHomepageRequests;
