import Big from 'big.js';

export const formatNumber = (number) => {
  return `${number ?? ''}`
    .replace('.', ',')
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
};

export const formatMoneyWithCurrency = (amount, includeDecimals = true) => {
  const _amount = new Big(amount ?? 0);
  return `${formatNumber(_amount.toFixed(includeDecimals ? 2 : 0))}€`;
};

export const formatMoney = (amount, includeDecimals = true) => {
  const _amount = new Big(amount ?? 0);
  return formatNumber(_amount.toFixed(includeDecimals ? 2 : 0));
};

export const conventFromCents = (amount) => {
  if (!!amount) {
    return new Big(amount).div(100).toNumber();
  }
  return 0;
};

export const conventToCents = (amount) => {
  if (!!amount) {
    return new Big(amount).times(100).toNumber();
  }
  return 0;
};
