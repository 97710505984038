import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@material-ui/core/InputAdornment';
import get from 'lodash/get';
import { isEmptyString } from 'utils/stringUtils';

import IconButton from '@material-ui/core/IconButton';
import InnerLoader from 'components/Buttons/InnerLoader';

import { ReactComponent as UploadIcon } from 'assets/icons/UploadIcon.svg';
import { StandardInput } from 'components/Inputs/Inputs';

import styles from './UploadWidget.module.scss';

const MB_TO_BYTES = 1000000;

const UploadWidget = ({
  label,
  name,
  existingFileName = '',
  register,
  errors = {},
  onFileChange = () => {},
  loading = false,
  required = false,
  clearOnCancel = false,
  acceptedFileTypes = [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-powerpoint',
  ],
  maxFileSizeMb = 12,
}) => {
  const { t } = useTranslation(['inputs']);

  const [fileName, setFileName] = useState(existingFileName);
  const onChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setFileName(file.name);
      onFileChange(event.target.files);
      return;
    }
    if (clearOnCancel) {
      setFileName('');
    }
    onFileChange(null);
  };

  const handleFocus = (event) => {
    event.stopPropagation();

    const fileUpload = document.getElementById(`file_${name}`);
    if (fileUpload) {
      document.getElementById(`file_name_${name}`).blur();
      fileUpload.click();
    }
  };
  const { onChange: onInputChange, ...registerProps } =
    register(name, {
      validate: {
        notEmpty: (value) => {
          if (
            required &&
            isEmptyString(fileName) &&
            (!value || value.length === 0)
          ) {
            return false;
          }
          return true;
        },
        inputType: (value) => {
          if (required && value && value.length > 0) {
            if (acceptedFileTypes.indexOf(value[0].type) === -1) {
              return t('inputs:errors.upload-failed');
            }
          }
          return true;
        },
        maxFileSize: (files) => {
          return (
            !files?.[0] ||
            files?.[0]?.size < maxFileSizeMb * MB_TO_BYTES ||
            t('inputs:errors.maxFileSize', { size: maxFileSizeMb })
          );
        },
      },
    }) || {};

  const labelProps = {
    classes: { root: styles.inputLabel, shrink: styles['inputLabel-shrink'] },
  };
  const inputProps = {
    classes: {
      root: styles.inputWrapper,
      input: styles.input,
    },
  };

  return (
    <>
      <StandardInput
        className={styles.inputContainer}
        id={`file_name_${name}`}
        errorMessage={get(errors, `${name}.message`, null)}
        error={get(errors, `${name}`, null) !== null}
        name={`file-name-${name}`}
        label={label}
        value={fileName}
        readOnly={true}
        onClick={handleFocus}
        InputLabelProps={labelProps}
        InputProps={inputProps}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="upload"
              className={styles.uploadButton}
              disabled={loading}
            >
              <InnerLoader loading={loading}>
                {!loading && <UploadIcon />}
              </InnerLoader>

              <input
                id={`file_${name}`}
                data-testid={`file_${name}`}
                {...registerProps}
                onChange={(e) => {
                  onInputChange(e);
                  onChange(e);
                }}
                type="file"
                hidden
                accept={acceptedFileTypes.join(', ')}
              />
            </IconButton>
          </InputAdornment>
        }
      />
    </>
  );
};

export default UploadWidget;
