import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAccountMeters from 'data/useAccountMeters';
import Helmet from 'utils/Helmet';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import EmptyStateWidget from 'components/Widget/EmptyStateWidget';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import MetersManagementWidget from './MetersManagementWidget';
import { ReactComponent as BillIcon } from 'assets/icons/EmptyEbillIcon.svg';
import useDialog from 'hooks/useDialog';
import { useAuthUser } from 'utils/AuthUser';
import EbillDialog from 'components/EbillDialogs/EbillDialog';
import LoyaltyBanner from 'components/Loyalty/LoyaltyBanner';
import { useCollectionOptions } from 'data/useCollectionOptions';
import {
  getPointsOfCategory,
  getWaveId,
} from 'utils/product/loyalty/pointsUtils';
import { collectionOptionTypes } from 'utils/product/enums/cmsEnums';
import useLoyaltyHistory from 'data/useLoyaltyHistory';
import { WarningDialog } from 'components/PromptDialogs/PromptDialog';
import {
  ALLOWED_METER_STATUSES_EL,
  ALLOWED_METER_STATUSES_GAS,
  findNotActivatedEbillWithoutReward,
  isMeterEligibleForReward,
} from 'utils/product/loyalty/ebillUtils';
import MultipleEbillsActivationDialog from 'components/EbillDialogs/MultipleEbillsActivationDialog';
import { isEmptyString } from 'utils/stringUtils';

import styles from './ManagementEbill.module.scss';

const ManagementEbill = () => {
  const { t } = useTranslation(['management', 'translation']);
  const baseName = 'management:managementRightSidebar';

  const ebillDialog = useDialog();
  const multipleEbillsDialog = useDialog();
  const warningDialog = useDialog();

  const { isLoyaltyCustomer, loyaltyCustomerId, user } = useAuthUser();
  const { collectionOptionsByType, loading: collectionOptionsLoading } =
    useCollectionOptions();

  const ebillPoints = getPointsOfCategory(
    collectionOptionsByType,
    collectionOptionTypes.E_BILL,
  );

  const waveId = getWaveId(
    collectionOptionsByType,
    collectionOptionTypes.E_BILL,
  );

  const [loadingMeterIdButton, setLoadingMeterIdButton] = useState(null);
  const [meterSelection, setMeterSelection] = useState(null);

  const { data: rewardsLoyaltyHistory, loading: historyLoading } =
    useLoyaltyHistory(loyaltyCustomerId, waveId);

  const {
    meters,
    loading: metersLoading,
    mutate,
  } = useAccountMeters({
    statusesElectricity: ALLOWED_METER_STATUSES_EL,
    statusesGas: ALLOWED_METER_STATUSES_GAS,
  });

  const hasMeters = meters?.length > 0;

  const numberOfInactiveEbills = meters?.filter(
    (meter) => !meter.Ebill,
  )?.length;

  const metersWithoutLoyaltyReward = findNotActivatedEbillWithoutReward(
    meters?.filter((meter) => isMeterEligibleForReward(meter)),
    rewardsLoyaltyHistory,
  );

  const canBeLoyaltyRewarded = metersWithoutLoyaltyReward.length > 0;

  const handleEbillActivateAll = () => {
    if (isEmptyString(user?.ContactEmail)) {
      warningDialog.show();
      return;
    }
    setLoadingMeterIdButton('All');
    multipleEbillsDialog.show();
  };

  const handleEbillActivation = (meter) => {
    setLoadingMeterIdButton(meter?.Id);
    setMeterSelection(meter);
    ebillDialog.show();
  };

  const handleEbillEdit = (meter) => {
    setMeterSelection(meter);

    ebillDialog.show();
  };

  const handleCloseEbillDialog = () => {
    setLoadingMeterIdButton(null);
    ebillDialog.close();
  };

  const handleCloseMultipleEbillsDialog = () => {
    setLoadingMeterIdButton(null);
    multipleEbillsDialog.close();
  };

  return (
    <>
      <Helmet
        head={{
          title: t(`${baseName}.eBill`),
          metaTags: [{ name: 'title', content: t(`${baseName}.eBill`) }],
        }}
      />
      <WidgetHeader title={t(`${baseName}.eBill`)} />
      <WidgetSkeleton
        show={metersLoading || collectionOptionsLoading || historyLoading}
        heightInPixels={56}
        hideTitle={true}
        distance="xs"
      >
        {isLoyaltyCustomer && hasMeters && canBeLoyaltyRewarded && (
          <LoyaltyBanner
            points={ebillPoints}
            text={t('management:ebill.loyalty-bonus')}
            distance="xs"
          />
        )}
      </WidgetSkeleton>
      <WidgetSkeleton
        show={metersLoading || collectionOptionsLoading || historyLoading}
        heightInPixels={108}
        hideTitle={true}
        distance="xs"
      >
        {hasMeters && numberOfInactiveEbills > 1 && (
          <Widget
            distance="xs"
            size="m"
            widgetClass={styles['activateAll-container']}
          >
            <p className={styles['activateAll-text']}>
              {t('management:ebill.activateAll-text')}
            </p>
            <PrimaryButtonLoader
              loading={loadingMeterIdButton === 'All'}
              disabled={!!loadingMeterIdButton}
              onClick={handleEbillActivateAll}
              className={styles['activateAll-button']}
            >
              {t('management:ebill.activateAll-button')}
            </PrimaryButtonLoader>
          </Widget>
        )}
        {!hasMeters && (
          <EmptyStateWidget
            icon={<BillIcon width="72px" height="72px" />}
            text={t('management:myMeters.empty-state')}
          />
        )}
      </WidgetSkeleton>

      <WidgetSkeleton
        show={metersLoading || collectionOptionsLoading || historyLoading}
        heightInPixels={240}
        distance=""
      >
        {hasMeters && (
          <Widget distance="" size="m" widgetClass={styles['meters-container']}>
            <h3>{t('management:ebill.meters-title')}</h3>
            <MetersManagementWidget
              meters={meters}
              loadingMeterId={loadingMeterIdButton}
              handlePrimaryAction={handleEbillActivation}
              handleSecondaryAction={handleEbillEdit}
              propertyKey={'Ebill'}
              showArrow
            />
          </Widget>
        )}
      </WidgetSkeleton>
      <EbillDialog
        open={ebillDialog.isOpen}
        closeDialog={handleCloseEbillDialog}
        mutate={mutate}
        meter={meterSelection}
        loyaltyHistory={rewardsLoyaltyHistory}
      />
      <MultipleEbillsActivationDialog
        open={multipleEbillsDialog.isOpen}
        closeDialog={handleCloseMultipleEbillsDialog}
        metersEligibleForLoyaltyReward={metersWithoutLoyaltyReward}
        waveId={waveId}
        mutate={mutate}
      />
      <WarningDialog
        open={warningDialog.isOpen}
        message={t('management:ebill.no-email-error')}
        primaryActionHandler={warningDialog.close}
      />
    </>
  );
};

export default ManagementEbill;
