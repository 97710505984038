import storage from 'utils/storage/storage';

const getLanguage = () => {
  return (
    storage.getItem({
      key: 'lang',
      storage: storage.STORAGE_TYPE.LocalStorage,
    }) ?? 'el'
  );
};

const setLanguage = (code) => {
  storage.setItem({
    key: 'lang',
    value: code,
    storage: storage.STORAGE_TYPE.LocalStorage,
  });
};

const languageStorage = { getLanguage, setLanguage };
export default languageStorage;
