import axios from 'axios';

const baseUrl = process.env.REACT_APP_LEADS_URL;
const api = axios.create({
  baseURL: baseUrl,
});

const contactHeron = (requestData, leadSource) => {
  var data = new FormData();
  data.append('first_name', requestData.firstName);
  data.append('last_name', requestData.lastName);
  data.append('phone', requestData.phone);
  data.append('email', requestData.email);
  data.append('type', requestData.type);
  data.append('existing_customer', requestData.existing_customer);

  if (requestData?.electricity)
    data.append('electricity', requestData.electricity);
  if (requestData?.gas) data.append('gas', requestData.gas);
  if (requestData?.heron_ena) data.append('heron_ena', requestData.heron_ena);
  if (requestData?.ecoroof) data.append('ecoroof', requestData.ecoroof);
  if (requestData?.ecoheat) data.append('ecoheat', requestData.ecoheat);

  return api.post('/add', data, {
    headers: {
      'x-api-key': leadSource,
    },
  });
};

export { contactHeron };
