import useData from 'data/useData';
import { useAuthUser } from 'utils/AuthUser';

import { consumptionsPreprocessing } from 'utils/product/consumptionUtils';

export default function useAccountConsumptions(meters) {
  const { accountId } = useAuthUser();
  const { data, error, loading } = useData(
    `/account/consumptionsBoth?accountId=${accountId}`
  );

  const results = data?.MeterConsumptionsResponses
    ? consumptionsPreprocessing(data, meters)
    : [];

  return {
    loading,
    error,
    data,
    consumptions: results,
  };
}
