import useData from 'data/useData';
import { useAuthUser } from 'utils/AuthUser';
import useAccountMeters from './useAccountMeters';
import { getContractStatusMetersWithExtraInfo } from 'utils/product/contractUtils';

export default function useContractStatus() {
  const { accountId } = useAuthUser();
  const { data, error, loading, mutate } = useData(
    `/account/contractStatusChange?accountId=${accountId}`
  );
  const { meters, loading: metersLoading } = useAccountMeters({
    statusesGas: [],
    statusesElectricity: [],
  });

  const result =
    loading || metersLoading
      ? []
      : getContractStatusMetersWithExtraInfo(
          data?.ContractStatusChangeInfo,
          meters
        );
  return {
    data: result,
    loading: loading || metersLoading,
    error,
    mutate,
  };
}
