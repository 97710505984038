import {
  contractStatusElectricity,
  contractStatusGas,
  contractStatusElectricityTimeLimits,
  contractStatusGasTimeLimits,
} from 'utils/product/enums/contractStatuses';
import { getDifferenceInDays } from 'utils/dateUtils';
import commodities from 'utils/product/enums/commodities';
import { minDate, parseDate } from 'utils/dateUtils';
import programTypes from './enums/programTypes';
import { uniqWith } from 'lodash';

export const getContractStatusElectricityTimeLimits = (status) => {
  switch (status) {
    case contractStatusElectricity.received:
      return contractStatusElectricityTimeLimits.received;
    case contractStatusElectricity.toBeSigned:
      return contractStatusElectricityTimeLimits.received;
    case contractStatusElectricity.pending:
      return contractStatusElectricityTimeLimits.received;
    case contractStatusElectricity.submitted:
    case contractStatusElectricity.forRepresentation:
    case contractStatusElectricity.pendingResolved:
      return contractStatusElectricityTimeLimits.received;
    case contractStatusElectricity.beingEdited:
      return contractStatusElectricityTimeLimits.received;
    case contractStatusElectricity.rejected:
      return contractStatusElectricityTimeLimits.received;
    case contractStatusElectricity.userWithdrawal:
      return contractStatusElectricityTimeLimits.userWithdrawal;
    case contractStatusElectricity.represented:
      return contractStatusElectricityTimeLimits.represented;
    default:
      return null;
  }
};

export const getContractStatusGasTimeLimits = (status) => {
  switch (status) {
    case contractStatusGas.toBeSigned:
      return contractStatusGasTimeLimits.toBeSigned;
    case contractStatusGas.pending:
      return contractStatusGasTimeLimits.pending;
    case contractStatusGas.submitted:
    case contractStatusGas.pendingResolved:
      return contractStatusGasTimeLimits.submitted;
    case contractStatusGas.beingEdited:
      return contractStatusGasTimeLimits.beingEdited;
    case contractStatusGas.rejected:
    case contractStatusGas.canceled:
      return contractStatusGasTimeLimits.rejected;
    case contractStatusGas.userWithdrawal:
      return contractStatusGasTimeLimits.userWithdrawal;
    case contractStatusGas.represented:
      return contractStatusGasTimeLimits.represented;
    default:
      return null;
  }
};

export const getStatusKeyElectricity = (meter) => {
  switch (meter.NewValue) {
    case contractStatusElectricity.received:
      return 'electricity.received';
    case contractStatusElectricity.toBeSigned:
      return 'electricity.toBeSigned';
    case contractStatusElectricity.pending:
      return 'electricity.pending';
    case contractStatusElectricity.submitted:
    case contractStatusElectricity.forRepresentation:
    case contractStatusElectricity.pendingResolved:
      return 'electricity.submitted';
    case contractStatusElectricity.beingEdited:
      return 'electricity.beingEdited';
    case contractStatusElectricity.rejected:
    case contractStatusElectricity.representationFailed:
      return 'electricity.rejected';
    case contractStatusElectricity.userWithdrawal:
      return 'electricity.user-rejected';
    case contractStatusElectricity.represented:
      return 'electricity.represented';
    default:
      return null;
  }
};

export const getStatusKeyGas = (meter) => {
  switch (meter.NewValue) {
    case contractStatusGas.toBeSigned:
      return 'gas.toBeSigned';
    case contractStatusGas.pending:
      return 'gas.pending';
    case contractStatusGas.submitted:
    case contractStatusGas.pendingResolved:
      return 'gas.submitted';
    case contractStatusGas.beingEdited:
      return 'gas.beingEdited';
    case contractStatusGas.rejected:
    case contractStatusGas.canceled:
      return 'gas.rejected';
    case contractStatusGas.userWithdrawal:
      return 'gas.user-rejected';
    case contractStatusGas.represented:
      return 'gas.represented';
    default:
      return null;
  }
};

export const getStatusKey = (meter) => {
  if (meter.Commodity === commodities.energy)
    return getStatusKeyElectricity(meter);
  if (meter.Commodity === commodities.gas) return getStatusKeyGas(meter);
  return null;
};

export const getTimeLimits = (meter, status) => {
  if (meter?.Commodity === commodities.energy)
    return getContractStatusElectricityTimeLimits(status);
  if (meter?.Commodity === commodities.gas)
    return getContractStatusGasTimeLimits(status);
  return null;
};

export const getContractStatusMetersWithExtraInfo = (
  contractStatusMeters,
  extraInfo,
) => {
  return contractStatusMeters
    ? contractStatusMeters?.reduce((accumulator, current) => {
        const meter = extraInfo?.find(
          (item) => item?.ContractNumber === current?.ContractCode,
        );

        const daysToExpire = getTimeLimits(meter, current?.NewValue);

        const isExpired =
          !daysToExpire ||
          daysToExpire < getDifferenceInDays(current?.ChangeDate, new Date());

        if (meter && !isExpired)
          accumulator.push({
            ...current,
            ...meter,
          });
        return accumulator;
      }, [])
    : [];
};

export const addMetersToContracts = (contracts, meters) => {
  return contracts && meters
    ? contracts.map((contract) => {
        const { Meterid: MeterId } = contract || {};
        const meterForRequest = meters.find((x) => x.Id === MeterId);
        return {
          ...contract,
          meter: meterForRequest || null,
        };
      })
    : [];
};

export const getOldestContractDate = (contracts, propertyName) => {
  if (contracts.length <= 0) return null;

  const dates = contracts?.reduce((accumulator, current) => {
    if (current[propertyName])
      accumulator.push(parseDate(current[propertyName]));
    return accumulator;
  }, []);
  if (dates.length <= 0) return null;

  return minDate(dates);
};

export const getObjectionPackageCodesInfo = (
  contracts,
  objectionPackageTypes,
) => {
  const activeElMeters = contracts?.filter(
    (contract) =>
      contract?.meter?.Commodity === commodities.energy &&
      contract?.meter?.IsActive,
  );

  const objectionContracts = activeElMeters?.filter((meter) => {
    const packageCode = meter?.Package?.PackageCode?.toLowerCase();
    return objectionPackageTypes.some((type) => packageCode.includes(type));
  });

  const hasObjectionPackages = objectionContracts?.length > 0;
  const hasOneObjectionPackage = objectionContracts?.length === 1;

  return {
    objectionContracts,
    hasObjectionPackages,
    hasOneObjectionPackage,
  };
};

export const getPackageIdsConfiguration = ({ heronPackages: packages }) => {
  const homePackagesIds = packages?.[programTypes.home]?.map((item) => ({
    id: item?.id,
    packageCode: item?.packageCode,
  }));

  const businessPackagesIds = packages?.[programTypes.business]?.map(
    (item) => ({ id: item?.id, packageCode: item?.packageCode }),
  );

  return homePackagesIds?.concat(businessPackagesIds);
};

const matchPackageCodeWithId = (packageCode, packagesIds) => {
  const packageId = packagesIds?.find(
    (programPackage) => programPackage?.packageCode === packageCode,
  )?.id;

  return packageId;
};

export const getPackageIdPath = (contract, packagesIds) => {
  const packageId = matchPackageCodeWithId(
    contract?.Package?.PackageCode,
    packagesIds,
  );
  return packageId ? `/${packageId}` : '';
};

export const getUniqueHeronPackagesBasedOnId = (packages) => {
  const uniquePackages = uniqWith(
    packages,
    (package1, package2) => package1?.id === package2?.id,
  );
  return uniquePackages;
};
