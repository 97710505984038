import { createTheme } from '@material-ui/core/styles';

const colors = {
  primary: '#00084e',
  secondary: '#323870',
  selected: '#545786',
  action: '#ffffff',
  success: '#009413',
  error: '#ff5800',
  purpleLight: '#d4d6ed',
  grayLight: '#e1e1df',
  grayMedium: '#adaeb1',
  energy: 'linear-gradient(90deg, rgb(198, 2, 13) 0%, rgb(249, 0, 10) 100%)',
  primaryFaded: 'rgba(0, 8, 78, 0.9)',
  actionFaded: 'rgba(255, 255, 255, 0.4)',
};

const theme = createTheme({
  palette: {
    primary: {
      light: colors.secondary,
      main: colors.primary,
    },
    secondary: {
      main: colors.action,
    },
    error: {
      main: colors.error,
    },
    success: {
      main: colors.success,
    },
  },
  typography: {
    fontFamily: `'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', 'sans-serif';`,
    button: {
      fontSize: '1rem',
      textTransform: 'none',
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: 'transparent',
      },
      elevation1: {
        boxShadow: 0,
      },
      rounded: {
        borderRadius: '10px',
      },
    },
    MuiSelect: {
      root: {
        outline: 'none',
        color: colors.action,
        border: 'none',
        fontWeight: '400',
        lineHeight: '1.25rem',
        minHeight: '2.5rem',
      },
      icon: {
        color: 'transparent',
        right: '0.5rem',
      },
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
      root: {
        background: colors.secondary,
      },
    },
    MuiMenuItem: {
      root: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        color: colors.action,
        background: colors.secondary,
        lineHeight: '1.25rem',
        minHeight: '2.5rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        paddingRight: '1rem',
      },
    },
    MuiButton: {
      contained: {
        color: colors.primary,
        borderRadius: '0.25rem',
        fontWeight: '700',
        lineHeight: '1.25rem',
        minHeight: '2.5rem',
        backgroundColor: colors.action,
        '&$disabled': {
          color: colors.primary,
          backgroundColor: colors.action,
          opacity: 0.5,
        },
      },
      outlined: {
        color: colors.action,
        borderRadius: '0.25rem',
        fontWeight: '700',
        lineHeight: '1.25rem',
        border: `0.0625rem solid ${colors.action}`,
        minHeight: '2.5rem',
        '&$disabled': {
          color: colors.action,
          border: `0.0625rem solid ${colors.action}`,
          opacity: 0.5,
        },
      },
    },
    MuiAccordion: {
      root: {
        color: colors.action,
        backgroundColor: colors.secondary,
        borderRadius: '0',
        fontWeight: '400',
        lineHeight: '1.25rem',
        minHeight: '2.5rem',
        '&$disabled': {
          color: colors.primary,
          backgroundColor: colors.action,
          opacity: 0.5,
        },
        '&$expanded': {
          margin: 0,
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: '0 0.5rem ',
        '&$expanded': {
          minHeight: 'auto',
        },
      },
      content: {
        '&$expanded': {
          margin: '1.125rem 0',
        },
        margin: '1.125rem 0',
      },
      expandIcon: {
        padding: '1rem 1.5rem',
        marginRight: '-1.5rem',
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: '0 3rem 1.125rem 0.5rem',
      },
    },
    MuiInputLabel: {
      formControl: {
        transform: 'translate(8px, 20px) scale(1)',
      },
      shrink: {
        transform: 'translate(8px, 12px) scale(0.75)',
      },

      root: {
        '&$error': {
          color: colors.error,
          '&$focused': {
            color: colors.error,
          },
        },
        color: colors.grayLight,
        '&$focused': {
          color: colors.grayLight,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '125%',
        },
        '&$shrink': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '125%',
        },
        '&$disabled': {
          color: colors.actionFaded,
          '&$shrink': {
            color: colors.grayLight,
          },
          '&$focused': {
            color: colors.grayLight,
          },
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiSvgIcon: {
      root: {
        color: colors.action,
        backgroundColor: 'transparent',
      },
    },
    MuiFormHelperText: {
      root: {
        color: colors.action,
        paddingLeft: '0.5rem',
        fontSize: '0.875rem',
        lineHeight: '1.125rem',
        '&$disabled': {
          color: colors.actionFaded,
        },
      },
    },
    MuiSkeleton: {
      root: {
        backgroundColor: colors.selected,
      },
    },
    MuiTabs: {
      root: {
        minHeight: '2rem !important',
        width: '100%',
        padding: '0 1.5rem',
      },
      indicator: {
        height: '0.125rem',
      },
      flexContainer: {
        justifyContent: 'space-around',
      },
    },
    MuiTab: {
      root: {
        minHeight: '2rem !important',
        '&$selected': {
          fontWeight: '700',
        },
        '&$textColorInherit': { opacity: '1', lineHeight: '1.3rem' },
      },
    },
    MuiChip: {
      avatar: {
        marginLeft: '0.75rem!important',
      },
      root: {
        color: colors.action,
        fontWeight: '700',
        fontSize: '1rem',
        height: '2rem',
        MuiChip: {
          avatarColorSecondary: {
            background: colors.energy,
          },
        },
      },
      label: {
        paddingLeft: '0.75rem',
        paddingRight: '0.75rem',
      },
      avatarColorPrimary: {
        backgroundColor: 'transparent !important',
      },
      avatarColorSecondary: {
        backgroundColor: 'transparent !important',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: colors.primaryFaded,
      },
    },
    MuiDialog: {
      paper: {
        boxShadow: 'none',
        background: colors.secondary,
        color: colors.action,
        borderRadius: '1rem',
      },
      paperWidthFalse: {
        maxWidth: '(100%-1rem)',
        margin: '1rem',
      },
    },
    MuiDialogContent: {
      root: {
        '&:first-child': {
          paddingTop: 0,
        },
        padding: 0,
      },
    },
    MuiDialogActions: {
      root: {
        padding: 0,
      },
      spacing: {
        '& > :not(:first-child)': {
          marginLeft: 0,
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
      },
    },
    MuiButtonBase: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
        verticalAlign: 'unset',
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
        minWidth: '100%',
        verticalAlign: 'unset',
      },
    },
    MuiInputBase: {
      adornedEnd: {
        paddingRight: 0,
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        padding: '0',
        '&[class*="MuiInput-root"]': {
          padding: '0.75rem 0.5rem 0.625rem',
        },
      },
      input: {
        '&:first-child': {
          height: '1.125rem',
          padding: '0.75rem 0.5rem 0.625rem',
        },
      },
      paper: {
        color: colors.action,
        backgroundColor: colors.secondary,
        boxShadow:
          '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
      },
      option: {
        color: colors.white,
      },
      noOptions: {
        color: colors.white,
      },
      endAdornment: {
        margin: '0 0.5rem',
        top: 'calc(50% - 1.25rem)',
      },
      clearIndicator: {
        display: 'none',
      },
      clearIndicatorDirty: {
        visibility: 'hidden',
      },
    },
    MuiInput: {
      input: {
        '&:-webkit-autofill': {
          WebkitTextFillColor: colors.action,
          WebkitBackgroundClip: 'text',
          caretColor: colors.action,
        },
        fontWeight: '700',
        padding: '0.75rem 0.5rem 0.625rem',
        height: '1.125rem',
      },
      root: {
        color: colors.action,

        '&$disabled': {
          color: colors.actionFaded,
          '&$focused': {
            color: colors.actionFaded,
          },
          '&:hover': {
            color: colors.actionFaded,
          },
        },
      },
      multiline: {
        padding: '0.75rem 0.5rem 0.625rem',
      },
      inputMultiline: {
        padding: 0,
      },
      underline: {
        '&:before': {
          borderBottom: `0.0625rem solid ${colors.purpleLight}`,
        },
        '&:after': {
          borderBottom: `0.125rem solid ${colors.purpleLight}`,
        },
        '&&:hover:not(.Mui-disabled):before': {
          borderBottom: `0.125rem solid ${colors.purpleLight}`,
        },
        '&&:hover:before': {
          borderBottom: `0.0625rem solid ${colors.purpleLight}`,
        },
        '&$disabled': {
          '&&:before': {
            borderBottom: `0.0625rem solid ${colors.purpleLight}`,
          },
        },
      },
    },
    MuiInputAdornment: {
      root: {
        margin: 0,
        height: '2.5rem',
        padding: 0,
        top: 'calc(50% - 1.25rem)',
        textAlign: 'center',
      },
    },
    MuiOutlinedInput: {
      root: {
        height: '2.5rem',
        textAlign: 'center',
        justifyContent: 'center',
        margin: 0,
        padding: 0,
        color: colors.action,
      },
      notchedOutline: {
        borderColor: `${colors.action} !important`,
      },
      adornedStart: {
        paddingLeft: '0.5rem !important',
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: '1rem',
        background: colors.secondary,
      },
    },
    MuiRadio: {
      root: {
        padding: '0',
        margin: '0 0.5rem 0 0',
      },
    },
    MuiCheckbox: {
      root: {
        padding: '0 0.25rem 0 0',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: colors.secondary,
        padding: '0',
        borderRadius: '20px',
        minWidth: '120px',
        minHeight: '200px',
      },
    },
    MuiPickersDay: {
      day: {
        color: colors.action,
        fontSize: '0.875rem',
        lineHeight: '1.125rem',
      },
      daySelected: {
        backgroundColor: colors.purpleLight,
        color: colors.secondary,
        '&:hover': {
          backgroundColor: colors.action,
          color: colors.secondary,
        },
        '& p': {
          fontWeight: '700',
        },
      },
      current: {
        color: colors.action,
        fontWeight: '700',
        '& p': {
          fontWeight: '700',
        },
      },
      dayDisabled: {
        color: colors.actionFaded,
      },
    },
    MuiPickersMonth: {
      root: {
        color: colors.action,
        height: '60px',
        width: '60px',
        fontSize: '1rem',
      },
      monthSelected: {
        color: colors.action,
        fontSize: '1.25rem',
        fontWeight: '700',
      },
      monthDisabled: {
        color: colors.actionFaded,
        cursor: 'not-allowed',
      },
    },
    MuiPickersYear: {
      root: {
        color: colors.action,
        width: '100%',
        margin: 0,
        flex: '0 0 2rem',
      },
      yearSelected: {
        fontSize: '1.125rem',
        fontWeight: '700',
        margin: '0.25rem 0',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        position: 'absolute',
        height: '2rem',
        minHeight: '2rem',
        backgroundColor: 'transparent',
        top: '1.5rem ',
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        margin: '0 4px 0 0',
        fontSize: '0.875rem',
        fontWeight: '700',
        lineHeight: '2rem',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        fontSize: '0.875rem',
        fontWeight: '700',
        lineHeight: '2rem',
      },
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        order: 0,
        '& > p': {
          textAlign: 'left',
          fontSize: '0.875rem',
          lineHeight: '1.125rem',
          fontWeight: '700',
        },
        display: 'none',
      },
      iconButton: {
        paddingLeft: '0.625rem',
        order: 1,
        backgroundColor: 'transparent',
        '&:disabled': {
          opacity: 0.5,
        },
        '&  svg': {
          width: '2rem',
          height: '2rem',
        },
      },
      switchHeader: {
        alignItems: 'center',
        color: colors.action,
        marginBottom: '1rem',
        padding: '1.5rem 1rem 0 1.5rem ',
        justifyContent: 'flex-end',
      },
      daysHeader: {
        padding: '0',
        margin: '0.25rem 0 1rem',
        '&>span': {
          color: 'rgba(255,255,255,0.65)',
          fontSize: '0.875rem',
          lineHeight: '1.125rem',
          fontWeight: '700',
        },
      },
      dayLabel: {
        color: colors.action,
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        marginTop: 0,
        minHeight: '220px',
        minWidth: '304px',
      },
    },

    MuiPickersMonthSelection: {
      container: {
        minWidth: '304px',
        padding: '1rem',
      },
    },
    MuiPickersYearSelection: {
      container: {
        // padding: '1.5rem 1rem 1.5rem 1.5rem',
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'stretch',
        minWidth: '304px',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        marginRight: 0,
        alignItems: 'flex-start',
      },
      label: {
        color: colors.action,
        '&$disabled': {
          color: colors.action,
        },
      },
    },
    MuiSwitch: {
      root: {
        width: '44px',
        height: '1.5rem',
        padding: 0,
        margin: '0 0.75rem 0 0',
      },
      thumb: {
        width: 20,
        height: 20,
      },
      //focusVisible: {},
      switchBase: {
        padding: '2px',
        '&$checked': {
          transform: 'translateX(20px)',
          color: colors.success,
          '& + $track': {
            backgroundColor: colors.grayMedium,
            opacity: 1,
            border: 'none',
          },
        },
        '&$focusVisible $thumb': {
          color: colors.grayMedium,
          border: '6px solid #fff',
        },
      },
      colorSecondary: {
        '&$checked': {
          '& + $track': {
            backgroundColor: colors.success,
          },
        },
      },

      track: {
        borderRadius: 26 / 2,
        border: `2px solid ${colors.grayMedium}`,
        backgroundColor: colors.grayMedium,
        opacity: 1,
        transition: 'background-color, border',
      },
    },
    MuiStepIcon: {
      root: {
        height: '1.25rem',
        width: '1.25rem',
      },
      text: {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
      },
    },
    MuiSlider: {
      root: {
        height: '0.75rem',
        padding: '0.5rem 0',
      },
      rail: {
        height: '0.75rem',
        borderRadius: '0.5rem',
        backgroundColor: colors.grayLight,
      },
      track: {
        height: '0.75rem',
        borderRadius: '0.5rem',
      },
      thumb: {
        width: '1.25rem',
        height: '1.25rem',
        marginTop: '-0.25rem',
        marginLeft: '-0.625rem',
        '&::after': {
          top: '-0.5rem',
          left: '-0.5rem',
          right: '-0.5rem',
          bottom: '-0.5rem',
        },
      },
      markLabel: {
        color: colors.action,
        fontSize: '1rem',
        lineHeight: 1.2,
        marginTop: '0.5rem',
        top: '1.25rem',
      },
      markLabelActive: {
        color: colors.action,
      },
      marked: {
        marginBottom: '1.5rem',
      },
      mark: {
        display: 'none',
      },
      colorSecondary: {
        color: '#FFF375',
        '& .MuiSlider-track': {
          color: '#FFF375',
          background:
            'linear-gradient(90deg, #FE663D 0%, #FFF04A 53%, #FFF9C5 100%)',
        },
      },
      thumbColorSecondary: {
        '&:hover': {
          boxShadow: '0px 0px 0px 0.25rem rgb(255 255 255 / 16%)',
        },
        '&.MuiSlider-active': {
          boxShadow: '0px 0px 0px 0.5rem rgb(255 255 255 / 16%)',
        },
      },
    },
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
});

export { theme };
