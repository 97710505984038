import React from 'react';
import { useFormContext } from 'react-hook-form';

import FindProgramImage from 'components/OnboardingNewClient/FindProgram/FindProgramImage';
import RadioButton from 'components/RadioButtonsGroup/RadioButton';
import RadioGroup from 'components/RadioButtonsGroup/RadioGroup';

import styles from './FindProgramBody.module.scss';

const FindProgramBody = ({
  items,
  info,
  image,
  question,
  nextRouteData,
  onChecked,
}) => {
  const { control, register } = useFormContext();

  return (
    <>
      <FindProgramImage svgImage={image} />

      {items &&
        items.map((item, index) => (
          <article key={`items_${index}`}>
            <p
              className={`${styles.paragraph} ${
                index > 0 ? styles.paragraphNext : ''
              }`}
            >
              {item.description}
            </p>

            <RadioGroup
              control={control}
              name={item.name}
              value={nextRouteData[item.name] || ''}
              handleChange={(event) => {
                onChecked(item.name, event.target.value);
              }}
            >
              {item.choices &&
                item.choices.map((element, index) => {
                  const { value, label } = element || {};
                  return (
                    <RadioButton
                      key={value}
                      value={`${value}`}
                      label={label}
                      register={register}
                      name={item.name}
                      className={styles.radio}
                    />
                  );
                })}
            </RadioGroup>
          </article>
        ))}
      {info && (
        <ul className={styles.list}>
          {info.map((infoText, index) => (
            <li key={index}>{infoText}</li>
          ))}
        </ul>
      )}
    </>
  );
};

export default FindProgramBody;
