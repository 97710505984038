import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorDialog } from 'components/PromptDialogs/PromptDialog';

const UnavailableRequestDialog = ({ open, closeDialog }) => {
  const { t } = useTranslation(['requests']);
  return (
    <ErrorDialog
      open={open}
      closeDialog={closeDialog}
      header={t(`requests:request-unavailable-title`)}
      message={t(`requests:request-unavailable-message`)}
    />
  );
};
export default UnavailableRequestDialog;
