import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as SuccessIcon } from 'assets/icons/LoyaltySuccessIcon.svg';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';

import { useCollectionOptions } from 'data/useCollectionOptions';
import RewardsWidget from 'components/Loyalty/RewardsWidget';
import { collectionOptionTypes } from 'utils/product/enums/cmsEnums';
import { promotionTypes } from 'utils/product/enums/cmsEnums';
import PromotionWidget from './PromotionWidget';

import styles from './QuestionnaireSuccessDialog.module.scss';

const QuestionnaireSuccessDialog = ({ open, closeDialog, onClose }) => {
  const { t, i18n } = useTranslation(['loyalty', 'translation']);
  const { data, loading } = useCollectionOptions(i18n.language);

  //TODO: check if we can use getPointsOfCategory
  const getPoints = (_data) => {
    if (!data || data.length === 0) return 0;
    const benefit = _data.find(
      (x) => x.type === collectionOptionTypes.REGISTER_LOYALTY
    );
    return benefit?.benefits && benefit?.benefits.length > 0
      ? benefit?.benefits[0].value
      : 0;
  };

  return (
    <>
      <DialogOverlay
        open={open}
        closeDialog={() => {
          closeDialog();
          if (typeof onClose === 'function') onClose();
        }}
        scroll="body"
        dialogHeader={t(`loyalty:register.success.header`)}
        maxWidth="sm"
      >
        <div className={styles.container}>
          <SuccessIcon
            width="60"
            height="60"
            alt="success"
            className={styles.successIcon}
          />
          <p className={styles.title}>{t(`loyalty:register.success.title`)}</p>
          <RewardsWidget
            loading={loading}
            heightInPixels={206}
            points={getPoints(data)}
          >
            <p>
              <Trans
                i18nKey={t(`loyalty:register.success.winDescription`)}
                components={{
                  yellow: <span className={styles['warning-text']}></span>,
                }}
              />
            </p>
          </RewardsWidget>
          <PromotionWidget
            promotionType={promotionTypes.loyaltyRegistration}
            closeDialog={closeDialog}
          />
        </div>
      </DialogOverlay>
    </>
  );
};

export default QuestionnaireSuccessDialog;
