import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import LoginWidget from 'pages/onboarding/LoginWidget';
import OnboardingLayout from 'layouts/OnboardingLayout';
import { removeChat } from 'utils/product/chatUtils';
import useWebDowntime from 'data/useWebDowntime';

import styles from './ComingSoon.module.scss';

const ComingSoon = () => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const { data: { downTimeWeb = {}, hasDownTime } = {} } =
    useWebDowntime(i18n.language) || {};

  const _defaultDowntime = hasDownTime ? downTimeWeb : {};

  const { downTimeWeb: { message } = _defaultDowntime } = location.state || {};

  useEffect(() => {
    try {
      removeChat();
    } catch (e) {}
  }, []);

  if (!hasDownTime) return <Navigate to={'/'} />;

  return (
    <OnboardingLayout>
      {message && (
        <LoginWidget containerClass={styles.widgetContainer}>
          <p dangerouslySetInnerHTML={{ __html: message }}></p>
        </LoginWidget>
      )}
    </OnboardingLayout>
  );
};

export default ComingSoon;
