import React from 'react';

import BillAmounts from 'components/Bills/BillAmounts';
import BillMessage from 'components/Bills/BillMessage';

import styles from './BillOverview.module.scss';

const BillOverview = ({
  amount,
  strikedAmount,
  invoice,
  meter,
  isLastInvoice,
  billAmountType,
  containerClass = '',
  messageClass = '',
  amountClass = '',
}) => {
  return (
    <div className={`${styles.container} ${containerClass}`}>
      <BillMessage
        invoice={invoice}
        meter={meter}
        isLastInvoice={isLastInvoice}
        containerClass={`${styles.billMessage} ${messageClass}`}
      />

      <BillAmounts
        amount={amount}
        strikedAmount={strikedAmount}
        type={billAmountType}
        containerClass={`${styles.billAmounts} ${amountClass}`}
        invoice={invoice}
      />
    </div>
  );
};

export default BillOverview;
