import { formatNumber } from 'utils/moneyUtils';
import { sortByDateFieldDescending } from 'utils/sortUtils';
import { getMeterNightIndicationDictionary } from './meterUtils';

export const mergeOwnMeasurements = (data, meters) => {
  if (!data || !data.MeterOwnMeasurements) return [];

  const nightMeterDictionary = getMeterNightIndicationDictionary(meters);
  const ownMeasurements = data.MeterOwnMeasurements.map((x) => {
    return x.ownmeasurements.map((ownMeasurement) => {
      return {
        ...ownMeasurement,
        DayMeasurementString: formatOwnMeasurements(
          ownMeasurement.DayMeasurement || 0
        ),
        NightMeasurementString: formatOwnMeasurements(
          ownMeasurement.NightMeasurement || 0
        ),
        MeterId: x.MeterId,
        FriendlyName: x.FriendlyName,
        MeterNo: x.meterNo,
        Commodity: x.Commodity,
        hasNightIndication: nightMeterDictionary[x.MeterId] || false,
      };
    });
  });
  const results = ownMeasurements.reduce(
    (accumulator, currentValue) => [...accumulator, ...currentValue],
    []
  );

  sortByDateFieldDescending(results, 'ReadingDate');

  return results;
};

export const formatOwnMeasurements = (number) => {
  if (!number) return '0';

  return formatNumber(number);
};

export const formatClearingMeasurements = (number) =>
  `${formatNumber(Math.trunc(number))} kWh`;
