import React from 'react';
import { SWRConfig } from 'swr';

const SWRConfigCustom = ({ children }) => {
  return (
    <SWRConfig
      value={{
        dedupingInterval: 900000,
        revalidateOnFocus: false,
        onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
          if (error?.status === 404) return;
          // Only retry up to 3 times.
          if (retryCount >= 2) return;
          // Retry after 5 seconds.
          setTimeout(
            () => revalidate({ retryCount }),
            retryCount === 1 ? 7000 : 12000,
          );
        },
      }}
    >
      {children}
    </SWRConfig>
  );
};

export default SWRConfigCustom;
