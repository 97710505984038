import React from 'react';
import useData from 'data/useData';
import { useAuthUser } from 'utils/AuthUser';

import { getGroupedMeterPayments } from 'utils/product/paymentUtils';

export default function useAccountPayments(skip = false) {
  const { accountId } = useAuthUser();
  const { data, error, loading } = useData(
    skip ? null : `/account/payments?accountId=${accountId}`
  );

  const paymentData = React.useMemo(() => {
    return getGroupedMeterPayments(data);
  }, [data]);

  return {
    groupedPayments: paymentData.groupedPayments,
    payments: paymentData.allPayments,
    loading,
    error,
    data,
  };
}
