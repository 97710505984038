import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthUser } from 'utils/AuthUser';
import { initializeChat } from 'utils/product/chatUtils';
import authStorage from 'utils/storage/authStorage';

function ChatWidget() {
  const { i18n } = useTranslation();
  const { loading, user } = useAuthUser();
  const [chatInitialized, setChatInitialized] = useState(false);
  useEffect(() => {
    const token = authStorage.getToken();
    if (!chatInitialized && !loading && user) {
      initializeChat(token, i18n.language);
      setChatInitialized(true);
    }

    return () => {};
  }, [i18n.language, chatInitialized, loading, user]);

  return null;
}

export default ChatWidget;
