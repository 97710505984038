import React from 'react';

import styles from './HistoryRecord.module.scss';

const HistoryRecordItem = ({ text, value }) => {
  return (
    <div className={styles['records']}>
      <section className={styles['left-section']}>{text}</section>
      <section className={styles['right-section']}>{value}</section>
    </div>
  );
};

export default HistoryRecordItem;
