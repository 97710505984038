import React from 'react';
import { useTranslation } from 'react-i18next';

import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import SingleColumnLayout from 'layouts/SingleColumnLayout';

const MovingTerms = () => {
  const { t } = useTranslation(['requests']);

  return (
    <SingleColumnLayout>
      <Widget
        size="m"
        title={<WidgetHeader title={t(`requests:terms.title`)} />}
      >
        <p
          dangerouslySetInnerHTML={{ __html: t(`requests:terms.content`) }}
        ></p>
      </Widget>
    </SingleColumnLayout>
  );
};

export default MovingTerms;
