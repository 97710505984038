import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useAccountMeters from 'data/useAccountMeters';
import useDialog from 'hooks/useDialog';
import { getAvailableMetersForProgramChange } from 'utils/product/shopUtils';
import { trackShopProgramEvent } from 'utils/product/tracking/events';
import MetersListDialog from 'components/RequestsHome/MetersListDialog';
import { PrimaryButton } from 'components/Buttons/Buttons';
import ChangeProgramDialog from 'components/ChangeProgramDialog/ChangeProgramDialog';

import styles from './ProgramActions.module.scss';

const ChangeProgramButton = ({
  program,
  title,
  energyType,
  programType,
  generousGuarantee,
}) => {
  const { t } = useTranslation(['shop']);
  const confirmationDialog = useDialog();
  const metersSelectionDialog = useDialog();

  const {
    meters,
    loading: metersLoading,
    mutate,
  } = useAccountMeters({
    statusesElectricity: [],
    statusesGas: [],
  });
  const availableMeters = !metersLoading
    ? getAvailableMetersForProgramChange(
        meters,
        energyType,
        programType,
        program?.tariffGroups,
        program?.agreedPower,
        program?.ppcTypes,
      )
    : [];

  const canActivate = availableMeters.length > 0;

  const [selectedMeterId, setSelectedMeterId] = useState(
    availableMeters.length === 1 ? availableMeters[0].Id : '',
  );

  useEffect(() => {
    if (metersSelectionDialog.isOpen === false) {
      metersSelectionDialog.clearError();
    }
  }, [metersSelectionDialog]);

  const handleChangeProgram = () => {
    metersSelectionDialog.close();
    confirmationDialog.show();
  };

  return (
    canActivate && (
      <>
        <PrimaryButton
          className={styles.actionButton}
          onClick={() => {
            metersSelectionDialog.show();
            trackShopProgramEvent('shop_program_change', title);
          }}
        >
          {t('shop:program.change-program')}
        </PrimaryButton>
        <MetersListDialog
          dialogHeader={t('shop:program.activate-header')}
          message={t(`shop:program.activate-title`)}
          open={metersSelectionDialog.isOpen}
          closeDialog={metersSelectionDialog.close}
          clickHandler={handleChangeProgram}
          setSelectedMeter={setSelectedMeterId}
          meters={availableMeters}
          buttonLoading={metersLoading}
          alignActions="right"
        />
        <ChangeProgramDialog
          confirmationDialog={confirmationDialog}
          meters={availableMeters}
          mutateMeters={mutate}
          program={program}
          programTitle={title}
          selectedMeterId={selectedMeterId}
          generousGuarantee={generousGuarantee}
        />
      </>
    )
  );
};

export default ChangeProgramButton;
