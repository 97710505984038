import api from './api';

const forgotUserPassword = (email) => {
  return api.post('/user/password/forgot', { email });
};

const resetUserPassword = (data) => {
  return api.post('/user/password/reset', data);
};

const setUserConfirm = (emailAndTokenObject) => {
  return api.post('/user/confirm', emailAndTokenObject);
};

const setUserLogout = () => {
  return api.post('/user/logout');
};

const setUserPassword = (userInfo) => {
  return api.post('/user/password/change', userInfo);
};

const setUserRegister = (userRegistrationInfo) => {
  return api.post('/user/register', userRegistrationInfo);
};

export {
  forgotUserPassword,
  resetUserPassword,
  setUserConfirm,
  setUserLogout,
  setUserPassword,
  setUserRegister,
};
