import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tab, Tabs } from 'components/Tabs/Tabs';
import { ReactComponent as CreditCardIcon } from 'assets/icons/CreditCardIcon.svg';
import { ReactComponent as EnaGiftIcon } from 'assets/icons/EnaGiftIcon.svg';
import { ReactComponent as EmptyBillsHistory } from 'assets/icons/EmptyBillsHistory.svg';
import useSolarisPaymentHistory from 'data/useSolarisPaymentHistory';
import useSolarisDiscountHistory from 'data/useSolarisDiscountHistory';
import enaPaymentTypes from 'utils/product/enums/enaPaymentTypes';
import { trackEnaEvent } from 'utils/product/tracking/events';
import { formatDate } from 'utils/dateUtils';
import { dateFormats } from 'utils/product/config';
import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import IconText from 'components/IconText/IconText';
import EuroContainer from 'components/EuroContainer/EuroContainer';
import Pager from 'components/Pager/Pager';
import EmptyState from 'components/History/EmptyState';
import TabPanel from 'components/Tabs/TabPanel';
import HistoryMeterChips from 'components/Chip/HistoryMeterChips';

import styles from './EnaHistoryWidget.module.scss';

const EnaHistoryWidget = ({ meters, metersLoading }) => {
  const pagerLimit = 8;
  const { t } = useTranslation(['translation', 'ena']);
  const [value, setValue] = useState(0);

  const {
    discounts,
    loading: discountsLoading,
    data: discountData,
  } = useSolarisDiscountHistory();

  const {
    payments,
    loading: paymentsLoading,
    data: paymentData,
  } = useSolarisPaymentHistory(value !== 1);

  const [limit, setLimit] = useState(pagerLimit);
  const [paymentsLimit, setPaymentsLimit] = useState(pagerLimit);
  const [paymentChosenMeter, setPaymentChosenMeter] = useState('all');
  const [discountChosenMeter, setDiscountChosenMeter] = useState('all');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    trackEnaEvent(
      newValue === 0 ? 'ENA_history_discounts' : 'ENA_history_participation',
    );
  };
  const handleLimitChange = (value) => {
    setLimit(value);
  };

  const handlePaymentsLimitChange = (value) => {
    setPaymentsLimit(value);
  };

  const handlePaymentChosenMeter = (meter) => {
    const tempMeter = meter !== 'all' ? meter.Id : 'all';
    setPaymentChosenMeter(tempMeter);
  };

  const handleDiscountChosenMeter = (meter) => {
    const tempMeter = meter !== 'all' ? meter.Id : 'all';
    setDiscountChosenMeter(tempMeter);
  };

  const filteredDiscounts =
    discountChosenMeter === 'all'
      ? discounts
      : discounts.filter((x) => x.MeterId === discountChosenMeter);

  const filteredPayments =
    paymentChosenMeter === 'all'
      ? payments
      : payments.filter((x) => x.MeterId === paymentChosenMeter);

  return (
    <Widget
      distance="s"
      title={<WidgetHeader title={t('ena:history.title')} />}
    >
      <section className={styles['tabs-container']}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={t('ena:history.discounts')} />
          <Tab label={t('ena:history.participation')} />
        </Tabs>
      </section>
      <section className={styles['tabsPanel-container']}>
        <TabPanel
          value={value}
          index={0}
          idPrefix="ena-discounts"
          innerClass={styles['tabPanel-innerContainer']}
        >
          <WidgetSkeleton distance="s" show={discountsLoading || metersLoading}>
            <HistoryMeterChips
              chipKeys={discountData?.map((x) => x.MeterId)}
              meters={meters}
              handlechosenMeter={handleDiscountChosenMeter}
              chosenMeter={discountChosenMeter}
            />
            {filteredDiscounts.length === 0 && (
              <EmptyState
                Icon={<EmptyBillsHistory />}
                text={t('ena:history.no-discount')}
              />
            )}
            {filteredDiscounts.length > 0 && (
              <>
                <article className={styles.listContainer}>
                  {filteredDiscounts.slice(0, limit).map((discount, index) => {
                    const dateString = formatDate(
                      discount.DiscountDate,
                      dateFormats.dayFullMonthYear,
                    );
                    return (
                      <div className={styles.panel} key={`discount_${index}`}>
                        <IconText content={dateString} />
                        <EuroContainer content={Math.abs(discount.Discount)} />
                      </div>
                    );
                  })}
                </article>
                <Pager
                  totalCount={filteredDiscounts.length}
                  onClick={handleLimitChange}
                  containerClass={styles['moreButton-container']}
                />
              </>
            )}
          </WidgetSkeleton>
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          idPrefix="ena-payments"
          innerClass={styles['tabPanel-innerContainer']}
        >
          <WidgetSkeleton distance="s" show={paymentsLoading || metersLoading}>
            <HistoryMeterChips
              chipKeys={paymentData?.map((x) => x.MeterId)}
              meters={meters}
              handlechosenMeter={handlePaymentChosenMeter}
              chosenMeter={paymentChosenMeter}
            />
            {filteredPayments.length === 0 && (
              <EmptyState
                Icon={<EmptyBillsHistory />}
                text={t('ena:history.no-participation')}
              />
            )}
            {filteredPayments.length > 0 && (
              <>
                <article className={styles.listContainer}>
                  {filteredPayments
                    .slice(0, paymentsLimit)
                    .map((payment, index) => {
                      const dateString = formatDate(
                        payment.Date,
                        dateFormats.dayFullMonthYear,
                      );
                      return (
                        <div className={styles.panel} key={`${index}`}>
                          <IconText
                            Icon={
                              payment.Type === enaPaymentTypes.Discount ? (
                                <EnaGiftIcon />
                              ) : (
                                <CreditCardIcon />
                              )
                            }
                            content={dateString}
                          />
                          <EuroContainer content={payment.Ammount} />
                        </div>
                      );
                    })}
                </article>
                <Pager
                  totalCount={filteredPayments.length}
                  onClick={handlePaymentsLimitChange}
                  containerClass={styles['moreButton-container']}
                />
              </>
            )}
          </WidgetSkeleton>
        </TabPanel>
      </section>
    </Widget>
  );
};
export default EnaHistoryWidget;
