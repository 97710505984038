import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function useQuestionnaireFlow() {
  const navigate = useNavigate();
  const location = useLocation();

  const [step, setStep] = useState(0);

  const [nextRouteData, setNextRouteData] = useState({
    data: { ...location.state?.data },
    currentStep: location.state?.currentStep,
  });

  useEffect(() => {
    try {
      const _stepNumber = location.state?.currentStep || 0;
      setStep(_stepNumber);
    } catch (e) {
      setStep(0);
    }
  }, [location.state]);

  const getNextStep = (currentStep) => {
    return currentStep + 1;
  };

  const incrementStep = (data) => {
    const nextIndex = getNextStep(step);
    navigate(location.pathname, {
      state: { data: data, currentStep: nextIndex },
    });
  };

  const goBack = () => {
    navigate(-1);
  };

  const goForward = (data) => {
    incrementStep(data);
  };

  return {
    step,
    nextRouteData,
    setNextRouteData,
    goBack,
    goForward,
  };
}
