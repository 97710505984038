const endpoints = {
  loyalty: {
    customer: '/v1/customers/by-customerId',
    customers: '/v1/customers',
    submitQuestionnaire: '/v1/games/questionnaires/submit',
    rewardPayment: '/v1/reward-schemes/events/bill-payment',
    redeemOffer: '/v1/reward-schemes/redemption-options/redeem',
    receivePoints: '/v1/reward-schemes/events/custom',
    rewardSchemesAccounts: '/v1/reward-schemes/accounts',
    gameQuestionnaire: '/v1/games/questionnaire-templates',
    historyEvents: '/v1/reward-schemes/events',
    contests: '/v1/contests',
    participations: '/v1/contests/participations',
    notifications: '/v1/notifications/history',
    notificationsStatus: '/v1/notifications/history/has-unviewed',
    coupons: '/v1/coupons/distributions/check',
    games: '/v1/games/mini-games',
    gameSession: '/v1/games/mini-games/sessions',
    addToGoogleWallet: '/v1/mobile-passes/coupons/google-wallet',
    addToAppleWallet: '/v1/mobile-passes/coupons/apple-wallet',
  },
};

export default endpoints;
