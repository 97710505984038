import React from 'react';

import RequestStatus from 'components/RequestsWidget/RequestStatus';

import styles from './RequestBody.module.scss';

const RequestBody = ({ typeDescription, statusDescription, status }) => {
  return (
    <>
      <p className={styles.descriptionParagraph}>{typeDescription}</p>
      <p className={styles.statusParagraph}>
        <RequestStatus val={status} width="24" height="24" />
        <span className={styles.span}>{statusDescription}</span>
      </p>
    </>
  );
};

export default RequestBody;
