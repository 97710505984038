import React from 'react';

import styles from './FindProgramImage.module.scss';

const FindProgramImage = ({ svgImage }) => {
  return (
    <section className={styles.section}>
      <figure className={styles.figure}>{svgImage}</figure>
    </section>
  );
};

export default FindProgramImage;
