import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Helmet from 'utils/Helmet';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import Widget from 'components/Widget/Widget';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import { useLocation } from 'react-router-dom';
import VibesAcquiredDialog from 'components/Loyalty/VibesAcquiredDialog';
import useDialog from 'hooks/useDialog';
import { Tab, Tabs } from 'components/Tabs/Tabs';
import TabPanel from 'components/Tabs/TabPanel';
import CouponTab from './CouponTab';
import { useMyCouponsInfo } from 'data/useMyCouponsInfo';
import { useAuthUser } from 'utils/AuthUser';
import { trackProfileCouponsEvent } from 'utils/product/tracking/events';

const Coupons = () => {
  const { t, i18n } = useTranslation(['profile']);
  const { state } = useLocation();
  const { displayVibes } = state || {};
  const vibesDialog = useDialog(!!displayVibes);
  const [value, setValue] = useState(0);
  const { accountId } = useAuthUser();

  const handleTabChange = (event, newValue) => {
    if (newValue === 1) {
      trackProfileCouponsEvent('profile_my_coupons_inactive_selected');
    }
    setValue(newValue);
  };

  const { data: couponData, loading } = useMyCouponsInfo(i18n.language);

  useEffect(() => {
    window.history.replaceState({}, document.title);
  }, []);

  return (
    <>
      <Helmet
        head={{
          title: t(`profile:my-coupons.title`),
          metaTags: [{ name: 'title', content: t(`profile:my-coupons.title`) }],
        }}
      />
      {vibesDialog.isOpen && (
        <VibesAcquiredDialog
          open={vibesDialog.isOpen}
          closeDialog={() => {
            vibesDialog.close();
          }}
          type="redemption"
        />
      )}

      <WidgetSkeleton
        heightInPixels={352}
        show={loading}
        title={<WidgetHeader title={t(`profile:my-coupons.title`)} />}
      >
        <Widget
          background="transparent"
          title={<WidgetHeader title={t(`profile:my-coupons.title`)} />}
        >
          <Tabs
            value={value}
            onChange={handleTabChange}
            centered
            variant="fullWidth"
          >
            <Tab label={t('profile:my-coupons.active')} />
            <Tab label={t('profile:my-coupons.non-active')} />
          </Tabs>
          <TabPanel value={value} index={0} idPrefix="coupons">
            <CouponTab data={couponData?.active} customerId={accountId} />
          </TabPanel>
          <TabPanel value={value} index={1} idPrefix="coupons">
            <CouponTab data={couponData?.expired} customerId={accountId} />
          </TabPanel>
        </Widget>
      </WidgetSkeleton>
    </>
  );
};

export default Coupons;
