import React from 'react';
import CmsHtmlField from 'components/CmsHtmlField/CmsHtmlField';

import styles from './ProgramCost.module.scss';

const ProgramCost = ({ cost }) => {
  return (
    <section className={styles.container}>
      <h2 className={styles.title}>{cost.title}</h2>
      {cost.charges.map((item, index) => (
        <article
          key={index}
          className={`${styles['cost-item']} ${
            item.secondary === true ? styles.secondary : ''
          }`}
        >
          <span>{item.name}</span>
          <b dangerouslySetInnerHTML={{ __html: item.value }}></b>
        </article>
      ))}
      {cost.notes && (
        <CmsHtmlField className={styles.notes} value={cost.notes} />
      )}
    </section>
  );
};
export default ProgramCost;
