import React, { useState } from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { requestTypes } from 'utils/product/enums/requests';
import { createRequest } from 'api/requests';
import { useAuthUser } from 'utils/AuthUser';
import { trackEnaEvent } from 'utils/product/tracking/events';
import useDialog from 'hooks/useDialog';
import { getObjectEntries } from 'utils/objectUtils';

import { LinkButton } from 'components/Buttons/Buttons';
import {
  PromptDialog,
  SuccessDialog,
  ErrorDialog,
} from 'components/PromptDialogs/PromptDialog';

const EnaDeactivationWidget = ({ meter }) => {
  const { t } = useTranslation(['ena', 'translation', 'errors/generic']);
  const { user } = useAuthUser();

  const [isLoading, setIsLoading] = useState(false);

  const deactivationDialog = useDialog();
  const successDialog = useDialog();
  const errorDialog = useDialog();

  const showDeactivationDialog = () => {
    trackEnaEvent('ENA_analysis_deactivate');
    deactivationDialog.show();
  };

  const deactivate = () => {
    trackEnaEvent('ENA_analysis_deactivate_confirm');
    setIsLoading(true);
    const postData = {
      Ονοματεπώνυμο: get(
        user,
        'CompanyName',
        `${user.FirstName} ${user.LastName}`
      ),
      'Αριθμός παροχής': get(meter, 'MeterNo'),
      Τηλέφωνο: get(user, 'Mobile', get(user, 'Phone', '-')),
      email: get(user, 'Email', '-'),
    };
    const requestText = getObjectEntries(postData).map(
      ([key, value]) => `${key}: ${value}\n\r`
    );
    const postString = {
      AccountId: user.Id,
      MeterId: meter.Id,
      Commodity: meter.Commodity,
      RequestCategory: requestTypes.enaDeactivation,
      RequestText: `Απενεργοποίηση ΗΡΩΝ EN.A\n\r${requestText}`,
    };
    createRequest(postString)
      .then((result) => {
        deactivationDialog.close();
        if (result.data.ResponseCode === 200) {
          successDialog.show();
          return;
        }
        errorDialog.show();
      })
      .catch((error) => {
        deactivationDialog.close();
        errorDialog.show();
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  return (
    <section>
      <LinkButton onClick={showDeactivationDialog}>
        {t('ena:deactivation.cta')}
      </LinkButton>
      <PromptDialog
        open={deactivationDialog.isOpen}
        closeDialog={deactivationDialog.close}
        header={t(`ena:deactivation.dialog-title`)}
        message={t(`ena:deactivation.dialog-message`)}
        primaryActionHandler={deactivate}
        primaryActionLoading={isLoading}
        primaryActionText={t(`ena:deactivation.confirm`)}
        showCloseButton={true}
      />
      <SuccessDialog
        open={successDialog.isOpen}
        closeDialog={successDialog.close}
        header={t(`ena:deactivation.success`)}
        message={t(`ena:deactivation.success-message`)}
      />
      <ErrorDialog open={errorDialog.isOpen} closeDialog={errorDialog.close} />
    </section>
  );
};

export default EnaDeactivationWidget;
