import React, { useState } from 'react';

import useDialog from 'hooks/useDialog';
import paymentTypes from 'utils/product/enums/paymentTypes';

import { trackBillPaymentEvent } from 'utils/product/tracking/events';
import { getTrackingEnergyType } from 'utils/product/tracking/trackingLabels';

import HomeBillWidget from 'components/HomeBillWidget/HomeBillWidget';
import PaymentWidget from 'components/Payment/PaymentWidget';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';

const HomeBillsList = ({ limit = 0, loading, meters, onPaymentSuccess }) => {
  const [paymentInfo, setPaymentInfo] = useState({});

  const paymentDialog = useDialog();

  const showPaymentDialog = (meter) => {
    const lastInvoice = meter.LastInvoice;
    if (lastInvoice) {
      setPaymentInfo({
        PaymentAmount: meter.CurrentBalance,
        PaymentCode: lastInvoice.paymentCode,
        MeterId: meter.Id,
        Commodity: meter.Commodity,
        InvoiceNo: lastInvoice.invoiceNo,
        PaymentDueDate: lastInvoice.dueDate,
        meter: meter,
      });
      paymentDialog.show();
      trackBillPaymentEvent(
        'bill_payment',
        getTrackingEnergyType(meter.Commodity),
      );
    }
  };

  return (
    <>
      <WidgetSkeleton show={loading}>
        {meters.length > 0
          ? (limit > 0 ? meters.slice(0, limit) : meters).map((meter, i) => (
              <HomeBillWidget
                key={meter.Id}
                meterType={meter.type}
                meter={meter}
                onPaymentInvoked={showPaymentDialog}
              />
            ))
          : null}
      </WidgetSkeleton>

      <PaymentWidget
        open={paymentDialog.isOpen}
        closeDialog={paymentDialog.close}
        openDialog={paymentDialog.show}
        paymentAmount={paymentInfo?.PaymentAmount}
        paymentCode={paymentInfo?.PaymentCode}
        paymentType={paymentTypes.bill}
        meterId={paymentInfo?.MeterId}
        invoiceNo={paymentInfo?.InvoiceNo}
        meter={paymentInfo?.meter}
        commodity={paymentInfo?.Commodity}
        paymentDueDate={paymentInfo?.PaymentDueDate}
        onPaymentSuccess={() => {
          if (typeof onPaymentSuccess === 'function') {
            onPaymentSuccess();
          }
        }}
      />
    </>
  );
};
export default HomeBillsList;
