import React from 'react';

import { PromptDialog } from 'components/PromptDialogs/PromptDialog';
import { useTranslation } from 'react-i18next';
import { Coupon } from './Coupon';
import styles from './OfferAcquisitionSuccess.module.scss';

const OfferAcquisitionSuccess = ({
  offer,
  coupon,
  open,
  closeDialog,
  clickHandler,
}) => {
  const { t } = useTranslation(['translation']);

  if (!offer) return null;

  const { successMessage } = offer;

  return (
    <PromptDialog
      icon={<Coupon coupon={coupon} />}
      open={open}
      closeDialog={closeDialog}
      primaryActionText={t('offers:acquisition-flow.coupons')}
      primaryActionHandler={() => {
        closeDialog();
        clickHandler();
      }}
      showSecondaryAction={true}
      secondaryActionText={t('offers:acquisition-flow.return')}
      secondaryActionHandler={closeDialog}
      header={t(`offers:acquisition-flow.success-title`)}
      iconClass={styles.couponIcon}
    >
      <p>
        {successMessage || t(`offers:acquisition-flow.success-coupon-message`)}
      </p>
    </PromptDialog>
  );
};

export default OfferAcquisitionSuccess;
