import React from 'react';
import { useTranslation } from 'react-i18next';

import IconText from 'components/IconText/IconText';
import { ReactComponent as ConsumptionsIcon } from 'assets/icons/Consumptions.svg';

import styles from './ConsumptionEmpty.module.scss';

const ConsumptionOverviewEmpty = () => {
  const { t } = useTranslation(['consumptions']);

  return (
    <IconText
      Icon={<ConsumptionsIcon />}
      content={t('consumptions:overview.empty-message')}
      containerClass={styles.container}
      gap="xs"
    />
  );
};

export default ConsumptionOverviewEmpty;
