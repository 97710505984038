import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { resetUserPassword } from 'api/user';
import useQueryParams from 'hooks/useQueryParams';
import useDialog from 'hooks/useDialog';
import useError from 'hooks/useError';
import { trackForgotPasswordEvent } from 'utils/product/tracking/events';
import { ConfirmPasswordInput } from 'components/Inputs/ConfirmPasswordInput';
import { NewPasswordInput } from 'components/Inputs/NewPasswordInput';
import { SuccessDialog } from 'components/PromptDialogs/PromptDialog';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import { LocalisedErrorBox } from 'components/MessageBox/MessageBox';

import styles from './ChangePasswordContent.module.scss';
import { isEmptyString } from 'utils/stringUtils';

const ChangePasswordContent = () => {
  const { t } = useTranslation(['onboarding']);
  const baseName = 'onboarding:changePassword';
  const navigate = useNavigate();
  const { mail: email, token } = useQueryParams();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const { error, handleError, clearError } = useError();
  const successDialog = useDialog();

  const onSubmit = (data) => {
    trackForgotPasswordEvent('login_forgot_password_new_password_save');
    const { newPassword: password } = data;
    setLoading(true);
    resetUserPassword({ password, email, token })
      .then(() => {
        clearError();
        successDialog.show();
        trackForgotPasswordEvent('login_forgot_password_success');
      })
      .catch((error) => {
        handleError(error, 'resetPassword');
        trackForgotPasswordEvent('login_forgot_password_fail');
      })
      .then(() => {
        setLoading(false);
      });
  };

  const goToHomepage = () => {
    navigate('/login');
  };

  const closeDialog = () => {
    successDialog.close();
    goToHomepage();
  };

  useEffect(() => {
    if (isEmptyString(token)) {
      navigate('/');
      return;
    }
  }, [navigate, token]);

  return (
    <>
      <h3 className={styles.header}>{t(`${baseName}.header`)}</h3>
      <div className={styles.description}>
        {t(`${baseName}.description`, { email })}
      </div>
      <LocalisedErrorBox
        open={error !== null}
        closeBox={clearError}
        {...(error || {})}
        distanceTop=""
      />
      <form
        className={styles.container}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <NewPasswordInput
          register={register}
          errors={errors}
          name="newPassword"
          label={t(`${baseName}.new-password`)}
        />
        <ConfirmPasswordInput
          register={register}
          errors={errors}
          name="confirmPassword"
          comparedInputName="newPassword"
          label={t(`${baseName}.new-password-confirm`)}
          watch={watch}
        />
        <section className={styles.submit}>
          <PrimaryButtonLoader
            loading={loading}
            disabled={loading}
            type="submit"
          >
            {t(`${baseName}.save`)}
          </PrimaryButtonLoader>
        </section>
      </form>
      <SuccessDialog
        open={successDialog.isOpen}
        closeDialog={closeDialog}
        header={t(`${baseName}.success-title`)}
        message={t(`${baseName}.success-message`)}
        primarActionText={t(`${baseName}.return`)}
        primarActionHandler={goToHomepage}
      />
    </>
  );
};

export default ChangePasswordContent;
