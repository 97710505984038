import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import commodities from 'utils/product/enums/commodities';
import { getTrackingEnergyType } from 'utils/product/tracking/trackingLabels';

import paymentTypes from 'utils/product/enums/paymentTypes';

import { getInvoiceFile } from 'api/invoices';
import { openPdfOnNewTab } from 'utils/pdfUtils';
import {
  trackBillPdfEvent,
  trackBillPaymentEvent,
} from 'utils/product/tracking/events';

import { TextButton } from 'components/Buttons/Buttons';
import Widget from 'components/Widget/Widget';
import { ReactComponent as Checked } from 'assets/icons/CheckGreen.svg';

import IconText from 'components/IconText/IconText';
import useError from 'hooks/useError';
import BillAmounts from 'components/Bills/BillAmounts';
import { ReactComponent as SeeBillsIcon } from 'assets/icons/PdfIcon.svg';
import { PrimaryButton } from 'components/Buttons/Buttons';
import BillsDetails from 'components/BillsDetails/BillsDetails';
import BillsSettlement from 'components/BillsSettlement/BillsSettlement';
import { LocalisedErrorDialog } from 'components/PromptDialogs/PromptDialog';
import PaymentWidget from 'components/Payment/PaymentWidget';
import { useAuthUser } from 'utils/AuthUser';
import useDialog from 'hooks/useDialog';
import {
  isLastInvoice as checkIsLastInvoice,
  isRecurringActivated,
  checkInvoiceAvailability,
  checkActiveRecurringCard,
} from 'utils/product/invoiceUtils';
import useStandingOrderMeterInfo from 'data/useStandingOrderMeterInfo';

import styles from './BillsWidget.module.scss';

const BillsWidget = ({ meter, invoice }) => {
  const { t } = useTranslation(['translation', 'homepage', 'bills', 'ena']);
  const location = useLocation();

  const { accountId } = useAuthUser();
  const { error, handleError, clearError } = useError();
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const paymentDialog = useDialog();
  const errorDialog = useDialog();

  const { data: standingOrderCardDetails } = useStandingOrderMeterInfo(
    meter?.Id,
    meter?.Commodity,
    !meter?.Id,
  );

  const activeRecurringCard = checkActiveRecurringCard(
    standingOrderCardDetails,
  );

  const displayActivatedRecurring = isRecurringActivated({
    activeRecurringCard,
    meter,
    invoice,
    location,
  });

  const isLastInvoice = checkIsLastInvoice(location?.state, invoice);

  const isInvoiceAvailable = checkInvoiceAvailability(meter, invoice);

  const showPaymentButton = isLastInvoice && meter.CurrentBalance > 0;

  const shouldShowSettlement =
    meter.Commodity === commodities.energy && invoice && !meter.HasSettlement;

  const handleInvoiceClick = () => {
    if (!isInvoiceAvailable) {
      handleError('unavailable', 'invoiceFile');
      errorDialog.show();
      return;
    }
    trackBillPdfEvent(
      'bill_pdf_selected',
      getTrackingEnergyType(meter.Commodity),
    );
    setIsPdfLoading(true);
    getInvoiceFile(invoice, meter, accountId)
      .then((res) => {
        openPdfOnNewTab(res);

        clearError();
        trackBillPdfEvent(
          'bill_pdf_download_success',
          getTrackingEnergyType(meter.Commodity),
        );
      })
      .catch((error) => {
        handleError(error, 'invoiceFile');
        errorDialog.show();
        trackBillPdfEvent(
          'bill_pdf_download_fail',
          getTrackingEnergyType(meter.Commodity),
        );
      })
      .then(() => {
        setIsPdfLoading(false);
      });
  };

  useEffect(() => {
    setIsPdfLoading(false);
  }, [invoice]);

  return (
    <>
      <Widget size="m" distance="s">
        <h3 className={styles.headerText}>{t('ena:analysis.title')}</h3>
        <article className={styles.billContainer}>
          <section className={styles['billAmount-container']}>
            <BillAmounts
              amount={isLastInvoice ? meter.CurrentBalance : invoice.grandTotal}
              strikedAmount={isLastInvoice ? invoice.grandTotal : null}
              invoice={invoice}
              type={isLastInvoice ? 'lastInvoice' : ''}
              containerClass={styles.billAmounts}
              descriptionClass={styles.description}
            />
          </section>
          <section className={styles['billAnalysis-container']}>
            <BillsDetails invoice={invoice} />
            <TextButton
              containerClass={styles.pdfButtonContainer}
              className={styles.pdfButton}
              onClick={handleInvoiceClick}
              loading={isPdfLoading}
              disabled={isPdfLoading}
            >
              <IconText
                Icon={<SeeBillsIcon />}
                content={t('bills:see-bill')}
                gap="s"
              />
            </TextButton>
          </section>
          <div className={styles['billButtons-container']}>
            {displayActivatedRecurring && (
              <IconText
                Icon={<Checked />}
                content={t('bills:recurring-payment.active')}
                gap="xs"
              />
            )}
            {showPaymentButton && (
              <PrimaryButton
                className={styles.actionButton}
                onClick={() => {
                  paymentDialog.show();
                  trackBillPaymentEvent(
                    'bill_payment',
                    getTrackingEnergyType(meter.Commodity),
                  );
                }}
              >
                {t('translation:payment')}
              </PrimaryButton>
            )}
            {shouldShowSettlement && (
              <BillsSettlement
                isLastInvoice={isLastInvoice}
                meterId={meter.Id}
                totalAmount={meter.CurrentBalance}
              />
            )}
          </div>
        </article>
      </Widget>
      <PaymentWidget
        open={paymentDialog.isOpen}
        closeDialog={paymentDialog.close}
        openDialog={paymentDialog.show}
        paymentAmount={meter?.CurrentBalance}
        paymentCode={invoice?.paymentCode}
        paymentType={paymentTypes.bill}
        meterId={meter?.Id}
        meter={meter}
        invoiceNo={invoice?.invoiceNo}
        commodity={meter?.Commodity}
        paymentDueDate={invoice?.dueDate}
      />
      <LocalisedErrorDialog
        open={errorDialog.isOpen}
        closeDialog={errorDialog.close}
        showCloseButton={false}
        showPrimaryAction={true}
        primaryActionText={t('translation:ok')}
        primaryActionHandler={errorDialog.close}
        {...(error || {})}
      />
    </>
  );
};

export default BillsWidget;
