import React from 'react';
import Helmet from 'utils/Helmet';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import ErrorBoundary from 'components/ErrorBoundaries/ErrorBoundary';

import { ReactComponent as Logo } from 'assets/icons/LogoIcon.svg';
import LanguageSelector from 'components/LanguageSelector/LanguageSelector';
import { AppContext } from 'utils/AppContext';

import styles from './OnboardingLayout.module.scss';

const OnboardingLayout = ({
  children,
  containerClass = '',
  alignContainerCenter = true,
  head,
}) => {
  const { showAppBanner } = React.useContext(AppContext);
  return (
    <div className={cn(styles.wrapper, { [styles.hasBanner]: showAppBanner })}>
      <ErrorBoundary>
        <Helmet head={head} />
        <LanguageSelector type="transparent" />
        <div className={styles.logoContainer}>
          <Link to="/" aria-label="homepage">
            <Logo className={styles.logo} alt="myHeron logo" />
          </Link>
        </div>
        <div
          className={`${styles.container} ${
            containerClass.length > 0 ? containerClass : ''
          }`}
        >
          {children}
        </div>
        {alignContainerCenter && <div className={styles.helper}></div>}
      </ErrorBoundary>
    </div>
  );
};

export default OnboardingLayout;
