import React from 'react';

import IconText from 'components/IconText/IconText';

import styles from './WidgetLabel.module.scss';

const WidgetLabel = ({
  type,
  labelClass = '',
  labelStyle = {},
  icon,
  text,
  contentClass = '',
  ...rest
}) => {
  return (
    <article className={`${styles.label} ${labelClass}`} style={labelStyle}>
      <IconText
        Icon={icon}
        content={text}
        weight="bold-ellipsis"
        contentClass={`${styles.content} ${contentClass}`}
        gap="xs"
        {...rest}
      />
    </article>
  );
};

export default WidgetLabel;
