import { statusTypes } from 'utils/product/enums/cmsEnums';
import { isAfter } from 'utils/dateUtils';
import { contestStatuses } from './enums/contests';

export const isCmsItemEnabled = (item) => {
  const today = new Date();

  const isInActiveStatus =
    item?.status === statusTypes.active || //this is for items other than contests
    item?.status === contestStatuses.available; //this is for contests
  const enabled =
    isInActiveStatus &&
    (item?.beginDate ? isAfter(today, item?.beginDate) : true);

  return enabled;
};
