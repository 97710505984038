import React from 'react';

import RequestsWidget from './RequestsWidget';
import ThreeColumnsLayout from 'layouts/ThreeColumnsLayout';

const RequestsDetailedList = () => {
  return <ThreeColumnsLayout main={<RequestsWidget detailed={true} />} />;
};

export default RequestsDetailedList;
