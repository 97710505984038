import React from 'react';

import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import Widget from 'components/Widget/Widget';
import AvailableRequestsList from 'components/RequestsHome/AvailableRequestsList';

const AvailableRequestsWidget = ({
  requests,
  loading,
  meters,
  showMeterDialog = false,
  ...widgetProps
}) => {
  return (
    <WidgetSkeleton
      heightInPixels={272}
      distance="m"
      show={loading}
      {...widgetProps}
    >
      <Widget size="m" distance="m" {...widgetProps}>
        <AvailableRequestsList
          requests={requests}
          meters={meters}
          showMeterDialog={showMeterDialog}
        />
      </Widget>
    </WidgetSkeleton>
  );
};

export default AvailableRequestsWidget;
