import React, { useState } from 'react';
import MaterialSlider from '@material-ui/core/Slider';
import { formatMoney } from 'utils/moneyUtils';

const Slider = ({
  min = 0,
  max = 100,
  step = 10,
  marks,
  onChange,
  onMouseUp,
  defaultValue,
  preventZero = false,
  maxValue,
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue);
  function valuetext(value) {
    return formatMoney(value, false);
  }

  const onSliderChange = (event, value) => {
    if (value <= maxValue) {
      let _value = value;
      if (preventZero && value === 0) {
        _value = step;
      }
      setValue(_value);
      onChange(_value);
    }
  };

  const onSliderCommitted = (event, value) => {
    if (typeof onMouseUp === 'function') onMouseUp(value, setValue, marks);
  };

  return (
    <MaterialSlider
      aria-label="Slider"
      defaultValue={30}
      step={step}
      marks={marks}
      min={min}
      max={max}
      getAriaValueText={valuetext}
      onChange={onSliderChange}
      onChangeCommitted={onSliderCommitted}
      valueLabelDisplay="off"
      value={value}
      {...rest}
    />
  );
};

export default Slider;
