import React, { useState, useEffect } from 'react';

import findIndex from 'lodash/findIndex';

import Stepper from 'components/Stepper/Stepper';

const QuestionnaireStepper = ({ step, steps, ...rest }) => {
  const [stepper, setStepper] = useState([]);
  const [initialStep, setInitialStep] = useState();

  useEffect(() => {
    const _stepper = steps?.map((x, index) => {
      return {
        title: x?.header,
        overallStep: index,
      };
    });
    setStepper(_stepper);
  }, [steps]);

  useEffect(() => {
    const _initialStep = findIndex(stepper, function (x) {
      return x.overallStep === step;
    });

    setInitialStep(_initialStep);
  }, [stepper, step]);

  return (
    <Stepper
      steps={stepper}
      initialStep={initialStep}
      handleActiveStepInternally={false}
      {...rest}
    />
  );
};

export default QuestionnaireStepper;
