import { cmsSchemas } from 'utils/product/enums/cmsEnums';
import { useCmsContent } from './useCmsContent';

const useTerms = (slug, language, skip = false) => {
  const { data, loading, error } = useCmsContent(
    slug,
    language,
    skip,
    '[content.slug.el-GR_eq]',
    { query: `filter[schema]=${cmsSchemas.terms}` }
  );

  const terms = loading || !data?.data?.length ? null : data?.data[0]?.content;

  return {
    loading,
    error,
    data: terms,
  };
};

const useTermsHtml = (slug, language, skip = false) => {
  const { data, loading, error } = useTerms(slug, language, skip);

  const terms = loading || !data ? null : data?.terms;

  return {
    loading,
    error,
    data: terms,
  };
};
export { useTermsHtml, useTerms };
