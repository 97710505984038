import api from 'api/wave';
import endpoints from 'utils/product/enums/endpoints';

const participate = (contestId, userId) => {
  return api.post(`${endpoints.loyalty.contests}/${contestId}/participations`, {
    customerId: userId,
  });
};

export { participate };
