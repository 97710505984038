import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import { contactHeron } from 'api/contact';
import { trackNewContractEvent } from 'utils/product/tracking/events';
import { onboardingPromotion as enaOnboardingPromotion } from 'utils/product/config';
import useDialog from 'hooks/useDialog';
import { ReactComponent as SuccessIcon } from 'assets/icons/CheckGreen.svg';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import {
  SuccessDialog,
  ErrorDialog,
} from 'components/PromptDialogs/PromptDialog';
import RewardDialog from 'components/Loyalty/RewardDialog';
import useGeneralConfig from 'data/useGeneralConfig';

import styles from './OnboardingSuccessDialog.module.scss';

const OnboardingSuccessDialog = ({
  open,
  closeDialog,
  openDialog,
  firstName,
  lastName,
  phone,
  email,
  type,
  existing_customer,
  isGas = false,
  isShop = false,
  programType,
  programEnergyType,
  programName,
  isLoyaltyCustomer,
  points,
}) => {
  const { t, i18n } = useTranslation(['loyalty', 'newClient', 'shop']);
  const navigate = useNavigate();
  const [isContactLoading, setIsContactLoading] = useState(false);
  const contactSuccesDialog = useDialog();
  const contactErrorDialog = useDialog();

  const {
    data: { onboardingPromotion },
  } = useGeneralConfig(i18n.language) || {};

  const promotion =
    isGas || onboardingPromotion?.promotionId !== enaOnboardingPromotion
      ? null
      : onboardingPromotion;

  const onPromotionClicked = (isEna = false) => {
    trackNewContractEvent('onboarding_ENA_lead', promotion?.promotionId, {
      energy_type: programEnergyType,
    });
    setIsContactLoading(true);
    contactHeron(
      {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        email: email,
        type: type,
        existing_customer: existing_customer,
        heron_ena: isEna ? 1 : 0,
      },
      promotion?.leadSrc,
    )
      .then((result) => {
        const isSuccess =
          result?.data && result?.data.length > 0 && result?.data[0] === 0;

        closeDialog();
        isSuccess ? contactSuccesDialog.show() : contactErrorDialog.show();
      })
      .catch((error) => {
        closeDialog();
        contactErrorDialog.show();
      })
      .then((res) => {
        setIsContactLoading(false);
      });
  };

  const goToLogin = () => {
    navigate(
      isShop
        ? `/shop/${programType}/${programEnergyType}/${programName}`
        : '/login',
    );
  };

  const promotionSection = promotion && (
    <section className={styles.promotion}>
      <img
        src={promotion?.imageUrl?.url}
        alt="promo"
        width="100%"
        height="234"
      />
      <article>
        <h3>{promotion?.header}</h3>
        <p>{promotion?.description}</p>
        {promotion?.cta && (
          <PrimaryButtonLoader
            onClick={() => onPromotionClicked(enaOnboardingPromotion === 'ena')}
            loading={isContactLoading}
            disabled={isContactLoading}
          >
            {promotion?.cta || t(`newClient:newGasSupply.cta`)}
          </PrimaryButtonLoader>
        )}
      </article>
    </section>
  );

  return (
    <>
      {!isLoyaltyCustomer ? (
        <DialogOverlay
          open={open}
          closeDialog={() => {
            closeDialog();
            goToLogin();
          }}
          scroll="body"
          dialogHeader={null}
          maxWidth="sm"
        >
          <div className={styles.container}>
            <SuccessIcon width="72" height="72" alt="success" />
            <p className={styles.title}>{t(`newClient:success.header`)}</p>
            <p className={styles.message}>{t(`newClient:success.subtitle`)}</p>
            {isShop === false && (
              <p className={styles.info}>{t(`newClient:success.info`)}</p>
            )}
            {promotionSection}
          </div>
        </DialogOverlay>
      ) : (
        <RewardDialog
          open={open}
          closeDialog={() => {
            closeDialog();
            goToLogin();
          }}
          points={points}
          icon={null}
          message={t(`newClient:success.header`)}
          subMessage={t(`newClient:success.subtitle`)}
          loading={false}
        >
          {promotionSection}
        </RewardDialog>
      )}

      <SuccessDialog
        open={contactSuccesDialog.isOpen}
        closeDialog={() => {
          goToLogin();
        }}
        header={t(`shop:program.contact-success-title`)}
        message={t(`shop:program.contact-success-message`, {
          program: promotion?.name,
        })}
      />
      <ErrorDialog
        open={contactErrorDialog.isOpen}
        closeDialog={() => {
          contactErrorDialog.close();
          openDialog();
        }}
      />
    </>
  );
};

export default OnboardingSuccessDialog;
