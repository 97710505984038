import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import accountCategoryTypes from 'utils/product/enums/accountCategoryTypes';
import useRequestFormData from 'hooks/useRequestFormData';
import generateRequestData from 'utils/product/requests/generateRequestData';
import useLocalizedFriendlyName from 'hooks/useLocalizedFriendlyName';
import { requestTypes } from 'utils/product/enums/requests';
import { createRequest } from 'api/requests';
import getPostData from 'utils/product/requests/getPostData';
import { scrollTo } from 'utils/scroll';
import useError from 'hooks/useError';

import { trackRequestsDetailViewEvent } from 'utils/product/tracking/events';
import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import PersonalDataHome from 'components/Requests/Shared/PersonalDataHome';
import PersonalDataBusiness from 'components/Requests/Shared/PersonalDataBusiness';
import MovingDate from './MovingDate';
import MeterData from './MeterData';
import MovingDestination from './MovingDestination';
import MovingTerms from './MovingTerms';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import {
  SuccessBox,
  LocalisedErrorBox,
} from 'components/MessageBox/MessageBox';

import styles from './MovingRequest.module.scss';

const MovingRequest = ({
  account,
  accountType,
  defaultValues,
  meter,
  keepSameProgram,
}) => {
  const { t } = useTranslation(['requests']);
  const movingLabels = useRequestFormData(
    requestTypes.movingRequest,
    accountType
  );
  const powerDisconnectLabels = useRequestFormData(
    requestTypes.powerDisconnect,
    accountType
  );
  const { getDisplayName } = useLocalizedFriendlyName();

  const movingRequestDefaultValues = {
    ...defaultValues,
    ...(meter
      ? {
          meterName: getDisplayName(meter.FriendlyName, meter.MeterNo),
          meterNo: meter.MeterNo,
          meterAddress: meter.FullAddress,
        }
      : {}),
  };
  const [initialValues, setInitialValues] = useState(
    movingRequestDefaultValues
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { error, handleError, clearError } = useError();
  const formMethods = useForm({ defaultValues: { atLeastOneSelected: false } });

  const onSubmit = (data) => {
    setIsSuccess(false);
    clearError();
    setIsLoading(true);
    const postData = getPostData({
      requestType: requestTypes.movingRequest,
      accountType,
      data,
      defaultValues: movingRequestDefaultValues,
    });
    const initialValuesString = generateRequestData({
      labels: movingLabels,
      data: {
        ...initialValues,
        keepSameProgram: keepSameProgram
          ? t('requests:moving.disconnect-yes')
          : t('requests:moving.disconnect-no'),
      },
      accountId: account.Id,
      requestType: requestTypes.movingRequest,
    });
    const postString = generateRequestData({
      labels: movingLabels,
      data: {
        ...postData,
        keepSameProgram: keepSameProgram
          ? t('requests:moving.disconnect-yes')
          : t('requests:moving.disconnect-no'),
      },
      accountId: account.Id,
      requestType: requestTypes.movingRequest,
      meterId: meter?.Id,
      commodity: meter?.Commodity,
    });

    if (initialValuesString.RequestText === postString.RequestText) {
      handleError('form-is-same', 'forms');
      setIsLoading(false);
      scrollTo(0, 0);
      return;
    }

    const powerDisconnectPostString = generateRequestData({
      labels: powerDisconnectLabels,
      data: postData,
      accountId: account.Id,
      requestType: requestTypes.powerDisconnect,
      meterId: meter?.Id,
      commodity: meter?.Commodity,
    });
    Promise.all([
      createRequest(powerDisconnectPostString),
      createRequest(postString),
    ])
      .then((result) => {
        const disconnectResponse = result[0];
        const movingResponse = result[1];
        if (
          disconnectResponse.data.ResponseCode === 200 &&
          movingResponse.data.ResponseCode === 200
        ) {
          trackRequestsDetailViewEvent(
            'request_activation_success',
            t(`requests:types.type-${requestTypes.movingRequest}`)
          );
          setIsSuccess(true);
          setInitialValues(postData);
          return;
        }
        handleError('general', 'requests');
        trackRequestsDetailViewEvent(
          'request_activation_fail',
          t(`requests:types.type-${requestTypes.movingRequest}`)
        );
      })
      .catch((error) => {
        handleError(error, 'requests');
        trackRequestsDetailViewEvent(
          'request_activation_fail',
          t(`requests:types.type-${requestTypes.movingRequest}`)
        );
      })
      .then(() => {
        setIsLoading(false);
        scrollTo(0, 0);
      });
  };

  return (
    <Widget
      size="m"
      title={
        <WidgetHeader
          title={t(`requests:types.type-${requestTypes.movingRequest}`)}
        />
      }
    >
      <SuccessBox
        open={isSuccess}
        closeBox={() => setIsSuccess(false)}
        header={t(`requests:submit-success-title`)}
        message={t(`requests:submit-success-subtitle`)}
        distanceTop=""
      />
      <LocalisedErrorBox
        open={error !== null}
        closeBox={clearError}
        {...(error || {})}
        distanceTop=""
      />

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
          {accountType === accountCategoryTypes.home ? (
            <PersonalDataHome
              account={account}
              defaultValues={movingRequestDefaultValues}
              editable={false}
              requestType={requestTypes.movingRequest}
            />
          ) : (
            <PersonalDataBusiness
              account={account}
              defaultValues={movingRequestDefaultValues}
              editable={false}
              requestType={requestTypes.movingRequest}
            />
          )}
          <MeterData defaultValues={movingRequestDefaultValues} />
          <MovingDate />
          <MovingDestination />
          <MovingTerms />
          <article className={styles.buttonContainer}>
            <PrimaryButtonLoader
              loading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              {t('requests:requests.submit')}
            </PrimaryButtonLoader>
          </article>
        </form>
      </FormProvider>
    </Widget>
  );
};

export default MovingRequest;
