import {
  addDays,
  startOfDay,
  isAfter,
  isBefore,
  endOfMonth,
  getDifferenceInDays,
} from 'utils/dateUtils';
import billHomeStatuses from 'utils/product/enums/billHomeStatuses';
import {
  billsExpirationInterval,
  billsDisconnectInterval,
} from 'utils/product/config';
import commodities from './enums/commodities';
import { invoiceCRMPreprocessing, transformToIDocsMeterNo } from './billUtils';
import { isMeterEligibleForEbill } from 'utils/product/loyalty/ebillUtils';
import { isRecurringAvailable } from 'utils/product/loyalty/recurringUtils';
import {
  meterStatusesElectricity,
  meterStatusesGas,
} from 'utils/product/enums/meterStatuses';
import { directDebitCardStatus } from 'utils/product/enums/directDebitCardStatus';

export const getInvoiceHomeStatus = (meter, invoice) => {
  if (!invoice) return null;
  if (meter.CurrentBalance < 0) return billHomeStatuses.credit;
  if (meter.CurrentBalance === 0) return billHomeStatuses.paid;
  const paymentDueDate = startOfDay(invoice.dueDate);
  if (!paymentDueDate) return null;

  const today = startOfDay(new Date());

  const expiringSoonDate = addDays(paymentDueDate, -billsExpirationInterval);
  const expiredDisconnectDate = addDays(
    paymentDueDate,
    billsDisconnectInterval,
  );

  if (isAfter(today, expiredDisconnectDate))
    return billHomeStatuses.expiredDisconnect;
  if (isAfter(today, paymentDueDate)) return billHomeStatuses.expired;
  if (isBefore(today, expiringSoonDate)) return billHomeStatuses.future;
  return billHomeStatuses.expiring;
};

export const getAllGasInvoicesUnsorted = (data) => {
  if (data?.AccountMeterInvoices) {
    const gasInvoices = data.AccountMeterInvoices?.filter((meterInfo) => {
      return (
        meterInfo?.Commodity === commodities.gas &&
        meterInfo?.Invoices?.length > 0
      );
    });

    if (gasInvoices.length === 0) {
      return [];
    }

    const mergedList = gasInvoices?.reduce((previous, current) => {
      previous.push(
        ...current.Invoices.map((invoice) => ({
          ...invoice,
          Commodity: current.Commodity,
          FriendlyName: current.FriendlyName,
          MeterId: current.MeterId,
          MeterNo: current.MeterNo,
        })),
      );
      return previous;
    }, []);

    return mergedList.map((x) => invoiceCRMPreprocessing(x));
  }

  return [];
};

export const isLastInvoice = (state, invoice) => {
  return state?.isLastInvoice || invoice?.IsLastInvoice;
};

export const convertLastInvoiceToIdocsInvoice = (meter) => {
  return {
    ...meter.LastInvoice,
    isSynced: false,
    meterNo: transformToIDocsMeterNo(meter.MeterSupplyNo),
  };
};

export const mergeInvoices = (meters, invoices) => {
  const invoicesCopy = invoices?.length > 0 ? [...invoices] : [];
  const invoiceNoSet = new Set(
    invoicesCopy.map((invoice) => invoice.invoiceNo),
  );

  meters.forEach((meter) => {
    const lastInvoiceNo = meter?.LastInvoice
      ? meter.LastInvoice.invoiceNo
      : undefined;

    if (lastInvoiceNo && !invoiceNoSet.has(lastInvoiceNo)) {
      invoicesCopy.push(convertLastInvoiceToIdocsInvoice(meter));

      invoiceNoSet.add(lastInvoiceNo);
    }
  });

  return invoicesCopy;
};

export const isRecurringActivated = ({
  activeRecurringCard,
  meter,
  invoice,
  location,
}) =>
  activeRecurringCard &&
  isRecurringAvailable(meter) &&
  isLastInvoice(location?.state, invoice);

export const showEbillBanner = ({ meter, invoice, loading, location }) => {
  return (
    !loading &&
    meter &&
    isMeterEligibleForEbill(meter) &&
    meter.Ebill === false &&
    isLastInvoice(location?.state, invoice)
  );
};

export const showRecurringBanner = ({
  meter,
  invoice,
  loading,
  location,
  inactiveRecurring,
}) => {
  return (
    !loading &&
    inactiveRecurring &&
    ((commodities.gas === meter?.Commodity &&
      meter?.MeterStatus === meterStatusesGas.active) ||
      (commodities.energy === meter?.Commodity &&
        meter?.MeterStatus === meterStatusesElectricity.active)) &&
    isLastInvoice(location?.state, invoice)
  );
};

export const showRecurringWarning = ({
  meter,
  loading,
  cardExpiredOrExpiresSoon,
}) => {
  return (
    !loading &&
    ((commodities.gas === meter?.Commodity &&
      meter?.MeterStatus === meterStatusesGas.active) ||
      (commodities.energy === meter?.Commodity &&
        meter?.MeterStatus === meterStatusesElectricity.active)) &&
    cardExpiredOrExpiresSoon
  );
};

export const isStandingOrderCardExpiresSoon = (expiryMonth, expiryYear) => {
  if (!expiryMonth || !expiryYear) return false;

  const margin = 30;

  const expiryDate = new Date(expiryYear, expiryMonth - 1);
  const endOfMothExpiryDate = endOfMonth(expiryDate);
  const timeSpan = getDifferenceInDays(new Date(), endOfMothExpiryDate);

  return timeSpan > 0 && timeSpan <= margin;
};

export const checkActiveRecurringCard = (standingOrderCardDetails) => {
  return (
    (!!standingOrderCardDetails?.Credit &&
      standingOrderCardDetails?.StatusCode === directDebitCardStatus.active) ||
    !!standingOrderCardDetails?.Bank
  );
};

export const checkInvoiceAvailability = (meter, invoice) => {
  return (
    !!invoice?.id ||
    (meter.Commodity === commodities.energy && invoice.isSynced !== false)
  );
};
