import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import Loader from 'components/Skeletons/Loader';

import useLocalizedFriendlyName from 'hooks/useLocalizedFriendlyName';
import availableMeterUsageTypes from 'utils/product/enums/availableMeterUsageTypes';
import { getObjectEntries } from 'utils/objectUtils';

import { TextInput } from 'components/Inputs/TextInput';
import { HiddenFormInput } from 'components/Inputs/HiddenFormInput';
import { ControlledSelect } from 'components/Selects/ControlledSelect';
import StandardSelectOption from 'components/Selects/StandardSelectOption';
import MetersListDialogSelect from 'components/RequestsHome/MetersListDialogSelect';

import styles from './MeterUsage.module.scss';

const MeterUsage = ({ meters, loading = false, defaultMeter }) => {
  const { t } = useTranslation(['requests']);
  const { getFriendlyName } = useLocalizedFriendlyName();
  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext();
  const [currentMeter, setCurrentMeter] = useState(defaultMeter);
  const [currentMeterId, setCurrentMeterId] = useState(
    defaultMeter ? `${defaultMeter.Id}` : ''
  );
  const [currentMeterUsage, setCurrentMeterUsage] = useState(
    defaultMeter
      ? t(`requests:meter-usage-types.type-${defaultMeter.MeterUsage}`)
      : ''
  );

  const [newMeterUsage, setNewMeterUsage] = useState('');

  const getMeterName = (meter) => {
    return meter && meter.FriendlyName
      ? `${getFriendlyName(meter.FriendlyName)} - ${meter.MeterNo}`
      : meter?.MeterNo;
  };
  const getMeterUsage = (meter) => {
    return meter
      ? t(`requests:meter-usage-types.type-${meter.MeterUsage}`)
      : '';
  };

  const handleChange = (meterId) => {
    setValue('meterId', meterId);
    setCurrentMeterId(meterId);

    const meter = meters.find((x) => x.Id === meterId);
    setCurrentMeter(meter);

    setValue('currentUsage', getMeterUsage(meter));
    setValue('currentUsageIgnored', getMeterUsage(meter));
    setCurrentMeterUsage(getMeterUsage(meter));
    if (getMeterUsage(meter) === newMeterUsage) handleUsageChange('');

    const meterName = getMeterName(meter);
    setValue('meterName', meterName);
  };

  const handleUsageChange = (newMeterUsage) => {
    setValue('newUsage', newMeterUsage);
    setNewMeterUsage(newMeterUsage);
  };

  return (
    <article className={styles.container}>
      <h3 className={styles.title}>
        {t(`requests:usage-change.section-title`)}
      </h3>
      <Loader show={loading} height={108}>
        <>
          <MetersListDialogSelect
            value={currentMeterId}
            meters={meters}
            handleChange={(e) => handleChange(e.target.value)}
            label={t('requests:billing.meter')}
          />
          <HiddenFormInput
            name={'meterName'}
            register={register}
            defaultValue={getMeterName(defaultMeter)}
          />
          <HiddenFormInput
            name={'currentUsage'}
            register={register}
            defaultValue={
              defaultMeter ? getMeterUsage(defaultMeter) : currentMeterUsage
            }
          />
          <TextInput
            name="currentUsageIgnored"
            label={t(`requests:usage-change.current-usage`)}
            value={
              defaultMeter ? getMeterUsage(defaultMeter) : currentMeterUsage
            }
            disabled={true}
            required={true}
          />
          <ControlledSelect
            label={t(`requests:usage-change.new-usage`)}
            value={newMeterUsage}
            control={control}
            name="newUsage"
            errors={errors}
            onChange={(e) => handleUsageChange(e.target.value)}
            defaultValue=""
            autoWidth={true}
            MenuProps={{
              classes: { list: styles.selectMenu },
            }}
          >
            {getObjectEntries(availableMeterUsageTypes).map(([key, value]) => {
              return (
                <StandardSelectOption
                  key={key}
                  value={t(`requests:meter-usage-types.type-${value}`)}
                  disabled={
                    meters && meters.length !== 1
                      ? value === currentMeter?.MeterUsage
                      : value === meters[0]?.MeterUsage
                  }
                  menuItemClass={styles.menuItem}
                >
                  {t(`requests:meter-usage-types.type-${value}`)}
                </StandardSelectOption>
              );
            })}
          </ControlledSelect>
        </>
      </Loader>
    </article>
  );
};

export default MeterUsage;
