import React, { useState } from 'react';
import { ReactComponent as LoyaltyDebitIcon } from 'assets/icons/LoyaltyDebitIcon.svg';
import { PromptDialog } from 'components/PromptDialogs/PromptDialog';
import { useTranslation } from 'react-i18next';
import { useAuthUser } from 'utils/AuthUser';
import { getRedemptionResult } from 'utils/product/loyalty/redemptionUtils';
import { redeemRedemptionOption } from 'api/loyalty';
import {
  trackLoyaltyOfferEvent,
  trackGameEvent,
} from 'utils/product/tracking/loyaltyEvents';
import { offerVisibilityTypes } from 'utils/product/enums/cmsEnums';
import discoveryItemTypes from 'utils/product/enums/discoveryItemTypes';

import styles from './OfferAcquisitionConfirm.module.scss';

const OfferAcquisitionConfirm = ({
  offer,
  open,
  closeDialog,
  onSuccess,
  onError,
}) => {
  const { t } = useTranslation(['translation', 'offers']);
  const { accountId } = useAuthUser();
  const [isLoading, setIsLoading] = useState(false);

  if (!offer) return null;

  const { confirmMessage, waveId, title, showInLoyalty } = offer;

  const clickHandler = () => {
    if (showInLoyalty === offerVisibilityTypes.loyalty) {
      if (offer._type === discoveryItemTypes.offer) {
        trackLoyaltyOfferEvent(
          'offer_loyalty_get_the_offer_selected_confirmation',
          '',
          { offer_name: title }
        );
      } else if (offer._type === discoveryItemTypes.game) {
        trackGameEvent('loyalty_game_overview_play_selected', '', {
          loyalty_game_name: title,
        });
      }
    }

    const postData = {
      customer: { customerId: accountId },
      touchpoint: 'WEB',
      currencyRedemptionOptionIds: [waveId],
    };
    setIsLoading(true);

    redeemRedemptionOption(postData)
      .then((result) => {
        closeDialog();
        const _data = getRedemptionResult(result?.data);
        onSuccess(_data);
      })
      .catch((error) => {
        closeDialog();
        onError(error);
      })
      .then(() => {
        setIsLoading(false);
      });
  };
  return (
    <PromptDialog
      icon={<LoyaltyDebitIcon />}
      open={open}
      closeDialog={closeDialog}
      primaryActionText={t(`translation:continue`)}
      primaryActionHandler={clickHandler}
      primaryActionLoading={isLoading}
      showSecondaryAction={true}
      secondaryActionText={t(`translation:cancel`)}
      secondaryActionHandler={closeDialog}
      header={t(`offers:acquisition-flow.confirm-title`)}
    >
      <p>{confirmMessage}</p>
      <p className={styles.subtext}>
        {t(`offers:acquisition-flow.confirm-message`)}
      </p>
    </PromptDialog>
  );
};

export default OfferAcquisitionConfirm;
