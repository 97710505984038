import { isPast, isFuture } from 'utils/dateUtils';
import { conventFromCents, formatNumber } from 'utils/moneyUtils';
import { isEmptyString } from 'utils/stringUtils';
import { cmsSchemas, offerVisibilityTypes } from './enums/cmsEnums';
import { sortByField } from 'utils/sortUtils';
import { isCmsItemEnabled } from 'utils/product/cmsUtils';
import { getObjectValues } from 'utils/objectUtils';
import discoveryItemTypes from 'utils/product/enums/discoveryItemTypes';
import notificationFlows from 'utils/product/enums/notificationFlows';
import { loyaltyPaths } from './loyalty/config';

export const shouldShowDiscoveryItem = (item) => {
  const { beginDate = null, displayEndDate = null } = item;
  const isValid =
    (!beginDate || isPast(beginDate)) &&
    (!displayEndDate || isFuture(displayEndDate));
  return isValid;
};

export const isValidDiscoveryItem = (discoveryItem, isLoyaltyCustomer) => {
  //in case of contests, where we want to display contests past their expiration date, we want to include those not available too
  //the ones we dont want to display will be excluded by their displayEndDate in shouldShowDiscoveryItem
  if (discoveryItem._type !== discoveryItemTypes.contest) {
    const isEnabled =
      typeof discoveryItem.enabled === 'undefined' ||
      discoveryItem.enabled === true;

    if (!isEnabled) return false;
  }

  const shouldShow = shouldShowDiscoveryItem(discoveryItem);
  if (!shouldShow) return false;

  const isValidUser =
    discoveryItem.showInLoyalty === offerVisibilityTypes.default ||
    (!isLoyaltyCustomer &&
      discoveryItem.showInLoyalty === offerVisibilityTypes.nonLoyalty) ||
    (isLoyaltyCustomer &&
      discoveryItem.showInLoyalty === offerVisibilityTypes.loyalty);

  return isValidUser;
};

export const sortArrayBasedOnOrder = (array) => {
  if (!array) return;

  const orderArray = array?.filter((item) => item?.order !== null);
  const unorderedArray = array?.filter((item) => item?.order === null);

  sortByField(orderArray, 'order');

  orderArray?.forEach((item) => {
    unorderedArray?.splice(item?.order - 1, 0, item);
  });

  return unorderedArray;
};

/* used in discovery page */
export const getDiscoveryItems = (items, isLoyaltyCustomer, vibes) => {
  if (!items || items.length === 0) return [];

  const takeItemsWithVibe = 2;
  const { data: list = [], included = {} } = items;

  const transformedList = list.map((item) => {
    return transformDiscoveryItem(item, included, vibes, isLoyaltyCustomer);
  });

  const filteredList = transformedList.filter((discoveryItem) => {
    return isValidDiscoveryItem(discoveryItem, isLoyaltyCustomer);
  });

  if (!isLoyaltyCustomer) {
    return sortArrayBasedOnOrder(filteredList);
  }

  const gamesList = filteredList?.filter(
    (item) => item._type === discoveryItemTypes.game,
  );

  if (filteredList.length > 1) {
    const extraItemsWithVibes = [];
    const filteredListWithPattern = vibes?.reduce((accumulator, current) => {
      const discoveryItemsWithVibe = filteredList?.filter(
        (item) => item?.vibe?.key === current?.key,
      );

      if (discoveryItemsWithVibe) {
        accumulator.push(...discoveryItemsWithVibe.slice(0, takeItemsWithVibe));
        extraItemsWithVibes.push(
          ...discoveryItemsWithVibe.slice(takeItemsWithVibe),
        );
      }

      if (gamesList.length) accumulator.push(gamesList?.shift());
      return accumulator;
    }, []);

    const discoveryItemsWithoutVibe = filteredList?.filter(
      (item) => !item?.vibe && item._type !== discoveryItemTypes.game,
    );

    const result = filteredListWithPattern
      .concat(extraItemsWithVibes)
      .concat(gamesList)
      .concat(discoveryItemsWithoutVibe);

    return sortArrayBasedOnOrder(result);
  }
  return filteredList;
};

/* used in discovery item details page */
export const getDiscoveryItem = (slug, items, isLoyaltyCustomer, vibes) => {
  if (isEmptyString(slug) || !items || items.length === 0) return [];

  const { data: list = [], included = {} } = items;

  const item = list.find(
    (_item) => _item.content.slug === slug || _item.id === slug,
  );

  if (!item) return undefined;

  const discoveryItem = transformDiscoveryItem(
    item,
    included,
    vibes,
    isLoyaltyCustomer,
  );

  return discoveryItem;
};

/* used for redirecting to offer/contest/game detail view from notification */
export const getDiscoveryItemSlugByWaveId = (flow, waveId, items) => {
  if (isEmptyString(waveId) || !items || items.length === 0) return null;

  const { data: list = [], included = {} } = items;

  const searchList =
    flow === notificationFlows.contest
      ? included[cmsSchemas.contests]
      : flow === notificationFlows.game
        ? included[cmsSchemas.games]
        : included[cmsSchemas.offers];

  const itemWithWaveId =
    searchList && typeof searchList === 'object'
      ? getObjectValues(searchList).find(
          (_item) => _item.content.waveId === waveId,
        )
      : null;

  if (!itemWithWaveId) {
    return null;
  }

  const forYouItem = list.find(
    (_forYouItem) =>
      _forYouItem.schema === cmsSchemas.forYou &&
      _forYouItem.content.cxmEntity?.schema === itemWithWaveId.schema &&
      _forYouItem.content.cxmEntity?.id === itemWithWaveId.id,
  );

  return forYouItem?.content?.slug;
};

const getRedeptionOptionProperties = (item, included) => {
  const redeptionOptions =
    item && item.redemptionOptions
      ? included[item.redemptionOptions.schema]?.[item.redemptionOptions.id]
          ?.content
      : item;

  const redeemPoints = conventFromCents(redeptionOptions?.priceAmount) ?? 0;

  return {
    priceAmount: redeptionOptions?.priceAmount,
    waveId: redeptionOptions?.waveId,
    benefits: redeptionOptions?.benefits,
    enabled: isCmsItemEnabled(redeptionOptions),
    redeemPoints,
    confirmMessage: isEmptyString(redeptionOptions?.confirmMessage)
      ? ''
      : redeptionOptions?.confirmMessage.replace(
          '{points}',
          formatNumber(redeemPoints),
        ),
    // startDate: _redeptionOptions?.startDate,
    // endDate: _redeptionOptions?.endDate,
  };
};

const getVibeProperties = (benefits, vibes, isLoyaltyCustomer) => {
  if (isLoyaltyCustomer && vibes) {
    const vibe = benefits?.reduce((previousVibe, benefit) => {
      const vibe = vibes?.find(
        (vibe) => vibe?.rewardSchemeId === benefit?.waveId,
      );
      const maxPercentage = previousVibe?.percentage ?? 0;

      return vibe && (vibe?.percentage > maxPercentage || !previousVibe)
        ? vibe
        : previousVibe;
    }, null);
    return { vibe };
  }
  return { vibe: null };
};

export const getBaseProperties = (forYouItem, included) => {
  if (!forYouItem) return undefined;
  const { content: { image, cxmEntity = {} } = {} } = forYouItem;

  const relatedEntity = cxmEntity
    ? included[cxmEntity.schema]?.[cxmEntity.id]?.content
    : {};

  const _schema = cxmEntity?.schema;

  return {
    id: forYouItem.id,
    slug: forYouItem?.slug || forYouItem?.id,
    _key: forYouItem.id,
    ...relatedEntity,
    ...forYouItem.content,
    detailsTitle: relatedEntity?.title,
    imageUrl: image?.image,
    largeImageUrl: relatedEntity?.largeImage?.image,
    isExpired: !!(
      relatedEntity?.endDate && isPast(relatedEntity?.endDate) === true
    ),
    order: forYouItem?.content?.order > 0 ? forYouItem?.content?.order : null,
    _type:
      _schema === cmsSchemas.contests
        ? discoveryItemTypes.contest
        : _schema === cmsSchemas.games
          ? discoveryItemTypes.game
          : discoveryItemTypes.offer,
    contestId: _schema === cmsSchemas.contests ? relatedEntity?.waveId : null,
  };
};

export const transformDiscoveryItem = (
  forYouItem,
  included,
  vibes,
  isLoyaltyCustomer,
) => {
  const baseItem = getBaseProperties(forYouItem, included);

  const redeptionOptionProperties = getRedeptionOptionProperties(
    baseItem,
    included,
  );

  const vibeProperties = getVibeProperties(
    redeptionOptionProperties?.benefits,
    vibes,
    isLoyaltyCustomer,
  );

  return {
    ...baseItem,
    ...redeptionOptionProperties,
    ...vibeProperties,
  };
};

export const getDiscoveryPageResults = (items, vibes) => {
  const keysOrdered = [
    discoveryItemTypes.game,
    ...vibes.map((vibe) => vibe.title),
    discoveryItemTypes.default,
  ];

  const groups = keysOrdered.reduce((previousValue, currentValue) => {
    return { ...previousValue, [currentValue]: [] };
  }, {});

  items.forEach((item) => {
    if (item._type === discoveryItemTypes.game) {
      groups[discoveryItemTypes.game].push(item);
      return;
    }

    if (item?.vibe && groups[item?.vibe?.title]) {
      groups[item?.vibe?.title].push(item);
      return;
    }
    groups[discoveryItemTypes.default].push(item);
  });

  const sections = keysOrdered.map((sectionKey) => {
    return {
      key: sectionKey,
      items: groups[sectionKey],
      vibe: vibes.find((x) => x.title === sectionKey),
    };
  });
  return sections;
};

export const isVibesPromoOffer = (item) => {
  return (
    item?.button?.url &&
    new URL(item?.button?.url)?.pathname ===
      `/loyalty/${loyaltyPaths.questionnaire}`
  );
};
