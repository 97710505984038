import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { IconButton } from '@material-ui/core';
import useLocalisedError from 'hooks/useLocalisedError';
import { isEmptyString } from 'utils/stringUtils';

import { ReactComponent as SuccessIcon } from 'assets/icons/CheckGreen.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/ErrorCircle.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/CancelIcon.svg';

import styles from './MessageBox.module.scss';

const SuccessBox = ({ header, ...props }) => {
  const { t } = useTranslation(['translation']);

  return (
    <MessageBox
      header={header ?? t('translation:completion')}
      icon={<SuccessIcon width={24} height={24} />}
      {...props}
    />
  );
};

const ErrorBox = ({ header, ...props }) => {
  const { t } = useTranslation(['translation']);

  return (
    <MessageBox
      header={header ?? t('translation:attention')}
      icon={<ErrorIcon />}
      {...props}
    />
  );
};

const LocalisedErrorBox = ({
  errorCode,
  errorScope,
  errorParams = {},
  ...props
}) => {
  const { title, message } = useLocalisedError(
    errorCode,
    errorScope,
    errorParams
  );
  return <ErrorBox header={title} message={message} {...props} />;
};

const MessageBox = ({
  header,
  message,
  icon,
  open = false,
  closeBox,

  boxClass = '',
  contentClass = '',
  headerClass = '',
  messageClass = '',
  iconClass = '',
  distanceTop = 'm',
  distanceBottom = 'm',
  id = null,
}) => {
  return (
    open && (
      <article
        className={cn(
          styles.container,
          {
            [styles[`container-top-${distanceTop}`]]:
              !isEmptyString(distanceTop),
            [styles[`container-bottom-${distanceBottom}`]]:
              !isEmptyString(distanceBottom),
          },
          boxClass
        )}
        id={id}
      >
        <section className={styles.topSection}>
          {icon &&
            React.cloneElement(icon, {
              ...icon.props,
              className: cn(styles.icon, iconClass),
            })}
          {header && (
            <h2 className={cn(styles.header, headerClass)}>{header}</h2>
          )}
          <IconButton
            className={styles.cancelIcon}
            aria-label="close"
            disableRipple
            disableFocusRipple={true}
            onClick={closeBox}
          >
            <CancelIcon />
          </IconButton>
        </section>
        <section className={cn(styles.bottomSection, contentClass)}>
          {message && (
            <div
              className={messageClass}
              dangerouslySetInnerHTML={{ __html: message }}
            ></div>
          )}
        </section>
      </article>
    )
  );
};

export { MessageBox, SuccessBox, ErrorBox, LocalisedErrorBox };
