import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

import Checkbox from 'components/Checkbox/Checkbox';

import styles from './MovingTerms.module.scss';

const MovingTerms = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation(['requests']);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  return (
    <article>
      <Checkbox
        value={isTermsAccepted}
        changeHandler={(event) => {
          setIsTermsAccepted(event.target.checked);
        }}
        label={
          <Link
            to="/moving-terms"
            target="_blank"
            rel="noreferrer"
            className={
              !!errors['termsAccepted'] ? styles.linkError : styles.link
            }
          >
            {t('requests:moving.accept-terms')}
          </Link>
        }
        name="termsAccepted"
        labelClass={styles.checkbox}
        register={register}
        registerRules={{
          validate: (value) => value === true,
        }}
      />
    </article>
  );
};

export default MovingTerms;
