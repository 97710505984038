import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/dateUtils';
import { sortByDateFieldDescending } from 'utils/sortUtils';
import { useAuthUser } from 'utils/AuthUser';
import { dateFormats } from 'utils/product/config';
import { getObjectKeys } from 'utils/objectUtils';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BellIcon } from 'assets/icons/Bell.svg';
import { trackNotificationEvent } from 'utils/product/tracking/events';
import { setNotificationsReadFlag } from 'api/notifications';
import { setLoyaltyNotificationAsRead } from 'api/loyalty';
import { default as NotificationDetailsDialog } from 'components/Loyalty/RewardDialog';
import useDialog from 'hooks/useDialog';
import Helmet from 'utils/Helmet';

import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import NotificationsGroup from './NotificationsGroup';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import useNotifications from 'data/useNotifications';
import HeronNotificationsIcon from './HeronNotificationsIcon';
import { isEmptyString } from 'utils/stringUtils';
import useNotificationsStatus from 'data/useNotificationsStatus';
import EmptyStateWidget from 'components/Widget/EmptyStateWidget';
import { trackNotificationsEvent } from 'utils/product/tracking/loyaltyEvents';

import styles from './Notifications.module.scss';

const renderNotifications = (notifications, handleClick) => {
  sortByDateFieldDescending(notifications, 'CreatedOn');
  const groupedNotifications = notifications?.reduce(
    (accumulator, currentValue) => {
      const key = currentValue?.CreatedOn
        ? `${formatDate(currentValue?.CreatedOn, dateFormats.dateDashReverse)}`
        : '';

      if (accumulator[key] === undefined) accumulator[key] = [];
      accumulator[key].push(currentValue);
      return accumulator;
    },
    {}
  );

  return getObjectKeys(groupedNotifications).map((key) => {
    return (
      <NotificationsGroup
        key={key}
        groupKey={key}
        group={groupedNotifications[key]}
        handleClick={handleClick}
      />
    );
  });
};

const Notifications = () => {
  const { t, i18n } = useTranslation(['notifications', 'translation']);
  const navigate = useNavigate();
  const { loyaltyCustomerId, accountId } = useAuthUser();
  const { mutate } = useNotificationsStatus(loyaltyCustomerId, accountId);

  const rewardDialog = useDialog();
  const [notificationDialogContent, setNotificationDialogContent] = useState(
    {}
  );

  const {
    data: notificationsData,
    loading,
    mutateLoyaltyNotifications,
    mutateHeronNotifications,
  } = useNotifications(accountId, loyaltyCustomerId, i18n.language);

  const setNotificationAsRead = (notification) => {
    trackNotificationsEvent('notification_unread_open');
    if (!notification?.IsLoyalty) {
      setNotificationsReadFlag({
        AccountId: accountId,
        notificationsInfo: [
          {
            NotificationsHistoryId: notification.NotificationsHistoryId,
            IsRead: 1,
          },
        ],
      })
        .then((res) => {
          mutateHeronNotifications();
        })
        .catch((error) => {});
      return;
    }

    if (notification?.IsLoyalty) {
      setLoyaltyNotificationAsRead(notification?.LoyaltyId)
        .then((res) => {
          mutateLoyaltyNotifications();
        })
        .catch((error) => {});
    }
  };

  const handleClick = (notification) => {
    trackNotificationEvent('notification_selection', notification?.Title);

    if (notification?.IsRead === false) {
      setNotificationAsRead(notification);
      mutate();
    }

    if (!isEmptyString(notification.RedirectionUrl)) {
      navigate(`/${notification.RedirectionUrl}`);
    } else {
      setNotificationDialogContent({
        icon: notification?.Image ? (
          <img
            src={notification?.Image}
            width="72px"
            height="72px"
            alt="notification"
            className={styles.successIcon}
          />
        ) : (
          <HeronNotificationsIcon
            iconType={notification.Type}
            width="72"
            height="72"
            alt="notification"
            className={styles.successIcon}
          />
        ),
        header: notification.Title,
        message: notification.DetailsMessage,
        points: notification.Points,
      });
      rewardDialog.show();
    }
  };

  const showEmptyState = notificationsData && notificationsData?.length === 0;

  return (
    <>
      <Helmet
        head={{
          title: t('notifications:notifications'),
          metaTags: [
            { name: 'title', content: t('notifications:notifications') },
          ],
        }}
      />
      <WidgetSkeleton
        distance="m"
        size="m"
        heightInPixels={548}
        show={loading}
        title={<WidgetHeader title={t('notifications:notifications')} />}
      >
        {notificationsData && notificationsData?.length > 0 && (
          <Widget
            size="m"
            title={<WidgetHeader title={t('notifications:notifications')} />}
          >
            {renderNotifications(notificationsData, handleClick)}
          </Widget>
        )}
        {showEmptyState && (
          <EmptyStateWidget
            header={t('notifications:notifications')}
            icon={<BellIcon width="72px" height="72px" />}
            text={t('notifications:empty')}
            isGray
          />
        )}
      </WidgetSkeleton>
      <NotificationDetailsDialog
        open={rewardDialog.isOpen}
        closeDialog={() => {
          rewardDialog.close();
        }}
        icon={notificationDialogContent?.icon}
        header={notificationDialogContent?.header}
        message={notificationDialogContent?.message}
        points={notificationDialogContent?.points}
      />
    </>
  );
};

export default Notifications;
