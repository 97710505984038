import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DownArrow } from 'assets/icons/ArrowDownIcon.svg';
import { ReactComponent as UpArrow } from 'assets/icons/ArrowUpIcon.svg';
import SeeMoreWidget from 'components/SeeMoreWidget/SeeMoreWidget';

const Pager = ({
  totalCount,
  onClick,
  /* optional values below here */
  Icon,
  content,
  pagerMin = 8,
  pagerMax = 24,
  containerClass = '',
}) => {
  const { t } = useTranslation(['translation']);

  const [limit, setLimit] = React.useState(pagerMin);

  const handlePagerClick = () => {
    const newValue = limit === pagerMin ? pagerMax : pagerMin;
    setLimit(newValue);
    onClick(newValue);
  };

  return totalCount > pagerMin ? (
    <SeeMoreWidget
      containerClass={containerClass}
      Icon={
        Icon ? (
          Icon
        ) : limit === pagerMin ? (
          <DownArrow fontSize="large" />
        ) : (
          <UpArrow fontSize="large" />
        )
      }
      content={content ?? (limit === pagerMin ? t('see-more') : t('see-less'))}
      onClick={handlePagerClick}
    />
  ) : null;
};

export default Pager;
