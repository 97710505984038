import React from 'react';
import { Navigate } from 'react-router-dom';
import i18n from 'i18next';

import GenericLoader from 'components/GenericLoader/GenericLoader';
import { useAuthUser } from './AuthUser';
import useWebDowntime from 'data/useWebDowntime';
import useScrollToTop from 'hooks/useScrollToTop';

const AuthorizedRoute = ({ component, ...rest }) => {
  const { isLoggedIn, loading } = useAuthUser();
  const {
    data: { downTimeWeb = {}, hasDownTime } = {},
    loading: configLoading,
  } = useWebDowntime(i18n.language) || {};

  useScrollToTop();

  if (configLoading) return <GenericLoader />;
  if (hasDownTime) {
    return <Navigate to={'/under-maintenance'} state={downTimeWeb} />;
  }
  if (!loading && !isLoggedIn) {
    return <Navigate to="/login" />;
  }
  return component;
};

export default AuthorizedRoute;
