import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Checkbox from 'components/Checkbox/Checkbox';

import styles from './PersonalDataPreferences.module.scss';

const PersonalDataPreferences = ({ defaultValues = {}, onUpdate }) => {
  const { register, setValue } = useFormContext();
  const { t } = useTranslation(['newClient']);

  const handleChange = (event) => {
    onUpdate({
      [event.target.name]: event.target.checked,
    });
    setValue(event.target.name, event.target.checked);
  };

  return (
    <article className={styles.container}>
      <h3 className={styles.header}>
        {t(`newClient:personalDataPreferences.header`)}
      </h3>
      <Checkbox
        value={defaultValues.personalDataAgreement ?? false}
        changeHandler={handleChange}
        name="personalDataAgreement"
        label={t(`newClient:personalDataPreferences.accept`)}
        labelClass={styles.label}
        checkboxClass={styles.checkbox}
        register={register}
      />
    </article>
  );
};

export default PersonalDataPreferences;
