import React from 'react';

import { formatMoneyWithCurrency } from 'utils/moneyUtils';

import styles from './StrikedAmount.module.scss';

const StrikedAmount = ({ number, numberClassname = 'text-default' }) => {
  return (
    <div className={styles[numberClassname]}>
      {formatMoneyWithCurrency(number)}
    </div>
  );
};

export default StrikedAmount;
