import React from 'react';
import { useTranslation } from 'react-i18next';

import ProgramWidget from 'components/Shop/Programs/ProgramWidget';
import {
  newClientSteps,
  existingClientSteps,
} from 'utils/product/onboardingConfig';
import { useShop } from 'data/useShop';

const ProgramDetails = ({ step, nextRouteData, isShop = false, onUpdate }) => {
  const { i18n } = useTranslation(['newClient']);
  const language = i18n.language;

  const { energyProgram, gasProgram } = nextRouteData;
  const steps = isShop ? existingClientSteps : newClientSteps;

  const { data: programs, loading: isLoading } = useShop(language);

  const selectedProgram = programs
    ? programs.find(
        (x) =>
          x.urlName ===
          (step === steps.energyProgram
            ? energyProgram.urlName
            : gasProgram.urlName),
      )
    : null;

  const generousGuarantee = selectedProgram?.sections?.guaranteeProgram
    ? typeof nextRouteData.generousGuarantee !== 'undefined'
      ? nextRouteData?.generousGuarantee
      : selectedProgram?.sections?.guaranteeProgram?.choices?.find(
          (x) => x.isSelected,
        )?.value
    : false;

  return (
    <ProgramWidget
      loading={isLoading || !selectedProgram}
      program={
        selectedProgram
          ? {
              ...selectedProgram,
              sections: { ...selectedProgram.sections, actions: null },
            }
          : null
      }
      defaultValues={{
        ...nextRouteData,
        generousGuarantee: `${generousGuarantee}`,
      }}
      onUpdate={onUpdate}
      isShop={false}
    />
  );
};

export default ProgramDetails;
