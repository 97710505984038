import authStorage from 'utils/storage/authStorage';
import { refreshTokenService } from 'api/auth';
import { updateChatToken } from 'utils/product/chatUtils';

export const requestInterceptor = (
  config,
  unauthorizedServices = [],
  isWaveLoyalty = false,
) => {
  const token = authStorage.getToken();
  if (!token || unauthorizedServices.indexOf(config.url) > -1) {
    if (config.headers && config.headers['Authorization']) {
      delete config.headers['Authorization'];
    }
  } else {
    config.headers['Authorization'] = `Bearer ${authStorage.getToken()}`;

    if (!isWaveLoyalty) {
      config.headers['Commodity'] = authStorage.getCommodity();
    }
  }
  return config;
};
const state = {
  isRefreshingToken: false,
  //will hold all requests that failed with 401, in order to repeat after token refresh
  queuedRequests: new Map(),
};

export const responseInterceptor = async (error, api, skipRefresh = false) => {
  const originalRequest = error.config;

  if (
    error?.response?.status !== 401 ||
    !originalRequest ||
    originalRequest?.url?.indexOf('wave/api/') > -1 ||
    originalRequest._isRetry
  ) {
    //logServiceFailure(error);
    return Promise.reject(error);
  }
  //flag to avoid infinite loop in case service fails again with 401
  originalRequest._isRetry = true;
  //clear invalid token so as to not be used in subsequent requests
  authStorage.removeToken();
  if (state.isRefreshingToken) {
    state.queuedRequests.set(originalRequest?.url, {
      request: originalRequest,
      api: api,
    });
    return Promise.reject(error);
  }

  const refreshToken = authStorage.getRefreshToken();
  if (!refreshToken) {
    window.location = '/login';
    return Promise.reject(error);
  }

  state.isRefreshingToken = true;
  return refreshTokenService(refreshToken)
    .then((res) => {
      const { access_token: newToken, refresh_token: newRefreshToken } =
        res?.data || {};

      if (!newRefreshToken) {
        state.isRefreshingToken = false;
        authStorage.clearAuthTokens();
        window.location = '/login';
        return;
      }
      authStorage.setRefreshToken({ refreshToken: newRefreshToken });

      if (newToken) {
        authStorage.setToken({ token: newToken });
        updateChatToken(newToken);
      }

      originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

      return api(originalRequest).then((response) => {
        for (let [key, queuedRequest] of state.queuedRequests) {
          const { request: _request, api: _api } = queuedRequest;
          _request.headers['Authorization'] = `Bearer ${newToken}`;
          _api(_request);
          state.queuedRequests.delete(key);
        }
        state.isRefreshingToken = false;
        return response;
      });
    })
    .catch((tokenError) => {
      state.isRefreshingToken = false;
      authStorage.clearAuthTokens();
      window.location = '/login';
    });
};
