import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Big from 'big.js';

import {
  createSettlementsRequest,
  createSettlementAnalysis,
  isDateAvailableForInstallment,
} from 'utils/product/settlementUtils';
import { addDays, isAfter, isBefore, startOfDay } from 'utils/dateUtils';
import {
  validateAmountTwoDecimalsFormat,
  validateNumberInRange,
} from 'utils/validators';
import { saveSettlements } from 'api/settlements';
import { trackBillSettlementEvent } from 'utils/product/tracking/events';
import useGeneralConfig from 'data/useGeneralConfig';
import useDialog from 'hooks/useDialog';
import useError from 'hooks/useError';

import { ControlledSelect } from 'components/Selects/ControlledSelect';
import StandardSelectOption from 'components/Selects/StandardSelectOption';
import { PrimaryButton, PrimaryButtonLoader } from 'components/Buttons/Buttons';
import { StandardInput } from 'components/Inputs/Inputs';
import { DatePicker } from 'components/Inputs/DatePicker';
import {
  SuccessDialog,
  ErrorDialog,
} from 'components/PromptDialogs/PromptDialog';
import { LocalisedErrorBox } from 'components/MessageBox/MessageBox';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import SettlementAnalysis from './SettlementAnalysis';

import { formatMoneyWithCurrency } from 'utils/moneyUtils';
import { fixDecimalString } from 'utils/numberUtils';

import styles from './CreateSettlementDialog.module.scss';

const CreateSettlementDialog = ({
  open,
  closeDialog,
  settlementResponse,
  accountId,
  meterId,
  totalAmount,
  onSettlementCreated,
}) => {
  const steps = {
    submit: 1,
    review: 2,
  };
  const { t } = useTranslation(['translation', 'bills', 'inputs']);
  const { data: { holidays } = {} } = useGeneralConfig() || {};

  const [step, setStep] = useState(steps.submit);
  const [isSaving, setIsSaving] = useState(false);
  const successDialog = useDialog();
  const errorDialog = useDialog();

  const [installments, setInstallments] = useState('');
  const [proposedSettlement, setProposedSettlement] = useState({});
  const [installmentsToReview, setInstallmentsToReview] = useState([]);
  const [selectedDate, handleDateChange] = useState(new Date());
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    clearErrors,
    reset,
  } = useForm();

  const numbers = Array.from(
    Array(settlementResponse.MaxInstallmentCount ?? 0).keys(),
  ).map((x) => x + 1);
  const today = new Date();
  const lastInstallmentDay = addDays(today, 4);
  const { error, handleError, clearError } = useError();

  const onSubmit = (data) => {
    trackBillSettlementEvent('bill_settlement_create');

    const result = createSettlementsRequest({
      totalAmount,
      installmentFrequency: settlementResponse.InstallmentsFrequency,
      installmentsCount: data.months,
      inAdvanceAmount: new Big(fixDecimalString(data.amount)).toNumber(),
      inAdvanceDate: selectedDate,
      holidays,
    });

    if (!result) {
      handleError('zero-amount', 'settlements');
      return;
    }
    setProposedSettlement({
      AccountId: accountId,
      MeterId: meterId,
      SettlementId: settlementResponse.SettlementId,
      ApplyOverdueCharges: false,
      ...result,
    });

    var installmentAnalysis = createSettlementAnalysis(result);
    setInstallmentsToReview(installmentAnalysis);
    setStep(steps.review);
  };

  const saveSettlement = () => {
    trackBillSettlementEvent('bill_settlement_submit');
    setIsSaving(true);
    saveSettlements(proposedSettlement)
      .then((res) => {
        handleClose();
        if (res.data.ResponseMessage !== 'OK') {
          trackBillSettlementEvent('bill_settlement_fail');
          errorDialog.show();
          return;
        }
        trackBillSettlementEvent('bill_settlement_success');
        successDialog.show();
      })
      .catch((error) => {
        handleClose();
        trackBillSettlementEvent('bill_settlement_fail');
        errorDialog.show();
      })
      .then(() => {
        setIsSaving(false);
      });
  };

  const handleClose = () => {
    closeDialog();
    setStep(steps.submit);
    setProposedSettlement({});
    setInstallmentsToReview([]);
  };

  const handleMonthsChange = (e) => {
    const installments = e.target.value;
    setValue('months', installments);
    setInstallments(installments);
    if (installments !== undefined && installments !== '')
      clearErrors('months');
  };

  const {
    ref: amountInputRef,
    name: amountInputName,
    ...amountInputRest
  } = register('amount', {
    required: {
      value: true,
      message: t(`inputs:errors.required`),
    },
    validate: (value) => {
      if (!validateAmountTwoDecimalsFormat(value)) {
        return t('inputs:errors:amountInvalid');
      }
      const _value = new Big(fixDecimalString(value)).toNumber();
      const minDepositAmount = new Big(totalAmount)
        .times(new Big(settlementResponse.MinDepositValue).div(100))
        .round(2)
        .toNumber();

      const isInRange = validateNumberInRange(
        _value,
        minDepositAmount,
        totalAmount,
      );
      return isInRange
        ? true
        : t('bills:settlements.deposit-error', {
            min: formatMoneyWithCurrency(minDepositAmount),
            max: formatMoneyWithCurrency(totalAmount),
          });
    },
  }) || {};

  useEffect(() => {
    if (!open) {
      reset();
      setValue('settlementDate', new Date());
    }
  }, [open, reset, setValue]);

  return (
    <>
      <DialogOverlay
        open={open}
        closeDialog={handleClose}
        contentClass={styles['dialog-content']}
        dialogHeader={t('bills:settlements.settlement')}
        showBackButton={step === steps.review}
        goBack={() => {
          setStep(steps.submit);
        }}
        scroll="body"
        alignActions="right"
        dialogActions={
          <>
            {step === steps.submit && (
              <PrimaryButton type="submit" onClick={handleSubmit(onSubmit)}>
                {t('submit')}
              </PrimaryButton>
            )}
            {step === steps.review && (
              <PrimaryButtonLoader
                loading={isSaving}
                disabled={isSaving}
                onClick={saveSettlement}
                type="button"
              >
                {t(`translation:confirm`)}
              </PrimaryButtonLoader>
            )}
          </>
        }
      >
        {step === steps.submit && (
          <>
            <p className={styles.subheader}>
              {t('bills:settlements.subtitle', {
                amount: formatMoneyWithCurrency(totalAmount),
              })}
            </p>
            <LocalisedErrorBox
              open={error !== null}
              closeBox={clearError}
              {...(error || {})}
              distanceBottom=""
            />
            <h3>{t('bills:settlements.title')}</h3>
            <form noValidate>
              <StandardInput
                inputRef={amountInputRef}
                name={amountInputName}
                label={t('bills:settlements.deposit-amount')}
                errorMessage={errors.amount ? errors.amount.message : ''}
                error={!!errors.amount}
                InputProps={null}
                defaultValue={
                  proposedSettlement
                    ? proposedSettlement?.InAdvanceAmmount
                    : null
                }
                {...amountInputRest}
              />
              <DatePicker
                label={t('bills:settlements.deposit-date')}
                value={selectedDate}
                onChange={handleDateChange}
                errors={errors}
                required={true}
                control={control}
                name="settlementDate"
                disableToolbar={false}
                disablePast={true}
                maxDate={lastInstallmentDay}
                autoOk={true}
                animateYearScrolling
                shouldDisableDate={(date) =>
                  !isDateAvailableForInstallment(date, holidays)
                }
                validator={(date) => {
                  if (!isDateAvailableForInstallment(date, holidays)) {
                    return t('bills:settlements.date-error');
                  }
                  if (
                    isBefore(date, startOfDay(new Date())) ||
                    isAfter(date, lastInstallmentDay)
                  ) {
                    return t('bills:settlements.date-error');
                  }
                  return true;
                }}
              />

              <ControlledSelect
                label={t('bills:settlements.installments')}
                value={installments}
                control={control}
                name="months"
                onChange={handleMonthsChange}
                defaultValue={
                  proposedSettlement &&
                  proposedSettlement?.Installments?.length > 0
                    ? proposedSettlement?.Installments?.length
                    : ''
                }
                errors={errors}
                autoWidth={true}
                MenuProps={{
                  classes: { list: styles.selectMenu },
                }}
              >
                <StandardSelectOption
                  key="default"
                  value=""
                  style={{ display: 'none' }}
                >
                  -
                </StandardSelectOption>
                {numbers.map((number) => (
                  <StandardSelectOption
                    key={number}
                    value={number}
                    menuItemClass={styles.menuItem}
                  >
                    {number}
                  </StandardSelectOption>
                ))}
              </ControlledSelect>
            </form>
          </>
        )}
        {step === steps.review && (
          <>
            <p className={styles.subheader}>
              {t('bills:settlements.settlement-confirm')}
            </p>
            <SettlementAnalysis installments={installmentsToReview} />
          </>
        )}
      </DialogOverlay>
      {successDialog.isOpen === true && (
        <SuccessDialog
          open={successDialog.isOpen}
          closeDialog={() => {
            successDialog.close();
            if (typeof onSettlementCreated === 'function') {
              onSettlementCreated();
            }
          }}
          header={t(`translation:completion`)}
          message={t(`bills:settlements.settlements-request-accepted`)}
        />
      )}
      {errorDialog.isOpen === true && (
        <ErrorDialog
          open={errorDialog.isOpen}
          closeDialog={() => {
            errorDialog.close();
            if (typeof onSettlementCreated === 'function') {
              onSettlementCreated();
            }
          }}
          header={t(`translation:completion-fail`)}
          message={t(`bills:settlements.something-happened`)}
        />
      )}
    </>
  );
};
export default CreateSettlementDialog;
