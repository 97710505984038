import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAccountMeters from 'data/useAccountMeters';
import useError from 'hooks/useError';
import useDialog from 'hooks/useDialog';
import {
  trackContestsEvent,
  trackEbillContestsEvent,
} from 'utils/product/tracking/events';
import commodities from 'utils/product/enums/commodities';
import { setAccountEbilling } from 'api/account';
import { ReactComponent as TreeIcon } from 'assets/icons/TreeIcon.svg';
import { CustomButton } from 'components/Offers/OfferElements';

import { participate } from 'api/contest';
import { useAuthUser } from 'utils/AuthUser';
import { LocalisedErrorBox } from 'components/MessageBox/MessageBox';
import {
  PromptDialog,
  SuccessDialog,
  LocalisedErrorDialog,
} from 'components/PromptDialogs/PromptDialog';
import useGeneralConfig from 'data/useGeneralConfig';

const ContestParticipation = ({ contest, mutateParticipations }) => {
  const { t } = useTranslation(['offers']);
  const { user, accountId } = useAuthUser();
  const {
    data: {
      customContestIds: { ebill: ebillContestId },
    },
  } = useGeneralConfig() || {};
  const ebillDialog = useDialog();
  const successDialog = useDialog();
  const {
    error: ebillError,
    handleError: handleEbillError,
    clearError: clearEbillError,
  } = useError();
  const { error, handleError, clearError } = useError();
  const {
    contestId,
    title,
    button: { buttonTitle: ctaText } = {},
    successTitle,
    successDescription,
  } = contest;
  const {
    meters,
    loading: metersLoading,
    mutate: mutateMeters,
  } = useAccountMeters();
  const [loading, setLoading] = useState(false);

  const invokeParticipation = () => {
    if (contest.contestId === ebillContestId) {
      const activeMeters = meters
        ? meters.filter((x) => x.Commodity === commodities.energy && x.IsActive)
        : [];
      if (activeMeters.length === 0) {
        handleError('noActive', 'contest');
        return;
      }

      const hasEnabledEbill =
        activeMeters.filter((x) => x.Ebill === false).length === 0;
      if (!hasEnabledEbill) {
        ebillDialog.show();
        return;
      }
    }
    completeParticipation();
  };

  const completeParticipation = () => {
    setLoading(true);
    participate(contestId, user?.Id)
      .then((x) => {
        successDialog.show();
      })
      .catch((errorResponse) => {
        handleError(errorResponse, 'contest');
      })
      .then((x) => setLoading(false));
  };

  const enableEbill = () => {
    ebillDialog.startLoading();

    trackEbillContestsEvent('contest_ebill_activation_selected', title);
    setAccountEbilling(true, commodities.energy, accountId)
      .then((res) => {
        ebillDialog.close();
        completeParticipation();
        mutateMeters();
      })
      .catch((errorResponse) => {
        handleEbillError(errorResponse.response, 'ebill');
      })
      .then(() => {
        ebillDialog.stopLoading();
      });
  };
  return (
    <>
      <CustomButton
        clickHandler={() => {
          trackContestsEvent('contest_button_selected', title);
          invokeParticipation();
        }}
        text={ctaText}
        loading={loading}
        disabled={metersLoading || loading}
      />
      <PromptDialog
        open={ebillDialog.isOpen}
        closeDialog={ebillDialog.close}
        icon={<TreeIcon width={72} height={72} />}
        header={t('offers:ebillContest.title')}
        message={t('offers:ebillContest.message')}
        showCloseButton={false}
        primaryActionText={t('offers:ebillContest.cta')}
        primaryActionHandler={enableEbill}
        primaryActionLoading={ebillDialog.isLoading}
        showSecondaryAction={true}
        secondaryActionHandler={ebillDialog.close}
      >
        <LocalisedErrorBox
          open={ebillError !== null}
          closeBox={clearEbillError}
          {...(ebillError || {})}
        />
      </PromptDialog>
      <LocalisedErrorDialog
        open={error !== null}
        closeDialog={clearError}
        showCloseButton={false}
        showPrimaryAction={true}
        primaryActionHandler={clearError}
        {...(error || {})}
      />
      <SuccessDialog
        open={successDialog.isOpen}
        closeDialog={() => {
          successDialog.close();
          if (typeof mutateParticipations === 'function')
            mutateParticipations();
        }}
        header={successTitle}
        message={successDescription}
      />
    </>
  );
};

export default ContestParticipation;
