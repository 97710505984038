import React from 'react';

import paymentTypes from 'utils/product/enums/paymentTypes';
import RecurringPaymentSuccess from './RecurringPaymentSuccess';
import CardPaymentsSuccess from './CardPaymentsSuccess';

const PaymentSuccessDialog = ({
  open = false,
  closeDialog,
  openDialog,
  paymentResult,
  paymentType,
  isRecurringCardUpdate,
  ...rest
}) => {
  return paymentType === paymentTypes.recurring ? (
    <RecurringPaymentSuccess
      open={open}
      openDialog={openDialog}
      closeDialog={closeDialog}
      paymentResult={paymentResult}
      paymentType={paymentType}
      isCardUpdate={isRecurringCardUpdate}
      {...rest}
    />
  ) : (
    <CardPaymentsSuccess
      open={open}
      openDialog={openDialog}
      closeDialog={closeDialog}
      paymentResult={paymentResult}
      paymentType={paymentType}
      {...rest}
    />
  );
};

export default PaymentSuccessDialog;
