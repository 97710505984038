import React, { useEffect } from 'react';
import GenericLoader from 'components/GenericLoader/GenericLoader';
import { isTestUrl } from 'utils/urlUtils';

const PaymentResult = () => {
  useEffect(() => {
    const handleMessage = (event) => {
      if (isTestUrl(event.origin)) {
        try {
          if (event.data && event.data.pageOrigin) {
            if (window.location.origin !== event.data.pageOrigin) {
              const urlParts = [
                event.data.pageOrigin,
                window.location.pathname,
                window.location.search,
              ];

              window.location.href = urlParts.join('');
            }
          }
        } catch (e) {}
      }
    };

    window.parent.postMessage('Payment result loaded.', '*');

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return <GenericLoader secondary={true} />;
};

export default PaymentResult;
