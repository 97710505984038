import React from 'react';

import {
  getLVConsumptionInfo,
  isNewMeter,
  meterOwnReadings,
} from 'utils/product/consumptionUtils';

import {
  ConsumptionMeasurement,
  ConsumptionForecast,
  ConsumptionClearingDetails,
  ConsumptionDivider,
} from 'components/Consumptions/DetailViewStates/ConsumptionDetailElements';
import RegisterOwnReadingsPrompt from 'components/Consumptions/RegisterOwnReadings/RegisterOwnReadingsWidget';

import styles from './ConsumptionCountAndWin.module.scss';

const ConsumptionCountAndWin = ({ meter, refreshData }) => {
  const {
    duration,
    dayTimeConsumption,
    nightTimeConsumption,
    consumptionPerDay,
    consumptionPerNight,
    dayEstimation,
    nightEstimation,
    userRegistrationDay,
    userRegistrationNight,
    hasNightMeasurementCode,
  } = getLVConsumptionInfo(meter);
  const dayType = hasNightMeasurementCode ? 'day' : 'default';
  const { hasRegistered } = meterOwnReadings(meter);
  const isMeterNew = isNewMeter(meter);

  return (
    <section className={styles.container}>
      {hasRegistered && (
        <ConsumptionMeasurement type={dayType} value={userRegistrationDay} />
      )}
      {!isMeterNew && (
        <>
          <ConsumptionForecast
            meterId={meter.Id}
            consumptionPerDay={consumptionPerDay}
            consumptionTotal={dayTimeConsumption}
            consumptionEstimation={dayEstimation}
            duration={duration}
            type={dayType}
          />
          <ConsumptionClearingDetails
            duration={duration}
            consumptionTotal={dayTimeConsumption}
            type={dayType}
          />
        </>
      )}
      {hasNightMeasurementCode && (
        <>
          {(hasRegistered || !isMeterNew) && <ConsumptionDivider />}
          {hasRegistered && (
            <ConsumptionMeasurement
              type="night"
              value={userRegistrationNight}
            />
          )}
          {!isMeterNew && (
            <>
              <ConsumptionForecast
                meterId={meter.Id}
                consumptionPerDay={consumptionPerNight}
                consumptionTotal={nightTimeConsumption}
                consumptionEstimation={nightEstimation}
                duration={duration}
                type="night"
              />
              <ConsumptionClearingDetails
                duration={duration}
                consumptionTotal={nightTimeConsumption}
                type="night"
              />
            </>
          )}
        </>
      )}
      {(hasRegistered || !isMeterNew) && <ConsumptionDivider />}
      <RegisterOwnReadingsPrompt meter={meter} refreshData={refreshData} />
    </section>
  );
};
export default ConsumptionCountAndWin;
