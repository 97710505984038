import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const Loader = ({
  show,
  children,
  variant = 'rect',
  width = '100%',
  height = 300,
  ...rest
}) => {
  return show ? (
    <Skeleton variant={variant} width={width} height={height} {...rest} />
  ) : (
    children ?? null
  );
};

export default Loader;
