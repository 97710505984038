import React from 'react';

import { useTranslation } from 'react-i18next';
import { formatDate, isPast, getDifferenceInDays } from 'utils/dateUtils';
import { dateFormats } from 'utils/product/config';
import {
  expirationLabelThemes,
  expirationLabelTypes,
} from 'utils/product/enums/expirationLabels';
import { isEmptyString } from 'utils/stringUtils';

import styles from './ExpirationLabel.module.scss';

const ExpirationLabel = ({
  expirationDate,
  expirationWarningDays = 10,
  type = expirationLabelTypes.countdown,
  labelTheme,
}) => {
  const { t } = useTranslation(['offers']);
  const baseName = 'offers:expirationLabel';

  const getLabel = (expirationDate, type) => {
    if (isEmptyString(expirationDate)) return null;
    if (isPast(expirationDate)) {
      return t(`${baseName}.expired`);
    }
    if (type === expirationLabelTypes.countdown) {
      const days = getDifferenceInDays(new Date(), expirationDate, {
        useStartOfDay: false,
      });
      if (days <= expirationWarningDays)
        return t(`${baseName}.${days === 0 ? 'expiresToday' : 'expiresIn'}`, {
          count: days,
        });
    }
    if (type === expirationLabelTypes.date) {
      return `${t(`${baseName}.expiresAt`)} ${formatDate(
        expirationDate,
        dateFormats.dayMonthNoPad
      )}`;
    }
    return null;
  };

  const expirationString = getLabel(expirationDate, type);

  return (
    expirationString && (
      <p
        className={
          styles[
            `label-${(labelTheme ?? expirationLabelThemes.dark).toLowerCase()}`
          ]
        }
      >
        {expirationString}
      </p>
    )
  );
};

export default ExpirationLabel;
