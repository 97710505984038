import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import QuestionnaireDialog from 'components/Loyalty/QuestionnaireDialog';
import LoyaltyConsentDialog from 'components/Loyalty/LoyaltyConsentDialog';
import Offer from './Offer';
import { loyaltyPaths } from 'utils/product/loyalty/config';
import AuthorizedNonLoyalty from 'components/Loyalty/AuthorizedNonLoyalty';

const OfferRouter = () => {
  return (
    <Routes>
      <Route
        path={`loyalty/${loyaltyPaths.questionnaire}`}
        element={<Offer component={<QuestionnaireDialog />} />}
      />
      <Route
        path={`loyalty/${loyaltyPaths.consent}`}
        element={
          <AuthorizedNonLoyalty
            component={<Offer component={<LoyaltyConsentDialog />} />}
          />
        }
      />
      <Route index path="/" element={<Offer />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default OfferRouter;
