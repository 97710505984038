import React from 'react';
import i18n from 'i18next';

import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { useAuthUser } from './AuthUser';
import useWebDowntime from 'data/useWebDowntime';
import GenericLoader from 'components/GenericLoader/GenericLoader';
import CookiesConsent from 'components/Cookies/CookiesConsent';
import AppBanner from 'components/AppDownload/AppBanner';
import useScrollToTop from 'hooks/useScrollToTop';

/*
  Used for routes that we only want to show
  when the user is not logged in. Contrary,
  if the user is logged in we redirect him
  to the homepage
*/
const UnauthorizedRoute = ({
  component,
  hideAppBanner,
  hideCookies,
  ...rest
}) => {
  const { isLoggedIn, loading } = useAuthUser();
  const location = useLocation();
  const {
    data: { downTimeWeb = {}, hasDownTime } = {},
    loading: configLoading,
  } = useWebDowntime(i18n.language) || {};

  useScrollToTop();

  if (configLoading) return <GenericLoader />;
  if (hasDownTime)
    return <Navigate to={'/under-maintenance'} state={downTimeWeb} />;

  if (!loading && isLoggedIn && location.pathname !== '/login') {
    return <Navigate to="/" />;
  }
  return (
    <>
      {!hideAppBanner && <AppBanner />}
      {component}
      {!hideCookies && <CookiesConsent />}
    </>
  );
};

export default UnauthorizedRoute;
