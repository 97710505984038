import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAuthUser } from 'utils/AuthUser';
import GenericLoader from 'components/GenericLoader/GenericLoader';

const AuthorizedNonLoyalty = ({ component }) => {
  const { isLoyaltyCustomer, loyaltyLoading } = useAuthUser();

  if (loyaltyLoading) return <GenericLoader />;

  if (isLoyaltyCustomer) {
    return <Navigate to="/" replace={true} />;
  }

  return component;
};

export default AuthorizedNonLoyalty;
