import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import HomeBillsList from './HomeBillsList';
import SingleColumnLayout from 'layouts/SingleColumnLayout';
import { ReactComponent as EnergyIcon } from 'assets/icons/LoyaltyEnergyIcon.svg';
import IconText from 'components/IconText/IconText';
import { useCollectionOptions } from 'data/useCollectionOptions';
import { getPointsOfCategory } from 'utils/product/loyalty/pointsUtils';
import { ReactComponent as EmptyBills } from 'assets/icons/EmptyBillsHistory.svg';

import { formatMoney } from 'utils/moneyUtils';
import useAccountMeters from 'data/useAccountMeters';
import {
  getSortedMetersForHomePage,
  getPendingMeters,
} from 'utils/product/meterUtils';
import {
  meterStatusesElectricity,
  meterStatusesGas,
} from 'utils/product/enums/meterStatuses';
import { collectionOptionTypes } from 'utils/product/enums/cmsEnums';
import EmptyStateWidget from 'components/Widget/EmptyStateWidget';
import { trackLoyaltyBillPaymentEvent } from 'utils/product/tracking/loyaltyEvents';

import styles from './PendingBills.module.scss';

const PendingBills = () => {
  const { t } = useTranslation(['homepage']);
  const { collectionOptionsByType } = useCollectionOptions();

  const billPaymentPoints = getPointsOfCategory(
    collectionOptionsByType,
    collectionOptionTypes.BILL_PAYMENT,
  );
  const billPaymentInTimePoints = getPointsOfCategory(
    collectionOptionsByType,
    collectionOptionTypes.BILL_PAYMENT_DUE,
  );
  const {
    meters,
    loading: metersLoading,
    mutate: mutateMeters,
  } = useAccountMeters({
    statusesElectricity: [
      meterStatusesElectricity.active,
      meterStatusesElectricity.terminated,
    ],
    statusesGas: [meterStatusesGas.active, meterStatusesGas.terminated],
  });

  const allMeters = metersLoading ? [] : getSortedMetersForHomePage(meters);

  const pendingMeters = getPendingMeters(allMeters, false);

  return (
    <SingleColumnLayout
      head={{
        title: t('homepage:bills-payment.title'),
        metaTags: [
          { name: 'title', content: t('homepage:bills-payment.title') },
        ],
      }}
    >
      <Widget
        background="transparent"
        title={<WidgetHeader title={t('homepage:bills-payment.title')} />}
        widgetClass={styles.card}
      >
        <p>{t('homepage:bills-payment.message')}</p>
        <IconText
          containerClass={styles.topIconSection}
          content={
            <Trans
              i18nKey={'homepage:bills-payment.reward'}
              values={{
                amount: formatMoney(billPaymentPoints, false),
              }}
              components={{
                bold: <b />,
                yellow: <span className={styles['warning-text']}></span>,
              }}
            />
          }
          Icon={<EnergyIcon width={24} height={24} />}
          gap="xs"
        />
        <IconText
          containerClass={styles.bottomIconSection}
          content={
            <>
              <Trans
                i18nKey={'homepage:bills-payment.bonus'}
                values={{
                  points: formatMoney(billPaymentInTimePoints, false),
                }}
                components={{
                  bold: <b></b>,
                  yellow: <span className={styles['warning-text']}></span>,
                }}
              />
              <p className={styles.subtext}>
                {t('homepage:bills-payment.bonus-message')}
              </p>
            </>
          }
          Icon={<EnergyIcon width={24} height={24} />}
          gap="xs"
        />
        {!metersLoading && pendingMeters.length === 0 && (
          <EmptyStateWidget
            icon={<EmptyBills width="72" height="72" />}
            text={t('homepage:bills-no-more.title')}
            isFullWidth={false}
          />
        )}
        <HomeBillsList
          loading={metersLoading}
          meters={pendingMeters}
          onPaymentSuccess={() => {
            trackLoyaltyBillPaymentEvent(
              'bill_payment_success_with_loyalty_points',
            );
            mutateMeters();
          }}
        />
      </Widget>
    </SingleColumnLayout>
  );
};
export default PendingBills;
