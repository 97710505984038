import React from 'react';
import { useTranslation } from 'react-i18next';

import { prepareMetersList } from 'utils/product/consumptionUtils';
import {
  trackConsumptionHistoryEvent,
  trackHomeConsumptionsEvent,
} from 'utils/product/tracking/events';
import useAccountMeters from 'data/useAccountMeters';
import { ReactComponent as ConsumptionsIcon } from 'assets/icons/Consumptions.svg';
import WidgetHeaderLink from 'components/WidgetHeader/WidgetHeaderLink';
import Widget from 'components/Widget/Widget';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import IconText from 'components/IconText/IconText';
import ConsumptionsStates from 'components/Consumptions/HomeStates/ConsumptionsStates';
import DetailedListWidget from 'components/DetailedListWidget/DetailedListWidget';
import { homeCardsLimit } from 'utils/product/config';

const ConsumptionsOverview = ({ detailed = false }) => {
  const { t } = useTranslation(['translation', 'homepage']);

  const { meters: meterData, loading, mutate } = useAccountMeters();

  const meters = meterData && !loading ? prepareMetersList(meterData) : [];

  const refreshData = () => {
    mutate();
  };

  const WidgetHeader = () => (
    <WidgetHeaderLink
      title={t('homepage:my-consumptions.title')}
      secondTitle={t('homepage:record')}
      linkTo={`/consumptions${
        meters.length > 0 ? `/${meters[0].MeterNo}` : ''
      }`}
      onLinkClicked={() => {
        trackConsumptionHistoryEvent('home_consumption_history_consumption');
      }}
    />
  );

  return (
    <WidgetSkeleton
      distance="76"
      show={loading}
      title={<WidgetHeader />}
      data-testid="skeleton"
    >
      <Widget background="transparent" title={<WidgetHeader />}>
        {!loading && meters && meters.length > 0 ? (
          <>
            {meters
              .slice(0, detailed ? meters.length : homeCardsLimit)
              .map((meter) => {
                return (
                  <ConsumptionsStates
                    key={`${meter.MeterNo}-energyElement`}
                    meter={meter}
                    refreshData={refreshData}
                  />
                );
              })}
            {!detailed && (
              <DetailedListWidget
                show={meters?.length > homeCardsLimit}
                text={t('homepage:consumptions-list-max')}
                link="/all-consumptions"
                onClick={() =>
                  trackHomeConsumptionsEvent('home_consumption_view_all')
                }
              />
            )}
          </>
        ) : (
          <IconText
            Icon={<ConsumptionsIcon />}
            content={t('homepage:consumptions-empty-state')}
            gap="xs"
          />
        )}
      </Widget>
    </WidgetSkeleton>
  );
};

export default ConsumptionsOverview;
