import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tab, Tabs } from 'components/Tabs/Tabs';
import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import FAQsAccordionContent from 'components/FAQsAccordionContent/FAQsAccordionContent';
import TabPanel from 'components/Tabs/TabPanel';

import styles from './MainArea.module.scss';

const MainArea = () => {
  const { t } = useTranslation(['FAQs']);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Widget size="m" title={<WidgetHeader title={t('FAQs:FAQs.title')} />}>
      <section className={styles['tabs-section']}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={t(`FAQs:FAQs.electric`)} />
          <Tab label={t(`FAQs:FAQs.gas`)} />
        </Tabs>
      </section>
      <section className={styles['tabPanels-section']}>
        <TabPanel value={value} index={0} idPrefix="faq-energy">
          <FAQsAccordionContent energyType="FAQs:faqPacketsEnergy" />
        </TabPanel>
        <TabPanel value={value} index={1} idPrefix="faq-gas">
          <FAQsAccordionContent energyType="FAQs:faqPacketsGas" />
        </TabPanel>
      </section>
    </Widget>
  );
};

export default MainArea;
