import { sortByDateFieldDescending } from 'utils/sortUtils';
import paymentResultTypes from './enums/paymentResultTypes';
import paymentTypes from './enums/paymentTypes';
import { isTestUrl } from 'utils/urlUtils';
import Big from 'big.js';
import { creditCardTypes } from './enums/paymentCardEnums';

export const getGroupedMeterPayments = (data) => {
  const groupedPayments = { all: [] };
  const sortByProperty = 'PaymentDate';

  if (data && data.AccountMetersPayments !== null) {
    data.AccountMetersPayments.forEach((paymentData) => {
      if (!paymentData.Payments || paymentData.Payments.length === 0) {
        groupedPayments[paymentData.Meterid] = [];
        return;
      }
      sortByDateFieldDescending(paymentData.Payments, sortByProperty);

      const meterPayments = paymentData.Payments.map((payment) => {
        return {
          ...payment,
          MeterId: paymentData.Meterid,
          MeterNo: paymentData.MeterNo,
          FriendlyName: paymentData.FriendlyName,
          Commodity: paymentData.Commodity,
        };
      });

      groupedPayments[paymentData.Meterid] = meterPayments;
      groupedPayments['all'].push(...meterPayments);
    });

    sortByDateFieldDescending(groupedPayments['all'], sortByProperty);
  }

  return { groupedPayments, allPayments: groupedPayments['all'] };
};

export const isReceiptEmailAvailable = (paymentType) =>
  paymentType !== paymentTypes.guest && paymentType !== paymentTypes.thirdParty;

export const getPaymentOverlayInitialState = (
  paymentType,
  token,
  paymentCode,
  paymentAmount,
  meterId,
  commodity,
  invoiceNo,
  accountId,
) => {
  const _paymentCode =
    paymentType === paymentTypes.guest ||
    paymentType === paymentTypes.thirdParty ||
    paymentType === paymentTypes.recurring
      ? null
      : paymentCode;

  const _token =
    paymentType === paymentTypes.guest ||
    paymentType === paymentTypes.thirdParty
      ? null
      : token;

  const _paymentAmount =
    (paymentType === paymentTypes.bill ||
      paymentType === paymentTypes.settlement) &&
    paymentAmount
      ? paymentAmount
      : null;

  const _guest =
    paymentType === paymentTypes.guest ||
    paymentType === paymentTypes.thirdParty;
  const _meterId =
    paymentType === paymentTypes.guest ||
    paymentType === paymentTypes.thirdParty
      ? null
      : meterId;

  return {
    recurring: paymentType === paymentTypes.recurring,
    paymentCode: _paymentCode,
    token: _token,
    amount: _paymentAmount,
    guest: _guest,
    accountId: _guest ? null : accountId,
    fixedAmount: paymentType === paymentTypes.settlement,
    isEna: paymentType === paymentTypes.ena,
    meterId: _meterId,
    invoiceNo: invoiceNo ? invoiceNo : null,
    commodity: commodity ? commodity : null,
  };
};

export const getPaymentSuccessUrlsMapping = (config, origin) => {
  const { successfulPaymentUrl, successfulRecurringUrl } = config;
  return {
    [successfulPaymentUrl]: paymentResultTypes.paymentSuccess,
    [successfulRecurringUrl]: paymentResultTypes.recurringSuccess,
    ...(isTestUrl(origin)
      ? {
          '/payment/success': paymentResultTypes.paymentSuccess,
          '/recurring-payment/success': paymentResultTypes.recurringSuccess,
        }
      : {}),
  };
};

export const getPaymentFailureUrlsMapping = (config, origin) => {
  const { failedPaymentUrl, failedRecurringUrl, failedRecurringInternalUrl } =
    config;
  return {
    [failedPaymentUrl]: paymentResultTypes.paymentFailure,
    [failedRecurringUrl]: paymentResultTypes.recurringFailure,
    [failedRecurringInternalUrl]: paymentResultTypes.recurringError,
    ...(isTestUrl(origin)
      ? {
          '/payment/failure': paymentResultTypes.paymentFailure,
          '/recurring-payment/failure': paymentResultTypes.recurringFailure,
          '/recurring-payment/internal-error':
            paymentResultTypes.recurringError,
        }
      : {}),
  };
};

export const isFullPaymentForLoyaltyReward = (
  amountForBonusReward,
  paidAmount,
) => {
  return (
    amountForBonusReward > 0 &&
    new Big(amountForBonusReward).lte(new Big(paidAmount))
  );
};

export const getCreditCardType = (cardNumber) => {
  const maestroRegex =
    /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d/;
  const mastercardRegex = /^(2|5)\d/;
  const visaRegex = /^4\d/;

  if (maestroRegex.test(cardNumber)) {
    return creditCardTypes.MAESTRO;
  }

  if (visaRegex.test(cardNumber)) {
    return creditCardTypes.VISA;
  }

  if (mastercardRegex.test(cardNumber)) {
    return creditCardTypes.MASTERCARD;
  }
  return '';
};
