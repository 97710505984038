import React from 'react';
import { useTranslation } from 'react-i18next';

import cn from 'classnames';
import ExpirationLabel from 'components/Labels/ExpirationLabel';
import {
  expirationLabelThemes,
  expirationLabelTypes,
} from 'utils/product/enums/expirationLabels';
import { WinnerLabel } from 'components/Loyalty/LoyaltyLabels';
import MaxedOutLabel from 'components/Labels/MaxedOutLabel';
import useGeneralConfig from 'data/useGeneralConfig';

import styles from './OfferImage.module.scss';

const OfferImage = ({
  imageUrl,
  largeImageUrl,
  expirationDate,
  labelType = expirationLabelTypes.countdown,
  labelTheme = expirationLabelThemes.dark,
  containerClass = '',
  userIsWinner,
  gameLimitReached,
}) => {
  const { t } = useTranslation('profile');

  const {
    data: { discoveryExpirationWarningDays },
  } = useGeneralConfig() || {};
  return (
    <div className={cn(styles.container, containerClass)}>
      {userIsWinner && <WinnerLabel content={t('my-contests.win-label')} />}
      <section
        className={styles.mobileImage}
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        <ExpirationLabel
          expirationDate={expirationDate}
          expirationWarningDays={discoveryExpirationWarningDays}
          type={labelType}
          labelTheme={labelTheme}
        />
        {gameLimitReached && <MaxedOutLabel labelTheme={labelTheme} />}
      </section>
      <section
        className={styles.image}
        style={{ backgroundImage: `url(${largeImageUrl})` }}
      >
        <ExpirationLabel
          expirationDate={expirationDate}
          expirationWarningDays={discoveryExpirationWarningDays}
          type={labelType}
          labelTheme={labelTheme}
        />
        {gameLimitReached && <MaxedOutLabel labelTheme={labelTheme} />}
      </section>
    </div>
  );
};

export default OfferImage;
