import React from 'react';
import { useTranslation } from 'react-i18next';

import Accordion from 'components/Accordion/Accordion';
import IconText from 'components/IconText/IconText';
import { PrimaryButton } from 'components/Buttons/Buttons';
import { Link } from 'react-router-dom';
import { trackLoyaltyPageVibesEvent } from 'utils/product/tracking/loyaltyEvents';

import styles from './RemainingVibes.module.scss';

const RemainingVibes = ({ vibes = [] }) => {
  const { t } = useTranslation(['translation', 'loyalty']);

  const accordionClasses = {
    root: styles['accordion'],
    expanded: styles['accordion-expanded'],
  };
  const accordionSummaryClasses = {
    root: styles['accordion-summary'],
    expanded: styles['accordion-summary-expanded'],
    content: styles['accordion-summaryContent'],
    expandIcon: styles['accordion-expandIcon'],
  };

  const accordionDetailsClasses = {
    root: styles['accordion-details'],
  };
  return (
    vibes.length > 0 && (
      <div className={styles.container}>
        <p>{t(`loyalty:landing.remaining-vibes`)}</p>
        {vibes.map((vibe) => (
          <Accordion
            key={vibe.key}
            classes={accordionClasses}
            summaryClasses={accordionSummaryClasses}
            detailsClasses={accordionDetailsClasses}
            horizontalLine={false}
            onChange={(event, expanded) => {
              if (expanded)
                trackLoyaltyPageVibesEvent(
                  'loyalty_myvibes_inactive_vibe_unfold_selected',
                  '',
                  { vibe_type: vibe.title }
                );
            }}
            summary={
              <IconText
                weight="bold"
                Icon={
                  <img
                    src={vibe.imageUrl}
                    alt={vibe.key}
                    width={32}
                    height={32}
                  />
                }
                content={vibe.title}
              />
            }
            details={vibe.description}
          />
        ))}
        <Link to="/offers">
          <PrimaryButton className={styles.cta}>
            {t(`loyalty:landing.cta`)}
          </PrimaryButton>
        </Link>
      </div>
    )
  );
};
export default RemainingVibes;
