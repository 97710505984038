import { useCmsContent } from './useCmsContent';
import { cmsSchemas } from 'utils/product/enums/cmsEnums';

const useForYouSchema = (language) => {
  const {
    data: response,
    loading,
    error,
  } = useCmsContent(
    'for_you,redemption_options',
    language,
    false,
    '[schema_in]',
    { includeSchemas: [cmsSchemas.contests, cmsSchemas.games] }
  );

  if (!loading && response) {
    const forYouItems = response.data?.filter((_item) => {
      return _item.schema === cmsSchemas.forYou;
    });
    const offerItems = response?.data
      ?.filter((_item) => _item.schema === cmsSchemas.offers)
      .reduce((offers, offerItem) => {
        offers[offerItem.id] = offerItem;
        return offers;
      }, {});

    const transformedData = {
      loading: loading,
      error: error,
      data: {
        ...response,
        data: forYouItems,
        included: {
          ...response.included,
          [cmsSchemas.offers]: offerItems,
        },
      },
    };
    return transformedData;
  }

  return {
    loading: loading,
    error: error,
    data: response,
  };
};
export { useForYouSchema };
