import React from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import supplyNumberTypes from 'utils/product/enums/supplyNumberTypes';
import { StandardInput } from './Inputs';
import IconPopover from 'components/Popover/IconPopover';
import { ReactComponent as InfoIconFilled } from 'assets/icons/InfoCircleIcon.svg';

import styles from './SupplyNumberInput.module.scss';

const getRegexValidator = (_supplyNumberType) => {
  if (_supplyNumberType === supplyNumberTypes.electricityOrGas)
    return /^(\d{8}|\d{14})$/;
  if (_supplyNumberType === supplyNumberTypes.gas) return /^\d{14}$/;
  if (_supplyNumberType === supplyNumberTypes.electricityNoBill)
    return /^\d{9}$/;
  if (_supplyNumberType === supplyNumberTypes.electricityWithBill)
    return /^\d{11}$/;
  console.error('You must specify a supplyNumberType prop');
};

const supplyNumberLength = {
  [supplyNumberTypes.gas]: 14,
  [supplyNumberTypes.electricityNoBill]: 9,
  [supplyNumberTypes.electricityWithBill]: 11,
  [supplyNumberTypes.electricityOrGas]: 8,
};

const SupplyNumberInput = ({
  register,
  errors,
  label,
  name = 'supplyNumber',
  adornmentPosition,
  required = true,
  supplyNumberType,
  showInfo = true,
  onInfoClicked,
  ...rest
}) => {
  const { t } = useTranslation(['inputs']);
  const baseName = 'inputs:errors';
  const digits = supplyNumberLength[supplyNumberType] ?? 8;

  const regexPattern = getRegexValidator(supplyNumberType);

  const { ref, ...registerRest } =
    register(name, {
      required: { value: required, message: t(`${baseName}.required`) },
      pattern: {
        value: regexPattern,
        message: t(
          `${baseName}.supplyNumber${
            supplyNumberType === supplyNumberTypes.electricityOrGas
              ? '-electricityOrGas'
              : ''
          }`,
          { digits: digits }
        ),
      },
    }) || {};

  return (
    <>
      <StandardInput
        inputRef={ref}
        label={label}
        type="text"
        errorMessage={get(errors, `${name}.message`, null)}
        error={get(errors, `${name}`, null) !== null}
        endAdornment={
          showInfo ? (
            <IconPopover
              id={`supply-number-popover`}
              content={t(`inputs:supplyNumber-${supplyNumberType}`)}
              icon={<InfoIconFilled />}
              popoverClass={styles.popover}
              placement="bottom-end"
              buttonClass={styles.infoIcon}
              clickHandler={() => {
                if (typeof onInfoClicked === 'function') onInfoClicked();
              }}
            />
          ) : null
        }
        {...rest}
        {...registerRest}
      />
    </>
  );
};

export { SupplyNumberInput };
