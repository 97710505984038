import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuthUser } from 'utils/AuthUser';
import { PrimaryButton } from 'components/Buttons/Buttons';
import { loyaltyPaths } from 'utils/product/loyalty/config';
import { trackWhatsNewEvent } from 'utils/product/tracking/loyaltyEvents';

import styles from './LoyaltyRegistrationButton.module.scss';

const LoyaltyRegistrationButton = ({
  offer,
  ctaText,
  closeDialog,
  onClose,
}) => {
  const { t } = useTranslation(['translation', 'profile']);
  const { isLoyaltyCustomer } = useAuthUser();
  const navigate = useNavigate();

  const handleClick = () => {
    trackWhatsNewEvent('whats_new_loyalty_continue');
    if (!isLoyaltyCustomer) {
      if (typeof closeDialog === 'function') closeDialog();
      navigate(
        offer
          ? `${window.location?.pathname}/loyalty/${loyaltyPaths.consent}`
          : `/loyalty/${loyaltyPaths.consent}`,
        {
          state: offer ? window.location?.pathname : '',
        }
      );
      return;
    }

    navigate('/');

    if (typeof onClose === 'function') onClose();
  };
  return (
    <PrimaryButton
      className={offer && styles.offerVibesButton}
      onClick={handleClick}
    >
      {offer ? ctaText : t('translation:continue')}
    </PrimaryButton>
  );
};

export default LoyaltyRegistrationButton;
