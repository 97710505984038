import React from 'react';
import RadioButtonsGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export default function RadioGroup({
  children,
  value,
  name,
  labelValue,
  labelClass = '',
  handleChange,
  containerClass = '',
}) {
  return (
    <FormControl>
      {labelValue && <FormLabel className={labelClass}>{labelValue}</FormLabel>}
      <RadioButtonsGroup
        name={name}
        value={value}
        onChange={handleChange}
        className={containerClass}
      >
        {children}
      </RadioButtonsGroup>
    </FormControl>
  );
}
