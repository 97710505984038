import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import { useDiscoveryItem } from 'data/useDiscoveryItem';
import SingleColumnLayout from 'layouts/SingleColumnLayout';
import LoyaltyOffer from 'components/Offers/LoyaltyOffer';

import styles from './Offer.module.scss';

const Offer = ({ component = null }) => {
  const { t, i18n } = useTranslation(['offers']);
  const language = i18n.language;

  const { slug } = useParams();

  const { data: offer, loading } = useDiscoveryItem(slug, language);

  if (!loading && !offer) {
    return <Navigate to="/offers" />;
  }

  const pageTitle = t(`offers:details.offer-title`);
  return (
    <>
      {component}
      <SingleColumnLayout
        head={{
          title: offer ? offer.title : pageTitle,
          metaTags: [
            {
              name: 'title',
              content: offer ? offer.title : pageTitle,
            },
          ],
        }}
      >
        <section>
          <h3 className={styles.header}>{pageTitle}</h3>
          <WidgetSkeleton heightInPixels={540} distance="s" show={loading}>
            <LoyaltyOffer offer={offer} />
          </WidgetSkeleton>
        </section>
      </SingleColumnLayout>
    </>
  );
};

export default Offer;
