import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTermsHtml } from 'data/useTerms';
import GenericLoader from 'components/GenericLoader/GenericLoader';

import styles from './TermsRenderer.module.scss';

const TermsRenderer = () => {
  const { slug } = useParams();
  const { i18n } = useTranslation();

  const { data, loading } = useTermsHtml(slug, i18n.language, false);

  return loading ? (
    <GenericLoader />
  ) : (
    <>
      {data && (
        <div
          className={styles.container}
          dangerouslySetInnerHTML={{ __html: data }}
        />
      )}
    </>
  );
};

export default TermsRenderer;
