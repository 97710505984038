import React from 'react';
import { useTranslation } from 'react-i18next';

import PaymentCard from 'components/PaymentCard/PaymentCard';
import { paymentCardBannerTypes } from 'utils/product/enums/paymentCardEnums';
import { PrimaryButton, LinkButton } from 'components/Buttons/Buttons';

import styles from './StandingOrderEditCardContent.module.scss';

const StandingOrderEditCardContent = ({
  cardNumber,
  expiryDate,
  cardType,
  loading,
  cardExpired,
  handleChangeCard,
  handleDisableCard,
}) => {
  const { t } = useTranslation(['management', 'translation']);

  const baseName = 'management:standing-order';
  return (
    <>
      <div className={styles.content}>
        <PaymentCard
          showFriendlyName={false}
          cardNumber={cardNumber}
          expirationDate={expiryDate}
          creditCardType={cardType}
          contentType={paymentCardBannerTypes.DETAIL_VIEW}
          isLoading={loading}
          isExpired={cardExpired}
        />
        <PrimaryButton
          className={styles.actionButton}
          onClick={handleChangeCard}
        >
          {t(`${baseName}.edit.cta`)}
        </PrimaryButton>
      </div>
      <LinkButton
        extraClasses={styles.standingOrderDeactivationButton}
        onClick={handleDisableCard}
      >
        {t(`${baseName}.edit.secondary-cta`)}
      </LinkButton>
    </>
  );
};

export default StandingOrderEditCardContent;
