import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import Accordion from 'components/Accordion/Accordion';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import { useLoyaltyPersonalData } from 'data/useLoyaltyPersonalData';
import { loyaltyPaths } from 'utils/product/loyalty/config';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import CmsHtmlField from 'components/CmsHtmlField/CmsHtmlField';
import useContracts from 'data/useContracts';
import { useAuthUser } from 'utils/AuthUser';
import { getOldestContractDate } from 'utils/product/contractUtils';
import { registration } from 'utils/product/enums/waveEnums';
import { addCustomer } from 'api/loyalty';
import useError from 'hooks/useError';
import useDialog from 'hooks/useDialog';
import { LocalisedErrorDialog } from 'components/PromptDialogs/PromptDialog';

import styles from './LoyaltyConsentDialog.module.scss';

const LoyaltyConsentDialog = () => {
  const { t, i18n } = useTranslation(['loyalty']);
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedAccount, loyaltyCustomer, mutateLoyaltyCustomer } =
    useAuthUser();
  const { data: contracts, loading: contractsLoading } = useContracts(
    !!loyaltyCustomer
  );
  const [isSubmitting, setIsSubmitting] = useState();
  const [open, setOpen] = useState(true);
  const { error, handleError, clearError } = useError();
  const errorDialog = useDialog();

  const accordionClasses = {
    root: styles['accordion'],
  };
  const accordionSummaryClasses = {
    root: styles['accordion-summary'],
    expanded: styles['accordion-summary-expanded'],
    content: styles['accordion-summaryContent'],
    expandIcon: styles['accordion-expandIcon'],
  };

  const { data: personalData, loading } = useLoyaltyPersonalData(i18n.language);
  const { terms_list: termsList } = personalData || [];

  const accordionDetailsClasses = {
    root: styles['accordion-details'],
  };

  const goBack = () => {
    navigate(-1);
  };

  const goForward = () => {
    navigate(`${location?.state || ''}/loyalty/${loyaltyPaths.questionnaire}`);
  };

  const sendCustomerInfo = () => {
    setIsSubmitting(true);

    const oldestContractSignatureDate = getOldestContractDate(
      contracts,
      'ContractSignatureDate'
    );

    const annivarsaryData = oldestContractSignatureDate
      ? {
          anniversaries: [
            {
              type: registration.PREREGISTER,
              date: oldestContractSignatureDate,
            },
          ],
        }
      : {};

    addCustomer({
      customerId: selectedAccount.Id,
      ...annivarsaryData,
    })
      .then(() => {
        if (typeof closeDialog === 'function') closeDialog();
        mutateLoyaltyCustomer();
        goForward();
      })
      .catch((error) => {
        handleError('general', 'generic');
        if (typeof closeDialog === 'function') closeDialog();
        errorDialog.show();
      })
      .then((x) => setIsSubmitting(false));
  };

  const clickHandler = () => {
    if (!loyaltyCustomer) {
      sendCustomerInfo();
      return;
    }
    goForward();
  };

  const closeDialog = () => {
    setOpen(false);
    navigate('/');
  };

  return (
    <>
      <DialogOverlay
        open={open}
        closeDialog={closeDialog}
        contentClass={styles.dialogContent}
        scroll="body"
        showBackButton
        showCloseButton={false}
        goBack={goBack}
        dialogHeader={personalData?.title}
        alignActions="right"
        dialogActions={
          <PrimaryButtonLoader
            type="button"
            loading={isSubmitting}
            onClick={clickHandler}
            disabled={isSubmitting || contractsLoading}
          >
            {t(`loyalty:register.consent.submit`)}
          </PrimaryButtonLoader>
        }
      >
        <WidgetSkeleton heightInPixels={640} distance="s" show={loading}>
          {termsList?.map((item) => (
            <Accordion
              key={item?.header}
              classes={accordionClasses}
              summaryClasses={accordionSummaryClasses}
              detailsClasses={accordionDetailsClasses}
              summary={item?.header}
              details={<CmsHtmlField value={item?.message} />}
              horizontalLineClass={styles.accordionLine}
            />
          ))}
          <p className={styles['dialogContent-header']}>
            {personalData?.consent?.header}
          </p>
          <CmsHtmlField value={personalData?.consent?.message} />
        </WidgetSkeleton>
      </DialogOverlay>
      {errorDialog.isOpen && (
        <LocalisedErrorDialog
          open={errorDialog.isOpen}
          closeDialog={() => {
            errorDialog.close();
            clearError();
          }}
          showCloseButton={false}
          showPrimaryAction={true}
          primaryActionText={t('translation:ok')}
          primaryActionHandler={() => {
            errorDialog.close();
            clearError();
          }}
          {...(error || {})}
        />
      )}
    </>
  );
};

export default LoyaltyConsentDialog;
