import React from 'react';

import { ReactComponent as WarningCircleIcon } from 'assets/icons/WarningCircleIcon.svg';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/InfoCircleIcon.svg';
import { ReactComponent as RightArrow } from 'assets/icons/ArrowRightIcon.svg';
import { IconButton } from 'components/Buttons/Buttons';
import Widget from 'components/Widget/Widget';

import styles from './AlertWidget.module.scss';

const AlertWidget = ({
  header,
  message,
  distance = 's',
  iconClass = '',
  containerClass = '',
  altText = 'warning',
  type = 'warning',
  showRightArrow = false,
  onAlertClick = () => {},
}) => {
  return (
    <Widget
      background="transparent"
      containerClass={`${styles.container} ${
        showRightArrow ? styles.clickable : ''
      } ${containerClass}`}
      widgetClass={styles.content}
      distance={distance}
      onClick={onAlertClick}
    >
      {type === 'warning' && (
        <WarningCircleIcon alt={altText} className={iconClass} />
      )}
      {type === 'info' && (
        <InfoCircleIcon alt={altText} className={iconClass} />
      )}
      <div className={styles.textContainer}>
        {header && (
          <p
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: header,
            }}
          />
        )}
        <p
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
      </div>
      {showRightArrow && (
        <IconButton
          className={styles.rightArrow}
          aria-label="view more"
          onClick={onAlertClick}
        >
          <RightArrow />
        </IconButton>
      )}
    </Widget>
  );
};

export default AlertWidget;
