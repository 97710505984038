import React from 'react';

import styles from './HistoryRecord.module.scss';

const HistoryRecordHeader = ({ meterName, text }) => {
  return (
    <div className={styles['record-header']}>
      <section className={styles['left-section']}>{meterName}</section>
      <section className={styles['right-section']}>{text}</section>
    </div>
  );
};

export default HistoryRecordHeader;
