import React from 'react';

import { getLVConsumptionInfo } from 'utils/product/consumptionUtils';

import {
  ConsumptionForecast,
  ConsumptionClearingDetails,
  ConsumptionDivider,
} from 'components/Consumptions/DetailViewStates/ConsumptionDetailElements';

import styles from './ConsumptionPrediction.module.scss';

const ConsumptionPrediction = ({ meter }) => {
  const {
    duration,
    dayTimeConsumption,
    nightTimeConsumption,
    consumptionPerDay,
    consumptionPerNight,
    dayEstimation,
    nightEstimation,
    hasNightMeasurementCode,
  } = getLVConsumptionInfo(meter);
  const dayType = hasNightMeasurementCode ? 'day' : 'default';

  return (
    <section className={styles.container}>
      <ConsumptionForecast
        meterId={meter.Id}
        consumptionPerDay={consumptionPerDay}
        consumptionTotal={dayTimeConsumption}
        consumptionEstimation={dayEstimation}
        duration={duration}
        type={dayType}
      />
      <ConsumptionClearingDetails
        duration={duration}
        consumptionTotal={dayTimeConsumption}
        type={dayType}
      />
      {hasNightMeasurementCode && (
        <>
          <ConsumptionDivider />
          <ConsumptionForecast
            meterId={meter.Id}
            consumptionPerDay={consumptionPerNight}
            consumptionTotal={nightTimeConsumption}
            consumptionEstimation={nightEstimation}
            duration={duration}
            type="night"
          />
          <ConsumptionClearingDetails
            duration={duration}
            consumptionTotal={nightTimeConsumption}
            type="night"
          />
        </>
      )}
    </section>
  );
};
export default ConsumptionPrediction;
