import React from 'react';
import ReactSlickSlider from 'react-slick';

import 'styles/overrides/slick.scss';
import styles from './Slider.module.scss';

const Slider = ({
  children,
  containerClass,
  sliderClass = '',
  settings = {},
  ...rest
}) => {
  const mergedSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 6000,
    width: '100%',
    dotsClass: `slick-dots`,
    ...settings,
  };

  return (
    <section className={`${styles.container} ${containerClass}`}>
      <ReactSlickSlider className={sliderClass} {...mergedSettings} {...rest}>
        {children}
      </ReactSlickSlider>
    </section>
  );
};

export default Slider;
