import React from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { numberRegexPattern } from 'utils/product/config';
import { maxLengthPattern } from 'utils/product/inputUtils';
import { StandardInput } from './Inputs';

const MeterInput = ({
  register,
  errors,
  label,
  name = 'text',
  required = true,
  maxDigits = null,
  minDigits = null,
  ...rest
}) => {
  const { t } = useTranslation(['inputs']);
  const baseName = 'inputs:errors';
  const { ref, ...registerRest } =
    register(name, {
      required: { value: required, message: t(`${baseName}.required`) },
      minLength: {
        value: minDigits,
        message: t(`${baseName}.maxLengthMeterInput`, { number: minDigits }),
      },
      validate: {
        maxLength: (value) =>
          maxLengthPattern(maxDigits).test(value) ||
          t(`${baseName}.maxLengthMeterInput`, { number: maxDigits }),
      },
      pattern: {
        value: numberRegexPattern,
        message: t(`${baseName}.isNotNumber`),
      },
    }) || {};

  return (
    <StandardInput
      inputRef={ref}
      label={label}
      type="text"
      errorMessage={get(errors, `${name}.message`, null)}
      error={get(errors, `${name}`, null) !== null}
      {...rest}
      {...registerRest}
    />
  );
};

export { MeterInput };
