import i18n from 'utils/i18n.js';
import {
  format as formatDateFns,
  addDays as addDaysFns,
  subYears,
  isPast as isPastFns,
  isFuture as isFutureFns,
  isBefore as isBeforeFns,
  isAfter as isAfterFns,
  isEqual as isEqualFns,
  differenceInDays,
  parseISO,
  endOfMonth as endOfMonthFns,
  endOfDay as endOfDayFns,
  startOfDay as startOfDayFns,
  isValid,
  isWeekend as isWeekendFns,
  parse,
  fromUnixTime,
  toDate,
  min,
  getYear,
} from 'date-fns';
import { el } from 'date-fns/locale';
import { dateFormats } from './product/config';
import { isEmptyString } from './stringUtils';

export const isValidDate = (date) => {
  return isValid(date);
};

export const parseDate = (date, returnNowIfNull = false, format) => {
  if (date && typeof date === 'object') return date;
  const _parsedDate = format
    ? parse(date, format, new Date())
    : typeof date === 'number'
      ? fromUnixTime(date)
      : parseISO(date);
  if (isValid(_parsedDate) && getYear(_parsedDate) > 1) {
    return _parsedDate;
  }
  return returnNowIfNull ? new Date() : null;
};

export const formatDate = (
  date,
  format = dateFormats.date,
  language = i18n.language,
) => {
  const parsedDate = parseDate(date);

  if (!parsedDate) return '';
  const dateString = formatDateFns(parsedDate, format, {
    locale: language === 'el' ? el : '',
  });
  return dateString;
};

export const formatToUTC = (date) => {
  const parsedDate = parseDate(date);

  if (!parsedDate) return '';
  const dateString = toDate(parsedDate).toISOString();
  return dateString;
};

export const getDayName = (date, { language = i18n.language } = {}) => {
  return formatDate(date, dateFormats.dayName, language);
};

export const addDays = (date, days) => {
  const parsedDate = parseDate(date);
  if (!parsedDate) return null;

  return addDaysFns(parsedDate, days);
};

export const subtractYears = (date, years) => {
  const parsedDate = parseDate(date);
  if (!parsedDate) return null;
  return subYears(parsedDate, years);
};

export const getDifferenceInDays = (dateFrom, dateUntil, options = {}) => {
  const { includeLastDay = false, useStartOfDay = true } = options;
  const parsedDateFrom = parseDate(dateFrom);
  const parsedDateUntil = parseDate(dateUntil);
  if (!parsedDateFrom || !parsedDateUntil) return 0;

  const _from = useStartOfDay ? startOfDay(parsedDateFrom) : parsedDateFrom;
  const _until = addDays(
    useStartOfDay ? startOfDay(parsedDateUntil) : parsedDateUntil,
    includeLastDay ? 1 : 0,
  );
  return differenceInDays(_until, _from);
};

/**
 * returns end of date, null if parsing fails */
export const endOfDay = (date) => {
  const parsedDate = parseDate(date);
  if (parsedDate) return endOfDayFns(parsedDate);
  return null;
};

/**
 * returns end of month, null if parsing fails */
export const endOfMonth = (date) => {
  const parsedDate = parseDate(date);
  if (parsedDate) return endOfMonthFns(parsedDate);
  return null;
};

/**
 * returns start of date, null if parsing fails */
export const startOfDay = (date) => {
  const parsedDate = parseDate(date);
  if (parsedDate) return startOfDayFns(parsedDate);
  return null;
};

/* date comparison functions */

export const isDateBetween = (date, compareFromDate, compareToDate) => {
  const parsedDate = parseDate(date);
  const parsedFromDate = parseDate(compareFromDate);
  const parsedToDate = parseDate(compareToDate);

  if (!parsedDate || !parsedFromDate || !parsedToDate) return false;

  const _date = startOfDay(parsedDate);
  return startOfDay(parsedFromDate) <= _date && _date <= endOfDay(parsedToDate);
};

export const isBetween = (date, compareFromDate, compareToDate) => {
  const parsedDate = parseDate(date);
  const parsedFromDate = parseDate(compareFromDate);
  const parsedToDate = parseDate(compareToDate);

  if (!parsedDate || !parsedFromDate || !parsedToDate) return false;

  return parsedFromDate <= parsedDate && parsedDate <= parsedToDate;
};

export const isPast = (date) => {
  const parsedDate = parseDate(date);

  if (!parsedDate) return false;
  return isPastFns(parsedDate);
};

export const isFuture = (date) => {
  const parsedDate = parseDate(date);

  if (!parsedDate) return false;
  return isFutureFns(parsedDate);
};

export const isAfter = (date, dateToCompare) => {
  const parsedDate = parseDate(date);
  const parsedDateToCompare = parseDate(dateToCompare);

  if (!parsedDate || !parsedDateToCompare) return false;
  return isAfterFns(parsedDate, parsedDateToCompare);
};

export const isBefore = (date, dateToCompare) => {
  const parsedDate = parseDate(date);
  const parsedDateToCompare = parseDate(dateToCompare);

  if (!parsedDate || !parsedDateToCompare) return false;
  return isBeforeFns(parsedDate, parsedDateToCompare);
};

export const isEqual = (date, dateToCompare) => {
  const parsedDate = parseDate(date);
  const parsedDateToCompare = parseDate(dateToCompare);

  if (!parsedDate || !parsedDateToCompare) return false;
  return isEqualFns(parsedDate, parsedDateToCompare);
};

export const isWeekend = (date) => {
  const parsedDate = parseDate(date);
  return isWeekendFns(parsedDate);
};

export const minDate = (datesArray) => min(datesArray);
export const getDateRangeString = (
  dateFrom,
  dateTo,
  format = dateFormats.dateHalfYear,
) => {
  if (isEmptyString(dateTo)) {
    return formatDate(dateTo, format);
  }

  return `${formatDate(dateFrom, dateFormats.dateHalfYear)} - ${formatDate(
    dateTo,
    format,
  )}`;
};
