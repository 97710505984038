import { modifyAndMergeNotificationsArrays } from 'utils/product/loyalty/notificationsUtil';
import useLoyaltyNotifications from './useLoyaltyNotifications';
import { useCmsContent } from './useCmsContent';
import useHeronNotifications from './useHeronNotifications';

const useNotifications = (customerId, loyaltyCustomerId, lang) => {
  const {
    data: heronNotifications,
    loading: heronNotificationsLoading,
    error: heronNotificationsError,
    mutate: mutateHeronNotifications,
  } = useHeronNotifications(customerId);

  const {
    data: loyaltyNotifications,
    loading: loyaltyNotificationLoading,
    error: loyaltyNotificationsError,
    mutate: mutateLoyaltyNotifications,
  } = useLoyaltyNotifications(loyaltyCustomerId, lang);

  const {
    data: cmsData,
    loading: cmsLoading,
    error: cmsError,
  } = useCmsContent(
    'contests,collection_options,massNotifications',
    lang,
    !loyaltyCustomerId,
    '[schema_in]'
  );

  const loading =
    heronNotificationsLoading || loyaltyNotificationLoading || cmsLoading;

  const error =
    heronNotificationsError || loyaltyNotificationsError || cmsError;

  const mergedNotifications = !loading
    ? modifyAndMergeNotificationsArrays(
        heronNotifications,
        loyaltyNotifications,
        cmsData?.data,
        lang
      )
    : [];

  return {
    loading,
    error,
    data: mergedNotifications,
    mutateLoyaltyNotifications,
    mutateHeronNotifications,
  };
};

export default useNotifications;
