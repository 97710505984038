import React from 'react';
import { useTranslation } from 'react-i18next';

import RewardDialog from 'components/Loyalty/RewardDialog';
import { SuccessDialog } from 'components/PromptDialogs/PromptDialog';
import { getRewardPoints } from 'utils/product/loyalty/pointsUtils';
import AlertWidget from 'components/AlertWidget/AlertWidget';

import styles from './RecurringPaymentSuccess.module.scss';

const RecurringPaymentSuccess = ({
  loyaltyResponse,
  isLoyaltyCustomer,
  waveId,
  alertMessages,
  isCardUpdate = false,
  ...rest
}) => {
  const { t } = useTranslation(['bills', 'translation']);

  const rewardPoints = loyaltyResponse?.data
    ? getRewardPoints(loyaltyResponse?.data, waveId)
    : 0;

  return isLoyaltyCustomer && !isCardUpdate ? (
    <RewardDialog
      {...rest}
      points={rewardPoints}
      header={t(`loyalty:collection-success.DEBIT_PAYMENT.header`)}
      message={t(`loyalty:collection-success.DEBIT_PAYMENT.message`)}
      subMessage={t(`loyalty:collection-success.DEBIT_PAYMENT.subMessage`)}
      children={alertMessages?.map(
        (message) =>
          message && (
            <AlertWidget
              containerClass={styles.alertContainer}
              key={message}
              message={message}
              distance=""
            />
          ),
      )}
    />
  ) : (
    <SuccessDialog
      header={
        isCardUpdate
          ? t(`bills:payment-flow.card-update-recurring-success-title`)
          : t(`bills:payment-flow.recurring-success-title`)
      }
      message={
        isCardUpdate
          ? t(`bills:payment-flow.card-update-recurring-success-message`)
          : t(`bills:payment-flow.recurring-success-message`)
      }
      disableBackdropClick={false}
      {...rest}
    />
  );
};

export default RecurringPaymentSuccess;
