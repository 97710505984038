import { useCmsContent } from 'data/useCmsContent';
import { cmsGeneralConfigTransformation } from 'utils/configCmsTransformation';

const useGeneralConfig = (language = 'el') => {
  const { data, loading, error } = useCmsContent(
    'generalConfiguration',
    language,
  );

  const configurationData = cmsGeneralConfigTransformation(data, language);

  return {
    loading,
    data: configurationData,
    error,
  };
};
export default useGeneralConfig;
