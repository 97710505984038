import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuthUser } from 'utils/AuthUser';
import { getAccountType } from 'utils/product/accountUtils';
import { requestTypes } from 'utils/product/enums/requests';
import getDefaultValues from 'utils/product/requests/getDefaultValues';

import PersonalDataRequest from 'components/Requests/PersonalData/PersonalDataRequest';
import BillingQuestionsRequest from 'components/Requests/Billing/BillingQuestionsRequest';
import MovingRequest from 'components/Requests/Moving/MovingRequest';
import UsageChangeRequest from 'components/Requests/UsageChange/UsageChangeRequest';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';

const CreateRequestWrapper = ({ type, meter, keepSameProgram }) => {
  const { t } = useTranslation(['requests', 'bills']);
  const { user: account, loading: isLoading } = useAuthUser();

  const [accountType, setAccountType] = useState(0);
  const [defaultValues, setDefaultValues] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      type === requestTypes.movingRequest &&
      (!meter || keepSameProgram === undefined || keepSameProgram === null)
    ) {
      navigate('/requests/moving-meter', { replace: true });
    }
  });

  useEffect(() => {
    const type = getAccountType(account?.Category);
    setAccountType(type);
    setDefaultValues(
      getDefaultValues(requestTypes.personalDataChange, type, account)
    );
  }, [account]);

  return (
    <WidgetSkeleton
      heightInPixels={600}
      show={isLoading || defaultValues === null}
      title={<WidgetHeader title={t(`requests:types.type-${type}`)} />}
    >
      {type === requestTypes.personalDataChange && (
        <PersonalDataRequest
          account={account}
          accountType={accountType}
          loading={isLoading}
          defaultValues={defaultValues}
        />
      )}
      {type === requestTypes.billingQuestions && (
        <BillingQuestionsRequest
          account={account}
          accountType={accountType}
          loading={isLoading}
          defaultValues={defaultValues}
        />
      )}
      {type === requestTypes.movingRequest && (
        <MovingRequest
          account={account}
          accountType={accountType}
          loading={isLoading}
          meter={meter}
          keepSameProgram={keepSameProgram}
          defaultValues={defaultValues}
        />
      )}
      {type === requestTypes.usageChange && (
        <UsageChangeRequest
          account={account}
          accountType={accountType}
          loading={isLoading}
          defaultValues={defaultValues}
        />
      )}
    </WidgetSkeleton>
  );
};

export default CreateRequestWrapper;
