const validatePassword = (value) => {
  let count = 0;
  if (validateHasAtLeastOneNumber(value)) count++;
  if (validateHasAtLeastOneLowercase(value)) count++;
  if (validateHasAtLeastOneUppercase(value)) count++;
  if (validateHasAtLeastOneSymbol(value)) count++;
  return count >= 3;
};

const validateHasAtLeastOneNumber = (value) => value && /.*\d.*/.test(value);

const validateHasAtLeastOneLowercase = (value) =>
  value && /.*[a-z].*/.test(value);

const validateHasAtLeastOneUppercase = (value) =>
  value && /.*[A-Z].*/.test(value);

const validateHasAtLeastOneSymbol = (value) =>
  value && /.*[$&+,:;=\\\\?@#|/'<>.^*()%!-].*/.test(value);

const validateIsEmail = (value) =>
  value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

const validateTaxId = (taxId) => {
  if (!taxId || !taxId.match(/^\d{9}$/) || taxId === '000000000') {
    return false;
  }

  let m = 1;
  let sum = 0;
  for (let i = 7; i >= 0; i--) {
    m *= 2;
    sum += taxId.charAt(i) * m;
  }

  return (sum % 11) % 10 === parseInt(taxId.charAt(8), 10);
};

const validateGreekMobilePhone = (value) =>
  value && /^(\+30|0030)?69\d{8}$/.test(value);

const validateGreekLandlinePhone = (value) =>
  value && /^(\+30|0030)?(2)\d{9}$/.test(value);

const validateGreekPhone = (value) => validateGreekPhoneNoPrefix(value);

const validateGreekMobilePhoneNoPrefix = (value) =>
  value && /^69\d{8}$/.test(value);

const validateGreekLandlinePhoneNoPrefix = (value) =>
  value && /^2\d{9}$/.test(value);

const validateGreekPhoneNoPrefix = (value) =>
  validateGreekMobilePhoneNoPrefix(value) ||
  validateGreekLandlinePhoneNoPrefix(value);

const validateNumberInRange = (value, minValue, maxValue) =>
  validateNumberMin(value, minValue) && validateNumberMax(value, maxValue);

const validateNumberMin = (value, minValue) => value >= minValue;
const validateNumberMax = (value, maxValue) => value <= maxValue;

const validateAmountTwoDecimalsFormat = (value) =>
  (value && /^\d*((\.{1}|,{1})\d{1,2})?$/.test(value)) === true;

const validateEmail = (value) =>
  (value &&
    /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}$/.test(value)) === true;

export {
  validateTaxId,
  validateGreekMobilePhone,
  validateGreekLandlinePhone,
  validateGreekPhone,
  validateGreekMobilePhoneNoPrefix,
  validateGreekLandlinePhoneNoPrefix,
  validateGreekPhoneNoPrefix,
  validateNumberInRange,
  validateNumberMin,
  validateNumberMax,
  validatePassword,
  validateIsEmail,
  validateAmountTwoDecimalsFormat,
  validateEmail,
};
