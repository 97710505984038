import React from 'react';
import cn from 'classnames';
import { ReactComponent as AppStoreIcon } from 'assets/icons/AppStore.svg';
import { ReactComponent as PlayStoreIcon } from 'assets/icons/PlayStore.svg';
import { trackDownloadAppEvent } from 'utils/product/tracking/events';

import styles from './AppDownloadButtons.module.scss';

const AppStoreButton = ({ width = '102px', height = '30px', source }) => {
  return (
    <a
      href={process.env.REACT_APP_IOS_APP}
      target="_blank"
      rel="noreferrer"
      aria-label="App Store"
      onClick={() => {
        trackDownloadAppEvent(source, 'ios');
      }}
    >
      <AppStoreIcon width={width} height={height} />
    </a>
  );
};

const PlayStoreButton = ({ width = '102px', height = '30px', source }) => {
  return (
    <a
      href={process.env.REACT_APP_ANDROID_APP}
      target="_blank"
      rel="noreferrer"
      aria-label="Play Store"
      onClick={() => {
        trackDownloadAppEvent(source, 'android');
      }}
    >
      <PlayStoreIcon width={width} height={height} />
    </a>
  );
};

const AppDownloadButtons = ({ containerClass = '', width, height, source }) => {
  return (
    <div className={cn(styles.container, containerClass)}>
      <PlayStoreButton width={width} height={height} source={source} />
      <AppStoreButton width={width} height={height} source={source} />
    </div>
  );
};

export default AppDownloadButtons;
