import { isEmptyString } from 'utils/stringUtils';

export const removeNullFields = (obj) => {
  getObjectKeys(obj).forEach((key) => obj[key] == null && delete obj[key]);
};

export const isObjectEmpty = (data) => {
  return getObjectValues(data).find((x) => !isEmptyString(x)) === undefined;
};

export const getObjectValues = (data) => {
  if (data && typeof data === 'object') {
    if (typeof Object.values === 'function') return Object.values(data);

    const keys = getObjectKeys(data);
    return keys.map(function (_key) {
      return data[_key];
    });
  }
  return [];
};

export const getObjectKeys = (data) => {
  return data && typeof data === 'object' ? Object.keys(data) : [];
};

export const getObjectEntries = (data) => {
  if (data && typeof data === 'object') {
    if (typeof Object.entries === 'function') return Object.entries(data);

    const keys = getObjectKeys(data);
    return keys.map(function (_key) {
      return [_key, data[_key]];
    });
  }
  return [];
};
