import Cookies from 'js-cookie';

const STORAGE_TYPE = {
  Cookies: 'cookies',
  LocalStorage: 'local-storage',
  SessionStorage: 'session-storage',
};
Object.freeze(STORAGE_TYPE);

const getItem = ({ key, storage = STORAGE_TYPE.Cookies }) => {
  switch (storage) {
    case STORAGE_TYPE.Cookies:
      return Cookies.get(key);
    case STORAGE_TYPE.LocalStorage:
      try {
        const localItem = localStorage.getItem(key);
        try {
          return JSON.parse(localItem); //TODO: refactor so that parse is not called if not needed
        } catch (e) {}
        return localItem;
      } catch (e) {}
      return null;
    case STORAGE_TYPE.SessionStorage:
      try {
        const sessionItem = sessionStorage.getItem(key);
        try {
          return JSON.parse(sessionItem);
        } catch (e) {}
        return sessionItem;
      } catch (e) {}
      return null;
    default:
      break;
  }
};

const setItem = ({
  key,
  value,
  storage = STORAGE_TYPE.Cookies,
  expiresInDays = null,
}) => {
  const secure = process.env.REACT_APP_ENV !== 'development';
  switch (storage) {
    case STORAGE_TYPE.Cookies:
      if (expiresInDays)
        return Cookies.set(key, value, {
          expires: expiresInDays,
          secure: secure,
          sameSite: 'Lax',
        });
      return Cookies.set(key, value, { secure: secure, sameSite: 'Lax' });
    case STORAGE_TYPE.LocalStorage:
      try {
        if (typeof value === 'object')
          return localStorage.setItem(key, JSON.stringify(value));
        return localStorage.setItem(key, value);
      } catch (e) {}
      return null;
    case STORAGE_TYPE.SessionStorage:
      try {
        if (typeof value === 'object')
          return sessionStorage.setItem(key, JSON.stringify(value));
        return sessionStorage.setItem(key, value);
      } catch (e) {}
      return null;
    default:
      break;
  }
};

const removeItem = ({ key, storage = STORAGE_TYPE.Cookies }) => {
  switch (storage) {
    case STORAGE_TYPE.Cookies:
      return Cookies.remove(key);
    case STORAGE_TYPE.LocalStorage:
      try {
        return localStorage.removeItem(key);
      } catch (e) {
        return null;
      }
    case STORAGE_TYPE.SessionStorage:
      try {
        return sessionStorage.removeItem(key);
      } catch (e) {}
      return null;
    default:
      break;
  }
};

const storage = {
  STORAGE_TYPE,
  getItem,
  setItem,
  removeItem,
};
export default storage;
