import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate } from 'utils/dateUtils';
import { StandardInput } from 'components/Inputs/Inputs';

import styles from './SolarMetersBody.module.scss';

const SolarMetersBody = ({
  friendlyName,
  productionMeasurementCode,
  consumptionMeasurementCode,
  contractSignatureDate,
}) => {
  const { t } = useTranslation(['management']);
  const baseName = 'management:solarMetersBody';

  return (
    <>
      {friendlyName ? (
        <StandardInput
          value={friendlyName}
          label={t(`${baseName}.friendly-name`)}
          readOnly={true}
          disabled
        />
      ) : null}
      <StandardInput
        value={formatDate(contractSignatureDate)}
        readOnly={true}
        label={t(`${baseName}.contract-date`)}
        disabled
      />
      <h2 className={styles.header}>{t(`${baseName}.meters`)}</h2>
      <section>
        <StandardInput
          value={consumptionMeasurementCode}
          readOnly={true}
          label={t(`${baseName}.consumption-code`)}
          disabled
        />

        <StandardInput
          value={productionMeasurementCode}
          readOnly={true}
          label={t(`${baseName}.production-code`)}
          disabled
        />
      </section>
    </>
  );
};

export default SolarMetersBody;
