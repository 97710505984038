import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { trackEcontractEvent } from 'utils/product/tracking/events';
import { trackEcontractFbEvent } from 'utils/product/tracking/fbEvents';
import { PrimaryButton } from 'components/Buttons/Buttons';

import styles from './BottomSection.module.scss';

const BottomSection = () => {
  const { t } = useTranslation(['onboarding']);
  const baseName = 'onboarding:bottomSection';

  return (
    <section className={styles.section}>
      <h2 className={styles.header}>
        {t(`${baseName}.new-customer-question`)}
      </h2>
      <Link
        to="/e-contract/whats-new"
        onClick={() => {
          trackEcontractEvent('click', 'econtract_ginepelatis');
          trackEcontractFbEvent();
        }}
        className={styles.link}
      >
        <PrimaryButton>{t(`${baseName}.come-to-heron`)}</PrimaryButton>
      </Link>
    </section>
  );
};

export default BottomSection;
