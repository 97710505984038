import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  OfferDescription,
  OfferTitle,
  ExternalLink,
} from 'components/Offers/OfferElements';
import { acceptWinningParticipation } from 'api/loyalty';
import {
  mutateLoyaltyPoints,
  useLoyaltyPoints,
} from 'data/useRewardSchemeAccounts';
import { LocalisedErrorDialog } from 'components/PromptDialogs/PromptDialog';
import { useAuthUser } from 'utils/AuthUser';
import { isEmptyString } from 'utils/stringUtils';
import ContestParticipationWithPoints from './ContestParticipationWithPoints';
import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import OfferAcquisitionSuccess from 'components/Offers/OfferAcquisitionSuccess';
import OfferImage from 'components/Offers/OfferImage';
import { buttonActions } from 'utils/product/enums/cmsEnums';
import useDialog from 'hooks/useDialog';
import useError from 'hooks/useError';
import ContestParticipationOptions from './ContestParticipationOptions';
import RewardDialog from 'components/Loyalty/RewardDialog';
import VibesAcquiredDialog from 'components/Loyalty/VibesAcquiredDialog';
import { benefitTypes } from 'utils/product/enums/waveEnums';
import TermsLink from 'components/Terms/TermsLink';
import { trackContestsEvent } from 'utils/product/tracking/events';
import ContestInfoBox from './ContestInfoBox';
import { contestDrawStatuses } from 'utils/product/enums/contests';

import styles from './ContestWidget.module.scss';

const ContestWidget = ({
  contest,
  participations,
  participationsCount,
  mutateParticipations,
}) => {
  const { t } = useTranslation(['translation', 'offers']);
  const navigate = useNavigate();
  const { loyaltyCustomerId, accountId, isLoyaltyCustomer } = useAuthUser();
  const { data } = useLoyaltyPoints(loyaltyCustomerId, !isLoyaltyCustomer);

  const { slug } = useParams();

  const [couponReward, setCouponReward] = useState(null);
  const participationDialog = useDialog();
  const { error, handleError, clearError } = useError();
  const errorDialog = useDialog();
  const couponDialog = useDialog();
  const successDialog = useDialog();
  const vibesDialog = useDialog();

  const drawCompleted = contest?.drawStatus === contestDrawStatuses.COMPLETED;
  const showWinnersExternalLink =
    participationsCount > 0 &&
    drawCompleted &&
    !!contest.winners?.results &&
    !isEmptyString(contest.winners?.results);

  const onParticipationInvoked = () => {
    clearError();

    if (!!data && data?.availableRemainingAmount < contest.redeemPoints) {
      data?.remainingAmount !== data?.availableRemainingAmount &&
      data?.remainingAmount >= contest.redeemPoints
        ? handleError('CLIENT_PENDING_POINTS', 'wave')
        : handleError('CLIENT_INSUFFICIENT_POINTS', 'wave');

      errorDialog.show();
      return;
    }
    trackContestsEvent('contest_participate_selected', '', {
      contest_name: contest?.detailsTitle,
    });
    participationDialog.show();
  };

  const onSuccess = (data) => {
    trackContestsEvent(
      'contest_participate_selected_success_participation',
      '',
      { contest_name: contest?.detailsTitle },
    );
    mutateParticipations();
    successDialog.show();
    mutateLoyaltyPoints(loyaltyCustomerId);
  };
  const onError = (error) => {
    handleError(error?.response?.data?.code, 'contest');
    errorDialog.show();
  };

  const claimReward = () => {
    trackContestsEvent('contest_winner_get_coupon_selected', '', {
      contest_name: contest?.detailsTitle,
    });

    const data = {
      customerId: accountId,
      confirm: true,
    };
    acceptWinningParticipation(contest.contestId, data)
      .then((res) => {
        const coupon =
          res?.data?.prizes?.length > 0
            ? res?.data?.prizes?.find(
                (prize) => prize.type === benefitTypes.COUPON,
              )
            : null;
        setCouponReward(coupon?.coupon);
        couponDialog.show();
        mutateParticipations();
      })
      .catch((error) => {});
  };

  return (
    <>
      <Widget
        title={<WidgetHeader title={t(`offers:details.contest-title`)} />}
        widgetClass={styles.offerContainer}
      >
        <OfferImage
          imageUrl={contest.imageUrl}
          largeImageUrl={contest.largeImageUrl}
          expirationDate={contest.endDate}
          containerClass={styles.image}
          labelTheme={contest.expirationLabelColor}
        />
        <section className={styles.section}>
          <ContestInfoBox
            contest={contest}
            participations={participations}
            participationsCount={participationsCount}
            onParticipationInvoked={onParticipationInvoked}
            onClaimVoucher={claimReward}
          />
          <OfferTitle title={contest.detailsTitle} />
          <OfferDescription description={contest.description} />
          <div className={styles.linksContainer}>
            <TermsLink url={contest.termsUrl} linkClass={styles.termsLink} />
            {showWinnersExternalLink && (
              <div className={styles.winnersLink}>
                <ExternalLink
                  onClick={() => {
                    trackContestsEvent('contest_loyalty_see_winners');
                  }}
                  url={`/contests/results/${slug}`}
                  text={t('offers:contests.winnersLink')}
                />
              </div>
            )}
          </div>

          <div className={styles.ctaContainer}>
            <ContestParticipationOptions
              contest={contest}
              participationsCount={participationsCount}
              mutateParticipations={mutateParticipations}
              onParticipationInvoked={onParticipationInvoked}
            />
          </div>
        </section>
      </Widget>
      {contest.button?.buttonAction === buttonActions.default &&
        contest.redeemPoints > 0 && (
          <ContestParticipationWithPoints
            contest={contest}
            open={participationDialog.isOpen}
            closeDialog={participationDialog.close}
            userPendingLoyaltyPoints={Math.floor(data?.remainingAmount ?? 0)}
            userAvailableLoyaltyPoints={Math.floor(
              data?.availableRemainingAmount ?? 0,
            )}
            participationsCount={participationsCount}
            onSuccess={onSuccess}
            onError={onError}
          />
        )}
      <OfferAcquisitionSuccess
        offer={contest}
        coupon={couponReward}
        open={couponDialog.isOpen}
        closeDialog={couponDialog.close}
        clickHandler={() => {
          navigate('/profile/my-coupons');
        }}
      />
      {errorDialog.isOpen && (
        <LocalisedErrorDialog
          open={errorDialog.isOpen}
          closeDialog={errorDialog.close}
          showCloseButton={false}
          showPrimaryAction={true}
          primaryActionText={t('translation:ok')}
          primaryActionHandler={errorDialog.close}
          {...(error || {})}
        />
      )}
      {successDialog.isOpen && (
        <RewardDialog
          open={successDialog.isOpen}
          closeDialog={() => {
            successDialog.close();
            vibesDialog.show();
          }}
          icon={null}
          message={t('offers:contests.successParticipation')}
          subMessage={contest.successDescription}
        />
      )}
      {vibesDialog.isOpen && (
        <VibesAcquiredDialog
          open={vibesDialog.isOpen}
          closeDialog={() => {
            vibesDialog.close();
          }}
          type="redemption"
        />
      )}
    </>
  );
};

export default ContestWidget;
