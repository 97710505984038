import React from 'react';

import Slider from 'components/Slider/Slider';
import { ReactComponent as RightArrowIcon } from 'assets/icons/ArrowRightIcon.svg';

const LeftArrow = (props) => {
  const { className, currentSlide, onClick } = props;
  return currentSlide > 0 ? (
    <div className={className} onClick={onClick}>
      <RightArrowIcon />
    </div>
  ) : null;
};
const RightArrowL = (props) => {
  const { currentSlide, slideCount } = props;
  return slideCount > 3 && currentSlide < slideCount - 2 ? (
    <Arrow {...props} />
  ) : null;
};

const RightArrowM = (props) => {
  const { currentSlide, slideCount } = props;
  return slideCount >= 2 && currentSlide < slideCount - 2 ? (
    <Arrow {...props} />
  ) : null;
};

const RightArrowS = (props) => {
  const { currentSlide, slideCount } = props;
  return slideCount > 1 && currentSlide < slideCount - 1 ? (
    <Arrow {...props} />
  ) : null;
};

const Arrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <RightArrowIcon />
    </div>
  );
};

const OffersSlider = ({ children, sliderClass = '' }) => {
  const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    width: '100%',
    arrows: true,
    className: `slider variable-width discovery-slider ${sliderClass}`,
    nextArrow: <RightArrowL />,
    prevArrow: <LeftArrow />,
    variableWidth: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1130,
        settings: {
          nextArrow: <RightArrowM />,
        },
      },
      {
        breakpoint: 770,
        settings: {
          nextArrow: <RightArrowS />,
        },
      },
    ],
  };

  return <Slider {...settings}>{children}</Slider>;
};

export default OffersSlider;
