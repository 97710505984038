import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  CustomButton,
  OfferDescription,
  OfferTitle,
} from 'components/Offers/OfferElements';
import OfferImage from 'components/Offers/OfferImage';
import { buttonActions } from 'utils/product/enums/cmsEnums';
import {
  mutateLoyaltyPoints,
  useLoyaltyPoints,
} from 'data/useRewardSchemeAccounts';
import { RedeemPointsLabel } from 'components/Loyalty/LoyaltyLabels';
import { useAuthUser } from 'utils/AuthUser';
import useDialog from 'hooks/useDialog';
import useError from 'hooks/useError';

import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import OfferAcquisitionConfirm from 'components/Offers/OfferAcquisitionConfirm';
import GamesDialog from './GamesDialog';
import GamesRewardDialog from './GamesRewardDialog';
import {
  ErrorDialog,
  LocalisedErrorDialog,
} from 'components/PromptDialogs/PromptDialog';
import { benefitTypes } from 'utils/product/enums/waveEnums';
import useCheckGameSession from 'data/useCheckGameSession';
import {
  gameCategories,
  gameResultStatuses,
} from 'utils/product/enums/gameEnums';
import { openMiniGameSession } from 'api/loyalty';
import {
  trackGameEvent,
  trackGamesEvent,
} from 'utils/product/tracking/loyaltyEvents';
import { GameWarningMessage } from './GameWarningMessage';

import styles from './GameWidget.module.scss';

const GameWidget = ({ game }) => {
  const { t } = useTranslation(['translation', 'offers']);
  const navigate = useNavigate();
  const { loyaltyCustomerId, isLoyaltyCustomer } = useAuthUser();
  const { data } = useLoyaltyPoints(loyaltyCustomerId, !isLoyaltyCustomer);

  const [gameResult, setGameResult] = useState(null);
  const [gameData, setGameData] = useState();
  const { error, handleError, clearError } = useError();
  const errorDialog = useDialog();
  const confirmationDialog = useDialog();
  const rewardDialog = useDialog();
  const gameDialog = useDialog();

  const getMiniGameId = (_game) =>
    _game.benefits?.find((benefit) => benefit.type === benefitTypes.MINI_GAME)
      ?.waveId;

  const {
    data: sessionCheckData,
    error: gameError,
    gameLimitReached,
    hasOpenSession,
    loading: sessionCheckLoading,
    mutate: mutateCheckGameSession,
  } = useCheckGameSession(loyaltyCustomerId, getMiniGameId(game));

  const onPlayInvoked = () => {
    setGameResult(null);
    clearError();
    mutateLoyaltyPoints(loyaltyCustomerId);

    if (!!data && data?.availableRemainingAmount < game.redeemPoints) {
      data?.remainingAmount !== data?.availableRemainingAmount &&
      data?.remainingAmount >= game.redeemPoints
        ? handleError('CLIENT_PENDING_POINTS', 'waveGames')
        : handleError('CLIENT_INSUFFICIENT_POINTS', 'waveGames');

      errorDialog.show();
      return;
    }

    if (!sessionCheckData?.canOpenSession) {
      gameDialog.show();
      return;
    }

    confirmationDialog.show();
  };

  const onRedeemSuccess = (data) => {
    mutateLoyaltyPoints(loyaltyCustomerId);
    openMiniGameSession(getMiniGameId(game), loyaltyCustomerId)
      .then((res) => {
        setGameData(res);
        mutateCheckGameSession();
        gameDialog.show();
      })
      .catch((error) => {});
  };

  const onRedeemError = (error) => {
    handleError(error?.response?.data?.code, 'waveGames');
    errorDialog.show();
  };

  const onGameCompleted = (response) => {
    mutateCheckGameSession();
    gameDialog.close();
    setGameResult(response);
    const showFailureScreen =
      game?.category === gameCategories.skill &&
      response?.result === gameResultStatuses.failure;

    trackGameEvent(
      showFailureScreen
        ? 'loyalty_game_fail_overview'
        : 'loyalty_game_success_overview',
      '',
      {
        game_name: game?.detailsTitle,
      },
    );

    rewardDialog.show();
  };

  return (
    <>
      <Widget
        title={<WidgetHeader title={t(`offers:details.game-title`)} />}
        widgetClass={styles.offerContainer}
      >
        <OfferImage
          imageUrl={game.imageUrl}
          largeImageUrl={game.largeImageUrl}
          expirationDate={game.endDate}
          containerClass={styles.image}
          labelTheme={game.expirationLabelColor}
          gameLimitReached={gameLimitReached}
        />
        <section className={styles.section}>
          <OfferTitle title={game.detailsTitle} />
          <OfferDescription description={game.description} />

          <div className={styles.ctaContainer}>
            {!game.isExpired &&
              !sessionCheckLoading &&
              !gameError &&
              game.button.buttonAction === buttonActions.custom &&
              game.redeemPoints > 0 && (
                <>
                  {!hasOpenSession && (
                    <>
                      <p>{t('offers:acquisition-flow.redeem-one')}</p>
                      <RedeemPointsLabel
                        points={game.redeemPoints}
                        labelClass={styles.points}
                      />
                    </>
                  )}
                  <CustomButton
                    text={game.button.buttonTitle}
                    clickHandler={() => {
                      onPlayInvoked();
                      trackGameEvent(
                        !hasOpenSession
                          ? 'loyalty_game_overview_play_selected'
                          : 'loyalty_game_continue_pending_game_session',
                        '',
                        { game_name: game?.detailsTitle },
                      );
                    }}
                  />
                </>
              )}
            {!game.isExpired && !sessionCheckLoading && gameLimitReached && (
              <GameWarningMessage error={gameError} />
            )}
          </div>
        </section>
      </Widget>
      <OfferAcquisitionConfirm
        offer={game}
        open={confirmationDialog.isOpen}
        closeDialog={confirmationDialog.close}
        onSuccess={onRedeemSuccess}
        onError={onRedeemError}
      />
      <GamesDialog
        game={game}
        sessionData={sessionCheckData?.existingOpenSession || gameData}
        open={gameDialog.isOpen}
        openDialog={gameDialog.show}
        closeDialog={gameDialog.close}
        onGameCompleted={onGameCompleted}
        numberOfCompletedSessions={sessionCheckData?.numberOfCompletedSessions}
      />

      {gameResult && (
        <GamesRewardDialog
          open={rewardDialog.isOpen}
          closeDialog={rewardDialog.close}
          playAgainHandler={() => {
            onPlayInvoked();
            trackGamesEvent('loyalty_game_play_again', '', {
              game_name: game?.detailsTitle,
            });
          }}
          gameResult={gameResult}
          gameInfo={game}
          playAgainRedeemPoints={game.redeemPoints}
        />
      )}
      {errorDialog.isOpen && (
        <LocalisedErrorDialog
          open={errorDialog.isOpen}
          closeDialog={errorDialog.close}
          showCloseButton={false}
          showPrimaryAction={true}
          primaryActionText={t('translation:ok')}
          primaryActionHandler={errorDialog.close}
          {...(error || {})}
        />
      )}
      {gameError && !gameLimitReached && (
        <ErrorDialog isOpen={true} closeDialog={() => navigate('/offers')} />
      )}
    </>
  );
};

export default GameWidget;
