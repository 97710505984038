import React from 'react';
import { useTranslation } from 'react-i18next';

import commodities from 'utils/product/enums/commodities';
import cloneDeep from 'lodash/cloneDeep';
import MeterNamingElement from 'components/MeterNaming/MeterNamingElement';
import Widget from 'components/Widget/Widget';
import MeterLabel from 'components/Labels/MeterLabel';
import MyMetersWrapperElement from './MyMetersWrapperElement';

import styles from './MyMetersContent.module.scss';
import useLocalizedFriendlyName from 'hooks/useLocalizedFriendlyName';

const MyMetersContent = ({ meters, mutate, handleError, clearError }) => {
  const { t } = useTranslation(['management']);
  const { getDisplayName } = useLocalizedFriendlyName();

  const _metersCopy = meters
    ? cloneDeep(meters).sort((x) =>
        x.Commodity === commodities.energy ? -1 : 1
      )
    : [];

  return (
    <section className={styles.containerSection}>
      {_metersCopy.map((meter) => (
        <Widget
          key={meter.Id}
          widgetClass={styles['meter-widget']}
          distance="xs"
          size="m"
        >
          <MeterLabel
            size="m"
            prefix={
              meter.FriendlyName
                ? `${getDisplayName(meter.FriendlyName)} - `
                : ''
            }
            type={meter.Commodity}
            meterNo={meter.MeterNo}
          />

          <MyMetersWrapperElement
            AddressInfo={meter.AddressInfo}
            DayMeasurementCode={meter.DayMeasurementCode}
            NightMeasurementCode={meter.NightMeasurementCode}
          >
            <MeterNamingElement
              key={meter.MeterNo}
              meterNo={meter.MeterNo}
              meterId={meter.Id}
              friendlyName={meter.FriendlyName}
              commodity={meter.Commodity}
              supplyNumberVisible={false}
              containerClass={styles['meterNaming-container']}
              mutate={mutate}
              handleError={handleError}
              clearError={clearError}
              extraMessageForError={t(
                `management:myMeters.extraMessageForError`
              )}
            />
          </MyMetersWrapperElement>
        </Widget>
      ))}
    </section>
  );
};

export default MyMetersContent;
