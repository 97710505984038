import React from 'react';

import Widget from 'components/Widget/Widget';
import EmptyState from 'components/History/EmptyState';
import styles from './EmptyStateWidget.module.scss';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';

const EmptyStateWidget = ({
  icon,
  text,
  subText,
  isFullWidth = true,
  containerClass,
  isGray,
  header,
}) => {
  return (
    <Widget
      size="m"
      title={header && <WidgetHeader title={header} />}
      containerClass={
        isFullWidth ? styles['widget-fullWidth'] : styles['widget-default']
      }
    >
      <EmptyState
        containerClass={`${containerClass ?? styles.emptyState} ${
          isGray ? styles['gray-fill'] : ''
        }`}
        Icon={icon}
        text={text}
        subText={subText}
      />
    </Widget>
  );
};

export default EmptyStateWidget;
