import React from 'react';
import { useTranslation } from 'react-i18next';

import useContracts from 'data/useContracts';
import Helmet from 'utils/Helmet';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import ContractsList from 'components/Management/ContractsList';
import Widget from 'components/Widget/Widget';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import { ReactComponent as ContractsIcon } from 'assets/icons/EmptyContractsIcon.svg';
import EmptyStateWidget from 'components/Widget/EmptyStateWidget';

const Contracts = () => {
  const { t } = useTranslation(['management']);
  const baseName = 'management:managementRightSidebar';

  const { data: contracts, loading: contractsLoading } = useContracts();

  const showEmptyState = (contracts) => contracts?.length === 0;

  return (
    <>
      <Helmet
        head={{
          title: t(`${baseName}.contracts`),
          metaTags: [{ name: 'title', content: t(`${baseName}.contracts`) }],
        }}
      />
      <WidgetSkeleton
        heightInPixels={352}
        show={contractsLoading}
        title={<WidgetHeader title={t(`${baseName}.contracts`)} />}
      >
        <Widget
          background="transparent"
          title={<WidgetHeader title={t(`${baseName}.contracts`)} />}
        >
          {showEmptyState(contracts) && (
            <EmptyStateWidget
              icon={<ContractsIcon width="72px" height="72px" />}
              text={t('management:contracts.empty-state')}
            />
          )}
          <ContractsList contracts={contracts} />
        </Widget>
      </WidgetSkeleton>
    </>
  );
};

export default Contracts;
