import React from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { isEmptyString } from 'utils/stringUtils';

import { StandardInput } from './Inputs';

const FriendlyNameInput = ({
  register,
  errors,
  label,
  name = 'friendlyName',
  required = true,
  ...rest
}) => {
  const { t } = useTranslation(['inputs']);
  const baseName = 'inputs:errors';
  const { ref, ...registerRest } =
    register(name, {
      validate: {
        notEmpty: (v) =>
          required && isEmptyString(v) ? t(`${baseName}.required`) : true,
      },
    }) || {};
  return (
    <>
      <StandardInput
        inputRef={ref}
        label={label}
        type="text"
        errorMessage={get(errors, `${name}.message`, null)}
        error={get(errors, `${name}`, null) !== null}
        {...rest}
        {...registerRest}
      />
    </>
  );
};

export { FriendlyNameInput };
