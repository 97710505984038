import React, { Children, isValidElement, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import useUniqueOnboardingFlow from 'hooks/useUniqueOnboardingFlow';
import programTypes from 'utils/product/enums/programTypes';
import accountCategories from 'utils/product/enums/accountCategories';

import styles from './OnboardingFormProvider.module.scss';

const OnboardingFormProvider = ({
  isShop = false,
  user = null,
  program = null,
  children,
}) => {
  const formMethods = useForm({
    /* this is important, without it, in case i click next & form step has an error, 
    if i go back and try to click next onSubmit will not fire because form is considered invalid */
    shouldUnregister: true,
  });
  const { step, goForward, goBack, nextRouteData, setNextRouteData } =
    useUniqueOnboardingFlow(isShop, user, program);
  const [defaultValues, setDefaultValues] = useState(nextRouteData);

  useEffect(() => {
    setDefaultValues(nextRouteData);
  }, [nextRouteData]);

  const onChecked = (name, value) => {
    const updatedData =
      name === 'programType' &&
      value === programTypes.business &&
      nextRouteData.customerCategory === `${accountCategories.private}`
        ? { [name]: value, customerCategory: `${accountCategories.freelancer}` }
        : { [name]: value };
    onUpdate(updatedData);
  };

  const getUpdatedData = (data) => {
    const _copy = cloneDeep(nextRouteData);
    return merge(_copy, data);
  };
  const onUpdate = (updatedData) => {
    const newData = getUpdatedData(updatedData);
    setNextRouteData(newData);
  };

  return (
    <FormProvider {...formMethods}>
      <form className={styles.form}>
        {Children.map(children, (child, index) => {
          if (isValidElement(child))
            return React.cloneElement(child, {
              ...(child?.props ? child.props : {}),
              key: child.type.name || child.type.displayName || index,
              step,
              goForward,
              goBack,
              nextRouteData,
              setNextRouteData,
              defaultValues,
              onChecked,
              onUpdate,
            });
          return false;
        })}
      </form>
    </FormProvider>
  );
};

export default OnboardingFormProvider;
