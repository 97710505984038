import React from 'react';
import { useTranslation } from 'react-i18next';

import TwoColumnsLayout from 'layouts/TwoColumnsLayout';
import RightSidebar from './RightSidebar';
import MainArea from './MainArea';
import useAccountMeters from 'data/useAccountMeters';
import { prepareMetersList } from 'utils/product/consumptionUtils';

const Consumptions = () => {
  const { t } = useTranslation(['homepage']);
  const { meters, loading, mutate } = useAccountMeters({
    statusesElectricity: [],
    statusesGas: [],
  });
  const metersWithConsumptions = meters ? prepareMetersList(meters) : [];
  return (
    <TwoColumnsLayout
      head={{
        title: t('homepage:my-consumptions.title'),
        metaTags: [
          { name: 'title', content: t('homepage:my-consumptions.title') },
        ],
      }}
      main={
        <MainArea
          meters={metersWithConsumptions}
          loading={loading}
          mutate={mutate}
        />
      }
      right={<RightSidebar meters={meters} metersLoading={loading} />}
    />
  );
};

export default Consumptions;
