import React from 'react';
import { useTranslation } from 'react-i18next';

import { getLVConsumptionInfo } from 'utils/product/consumptionUtils';
import { DefaultLayout } from 'components/Consumptions/HomeStates/ConsumptionLayouts';
import {
  ConsumptionIndications,
  ConsumptionMessage,
  ConsumptionAnalysisButton,
} from 'components/Consumptions/Home/ConsumptionInfoElements';

const StateClearing = ({ meter }) => {
  const { t } = useTranslation(['consumptions']);

  const consumptionInfo = getLVConsumptionInfo(meter);

  return (
    <DefaultLayout>
      {{
        message: (
          <ConsumptionMessage
            text={t(`consumptions:real-measurement.message`)}
            size="s"
          />
        ),
        info: <ConsumptionIndications {...consumptionInfo} />,
        secondaryButton: <ConsumptionAnalysisButton meterNo={meter.MeterNo} />,
      }}
    </DefaultLayout>
  );
};

export default StateClearing;
