import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { contactHeron } from 'api/contact';
import useGeneralConfig from 'data/useGeneralConfig';
import { ReactComponent as Logo } from 'assets/icons/HeronIcon.svg';
import { PromptDialog } from 'components/PromptDialogs/PromptDialog';

import styles from './ConsentDialog.module.scss';

const ConsentDialog = ({
  open,
  closeDialog,
  firstName,
  lastName,
  phone,
  email,
  type,
  existing_customer,
  electricity,
  gas,
  onActionClicked = () => {},
}) => {
  const { t } = useTranslation(['newClient']);
  const {
    data: { leadSources: { onboardingEContract } = {} },
  } = useGeneralConfig() || {};

  const [loading, setLoading] = useState(false);
  const sendLeadRequest = () => {
    setLoading(true);

    contactHeron(
      {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        email: email,
        type: type,
        existing_customer: existing_customer,
        electricity: electricity,
        gas: gas,
      },
      onboardingEContract,
    )
      .then((result) => {
        onActionClicked(true);
      })
      .catch((error) => {})
      .then((res) => {
        setLoading(false);
      });
  };

  return (
    <PromptDialog
      open={open}
      closeDialog={closeDialog}
      primaryActionText={t(`newClient:contactConsent.agree`)}
      primaryActionHandler={() => sendLeadRequest()}
      primaryActionLoading={loading}
      showSecondaryAction={true}
      secondaryActionText={t(`newClient:contactConsent.disagree`)}
      secondaryActionHandler={() => onActionClicked(false)}
      icon={<Logo alt="heron" />}
      header={t(`newClient:contactConsent.title`)}
      message={t(`newClient:contactConsent.message`)}
      dialogClass={styles.dialog}
      iconClass={styles.icon}
    >
      <p className={styles.info}>{t(`newClient:contactConsent.info`)}</p>
    </PromptDialog>
  );
};

export default ConsentDialog;
