import { useForYouSchema } from 'data/useForYouSchema';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Navigate, useParams } from 'react-router-dom';
import { getRedirectionUrl } from 'utils/product/loyalty/notificationsUtil';
import { getDiscoveryItemSlugByWaveId } from 'utils/product/offerUtils';

const NotificationsRouter = () => {
  const { i18n } = useTranslation();
  const { waveId, flow } = useParams();
  const { data, loading } = useForYouSchema(i18n.language);

  if (loading) return <h1>Loading</h1>;
  const slug = getDiscoveryItemSlugByWaveId(flow, waveId, data);

  const redirectionUrl = getRedirectionUrl(flow, slug);

  return <Navigate to={redirectionUrl} replace={true} />;
};

export default NotificationsRouter;
