import cookiesStorage from 'utils/storage/cookiesStorage';
import { getObjectKeys } from 'utils/objectUtils';

export const shouldTrack = () => {
  if (process.env.REACT_APP_ENV === 'development') return false;

  const consent = cookiesStorage.getCookiesConsent();
  return consent && consent.performance === true;
};

export const initializeTracking = () => {
  const isTrackingEnabled = shouldTrack();

  isTrackingEnabled ? enableTracking() : disableTracking();
};

export const enableTracking = () => {
  if (typeof window.gtag === 'function') {
    window.gtag('consent', 'update', {
      analytics_storage: 'granted',
    });
  }
};

export const disableTracking = () => {
  if (typeof window.gtag === 'function') {
    window.gtag('consent', 'update', {
      analytics_storage: 'denied',
    });
  }
};

const getDimensionKey = (name) => {
  switch (name) {
    case 'energy_type':
      return 'dimension2';
    case 'customer_type':
      return 'dimension3';
    case 'shop_or_onboarding_type':
      return 'dimension4';
    case 'shop_program_name':
      return 'dimension1';
    default:
      return name;
  }
};

export const getCustomDimensions = (customDimensions) => {
  const keys = getObjectKeys(customDimensions);
  if (keys.length === 0) return {};

  return keys.reduce(function (accumulator, current, index) {
    accumulator[getDimensionKey(current)] = customDimensions[current];
    return accumulator;
  }, {});
};

export const trackEvent = (
  category,
  action,
  label = '',
  customDimensions = {},
  force = false
) => {
  if (shouldTrack() || force === true) {
    const _dimensions = getCustomDimensions(customDimensions);
    typeof window.gtag === 'function' &&
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
        ..._dimensions,
      });
  }
};

export const trackPageview = (path) => {
  if (shouldTrack()) {
    //fix to prevent PPI within query params from being logged
    const origin = window.location?.origin;

    typeof window.gtag === 'function' &&
      window.gtag('event', 'page_view', {
        page_location: `${origin}${path}`,
        page_path: path,
        send_to: process.env.REACT_APP_ANALYTICS_ID,
      });
  }
};
