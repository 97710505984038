//this will not work on safari if called from insede an async method
export const openPdfOnNewTab = (response, pdfWindow) => {
  try {
    //Create a Blob from the PDF Stream
    const file = new Blob([response.data], { type: 'application/pdf' });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);

    const linkElement = document.createElement('a');
    linkElement.style = 'display: none';
    linkElement.target = '_blank';
    linkElement.href = fileURL;
    linkElement.click();
  } catch (error) {
    console.error(`Error on openPdfOnNewTab: ${JSON.stringify(error)}`);
    return false;
  }
};

//use this to generate pdf inside async methods
export const generatePdfUrl = (response, pdfWindow) => {
  try {
    //Create a Blob from the PDF Stream
    const file = new Blob([response.data], { type: 'application/pdf' });
    //Build a URL from the file
    return URL.createObjectURL(file);
  } catch (error) {
    return null;
  }
};

//use this to open pdf in new tab
export const openPdfUrlOnNewTab = (fileURL) => {
  try {
    const pdfWindow = window.open();

    pdfWindow.location.href = fileURL;
  } catch (error) {
    return null;
  }
};

export const downloadPDF = (data, name) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name ?? 'file.pdf');
  document.body.appendChild(link);
  link.click();
};
