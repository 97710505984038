import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import LanguageSelect from 'components/Selects/Wrappers/LanguageSelect';
import StandardSelectOption from 'components/Selects/StandardSelectOption';
import GreatBritainFlag from 'assets/icons/GreatBritainFlag.png';
import GreekFlag from 'assets/icons/GreekFlag.png';
import languageStorage from 'utils/storage/languageStorage';
import { trackHomeEvent } from 'utils/product/tracking/events';
import { updateChatLocale } from 'utils/product/chatUtils';

import styles from './LanguageSelector.module.scss';

export default function LanguageSelector({
  type = 'default',
  isLoggedIn = false,
}) {
  const { t, i18n } = useTranslation(['translation']);
  const [lang, setLang] = React.useState(languageStorage.getLanguage());

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
    languageStorage.setLanguage(code);
    document.documentElement.lang = code;
    if (isLoggedIn) {
      updateChatLocale(code);
    }
  };

  const handleChange = (event) => {
    setLang(event.target.value);
    changeLanguage(event.target.value);
  };

  return (
    <div className={cn(styles.container, styles[`container-${type}`])}>
      <LanguageSelect
        lang={lang}
        handleChange={handleChange}
        rootClass={
          type === 'transparent'
            ? styles.transparentSelect
            : styles.defaultSelect
        }
      >
        <StandardSelectOption
          menuItemClass={styles.menuItem}
          value="el"
          onClick={() => trackHomeEvent('home_change_language', 'el')}
        >
          <div className={styles.optionContainer}>
            <div className={styles.text}>{t('translation:el')}</div>
            <img src={GreekFlag} alt="greek flag" width={26} height={25} />
          </div>
        </StandardSelectOption>
        <StandardSelectOption
          menuItemClass={styles.menuItem}
          value="en"
          onClick={() => trackHomeEvent('home_change_language', 'en')}
        >
          <div className={styles.optionContainer}>
            <div className={styles.text}>{t('translation:en')}</div>
            <img
              src={GreatBritainFlag}
              alt="great britain flag"
              width={26}
              height={25}
            />
          </div>
        </StandardSelectOption>
      </LanguageSelect>
    </div>
  );
}
