import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowBackIcon } from 'assets/icons/ArrowLeftIcon.svg';

import styles from './WidgetHeader.module.scss';

const WidgetHeader = ({ title, showBackArrow = false, backUrl }) => {
  const navigate = useNavigate();

  const handleArrowClick = () => {
    navigate(backUrl ? backUrl : -1);
  };

  return (
    <div className={styles.container}>
      {showBackArrow && (
        <ArrowBackIcon className={styles.arrow} onClick={handleArrowClick} />
      )}
      <h3 className={styles.title}>{title}</h3>
    </div>
  );
};

export default WidgetHeader;
