import React from 'react';
import Helmet from 'utils/Helmet';

import styles from './TwoColumnsLayout.module.scss';

const TwoColumnsLayout = ({ main, right, head, containerClass = '' }) => {
  return (
    <>
      <Helmet head={head} />
      <section className={`${styles.container} ${containerClass}`}>
        <main className={styles.main}>{main}</main>
        <aside className={styles.right}>{right}</aside>
      </section>
    </>
  );
};

export default TwoColumnsLayout;
