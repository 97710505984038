import { getQueryParams } from 'utils/urlUtils';
import { notificationsTypes } from '../enums/cmsEnums';
import { isEmptyString } from 'utils/stringUtils';
import { loyaltyPaths } from 'utils/product/loyalty/config';
import { conventFromCents } from 'utils/moneyUtils';

export const modifyAndMergeNotificationsArrays = (
  heronNotifications,
  loyaltyNotifications,
  cmsData,
  language
) => {
  const heronModifiedNotifications =
    heronNotifications?.map((notification) => {
      return {
        ...notification,
        Title:
          language === 'el' ? notification?.Title_gr : notification?.Title_en,
        Message:
          language === 'el'
            ? notification?.LongDescription_gr
            : notification?.LongDescription_en,
        DetailsMessage:
          language === 'el'
            ? notification?.LongDescription_gr
            : notification?.LongDescription_en,
        Description:
          language === 'el'
            ? notification?.Description_gr
            : notification?.Description_en,
        IsLoyalty: false,
      };
    }) || [];

  const loyaltyModifiedNotifications =
    loyaltyNotifications?.map((notification) => {
      const cmsItem = cmsData?.find(
        (item) => item?.id === notification?.cmsId
      )?.content;

      return {
        LoyaltyId: notification?._id,
        CreatedOn: notification?.createdAt,
        Title: notification?.title,
        Message: notification?.message,
        DetailsMessage:
          cmsItem?.notificationInfoMessage ?? notification?.message,
        DefaultAction: { ...notification?.defaultAction },
        Image: cmsItem?.notificationImage?.image,
        Points:
          notification?.defaultAction?.type === notificationsTypes.CMS_RETRIEVAL
            ? conventFromCents(cmsItem?.benefits?.[0]?.value)
            : null,
        RedirectionUrl:
          notification?.defaultAction?.type === notificationsTypes.DEEP_LINKING
            ? getNotificationUrl(notification)
            : null,
        IsLoyalty: true,
        IsRead: notification?.hasBeenViewed,
      };
    }) || [];

  return heronModifiedNotifications?.concat(loyaltyModifiedNotifications);
};

export const getNotificationUrl = (notification) => {
  const deepLinkUrl = notification?.defaultAction?.value;

  const { flow, waveId } = getQueryParams(deepLinkUrl);

  if (flow) {
    if (waveId) return `notifications/${flow}/${waveId}`;
    return `notifications/${flow}`;
  }
  return null;
};

export const getRedirectionUrl = (flow, slug) => {
  const mapping = {
    offers: '/offers',
    contests: '/contests',
    game: '/games',
    loyaltyMain: `/loyalty/${loyaltyPaths.landing}`,
    coupons: '/profile/my-coupons',
  };

  const path = mapping[flow];
  if (isEmptyString(path)) {
    return `/`;
  }

  if (!isEmptyString(slug)) {
    return `${path}/${slug}`;
  }

  return `${path}`;
};
