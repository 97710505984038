import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getMeterTerminationPdf, getMeterRepresantationPdf } from 'api/meters';
import useDialog from 'hooks/useDialog';
import { openPdfOnNewTab } from 'utils/pdfUtils';
import Widget from 'components/Widget/Widget';
import RightArrowLinkElement from 'components/RequestsHome/RightArrowLinkElement';
import MetersListDialog from 'components/RequestsHome/MetersListDialog';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import { ErrorDialog } from 'components/PromptDialogs/PromptDialog';
import UnavailableRequestDialog from './UnavailableRequestDialog';
import {
  trackCertificateEvent,
  trackRequestsEvent,
} from 'utils/product/tracking/events';
import { useAuthUser } from 'utils/AuthUser';

const requests = {
  meterRepresantation: getMeterRepresantationPdf,
  meterTermination: getMeterTerminationPdf,
};

const CertificatesWidget = ({
  meters,
  loading,
  elegibleMeters,
  terminatedMeters,
  ...widgetProps
}) => {
  const { t } = useTranslation(['requests']);
  const baseName = 'requests:certificates';
  const { accountId } = useAuthUser();
  const metersDialog = useDialog();
  const errorDialog = useDialog();
  const unavailableDialog = useDialog();
  const [requestToMake, setRequestToMake] = useState();

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [currentElegibleMeter, setCurrentElegibleMeter] = useState(
    elegibleMeters?.length === 1 ? elegibleMeters[0].Id : ''
  );
  const [currentTerminatedMeter, setCurrentTerminatedMeter] = useState(
    terminatedMeters?.length === 1 ? terminatedMeters[0].Id : ''
  );

  const invokeCertificateDownload = (requestKey, requestName, meters) => {
    trackRequestsEvent('certificate_selection', requestName);
    if (meters?.length === 0) {
      unavailableDialog.show();
      return;
    }
    setRequestToMake({ key: requestKey, name: requestName });
    metersDialog.show();
  };

  const makeRequest = () => {
    if (requestToMake && (currentElegibleMeter || currentTerminatedMeter)) {
      setIsButtonLoading(true);

      requests[requestToMake.key](
        requestToMake.key === 'meterRepresantation'
          ? currentElegibleMeter
          : currentTerminatedMeter,
        accountId
      )
        .then((res) => {
          openPdfOnNewTab(res);
          trackCertificateEvent(
            'certificate_activation_success',
            requestToMake.name
          );
        })
        .catch(() => {
          errorDialog.show();
          trackCertificateEvent(
            'certificate_activation_fail',
            requestToMake.name
          );
        })
        .then(() => {
          metersDialog.close();
          setIsButtonLoading(false);
        });
    }
  };

  return (
    <>
      <ErrorDialog
        open={errorDialog.isOpen}
        closeDialog={errorDialog.close}
        primaryActionHandler={errorDialog.close}
      />
      <WidgetSkeleton
        distance="m"
        heightInPixels={161}
        show={loading}
        {...widgetProps}
      >
        <Widget size="m" {...widgetProps}>
          {elegibleMeters.length > 0 && (
            <RightArrowLinkElement
              onClick={() => {
                invokeCertificateDownload(
                  'meterRepresantation',
                  t(`${baseName}.representation-certificate`),
                  elegibleMeters
                );
              }}
              message={t(`${baseName}.representation-certificate`)}
            />
          )}
          {terminatedMeters.length > 0 && (
            <RightArrowLinkElement
              onClick={() => {
                invokeCertificateDownload(
                  'meterTermination',
                  t(`${baseName}.representation-certificate-end`),
                  terminatedMeters
                );
              }}
              message={t(`${baseName}.representation-certificate-end`)}
            />
          )}
        </Widget>
        <MetersListDialog
          headerText={t(`${baseName}.choose-meter`)}
          open={metersDialog.isOpen}
          closeDialog={metersDialog.close}
          clickHandler={makeRequest}
          setSelectedMeter={
            requestToMake?.key === 'meterRepresantation'
              ? setCurrentElegibleMeter
              : setCurrentTerminatedMeter
          }
          meters={
            requestToMake?.key === 'meterRepresantation'
              ? elegibleMeters
              : terminatedMeters
          }
          buttonLoading={isButtonLoading}
        />
      </WidgetSkeleton>
      <UnavailableRequestDialog
        open={unavailableDialog.isOpen}
        closeDialog={unavailableDialog.close}
      />
    </>
  );
};

export default CertificatesWidget;
