import React from 'react';
import CmsHtmlField from 'components/CmsHtmlField/CmsHtmlField';

import styles from './ProgramInfo.module.scss';

const ProgramInfo = ({ basicInfo }) => {
  return (
    <section className={styles.container}>
      <h2 className={styles.title}>{basicInfo.title}</h2>
      <CmsHtmlField
        className={styles.description}
        value={basicInfo.description}
      />
    </section>
  );
};
export default ProgramInfo;
