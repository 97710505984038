import React from 'react';

import styles from './CustomLabeledTextfield.module.scss';

const CustomLabeledTextfield = ({
  day,
  label,
  content,
  amount,
  containerClass = '',
}) => {
  return (
    <div className={`${styles.container} ${containerClass}`}>
      {(day || label) && (
        <p className={styles.label}>
          {day} {` `} {label}
        </p>
      )}
      <div className={styles.textfield}>
        <p className={styles.content}>{content}</p>
        <p className={styles.amount}>{amount}</p>
      </div>
    </div>
  );
};

export default CustomLabeledTextfield;
