import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';

import MeterIconText from 'components/Labels/MeterIconText';
import Helmet from 'utils/Helmet';
import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import {
  PromptDialog,
  SuccessDialog,
} from 'components/PromptDialogs/PromptDialog';
import { LocalisedErrorBox } from 'components/MessageBox/MessageBox';
import useDialog from 'hooks/useDialog';
import useError from 'hooks/useError';
import PaymentWidget from 'components/Payment/PaymentWidget';
import paymentTypes from 'utils/product/enums/paymentTypes';
import AlertWidget from 'components/AlertWidget/AlertWidget';
import useStandingOrderMeterInfo from 'data/useStandingOrderMeterInfo';
import StandingOrderEditCardContent from 'components/StandingOrderEditCardContent/StandingOrderEditCardContent';
import StandingOrderBankActivatedContent from 'components/StandingOrderBankActivatedContent/StandingOrderBankActivatedContent';
import { meterDebitCardDeletion } from 'api/meters';
import { useAuthUser } from 'utils/AuthUser';
import useStandingOrderAccountInfo from 'data/useStandingOrderAccountInfo';
import { directDebitCardStatus } from 'utils/product/enums/directDebitCardStatus';

import styles from './ManagementStandingOrderEditCard.module.scss';

const ManagementStandingOrderEditCard = () => {
  const { t } = useTranslation(['management', 'translation']);
  const navigate = useNavigate();
  const { accountId } = useAuthUser();

  const baseName = 'management:standing-order';

  const confirmationDialog = useDialog();
  const successDialog = useDialog();
  const paymentDialog = useDialog();
  const { error, handleError, clearError } = useError();
  const [cardDeleteLoading, setCardDeleteLoading] = useState(false);

  const { state } = useLocation();
  const {
    meter,
    bankActivated,
    previousPath = '/management/direct-debit-payment',
  } = state || {};

  const {
    data: cardDetails,
    loading: cardDetailsLoading,
    mutate: mutateMeterCard,
  } = useStandingOrderMeterInfo(meter?.Id, meter?.Commodity, bankActivated);

  const cardExpired =
    !cardDetails?.Credit &&
    !cardDetails?.Bank &&
    cardDetails?.StatusCode === directDebitCardStatus.expired;

  const { cardDeletionMutate, cardActivationMutate } =
    useStandingOrderAccountInfo(accountId);

  const isLoading = cardDetailsLoading || cardDeleteLoading;

  const onDisableStandingOrder = () => {
    setCardDeleteLoading(true);
    meterDebitCardDeletion(
      cardDetails?.MeterId,
      cardDetails?.CreditCardTokenizationId,
    )
      .then((res) => {
        cardDeletionMutate(meter?.Id);
        successDialog.show();
      })
      .catch((err) => {
        handleError(err);
        setCardDeleteLoading(false);
      });
  };

  const handleCloseSuccessDisableDialog = () => {
    successDialog.close();
    navigate(previousPath, { replace: true });
    setCardDeleteLoading(false);
    mutateMeterCard();
  };

  const handleCloseStandingOrderConfirmationDialog = () => {
    confirmationDialog.close();
    clearError();
  };

  const mutateCardDetails = () => {
    cardActivationMutate(meter?.Id);
    setCardDeleteLoading(true);
    mutateMeterCard().finally(() => {
      setCardDeleteLoading(false);
    });
  };

  if (
    !meter ||
    !meter?.Id ||
    !meter?.Commodity ||
    (!bankActivated && !cardDetailsLoading && !cardDetails?.CardNumber)
  ) {
    return <Navigate to={previousPath} replace />;
  }

  return (
    <>
      <Helmet
        head={{
          title: t(`${baseName}.edit.header`),
          metaTags: [{ name: 'title', content: t(`${baseName}.edit-header`) }],
        }}
      />
      <WidgetHeader
        title={t(`${baseName}.edit.header`)}
        backUrl={previousPath}
        showBackArrow
      />
      <Widget distance="" size="m" widgetClass={styles.container}>
        <MeterIconText
          meterNo={meter?.MeterNo}
          commodity={meter?.Commodity}
          friendlyName={meter?.FriendlyName}
        />
        {cardExpired && (
          <AlertWidget
            containerClass={styles.alertContainer}
            message={t(`${baseName}.edit.inactive-card-message`)}
            distance=""
          />
        )}
        {bankActivated ? (
          <StandingOrderBankActivatedContent />
        ) : (
          <StandingOrderEditCardContent
            cardNumber={cardDetails?.CardNumber}
            expiryDate={cardDetails?.ExpiryDate}
            cardType={cardDetails?.CardType}
            loading={isLoading}
            cardExpired={cardExpired}
            handleChangeCard={paymentDialog.show}
            handleDisableCard={confirmationDialog.show}
          />
        )}
      </Widget>
      <PromptDialog
        open={confirmationDialog.isOpen}
        closeDialog={confirmationDialog.close}
        header={t(`${baseName}.edit.disabled-header`)}
        message={t(`${baseName}.edit.disabled-message`)}
        primaryActionText={t('translation:confirm')}
        primaryActionHandler={onDisableStandingOrder}
        primaryActionLoading={cardDeleteLoading}
        showSecondaryAction
        secondaryActionHandler={handleCloseStandingOrderConfirmationDialog}
        showCloseButton={false}
      >
        <LocalisedErrorBox
          open={error !== null}
          closeBox={clearError}
          {...(error || {})}
          distanceBottom=""
        />
      </PromptDialog>
      <SuccessDialog
        open={successDialog.isOpen}
        closeDialog={handleCloseSuccessDisableDialog}
        header={t(`${baseName}.edit.success-deactivation-header`)}
        message={t(`${baseName}.edit.success-deactivation-message`)}
      />
      <PaymentWidget
        open={paymentDialog.isOpen}
        closeDialog={paymentDialog.close}
        openDialog={paymentDialog.show}
        paymentType={paymentTypes.recurring}
        meterId={meter?.Id}
        meter={meter}
        commodity={meter?.Commodity}
        isRecurringCardUpdate
        onPaymentSuccess={mutateCardDetails}
      />
    </>
  );
};

export default ManagementStandingOrderEditCard;
