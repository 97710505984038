import React from 'react';

import MeterLabelWrapper from 'components/Labels/MeterLabelWrapper';
import Widget from 'components/Widget/Widget';
import SolarMetersBody from 'components/Management/SolarMetersBody';

import styles from './SolarMetersList.module.scss';

const SolarMetersList = ({ solarMeters }) => {
  return solarMeters && solarMeters.length > 0
    ? solarMeters.map((solarMeter) => {
        const {
          MeterId,
          Commodity,
          FriendlyName,
          ProductionMeasurementCode,
          ConsumptionMeasurementCode,
          ContractSignatureDate,
          MeterNo,
        } = solarMeter;

        return (
          <Widget
            key={MeterId}
            widgetClass={styles['solarMeter-widget']}
            distance="xs"
          >
            <MeterLabelWrapper
              type="eco"
              meterNo={MeterNo}
              meter={solarMeter}
            />
            <SolarMetersBody
              meterId={MeterId}
              commodity={Commodity}
              friendlyName={FriendlyName}
              productionMeasurementCode={ProductionMeasurementCode}
              consumptionMeasurementCode={ConsumptionMeasurementCode}
              contractSignatureDate={ContractSignatureDate}
            />
          </Widget>
        );
      })
    : null;
};

export default SolarMetersList;
