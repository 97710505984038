import { useCmsContent } from 'data/useCmsContent';
import { cmsWebDowntimeTransformation } from 'utils/configCmsTransformation';

const useWebDowntime = (language = 'el') => {
  const { data, loading, error } = useCmsContent(
    'generalConfiguration',
    language,
    ...Array(3),
    { dedupingInterval: 60000 },
  );

  const configurationData = cmsWebDowntimeTransformation(data);

  return {
    loading: loading,
    data: configurationData,
    error: error,
  };
};
export default useWebDowntime;
