import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Widget from 'components/Widget/Widget';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import { Link } from 'react-router-dom';
import { PrimaryButton } from 'components/Buttons/Buttons';
import { gamesStatuses } from 'utils/product/enums/gameEnums';
import { RedeemPointsLabel } from 'components/Loyalty/LoyaltyLabels';
import useCheckGameSession from 'data/useCheckGameSession';
import CmsHtmlField from 'components/CmsHtmlField/CmsHtmlField';

import styles from './GamesRewardWidget.module.scss';

const CouponReward = ({
  benefit,
  gameResult,
  playAgainHandler,
  redeemPoints,
  playAgainPrompt,
}) => {
  const { t } = useTranslation(['loyalty']);

  return (
    <>
      <Widget background="selected" size={'s'}>
        <h3 className={styles.title}>
          {t(`loyalty:games.reward.widgetWinTitle`)}
        </h3>
        <section
          style={{ backgroundImage: `url(${benefit?.content?.image?.url})` }}
          className={styles.rewardImage}
        />
        <CmsHtmlField
          className={styles.couponDescription}
          value={benefit?.content?.couponDescription}
        />
        <Link to={'/profile/my-coupons'}>
          <PrimaryButton className={styles.button}>
            {t(`loyalty:games.reward.see-coupon`)}
          </PrimaryButton>
        </Link>
      </Widget>
      <PlayAgain
        gameResult={gameResult}
        playAgainHandler={playAgainHandler}
        redeemPoints={redeemPoints}
        playAgainPrompt={playAgainPrompt}
      />
    </>
  );
};

const ContestReward = ({
  benefit,
  gameResult,
  playAgainHandler,
  redeemPoints,
  playAgainPrompt,
}) => {
  const { t } = useTranslation(['loyalty']);
  return (
    <>
      <Widget background="selected" size={'s'} distance="m">
        <h3 className={styles.title}>
          {t(`loyalty:games.reward.widgetWinTitle`)}
        </h3>
        <p className={styles.contestDescription}>
          <Trans
            i18nKey={`loyalty:games.reward.contest-participation`}
            values={{
              contest: benefit?.content?.title,
            }}
            components={{
              bold: <b />,
            }}
          />
        </p>
        <section
          style={{
            backgroundImage: `url(${benefit?.content?.largeImage?.url})`,
          }}
          className={styles.rewardImage}
        />
      </Widget>
      <PlayAgain
        gameResult={gameResult}
        playAgainHandler={playAgainHandler}
        redeemPoints={redeemPoints}
        playAgainPrompt={playAgainPrompt}
      />
    </>
  );
};

const PlayAgain = ({
  gameResult,
  playAgainHandler,
  redeemPoints,
  playAgainPrompt,
}) => {
  const { t } = useTranslation(['loyalty']);
  const {
    data: { errors = [] },
    loading,
  } = useCheckGameSession(gameResult?.customerId, gameResult?.miniGameId) || {};

  const isGameLimitReached =
    errors?.[0]?.code === gamesStatuses?.gameLimitReached;

  return (
    <WidgetSkeleton heightInPixels={80} distance="s" show={loading}>
      {!isGameLimitReached && (
        <div className={styles['play-again-container']}>
          <p>{playAgainPrompt}</p>
          <div className={styles.redeemPoints}>
            <p>{t(`loyalty:games.reward.pointsRedeem`)}</p>
            <RedeemPointsLabel
              points={redeemPoints}
              labelClass={styles.points}
            />
          </div>
          <PrimaryButton onClick={playAgainHandler}>
            {t(`loyalty:games.reward.playAgainButton`)}
          </PrimaryButton>
        </div>
      )}
    </WidgetSkeleton>
  );
};

export { CouponReward, ContestReward };
