import { useCmsContent } from './useCmsContent';

const useLoyaltyFAQs = (language) => {
  const { data, loading, error } = useCmsContent('loyalty_faqs', language);

  const loyaltyFAQs = data?.data?.[0]?.content;

  return {
    loading: loading,
    data: loyaltyFAQs,
    error: error,
  };
};

export { useLoyaltyFAQs };
