import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Tab, Tabs } from 'components/Tabs/Tabs';
import { useAuthUser } from 'utils/AuthUser';
import { trackShopEvent } from 'utils/product/tracking/events';
import shopEnergyTypes from 'utils/product/enums/shopEnergyTypes';
import programTypes from 'utils/product/enums/programTypes';
import TabPanel from 'components/Tabs/TabPanel';
import ShopCategory from 'components/Shop/Overview/ShopCategory';
import ShopLoyaltyPointsWidget from 'components/Loyalty/ShopLoyaltyPointsWidget';
import { useShop } from 'data/useShop';

import styles from './ShopWidget.module.scss';

const ShopWidget = ({ programType }) => {
  const { t, i18n } = useTranslation(['shop']);
  const language = i18n.language;

  const { isLoyaltyCustomer } = useAuthUser();
  const { data: programs, loading: isLoading } = useShop(language);

  const [value, setValue] = React.useState(programType === 'home' ? 0 : 1);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    trackShopEvent(`shop_selector_${newValue === 0 ? 'home' : 'business'}`);
    navigate(`/shop/${newValue === 0 ? 'home' : 'business'}`, {
      replace: true,
    });
    setValue(newValue);
  };
  const tabPrefix = 'tabs-';
  const tabClasses = { root: styles.tab };

  return (
    <section className={styles.container}>
      <article className={styles.tabsContainer}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab
            label={t(`shop:shop.home-title`)}
            id={`${tabPrefix}-0`}
            aria-controls={`${tabPrefix}-tabpanel-0`}
            classes={tabClasses}
          />
          <Tab
            label={t(`shop:shop.business-title`)}
            id={`${tabPrefix}-1`}
            aria-controls={`${tabPrefix}-tabpanel-1`}
            classes={tabClasses}
          />
        </Tabs>
      </article>
      {isLoyaltyCustomer && <ShopLoyaltyPointsWidget />}
      <article>
        <TabPanel value={value} index={0} idPrefix={tabPrefix}>
          <ShopCategory
            title={t(`shop:shop.electricity`)}
            programs={programs.filter(
              (item) =>
                item.programType === programTypes.home &&
                (item.energyType === shopEnergyTypes.electricity ||
                  item.energyType === shopEnergyTypes.combinedElectricity),
            )}
            loading={isLoading}
            loyaltyReward
          />
          <ShopCategory
            title={t(`shop:shop.gas`)}
            programs={programs.filter(
              (item) =>
                item.programType === programTypes.home &&
                (item.energyType === shopEnergyTypes.combined ||
                  item.energyType === shopEnergyTypes.gas),
            )}
            loading={isLoading}
            loyaltyReward
          />
          <ShopCategory
            title={t(`shop:shop.eco`)}
            programs={programs.filter(
              (item) =>
                item.programType === programTypes.home &&
                item.energyType === shopEnergyTypes.eco,
            )}
            loading={isLoading}
          />
          <ShopCategory
            title={t(`shop:shop.service`)}
            programs={programs.filter(
              (item) =>
                item.programType === programTypes.home &&
                item.energyType === shopEnergyTypes.service,
            )}
            loading={isLoading}
          />
        </TabPanel>
        <TabPanel value={value} index={1} idPrefix={tabPrefix}>
          <ShopCategory
            title={t(`shop:shop.electricity`)}
            programs={programs.filter(
              (item) =>
                item.programType === programTypes.business &&
                (item.energyType === shopEnergyTypes.electricity ||
                  item.energyType === shopEnergyTypes.combinedElectricity),
            )}
            loading={isLoading}
            loyaltyReward
          />
          <ShopCategory
            title={t(`shop:shop.gas`)}
            programs={programs.filter(
              (item) =>
                item.programType === programTypes.business &&
                (item.energyType === shopEnergyTypes.combined ||
                  item.energyType === shopEnergyTypes.gas),
            )}
            loading={isLoading}
            loyaltyReward
          />
          <ShopCategory
            title={t(`shop:shop.service`)}
            programs={programs.filter(
              (item) =>
                item.programType === programTypes.business &&
                item.energyType === shopEnergyTypes.eco,
            )}
            loading={isLoading}
          />
          <ShopCategory
            title={t(`shop:shop.service`)}
            programs={programs.filter(
              (item) =>
                item.programType === programTypes.business &&
                item.energyType === shopEnergyTypes.service,
            )}
            loading={isLoading}
          />
        </TabPanel>
      </article>
    </section>
  );
};

export default ShopWidget;
