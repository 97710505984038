import React from 'react';
import styles from './WidgetLine.module.scss';

const WidgetLine = ({ distance = 's', lineClass = '' }) => (
  <hr
    className={`${styles.line} ${
      distance ? styles[`line-${distance}`] : ''
    } ${lineClass}`}
  />
);

export default WidgetLine;
