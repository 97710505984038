import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthUser } from 'utils/AuthUser';
import useData from 'data/useData';
import Helmet from 'utils/Helmet';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import SolarMetersList from 'components/Management/SolarMetersList';
import Widget from 'components/Widget/Widget';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';

const SolarMeters = () => {
  const { t } = useTranslation(['management']);
  const { accountId } = useAuthUser();
  const baseName = 'management:solarMeters';

  const { data: solarMeters, loading: solarMetersLoading } = useData(
    `/account/solarMeters?accountId=${accountId}`
  );

  const solarMetersToDisplay =
    solarMeters && solarMeters.SolarMetersInfo
      ? solarMeters.SolarMetersInfo.filter(
          (x) => x.ConsumptionMeasurementCode && x.ProductionMeasurementCode
        )
      : [];

  return (
    <>
      <Helmet
        head={{
          title: t(`${baseName}.my-solar-meters`),
          metaTags: [
            { name: 'title', content: t(`${baseName}.my-solar-meters`) },
          ],
        }}
      />
      <WidgetSkeleton
        show={solarMetersLoading}
        heightInPixels={350}
        title={<WidgetHeader title={t(`${baseName}.my-solar-meters`)} />}
      >
        <Widget
          background="transparent"
          title={<WidgetHeader title={t(`${baseName}.my-solar-meters`)} />}
        >
          <SolarMetersList solarMeters={solarMetersToDisplay} />
        </Widget>
      </WidgetSkeleton>
    </>
  );
};

export default SolarMeters;
