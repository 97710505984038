import React from 'react';
import { useTranslation } from 'react-i18next';

import RewardDialog from 'components/Loyalty/RewardDialog';
import useLoyaltyHistoryByCollectionType from 'data/useLoyaltyHistoryByCollectionType';

const CollectionOptionRewardDialog = ({ open, closeDialog, onClose, type }) => {
  const { t } = useTranslation(['loyalty', 'translation']);

  const { data: rewardsLoyaltyHistory, loading } =
    useLoyaltyHistoryByCollectionType(type);

  const points = rewardsLoyaltyHistory?.[0]?.amount || true;

  return (
    <RewardDialog
      open={open}
      onClose={onClose}
      closeDialog={() => {
        closeDialog();
        if (typeof onClose === 'function') onClose();
      }}
      points={points}
      icon={null}
      header={t(`loyalty:collection-success.${type}.header`)}
      message={t(`loyalty:collection-success.${type}.message`)}
      rewardMessage={t(`loyalty:register.success.alreadyWinTitle`)}
      loading={loading}
      animateLoyaltyPoints={false}
    />
  );
};

export default CollectionOptionRewardDialog;
