import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Widget from 'components/Widget/Widget';
import { paymentCardBannerTypes } from 'utils/product/enums/paymentCardEnums';
import { ReactComponent as Bin } from 'assets/icons/BinIcon.svg';
import { IconButton } from 'components/Buttons/Buttons';
import PaymentCardDetailViewContent from './PaymentCardDetailViewContent';
import PaymentCardViewMoreContent from './PaymentCardViewMoreContent';
import PaymentCardSelectContent from './PaymentCardSelectContent';
import CreditCardSkeleton from 'components/Skeletons/CreditCardSkeleton';

import styles from './PaymentCard.module.scss';

const PaymentCard = ({
  showFriendlyName = true,
  friendlyName,
  cardNumber,
  expirationDate,
  contentType = paymentCardBannerTypes.DETAIL_VIEW,
  creditCardType,
  isExpired = false,
  registerRadioButton,
  onViewMoreClick = () => {},
  onDeleteClick = () => {},
  containerClass = '',
  radioValue,
  radioName,
  isLoading,
}) => {
  const { t } = useTranslation('translation');
  const [contentFadeInAfterLoading, setContentFadeInAfterLoading] =
    useState(isLoading);

  const isDetailView = contentType === paymentCardBannerTypes.DETAIL_VIEW;
  const isViewMore = contentType === paymentCardBannerTypes.VIEW_MORE;
  const isRadioSelect = contentType === paymentCardBannerTypes.SELECT;

  useEffect(() => {
    isLoading && setContentFadeInAfterLoading(true);
  }, [isLoading]);

  return (
    <article className={styles.container}>
      <CreditCardSkeleton
        size="s"
        distance=""
        background={'transparent'}
        containerClass={styles.banner}
        widthInPixels={270}
        heightInPixels={128}
        show={isLoading}
      >
        <Widget
          size="s"
          background={'transparent'}
          containerClass={`${isViewMore ? styles.clickable : ''} ${
            styles[`banner${isExpired ? '-expired' : ''}`]
          } ${containerClass}`}
          widgetClass={`${styles.content} ${
            contentFadeInAfterLoading ? styles.contentFadeIn : ''
          }`}
          onClick={onViewMoreClick}
        >
          {isDetailView && (
            <PaymentCardDetailViewContent
              showFriendlyName={showFriendlyName}
              friendlyName={friendlyName}
              number={cardNumber}
              date={expirationDate}
              creditCardType={creditCardType}
            />
          )}
          {isViewMore && (
            <PaymentCardViewMoreContent
              friendlyName={friendlyName}
              number={cardNumber}
              creditCardType={creditCardType}
              isExpired={isExpired}
            />
          )}
          {isRadioSelect && (
            <PaymentCardSelectContent
              friendlyName={friendlyName}
              creditCardType={creditCardType}
              number={cardNumber}
              radioButtonRegister={registerRadioButton}
              radioButtonValue={radioValue}
              radioButtonName={radioName}
            />
          )}
        </Widget>
        {isExpired && (
          <p
            className={`${styles.expirationText} ${
              contentFadeInAfterLoading ? styles.contentFadeIn : ''
            }`}
          >
            {t('payment-card.expired')}
          </p>
        )}
        {isExpired && isViewMore && (
          <IconButton
            className={styles.binIcon}
            aria-label="delete card"
            onClick={onDeleteClick}
          >
            <Bin />
          </IconButton>
        )}
      </CreditCardSkeleton>
    </article>
  );
};

export default PaymentCard;
