import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EmailInput } from 'components/Inputs/EmailInput';
import { TextInput } from 'components/Inputs/TextInput';
import { TelephoneInput } from 'components/Inputs/TelephoneInput';

import styles from './PrimaryContact.module.scss';

const PrimaryContact = ({ defaultValues = {} }) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();
  const { t } = useTranslation(['newClient']);

  return (
    <article className={styles.container}>
      <h3 className={styles.header}>{t(`newClient:primaryContact.header`)}</h3>

      <TextInput
        register={register}
        errors={errors}
        control={control}
        name="primaryContact.FirstName"
        label={t(`newClient:sharedDetails.firstName`)}
        defaultValue={
          defaultValues?.primaryContact?.FirstName ??
          defaultValues?.legalRepresentative?.FirstName
        }
        required={true}
      />
      <TextInput
        register={register}
        errors={errors}
        control={control}
        name="primaryContact.LastName"
        label={t(`newClient:sharedDetails.lastName`)}
        defaultValue={
          defaultValues?.primaryContact?.LastName ??
          defaultValues?.legalRepresentative?.LastName
        }
        required={true}
      />
      <TelephoneInput
        register={register}
        errors={errors}
        name="primaryContact.MobilePhone"
        label={t(`newClient:sharedDetails.phone`)}
        defaultValue={
          defaultValues?.primaryContact?.MobilePhone ??
          defaultValues?.legalRepresentative?.MobilePhone
        }
        required={true}
      />
      <EmailInput
        register={register}
        errors={errors}
        name="primaryContact.Email"
        label={t(`newClient:sharedDetails.email`)}
        defaultValue={defaultValues?.primaryContact?.Email}
        required={true}
      />
    </article>
  );
};

export default PrimaryContact;
