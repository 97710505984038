import useData from 'data/useData';
import { useAuthUser } from 'utils/AuthUser';

export default function useContacts(skip = false) {
  const { accountId } = useAuthUser();
  const { data, error, loading, ...rest } = useData(
    skip ? null : `/account/contacts?accountId=${accountId}`
  );

  const primaryContact =
    !loading && data?.ContactsInfo?.length > 0
      ? data.ContactsInfo.find((contact) => contact.IsPrimaryContact === true)
      : null;

  return {
    data,
    primaryContact,
    loading,
    error,
    ...rest,
  };
}
