import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import useError from 'hooks/useError';
import { setMeterEbilling } from 'api/meters';
import { PromptDialog } from 'components/PromptDialogs/PromptDialog';
import { LocalisedErrorBox } from 'components/MessageBox/MessageBox';
import { trackEbillEvent } from 'utils/product/tracking/events';
import { getTrackingEnergyType } from 'utils/product/tracking/trackingLabels';
import commodities from 'utils/product/enums/commodities';
import useDialog from 'hooks/useDialog';
import useLocalizedFriendlyName from 'hooks/useLocalizedFriendlyName';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import EbillDeactivationModal from 'components/EbillModal/EbillDeactivationModal';
import { LinkButton } from 'components/Buttons/Buttons';
import MeterIconText from 'components/Labels/MeterIconText';

import styles from './EbillGasDialog.module.scss';

const EbillGasDialog = ({
  open,
  closeDialog,
  onSuccessActivation,
  onSuccessDeactivation,
  meter,
}) => {
  const { t } = useTranslation(['translation', 'management']);
  const { error, handleError, clearError } = useError();
  const [loading, setLoading] = useState(false);
  const { getDisplayName } = useLocalizedFriendlyName();
  const ebillDeactivationDialog = useDialog();

  const isManageMode = meter?.Ebill;
  const showActivationDialog = open && meter && !isManageMode;
  const showEditDialog = open && meter && isManageMode;

  const handleEbillActivation = () => {
    setLoading(true);
    trackEbillEvent('bill_ebill', getTrackingEnergyType(commodities.gas));

    setMeterEbilling(meter.Id, true, commodities.gas)
      .then((res) => {
        trackEbillEvent(
          'bill_ebill_success',
          getTrackingEnergyType(commodities.gas),
        );
        closeDialog();
        onSuccessActivation();
      })
      .catch((error) => {
        handleError(error, 'ebill');
        trackEbillEvent(
          'bill_ebill_fail',
          getTrackingEnergyType(commodities.gas),
        );
      })
      .then(() => {
        setLoading(false);
      });
  };

  const handleEBillDeactivation = () => {
    closeDialog();
    ebillDeactivationDialog.show();
  };

  const handleOnSuccessEbillDeactivation = () => {
    ebillDeactivationDialog.close();
    onSuccessDeactivation();
  };

  return (
    <>
      <DialogOverlay
        open={showEditDialog}
        closeDialog={closeDialog}
        dialogHeader={t('management:ebill.edit-header')}
        contentClass={styles.dialogContent}
        alignActions="right"
        dialogActions={
          meter && (
            <LinkButton
              extraClasses={styles.eBillDeactivationButton}
              onClick={handleEBillDeactivation}
            >
              {t('management:ebill.deactivate-cta')}
            </LinkButton>
          )
        }
      >
        <div className={styles['meterLabel-container']}>
          <MeterIconText
            meterNo={meter?.MeterNo}
            commodity={meter?.Commodity}
            friendlyName={meter?.FriendlyName}
          />
        </div>
        <p className={styles.message}>
          {t('management:ebill.edit-message-gas')}
        </p>
      </DialogOverlay>

      <EbillDeactivationModal
        open={ebillDeactivationDialog.isOpen}
        closeDialog={ebillDeactivationDialog.close}
        onSuccess={handleOnSuccessEbillDeactivation}
        meterId={meter?.Id}
        commodity={meter?.Commodity}
      />

      <PromptDialog
        open={showActivationDialog}
        closeDialog={closeDialog}
        header={t('management:ebill.activation-header')}
        message={
          <Trans
            i18nKey={'management:ebill.activation-message-gas'}
            values={{
              name: getDisplayName(meter?.FriendlyName, meter?.MeterNo),
            }}
            components={{
              bold: <b></b>,
            }}
          />
        }
        primaryActionText={t('translation:confirm')}
        primaryActionHandler={handleEbillActivation}
        primaryActionLoading={loading}
        showSecondaryAction={true}
        secondaryActionHandler={closeDialog}
        showCloseButton={false}
      >
        <LocalisedErrorBox
          open={error !== null}
          closeBox={clearError}
          {...(error || {})}
          distanceBottom=""
        />
      </PromptDialog>
    </>
  );
};

export default EbillGasDialog;
