import React from 'react';
import CollectionOptions from 'components/LoyaltyCollectionOptions/CollectionOptions';
import { useNavigate } from 'react-router-dom';

import HistoryWidget from 'components/Loyalty/HistoryWidget';
import VibesCard from 'components/LoyaltyVibes/VibesCard';
import TwoColumnsLayout from 'layouts/TwoColumnsLayout';
import MyEnergyPoints from 'components/Loyalty/MyEnergyPoints';
import LoyaltyVibesLogo from 'assets/icons/LoyaltyVibesLogo.png';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { IconButton } from '@material-ui/core';
import { loyaltyPaths } from 'utils/product/loyalty/config';

import styles from './Main.module.scss';

const Main = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.header}>
        <div className={styles['logoAndInfo-container']}>
          <img src={LoyaltyVibesLogo} width={128} alt="vibes" />
          <IconButton
            className={styles.infoButton}
            aria-label="info"
            onClick={() => {
              navigate(`/loyalty/${loyaltyPaths.landing}/${loyaltyPaths.faqs}`);
            }}
          >
            <HelpOutlineIcon />
          </IconButton>
        </div>
        <MyEnergyPoints type="banner" distance="xs" page="loyalty" />
      </div>
      <TwoColumnsLayout
        containerClass={styles['twoColumns-container']}
        main={<CollectionOptions />}
        right={
          <>
            <VibesCard />
            <HistoryWidget />
          </>
        }
      />
    </>
  );
};

export default Main;
