export const initializeChatScript = () => {
  if (document) {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.text = getChatScript();

    document.body.appendChild(script);
  }
};

export const getChatScript = () => {
  return `
   
    (function() {
      let baseUrl = "${process.env.REACT_APP_CHAT_URL}/bm/";
      let botChannel = "web";
      let embedScriptUrl = baseUrl + "embed/embed.js";
      
      window.initializeChat = function (m, t, n, w, e, b, token, locale) {
          (m["VWBotObject"] = e),
            (m[e] = {}),
            (m[e].host = baseUrl),
            (m[e].ch = b),
            (m[e].dn = token),
            (m[e].hl = locale),
            (m[e].trigger_icon = "boticon.22.png"),
            (m[e].texts = {}),
            (bot = t.createElement(n)),
            (tg = t.getElementsByTagName(n)[0]),
            (bot.async = 1),
            (bot.src = w),
            tg.parentNode.insertBefore(bot, tg);
        };
  
        window.removeChat = function (m, t, n, w, e, b, token, locale) {
          (m["VWBotObject"] = e),
            (m[e] = {}),
            (m[e].host = baseUrl),
            (m[e].ch = b),
            (m[e].dn = token),
            (m[e].hl = locale),
            (m[e].action = "remove"),
            (m[e].trigger_icon = "boticon.22.png"),
            (m[e].texts = {}),
            (bot = t.createElement(n)),
            (tg = t.getElementsByTagName(n)[0]),
            (bot.async = 1),
            (bot.src = w),
            tg.parentNode.insertBefore(bot, tg);
        };
  
        window.updateChatAccessToken = function(token) {
          if (typeof BotEmbedder !== "undefined") {
            BotEmbedder.updateAccessToken(token, baseUrl);
          }
        };
  
        window.updateChatLocale = function(locale) {
          if (typeof BotEmbedder !== "undefined") {
            BotEmbedder.updateLocale(locale, baseUrl);
          }
        };
    }());
  
    `;
};

export const initializeChat = (token, language) => {
  if (window.initializeChat && typeof window.initializeChat === 'function') {
    window.initializeChat(
      window,
      document,
      'script',
      `${process.env.REACT_APP_CHAT_URL}/bm/embed/embed.js`,
      'vwbot',
      'web',
      token,
      getChatLocale(language)
    );
  }
};

export const removeChat = (token, language) => {
  if (window.removeChat && typeof window.removeChat === 'function') {
    window.removeChat(
      window,
      document,
      'script',
      `${process.env.REACT_APP_CHAT_URL}/bm/embed/embed.js`,
      'vwbot',
      'web',
      token,
      getChatLocale(language)
    );
  }
};

export const updateChatLocale = (language) => {
  try {
    if (
      window.updateChatLocale &&
      typeof window.updateChatLocale === 'function'
    ) {
      window.updateChatLocale(getChatLocale(language));
    }
  } catch (e) {}
};

export const updateChatToken = (token) => {
  if (
    window.updateChatAccessToken &&
    typeof window.updateChatAccessToken === 'function'
  ) {
    window.updateChatAccessToken(token);
  }
};

const getChatLocale = (language) => {
  return language === 'en' ? 'en-US' : 'el-GR';
};
