import React, { useState } from 'react';

import Loader from 'components/Skeletons/Loader';
import {
  trackShopProgramEvent,
  trackShopServiceEvent,
} from 'utils/product/tracking/events';
import shopEnergyTypes from 'utils/product/enums/shopEnergyTypes';
import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import ProgramImage from './ProgramImage';
import ProgramSeparator from './ProgramSeparator';
import ProgramInfo from './ProgramInfo';
import ProgramCost from './ProgramCost';
import ProgramExtras from './ProgramExtras';
import ProgramActions from './ProgramActions';
import ProgramPriviledges from './ProgramPriviledges';
import ProgramCalculator from './ProgramCalculator';
import PlanSelector from './PlanSelector';

import styles from './ProgramWidget.module.scss';
import ProgramLabel from './ProgramLabel';

const ProgramWidget = ({
  program,
  loading,
  widgetClass = '',
  defaultValues,
  onUpdate,
  isShop = true,
}) => {
  const [generousGuarantee, setGenerousGuarantee] = useState(
    defaultValues?.generousGuarantee === 'true',
  );

  const clickHandler = () => {
    switch (program?.energyType) {
      case shopEnergyTypes.service:
        trackShopServiceEvent('shop_service_learn_more', program.title);
        break;
      default:
        trackShopProgramEvent('shop_program_learn_more', program.title);
        break;
    }
  };
  return (
    <>
      {isShop && (
        <Loader
          show={loading}
          variant="text"
          height={40}
          width={250}
          className={styles.textLoading}
        />
      )}
      <Loader
        show={loading}
        height={800}
        width={730}
        className={styles.cardLoading}
      >
        {program && (
          <Widget
            size=""
            widgetClass={widgetClass}
            title={<WidgetHeader title={program.title} />}
          >
            <ProgramImage
              imageUrl={program.imageUrl}
              packageId={program.packageId}
              isEna={program.isEna}
            />
            {program.label && <ProgramLabel label={program.label} />}
            {program.sections && (
              <section className={styles.innerContainer}>
                <ProgramInfo basicInfo={program.sections.basic} />
                {program.sections.cost && program.sections.cost.charges && (
                  <>
                    <ProgramSeparator type={program.energyType} />
                    <ProgramCost cost={program.sections.cost} />
                  </>
                )}
                {program.sections.additionalPriviledges && (
                  <>
                    <ProgramSeparator type={program.energyType} />
                    <ProgramPriviledges
                      additionalPriviledges={
                        program.sections.additionalPriviledges
                      }
                    />
                  </>
                )}
                {program.sections.moreInfo && (
                  <ProgramExtras
                    moreInfo={program.sections.moreInfo}
                    clickHandler={clickHandler}
                  />
                )}
                {program.sections.guaranteeProgram && (
                  <>
                    <ProgramSeparator type={program.energyType} />
                    <PlanSelector
                      planSelection={program.sections.guaranteeProgram}
                      defaultValues={defaultValues}
                      onUpdate={(value) => {
                        onUpdate(value);
                        setGenerousGuarantee(
                          value?.generousGuarantee === 'true',
                        );
                      }}
                    />
                  </>
                )}

                {program.sections.servicesCost && (
                  <>
                    <ProgramSeparator type={program.energyType} />
                    <ProgramCost cost={program.sections.servicesCost} />
                  </>
                )}
                {program.calculator && (
                  <>
                    <ProgramSeparator type={program.energyType} />
                    <ProgramCalculator calculator={program.calculator} />
                  </>
                )}
                {program.sections.actions && (
                  <ProgramActions
                    program={program}
                    isEna={program.isEna}
                    actions={program.sections.actions}
                    title={program.title}
                    energyType={program.energyType}
                    programType={program.programType}
                    leadSrc={program.leadSrc}
                    packageId={program.packageId}
                    isShared={program.isShared}
                    generousGuarantee={generousGuarantee}
                  />
                )}
              </section>
            )}
          </Widget>
        )}
      </Loader>
    </>
  );
};
export default ProgramWidget;
