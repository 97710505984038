import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import useDialog from 'hooks/useDialog';
import useError from 'hooks/useError';
import { setMeter } from 'api/meters';
import { formatDate } from 'utils/dateUtils';
import { removeNullFields } from 'utils/objectUtils';
import { stripLeadingZeros } from 'utils/stringUtils';
import { trackConsumptionCountEvent } from 'utils/product/tracking/events';
import { formatOwnMeasurements } from 'utils/product/ownMeasurementUtils';
import { hasNightConsumption } from 'utils/product/consumptionUtils';
import { dateFormats } from 'utils/product/config';
import { ReactComponent as DayConsumptionIcon } from 'assets/icons/DayConsumptionIcon.svg';
import { ReactComponent as NightConsumptionIcon } from 'assets/icons/NightConsumptionIcon.svg';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import { LocalisedErrorBox } from 'components/MessageBox/MessageBox';
import { MeterInput } from 'components/Inputs/MeterInput';
import {
  SuccessDialog,
  ErrorDialog,
} from 'components/PromptDialogs/PromptDialog';
import RewardDialog from 'components/Loyalty/RewardDialog';
import { useCollectionOptions } from 'data/useCollectionOptions';
import { collectionOptionTypes } from 'utils/product/enums/cmsEnums';
import { useAuthUser } from 'utils/AuthUser';
import { receivePoints } from 'api/loyalty';
import { getWaveId, getRewardPoints } from 'utils/product/loyalty/pointsUtils';
import { mutateLoyaltyPoints } from 'data/useRewardSchemeAccounts';
import { mutateLoyaltyHistory } from 'data/useLoyaltyHistory';
import { logWaveServiceFailure } from 'utils/product/logs';
import { PromptDialog } from 'components/PromptDialogs/PromptDialog';
import { trackMMSEvent } from 'utils/product/tracking/loyaltyEvents';

import styles from './MeasurementRegistration.module.scss';

const InputDescription = ({ date, consumption }) => {
  const { t } = useTranslation(['measurementRegistration']);
  const baseName = 'measurementRegistration:modal';

  return date && consumption ? (
    <p className={styles.paragraph}>
      {t(`${baseName}.input-description`, { date, consumption })}
    </p>
  ) : null;
};

const MeasurementRegistration = ({
  open = true,
  closeDialog,
  meter,
  refreshData,
  isTheFirstTimeOfRegistration = true,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const { t } = useTranslation(['measurementRegistration']);
  const baseName = 'measurementRegistration:modal';
  const { error, handleError, clearError } = useError();
  const [loading, setLoading] = useState(false);
  const [confirmationDescription, setConfirmationDescription] = useState();
  const [rewardPoints, setRewardPoints] = useState();
  const successDialog = useDialog();
  const loyaltySuccessDialog = useDialog();
  const [meterHasNightConsumption] = useState(() => hasNightConsumption(meter));

  const { isLoyaltyCustomer, loyaltyCustomerId } = useAuthUser();
  const { collectionOptionsByType } = useCollectionOptions();
  const waveId = getWaveId(
    collectionOptionsByType,
    collectionOptionTypes.REGISTER_CONSUMPTION
  );

  const resetMeterConfirmationDialog = useDialog();

  const maxDigits = meter?.PredecimalPlaces;

  const receiveLoyaltyPoints = (meterId) => {
    const postData = {
      customer: {
        customerId: loyaltyCustomerId,
      },
      type: 'CUSTOM',
      ruleId: waveId,
      items: [{ id: meterId }],
    };

    return receivePoints(postData)
      .then((loyaltyResponse) => {
        setRewardPoints(getRewardPoints(loyaltyResponse?.data, waveId));
        resetMeterConfirmationDialog.close();
        closeDialog();
        loyaltySuccessDialog.show();
        trackMMSEvent('consumption_success_with_loyalty_points');
      })
      .catch((error) => {
        resetMeterConfirmationDialog.close();
        closeDialog();
        successDialog.show();
      });
  };

  const { Id: MeterId, MeterNo, latestInvoicedReading } = meter || {};

  const {
    Day,
    Night,
    Date: latestInvoicedReadingDate,
  } = latestInvoicedReading || {};

  const [date] = useState(() =>
    formatDate(latestInvoicedReadingDate, dateFormats.dateHalfYear)
  );

  const closeSuccessModal = () => {
    successDialog.close();
    refreshData();
  };
  const closeLoyaltySuccessModal = () => {
    mutateLoyaltyPoints(loyaltyCustomerId);
    mutateLoyaltyHistory(loyaltyCustomerId);
    loyaltySuccessDialog.close();
    refreshData();
  };

  useEffect(() => {
    if (!open) {
      setValue('DayMeasurement', '');
      setValue('NightMeasurement', '');
      clearError();
    }
  }, [open, setValue, clearError]);

  const registerMeter = (data, resetMeterValue) => {
    data = {
      DayMeasurement: data.DayMeasurement
        ? stripLeadingZeros(data.DayMeasurement)
        : null,
      NightMeasurement: data.NightMeasurement
        ? stripLeadingZeros(data.NightMeasurement)
        : null,
      MeterId,
      ResetMeter: resetMeterValue,
    };

    setLoading(true);

    removeNullFields(data);

    setMeter(data)
      .then((res) => {
        if (res?.data?.ResponseCode === 200) {
          clearError();
          trackConsumptionCountEvent('count_submit_success');

          const shouldRewardAction =
            isLoyaltyCustomer && isTheFirstTimeOfRegistration;

          if (shouldRewardAction && waveId === 0) {
            logWaveServiceFailure(
              'Loyalty | Cannot reward MMS - empty waveId',
              loyaltyCustomerId,
              waveId,
              { meter: MeterId }
            );
          }

          if (shouldRewardAction && waveId) {
            return receiveLoyaltyPoints(MeterId);
          } else {
            resetMeterConfirmationDialog.close();
            closeDialog();
            successDialog.show();
          }
          return;
        }

        handleError(res?.data?.ResponseCode, 'measurementRegistrationForm', {
          MeterNo,
        });
        trackConsumptionCountEvent('count_submit_fail');
      })
      .catch((error) => {
        handleError(error, 'measurementRegistrationForm', { MeterNo });
        trackConsumptionCountEvent('count_submit_fail');
      })
      .then(() => {
        resetMeterConfirmationDialog.close();
        setLoading(false);
      });
  };

  const onSubmit = (data) => {
    trackConsumptionCountEvent('count_submit');

    const isDayMeasurementLessThanPrevious =
      parseInt(data.DayMeasurement) < Day;

    const isNightMeasurementLessThanPrevious =
      parseInt(data.NightMeasurement) < Night;

    setConfirmationDescription('');
    if (
      isDayMeasurementLessThanPrevious ||
      isNightMeasurementLessThanPrevious
    ) {
      if (
        isDayMeasurementLessThanPrevious &&
        isNightMeasurementLessThanPrevious
      )
        setConfirmationDescription('description-dayAndNight');
      else if (isDayMeasurementLessThanPrevious)
        if (!meterHasNightConsumption)
          setConfirmationDescription('description');
        else setConfirmationDescription('description-day');
      else if (isNightMeasurementLessThanPrevious)
        setConfirmationDescription('description-night');

      resetMeterConfirmationDialog.show();
      return;
    }

    registerMeter(data, false);
  };

  const handleResetMeter = (data) => {
    registerMeter(data, true);
  };

  if (!maxDigits) return <ErrorDialog open={open} closeDialog={closeDialog} />;

  return (
    <>
      <PromptDialog
        open={resetMeterConfirmationDialog.isOpen}
        closeDialog={resetMeterConfirmationDialog.close}
        header={t('measurementRegistration:confirmation-modal.header')}
        message={t(
          `measurementRegistration:confirmation-modal.${confirmationDescription}`
        )}
        primaryActionText={t(
          'measurementRegistration:confirmation-modal.confirmation-button'
        )}
        primaryActionHandler={handleSubmit(handleResetMeter)}
        primaryActionLoading={loading}
        showSecondaryAction
        secondaryActionHandler={resetMeterConfirmationDialog.close}
      />
      <SuccessDialog
        open={successDialog.isOpen}
        closeDialog={closeSuccessModal}
        header={t(
          `measurementRegistration:success-modal.${
            !confirmationDescription ? 'header' : 'header-meterReset'
          }`
        )}
        message={t('measurementRegistration:success-modal.description')}
      />
      <RewardDialog
        open={loyaltySuccessDialog.isOpen}
        onClose={closeLoyaltySuccessModal}
        closeDialog={closeDialog}
        points={rewardPoints}
        icon={null}
        header={t('measurementRegistration:success-modal.header')}
        message={t('measurementRegistration:success-modal.description')}
      />
      <DialogOverlay
        scroll="body"
        open={open}
        closeDialog={closeDialog}
        actionsClass={styles.actions}
        dialogHeader={t(`${baseName}.header`)}
        dialogActions={
          <PrimaryButtonLoader
            loading={loading}
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
            type="submit"
          >
            {t(`${baseName}.submit-button`)}
          </PrimaryButtonLoader>
        }
      >
        <h2 className={styles.headerDescription}>
          {t(`${baseName}.description`)}
        </h2>
        <p className={styles.paragraphSubDescription}>
          {t(`${baseName}.sub-description`)}
        </p>
        <LocalisedErrorBox
          open={error !== null}
          closeBox={clearError}
          {...(error || {})}
          distanceTop=""
        />
        {meterHasNightConsumption && (
          <h2 className={styles.header}>
            <DayConsumptionIcon className={styles.icon} />
            {t(`${baseName}.day-input-header`)}
          </h2>
        )}
        <MeterInput
          register={register}
          errors={errors}
          name="DayMeasurement"
          label={t(`${baseName}.input-label`)}
          aria-label={t(`${baseName}.day-input-header`)}
          maxDigits={maxDigits}
        />
        <InputDescription
          date={date}
          consumption={formatOwnMeasurements(Day)}
        />
        {meterHasNightConsumption ? (
          <>
            <h2 className={`${styles.header} ${styles['header-night']}`}>
              <NightConsumptionIcon className={styles.icon} />
              {t(`${baseName}.night-input-header`)}
            </h2>
            <MeterInput
              register={register}
              errors={errors}
              name="NightMeasurement"
              label={t(`${baseName}.input-label`)}
              aria-label={t(`${baseName}.night-input-header`)}
              maxDigits={maxDigits}
            />
            <InputDescription
              date={date}
              consumption={formatOwnMeasurements(Night)}
            />
          </>
        ) : null}
      </DialogOverlay>
    </>
  );
};

export default MeasurementRegistration;
