import React from 'react';
import { useTranslation } from 'react-i18next';
import Big from 'big.js';
import cn from 'classnames';
import { formatDate } from 'utils/dateUtils';
import { getParticipation } from 'utils/product/enaUtils';

import Widget from 'components/Widget/Widget';
import MeterLabelWrapper from 'components/Labels/MeterLabelWrapper';
import EuroContainer from 'components/EuroContainer/EuroContainer';
// import WattContainer from 'components/WattContainer/WattContainer';
import EnaAnalysisTextField from './EnaAnalysisTextField';
import { PrimaryButton } from 'components/Buttons/Buttons';
import EnaOverview from './EnaOverview';

import styles from './EnaAnalysisWidget.module.scss';

const EnaAnalysisWidget = ({
  meter,
  enaDetails,
  onPaymentClicked = () => {},
}) => {
  const { t } = useTranslation(['ena', 'translation']);

  return (
    <section>
      {enaDetails && (
        <Widget size="m" distance="m" widgetClass={styles['ena-widget']}>
          <MeterLabelWrapper
            type={meter.type}
            friendlyName={meter.FriendlyName}
            meterNo={meter.MeterNo}
            meter={meter}
          />
          <EnaOverview enaDetails={meter?.solarisInfo} />
          <section className={styles.container}>
            <article className={styles.details}>
              <EnaAnalysisTextField
                content={t('ena:analysis.discounts')}
                amount={
                  <EuroContainer
                    content={Math.abs(enaDetails.TotalDiscount ?? 0)}
                  />
                }
              />
              <EnaAnalysisTextField
                content={t('ena:analysis.participation')}
                amount={
                  <EuroContainer content={getParticipation(enaDetails)} />
                }
              />
              <EnaAnalysisTextField
                content={t('ena:analysis.funding')}
                amount={<EuroContainer content={enaDetails.Funding ?? 0} />}
                containerClass={cn(
                  styles['analysis-subitem'],
                  styles['half-distance']
                )}
              />
              <EnaAnalysisTextField
                content={t('ena:analysis.deposits')}
                amount={
                  <EuroContainer
                    content={enaDetails.TotalPaymentAmmount ?? 0}
                  />
                }
                containerClass={cn(
                  styles['analysis-subitem'],
                  styles['full-distance']
                )}
              />

              {/* <EnaAnalysisTextField
                content={t('ena:analysis.production')}
                amount={
                  <WattContainer
                    weight="bold"
                    //wrong field here. we dont have the correct one, so it is commented out for now
                    amount={enaDetails.PowerKva ?? 0}
                    containerClass={styles.kwhContainer}
                  />
                }
              /> */}
              <EnaAnalysisTextField
                content={t('ena:analysis.power-factor')}
                amount={`${
                  new Big(enaDetails.Powerfactor).toFixed(5) ?? 0
                } KW/€`}
              />
              <EnaAnalysisTextField
                content={t('ena:analysis.activation-date')}
                amount={
                  enaDetails.ActivationDate
                    ? formatDate(enaDetails.ActivationDate)
                    : ''
                }
                containerClass={styles['analysis-subitem']}
              />
            </article>
            <article className={styles.discount}>
              <PrimaryButton
                className={styles.actionButton}
                onClick={() => onPaymentClicked(meter)}
              >
                {t('ena:analysis.increase-participation')}
              </PrimaryButton>
            </article>
          </section>
        </Widget>
      )}
    </section>
  );
};

export default EnaAnalysisWidget;
