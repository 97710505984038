const addMetersToRequests = (requests, meters) => {
  return requests && meters
    ? requests.map((request) => {
        const { MeterId } = request || {};
        const meterForRequest = meters.find((x) => x.Id === MeterId);
        return {
          ...request,
          meter: meterForRequest || null,
        };
      })
    : [];
};

export default addMetersToRequests;
