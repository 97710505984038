import { getDiscoveryItems } from 'utils/product/offerUtils';
import { useAuthUser } from 'utils/AuthUser';
import { useVibes } from './useRewardSchemeAccounts';
import { useForYouSchema } from './useForYouSchema';

const useDiscovery = (language) => {
  const { isLoyaltyCustomer, loyaltyCustomerId, loyaltyLoading } =
    useAuthUser();
  const {
    data: discoveryItems,
    loading: discoveryLoading,
    error,
  } = useForYouSchema(language);

  const {
    data: userVibes,
    error: vibesError,
    loading: vibesLoading,
  } = useVibes(loyaltyCustomerId, language, !isLoyaltyCustomer);
  const loading = discoveryLoading || vibesLoading || loyaltyLoading;

  const results = loading
    ? []
    : getDiscoveryItems(discoveryItems, isLoyaltyCustomer, userVibes);

  return {
    loading,
    error: error || vibesError,
    data: results,
  };
};
export { useDiscovery };
