import React from 'react';

import { ReactComponent as MasterCard } from 'assets/icons/MasterCardIcon.svg';
import { ReactComponent as Visa } from 'assets/icons/VisaIcon.svg';
import { ReactComponent as RightArrow } from 'assets/icons/ArrowRightIcon.svg';
import { ReactComponent as MaestroCard } from 'assets/icons/MaestroCardIcon.svg';
import { creditCardTypes } from 'utils/product/enums/paymentCardEnums';
import { cardNumberAdjustSpacing } from 'utils/numberUtils';
import { IconButton } from 'components/Buttons/Buttons';

import styles from './PaymentCardViewMoreContent.module.scss';

const PaymentCardViewMoreContent = ({
  friendlyName,
  number,
  creditCardType,
  isExpired,
  onViewMoreClick = () => {},
}) => {
  return (
    <>
      <div className={styles.container}>
        {friendlyName && <p>{friendlyName}</p>}
        <p>{cardNumberAdjustSpacing(number)}</p>
        {isExpired && <span className={styles.dummySpaceForExpirationLabel} />}
      </div>

      {creditCardType === creditCardTypes.MASTERCARD && (
        <MasterCard alt="mastercard" className={styles.cardIcon} />
      )}
      {creditCardType === creditCardTypes.VISA && (
        <Visa alt="visa" className={styles.cardIcon} />
      )}
      {creditCardType === creditCardTypes.MAESTRO && (
        <MaestroCard alt="maestro" className={styles.cardIcon} />
      )}
      {isExpired ? (
        <span className={styles.dummySpaceForBin} />
      ) : (
        <IconButton
          className={styles.rightArrow}
          aria-label="view more"
          onClick={onViewMoreClick}
        >
          <RightArrow />
        </IconButton>
      )}
    </>
  );
};

export default PaymentCardViewMoreContent;
