import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import useDialog from 'hooks/useDialog';
import commodities from 'utils/product/enums/commodities';
import useLocalizedFriendlyName from 'hooks/useLocalizedFriendlyName';
import {
  SuccessDialog,
  PromptDialog,
} from 'components/PromptDialogs/PromptDialog';
import { LocalisedErrorBox } from 'components/MessageBox/MessageBox';
import { createCase, createRequest } from 'api/requests';
import { useAuthUser } from 'utils/AuthUser';
import { requestTypes } from 'utils/product/enums/requests';
import { trackShopProgramEvent } from 'utils/product/tracking/events';
import { isEmptyString } from 'utils/stringUtils';

const ChangeProgramDialog = ({
  confirmationDialog,
  meters,
  mutateMeters,
  program,
  programTitle,
  selectedMeterId,
  generousGuarantee,
}) => {
  const { t } = useTranslation(['translation', 'shop']);
  const successDialog = useDialog();
  const { user: account } = useAuthUser();
  const { getDisplayName } = useLocalizedFriendlyName();

  const selectedMeter = meters.find((meter) => meter.Id === selectedMeterId);
  const successMessage = t(
    `shop:program.activate-success-message-${
      selectedMeter?.Commodity === commodities.energy ? 'electricity' : 'gas'
    }`,
  );

  const handleChangeProgram = () => {
    confirmationDialog.startLoading();

    const changeProgram =
      selectedMeter?.Commodity === commodities.energy
        ? changeElectricityProgram
        : changeGasProgram;

    changeProgram()
      .then((result) => {
        if (result.data.ResponseCode === 200) {
          confirmationDialog.close();
          successDialog.show();
          trackShopProgramEvent('shop_program_change_success', programTitle);
          return;
        }
        confirmationDialog.handleError('general');
        trackShopProgramEvent('shop_program_change_fail', programTitle);
      })
      .catch((error) => {
        confirmationDialog.handleError(error, 'generic');
        trackShopProgramEvent('shop_program_change_fail', programTitle);
      })
      .then((res) => {
        confirmationDialog.stopLoading();
      });
  };

  const changeElectricityProgram = () => {
    const payload = {
      PrimaryContactid: account.PrimarycontactId,
      AccountId: account.Id,
      MeterId: selectedMeterId,
      Commodity: commodities.energy,
      CaseCategoryCode: requestTypes.electricityProgramChange,
      PackageFriendlyname: program?.packageId,
      GuaranteeCode: generousGuarantee,
    };

    return createCase(payload);
  };

  const changeGasProgram = () => {
    const selectedMeter = meters.find((meter) => meter.Id === selectedMeterId);

    const suffix = program?.sections?.guaranteeProgram
      ? program?.sections?.guaranteeProgram?.choices?.find(
          (choice) => choice.value === generousGuarantee,
        )?.title
      : '';

    const payload = {
      AccountId: account.Id,
      MeterId: selectedMeterId,
      Commodity: selectedMeter?.Commodity,
      RequestCategory: requestTypes.programChange,
      RequestText: `Αλλαγή προγράμματος - ${programTitle}${
        isEmptyString(suffix) ? '' : ` - ${suffix}`
      }`,
    };

    return createRequest(payload);
  };

  const handleConfirmationCloseDialog = () => {
    confirmationDialog.close();
    confirmationDialog.clearError();
  };

  return (
    <>
      <PromptDialog
        open={confirmationDialog.isOpen}
        closeDialog={handleConfirmationCloseDialog}
        dialogHeader={t('shop:program.activate-header')}
        message={
          <Trans
            i18nKey={'shop:program.activate-confirmation-message'}
            values={{
              supply: getDisplayName(
                selectedMeter?.FriendlyName,
                selectedMeter?.MeterNo,
              ),
              program: programTitle,
            }}
            components={{
              bold: <b></b>,
            }}
          />
        }
        primaryActionText={t('translation:confirm')}
        primaryActionHandler={handleChangeProgram}
        primaryActionLoading={confirmationDialog.isLoading}
        showCloseButton
        alignActions="right"
      >
        <LocalisedErrorBox
          open={confirmationDialog.error !== null}
          closeBox={confirmationDialog.clearError}
          {...(confirmationDialog.error || {})}
          distanceBottom=""
        />
      </PromptDialog>
      <SuccessDialog
        open={successDialog.isOpen}
        closeDialog={() => {
          mutateMeters();
          successDialog.close();
        }}
        header={t(`shop:program.activate-success-title`)}
        message={successMessage}
      />
    </>
  );
};

export default ChangeProgramDialog;
