import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useAccountMeters from 'data/useAccountMeters';
import {
  meterStatusesElectricity,
  meterStatusesGas,
} from 'utils/product/enums/meterStatuses';

import useError from 'hooks/useError';
import useDialog from 'hooks/useDialog';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import { LocalisedErrorBox } from 'components/MessageBox/MessageBox';
import MeterNamingContent from 'components/MeterNaming/MeterNamingContent';
import useUniqueFlow from 'hooks/useUniqueFlow';

import styles from './MeterNaming.module.scss';

const MeterNaming = () => {
  const { t } = useTranslation(['onboarding', 'translation']);
  const baseName = 'onboarding:meterNaming';
  const { meters, loading } = useAccountMeters({
    statusesElectricity: [
      meterStatusesElectricity.active,
      meterStatusesElectricity.new,
      meterStatusesElectricity.checked,
      meterStatusesElectricity.pending,
    ],
    statusesGas: [
      meterStatusesGas.active,
      meterStatusesGas.new,
      meterStatusesGas.pending,
    ],
  });

  const { incrementStep } = useUniqueFlow();

  const metersDialog = useDialog(true);

  const handleClick = () => {
    metersDialog.close();
    incrementStep();
  };

  const { error, handleError, clearError } = useError();

  useEffect(() => {
    if (error != null) {
      document.getElementById('error_box') &&
        document
          .getElementById('error_box')
          .scrollIntoView({ behavior: 'smooth' });
    }
  }, [error]);

  return (
    <DialogOverlay
      open={metersDialog.isOpen}
      closeDialog={handleClick}
      contentClass={styles.dialogContent}
      dialogHeader={t(`${baseName}.friendly-name`)}
      scroll="body"
    >
      <p className={styles.paragraph}>
        {t(`${baseName}.friendly-name-description`)}
      </p>
      <LocalisedErrorBox
        id="error_box"
        open={error !== null}
        closeBox={clearError}
        {...(error || {})}
        distanceTop=""
      />

      <MeterNamingContent
        meters={meters}
        metersLoading={loading}
        handleError={handleError}
        clearError={clearError}
        closeDialog={handleClick}
      />
    </DialogOverlay>
  );
};

export default MeterNaming;
