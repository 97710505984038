import {
  contestStatuses,
  contestParticipationStatuses,
  contestUserStatus,
  contestDrawStatuses,
} from 'utils/product/enums/contests';
import {
  sortByDateFieldAscending,
  sortByDateFieldDescending,
} from 'utils/sortUtils';
import { getBaseProperties } from './offerUtils';
import { isPast, isFuture } from 'utils/dateUtils';

const checkIfUserIsEvenARejectedWinner = (userParticipationStatus) =>
  userParticipationStatus === contestParticipationStatuses.winner ||
  userParticipationStatus === contestParticipationStatuses.rejected;

const isContestItemValid = (item) => {
  const isAvailableContest = item?.status === contestStatuses.available;
  const { beginDate = null, endDate = null } = item;
  const isValid =
    (!beginDate || isPast(beginDate)) && (!endDate || isFuture(endDate));
  return isValid && isAvailableContest;
};

export const getUserProfileContestsData = (
  contestsParticipations,
  contestsCMS,
) => {
  const { data: contestsList = [], included = {} } = contestsCMS || {};

  const contests = contestsList?.map((item) => {
    return getBaseProperties(item, included);
  });

  const participations = contests?.reduce(
    (accumulator, currentContestCMS) => {
      const participatedContest = contestsParticipations?.find(
        (participation) =>
          currentContestCMS?.contestId === participation.contest,
      );

      if (!participatedContest) return accumulator;

      const isActiveContest = isContestItemValid(currentContestCMS);

      const contest = {
        id: currentContestCMS?.id,
        slug: currentContestCMS?.slug || currentContestCMS?.id,
        image: currentContestCMS?.imageUrl,
        title: currentContestCMS?.title,
        endDate: currentContestCMS?.endDate,
      };

      if (isActiveContest) {
        contest.expirationLabelColor = currentContestCMS?.expirationLabelColor;
        contest.participationStatus = contestUserStatus.active;
        contest.participationsCount = participatedContest?.participationsCount;

        accumulator.live.push(contest);
      } else {
        const isWinner = contestsParticipations?.some(
          (participation) =>
            currentContestCMS?.contestId === participation.contest &&
            currentContestCMS?.drawStatus === contestDrawStatuses.COMPLETED &&
            checkIfUserIsEvenARejectedWinner(participation.participationStatus),
        );

        const isLoser =
          currentContestCMS?.drawStatus === contestDrawStatuses.COMPLETED &&
          !isWinner;

        contest.participationStatus = isWinner
          ? contestUserStatus.winner
          : isLoser
            ? contestUserStatus.loser
            : contestUserStatus.completed;

        accumulator.expired.push(contest);
      }

      return accumulator;
    },
    {
      live: [],
      expired: [],
    },
  );

  sortByDateFieldAscending(participations.live, 'endDate');
  sortByDateFieldDescending(participations.expired, 'endDate');

  return participations;
};
