import React from 'react';

import shopEnergyTypes from 'utils/product/enums/shopEnergyTypes';
import { ReactComponent as EnergyIcon } from 'assets/icons/EnergyNoFillIcon.svg';
import { ReactComponent as GasIcon } from 'assets/icons/GasNoFillIcon.svg';
import { ReactComponent as EcoIcon } from 'assets/icons/EcoNoFillIcon.svg';

import styles from './ProgramSeparator.module.scss';

const ProgramSeparator = ({ type }) => {
  return (
    <section className={styles.container}>
      <div className={styles.line}></div>
      <div className={styles.iconContainer}>
        {(type === shopEnergyTypes.electricity ||
          type === shopEnergyTypes.eco ||
          type === shopEnergyTypes.combinedElectricity ||
          type === shopEnergyTypes.combined) && (
          <EnergyIcon className={styles.icon} />
        )}
        {(type === shopEnergyTypes.gas ||
          type === shopEnergyTypes.combined) && (
          <GasIcon className={styles.icon} />
        )}
        {type === shopEnergyTypes.service && (
          <EcoIcon className={styles.icon} />
        )}
      </div>
      <div className={styles.line}></div>
    </section>
  );
};
export default ProgramSeparator;
