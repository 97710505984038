import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useDialog from 'hooks/useDialog';
import useAccountMeters from 'data/useAccountMeters';
import {
  getSortedMetersForHomePage,
  getMetersWithSettlement,
} from 'utils/product/meterUtils';
import { trackHomeBillsEvent } from 'utils/product/tracking/events';
import {
  meterStatusesElectricity,
  meterStatusesGas,
} from 'utils/product/enums/meterStatuses';

import DetailedListWidget from 'components/DetailedListWidget/DetailedListWidget';
import MetersListDialog from 'components/RequestsHome/MetersListDialog';
import SettlementOverviewDialog from 'components/BillsSettlement/SettlementOverviewDialog';

import styles from './HomeSettlementWidget.module.scss';

const HomeSettlementWidget = () => {
  const { t } = useTranslation(['homepage', 'requests']);
  const {
    meters,
    loading: metersLoading,
    mutate: mutateMeters,
  } = useAccountMeters({
    statusesElectricity: [
      meterStatusesElectricity.active,
      meterStatusesElectricity.terminated,
    ],
    statusesGas: [meterStatusesGas.active, meterStatusesGas.terminated],
  });
  const [settlementMeter, setSettlementMeter] = useState(null);

  const metersDialog = useDialog();
  const settlementDialog = useDialog();

  const allMeters = metersLoading ? [] : getSortedMetersForHomePage(meters);

  const metersWithSettlement = getMetersWithSettlement(allMeters);

  const showSettlementDialog = () => {
    metersDialog.close();
    settlementDialog.show();
  };

  const onSettlementClicked = () => {
    trackHomeBillsEvent('home_settlement_view');

    if (metersWithSettlement.length === 0) return;
    if (metersWithSettlement.length === 1) {
      setSettlementMeter(metersWithSettlement[0].Id);
      showSettlementDialog();
      return;
    }
    metersDialog.show();
  };

  return (
    !metersLoading &&
    metersWithSettlement.length > 0 && (
      <section className={styles.container}>
        <DetailedListWidget
          text={t('homepage:settlement')}
          clickHandler={onSettlementClicked}
        />
        <MetersListDialog
          headerText={t(`requests:certificates.choose-meter`)}
          open={metersDialog.isOpen}
          closeDialog={metersDialog.close}
          clickHandler={showSettlementDialog}
          setSelectedMeter={setSettlementMeter}
          meters={metersWithSettlement}
          buttonLoading={false}
        />
        {settlementDialog.isOpen && (
          <SettlementOverviewDialog
            open={settlementDialog.isOpen}
            closeDialog={() => {
              settlementDialog.close();
              setSettlementMeter(null);
            }}
            meter={
              settlementMeter
                ? meters.find((x) => x.Id === settlementMeter)
                : null
            }
            mutateMeters={mutateMeters}
          />
        )}
      </section>
    )
  );
};
export default HomeSettlementWidget;
