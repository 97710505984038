import { useAuthUser } from 'utils/AuthUser';
import useData from './useData';

export default function useSolarisDetails(solarisId) {
  const { accountId } = useAuthUser();

  const { data, error, loading } = useData(
    solarisId
      ? `/solaris/getDetails?SolarisId=${solarisId}&accountId=${accountId}`
      : null
  );

  return {
    loading: loading && solarisId !== null,
    error,
    data,
  };
}
