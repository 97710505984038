import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Shop from 'pages/shop/Shop';
import Program from 'pages/shop/Program';

const ShopRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="home" replace />} />
      <Route path="ena" element={<Program isEna={true} />} />
      <Route
        path="home/service/ena"
        element={<Navigate to="/shop/ena" replace />}
      />
      <Route
        path="business/service/ena"
        element={<Navigate to="/shop/ena" replace />}
      />
      <Route path=":programType" element={<Shop />} />
      <Route
        path=":programType/:energyType/:programName"
        element={<Program />}
      />
      <Route
        path=":programType/:energyType/:programName/:onboarding"
        element={<Program isDialogOpen={true} />}
      />
    </Routes>
  );
};

export default ShopRouter;
