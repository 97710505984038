import React from 'react';

import styles from './EmptyState.module.scss';

const EmptyState = ({ Icon, containerClass, text, subText }) => {
  return (
    <div className={containerClass ?? styles.container}>
      {Icon}
      <p className={styles.text}>{text}</p>
      {subText && <p className={styles.subText}>{subText}</p>}
    </div>
  );
};

export default EmptyState;
