import Big from 'big.js';
import { validateAmountTwoDecimalsFormat } from 'utils/validators';
import { fixDecimalString } from 'utils/numberUtils';

const isInputValid = (data, config) => {
  const value = fixDecimalString(data);

  if (
    value === undefined ||
    value === null ||
    !validateAmountTwoDecimalsFormat(value) ||
    isNaN(value) ||
    Big(value) <= 0
  )
    return { isValid: false, message: '' };

  if (config?.maxAmount > 0 && Big(value) > config?.maxAmount)
    return { isValid: false, message: 'max-value' };
  return { isValid: true, message: '' };
};

export { isInputValid };
