import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExternalLink } from 'components/Offers/OfferElements';

const TermsLink = ({ url, ...rest }) => {
  const { t } = useTranslation(['offers']);

  const _url = url ? new URL(url) : {};
  const { pathname: path } = _url;
  const parts = path?.split('/');
  const index =
    parts?.indexOf('terms-content') > -1
      ? parts?.indexOf('terms-content') + 1
      : -1;
  const slug = index > -1 ? parts?.[index] : '';

  const redirectUrl = slug ? `/terms/${slug}` : url;
  return (
    <ExternalLink
      url={redirectUrl}
      text={t('offers:contests.termsLink')}
      {...rest}
    />
  );
};

export default TermsLink;
