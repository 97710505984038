import React from 'react';
import { useTranslation } from 'react-i18next';
import whatsNewTypes from 'utils/product/enums/whatsNewTypes';

import styles from './WhatsNewInfo.module.scss';

const WhatsNewInfo = ({ pageIndex = 0, type = whatsNewTypes.default }) => {
  const { t } = useTranslation(['onboarding']);

  const translationName = {
    [whatsNewTypes.default]: 'whatsNew',
    [whatsNewTypes.b2b]: 'whatsNewB2B',
    [whatsNewTypes.newClient]: 'whatsNewNewClient',
  };

  const baseName = `onboarding:${translationName[type]}.page${pageIndex}`;

  return (
    <section className={styles.section}>
      <h1 className={styles.header}>{t(`${baseName}.title`)}</h1>
      <p className={styles.paragraph}>{t(`${baseName}.paragraph`)}</p>
    </section>
  );
};

export default WhatsNewInfo;
