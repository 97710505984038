import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  newClientSteps,
  newClientFlow,
  existingClientSteps,
  existingClientFlow,
} from 'utils/product/onboardingConfig';
import accountCategories from 'utils/product/enums/accountCategories';
import energyTypes from 'utils/product/enums/shopEnergyTypes';
import findIndex from 'lodash/findIndex';

import Stepper from 'components/Stepper/Stepper';

const OnboardingStepper = ({
  step,
  energyType,
  customerCategory,
  hasGasSupply,
  isShop = false,
}) => {
  const { t } = useTranslation(['translation', 'newClient']);
  const [stepper, setStepper] = useState([]);
  const [initialStep, setInitialStep] = useState();
  const {
    programType,
    energyType: programEnergyType,
    programName,
  } = useParams();
  const steps = isShop ? existingClientSteps : newClientSteps;

  useEffect(() => {
    const energyDependentSteps =
      energyType === energyTypes.combined
        ? hasGasSupply === 'true'
          ? [
              'meterDetails',
              'eBill',
              'gasMeterDetails',
              'gasEBill',
              'userCapacity',
            ]
          : ['meterDetails', 'eBill']
        : energyType === energyTypes.gas
        ? ['gasMeterDetails', 'gasEBill', 'userCapacity']
        : ['meterDetails', 'eBill'];

    const _sharedSteps = [
      'contactPreferences',
      'identification',
      'documents',
      'success',
    ];

    const stepKeys = isShop
      ? [...energyDependentSteps, 'identification', 'documents', 'success']
      : customerCategory === `${accountCategories.company}`
      ? [
          'companyDetails',
          'primaryContact',
          ...energyDependentSteps,
          ..._sharedSteps,
        ]
      : ['customerDetails', ...energyDependentSteps, ..._sharedSteps];

    const _stepper = stepKeys.map((x, index) => {
      return {
        path: isShop
          ? `/shop/${programType}/${programEnergyType}/${programName}${existingClientFlow[x]}`
          : newClientFlow[x],
        title: t(`newClient:${x}.title`),
        overallStep: steps[x],
      };
    });
    setStepper(_stepper);
  }, [
    energyType,
    customerCategory,
    hasGasSupply,
    t,
    isShop,
    programName,
    programType,
    programEnergyType,
    steps,
  ]);

  useEffect(() => {
    const _initialStep = findIndex(stepper, function (x) {
      return x.overallStep === step;
    });

    setInitialStep(_initialStep);
  }, [stepper, step]);

  return (
    (step >= steps.customerDetails || step >= steps.companyDetails) && (
      <Stepper
        steps={stepper}
        initialStep={initialStep}
        handleActiveStepInternally={false}
      />
    )
  );
};

export default OnboardingStepper;
