import React from 'react';
import cn from 'classnames';
import { formatNumber } from 'utils/moneyUtils';

import styles from './WattContainer.module.scss';

const WattContainer = ({
  size = 'm',
  unitSize = 'm',
  amount,
  containerClass,
  amountClass,
  unitClass,
  weight = 'regular',
}) => {
  return (
    <div className={cn(styles[`container-${weight}`], containerClass)}>
      <span className={cn(styles[`amount-${size}`], amountClass)}>
        {amount ? formatNumber(amount) : '0'}
      </span>
      <span className={cn(styles[`unit-${unitSize}`], unitClass)}>
        &nbsp;kWh
      </span>
    </div>
  );
};

export default WattContainer;
