import Big from 'big.js';

export const fixDecimalString = (data) => {
  return typeof data === 'string' ? data.trim().replace(',', '.') : null;
};

export const getNumberParts = (number) => {
  if (number === undefined || number === null || isNaN(Number(number))) {
    return { integerPart: '', decimalPart: '' };
  }
  const y = new Big(number).toFixed(2);
  const [integerPart, decimalPart] = y.split('.');

  return { integerPart, decimalPart };
};

export const cardNumberAdjustSpacing = (number) => {
  const numberArray = number
    ?.replace(/\s/g, '')
    ?.replace(/\D/g, '•')
    ?.split('');

  return (
    <>
      <span>{numberArray?.slice(0, 4)}</span>
      <span>{numberArray?.slice(4, 8)}</span>
      <span>{numberArray?.slice(8, 12)}</span>
      <span>{numberArray?.slice(12, 16)}</span>
    </>
  );
};
