import React from 'react';
import { ReactComponent as LoyaltyDebitIcon } from 'assets/icons/LoyaltyDebitIcon.svg';
import { ReactComponent as LoyaltyCreditIcon } from 'assets/icons/LoyaltyCreditIcon.svg';
import { ReactComponent as TrophyIcon } from 'assets/icons/TrophyIcon.svg';
import WidgetLabel from 'components/WidgetLabel/WidgetLabel';
import { formatMoney } from 'utils/moneyUtils';

import styles from './LoyaltyLabels.module.scss';

export const RedeemPointsLabel = ({ points, labelClass = '' }) => {
  return points > 0 ? (
    <WidgetLabel
      icon={<LoyaltyDebitIcon />}
      content={formatMoney(points, false)}
      labelClass={`${styles.pointsLabel} ${labelClass}`}
      containerClass={styles['pointsLabel-container']}
    />
  ) : null;
};

export const EarnPointsLabel = ({
  points,
  labelClass = styles.pointsLabel,
  isYellow = true,
  ...rest
}) => {
  return points > 0 ? (
    <WidgetLabel
      icon={<LoyaltyCreditIcon />}
      content={formatMoney(points, false)}
      labelClass={labelClass}
      containerClass={styles['pointsLabel-container']}
      contentClass={isYellow ? styles.pointsText : ''}
      {...rest}
    />
  ) : null;
};

export const WinnerLabel = ({ labelClass = styles.winnerLabel, ...rest }) => {
  return (
    <WidgetLabel
      icon={<TrophyIcon />}
      labelClass={labelClass}
      containerClass={styles['winnerLabel-container']}
      {...rest}
    />
  );
};

export const VibeLabel = ({ vibe }) => {
  return vibe ? (
    <WidgetLabel
      icon={<img src={vibe.imageUrl} alt={vibe.title} width={24} />}
      content={vibe.title}
      labelStyle={{ backgroundColor: vibe.color }}
      labelClass={styles.vibeLabel}
      iconClass={styles.vibeIcon}
    />
  ) : null;
};
