import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import MetersListDialogSelect from './MetersListDialogSelect';
import { LocalisedErrorBox } from 'components/MessageBox/MessageBox';

import styles from './MetersListDialog.module.scss';

const MetersListDialog = ({
  open = true,
  closeDialog,
  headerText,
  message,
  clickHandler,
  setSelectedMeter,
  meters,
  buttonLoading,
  error = null,
  clearError,
  defaultMeter,
  ...props
}) => {
  const { t } = useTranslation(['translation']);

  const [currentMeter, setCurrentMeter] = useState(
    meters.length === 1 ? meters[0].Id : '',
  );
  const handleChange = (event) => {
    setCurrentMeter(event.target.value);
    setSelectedMeter(event.target.value);
  };

  useEffect(() => {
    if (open === false) setCurrentMeter('');
  }, [open]);

  useEffect(() => {
    if (meters?.length === 1 && open === true) setCurrentMeter(meters[0].Id);
  }, [meters, open]);

  return (
    <DialogOverlay
      open={open}
      closeDialog={closeDialog}
      contentClass={styles.dialogContent}
      dialogActions={
        <PrimaryButtonLoader
          loading={buttonLoading}
          onClick={clickHandler}
          disabled={buttonLoading || !currentMeter}
        >
          {t('translation:continue')}
        </PrimaryButtonLoader>
      }
      {...props}
    >
      {headerText && <h2 className={styles.header}>{headerText} </h2>}
      {message && <p className={styles.message}>{message}</p>}
      <LocalisedErrorBox
        open={error !== null}
        closeBox={clearError}
        {...(error || {})}
        distanceTop=""
      />
      <MetersListDialogSelect
        value={currentMeter}
        meters={meters}
        handleChange={handleChange}
        label={t('translation:supplies')}
      />
    </DialogOverlay>
  );
};

export default MetersListDialog;
