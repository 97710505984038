import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs, Tab } from 'components/Tabs/Tabs';
import useAccountConsumptions from 'data/useAccountConsumptions';
import useAccountOwnMeasurements from 'data/useAccountOwnMeasurements';
import { trackConsumptionCountHistoryEvent } from 'utils/product/tracking/events';

import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import EmptyState from 'components/History/EmptyState';
import { ReactComponent as ConsumptionsHistoryIcon } from 'assets/icons/ConsumptionsHistory.svg';
import Pager from 'components/Pager/Pager';
import TabPanel from 'components/Tabs/TabPanel';
import HistoryMeterChips from 'components/Chip/HistoryMeterChips';
import ConsumptionsHistoryElement from './ConsumptionsHistoryElement';
import OwnMeasurementsHistoryElement from './OwnMeasurementsHistoryElement';

import styles from './ConsumptionsHistoryWidget.module.scss';

const ConsumptionsHistoryWidget = ({ meters, metersLoading }) => {
  const { t } = useTranslation(['translation', 'consumptions']);
  const { consumptions, loading: consumptionsLoading } =
    useAccountConsumptions(meters);

  const [value, setValue] = useState(0);

  const {
    ownMeasurements: ownMeasurementsData,
    loading: ownMeasurementsLoading,
  } = useAccountOwnMeasurements(meters, value !== 1);

  const [limit, setLimit] = useState(8);

  const [chosenConsumptionMeter, setChosenConsumptionMeter] = useState('all');
  const [chosenOwnConsumptionMeter, setChosenOwnConsumptionMeter] =
    useState('all');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1)
      trackConsumptionCountHistoryEvent('consumption_history_count');
  };

  const chooseConsumptionMeter = (meter) => {
    setChosenConsumptionMeter(meter !== 'all' ? meter.Id : 'all');
  };
  const chooseOwnConsumptionMeter = (meter) => {
    setChosenOwnConsumptionMeter(meter !== 'all' ? meter.Id : 'all');
  };

  const filteredConsumptions =
    chosenConsumptionMeter === 'all'
      ? consumptions
      : consumptions?.filter((x) => x.MeterId === chosenConsumptionMeter);

  const ownMeasurements = ownMeasurementsData?.map((ownMeasurement) => {
    return {
      ...ownMeasurement,
      Commodity: meters?.find((meter) => meter.Id === ownMeasurement.MeterId)
        ?.Commodity,
    };
  });

  const filteredOwnMeasurements =
    chosenOwnConsumptionMeter === 'all'
      ? ownMeasurements
      : ownMeasurements.filter((x) => x.MeterId === chosenOwnConsumptionMeter);

  const handleLimitChange = (value) => {
    setLimit(value);
  };
  return (
    <Widget
      distance="s"
      title={<WidgetHeader title={t('consumptions:history.title')} />}
    >
      <section className={styles['tabs-container']}>
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{ root: styles.tabs }}
          centered
        >
          <Tab
            label={
              <span
                dangerouslySetInnerHTML={{
                  __html: t('consumptions:tab-title-1'),
                }}
              />
            }
          />
          <Tab
            label={
              <span
                dangerouslySetInnerHTML={{
                  __html: t('consumptions:tab-title-2'),
                }}
              />
            }
          />
        </Tabs>
      </section>
      <section className={styles['tabsPanel-container']}>
        <TabPanel
          value={value}
          index={0}
          idPrefix="consumptions"
          innerClass={styles['tabPanel-innerContainer']}
        >
          <HistoryMeterChips
            meters={meters}
            handlechosenMeter={chooseConsumptionMeter}
            chosenMeter={chosenConsumptionMeter}
            containerClass={styles.chipContainer}
          />
          <WidgetSkeleton
            distance="s"
            show={metersLoading || consumptionsLoading}
          >
            {filteredConsumptions?.length === 0 && (
              <EmptyState
                Icon={<ConsumptionsHistoryIcon />}
                text={t('consumptions:no-clearing-account')}
              />
            )}
            {filteredConsumptions?.length > 0 && (
              <>
                <article className={styles.listContainer}>
                  {filteredConsumptions.slice(0, limit).map((consumption) => (
                    <ConsumptionsHistoryElement
                      key={`${consumption.MeterId}-${consumption.innerId}`}
                      meterNo={consumption.MeterNo}
                      commodity={consumption.Commodity}
                      friendlyName={consumption.FriendlyName}
                      dayMeasurement={consumption.DayConsumptionString}
                      nightMeasurement={consumption.NightConsumptionString}
                      dateFrom={consumption.Billfrom}
                      dateTo={consumption.BillTo}
                      hasNightIndication={consumption.hasNightIndication}
                    />
                  ))}
                </article>
                <Pager
                  containerClass={styles['moreButton-container']}
                  totalCount={filteredConsumptions?.length}
                  onClick={handleLimitChange}
                />
              </>
            )}
          </WidgetSkeleton>
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          idPrefix="ownMeasurements"
          innerClass={styles['tabPanel-innerContainer']}
        >
          <WidgetSkeleton
            distance="s"
            show={metersLoading || ownMeasurementsLoading}
          >
            <HistoryMeterChips
              meters={meters}
              handlechosenMeter={chooseOwnConsumptionMeter}
              chosenMeter={chosenOwnConsumptionMeter}
              containerClass={styles.chipContainer}
            />
            {filteredOwnMeasurements?.length === 0 && (
              <EmptyState
                Icon={<ConsumptionsHistoryIcon />}
                text={t('consumptions:no-own-measurements')}
              />
            )}
            {filteredOwnMeasurements?.length > 0 && (
              <>
                <article className={styles.listContainer}>
                  {filteredOwnMeasurements
                    .slice(0, limit)
                    .map((ownMeasurement) => (
                      <OwnMeasurementsHistoryElement
                        key={`${ownMeasurement.MeterId}-${ownMeasurement.MeasurementStatus}-${ownMeasurement.ReadingDate}`}
                        meterNo={ownMeasurement.MeterNo}
                        commodity={ownMeasurement.Commodity}
                        friendlyName={ownMeasurement.FriendlyName}
                        dayMeasurement={ownMeasurement.DayMeasurementString}
                        nightMeasurement={ownMeasurement.NightMeasurementString}
                        dateFrom={ownMeasurement.ReadingDate}
                        hasNightIndication={ownMeasurement.hasNightIndication}
                      />
                    ))}
                </article>
                <Pager
                  containerClass={styles['moreButton-container']}
                  totalCount={filteredOwnMeasurements?.length}
                  onClick={handleLimitChange}
                />
              </>
            )}
          </WidgetSkeleton>
        </TabPanel>
      </section>
    </Widget>
  );
};
export default ConsumptionsHistoryWidget;
