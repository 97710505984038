import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import useEnum from 'data/useEnum';
import programTypes from 'utils/product/enums/programTypes';
import {
  getSupplyNumberType,
  shouldGetDefaultActivityId,
} from 'utils/product/onboarding/helpers';
import { kvaList } from 'utils/product/config';
import { TextInput } from 'components/Inputs/TextInput';
import { ControlledSelect } from 'components/Selects/ControlledSelect';
import StandardSelectOption from 'components/Selects/StandardSelectOption';
import { SupplyNumberInput } from 'components/Inputs/SupplyNumberInput';
import { HiddenFormInput } from 'components/Inputs/HiddenFormInput';
import ActivityCodeInput from 'components/Inputs/ActivityCodeInput';

import { trackNewContractEvent } from 'utils/product/tracking/events';
import {
  getCustomerCategory,
  getShopOrOnboardingType,
} from 'utils/product/tracking/trackingLabels';
import styles from './MeterDetails.module.scss';

const MeterDetails = ({
  defaultValues = {},
  onUpdate,
  isGasProgram = false,
  isShop = false,
}) => {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const { t } = useTranslation(['inputs', 'newClient']);
  const baseObjectName = isGasProgram ? 'gasProgram' : 'energyProgram';
  const [edaCode, setEdaCode] = useState(null);

  const { Activities = [] } = useEnum('Activities');
  const getGasCommunitiesKey = (isGas) => (isGas ? 'GasCommunities' : null);
  const { GasCommunities = [], loading: gasCommunitiesLoading } = useEnum(
    getGasCommunitiesKey(isGasProgram)
  );
  const getRegionsKey = (isGas) => (isGas ? null : 'Regions');
  const { Regions = [] } = useEnum(getRegionsKey(isGasProgram));

  const meterNo =
    watch(
      `${baseObjectName}.meterNo`,
      defaultValues[baseObjectName]?.meterNo
    ) ?? '';

  const handleGasCommunityChange = (e) => {
    const gasCommunityId = e.target.value;
    setValue(`gasCommunity`, gasCommunityId);
    onUpdate({
      gasCommunity: gasCommunityId,
    });
  };

  const handleChange = (e) => {
    const selectedKVA = e.target.value;
    setValue(`${baseObjectName}.agreedPowerKVA`, selectedKVA);
    onUpdate({
      [baseObjectName]: {
        ...defaultValues[baseObjectName],
        agreedPowerKVA: selectedKVA,
      },
    });
  };

  const onActivityChange = (value) => {
    setValue(`${baseObjectName}.activityCodeId`, value);
    onUpdate({
      [baseObjectName]: {
        ...defaultValues[baseObjectName],
        activityCodeId: value,
      },
    });
  };

  useEffect(() => {
    if (isGasProgram) {
      const prefix = meterNo ? meterNo.substring(0, 2) : '';
      setEdaCode(prefix ?? null);
    }
  }, [meterNo, isGasProgram]);

  useEffect(() => {
    if (!isGasProgram) {
      const regionCode = meterNo ? meterNo.substring(0, 1) : '';
      setValue(
        `${baseObjectName}.region`,
        Regions?.find((x) => `${x.RegionCode}` === regionCode)?.RegionId
      );
    }
  }, [meterNo, isGasProgram, Regions, baseObjectName, setValue]);

  useEffect(() => {
    if (shouldGetDefaultActivityId(baseObjectName, defaultValues)) {
      setValue(
        `${baseObjectName}.activityCodeId`,
        Activities?.find((x) => x.IsDefault)?.Id
      );
    }
  }, [Activities, defaultValues, baseObjectName, setValue]);

  return (
    <article className={styles.container}>
      <h3 className={styles.header}>{t(`newClient:meterDetails.header`)}</h3>
      <TextInput
        name={`${baseObjectName}.programNameIgnored`}
        label={t(`newClient:meterDetails.programName`)}
        defaultValue={defaultValues[baseObjectName]?.name ?? ''}
        disabled={true}
      />
      <HiddenFormInput
        name={`${baseObjectName}.programName`}
        register={register}
        defaultValue={defaultValues[baseObjectName]?.name ?? ''}
      />
      <SupplyNumberInput
        register={register}
        errors={errors}
        name={`${baseObjectName}.meterNo`}
        label={t(`newClient:meterDetails.${isGasProgram ? 'gas-' : ''}meterNo`)}
        defaultValue={defaultValues[baseObjectName]?.meterNo ?? ''}
        supplyNumberType={getSupplyNumberType(isGasProgram, defaultValues)}
        onInfoClicked={trackNewContractEvent(
          `onboarding_info_icon_${isGasProgram ? 'gas' : ''}_supply_number`,
          '',
          {
            shop_or_onboarding_type: getShopOrOnboardingType(isShop),
            energy_type: defaultValues.energyType,
            customer_type: getCustomerCategory(defaultValues.customerCategory),
          }
        )}
      />
      {isGasProgram === true && (
        <ControlledSelect
          label={t(`newClient:meterDetails.gasCommunity`)}
          value={get(defaultValues, `${baseObjectName}.gasCommunity`)}
          name={`${baseObjectName}.gasCommunity`}
          errors={errors}
          onChange={handleGasCommunityChange}
          defaultValue={get(
            defaultValues,
            `${baseObjectName}.gasCommunity`,
            ''
          )}
          autoWidth={true}
          MenuProps={{
            classes: { list: styles.selectMenu },
          }}
          loading={gasCommunitiesLoading}
        >
          <StandardSelectOption
            key="default"
            value=""
            style={{ display: 'none' }}
          >
            -
          </StandardSelectOption>
          {GasCommunities.filter((x) => x.EdaCode === edaCode).map((item) => {
            return (
              <StandardSelectOption
                key={item.GasCommunityId}
                value={item.GasCommunityId}
              >
                {item.GasCommunityName}
              </StandardSelectOption>
            );
          })}
        </ControlledSelect>
      )}
      {defaultValues?.programType === `${programTypes.business}` &&
        !isGasProgram && (
          <>
            <ControlledSelect
              label={t(`newClient:meterDetails.agreedPowerKVA`)}
              value={defaultValues[baseObjectName]?.agreedPowerKVA}
              name={`${baseObjectName}.agreedPowerKVA`}
              errors={errors}
              onChange={handleChange}
              defaultValue={defaultValues[baseObjectName]?.agreedPowerKVA ?? ''}
              autoWidth={true}
              MenuProps={{
                classes: { list: styles.selectMenu },
              }}
            >
              <StandardSelectOption
                key="default"
                value=""
                menuItemClass={styles.menuItem}
                style={{ display: 'none' }}
              >
                -
              </StandardSelectOption>
              {kvaList.map((item) => {
                return (
                  <StandardSelectOption
                    key={item.value}
                    value={item.value}
                    menuItemClass={styles.menuItem}
                  >
                    {item.text}
                  </StandardSelectOption>
                );
              })}
            </ControlledSelect>
            <p className={styles.helperText}>
              {t(`newClient:meterDetails.agreedPowerKVA-info`)}
            </p>
          </>
        )}
      {!isGasProgram && (
        <>
          {defaultValues?.programType === `${programTypes.business}` && (
            <ActivityCodeInput
              register={register}
              errors={errors}
              name={`${baseObjectName}.profession`}
              label={t(`newClient:meterDetails.profession`)}
              value={defaultValues[baseObjectName]?.profession ?? ''}
              onActivityChange={onActivityChange}
            />
          )}
        </>
      )}
    </article>
  );
};

export default MeterDetails;
