import axios from 'axios';
import { requestInterceptor, responseInterceptor } from './interceptors';

//import { logServiceFailure } from 'utils/product/logs';

export const fetcher = (url) => api.get(url).then((res) => res.data);
export const postFetcher = (url, params) => {
  return api.post(url, params).then((res) => res.data);
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Ocp-Apim-Subscription-Key':
      process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
  },
});

// this can be used to hold services, which fail when Authorization is added on the headers
// eg. user tries to confirm an email while logged in to another account
const unauthorizedServices = ['/user/confirm'];

api.interceptors.request.use(
  function (config) {
    return requestInterceptor(config, unauthorizedServices);
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    return responseInterceptor(error, api);
  }
);

export default api;
