const requestStatuses = {
  draft: 1,
  completed: 2,
  canceled: 3,
  pending: 4,
  wrong: 5,
};

const requestCasesStatuses = {
  pending: 0,
  completed: 1,
  canceled: 2,
};

const acceptedRequestStatuses = [
  requestStatuses.completed,
  requestStatuses.pending,
  requestStatuses.draft,
];

const linksForRequestsCategories = {
  5: '/personal-data-change',
  1: '/billing-questions',
  24: '/moving-request',
  18: '/usage-change-request',
};

const requestTypes = {
  personalDataChange: 5,
  billingQuestions: 1,
  movingRequest: 24,
  powerDisconnect: 23,
  newGasSupply: 16,
  usageChange: 18,
  enaDeactivation: 21,
  programChange: 22,
  electricityProgramChange: 'dc_30',
  electricityProgramChangeConfigSide: 30,
  governmentProgramObjection: 25,
  governmentProgramObjectionChatBox: 26,
};

const channelElementTypes = {
  information: 'ΕΝΗΜΕΡΩΣΗ',
  multipleSupplies: 'ΠΟΛΛΑΠΛΕΣ ΠΑΡΟΧΕΣ',
  complaintsOfNonInfo: 'ΠΑΡΑΠΟΝΑ ΓΙΑ ΜΗ ΕΝΗΜΕΡΩΣΗ',
};

const channelCategoryTypes = {
  contract: 'ΣΥΜΒΟΛΑΙΟ',
  changePackageRequest: 'Αίτημα Αλλαγής Πακέτου',
};

const channelSubCategoryTypes = {
  newMeasures: 'ΝΕΑ ΜΕΤΡΑ',
};

export {
  channelSubCategoryTypes,
  channelCategoryTypes,
  channelElementTypes,
  requestStatuses,
  requestCasesStatuses,
  acceptedRequestStatuses,
  linksForRequestsCategories,
  requestTypes,
};
