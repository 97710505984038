import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TwoColumnsLayout from 'layouts/TwoColumnsLayout';
import RequestsSidebar from './RequestsSidebar';
import RequestsMain from './RequestsMain';
import CreateRequestWrapper from 'components/Requests/Shared/CreateRequestWrapper';

import { linksForRequestsCategories } from 'utils/product/enums/requests';
import { getObjectEntries } from 'utils/objectUtils';

const Requests = () => {
  const { t } = useTranslation(['requests']);
  const location = useLocation();

  const { meter, keepSameProgram } = location.state || {};

  return (
    <TwoColumnsLayout
      head={{
        title: t('requests:requests.requests'),
        metaTags: [{ name: 'title', content: t('requests:requests.requests') }],
      }}
      main={
        <section>
          <RequestsMain />
          <Routes>
            {getObjectEntries(linksForRequestsCategories).map(
              ([key, value]) => (
                <Route
                  path={value}
                  key={`request_${key}`}
                  element={
                    <CreateRequestWrapper
                      type={parseInt(key)}
                      meter={meter}
                      keepSameProgram={keepSameProgram}
                    />
                  }
                />
              )
            )}
          </Routes>
        </section>
      }
      right={<RequestsSidebar />}
    />
  );
};

export default Requests;
