import React from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Skeletons/Loader';
import useSettlement from 'data/useSettlement';
import { useAuthUser } from 'utils/AuthUser';

import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import SettlementInstallments from './SettlementInstallments';

import styles from './SettlementOverviewDialog.module.scss';

const SettlementOverviewDialog = ({
  open,
  closeDialog,
  meter,
  mutateMeters = () => {},
}) => {
  const { t } = useTranslation(['translation', 'bills']);
  const { accountId } = useAuthUser();
  const {
    loading,
    installments,
    mutate: mutateSettlements,
  } = useSettlement(accountId, meter?.Id);

  const handleOnSuccess = () => {
    mutateSettlements();
    mutateMeters();
  };

  return (
    <DialogOverlay
      open={open}
      closeDialog={closeDialog}
      dialogClass={styles.dialog}
      contentClass={styles.dialogContent}
      closeButtonClass={styles.closeButton}
      dialogHeader={t('bills:settlements.settlement')}
      scroll="body"
    >
      <Loader show={loading}>
        <SettlementInstallments
          meter={meter}
          installments={installments}
          onPaymentSuccess={handleOnSuccess}
        />
      </Loader>
    </DialogOverlay>
  );
};

export default SettlementOverviewDialog;
