export const offerVisibilityTypes = {
  default: 'BOTH',
  loyalty: 'LOYALTY',
  nonLoyalty: 'NON_LOYALTY',
};

export const collectionOptionTypes = {
  REGISTER_LOYALTY: 'REGISTER_LOYALTY',
  BILL_PAYMENT: 'BILL_PAYMENT',
  BILL_PAYMENT_DUE: 'BILL_PAYMENT_DUE',
  REGISTER_CONSUMPTION: 'REGISTER_CONSUMPTION',
  E_BILL: 'E_BILL',
  PARTY_GIFTS: 'PARTY_GIFTS',
  SHOP: 'SHOP',
  DEBIT_PAYMENT: 'DIRECT_DEBIT_PAYMENT',
};

export const redemptionOptionsTypes = {
  CONTEST: 'CONTEST',
  OFFER: 'OFFER',
  GAME: 'GAME',
};

export const cmsSchemas = {
  forYou: 'for_you',
  contests: 'contests',
  games: 'mini_games',
  offers: 'redemption_options',
  collectionOptions: 'collection_options',
  terms: 'terms',
};

export const notificationsTypes = {
  CMS_RETRIEVAL: 'CMS_RETRIEVAL',
  DEEP_LINKING: 'DEEP_LINKING',
};
export const buttonActions = {
  default: 'DEFAULT',
  internalUrl: 'UNIVERSAL_LINK',
  custom: 'CUSTOM',
  externalUrlInApp: 'WEB_IN_APP',
  externalUrl: 'WEB_BROWSER',
};

export const promotionTypes = {
  loyaltyRegistration: 'LOYALTY_REGISTRATION',
};

export const statusTypes = {
  active: 'ACTIVE',
  inactive: 'INACTIVE',
  expired: 'EXPIRED',
};
