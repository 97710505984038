import React from 'react';
import { useTranslation } from 'react-i18next';

import TwoColumnsLayout from 'layouts/TwoColumnsLayout';
import RightSidebar from './RightSidebar';
import MainArea from './MainArea';
import useAccountMeters from 'data/useAccountMeters';

const Ena = () => {
  const { t } = useTranslation(['ena']);
  const { meters, loading: metersLoading } = useAccountMeters({
    statusesElectricity: [],
    statusesGas: [],
  });
  const metersWithEna = meters
    ? meters.filter(
        (x) => x.IsActive && x.solarisInfo && x.solarisInfo.SolarisId !== null
      )
    : [];
  return (
    <TwoColumnsLayout
      head={{
        title: t('ena:home.title'),
        metaTags: [{ name: 'title', content: t('ena:home.title') }],
      }}
      main={
        <MainArea metersWithEna={metersWithEna} metersLoading={metersLoading} />
      }
      right={<RightSidebar meters={meters} metersLoading={metersLoading} />}
    />
  );
};

export default Ena;
