import React from 'react';

import useCounter from 'hooks/useCounter';

const BubbleContext = React.createContext();

function BubbleProvider({ children }) {
  const maxSlideIndex = 4;
  const defaultSlideIndex = 1;

  const { number: currentSlideIndex, increaseNumber: nextIndex } = useCounter(
    defaultSlideIndex,
    maxSlideIndex
  );

  return (
    <>
      <BubbleContext.Provider
        value={{
          currentSlideIndex,
          nextIndex,
        }}
      >
        {children}
      </BubbleContext.Provider>
    </>
  );
}
export { BubbleContext, BubbleProvider };
