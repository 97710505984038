export const energyPointsId = process.env.REACT_APP_LOYALTY_ENERGYPOINTS_ID;

export const loyaltyPaths = {
  landing: 'my-vibes',
  eBill: 'activate-ebill',
  prompt: 'whats-new',
  promptDialog: 'vibes',
  consent: 'consent',
  faqs: 'vibes/faqs',
  questionnaire: 'registration',
  offerFlow: 'registration',
  pendingBills: 'pending-bills',
  debitPayment: 'debit-payment',
  consumptions: 'consumptions',
};
