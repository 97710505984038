import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthUser } from 'utils/AuthUser';

import { useDiscovery } from 'data/useDiscovery';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import FullWidthLayout from 'layouts/FullWidthLayout';
import { useVibes } from 'data/useRewardSchemeAccounts';
import { getDiscoveryPageResults } from 'utils/product/offerUtils';
import IconText from 'components/IconText/IconText';
import DiscoveryItemOverview from 'components/Discovery/ItemOverview';
import OffersSlider from './OffersSlider';
import discoveryItemTypes from 'utils/product/enums/discoveryItemTypes';
import offerEntryPoints from 'utils/product/enums/offerEntryPoints';

import styles from './LoyaltyOffers.module.scss';

const LoyaltyOffers = () => {
  const { t, i18n } = useTranslation(['offers']);
  const { loyaltyCustomerId, isLoyaltyCustomer } = useAuthUser();
  const baseName = 'offers:general';
  const language = i18n.language;
  const { data: vibes, loading: vibesLoading } = useVibes(
    loyaltyCustomerId,
    language,
    !isLoyaltyCustomer
  );

  const { data: results, loading } = useDiscovery(language);

  const discoveryItems = getDiscoveryPageResults(results, vibes);

  return (
    <FullWidthLayout
      mainClass={styles.pageColumn}
      head={{
        title: t(`${baseName}.my-offers`),
        metaTags: [
          {
            name: 'title',
            content: t(`${baseName}.my-offers`),
          },
        ],
      }}
    >
      <section>
        <WidgetSkeleton
          heightInPixels={548}
          distance="s"
          show={loading || vibesLoading}
        >
          {discoveryItems.map(
            (section) =>
              section.items.length > 0 && (
                <section
                  className={
                    section.key === discoveryItemTypes.game
                      ? styles['games-section']
                      : styles.section
                  }
                  key={section.key}
                >
                  {section.key !== discoveryItemTypes.game && (
                    <IconText
                      weight="bold"
                      Icon={
                        section.vibe ? (
                          <img
                            src={section.vibe.imageUrl}
                            alt={section.key}
                            width={32}
                          />
                        ) : null
                      }
                      content={
                        section.key === discoveryItemTypes.default
                          ? t(`offers:general.default`)
                          : section.key
                      }
                      containerClass={styles.header}
                    />
                  )}
                  <OffersSlider
                    sliderClass={
                      section.key === discoveryItemTypes.game
                        ? 'games-slider'
                        : ''
                    }
                  >
                    {section.items.map((result) => (
                      <section className={styles.itemWrapper} key={result.id}>
                        <DiscoveryItemOverview
                          item={result}
                          hideVibeLabel={true}
                          page={offerEntryPoints.loyaltyList}
                        />
                      </section>
                    ))}
                  </OffersSlider>
                </section>
              )
          )}
        </WidgetSkeleton>
      </section>
    </FullWidthLayout>
  );
};

export default LoyaltyOffers;
