import React from 'react';
import { useTranslation } from 'react-i18next';

import useAccountMeters from 'data/useAccountMeters';
import useError from 'hooks/useError';
import Helmet from 'utils/Helmet';
import { LocalisedErrorBox } from 'components/MessageBox/MessageBox';
import MyMetersContent from 'components/Management/MyMetersContent';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import { ReactComponent as SuppliesIcon } from 'assets/icons/ConsumptionsHistory.svg';
import EmptyStateWidget from 'components/Widget/EmptyStateWidget';

const MyMeters = () => {
  const { t } = useTranslation(['management', 'translation']);
  const baseName = 'management:managementRightSidebar';

  const {
    meters,
    loading: metersLoading,
    mutate,
  } = useAccountMeters({ onlyActive: true });

  const { error, handleError, clearError } = useError();

  return (
    <>
      <Helmet
        head={{
          title: t(`${baseName}.supplies`),
          metaTags: [{ name: 'title', content: t(`${baseName}.supplies`) }],
        }}
      />
      <WidgetSkeleton
        show={metersLoading}
        heightInPixels={294}
        title={<WidgetHeader title={t(`${baseName}.supplies`)} />}
      >
        <Widget
          background="transparent"
          title={<WidgetHeader title={t(`${baseName}.supplies`)} />}
        >
          <LocalisedErrorBox
            open={error !== null}
            closeBox={clearError}
            {...(error || {})}
            distanceTop=""
          />
          {meters.length === 0 && (
            <EmptyStateWidget
              icon={<SuppliesIcon width="72px" height="72px" />}
              text={t('management:myMeters.empty-state')}
            />
          )}
          <MyMetersContent
            meters={meters}
            handleError={handleError}
            clearError={clearError}
            mutate={mutate}
          />
        </Widget>
      </WidgetSkeleton>
    </>
  );
};

export default MyMeters;
