import { replaceAll } from 'utils/stringUtils';

const getQueryParams = (data = '') => {
  const queryString = data.indexOf('?') > -1 ? data.split('?')[1] : data;
  const url = replaceAll(replaceAll(queryString, '+', '%2B'), '@', '%40');

  const params = url.split('&');

  const obj = params.reduce((accumulator, current) => {
    const _parts = current.split('=');
    if (_parts.length > 0) {
      const value = _parts.length > 1 ? _parts[1] : '';
      accumulator[_parts[0]] = value
        ? decodeURI(replaceAll(replaceAll(_parts[1], '%2B', '+'), '%40', '@'))
        : '';
    }
    return accumulator;
  }, {});
  return obj;
};

const isProductionUrl = (value) => {
  return /^(https:\/\/)?my\.heron\.gr/.test(value);
};

const isStageUrl = (value) => {
  return /^(https:\/\/)?myheron\.thinkdesquared\.com/.test(value);
};

const isTestUrl = (value) => {
  const patterns = [
    /^(http:\/\/)?localhost:/,
    /^(https:\/\/)?gray-forest-05a16c203\.azurestaticapps\.net/,
    /^(https:\/\/)?gray-forest-05a16c203(?:-\d+)?\.westeurope\.azurestaticapps\.net/,
    /^(https:\/\/)?delightful-sea-0641b9b03\.azurestaticapps\.net/,
    /^(https:\/\/)?delightful-sea-0641b9b03(?:-\d+)?\.westeurope\.azurestaticapps\.net/,
    /^(https:\/\/)?witty-island-0d5649403\.azurestaticapps\.net/,
    /^(https:\/\/)?witty-island-0d5649403(?:-\d+)?\.westeurope\.azurestaticapps\.net/,
  ];

  const matchingUrl = patterns.filter((pattern) => pattern.test(value));

  return matchingUrl.length > 0;
};

export { getQueryParams, isTestUrl, isStageUrl, isProductionUrl };
