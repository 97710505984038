import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import AccountSelector from 'components/AccountSelector/AccountSelector';
import { trackHomeEvent } from 'utils/product/tracking/events';
import { getTopbarName } from 'utils/product/accountUtils';
import { useAuthUser } from 'utils/AuthUser';
import IconText from 'components/IconText/IconText';
import { ReactComponent as ShoppingBagIcon } from 'assets/icons/ShoppingBagIcon.svg';
import { ReactComponent as ShoppingBagIconFilled } from 'assets/icons/ShoppingBagIconFilled.svg';
import { ReactComponent as ManagementIcon } from 'assets/icons/ManagementIcon.svg';
import { ReactComponent as ManagementIconFilled } from 'assets/icons/ManagementIconFilled.svg';
import AuthorizedHeaderAvatar from 'components/AuthorizedHeaderContent/AuthorizedHeaderAvatar';
import BubbleContent from 'components/HowToWizardBubble/BubbleContent';

import styles from './AuthorizedHeaderContentWrapper.module.scss';

const HeaderNavIcon = ({
  icon,
  iconFilled,
  isActive,
  showBubble,
  name,
  step,
  wizardType,
}) => {
  const { t } = useTranslation(['header', 'onboarding']);
  return (
    <div className={styles.iconContainer}>
      {!isActive ? (
        <>
          {icon}
          {showBubble && (
            <BubbleContent
              step={step}
              side="bottom"
              header={`${t(`header:${name}`)}!`}
              content={t(`onboarding:${wizardType}.${name}`)}
            />
          )}
        </>
      ) : (
        iconFilled
      )}
    </div>
  );
};

const AuthorizedHeaderContentWrapper = ({
  linksDisabled = false,
  showBubbleContent = false,
  wizardType,
}) => {
  const { selectedAccount, isLoyaltyCustomer } = useAuthUser();
  const { t } = useTranslation(['header', 'onboarding']);
  const profileIconTextContent = getTopbarName(selectedAccount);

  const handleLinkClick = (event, eventAction) => {
    if (!linksDisabled) trackHomeEvent(eventAction);

    return linksDisabled && event.preventDefault();
  };

  const routesLinks = {
    shop: '/shop',
    management: '/management',
    profile: '/profile',
  };

  return (
    <section className={styles.container}>
      <NavLink
        to={routesLinks['shop']}
        className={styles.link}
        onClick={(event) => {
          handleLinkClick(event, 'home_shop_selected');
        }}
        aria-label={t('header:shop')}
      >
        {({ isActive }) => (
          <IconText
            contentClass={styles.iconTextContentWrapper}
            iconClass={styles['icon']}
            Icon={
              <HeaderNavIcon
                isActive={isActive}
                icon={<ShoppingBagIcon alt="shop" />}
                iconFilled={<ShoppingBagIconFilled alt="shop" />}
                name="shop"
                step={3}
                showBubble={showBubbleContent}
                wizardType={wizardType}
              />
            }
            content={t('header:shop')}
            gap="m"
          />
        )}
      </NavLink>
      <NavLink
        to={routesLinks['management']}
        className={styles.link}
        onClick={(event) => {
          handleLinkClick(event, 'home_management_selected');
        }}
        aria-label={t('header:management')}
      >
        {({ isActive }) => (
          <IconText
            contentClass={styles.iconTextContentWrapper}
            iconClass={styles['icon']}
            Icon={
              <HeaderNavIcon
                isActive={isActive}
                icon={<ManagementIcon alt="management" />}
                iconFilled={<ManagementIconFilled alt="management" />}
                name="management"
                step={2}
                showBubble={showBubbleContent}
                wizardType={wizardType}
              />
            }
            content={t('header:management')}
            gap="m"
          />
        )}
      </NavLink>
      <NavLink
        to={routesLinks['profile']}
        className={styles.link}
        onClick={(event) => {
          handleLinkClick(event, 'home_profile_selected');
        }}
        aria-label="profile"
      >
        {({ isActive }) => (
          <IconText
            contentClass={`${styles.iconTextContentWrapper} ${
              isLoyaltyCustomer ? styles.loyaltyText : ''
            }`}
            iconClass={
              selectedAccount && profileIconTextContent ? styles['icon'] : ' '
            }
            Icon={
              <HeaderNavIcon
                isActive={isActive}
                icon={<AuthorizedHeaderAvatar />}
                iconFilled={<AuthorizedHeaderAvatar filled />}
                name="profile"
                step={1}
                showBubble={showBubbleContent}
                wizardType={wizardType}
              />
            }
            content={profileIconTextContent ? profileIconTextContent : ''}
            gap="m"
          />
        )}
      </NavLink>
      <AccountSelector />
    </section>
  );
};

export default AuthorizedHeaderContentWrapper;
