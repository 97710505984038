import React from 'react';

import { ReactComponent as Logo } from 'assets/icons/HeronIcon.svg';
import { ReactComponent as WarningTriangleIcon } from 'assets/icons/WarningTriangleIcon.svg';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/InfoCircleIcon.svg';

const HeronLogo = ({ ...rest }) => <Logo {...rest} />;

const HeronWarningTriangleIcon = ({ ...rest }) => (
  <WarningTriangleIcon {...rest} />
);

const HeronInfoCircleIcon = ({ ...rest }) => <InfoCircleIcon {...rest} />;

const HeronNotificationsIcon = ({ iconType, ...rest }) => {
  switch (iconType?.toLowerCase()) {
    case 'promotional':
      return <HeronLogo {...rest} />;
    case 'alert':
      return <HeronWarningTriangleIcon {...rest} />;
    case 'informative':
      return <HeronInfoCircleIcon {...rest} />;

    default:
      return <HeronLogo {...rest} />;
  }
};

export default HeronNotificationsIcon;
