import {
  newClientSteps,
  existingClientSteps,
} from 'utils/product/onboardingConfig';
import energyTypes from 'utils/product/enums/shopEnergyTypes';
import programTypes from 'utils/product/enums/programTypes';
import accountCategories from 'utils/product/enums/accountCategories';

/* these steps stay the same despite user selections */
const staticSteps = (steps) => ({
  [steps.whatsNew]: steps.energyType,
  [steps.energyType]: steps.programType,
  [steps.energyShop]: steps.energyProgram,
  [steps.gasShop]: steps.gasProgram,
  [steps.companyDetails]: steps.primaryContact,
  [steps.meterDetails]: steps.eBill,
  [steps.gasMeterDetails]: steps.gasEBill,
  [steps.contactPreferences]: steps.identification,
  [steps.identification]: steps.documents,
  [steps.documents]: steps.completed,
});

/* these steps change based on user selections */

const stepsMappingElectricity = (steps) => ({
  [steps.customerCategory]: steps.electricity,
  [steps.businessCustomerCategory]: steps.electricity,
  [steps.electricity]: steps.electricityInfo,
});

const stepsFormElectricity = (steps, isShop = false) => ({
  [steps.customerDetails]: steps.meterDetails,
  [steps.primaryContact]: steps.meterDetails,
  [steps.eBill]: isShop ? steps.identification : steps.contactPreferences,
});

const stepsMappingGas = (steps) => ({
  [steps.customerCategory]: steps.gas,
  [steps.businessCustomerCategory]: steps.gas,
  [steps.electricity]: steps.electricityInfo,
  [steps.gas]: steps.gasInfo,
  [steps.newGasSupply]: steps.newGasSupplySuccess,
  [steps.newGasSupplySuccess]: steps.login,
});

const stepsFormGas = (steps, isShop = false) => ({
  [steps.customerDetails]: steps.gasMeterDetails,
  [steps.primaryContact]: steps.gasMeterDetails,
  [steps.gasEBill]: steps.userCapacity,
  [steps.userCapacity]: isShop
    ? steps.identification
    : steps.contactPreferences,
});

const stepsMappingCombined = (steps) => ({
  [steps.customerCategory]: steps.electricity,
  [steps.businessCustomerCategory]: steps.electricity,
  [steps.electricity]: steps.gas,
  [steps.newGasSupply]: steps.newGasSupplySuccess,
  [steps.newGasSupplySuccess]: steps.electricityInfo,
});

const stepsFormCombined = (steps, isShop = false) => ({
  [steps.customerDetails]: steps.meterDetails,
  [steps.primaryContact]: steps.meterDetails,
  [steps.eBill]: steps.gasMeterDetails,
  [steps.gasEBill]: steps.userCapacity,
  [steps.userCapacity]: isShop
    ? steps.identification
    : steps.contactPreferences,
});

const stepsMappingCompany = (steps) => {
  return {
    [steps.electricityInfo]: steps.companyDetails,
    [steps.gasInfo]: steps.companyDetails,
    [steps.combinedInfo]: steps.companyDetails,
  };
};

const stepsMappingPrivate = (steps, isShop = false) => ({
  [steps.electricityInfo]: steps.customerDetails,
  [steps.gasInfo]: steps.customerDetails,
  [steps.combinedInfo]: steps.customerDetails,
});

const getEnergyTypeDependentSteps = (
  steps,
  energyType,
  hasGasSupply,
  isShop = false
) => {
  return {
    [steps.electricitySupply]:
      energyType === energyTypes.combined ? steps.energyShop : steps.gas,
    ...(energyType === energyTypes.combined
      ? stepsMappingCombined(steps)
      : energyType === energyTypes.gas
      ? stepsMappingGas(steps)
      : stepsMappingElectricity(steps)),
    ...(energyType === energyTypes.combined
      ? hasGasSupply === 'true'
        ? stepsFormCombined(steps, isShop)
        : stepsFormElectricity(steps, isShop)
      : energyType === energyTypes.gas
      ? stepsFormGas(steps, isShop)
      : stepsFormElectricity(steps, isShop)),
  };
};

const getCustomerCategoryDependentSteps = (
  steps,
  customerCategory,
  isShop = false
) => {
  return isShop
    ? {
        [steps.electricityInfo]: steps.meterDetails,
        [steps.gasInfo]: steps.gasMeterDetails,
        [steps.combinedInfo]: steps.meterDetails,
      }
    : customerCategory === `${accountCategories.company}`
    ? stepsMappingCompany(steps)
    : stepsMappingPrivate(steps);
};

const getProgramTypeDependentSteps = (
  steps,
  programType,
  energyType,
  isShop = false
) => {
  return {
    [steps.gasProgram]: isShop
      ? energyType === energyTypes.combined
        ? steps.energyShop
        : steps.gas
      : programType === programTypes.home
      ? steps.customerCategory
      : steps.businessCustomerCategory,
  };
};

const getComplexSteps = (
  steps,
  programType,
  energyType,
  hasGasSupply,
  isShop = false
) => {
  return {
    [steps.programType]:
      programType === programTypes.home &&
      (energyType === energyTypes.gas || energyType === energyTypes.combined)
        ? steps.shared
        : energyType === energyTypes.gas
        ? steps.gasShop
        : steps.energyShop,
    [steps.shared]:
      energyType === energyTypes.gas ? steps.gasShop : steps.energyShop,
    [steps.energyProgram]: isShop
      ? steps.electricity
      : energyType === energyTypes.combined
      ? steps.gasShop
      : programType === programTypes.home
      ? steps.customerCategory
      : steps.businessCustomerCategory,
    [steps.gas]:
      hasGasSupply === 'false'
        ? steps.newGasSupply
        : energyType === energyTypes.combined
        ? steps.combinedInfo
        : steps.gasInfo,
  };
};

export const getNextSteps = ({
  customerCategory,
  programType,
  energyType,
  gas: hasGasSupply,
  isShop = false,
}) => {
  const steps = isShop ? existingClientSteps : newClientSteps;
  const _mapping = {
    ...staticSteps(steps),
    ...getEnergyTypeDependentSteps(steps, energyType, hasGasSupply, isShop),
    ...getCustomerCategoryDependentSteps(steps, customerCategory, isShop),
    ...getProgramTypeDependentSteps(steps, programType, energyType, isShop),
    ...getComplexSteps(steps, programType, energyType, hasGasSupply, isShop),
  };

  return _mapping;
};
