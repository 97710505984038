import React from 'react';
import { useTranslation } from 'react-i18next';

import useContacts from 'data/useContacts';
import Helmet from 'utils/Helmet';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import PersonalInfoBody from './PersonalInfoBody';
import Widget from 'components/Widget/Widget';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';

const PersonalInfo = () => {
  const { t } = useTranslation(['management']);
  const baseName = 'management:managementRightSidebar';

  const { primaryContact, loading: contactsLoading } = useContacts();

  return (
    <>
      <Helmet
        head={{
          title: t(`${baseName}.contract-info`),
          metaTags: [
            { name: 'title', content: t(`${baseName}.contract-info`) },
          ],
        }}
      />
      <WidgetSkeleton
        show={contactsLoading}
        heightInPixels={528}
        title={<WidgetHeader title={t(`${baseName}.contract-info`)} />}
      >
        <Widget
          background="transparent"
          title={<WidgetHeader title={t(`${baseName}.contract-info`)} />}
        >
          {primaryContact && (
            <Widget size="m">
              <PersonalInfoBody
                primaryContact={primaryContact}
                key={primaryContact?.Id}
              />
            </Widget>
          )}
        </Widget>
      </WidgetSkeleton>
    </>
  );
};

export default PersonalInfo;
