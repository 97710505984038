import React from 'react';
import { ReactComponent as EnergyIcon } from 'assets/icons/LoyaltyEnergyIcon.svg';
import { formatMoney } from 'utils/moneyUtils';
import IconText from 'components/IconText/IconText';

import styles from './EnergyPointsLabel.module.scss';

const EnergyPointsLabel = ({ points }) => {
  return (
    <IconText
      Icon={<EnergyIcon width={24} height={24} />}
      content={formatMoney(Math.floor(points), false)}
      contentClass={styles.label}
      gap={'xs'}
      weight="bold"
    />
  );
};

export default EnergyPointsLabel;
