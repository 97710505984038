import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/dateUtils';
import { dateFormats } from 'utils/product/config';
import { SecondaryButton } from 'components/Buttons/Buttons';

import styles from './RegisterOwnReadingsWidget.module.scss';

const RegisterOwnReadingsAgain = ({ allowedDateTo, registerReading }) => {
  const { t } = useTranslation(['consumptions']);

  return (
    <>
      <p className={styles.text}>
        {t('consumptions:overview.reregister-text', {
          date: formatDate(allowedDateTo, dateFormats.dayMonthNoDayPad),
        })}
      </p>
      <div className={styles.buttonContainer}>
        <SecondaryButton onClick={registerReading}>
          {t('consumptions:mms')}
        </SecondaryButton>
      </div>
    </>
  );
};

export default RegisterOwnReadingsAgain;
