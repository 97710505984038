import React from 'react';
import CmsHtmlField from 'components/CmsHtmlField/CmsHtmlField';

import styles from './ProgramPriviledges.module.scss';

const ProgramPriviledges = ({ additionalPriviledges }) => {
  return (
    <section>
      <h2 className={styles.title}>{additionalPriviledges.title}</h2>
      {additionalPriviledges.privileges && (
        <CmsHtmlField
          className={styles.privileges}
          value={additionalPriviledges.privileges}
        />
      )}
    </section>
  );
};
export default ProgramPriviledges;
