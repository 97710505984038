import React from 'react';

import useDialog from 'hooks/useDialog';
import useUniqueFlow from 'hooks/useUniqueFlow';

import LoyaltyPrompt from './LoyaltyPrompt';

const LoyaltyWhatsNew = ({ children, ...rest }) => {
  const { incrementStep } = useUniqueFlow();
  const dialog = useDialog(true);

  return (
    <LoyaltyPrompt
      open={dialog.isOpen}
      closeDialog={dialog.close}
      onClose={() => {
        incrementStep();
      }}
    />
  );
};

const LoyaltyInfoDialog = ({ children, ...rest }) => {
  const dialog = useDialog(true);

  return <LoyaltyPrompt open={dialog.isOpen} closeDialog={dialog.close} />;
};

export { LoyaltyWhatsNew, LoyaltyInfoDialog };
