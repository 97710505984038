import React from 'react';
import { useTranslation } from 'react-i18next';

import TwoColumnsLayout from 'layouts/TwoColumnsLayout';
import RightSidebar from './RightSidebar';
import MainArea from './MainArea';
import { useStoreLocations } from 'data/useStoreLocations';

const Stores = () => {
  const { t } = useTranslation(['homepage']);
  const { loading, data: markers } = useStoreLocations();

  return (
    <TwoColumnsLayout
      head={{
        title: t('homepage:physical-stores.title'),
        metaTags: [
          { name: 'title', content: t('homepage:physical-stores.title') },
        ],
      }}
      main={<MainArea loading={loading} markers={markers} />}
      right={<RightSidebar />}
    />
  );
};

export default Stores;
