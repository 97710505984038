import React, { useState } from 'react';
import { ReactComponent as LoyaltyDebitIcon } from 'assets/icons/LoyaltyDebitIcon.svg';
import { PromptDialog } from 'components/PromptDialogs/PromptDialog';
import { useTranslation } from 'react-i18next';
import { useAuthUser } from 'utils/AuthUser';
import { redeemRedemptionOption } from 'api/loyalty';
import { getRedemptionResult } from 'utils/product/loyalty/redemptionUtils';
import { trackContestsEvent } from 'utils/product/tracking/events';

import styles from './ParticipationConfirm.module.scss';

const ParticipationConfirm = ({
  contest,
  open,
  closeDialog,
  pointsToRedeem,
  participations,
  onSuccess,
  onError,
}) => {
  const { t } = useTranslation(['translation', 'offers']);
  const { accountId } = useAuthUser();
  const [isLoading, setIsLoading] = useState(false);

  const { waveId } = contest;

  const clickHandler = () => {
    setIsLoading(true);
    closeDialog();
    trackContestsEvent(
      'contest_participate_selected_confirmation_continue',
      '',
      {
        contest_name: contest?.title,
      },
    );

    const _ids = Array(participations).fill(waveId);
    const postData = {
      customer: { customerId: accountId },
      touchpoint: 'WEB',
      currencyRedemptionOptionIds: _ids,
    };
    setIsLoading(true);
    redeemRedemptionOption(postData)
      .then((result) => {
        closeDialog();
        const _data = getRedemptionResult(result?.data);
        onSuccess(_data);
      })
      .catch((error) => {
        closeDialog();
        onError(error);
      })
      .then(() => {
        setIsLoading(false);
      });
  };
  return (
    <PromptDialog
      icon={<LoyaltyDebitIcon />}
      open={open}
      closeDialog={closeDialog}
      primaryActionText={t(`translation:continue`)}
      primaryActionHandler={clickHandler}
      primaryActionLoading={isLoading}
      showSecondaryAction={true}
      secondaryActionText={t(`translation:cancel`)}
      secondaryActionHandler={closeDialog}
      header={t(`offers:acquisition-flow.confirm-title`)}
    >
      <p>
        {t(`offers:contest-participation.confirm`, {
          pointsToRedeem,
          count: participations,
        })}
      </p>
      <p className={styles.subtext}>
        {t(`offers:acquisition-flow.confirm-message`)}
      </p>
    </PromptDialog>
  );
};

export default ParticipationConfirm;
