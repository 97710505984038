import React from 'react';
import { useTranslation } from 'react-i18next';

import LoginWidget from 'pages/onboarding/LoginWidget';
import OnboardingLayout from 'layouts/OnboardingLayout';
import { ReactComponent as MediumCheckedIcon } from 'assets/icons/CheckGreen.svg';

import styles from './UserCreated.module.scss';

const UserCreated = () => {
  const { t } = useTranslation(['onboarding', 'translation']);
  const baseName = 'onboarding:createUserContent';

  return (
    <OnboardingLayout>
      <LoginWidget containerClass={styles.widgetContainer}>
        <MediumCheckedIcon width={72} height={72} />
        <h1 className={styles.header}>
          {t('translation:request-recorded-success')}
        </h1>
        <p className={styles.paragraph}>{t(`${baseName}.receive-mail`)}</p>
      </LoginWidget>
    </OnboardingLayout>
  );
};

export default UserCreated;
