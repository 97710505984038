import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as VoucherIcon } from 'assets/icons/VoucherIcon.svg';
import CouponWidget from './CouponWidget';
import EmptyStateWidget from 'components/Widget/EmptyStateWidget';
import useDeviceDetect from 'hooks/useDeviceDetect';

import styles from './CouponTab.module.scss';

const CouponTab = ({ data, customerId }) => {
  const { t } = useTranslation(['profile']);
  const { isIos, isAndroid, isPC } = useDeviceDetect();

  return (
    <div className={styles.tabContainer}>
      {data?.map((item) => (
        <CouponWidget
          data={item}
          key={item.code}
          customerId={customerId}
          isIos={isIos}
          isAndroid={isAndroid}
          isPC={isPC}
        />
      ))}
      {data?.length === 0 && (
        <EmptyStateWidget
          icon={<VoucherIcon width="72" height="72" alt="" />}
          text={t('profile:my-coupons.empty-state')}
          isFullWidth
        />
      )}
    </div>
  );
};

export default CouponTab;
