import React from 'react';
import { Trans } from 'react-i18next';
import { ReactComponent as EnergyIcon } from 'assets/icons/LoyaltyEnergyIcon.svg';
import IconText from 'components/IconText/IconText';
import YellowsWidget from './YellowsWidget';
import { formatNumber } from 'utils/moneyUtils';
import RewardsWidget from 'components/Loyalty/RewardsWidget';

import styles from './PaymentRewards.module.scss';

const PaymentRewards = ({
  loading,
  yellows,
  points,
  billPoints,
  bonusPoints,
  distance,
}) => {
  const show = points > 0 || yellows > 0;
  return (
    <RewardsWidget
      loading={loading}
      points={points}
      show={show}
      distance={distance}
    >
      {billPoints > 0 && bonusPoints > 0 && (
        <>
          <IconText
            containerClass={styles.iconSection}
            contentClass={styles.explanation}
            iconClass={styles.explanationIcon}
            content={
              <Trans
                i18nKey={'bills:payment-flow.loyalty-points'}
                values={{
                  amount: formatNumber(billPoints),
                }}
                components={{
                  bold: <b />,
                  yellow: <span className={styles['warning-text']}></span>,
                }}
              />
            }
            Icon={<EnergyIcon width={20} height={20} />}
            gap="xs"
          />
          <IconText
            containerClass={styles['iconSection--bottom']}
            contentClass={styles.explanation}
            iconClass={styles.explanationIcon}
            content={
              <Trans
                i18nKey={'bills:payment-flow.bonus-loyalty-points'}
                values={{
                  points: formatNumber(bonusPoints),
                }}
                components={{
                  bold: <b></b>,
                  yellow: <span className={styles['warning-text']}></span>,
                }}
              />
            }
            Icon={<EnergyIcon width={20} height={20} />}
            gap="xs"
          />
        </>
      )}
      <YellowsWidget amount={yellows} distance="" />
    </RewardsWidget>
  );
};

export default PaymentRewards;
