import React from 'react';

import { getConsumptionState } from 'utils/product/consumptionUtils';
import consumptionStates from 'utils/product/enums/consumptionStates';

import Widget from 'components/Widget/Widget';
import MeterLabelWrapper from 'components/Labels/MeterLabelWrapper';
import ConsumptionPrediction from './ConsumptionPrediction';
import ConsumptionCountAndWin from './ConsumptionCountAndWin';
import ConsumptionClearing from './ConsumptionClearing';
import ConsumptionClearingGas from './ConsumptionClearingGas';
import MVConsumptions from 'components/Consumptions/MediumVoltage/MVConsumptions';
import LabelsWrapper from 'components/LabelsWrapper/LabelsWrapper';
import { getPointsOfCategory } from 'utils/product/loyalty/pointsUtils';
import { collectionOptionTypes } from 'utils/product/enums/cmsEnums';
import { useCollectionOptions } from 'data/useCollectionOptions';
import { useAuthUser } from 'utils/AuthUser';
import { EarnPointsLabel } from 'components/Loyalty/LoyaltyLabels';

import styles from './MeterConsumption.module.scss';

const MeterConsumption = ({ meter, title, refreshData, loading }) => {
  const { isLoyaltyCustomer } = useAuthUser();
  const state = getConsumptionState(meter);

  const { collectionOptionsByType } = useCollectionOptions();
  const registerConsumptionPoints = getPointsOfCategory(
    collectionOptionsByType,
    collectionOptionTypes.REGISTER_CONSUMPTION
  );

  return state === consumptionStates.mediumVoltage ? (
    <MVConsumptions meter={meter} loading={loading} />
  ) : (
    <Widget size="m" distance="m" title={title}>
      <LabelsWrapper>
        <MeterLabelWrapper
          type={meter.type}
          friendlyName={meter.FriendlyName}
          meterNo={meter.MeterNo}
          meter={meter}
          size="m"
          labelClass={styles.meterLabel}
        />
        {isLoyaltyCustomer && state === consumptionStates.register && (
          <EarnPointsLabel points={registerConsumptionPoints} />
        )}
      </LabelsWrapper>
      <section className={styles['innerContainer']}>
        {(state === consumptionStates.register ||
          state === consumptionStates.registered) && (
          <ConsumptionCountAndWin meter={meter} refreshData={refreshData} />
        )}
        {state === consumptionStates.clearing && (
          <ConsumptionClearing meter={meter} />
        )}
        {state === consumptionStates.clearingGas && (
          <ConsumptionClearingGas meter={meter} />
        )}
        {state === consumptionStates.prediction && (
          <ConsumptionPrediction meter={meter} />
        )}
      </section>
    </Widget>
  );
};

export default MeterConsumption;
