import { enableTracking, disableTracking } from 'utils/analytics';
import { isAfter } from 'utils/dateUtils';
import { enableFbPixel, disableFbPixel } from 'utils/fbPixel';
import { enableAdTracking, disableAdTracking } from 'utils/googleAds';

import storage from 'utils/storage/storage';

const _cookiesConsentKey = 'myHeron:privacy';
const _cookiesLastUpdate = '2022-02-02T10:00:00.000Z';

const defaultConsent = {
  performance: false,
  ads: false,
  ts: null,
};

const getCookiesConsent = () => {
  const consent = storage.getItem({
    key: _cookiesConsentKey,
    storage: storage.STORAGE_TYPE.LocalStorage,
  });

  if (consent) {
    if (isAfter(consent.ts, new Date(_cookiesLastUpdate))) {
      return consent;
    }
  }

  return defaultConsent;
};

const setCookiesConsent = (consent) => {
  storage.setItem({
    key: _cookiesConsentKey,
    value: {
      ...consent,
      ts: Date.now(),
    },
    storage: storage.STORAGE_TYPE.LocalStorage,
  });

  const { performance: isTrackingEnabled, ads: isAdsTrackingEnabled } = consent;
  isTrackingEnabled ? enableTracking() : disableTracking();
  isAdsTrackingEnabled ? enableFbPixel() : disableFbPixel();
  isAdsTrackingEnabled ? enableAdTracking() : disableAdTracking();
};

const acceptAll = () => {
  setCookiesConsent({ performance: true, ads: true });
};

const rejectAll = () => {
  setCookiesConsent({ performance: false, ads: false });
};

const cookiesStorage = {
  getCookiesConsent,
  setCookiesConsent,
  acceptAll,
  rejectAll,
  defaultConsent,
};
export default cookiesStorage;
