import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import useDialog from 'hooks/useDialog';
import paymentTypes from 'utils/product/enums/paymentTypes';
import { useParams } from 'react-router-dom';
import useSolarisDetails from 'data/useSolarisDetails';
import useEnaConfig from 'data/useEnaConfig';
import { isEmptyString } from 'utils/stringUtils';

import { trackEnaEvent } from 'utils/product/tracking/events';
import MeterLabelWrapper from 'components/Labels/MeterLabelWrapper';
import EnergyBenefitsWidget from 'components/Ena/EnaCalculator/EnergyBenefitsWidget';
import EnaAnalysisWidget from 'components/Ena/EnaAnalysisWidget';
import LiveStatsGraph from 'components/Ena/LiveStatsGraph';
import LiveStats from 'components/Ena/LiveStats';
import EnaDeactivationWidget from 'components/Ena/EnaDeactivationWidget';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import PaymentWidget from 'components/Payment/PaymentWidget';

import styles from './MainArea.module.scss';

const MainArea = ({ metersWithEna, metersLoading }) => {
  const { t, i18n } = useTranslation(['ena']);
  const { loading: configLoading, data: config } = useEnaConfig(i18n.language);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const paymentDialog = useDialog();

  const increaseParticipation = (meter) => {
    setPaymentInfo({
      PaymentCode: paymentCode,
      MeterId: meter?.Id,
      Commodity: meter?.Commodity,
      meter: meter,
    });
    paymentDialog.show();
    trackEnaEvent('ENA_participation_payment');
  };
  const { meterNo = '' } = useParams();

  const enaMeter = metersWithEna.find((x) => x.MeterNo === meterNo);
  const paymentCode = get(enaMeter, 'solarisInfo.SolarisPaymentCode', null);

  const showEmpty =
    !enaMeter ||
    isEmptyString(paymentCode) ||
    enaMeter?.solarisInfo?.FirstSolarisDepositHasOccured === false;

  const { data: enaDetails, loading: detailsLoading } = useSolarisDetails(
    showEmpty ? null : enaMeter?.solarisInfo?.SolarisId
  );
  return (
    <section>
      <WidgetSkeleton
        heightInPixels={360}
        distance="m"
        show={metersLoading || detailsLoading}
        title={<WidgetHeader title={t('ena:analysis.title')} />}
      >
        {!showEmpty && (
          <Widget
            distance="m"
            background="transparent"
            title={<WidgetHeader title={t('ena:analysis.title')} />}
          >
            <EnaAnalysisWidget
              meter={enaMeter}
              enaDetails={enaDetails}
              onPaymentClicked={increaseParticipation}
            />
          </Widget>
        )}
      </WidgetSkeleton>
      <WidgetSkeleton
        heightInPixels={614}
        distance="m"
        show={metersLoading || detailsLoading}
        title={<WidgetHeader title={t('ena:live-stats.title')} />}
      >
        {!showEmpty && (
          <Widget
            distance="m"
            size="m"
            title={<WidgetHeader title={t('ena:live-stats.title')} />}
            widgetClass={styles.liveStatsContainer}
          >
            <MeterLabelWrapper
              type={enaMeter.type}
              friendlyName={enaMeter.FriendlyName}
              meterNo={enaMeter.MeterNo}
              meter={enaMeter}
            />
            <LiveStatsGraph powerFactor={get(enaDetails, 'Powerfactor')} />
            <LiveStats powerFactor={get(enaDetails, 'Powerfactor')} />
          </Widget>
        )}
      </WidgetSkeleton>
      <WidgetSkeleton
        heightInPixels={360}
        distance="m"
        show={configLoading}
        title={<WidgetHeader title={t('ena:benefits.title')} />}
      >
        {!showEmpty && config && (
          <Widget
            size="m"
            distance="m"
            title={<WidgetHeader title={t('ena:benefits.title')} />}
          >
            <EnergyBenefitsWidget config={config} />
          </Widget>
        )}
      </WidgetSkeleton>

      {!showEmpty && config && (
        <>
          {!configLoading && (
            <p
              className={styles.enaInfo}
              dangerouslySetInnerHTML={{ __html: config.disclaimer }}
            ></p>
          )}
          <EnaDeactivationWidget meter={enaMeter} />
        </>
      )}
      <PaymentWidget
        open={paymentDialog.isOpen}
        closeDialog={paymentDialog.close}
        openDialog={paymentDialog.show}
        paymentCode={paymentInfo?.PaymentCode}
        meterId={paymentInfo?.MeterId}
        meter={paymentInfo?.meter}
        paymentType={paymentTypes.ena}
        commodity={paymentInfo?.Commodity}
      />
    </section>
  );
};

export default MainArea;
