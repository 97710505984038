import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  PromptDialog,
  ErrorDialog,
  LocalisedErrorDialog,
} from 'components/PromptDialogs/PromptDialog';

const FileUploadErrorDialog = ({ open, closeDialog, clickHandler }) => {
  const { t } = useTranslation(['newClient']);
  return (
    <ErrorDialog
      open={open}
      closeDialog={closeDialog}
      header={t(`newClient:failure.title`)}
      message={t(`newClient:failure.message`)}
      showPrimaryAction={true}
      primaryActionText={t(`newClient:failure.cta`)}
      primaryActionHandler={clickHandler}
    />
  );
};

const CreateRequestErrorDialog = ({ open, closeDialog, error }) => {
  const { errorScope, errorCode } = error || {};
  const showPrimaryAction = errorScope === 'onboardingEl' && errorCode === 506;
  return (
    <LocalisedErrorDialog
      open={open}
      closeDialog={closeDialog}
      showPrimaryAction={showPrimaryAction}
      primaryActionText={showPrimaryAction ? 'OK' : null}
      primaryActionHandler={closeDialog}
      {...(error || {})}
    />
  );
};

const RegistrationErrorDialog = ({ open, closeDialog, clickHandler }) => {
  const { t } = useTranslation(['newClient']);
  return (
    <PromptDialog
      open={open}
      closeDialog={closeDialog}
      header={t(`newClient:register-failure.title`)}
      message={t(`newClient:register-failure.message`)}
      primaryActionText={'OK'}
      primaryActionHandler={clickHandler}
    />
  );
};

export {
  FileUploadErrorDialog,
  CreateRequestErrorDialog,
  RegistrationErrorDialog,
};
