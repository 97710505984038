import useData from 'data/useData';
import { useAuthUser } from 'utils/AuthUser';
import useAccountMeters from 'data/useAccountMeters';
import { addMetersToContracts } from 'utils/product/contractUtils';

export default function useContracts(skip = false) {
  const { accountId } = useAuthUser();
  const { data, error, loading } = useData(
    skip ? null : `/account/contracts?accountId=${accountId}`
  );

  const { meters, loading: metersLoading } = useAccountMeters({
    onlyActive: true,
  });

  const contracts = data ? [...data.ElContracts, ...data.GasContracts] : [];
  return {
    data: addMetersToContracts(contracts, meters),
    loading: loading || metersLoading,
    error,
  };
}
