import React from 'react';

import { ReactComponent as Energy } from 'assets/icons/EnergyIcon.svg';
import { ReactComponent as EnergyGas } from 'assets/icons/EnergyGasIcon.svg';
import commodities from 'utils/product/enums/commodities';
import Chip from 'components/Chip/Chip';
import useLocalizedFriendlyName from 'hooks/useLocalizedFriendlyName';

import styles from './MeterChipContainer.module.scss';

const ChipContainer = ({ meter, isSelected, ...rest }) => {
  const { getDisplayName } = useLocalizedFriendlyName();
  const icons =
    meter.Commodity === commodities.gas ? <EnergyGas /> : <Energy />;
  const color = meter.Commodity === commodities.gas ? 'gas' : 'energy';

  const chipClasses = {
    icon: styles[`icon-${color}`],
  };
  return (
    <Chip
      color={color}
      Icon={icons}
      label={getDisplayName(meter.FriendlyName, meter.MeterNo)}
      classes={chipClasses}
      isSelected={isSelected}
      {...rest}
    />
  );
};

export default ChipContainer;
