import React from 'react';
import { useTranslation } from 'react-i18next';

import { StandardInput } from 'components/Inputs/Inputs';
import { formatDate } from 'utils/dateUtils';

const convertDaysToMonths = (days, t) => Math.floor(days / 30);

const ContractsBody = ({
  supplyNumber,
  programName,
  contractDurationInDays,
  contractSignatureDate,
  contractNumber,
}) => {
  const { t } = useTranslation(['management', 'translation']);
  const baseName = 'management:contracts.contractsBody';

  const months = convertDaysToMonths(contractDurationInDays);

  const list = [
    {
      label: t(`${baseName}.supply-number`),
      defaultValue: supplyNumber,
      key: 1,
    },
    { label: t(`${baseName}.program`), defaultValue: programName, key: 2 },
    {
      label: t(`${baseName}.contract-duration`),
      value: contractDurationInDays
        ? `${months} ${t(`translation:${months === 1 ? 'month' : 'months'}`)}`
        : t(`${baseName}.indefinite`),
      key: 3,
    },
    {
      label: t(`${baseName}.signing-date`),
      value: formatDate(contractSignatureDate),
      key: 4,
    },
    {
      label: t(`${baseName}.contract-number`),
      defaultValue: contractNumber,

      key: 5,
    },
  ];

  return list.map((element) => {
    return element.defaultValue || element.value ? (
      <StandardInput {...element} disabled={true} />
    ) : null;
  });
};

export default ContractsBody;
