import useSWR, { mutate } from 'swr';
import { fetcher } from 'api/wave';
import { energyPointsId } from 'utils/product/loyalty/config';
import { getVibes } from 'utils/product/loyalty/vibesUtils';
import { useVibesConfig } from './useVibesConfig';
import { conventFromCents } from 'utils/moneyUtils';
import endpoints from 'utils/product/enums/endpoints';

export const mutateLoyaltyPoints = (customerId) => {
  mutate(`${endpoints.loyalty.rewardSchemesAccounts}?customerId=${customerId}`);
};

export const useRewardSchemeAccounts = (
  customerId,
  rewardSchemeId,
  skip = false,
) => {
  const queryParams = [];
  if (customerId) queryParams.push(`customerId=${customerId}`);
  if (rewardSchemeId) queryParams.push(`rewardSchemeId=${rewardSchemeId}`);

  const query = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

  const _skip = skip === true || !customerId;
  const { data, error } = useSWR(
    _skip ? null : `${endpoints.loyalty.rewardSchemesAccounts}${query}`,
    fetcher,
  );

  if (_skip) {
    return {
      loading: false,
      error: null,
      data: null,
    };
  }
  const loading = !data && !error;

  const transformedData = data
    ? data.map((x) => ({
        ...x,
        remainingAmount: conventFromCents(x.remainingAmount),
        availableRemainingAmount: conventFromCents(x.availableRemainingAmount),
      }))
    : [];

  return {
    loading,
    error,
    data: transformedData,
  };
};

export const useLoyaltyPoints = (customerId, skip = false) => {
  const { data, ...rest } = useRewardSchemeAccounts(customerId, null, skip);
  if (data && data.length > 0) {
    const scheme = data
      ? data.find((x) => x.rewardSchemeId === energyPointsId)
      : null;
    return {
      data: {
        _id: scheme?._id,
        rewardSchemeId: scheme?.rewardSchemeId,
        remainingAmount: scheme?.remainingAmount,
        availableRemainingAmount: scheme?.availableRemainingAmount,
      },
      ...rest,
    };
  }

  return { data, ...rest };
};

export const useVibes = (customerId, language, skip = false) => {
  const { data, loading, error } = useRewardSchemeAccounts(
    customerId,
    null,
    skip,
  );
  const {
    data: vibesConfig,
    loading: vibesLoading,
    error: vibesError,
  } = useVibesConfig(language, skip);

  if (data && data.length > 0) {
    const vibeIds = vibesConfig ? vibesConfig.map((x) => x.rewardSchemeId) : [];

    const allUserVibes = vibeIds.map((vibeId) => {
      const rewardScheme = data.find((x) => x.rewardSchemeId === vibeId);

      if (rewardScheme) {
        return rewardScheme;
      }
      return {
        rewardSchemeId: vibeId,
        collectedAmount: 0,
        remainingAmount: 0,
        availableRemainingAmount: 0,
        redeemedAmount: 0,
        expiredAmount: 0,
      };
    });

    const vibes = getVibes(allUserVibes, vibesConfig);

    return {
      data: vibes,
      loading: loading || vibesLoading,
      error: error || vibesError,
    };
  }

  return {
    data,
    vibes: vibesConfig,
    loading: loading || vibesLoading,
    error: error || vibesError,
  };
};
