import React from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import styles from './RadioButton.module.scss';

export default function RadioButton({
  value,
  label,
  labelPlacement = 'end',
  register,
  name = 'radio_btn',
  classes = styles,
  ...props
}) {
  const { ref, ...registerRest } = register(name) || {};
  return (
    <FormControlLabel
      value={value}
      label={label}
      labelPlacement={labelPlacement}
      control={<Radio />}
      inputRef={ref}
      classes={classes}
      {...props}
      {...registerRest}
    />
  );
}
