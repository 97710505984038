import React, { useEffect, useState, useCallback } from 'react';
import Big from 'big.js';

import { gameEventNames } from 'utils/product/enums/gameEnums';
import { completeMiniGameSession } from 'api/loyalty';
import { useAuthUser } from 'utils/AuthUser';
import { gameResult } from 'utils/product/enums/waveEnums';
import { gameTypes } from 'utils/product/enums/gameEnums';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import useDialog from 'hooks/useDialog';
import GameExitDialog from './GameExitDialog';
import GamesInstructions from './GamesInstructions';
import CmsHtmlField from 'components/CmsHtmlField/CmsHtmlField';
import useHtmlBodyClass from 'hooks/useHtmlBodyClass';

import styles from './GamesDialog.module.scss';

const GamesDialog = ({
  game,
  sessionData,
  open,
  closeDialog,
  onGameCompleted,
  numberOfCompletedSessions,
}) => {
  useHtmlBodyClass(open ? styles['turn-off-overscroll'] : '');

  const { loyaltyCustomerId } = useAuthUser();
  const exitDialog = useDialog();
  const [hideInstruction, setHideInstruction] = useState(false);
  const [displayInstructions, setDisplayInstructions] = useState(false);
  const isWheelOrScratchGame =
    game?.type === gameTypes.wheel || game?.type === gameTypes.scratch;

  const continueGame = () => {
    exitDialog.close();
  };

  const giveUpGame = () => {
    exitDialog.close();
    closeDialog();
  };

  const handleGameClose = () => {
    exitDialog.show();
  };

  const onGameStarted = useCallback(() => {
    //send analytics event?
  }, []);

  const onGameResult = useCallback(
    (eventName) => {
      const data =
        eventName === gameEventNames.completed
          ? {
              customerId: loyaltyCustomerId,
            }
          : {
              customerId: loyaltyCustomerId,
              skillGameResult:
                eventName === gameEventNames.won
                  ? gameResult.win
                  : gameResult.lose,
            };
      completeMiniGameSession(sessionData?._id, data)
        .then((res) => {
          onGameCompleted(res.data);
        })
        .catch((error) => {});
    },
    [loyaltyCustomerId, sessionData?._id, onGameCompleted],
  );

  const handlePostMessage = useCallback(
    (event) => {
      if (event.data.eventName || event.data.eventName) {
        const { eventName /* eventData */ } = event.data || {};
        if (typeof eventName === 'string') {
          if (eventName === gameEventNames.started) {
            setDisplayInstructions(false);
            onGameStarted();
            return;
          }
          if (
            eventName === gameEventNames.won ||
            eventName === gameEventNames.completed ||
            eventName === gameEventNames.timesUp ||
            eventName === gameEventNames.lost
          ) {
            onGameResult(eventName);
          }
        }
      }
    },
    [onGameStarted, onGameResult],
  );
  useEffect(() => {
    window.addEventListener('message', handlePostMessage);
    setHideInstruction(false);

    const timer = setTimeout(() => setHideInstruction(true), 4000);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('message', handlePostMessage);
    };
  }, [handlePostMessage]);

  const _paddingBottom = game.widthToHeightRatio
    ? new Big(game.widthToHeightRatio).times(100).toNumber()
    : 100;
  return (
    <>
      <DialogOverlay
        open={open}
        closeDialog={handleGameClose}
        dialogHeader={game?.title}
        dialogClass={styles.dialog}
        contentClass={styles.content}
        scroll="body"
      >
        <section
          className={styles['game-iframe-container']}
          style={{ paddingBottom: `${_paddingBottom}%` }}
        >
          {displayInstructions && (
            <GamesInstructions
              gameType={game?.type}
              hideInstruction={hideInstruction}
            />
          )}

          <iframe
            id="game_frame"
            title="myΗρων"
            onLoad={() => {
              setDisplayInstructions(
                isWheelOrScratchGame && numberOfCompletedSessions === 0,
              );
            }}
            src={sessionData?.url}
            className={styles['game-iframe']}
            referrerPolicy="origin"
            sandbox="allow-same-origin allow-forms allow-scripts allow-popups allow-forms allow-popups-to-escape-sandbox"
          />
        </section>

        <CmsHtmlField className={styles.details} value={game?.instructions} />
      </DialogOverlay>
      <GameExitDialog
        open={exitDialog.isOpen}
        closeDialog={giveUpGame}
        openGameAgain={continueGame}
      />
    </>
  );
};

export default GamesDialog;
