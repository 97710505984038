import React from 'react';

import ThreeColumnsLayout from 'layouts/ThreeColumnsLayout';
import LeftSidebar from './LeftSidebar';
import ManagementMain from './ManagementMain';
import ManagementRightSideBar from './ManagementRightSideBar';

const Management = () => {
  return (
    <ThreeColumnsLayout
      left={<LeftSidebar />}
      main={<ManagementMain />}
      right={<ManagementRightSideBar />}
    />
  );
};

export default Management;
