import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { formatDate, getDifferenceInDays, startOfDay } from 'utils/dateUtils';
import { getInvoiceHomeStatus } from 'utils/product/invoiceUtils';
import billHomeStatuses from 'utils/product/enums/billHomeStatuses';
import { dateFormats } from 'utils/product/config';
import { useAuthUser } from 'utils/AuthUser';
import IconText from 'components/IconText/IconText';
import { useCollectionOptions } from 'data/useCollectionOptions';
import { getPointsOfCategory } from 'utils/product/loyalty/pointsUtils';
import { collectionOptionTypes } from 'utils/product/enums/cmsEnums';
import { formatMoney } from 'utils/moneyUtils';

import styles from './BillMessage.module.scss';

const BillMessage = ({
  invoice,
  isLastInvoice = true,
  containerClass = '',
  meter,
}) => {
  const status = getInvoiceHomeStatus(meter, invoice);

  return (
    isLastInvoice && (
      <div className={containerClass}>
        <>
          {status === billHomeStatuses.credit && <CreditBillMessage />}
          {status === billHomeStatuses.expiring && (
            <ExpiringBillMessage paymentDueDate={invoice.dueDate} />
          )}
          {status === billHomeStatuses.future && (
            <FutureBillMessage paymentDueDate={invoice.dueDate} />
          )}
          {status === billHomeStatuses.expired && <ExpiredBillMessage />}
          {status === billHomeStatuses.expiredDisconnect && (
            <ExpiredDisconnectBillMessage />
          )}
        </>
      </div>
    )
  );
};

const CreditBillMessage = () => {
  const { t } = useTranslation(['homepage']);
  return (
    <IconText
      weight="bold"
      contentClass={styles['content-text']}
      content={<span>{t('homepage:credit-nopay')}</span>}
    />
  );
};

const FutureBillMessage = ({ paymentDueDate }) => {
  const { isLoyaltyCustomer } = useAuthUser();
  const { collectionOptionsByType } = useCollectionOptions();

  const billPaymentInTimePoints = getPointsOfCategory(
    collectionOptionsByType,
    collectionOptionTypes.BILL_PAYMENT_DUE
  );

  return (
    <IconText
      content={
        <Trans
          i18nKey={`homepage:${
            isLoyaltyCustomer ? 'loyalty-bills' : 'non-loyalty-bills'
          }.future`}
          values={{
            points: formatMoney(billPaymentInTimePoints, false),
            dueDate: formatDate(paymentDueDate, dateFormats.dateHalfYear),
          }}
          components={{
            bold: <b />,
            yellow: <span className={styles['warning-text']}></span>,
          }}
        ></Trans>
      }
    />
  );
};

const ExpiringBillMessage = ({ paymentDueDate }) => {
  const { isLoyaltyCustomer } = useAuthUser();
  const { collectionOptionsByType } = useCollectionOptions();

  const daysUntilExpiration = getDifferenceInDays(
    startOfDay(new Date()),
    startOfDay(paymentDueDate)
  );

  const billPaymentInTimePoints = getPointsOfCategory(
    collectionOptionsByType,
    collectionOptionTypes.BILL_PAYMENT_DUE
  );

  return (
    <IconText
      content={
        <Trans
          i18nKey={`homepage:${
            isLoyaltyCustomer ? 'loyalty-bills' : 'non-loyalty-bills'
          }.pay-soon`}
          values={{
            points: formatMoney(billPaymentInTimePoints, false),
            count: daysUntilExpiration,
            context: `${daysUntilExpiration}`,
          }}
          components={{
            bold: <b />,
            yellow: <span className={styles['warning-text']}></span>,
          }}
        ></Trans>
      }
    />
  );
};

const ExpiredBillMessage = () => {
  return (
    <IconText
      content={
        <Trans
          i18nKey={`homepage:pay-fast`}
          components={{
            orange: <span className={styles['danger-text']}></span>,
          }}
        />
      }
    />
  );
};

const ExpiredDisconnectBillMessage = () => {
  return (
    <IconText
      content={
        <Trans
          i18nKey={`homepage:pay-now`}
          components={{
            orange: <span className={styles['danger-text']}></span>,
            bold: <b />,
          }}
        />
      }
    />
  );
};

export default BillMessage;
