import React from 'react';
import { useTranslation } from 'react-i18next';

import Widget from 'components/Widget/Widget';
import MeterLabelWrapper from 'components/Labels/MeterLabelWrapper';
import { getStatusKey } from 'utils/product/contractUtils';
import useContractStatus from 'data/useContractStatus';

import styles from './NewClientContractStatus.module.scss';

const NewClientContractStatus = () => {
  const { t } = useTranslation(['homepage']);
  const { data: metersWithPendingContractStatus, loading } =
    useContractStatus();
  return (
    !loading &&
    metersWithPendingContractStatus?.length > 0 && (
      <Widget
        background="transparent"
        title={null}
        containerClass={styles.container}
      >
        {metersWithPendingContractStatus.map((meter) => {
          const statusKey = getStatusKey(meter);
          return (
            <Widget
              key={meter.Id}
              distance="xs"
              widgetClass={styles['new-contract-widget']}
            >
              <MeterLabelWrapper
                type={meter.type}
                friendlyName={meter.FriendlyName}
                meterNo={meter.MeterNo}
                meter={meter}
                size="m"
              />
              <h4>{t(`homepage:new-client.${statusKey}-title`)}</h4>
              <p>
                {t(`homepage:new-client.${statusKey}-message`, {
                  code: meter.ContractNumber,
                })}
              </p>
            </Widget>
          );
        })}
      </Widget>
    )
  );
};

export default NewClientContractStatus;
