import discoveryItemTypes from '../enums/discoveryItemTypes';
import { benefitTypes } from '../enums/waveEnums';

export const addEventDescriptionToPointsHistory = (data, associatedData) => {
  return data?.map((event) => {
    const associatedItem = associatedData?.find(
      (associatedItem) =>
        associatedItem?.content?.waveId ===
          event?.transactions[0]?.subtransactions[0]?.associatedWaveEntity.id ||
        associatedItem?.content?.waveId === event?.associatedWaveEntity?.id
    )?.content;
    const eventDescription =
      associatedItem?.eventDescription || associatedItem?.title;

    const type =
      associatedItem?.benefits?.[0].type === benefitTypes.CONTEST
        ? discoveryItemTypes.contest
        : associatedItem?.benefits?.[0].type === benefitTypes.MINI_GAME
        ? discoveryItemTypes.game
        : discoveryItemTypes.offer;

    return {
      ...event,
      eventDescription: eventDescription,
      offerType: type,
    };
  });
};

export const uniqueWaveIds = (data) => {
  if (!data) return;
  const wavesIdSet = new Set(
    data?.map(
      (item) =>
        item?.transactions[0]?.subtransactions[0]?.associatedWaveEntity?.id ||
        item?.associatedWaveEntity?.id
    )
  );
  return Array.from(wavesIdSet).join(',');
};
