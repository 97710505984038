import useSWR from 'swr';
import { fetcher } from 'api/api';
import get from 'lodash/get';
import { enumUrls } from 'utils/product/config';
import { data as enums } from 'data/testData/enums';

export default function useEnum(
  propertyName,
  options = { dedupingInterval: 60000 }
) {
  const { [propertyName]: url } = enumUrls;
  const { data, error, mutate, isLoading } = useSWR(url, fetcher, options);
  if (error) {
    const results = get(enums, propertyName, []);

    const loading = propertyName && isLoading;

    return {
      loading,
      error,
      [propertyName]: results,
      mutate,
    };
  }
  const results = get(data, propertyName, []);

  const loading = propertyName && isLoading;

  return {
    loading,
    error,
    [propertyName]: results,
    mutate,
  };
}
