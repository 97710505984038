import api from './api';

const uploadRequestFiles = (requestId, files, isHeronRequest) => {
  var formData = new FormData();

  files.forEach((file, index) => {
    formData.append(`file-${index}`, file);
  });
  return api.post(
    `/pdf/uploadFileAsync?requestId=${requestId}${
      isHeronRequest ? '&isHeronRequestNote=true' : ''
    }`,
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    },
  );
};

const uploadRequestFile = (requestId, file, isHeronRequest) => {
  var formData = new FormData();
  formData.append('', file, file.name);

  return api.post(
    `/pdf/uploadFileAsync?requestId=${requestId}${
      isHeronRequest ? '&isHeronRequestNote=true' : ''
    }`,
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    },
  );
};

const createRequest = (requestData) => {
  return api.post('/account/create', requestData);
};

const createCase = (requestData) => {
  return api.post('/account/createCase', requestData);
};

export { uploadRequestFile, uploadRequestFiles, createRequest, createCase };
