import React from 'react';
import { getFirstAndLastName } from 'utils/product/accountUtils';
import { ReactComponent as Avatar } from 'assets/icons/Avatar.svg';
import { ReactComponent as AvatarLoyalty } from 'assets/icons/AvatarLoyalty.svg';
import AvatarInitials from 'components/AuthorizedHeaderContent/AvatarInitials';
import { useAuthUser } from 'utils/AuthUser';
import useNotificationsStatus from 'data/useNotificationsStatus';
import styles from './AuthorizedHeaderAvatar.module.scss';

const AuthorizedHeaderAvatar = ({ filled: _filled }) => {
  const {
    selectedAccount: user,
    isLoyaltyCustomer,
    loyaltyCustomerId,
    accountId,
  } = useAuthUser();

  const { hasUnviewedNotifications } = useNotificationsStatus(
    loyaltyCustomerId,
    accountId
  );
  const { firstName, lastName } = getFirstAndLastName(user);

  return (
    <section>
      {hasUnviewedNotifications && <div className={styles.dot}></div>}
      {firstName ? (
        <AvatarInitials
          name={firstName}
          lastname={lastName}
          isLoyaltyCustomer={isLoyaltyCustomer}
          filled={_filled}
        />
      ) : isLoyaltyCustomer ? (
        <AvatarLoyalty width={28} height={28} />
      ) : (
        <Avatar width="28" height={28} />
      )}
    </section>
  );
};

export default AuthorizedHeaderAvatar;
