import React from 'react';
import { useTranslation } from 'react-i18next';

import useDialog from 'hooks/useDialog';
import PaymentWidget from './PaymentWidget';
import paymentTypes from 'utils/product/enums/paymentTypes';
import { useAuthUser } from 'utils/AuthUser';
import {
  getPointsOfCategory,
  getWaveId,
} from 'utils/product/loyalty/pointsUtils';
import { useCollectionOptions } from 'data/useCollectionOptions';
import { collectionOptionTypes } from 'utils/product/enums/cmsEnums';
import { EarnPointsLabel } from 'components/Loyalty/LoyaltyLabels';
import {
  isMeterEligibleForReward,
  findMetersWithoutRecurringReward,
} from 'utils/product/loyalty/recurringUtils';
import Widget from 'components/Widget/Widget';
import useLoyaltyHistory from 'data/useLoyaltyHistory';
import { mutateStandingOrderMeterInfo } from 'data/useStandingOrderMeterInfo';

import styles from './RecurringPaymentWidget.module.scss';

const RecurringPaymentWidget = ({ meter }) => {
  const { t } = useTranslation(['bills', 'homepage']);
  const paymentDialog = useDialog();

  const { isLoyaltyCustomer, loyaltyCustomerId } = useAuthUser();
  const { collectionOptionsByType } = useCollectionOptions();

  const recurringPoints = getPointsOfCategory(
    collectionOptionsByType,
    collectionOptionTypes.DEBIT_PAYMENT,
  );

  const waveId = getWaveId(
    collectionOptionsByType,
    collectionOptionTypes.DEBIT_PAYMENT,
  );

  const { data: loyaltyHistory } = useLoyaltyHistory(loyaltyCustomerId, waveId);

  const meterCanBeRewarded =
    findMetersWithoutRecurringReward([meter], loyaltyHistory)?.length > 1;

  return (
    <>
      <div className={styles.container} onClick={paymentDialog.show}>
        <Widget
          size="s"
          background={'transparent'}
          containerClass={styles.banner}
          widgetClass={styles.content}
          //onClick={paymentDialog.show}
        >
          <p>{t('recurring-payment.add')}</p>
        </Widget>
        {isLoyaltyCustomer &&
          isMeterEligibleForReward(meter) &&
          meterCanBeRewarded && (
            <EarnPointsLabel
              points={recurringPoints}
              labelClass={styles.loyaltyPoints}
              distance=""
            />
          )}
      </div>

      {/* this is outside the canActivateRecurring check, so that when swr updates the value, payment dialog does not disappear */}
      <PaymentWidget
        open={paymentDialog.isOpen}
        closeDialog={paymentDialog.close}
        openDialog={paymentDialog.show}
        paymentType={paymentTypes.recurring}
        meterId={meter?.Id}
        meter={meter}
        commodity={meter?.Commodity}
        onPaymentSuccess={() => {
          mutateStandingOrderMeterInfo(meter?.Id, meter?.Commodity);
        }}
      />
    </>
  );
};

export default RecurringPaymentWidget;
