import Big from 'big.js';
import { sortByFieldDescending } from 'utils/sortUtils';

export const getVibes = (rewardSchemes, vibesConfig) => {
  if (!rewardSchemes || rewardSchemes?.length === 0) return [];

  const totalPoints = rewardSchemes.reduce((previousValue, currentValue) => {
    const sum = previousValue + currentValue.remainingAmount;
    return sum;
  }, 0);

  const result = rewardSchemes.map((x, index) => {
    const percentage =
      totalPoints > 0 && x.remainingAmount > 0
        ? new Big(x.remainingAmount)
            .times(100)
            .div(totalPoints)
            .round()
            .toNumber()
        : 0;

    const vibeOptions = vibesConfig.find(
      (option) => option.rewardSchemeId === x.rewardSchemeId
    );
    return {
      ...x,
      percentage,
      id: vibeOptions?.id,
      key: vibeOptions ? vibeOptions.title.toLowerCase() : x.rewardSchemeId,
      color: vibeOptions ? `#${vibeOptions.color}` : '',
      imageUrl: vibeOptions?.imageUrl,
      title: vibeOptions?.title,
      description: vibeOptions?.description,
    };
  });

  sortByFieldDescending(result, 'remainingAmount');

  const totalPercentage = result.reduce(
    (prev, current) => prev + current.percentage,
    0
  );

  const remainingPercentage = 100 - totalPercentage;
  if (totalPercentage > 0) {
    result[0].percentage += remainingPercentage;
  }
  sortByFieldDescending(result, 'percentage');

  return result;
};

export const getAcquiredVibes = (vibes) =>
  vibes?.length > 0 ? vibes.filter((x) => x.remainingAmount > 0) : [];
export const getNonAcquiredVibes = (vibes) =>
  vibes?.length > 0 ? vibes.filter((x) => x.remainingAmount === 0) : [];
