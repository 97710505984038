import React, { useState, useContext, useEffect } from 'react';
import * as Sentry from '@sentry/react';

import authStorage from 'utils/storage/authStorage';
import { loginService } from 'api/auth';
import { setUserLogout } from 'api/user';
import {
  decodeToken,
  getAccountType,
  isUserB2B,
  getConnectedAccounts,
} from 'utils/product/accountUtils';
import accountCategoryTypes from './product/enums/accountCategoryTypes';
import useWebDowntime from 'data/useWebDowntime';
import { getAccountInfo } from 'api/account';
import { removeChat } from './product/chatUtils';
import { formatGuid, isStringEqual } from './stringUtils';
import loyaltyStatuses from './product/enums/loyaltyStatuses';
import useLoyaltyUser from 'data/useLoyaltyUser';
//commenting. will remove with next release
// import { isUserAllowed } from './product/loyalty/loyaltyUtils';

export const AuthUserContext = React.createContext();

export const AuthUserProvider = ({ children }) => {
  const { data: { hasDownTime } = {} } = useWebDowntime() || {};
  const [user, setUser] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState({});

  const lastAccountId = authStorage.getAccountId();
  const token = authStorage.getToken();
  const { accountId: decodedAcountId } = token ? decodeToken(token) : {};
  const _accountId = token ? lastAccountId ?? decodedAcountId : null;
  const [accountId, setAccountId] = useState(formatGuid(_accountId));
  const [loading, setLoading] = useState(!!token);

  const {
    loading: loyaltyLoading,
    data: loyaltyCustomer,
    mutate: mutateLoyaltyCustomer,
  } = useLoyaltyUser(accountId);

  const [accounts, setAccounts] = useState([]);

  const onStorageUpdate = (e) => {
    const { key, newValue } = e;
    if (isStringEqual(key, authStorage.keys.ACCOUNT_ID)) {
      if (newValue) {
        setAccountId(formatGuid(newValue));
        return;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('storage', onStorageUpdate);

    return () => {
      window.removeEventListener('storage', onStorageUpdate);
    };
  }, []);

  useEffect(() => {
    if (!accountId) {
      return;
    }

    setLoading(true);

    getAccountInfo(accountId)
      .then((accountRes) => {
        const { data: user } = accountRes || {};
        if (user) {
          setUser(user);
          const accounts = getConnectedAccounts(user);
          setAccounts(accounts);

          return user;
        }
      })
      .catch()
      .finally(() => {
        setLoading(false);
      });
  }, [accountId]);

  //---
  useEffect(() => {
    if (hasDownTime === true) {
      setLoading(false);
      setAccountId(null);
      setSelectedAccount(null);
      return;
    }
  }, [hasDownTime]);

  //this is to set sentry user only
  useEffect(() => {
    try {
      if (accountId) {
        const { accountId: parentAccountId } = decodeToken(
          authStorage.getToken(),
        );
        Sentry.setUser({
          id: accountId,
          parentId: parentAccountId,
        });
        return;
      }
      Sentry.setUser(null);
    } catch (e) {}
  }, [accountId]);

  useEffect(() => {
    if (!accountId) {
      authStorage.removeAccountId();

      setSelectedAccount(null);
      return;
    }

    authStorage.setAccountId(formatGuid(accountId));
    const _tempUser =
      accounts?.find((x) => isStringEqual(x.Id, accountId)) || {};
    setSelectedAccount(_tempUser);
  }, [accountId, accounts]);

  const login = ({ email, password }) => {
    authStorage.clearAuthTokens();
    return loginService({ email, password }).then((res) => {
      const { access_token: token, refresh_token, Commodity } = res.data;

      if (token) {
        const { accountId: decodedAcountId } = decodeToken(token);
        if (refresh_token) {
          authStorage.setRefreshToken({ refreshToken: refresh_token });
        }
        authStorage.setToken({ token: token });
        authStorage.setCommodity({ commodity: Commodity });
        setAccountId(formatGuid(decodedAcountId));
        setLoading(false);
      }
    });
  };

  const logout = () => {
    setUserLogout()
      .then(() => {})
      .catch((error) => {})
      .then(() => {
        setUser(null);
        setAccountId(null);
        const token = authStorage.getToken();
        removeChat(token);
        authStorage.clearAuthTokens();
        Sentry.setUser(null);
        setLoading(false);
      });
  };

  const selectAccount = (id) => {
    setAccountId(formatGuid(id));
  };

  const mutateAccount = () => {
    setLoading(true);
    getAccountInfo(accountId)
      .then((accountRes) => {
        const { data: user } = accountRes || {};
        if (user) {
          setUser(user);
          return user;
        }
      })
      .catch()
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AuthUserContext.Provider
      value={{
        isLoggedIn: !!accountId,
        user: user || {},
        login,
        loading: loading,
        logout,
        isB2B: isUserB2B(user),
        isBusinessCustomer:
          getAccountType(user?.Category) === accountCategoryTypes.business,
        accountId,
        selectAccount,
        selectedAccount,
        accounts,
        loyaltyCustomer,
        loyaltyCustomerId: loyaltyCustomer?.customerId,
        mutateLoyaltyCustomer,
        isLoyaltyCustomer:
          loyaltyCustomer?.loyaltyStatus === loyaltyStatuses.active,
        loyaltyLoading,
        mutateAccount,
      }}
    >
      {children}
    </AuthUserContext.Provider>
  );
};

export const useAuthUser = () => {
  return useContext(AuthUserContext);
};
