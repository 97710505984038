const shopEnergyTypes = {
  gas: 'GAS',
  electricity: 'ELECTRICITY',
  service: 'SERVICE',
  eco: 'ECO',
  combined: 'COMBINED',
  combinedElectricity: 'COMBINED_EL',
};

export default shopEnergyTypes;
