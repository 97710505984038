import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmptyString } from './stringUtils';
import languageStorage from './storage/languageStorage';

const LanguageRoute = ({ lang = 'en' }) => {
  const location = useLocation();
  const { i18n } = useTranslation();

  const path = location.pathname;
  const queryParams = location.search;
  const hash = location.hash;

  const langPathPrefix = `/${lang}`;
  languageStorage.setLanguage(lang === 'en' ? 'en' : 'el');
  i18n.changeLanguage(lang === 'en' ? 'en' : 'el');

  const newPath =
    path.indexOf(langPathPrefix) === 0
      ? path.slice(langPathPrefix.length)
      : path;
  const redirectTo = `${!isEmptyString(newPath) ? newPath : '/'}${
    !isEmptyString(queryParams) ? queryParams : ''
  }${!isEmptyString(hash) ? hash : ''}`;

  return <Navigate to={redirectTo} replace={true} state={location?.state} />;
};

export default LanguageRoute;
