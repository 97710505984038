import React from 'react';
import { NavLink } from 'react-router-dom';

import SidebarElement from 'components/SidebarElements/SidebarElement';

import styles from './SidebarLinkElement.module.scss';

const SidebarLinkElement = ({
  target = '/',
  Icon,
  iconRight,
  content,
  iconWeight,
  ...rest
}) => {
  return (
    <NavLink
      className={({ isActive }) =>
        `${styles.link} ${isActive ? styles.selectedLink : ''}`
      }
      to={target}
      {...rest}
    >
      <SidebarElement
        Icon={Icon}
        content={content}
        iconWeight={iconWeight}
        iconRight={iconRight}
      />
    </NavLink>
  );
};

export default SidebarLinkElement;
