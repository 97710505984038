import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as GoogleWalletIconGR } from 'assets/icons/GoogleWalletIconGR.svg';
import { ReactComponent as GoogleWalletIconEN } from 'assets/icons/GoogleWalletIconEN.svg';
import { getGooglePayPassJWT } from 'api/loyalty';

import styles from './GoogleWallet.module.scss';

const GoogleWalletIcon = ({ ...rest }) => {
  const { i18n } = useTranslation();

  return i18n.language === 'el' ? (
    <GoogleWalletIconGR {...rest} />
  ) : (
    <GoogleWalletIconEN {...rest} />
  );
};

const GoogleWallet = ({ couponCode, customerId }) => {
  return (
    <GoogleWalletIcon
      alt="Add to Google Wallet"
      className={styles['addToWallet-button']}
      onClick={() => {
        getGooglePayPassJWT(couponCode, customerId)
          .then((response) => {
            window.open(
              `${process.env.REACT_APP_GOOGLE_URL}${response?.data?.googleJwt}`,
              '_blank'
            );
          })
          .catch((err) => {});
      }}
    />
  );
};

export default GoogleWallet;
