import React from 'react';
import { useTranslation } from 'react-i18next';

import { getDayName, formatDate } from 'utils/dateUtils';
import { formatMoneyWithCurrency } from 'utils/moneyUtils';
import { getSettlementsSum } from 'utils/product/settlementUtils';
import { dateFormats } from 'utils/product/config';
import CustomLabeledTextfield from 'components/CustomLabeledTextfield/CustomLabeledTextfield';
import EuroContainer from 'components/EuroContainer/EuroContainer';

import styles from './SettlementOverviewDialog.module.scss';

const SettlementAnalysis = ({ installments }) => {
  const { t } = useTranslation(['translation', 'bills']);
  return (
    <section>
      <h3>{t('bills:settlements.analysis')}</h3>

      {installments.map((installment, i) => (
        <CustomLabeledTextfield
          day={getDayName(installment.InstallmentDueDate)}
          label={formatDate(
            installment.InstallmentDueDate,
            dateFormats.dateHalfYear
          )}
          content={t(`bills:settlements.installment-number.${i}`)}
          amount={`${formatMoneyWithCurrency(installment.InstallmentAmmount)}`}
          key={installment.InstallmentDueDate}
        />
      ))}
      <div className={styles.footer}>
        {t('bills:settlements.total')}
        <EuroContainer content={getSettlementsSum(installments)} />
      </div>
    </section>
  );
};

export default SettlementAnalysis;
