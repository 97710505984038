import { useCmsContent } from 'data/useCmsContent';
import { cmsWebDowntimeNotificationsTransformation } from 'utils/configCmsTransformation';

const useWebNotificationsDowntime = (language) => {
  const { data, loading, error } = useCmsContent(
    'generalConfiguration',
    language,
    ...Array(3),
    { dedupingInterval: 60000 },
  );

  const configurationData = cmsWebDowntimeNotificationsTransformation(data);

  return {
    loading: loading,
    data: configurationData,
    error: error,
  };
};

export default useWebNotificationsDowntime;
