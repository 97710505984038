import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import ownershipTypes from 'utils/product/enums/ownershipTypes';
import RadioButton from 'components/RadioButtonsGroup/RadioButton';
import RadioGroup from 'components/RadioButtonsGroup/RadioGroup';
import { TextInput } from 'components/Inputs/TextInput';
import { TaxpayerIdInput } from 'components/Inputs/TaxpayerIdInput';
import { TaxOfficeInput } from 'components/Inputs/TaxOfficeInput';

import styles from './UserCapacity.module.scss';

const UserCapacity = ({
  defaultValues = {},
  onUpdate,
  isGasProgram = true,
}) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { t } = useTranslation(['newClient', 'translation']);
  const baseObjectName = isGasProgram ? 'gasProgram' : 'energyProgram';

  return (
    <article className={styles.container}>
      <h3 className={styles.header}>{t(`newClient:userCapacity.header`)}</h3>

      <RadioGroup
        name={`${baseObjectName}.userCapacity`}
        value={get(defaultValues, `${baseObjectName}.userCapacity`, '')}
        handleChange={(event) => {
          setValue(`${baseObjectName}.userCapacity`, event.target.value);
          onUpdate({
            [baseObjectName]: {
              ...defaultValues[baseObjectName],
              userCapacity: event.target.value,
            },
          });
        }}
      >
        <RadioButton
          value={`${ownershipTypes.owner}`}
          label={t('newClient:userCapacity.owner')}
          register={register}
          className={styles.radio}
        />
        <RadioButton
          value={`${ownershipTypes.manager}`}
          label={t('newClient:userCapacity.manager')}
          register={register}
          className={styles.radio}
        />

        <RadioButton
          value={`${ownershipTypes.tenant}`}
          label={t('newClient:userCapacity.tenant')}
          register={register}
          className={styles.radio}
        />
        {defaultValues[`${baseObjectName}`].userCapacity ===
          `${ownershipTypes.tenant}` && (
          <article>
            <TextInput
              register={register}
              errors={errors}
              name={`gasOwner.firstName`}
              label={t(`newClient:gasOwner.firstName`)}
              defaultValue={get(defaultValues, `gasOwner.firstName`)}
            />
            <TextInput
              register={register}
              errors={errors}
              name={`gasOwner.lastName`}
              label={t(`newClient:gasOwner.lastName`)}
              defaultValue={get(defaultValues, `gasOwner.lastName`)}
            />
            <TaxpayerIdInput
              register={register}
              errors={errors}
              name={`gasOwner.taxNo`}
              label={t(`newClient:gasOwner.taxNo`)}
              defaultValue={get(defaultValues, `gasOwner.taxNo`)}
            />
            <TaxOfficeInput
              register={register}
              errors={errors}
              name={`gasOwner.taxOffice`}
              value={get(defaultValues, `gasOwner.taxOffice`, '')}
              defaultValue={get(defaultValues, `gasOwner.taxOffice`)}
            />
          </article>
        )}
      </RadioGroup>
    </article>
  );
};

export default UserCapacity;
