import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Widget from 'components/Widget/Widget';
import {
  PrimaryButtonLoader,
  SecondaryButtonLoader,
} from 'components/Buttons/Buttons';
import { Divider } from '@material-ui/core';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import { ReactComponent as BillIcon } from 'assets/icons/EmptyEbillIcon.svg';
import { ReactComponent as Tree } from 'assets/icons/EbillLoyaltyTree.svg';
import EmptyStateWidget from 'components/Widget/EmptyStateWidget';
import { trackEbillEvent } from 'utils/product/tracking/loyaltyEvents';
import { getTrackingEnergyType } from 'utils/product/tracking/trackingLabels';
import useDialog from 'hooks/useDialog';
import {
  getPointsOfCategory,
  getWaveId,
} from 'utils/product/loyalty/pointsUtils';
import { useCollectionOptions } from 'data/useCollectionOptions';
import { collectionOptionTypes } from 'utils/product/enums/cmsEnums';
import { useAuthUser } from 'utils/AuthUser';
import {
  ALLOWED_METER_STATUSES_EL,
  ALLOWED_METER_STATUSES_GAS,
  findNotActivatedEbillWithoutReward,
  isMeterEligibleForReward,
} from 'utils/product/loyalty/ebillUtils';
import useAccountMeters from 'data/useAccountMeters';
import MeterIconText from 'components/Labels/MeterIconText';
import useLoyaltyHistory from 'data/useLoyaltyHistory';
import RewardAlreadyActivatedEbillsDialog from './RewardAlreadyActivatedEbillsDialog';
import EbillDialog from 'components/EbillDialogs/EbillDialog';
import MultipleEbillsActivationDialog from 'components/EbillDialogs/MultipleEbillsActivationDialog';
import { sortMetersForManagementPage } from 'utils/product/meterUtils';
import { WarningDialog } from 'components/PromptDialogs/PromptDialog';
import { isEmptyString } from 'utils/stringUtils';

import styles from './LoyaltyEbillWidget.module.scss';

const LoyaltyEbillWidget = () => {
  const { loyaltyCustomerId, user } = useAuthUser();
  const { t, i18n } = useTranslation(['loyalty']);
  const loyaltyEbillDialog = useDialog();
  const multipleEbillsDialog = useDialog();
  const warningDialog = useDialog();

  const [meterSelection, setMeterSelection] = useState(null);

  const { collectionOptionsByType, loading: collectionOptionsLoading } =
    useCollectionOptions(i18n.language);

  const ebillPoints = getPointsOfCategory(
    collectionOptionsByType,
    collectionOptionTypes.E_BILL
  );
  const waveId = getWaveId(
    collectionOptionsByType,
    collectionOptionTypes.E_BILL
  );

  const { meters, loading, mutate } = useAccountMeters({
    statusesElectricity: ALLOWED_METER_STATUSES_EL,
    statusesGas: ALLOWED_METER_STATUSES_GAS,
  });

  const { data: historyData, loading: historyLoading } = useLoyaltyHistory(
    loyaltyCustomerId,
    waveId
  );
  const metersEligibleForEbill = meters?.filter((meter) =>
    isMeterEligibleForReward(meter)
  );

  const metersWithoutEbill = sortMetersForManagementPage(
    findNotActivatedEbillWithoutReward(metersEligibleForEbill, historyData)
  );

  const eBillAvailable = metersEligibleForEbill?.length > 0;

  const [loadingMeterIdButton, setLoadingMeterIdButton] = useState(null);

  const handleCloseEbillActivation = () => {
    setLoadingMeterIdButton(null);
    loyaltyEbillDialog.close();
  };

  const handleCloseMultipleEbillsDialog = () => {
    setLoadingMeterIdButton(null);
    multipleEbillsDialog.close();
  };

  const handleEbill = (meter) => {
    setLoadingMeterIdButton(meter?.MeterId);
    setMeterSelection(meter);

    trackEbillEvent('bill_ebill', getTrackingEnergyType(meter?.Commodity));

    loyaltyEbillDialog.show();
  };

  const handleAllEbills = async () => {
    if (isEmptyString(user?.ContactEmail)) {
      warningDialog.show();
      return;
    }
    setLoadingMeterIdButton('All');
    multipleEbillsDialog.show();
  };

  return (
    <>
      <WidgetSkeleton
        heightInPixels="300"
        show={loading || collectionOptionsLoading || historyLoading}
      >
        {metersWithoutEbill.length > 0 ? (
          <>
            <Widget
              distance="xs"
              widgetClass={styles['ebill-widget']}
              containerClass={styles.widgetContainer}
            >
              <h3>{t('loyalty:collection-options.E_BILL.widget-title')}</h3>
              <div className={styles.container}>
                <div className={styles.ebillActivation}>
                  {metersWithoutEbill.map((meter) => {
                    return (
                      <React.Fragment key={meter.Id}>
                        <div className={styles.textAndButton}>
                          <MeterIconText
                            meterNo={meter?.MeterNo}
                            commodity={meter?.Commodity}
                            friendlyName={meter?.FriendlyName}
                          />
                          {metersWithoutEbill.length > 1 ? (
                            <SecondaryButtonLoader
                              onClick={() => handleEbill(meter)}
                              loading={loadingMeterIdButton === meter.Id}
                              disabled={!!loadingMeterIdButton}
                            >
                              {t(
                                'loyalty:collection-options.E_BILL.activateEbillButton'
                              )}
                            </SecondaryButtonLoader>
                          ) : (
                            <PrimaryButtonLoader
                              onClick={() => handleEbill(meter)}
                              loading={loadingMeterIdButton === meter.Id}
                              disabled={!!loadingMeterIdButton}
                            >
                              {t(
                                'loyalty:collection-options.E_BILL.activateEbillButton'
                              )}
                            </PrimaryButtonLoader>
                          )}
                        </div>
                        <Divider className={styles.divider} />
                      </React.Fragment>
                    );
                  })}
                </div>
                {metersWithoutEbill.length > 1 && (
                  <div className={styles.primaryButtonContainer}>
                    <PrimaryButtonLoader
                      onClick={handleAllEbills}
                      loading={loadingMeterIdButton === 'All'}
                      disabled={!!loadingMeterIdButton}
                    >
                      {t('loyalty:collection-options.E_BILL.activateAllButton')}
                    </PrimaryButtonLoader>
                  </div>
                )}
              </div>
            </Widget>
          </>
        ) : eBillAvailable ? (
          <EmptyStateWidget
            icon={<Tree width="72" height="72" />}
            text={t('loyalty:collection-options.E_BILL.completeEbill-message')}
            isFullWidth={false}
          />
        ) : (
          <EmptyStateWidget
            icon={<BillIcon width="72" height="72" />}
            text={t('loyalty:collection-options.E_BILL.empty-message')}
            isFullWidth={false}
          />
        )}
      </WidgetSkeleton>
      <EbillDialog
        open={loyaltyEbillDialog.isOpen}
        closeDialog={handleCloseEbillActivation}
        mutate={mutate}
        meter={meterSelection}
        loyaltyHistory={historyData}
      />
      <MultipleEbillsActivationDialog
        open={multipleEbillsDialog.isOpen}
        closeDialog={handleCloseMultipleEbillsDialog}
        metersEligibleForLoyaltyReward={metersWithoutEbill}
        waveId={waveId}
        mutate={mutate}
      />
      <WarningDialog
        open={warningDialog.isOpen}
        message={t('loyalty:collection-options.E_BILL.no-email-error')}
        primaryActionHandler={warningDialog.close}
      />
      {!collectionOptionsLoading && !historyLoading && !loading && (
        <RewardAlreadyActivatedEbillsDialog
          meters={metersEligibleForEbill}
          historyData={historyData}
          customerId={loyaltyCustomerId}
          waveId={waveId}
          ebillPoints={ebillPoints}
        />
      )}
    </>
  );
};

export default LoyaltyEbillWidget;
