import React from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from 'components/Buttons/Buttons';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';

import styles from './WhatsNewDialogOverlay.module.scss';

const WhatsNewDialogOverlay = ({
  open,
  closeDialog,
  handleClick,
  children,
  ...rest
}) => {
  const { t } = useTranslation(['translation']);

  return (
    <DialogOverlay
      open={open}
      closeDialog={closeDialog}
      scroll="body"
      dialogClass={styles.dialog}
      contentClass={styles.dialogContent}
      showCloseButton={false}
      dialogActions={
        <PrimaryButton onClick={handleClick}>
          {t('translation:continue')}
        </PrimaryButton>
      }
      alignActions="right"
      {...rest}
    >
      {children}
    </DialogOverlay>
  );
};

export default WhatsNewDialogOverlay;
