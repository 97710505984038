const billHomeStatuses = {
  future: 0,
  expiring: 1,
  expired: 2,
  expiredDisconnect: 3,
  credit: 4,
  paid: 5,
};

export default billHomeStatuses;
