import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import EnergySavingWidget from 'components/EnergySaving/EnergySavingWidget';
import MeterConsumption from 'components/Consumptions/DetailViewStates/MeterConsumption';
import ConsumptionEmpty from 'components/Consumptions/DetailViewStates/ConsumptionEmpty';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import { getConsumptionState } from 'utils/product/consumptionUtils';

const MainArea = ({ meters, loading, mutate }) => {
  const { t } = useTranslation(['consumptions']);
  const { meterNo = null } = useParams();

  const meter = meters.find((x) => x.MeterNo === meterNo);
  const state = meter ? getConsumptionState(meter) : null;

  return (
    <>
      <WidgetSkeleton
        heightInPixels={164}
        distance="s"
        show={loading}
        title={<WidgetHeader title={t('consumptions:overview.title')} />}
      >
        {!loading && !meter && (
          <>
            <WidgetHeader title={t('consumptions:overview.title')} />
            <ConsumptionEmpty />
          </>
        )}

        {meter && state !== null && (
          <MeterConsumption
            meter={meter}
            title={<WidgetHeader title={t('consumptions:overview.title')} />}
            key={`cons-${meter.Id}`}
            refreshData={mutate}
            loading={loading}
          />
        )}
      </WidgetSkeleton>

      <EnergySavingWidget />
    </>
  );
};

export default MainArea;
