import useSWR from 'swr';
import { fetcher } from 'api/wave';

export default function useWaveData(url, options = {}, skip = false) {
  const {
    data,
    error,
    mutate,
    isLoading: loading,
    ...rest
  } = useSWR(skip ? null : url, fetcher, options);

  return {
    ...rest,
    loading: skip ? true : loading,
    isError: !!error && !data,
    error,
    data,
    mutate,
  };
}
