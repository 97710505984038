import React from 'react';
import Widget from 'components/Widget/Widget';
import IconText from 'components/IconText/IconText';
import { ReactComponent as LoyaltyCreditIcon } from 'assets/icons/LoyaltyCreditIcon.svg';

import styles from './LoyaltyBanner.module.scss';

const LoyaltyBanner = ({ points, text, distance = 'xs' }) => {
  return (
    <Widget
      distance={distance}
      widgetClass={styles.widget}
      containerClass={styles['widget-container']}
    >
      <div className={styles['banner-container']}>
        <IconText
          Icon={<LoyaltyCreditIcon width={24} height={24} />}
          content={points}
          contentClass={styles.text}
          containerClass={styles.iconTextClass}
          gap={'xs'}
        />
        <p>{text}</p>
      </div>
    </Widget>
  );
};

export default LoyaltyBanner;
