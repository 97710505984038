import { useInvoices } from './iDocs';
import useGasInvoices from './useGasInvoices';
import { customHistorySortByFieldDescendingWithSameDate } from 'utils/sortUtils';
import { isEmptyString } from 'utils/stringUtils';
import commodities from 'utils/product/enums/commodities';
import { allBillsPageSize, meterBillsPageSize } from 'utils/product/config';

const useInvoicesHistory = (
  taxNo,
  meterSupplyNo,
  supplyType,
  commodity,
  meters,
  skip = false
) => {
  const electricityCount = meters?.filter(
    (_meter) => _meter.Commodity === commodities.energy
  )?.length;
  const skipElectricityApi =
    skip || electricityCount === 0 || commodity === commodities.gas;

  const gasCount = meters?.filter(
    (_meter) => _meter.Commodity === commodities.gas
  )?.length;
  const skipGasApi = skip || gasCount === 0 || commodity === commodities.energy;

  const {
    data: electricityInvoices,
    loading: electricityLoading,
    error: electricityError,
  } = useInvoices(taxNo, meterSupplyNo, supplyType, meters, skipElectricityApi);

  const {
    invoices: gasInvoices,
    loading: gasLoading,
    error: gasError,
  } = useGasInvoices(skipGasApi);

  if ((skipGasApi && skipElectricityApi) || gasLoading || electricityLoading) {
    return {
      loading: gasLoading || electricityLoading,
      error: false,
      data: [],
    };
  }

  const gasMeterNo = meterSupplyNo ? meterSupplyNo?.slice(-14) : null;
  const gasResults = !!meterSupplyNo
    ? gasInvoices.filter((invoice) => invoice.MeterNo === gasMeterNo)
    : gasInvoices;

  const results = gasResults.concat(electricityInvoices);

  const take = isEmptyString(taxNo) ? meterBillsPageSize : allBillsPageSize;

  customHistorySortByFieldDescendingWithSameDate(
    results,
    'invoiceNo',
    'issueDate'
  );

  const latestInvoicesDictionary = {};
  const finalList = results
    ?.map((invoice) => {
      if (!latestInvoicesDictionary[invoice?.MeterId]) {
        latestInvoicesDictionary[invoice?.MeterId] = true;
        return { ...invoice, IsLastInvoice: true };
      }

      return {
        ...invoice,
        IsLastInvoice: false,
      };
    })
    .slice(0, take);

  return {
    loading: electricityLoading || gasLoading,
    error: electricityError || gasError,
    data: finalList,
  };
};

export default useInvoicesHistory;
