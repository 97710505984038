import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput } from 'components/Inputs/TextInput';
import { SupplyNumberInput } from 'components/Inputs/SupplyNumberInput';
import PostalCodeInput from 'components/Inputs/PostalCodeInput';
import supplyNumberTypes from 'utils/product/enums/supplyNumberTypes';

import styles from './MovingDestination.module.scss';

const MovingDestination = () => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { t } = useTranslation(['requests']);

  return (
    <article className={styles.container}>
      <h3 className={styles.title}>{t(`requests:moving.new-meter`)}</h3>
      <SupplyNumberInput
        register={register}
        errors={errors}
        label={t(`requests:moving.new-supply`)}
        name={'newSupplyNumber'}
        supplyNumberType={supplyNumberTypes.electricityOrGas}
        showInfo={false}
      />
      <TextInput
        register={register}
        errors={errors}
        name={'newSupplyAddress'}
        label={t(`requests:moving.street`)}
      />
      <TextInput
        register={register}
        errors={errors}
        name={'newSupplyAddressNo'}
        label={t(`requests:moving.street-no`)}
      />
      <PostalCodeInput
        register={register}
        errors={errors}
        postalCodeName="newSupplyPostalCode"
        postalCodeIdName="newSupplyPostalCodeId"
        onChangeHandler={({ area }) => {
          setValue('newSupplyArea', area);
        }}
      />
    </article>
  );
};

export default MovingDestination;
