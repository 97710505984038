import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmptyString } from 'utils/stringUtils';

import get from 'lodash/get';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { StandardInput } from './Inputs';

const ConfirmPasswordInput = ({
  register,
  errors,
  name = 'confirmPassword',
  comparedInputName,
  label,
  watch,
  required = true,
  ...rest
}) => {
  const { t } = useTranslation(['inputs']);
  const baseName = 'inputs:errors';

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { ref, ...registerRest } =
    register(name, {
      required: { value: required, message: t(`${baseName}.required`) },
      validate: (value) => {
        if (!required && isEmptyString(value)) return true;
        return (
          value === watch(comparedInputName) ||
          t(`${baseName}.confirm-password`)
        );
      },
    }) || {};
  return (
    <>
      <StandardInput
        inputRef={ref}
        label={label}
        type={showPassword ? 'text' : 'password'}
        errorMessage={get(errors, `${name}.message`, null)}
        error={get(errors, `${name}`, null) !== null}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        {...rest}
        {...registerRest}
      />
    </>
  );
};

export { ConfirmPasswordInput };
