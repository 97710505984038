import React from 'react';
import { useTranslation } from 'react-i18next';

import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import IconText from 'components/IconText/IconText';
import { ReactComponent as PlugIcon } from 'assets/icons/PlugIcon.svg';
import { ReactComponent as CookingIcon } from 'assets/icons/CookingIcon.svg';
import { ReactComponent as FridgeIcon } from 'assets/icons/FridgeIcon.svg';
import { ReactComponent as WashingIcon } from 'assets/icons/WashingIcon.svg';
import { ReactComponent as TemperatureIcon } from 'assets/icons/TemperatureIcon.svg';

import styles from './EnergySavingWidget.module.scss';

const EnergySavingWidget = () => {
  const { t } = useTranslation(['energySaving']);

  return (
    <Widget size="m" title={<WidgetHeader title={t('energySaving:title')} />}>
      <section className={styles.section}>
        <IconText
          Icon={<PlugIcon />}
          content={t('energySaving:unplug.title')}
          iconClass={styles.icon}
          contentClass={styles.title}
          containerClass={styles['title-container']}
          distance="xs"
        />
        <p>{t('energySaving:unplug.tip1')}</p>
        <p>{t('energySaving:unplug.tip2')}</p>
        <p>{t('energySaving:unplug.tip3')}</p>
        <p>{t('energySaving:unplug.tip4')}</p>
      </section>
      <section className={styles.section}>
        <IconText
          Icon={<CookingIcon />}
          content={t('energySaving:cooking.title')}
          iconClass={styles.icon}
          contentClass={styles.title}
          containerClass={styles['title-container']}
          distance="xs"
        />
        <p>{t('energySaving:cooking.tip1')}</p>
        <p>{t('energySaving:cooking.tip2')}</p>
      </section>
      <section className={styles.section}>
        <IconText
          Icon={<FridgeIcon />}
          content={t('energySaving:fridge.title')}
          iconClass={styles.icon}
          contentClass={styles.title}
          containerClass={styles['title-container']}
          distance="xs"
        />
        <p>{t('energySaving:fridge.tip1')}</p>
        <p>{t('energySaving:fridge.tip2')}</p>
        <p>{t('energySaving:fridge.tip3')}</p>
      </section>
      <section className={styles.section}>
        <IconText
          Icon={<WashingIcon />}
          content={t('energySaving:washing.title')}
          iconClass={styles.icon}
          contentClass={styles.title}
          containerClass={styles['title-container']}
          distance="xs"
        />
        <p>{t('energySaving:washing.tip1')}</p>
        <p>{t('energySaving:washing.tip2')}</p>
        <p>{t('energySaving:washing.tip3')}</p>
      </section>
      <section className={styles.section}>
        <IconText
          Icon={<TemperatureIcon />}
          content={t('energySaving:temperature.title')}
          contentClass={styles.title}
          containerClass={styles['title-container']}
          distance="xs"
        />
        <p>{t('energySaving:temperature.tip1')}</p>
        <p>{t('energySaving:temperature.tip2')}</p>
        <p>{t('energySaving:temperature.tip3')}</p>
        <p>{t('energySaving:temperature.tip4')}</p>
      </section>
    </Widget>
  );
};

export default EnergySavingWidget;
