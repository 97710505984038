import React from 'react';
import { useTranslation } from 'react-i18next';

import LoginWidget from 'pages/onboarding/LoginWidget';
import OnboardingLayout from 'layouts/OnboardingLayout';
import ForgotPasswordContent from './ForgotPasswordContent';

import styles from './ForgotPassword.module.scss';

const ForgotPassword = () => {
  const { t } = useTranslation(['onboarding']);
  return (
    <OnboardingLayout
      head={{
        title: t('onboarding:loginWidgetContent.forgot-password'),
        metaTags: [
          {
            name: 'title',
            content: t('onboarding:loginWidgetContent.forgot-password'),
          },
        ],
      }}
    >
      <LoginWidget containerClass={styles.widgetContainer}>
        <ForgotPasswordContent />
      </LoginWidget>
    </OnboardingLayout>
  );
};

export default ForgotPassword;
