import api from 'api/api';

const loginService = ({ email, password }) => {
  return api.post('/user/token', { userPrincipalName: email, password });
};

const refreshTokenService = (refresh_token) => {
  return api.post('/user/token/refresh', { refresh_token });
};

export { loginService, refreshTokenService };
