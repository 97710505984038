import {
  contestParticipationStatuses,
  winnerStatuses,
  contestDrawStatuses,
} from 'utils/product/enums/contests';
import { formatDate } from 'utils/dateUtils';
import { benefitTypes } from 'utils/product/enums/waveEnums';

export const getPrizeType = (contest, position) => {
  return contest?.prizesConfiguration?.find(
    (item) =>
      item?.winningPositionStart <= position &&
      item?.winningPositionEnd >= position,
  )?.prizes?.[0]?.type;
};

const checkIfUserIsEvenARejectedWinner = (
  drawStatus,
  userParticipationStatus,
) =>
  drawStatus === contestDrawStatuses.COMPLETED &&
  (userParticipationStatus === contestParticipationStatuses.winner ||
    userParticipationStatus === contestParticipationStatuses.rejected);

const checkIfUserIsValidWinner = (userParticipationStatus) =>
  userParticipationStatus === contestParticipationStatuses.winner;

const checkIfUserIsRejectedWinner = (winnerResponse) =>
  winnerResponse === winnerStatuses?.rejectedAutomatically;

const checkIfIsLooser = (drawStatus, participationsCount, isWinner) =>
  drawStatus === contestDrawStatuses.COMPLETED &&
  !!participationsCount &&
  !isWinner;

export const getWinnerInfo = (participations, contest) => {
  const participationOfInterest = participations?.find(
    (participation) =>
      contest?.contestId === participation?.contest &&
      checkIfUserIsEvenARejectedWinner(
        contest?.drawStatus,
        participation.participationStatus,
      ),
  );

  if (!participationOfInterest) return {};

  const userIsWinner = checkIfUserIsValidWinner(
    participationOfInterest.participationStatus,
  );

  const winnerRejected = checkIfUserIsRejectedWinner(
    participationOfInterest?.winnerResponse,
  );

  const position = participationOfInterest?.winnerPosition;

  const prizeTypeAndWaveId = getPrizeTypeAndWaveId(contest, position);

  const isPrizeUnclaimed =
    participationOfInterest?.winnerResponse === winnerStatuses.pending;

  const claimUntil = participationOfInterest?.winnerResponseDeadline
    ? formatDate(participationOfInterest?.winnerResponseDeadline)
    : '';

  return {
    userIsWinner,
    winnerRejected,
    position,
    prizeTypeAndWaveId,
    isPrizeUnclaimed,
    claimUntil,
  };
};

const getPrizeTypeAndWaveId = (contest, position) => {
  const prize = contest?.prizesConfiguration?.find(
    (item) =>
      item?.winningPositionStart <= position &&
      item?.winningPositionEnd >= position,
  )?.prizes?.[0];

  const type = prize?.type;

  const waveId =
    type === benefitTypes.COUPON
      ? prize?.coupon?.groupId
      : prize?.benefitTemplate?._id;

  return {
    type,
    waveId,
  };
};

const checkIfParticipationLimitReached = (
  isExpired,
  participationsPerParticipantLimit,
  participationsCount,
) =>
  !isExpired &&
  !!participationsPerParticipantLimit &&
  participationsCount >= participationsPerParticipantLimit;

const checkIfCanMakeMoreParticipations = (
  isExpired,
  participationsCount,
  participationsPerParticipantLimit,
  redeemPoints,
) =>
  !isExpired &&
  participationsCount > 0 &&
  (participationsCount < participationsPerParticipantLimit ||
    !participationsPerParticipantLimit) &&
  redeemPoints > 0;

const checkForPendingDraw = (isExpired, participationsCount, drawCompleted) =>
  isExpired &&
  participationsCount > 0 &&
  drawCompleted !== contestDrawStatuses.COMPLETED;

const checkIfIsWinnerWithServiceOrProductPrize = (isWinner, prizeType) =>
  isWinner &&
  (prizeType === benefitTypes.PRODUCT || prizeType === benefitTypes.SERVICE);

const checkIfIsWinnerWithCoupon = (isWinner, prizeType) =>
  isWinner && prizeType === benefitTypes.COUPON;

const checkIfIsExpiredWithoutParticipation = (isExpired, participationsCount) =>
  isExpired && !participationsCount;

export const getContestStatusInfo = ({
  contest,
  participationsCount,
  userIsWinner,
  winnerRejected,
  prizeType,
}) => {
  const {
    redeemPoints,
    isExpired,
    participationsPerParticipantLimit,
    drawStatus,
  } = contest || {};

  const expiredWithoutParticipation = checkIfIsExpiredWithoutParticipation(
    isExpired,
    participationsCount,
  );

  const hasReachedParticipationLimit = checkIfParticipationLimitReached(
    isExpired,
    participationsPerParticipantLimit,
    participationsCount,
  );

  const canMakeMoreParticipations = checkIfCanMakeMoreParticipations(
    isExpired,
    participationsCount,
    participationsPerParticipantLimit,
    redeemPoints,
  );

  const expiredContestPendingDraw = checkForPendingDraw(
    isExpired,
    participationsCount,
    drawStatus,
  );

  const isWinnerWithServiceOrProductPrize =
    checkIfIsWinnerWithServiceOrProductPrize(userIsWinner, prizeType);

  const isWinnerWithCouponPrize = checkIfIsWinnerWithCoupon(
    userIsWinner,
    prizeType,
  );

  const isRejectedWinner = winnerRejected;

  const isLoser =
    !winnerRejected &&
    checkIfIsLooser(drawStatus, participationsCount, userIsWinner);

  return {
    expiredWithoutParticipation,
    hasReachedParticipationLimit,
    canMakeMoreParticipations,
    expiredContestPendingDraw,
    isWinnerWithServiceOrProductPrize,
    isRejectedWinner,
    isWinnerWithCouponPrize,
    isLoser,
    userIsWinner,
  };
};

export const getPrizeDescription = (prize, prizeType) => {
  const prizeData = prize?.data?.[0]?.content;
  if (prizeType === benefitTypes.COUPON) {
    return prizeData?.couponDescription || prizeData?.title;
  }

  return prizeData?.description;
};

export const getInfoBoxContainerClass = (
  canMakeMoreParticipations,
  userIsWinner,
) => {
  if (canMakeMoreParticipations) return 'container-makeMoreEntries';
  if (userIsWinner) return 'container-winner';
  return 'container';
};
