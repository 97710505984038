import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import accountCategoryTypes from 'utils/product/enums/accountCategoryTypes';
import useAccountMeters from 'data/useAccountMeters';
import atLeastOneCategorySelected from 'utils/product/requests/atLeastOneCategorySelected';
import useRequestFormData from 'hooks/useRequestFormData';
import generateRequestData from 'utils/product/requests/generateRequestData';
import { requestTypes } from 'utils/product/enums/requests';
import { createRequest } from 'api/requests';
import getPostData from 'utils/product/requests/getPostData';
import { scrollTo } from 'utils/scroll';
import { trackRequestsDetailViewEvent } from 'utils/product/tracking/events';
import useError from 'hooks/useError';

import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import PersonalDataHome from 'components/Requests/Shared/PersonalDataHome';
import PersonalDataBusiness from 'components/Requests/Shared/PersonalDataBusiness';
import BillingCategories from './BillingCategories';
import InvoiceData from './InvoiceData';
import MeterData from './MeterData';
import { TextInput } from 'components/Inputs/TextInput';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import {
  SuccessBox,
  LocalisedErrorBox,
} from 'components/MessageBox/MessageBox';

import styles from './BillingQuestionsRequest.module.scss';

const BillingQuestionsRequest = ({ account, accountType, defaultValues }) => {
  const { t } = useTranslation(['requests']);
  const { meters, loading: metersLoading } = useAccountMeters({
    onlyActive: true,
  });
  const formLabels = useRequestFormData(
    requestTypes.billingQuestions,
    accountType
  );
  const formMethods = useForm({ defaultValues: { atLeastOneSelected: false } });
  const {
    formState: { errors },
  } = formMethods;

  const [initialValues, setInitialValues] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { error, handleError, clearError } = useError();

  const onSubmit = (data) => {
    const postData = getPostData({
      requestType: requestTypes.billingQuestions,
      accountType,
      data,
      defaultValues,
    });
    if (atLeastOneCategorySelected(postData))
      formMethods.clearErrors('atLeastOne');
    else {
      formMethods.setError('atLeastOne', {
        type: 'manual',
        message: t(`requests:billing.at-least-one`),
      });
      return;
    }
    setIsSuccess(false);
    clearError();
    setIsLoading(true);

    const meter = meters ? meters.find((x) => x.Id === postData.meterId) : null;

    const initialValuesString = generateRequestData({
      labels: formLabels,
      data: initialValues,
      accountId: account.Id,
      requestType: requestTypes.billingQuestions,
    });
    const postString = generateRequestData({
      labels: formLabels,
      data: postData,
      accountId: account.Id,
      requestType: requestTypes.billingQuestions,
      meterId: meter?.Id,
      commodity: meter?.Commodity,
    });

    if (initialValuesString.RequestText === postString.RequestText) {
      handleError('form-is-same', 'forms');
      setIsLoading(false);
      scrollTo(0, 0);
      return;
    }

    createRequest(postString)
      .then((result) => {
        if (result.data.ResponseCode === 200) {
          if (result.data.RequestId) {
            trackRequestsDetailViewEvent(
              'request_activation_success',
              t(`requests:types.type-${requestTypes.billingQuestions}`)
            );
            setIsSuccess(true);
            setInitialValues(postData);
          }
          return;
        }
        handleError('general', 'requests');
        trackRequestsDetailViewEvent(
          'request_activation_fail',
          t(`requests:types.type-${requestTypes.billingQuestions}`)
        );
      })
      .catch((error) => {
        handleError(error, 'requests');
        trackRequestsDetailViewEvent(
          'request_activation_fail',
          t(`requests:types.type-${requestTypes.billingQuestions}`)
        );
      })
      .then(() => {
        setIsLoading(false);
        scrollTo(0, 0);
      });
  };
  return (
    <Widget
      size="m"
      title={
        <WidgetHeader
          title={t(`requests:types.type-${requestTypes.billingQuestions}`)}
        />
      }
    >
      <SuccessBox
        open={isSuccess}
        closeBox={() => setIsSuccess(false)}
        header={t(`requests:submit-success-title`)}
        message={t(`requests:submit-success-subtitle`)}
        distanceTop=""
      />
      <LocalisedErrorBox
        open={error !== null}
        closeBox={clearError}
        {...(error || {})}
        distanceTop=""
      />

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
          {accountType === accountCategoryTypes.home ? (
            <PersonalDataHome
              account={account}
              defaultValues={defaultValues}
              editable={false}
              requestType={requestTypes.billingQuestions}
            />
          ) : (
            <PersonalDataBusiness
              account={account}
              defaultValues={defaultValues}
              editable={false}
              requestType={requestTypes.billingQuestions}
            />
          )}
          <MeterData
            meters={meters}
            loading={metersLoading}
            defaultMeter={meters.length === 1 ? meters[0] : null}
          />
          <InvoiceData />
          <BillingCategories />
          <TextInput
            register={formMethods.register}
            errors={errors}
            name="reason"
            label={t(`requests:billing.reason`)}
            defaultValue={''}
            required={false}
            multiline={true}
            minRows={1}
            maxRows={4}
          />
          <TextInput
            register={formMethods.register}
            errors={errors}
            name="comments"
            label={t(`requests:billing.comments`)}
            defaultValue={''}
            required={false}
            multiline={true}
            minRows={1}
            maxRows={4}
          />
          <article className={styles.buttonContainer}>
            <PrimaryButtonLoader
              loading={isLoading}
              disabled={isLoading || metersLoading}
              type="submit"
            >
              {t('requests:requests.submit')}
            </PrimaryButtonLoader>
          </article>
        </form>
      </FormProvider>
    </Widget>
  );
};

export default BillingQuestionsRequest;
