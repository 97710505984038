import Big from 'big.js';
import { conventFromCents } from 'utils/moneyUtils';
import { collectionOptionTypes } from '../enums/cmsEnums';

export const getBillPointsReward = (amount, pointsPerEuro) => {
  const points = new Big(amount ?? 0)
    .times(pointsPerEuro)
    .round(0, Big.roundDown);

  return points;
};

export const getPointsOfCategory = (collectionOptionsByType, category) => {
  const benefit = collectionOptionsByType[category];

  return benefit?.benefits && benefit?.benefits.length > 0
    ? benefit?.benefits[0].value
    : 0;
};

export const getWaveId = (collectionOptionsByType, category) => {
  const benefit = collectionOptionsByType[category];

  return benefit?.benefits && benefit?.benefits.length > 0
    ? benefit?.waveId
    : 0;
};

export const getRewardPoints = (response, waveId) => {
  const transaction = response?.transactions.find(
    (x) => x.rewardSchemeId === process.env.REACT_APP_LOYALTY_ENERGYPOINTS_ID
  );
  if (transaction) {
    const subtransactions = transaction?.subtransactions.filter(
      (subtransaction) => subtransaction?.associatedWaveEntity?.id === waveId
    );

    if (subtransactions.length > 0) {
      var sum = subtransactions.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.amount;
      }, 0);
      return conventFromCents(sum);
    }
  }
  return 0;
};

export const getBillPaymentRewards = (loyaltyResponse, eventTypeIds) => {
  const transactions = loyaltyResponse?.transactions ?? [];
  if (transactions?.length === 0 || !eventTypeIds) return {};

  const energyPointsTransaction = transactions.find(
    (x) => x.rewardSchemeId === process.env.REACT_APP_LOYALTY_ENERGYPOINTS_ID
  );
  if (energyPointsTransaction) {
    const _points = energyPointsTransaction?.amount;

    if (energyPointsTransaction.subtransactions?.length > 0) {
      const _billPoints = energyPointsTransaction.subtransactions.find(
        (x) =>
          x.associatedWaveEntity.id ===
          eventTypeIds[collectionOptionTypes.BILL_PAYMENT]
      )?.amount;

      const _bonusPoints = energyPointsTransaction.subtransactions.find(
        (x) =>
          x.associatedWaveEntity.id ===
          eventTypeIds[collectionOptionTypes.BILL_PAYMENT_DUE]
      )?.amount;
      return {
        points: Math.floor(conventFromCents(_points)),
        billPoints: Math.floor(conventFromCents(_billPoints)),
        bonusPoints: Math.floor(conventFromCents(_bonusPoints)),
      };
    }
  }
  return { points: 0, billPoints: 0, bonusPoints: 0 };
};
