import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  trackPaymentSuccessEvent,
  trackPaymentFailureEvent,
} from 'utils/product/tracking/events';
import { getTrackingEnergyType } from 'utils/product/tracking/trackingLabels';
import useDialog from 'hooks/useDialog';

import PaymentOverlay from 'components/Payment/PaymentOverlay';
import PaymentSuccess from 'components/Payment/PaymentSuccess';
import PaymentError from 'components/Payment/PaymentError';
import { rewardPayment, receivePoints } from 'api/loyalty';
import paymentTypes from 'utils/product/enums/paymentTypes';
import { useAuthUser } from 'utils/AuthUser';
import { formatToUTC, endOfDay } from 'utils/dateUtils';
import { conventToCents } from 'utils/moneyUtils';
import { useCollectionOptions } from 'data/useCollectionOptions';
import { getWaveId } from 'utils/product/loyalty/pointsUtils';
import { collectionOptionTypes } from 'utils/product/enums/cmsEnums';
import { isMeterEligibleForReward } from 'utils/product/loyalty/recurringUtils';
import { logWaveServiceFailure } from 'utils/product/logs';
import { isFullPaymentForLoyaltyReward } from 'utils/product/paymentUtils';
import { ruleTypes } from 'utils/product/enums/waveEnums';
import { mutateLoyaltyHistory } from 'data/useLoyaltyHistory';
import { mutateLoyaltyPoints } from 'data/useRewardSchemeAccounts';

const PaymentWidget = ({
  open,
  closeDialog,
  openDialog,
  paymentAmount,
  paymentCode,
  paymentType,
  meterId,
  meter,
  invoiceNo,
  commodity,
  installment = null,
  paymentDueDate,
  isRecurringCardUpdate,
  onPaymentSuccess = () => {},
  onPaymentFailure = () => {},
}) => {
  const paymentSuccessDialog = useDialog();
  const paymentErrorDialog = useDialog();
  const [loyaltyResponse, setLoyaltyResponse] = useState();
  const [paymentAlertMessages, setPaymentAlertMessages] = useState([]);
  const [loyaltyResponseLoading, setLoyaltyResponseLoading] = useState(false);
  const [paymentResult, setPaymentResult] = useState();
  const { loyaltyCustomer, isLoyaltyCustomer, loyaltyCustomerId } =
    useAuthUser();
  const { i18n, t } = useTranslation('bills');

  const { collectionOptionsByType } = useCollectionOptions(i18n.language);

  const waveId =
    paymentType === paymentTypes.recurring
      ? getWaveId(collectionOptionsByType, collectionOptionTypes.DEBIT_PAYMENT)
      : null;

  const crmFailureMessage = t(
    `payment-flow.payment-crm-failure${
      paymentType === paymentTypes.thirdParty ? '-third-party' : ''
    }`,
  );

  const paymentSuccess = (data) => {
    closeDialog();
    !data?.crmResult && setPaymentAlertMessages([crmFailureMessage]);

    const isSettlementPayment = paymentType === paymentTypes.settlement;
    const isBillPayment = paymentType === paymentTypes.bill;

    if (isLoyaltyCustomer && !!data && (isBillPayment || isSettlementPayment)) {
      setLoyaltyResponseLoading(true);
      setLoyaltyResponse(null);

      const paidAmount = data.paymentAmount;
      const amountForBonusReward = meter?.CurrentBalance;

      const postData = {
        customer: {
          customerId: loyaltyCustomer.customerId,
        },
        externalId: data.approvalCode,
        totalPrice: conventToCents(paidAmount),
        billExpirationDate: endOfDay(paymentDueDate),
        dateTime: formatToUTC(new Date()),
        isFullPayment: isFullPaymentForLoyaltyReward(
          amountForBonusReward,
          paidAmount,
        ),
        ...(isSettlementPayment && { tags: ['settlement'] }),
      };

      rewardPayment(postData)
        .then((response) => {
          setLoyaltyResponse(response?.data);
          setLoyaltyResponseLoading(false);
          mutateLoyaltyHistory(loyaltyCustomerId);
          mutateLoyaltyPoints(loyaltyCustomerId);
        })
        .catch((x) => {
          setPaymentAlertMessages((prev) => [
            ...prev,
            t('payment-flow.payment-wave-failure'),
          ]);
          logWaveServiceFailure(
            'Loyalty | Not rewarded payment',
            loyaltyCustomerId,
            waveId,
            postData,
          );
        })
        .then((x) => setLoyaltyResponseLoading(false));
    }
    if (
      isLoyaltyCustomer &&
      !!data &&
      paymentType === paymentTypes.recurring &&
      isMeterEligibleForReward(meter)
    ) {
      setLoyaltyResponseLoading(true);
      setLoyaltyResponse(null);

      const postData = {
        customer: {
          customerId: loyaltyCustomerId,
        },
        type: 'CUSTOM',
        ruleId: waveId,
        items: [{ id: meterId }],
        tolerantToErrorCodes: [
          ruleTypes.LIMIT__NOT_WITHIN_TOTAL_LIMITS_PER_ITEM_PER_CUSTOMER_LIMIT,
        ],
      };

      receivePoints(postData)
        .then((res) => {
          setLoyaltyResponse(res);
          setLoyaltyResponseLoading(false);
          mutateLoyaltyHistory(loyaltyCustomerId);
          mutateLoyaltyPoints(loyaltyCustomerId);
        })
        .catch((x) => {
          setPaymentAlertMessages([t('payment-flow.payment-wave-failure')]);
          logWaveServiceFailure(
            'Loyalty | Not rewarded standing order activation',
            loyaltyCustomerId,
            waveId,
            postData,
          );
        })
        .then((x) => setLoyaltyResponseLoading(false));
    }

    setPaymentResult(data);
    paymentSuccessDialog.show();
    trackPaymentSuccessEvent(paymentType, getTrackingEnergyType(commodity));
  };
  const paymentFailed = (data) => {
    closeDialog();
    setPaymentResult(data);
    paymentErrorDialog.show();
    onPaymentFailure(data);
    trackPaymentFailureEvent(paymentType, getTrackingEnergyType(commodity));
  };

  return (
    <>
      {open && (
        <PaymentOverlay
          open={open}
          closeDialog={closeDialog}
          openDialog={openDialog}
          paymentAmount={paymentAmount}
          paymentCode={paymentCode}
          meterId={meterId}
          invoiceNo={invoiceNo}
          commodity={commodity}
          onSuccess={paymentSuccess}
          onError={paymentFailed}
          paymentType={paymentType}
          isCardUpdate={isRecurringCardUpdate}
        />
      )}
      <PaymentError
        open={paymentErrorDialog.isOpen}
        closeDialog={() => {
          paymentErrorDialog.close();
          openDialog();
        }}
        paymentResult={paymentResult}
        paymentType={paymentType}
      />
      <PaymentSuccess
        open={paymentSuccessDialog.isOpen}
        loading={loyaltyResponseLoading}
        alertMessages={paymentAlertMessages}
        closeDialog={() => {
          paymentSuccessDialog.close();
          onPaymentSuccess({ installment });
        }}
        openDialog={paymentSuccessDialog.show}
        paymentResult={paymentResult}
        paymentType={paymentType}
        loyaltyResponse={loyaltyResponse}
        isLoyaltyCustomer={isLoyaltyCustomer}
        waveId={waveId}
        isRecurringCardUpdate={isRecurringCardUpdate}
      />
    </>
  );
};

export default PaymentWidget;
