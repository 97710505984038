import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import useError from 'hooks/useError';
import useDialog from 'hooks/useDialog';
import { setUserRegister } from 'api/user';
import supplyNumberTypes from 'utils/product/enums/supplyNumberTypes';
import {
  trackGuestEvent,
  trackLoginEvent,
} from 'utils/product/tracking/events';
import { EmailInput } from 'components/Inputs/EmailInput';
import { TaxpayerIdInput } from 'components/Inputs/TaxpayerIdInput';
import { SupplyNumberInput } from 'components/Inputs/SupplyNumberInput';
import { PrimaryButton } from 'components/Buttons/Buttons';
import { ReactComponent as CancelIcon } from 'assets/icons/CancelIcon.svg';
import { NewPasswordInput } from 'components/Inputs/NewPasswordInput';

import { LocalisedErrorBox } from 'components/MessageBox/MessageBox';
import RegisterEmailModal from 'pages/onboarding/CreateUser/RegisterEmailModal';
import Terms from 'components/OnboardingNewClient/Shared/Terms';

import styles from './CreateUserContent.module.scss';

const CreateUserContent = () => {
  const { t } = useTranslation(['onboarding']);
  const baseName = 'onboarding:createUserContent';
  const generalName = 'onboarding:general';
  const { error, handleError, clearError } = useError();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const registerEmailDialog = useDialog();
  const [formData, setFormData] = useState();

  const onSubmit = (data) => {
    clearError();
    setFormData(data);
    registerEmailDialog.show();
    trackGuestEvent('loginscreen_create_user_submit');
  };

  const handleModalButtonClick = (setEmailAsPrimary) => {
    setEmailAsPrimary === true
      ? registerEmailDialog.startLoading()
      : registerEmailDialog.startSecondaryLoading();

    const postData = {
      setEmailAsPrimary,
      ...formData,
    };

    setUserRegister(postData)
      .then(() => {
        trackLoginEvent('loginscreen_create_user_submit_success');
        navigate('/user-created');
      })
      .catch((error) => {
        trackLoginEvent('loginscreen_create_user_submit_fail');
        handleError(error, 'register');
      })
      .then(() => {
        registerEmailDialog.stopLoading(false);
        registerEmailDialog.stopSecondaryLoading(false);
        registerEmailDialog.close();
      });
  };

  return (
    <>
      <RegisterEmailModal
        open={registerEmailDialog.isOpen}
        closeDialog={registerEmailDialog.close}
        email={formData?.email}
        handleButtonClick={handleModalButtonClick}
        yesLoading={registerEmailDialog.isLoading}
        noLoading={registerEmailDialog.isSecondaryLoading}
      />
      <section className={styles.topSection}>
        <h3 className={styles.topHeader}>{t(`${baseName}.create-user`)}</h3>
        <Link to="/auth" aria-label="back to login">
          <CancelIcon />
        </Link>
      </section>
      <LocalisedErrorBox
        open={error !== null}
        closeBox={clearError}
        {...(error || {})}
        distanceBottom=""
      />
      <form
        className={styles.container}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <h3 className={styles.sectionHeader}>{t(`${baseName}.user-info`)}</h3>
        <EmailInput
          register={register}
          errors={errors}
          label={t(`${generalName}.email`)}
        />
        <NewPasswordInput
          register={register}
          errors={errors}
          name="password"
          label={t(`${generalName}.password`)}
        />
        <p className={styles.fadedDescription}>
          {t(`${baseName}.password-desc`)}
        </p>
        <h3 className={styles.sectionHeader}>
          {t(`${baseName}.personal-info`)}
        </h3>
        <p className={styles.personalInfoDescription}>
          {t(`${baseName}.personal-info-desc`)}
        </p>
        <TaxpayerIdInput
          register={register}
          errors={errors}
          label={t(`${baseName}.taxpayer-id`)}
        />
        <SupplyNumberInput
          register={register}
          errors={errors}
          label={t(`${baseName}.supply-number`)}
          supplyNumberType={supplyNumberTypes.electricityOrGas}
        />
        <p className={styles.fadedDescription}>
          {t(`${baseName}.supply-number-desc`)}
        </p>
        <Terms containerClass={styles.terms} />
        <section className={styles.submitButton}>
          <PrimaryButton type="submit">{t(`${baseName}.submit`)}</PrimaryButton>
        </section>
      </form>
    </>
  );
};

export default CreateUserContent;
