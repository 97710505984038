import { jwtDecode } from 'jwt-decode';
import accountCategoryTypes from 'utils/product/enums/accountCategoryTypes';
import accountCategories from 'utils/product/enums/accountCategories';
import accountTypes from 'utils/product/enums/accountTypes';
import { largeAccountMetersThreshold } from 'utils/product/config';
import { formatGuid, isEmptyString } from 'utils/stringUtils';
import authStorage from 'utils/storage/authStorage';

export const isUserB2B = (user) => {
  return (
    user &&
    (user.AccountType === accountTypes.B2B ||
      user.hasMVMeter ||
      user.CountOfActiveMeters > largeAccountMetersThreshold)
  );
};

export const getAccountType = (category) => {
  return category === accountCategories.private ||
    category === accountCategories.freelancer
    ? accountCategoryTypes.home
    : accountCategoryTypes.business;
};

export const accountHasSolarMeters = (user) => user && user.hasPVs === true;

export const getFirstAndLastName = (account) => {
  const { CompanyName, FirstName, LastName, AccountType } = account || {};
  const [companyFirstName, companySecondName] =
    (CompanyName && CompanyName.split(/\s+/)) || [];

  const firstName =
    AccountType === accountTypes.B2C && !isEmptyString(FirstName)
      ? FirstName
      : companyFirstName
        ? companyFirstName
        : '';
  const lastName =
    AccountType === accountTypes.B2C && !isEmptyString(LastName)
      ? LastName
      : companySecondName
        ? companySecondName
        : '';

  return { firstName, lastName };
};

export const getTopbarName = (account) => {
  const { FirstName, CompanyName, AccountType } = account || {};

  return AccountType === accountTypes.B2C && !isEmptyString(FirstName)
    ? FirstName
    : CompanyName;
};

export const decodeToken = (token) => {
  const decodedToken = token ? jwtDecode(token) : {};
  const {
    name,
    extension_isParent: isParent,
    extension_AccountId: accountId,
    extension_childAccountIds: connectedAccountIds = [],
    emails,
  } = decodedToken;
  return {
    name,
    accountId,
    connectedAccountIds,
    isParent,
    emails,
    email: emails?.length > 0 ? emails[0] : '',
  };
};

export const getConnectedAccounts = (user) => {
  const token = authStorage.getToken();
  const { isParent } = decodeToken(token);

  const _currentAccount = {
    Id: formatGuid(user.Id),
    FirstName: user.FirstName,
    LastName: user.LastName,
    CompanyName: user.CompanyName,
    AccountType: user.AccountType,
    TaxNo: user.TaxNo,
    IsParent: user.IsParent,
  };

  if (isParent === true) {
    return [
      _currentAccount,
      ...user.ConnectedAccounts?.map((_account) => {
        return {
          ..._account,
          Id: formatGuid(_account.Id),
        };
      }),
    ];
  }
  return [_currentAccount];
};
