import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import PostalCodeInput from 'components/Inputs/PostalCodeInput';
import { EmailInput } from 'components/Inputs/EmailInput';
import { TextInput } from 'components/Inputs/TextInput';
import { TelephoneInput } from 'components/Inputs/TelephoneInput';
import { NewPasswordInput } from 'components/Inputs/NewPasswordInput';
import { TaxpayerIdInput } from 'components/Inputs/TaxpayerIdInput';
import { TaxOfficeInput } from 'components/Inputs/TaxOfficeInput';

import styles from './CompanyDetails.module.scss';

const CompanyDetails = ({ defaultValues = {}, onUpdate = () => {} }) => {
  const { t } = useTranslation(['newClient', 'onboarding']);
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  const helperTextClasses = {
    root: styles.passwordInfo,
  };

  const handleChange = ({ postalCodeId }) => {
    setValue(`companyPostalCodeId`, postalCodeId);
    onUpdate({
      companyPostalCodeId: postalCodeId,
    });
  };

  return (
    <article className={styles.container}>
      <h3 className={styles.header}>{t(`newClient:companyDetails.header`)}</h3>

      <TextInput
        register={register}
        errors={errors}
        name="companyName"
        label={t(`newClient:companyDetails.companyName`)}
        defaultValue={defaultValues?.companyName}
      />
      <TextInput
        register={register}
        errors={errors}
        name="companyStreet"
        label={t(`newClient:companyDetails.companyStreet`)}
        defaultValue={defaultValues?.companyStreet}
      />

      <TextInput
        register={register}
        errors={errors}
        name="companyStreetNo"
        label={t(`newClient:sharedDetails.streetNo`)}
        defaultValue={defaultValues?.companyStreetNo}
      />
      <PostalCodeInput
        register={register}
        errors={errors}
        postalCodeName="companyPostalCode"
        postalCodeValue={defaultValues?.companyPostalCode}
        postalCodeIdName="companyPostalCodeId"
        postalCodeIdValue={defaultValues?.companyPostalCodeId}
        onChangeHandler={handleChange}
      />
      <TaxpayerIdInput
        register={register}
        errors={errors}
        name="taxNo"
        label={t(`newClient:sharedDetails.taxNo`)}
        defaultValue={defaultValues?.taxNo}
      />
      <TaxOfficeInput
        register={register}
        errors={errors}
        name="taxOffice"
        value={defaultValues?.taxOffice}
        defaultValue={defaultValues?.taxOffice}
      />
      <TelephoneInput
        register={register}
        errors={errors}
        name="phone"
        label={t(`newClient:sharedDetails.phone`)}
        defaultValue={defaultValues?.phone}
        required={false}
      />
      <TelephoneInput
        register={register}
        errors={errors}
        name="mobile"
        label={t(`newClient:sharedDetails.mobile`)}
        defaultValue={defaultValues?.mobile}
        mobileOnly={true}
      />
      <EmailInput
        register={register}
        errors={errors}
        name="email"
        label={t(`newClient:sharedDetails.email`)}
        defaultValue={defaultValues?.email}
      />
      <NewPasswordInput
        register={register}
        errors={errors}
        name="password"
        label={t(`newClient:sharedDetails.password`)}
        defaultValue={defaultValues?.password}
        helperText={t(`onboarding:createUserContent.password-desc`)}
        FormHelperTextProps={{ classes: helperTextClasses }}
      />
    </article>
  );
};

export default CompanyDetails;
