import React from 'react';

import commodities from 'utils/product/enums/commodities';
import { ReactComponent as EnergyCircle } from 'assets/icons/EnergyCircleIcon.svg';
import { ReactComponent as EnergyGasCircle } from 'assets/icons/EnergyGasCircleIcon.svg';
import IconText from 'components/IconText/IconText';
import useLocalizedFriendlyName from 'hooks/useLocalizedFriendlyName';

const MeterIconText = ({
  commodity,
  friendlyName,
  meterNo,
  iconClassName,
  ...rest
}) => {
  const { getDisplayName } = useLocalizedFriendlyName();
  const content = `${getDisplayName(friendlyName, meterNo)}`;

  return (
    <IconText
      Icon={
        commodity === commodities.energy ? (
          <EnergyCircle className={iconClassName} />
        ) : (
          <EnergyGasCircle className={iconClassName} />
        )
      }
      weight="bold-ellipsis"
      gap="s"
      content={content}
      {...rest}
    />
  );
};

export default MeterIconText;
