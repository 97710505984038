export const gameEventNames = {
  won: 'gameWon',
  lost: 'gameLost',
  completed: 'gameCompleted',
  started: 'gameStarted',
  timesUp: 'timesUp',
};

export const gameResultStatuses = {
  success: 'SUCCESS',
  failure: 'FAILURE',
};

export const gamesStatuses = {
  openedGame: 'MINI_GAME0003',
  gameLimitReached: 'LIMIT0014',
  insufficientAmount: 'REDEMPTION_OPTION0003',
};

export const gameErrorCodes = {
  LIMIT__REACHED_FOR_INTERVAL_GLOBAL_LIMIT: 'LIMIT0001',
  LIMIT__NOT_WITHIN_CUSTOM_LIMITS_GLOBAL_LIMIT: 'LIMIT0002',
  LIMIT__NOT_WITHIN_DAILY_LIMITS_GLOBAL_LIMIT: 'LIMIT0003',
  LIMIT__NOT_WITHIN_WEEKLY_LIMITS_GLOBAL_LIMIT: 'LIMIT0004',
  LIMIT__NOT_WITHIN_MONTHLY_LIMITS_GLOBAL_LIMIT: 'LIMIT0005',
  LIMIT__NOT_WITHIN_YEARLY_LIMITS_GLOBAL_LIMIT: 'LIMIT0006',
  LIMIT__NOT_WITHIN_TOTAL_LIMITS_GLOBAL_LIMIT: 'LIMIT0007',
  LIMIT__HAS_REACHED_FOR_INTERVAL_PER_CUSTOMER_LIMIT: 'LIMIT0008',
  LIMIT__NOT_WITHIN_CUSTOM_LIMITS_PER_CUSTOMER_LIMIT: 'LIMIT0009',
  LIMIT__NOT_WITHIN_DAILY_LIMITS_PER_CUSTOMER_LIMIT: 'LIMIT0010',
  LIMIT__NOT_WITHIN_WEEKLY_LIMITS_PER_CUSTOMER_LIMIT: 'LIMIT0011',
  LIMIT__NOT_WITHIN_MONTHLY_LIMITS_PER_CUSTOMER_LIMIT: 'LIMIT0012',
  LIMIT__NOT_WITHIN_YEARLY_LIMITS_PER_CUSTOMER_LIMIT: 'LIMIT0013',
  LIMIT__NOT_WITHIN_TOTAL_LIMITS_PER_CUSTOMER_LIMIT: 'LIMIT0014',
  LIMIT__REACHED_FOR_INTERVAL_PER_ITEM_PER_CUSTOMER_LIMIT: 'LIMIT0019',
  LIMIT__NOT_WITHIN_CUSTOM_LIMITS_PER_ITEM_PER_CUSTOMER_LIMIT: 'LIMIT0020',
  LIMIT__NOT_WITHIN_DAILY_LIMITS_PER_ITEM_PER_CUSTOMER_LIMIT: 'LIMIT0021',
  LIMIT__NOT_WITHIN_WEEKLY_LIMITS_PER_ITEM_PER_CUSTOMER_LIMIT: 'LIMIT0022',
  LIMIT__NOT_WITHIN_MONTHLY_LIMITS_PER_ITEM_PER_CUSTOMER_LIMIT: 'LIMIT0023',
  LIMIT__NOT_WITHIN_YEARLY_LIMITS_PER_ITEM_PER_CUSTOMER_LIMIT: 'LIMIT0024',
  LIMIT__NOT_WITHIN_TOTAL_LIMITS_PER_ITEM_PER_CUSTOMER_LIMIT: 'LIMIT0025',
};

export const gameCategories = {
  luck: 'LUCK',
  skill: 'SKILL',
};

export const gameTypes = {
  wheel: 'WHEEL_OF_FORTUNE',
  scratch: 'SCRATCH',
  memory: 'MEMORY',
};
