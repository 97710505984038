import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';

import StandardSelectOption from 'components/Selects/StandardSelectOption';
import StandardSelect from 'components/Selects/StandardSelect';
import SelectFormControl from 'components/Selects/SelectFormControl';
import { useAuthUser } from 'utils/AuthUser';
import { getTopbarName } from 'utils/product/accountUtils';
import { isStringEqual } from 'utils/stringUtils';

import styles from './AccountSelector.module.scss';

const modalUnderSelectInputProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  getContentAnchorEl: null,
};

const menuProps = {
  ...modalUnderSelectInputProps,
};

export default function AccountSelector({ type = 'default' }) {
  const { accounts, accountId, selectAccount } = useAuthUser();
  const { t } = useTranslation(['header']);
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const handleChange = (event) => {
    selectAccount(event.target.value);
  };

  const selectClasses = {
    select: styles.select,
    icon: styles.selectIcon,
  };

  useEffect(() => {
    if (location.pathname === '/') {
      setIsVisible(true);
      return;
    }
    setIsVisible(false);
  }, [location]);

  if (accounts?.length <= 1 || isVisible === false) return null;
  return (
    <div className={cn(styles.container, styles[`container-${type}`])}>
      <SelectFormControl>
        <StandardSelect
          disableUnderline={true}
          displayEmpty
          value={accountId}
          onChange={handleChange}
          MenuProps={menuProps}
          classes={selectClasses}
        >
          {accounts.map((x) => {
            return (
              <StandardSelectOption
                menuItemClass={`${styles.menuItem} ${
                  isStringEqual(x.Id, accountId) ? styles.menuItemHidden : ''
                }`}
                value={x.Id}
                key={x.Id}
              >
                {isStringEqual(x.Id, accountId) ? (
                  <span></span>
                ) : (
                  <div className={styles.optionContainer}>
                    {getTopbarName(x)} - ({t('header:vat')}: {x.TaxNo})
                  </div>
                )}
              </StandardSelectOption>
            );
          })}
        </StandardSelect>
      </SelectFormControl>
    </div>
  );
}
