import React from 'react';
import { useTranslation } from 'react-i18next';

import Widget from 'components/Widget/Widget';
import { ReactComponent as WarningTriangleIcon } from 'assets/icons/WarningTriangleIcon.svg';
import useWebNotificationsDowntime from 'data/useWebNotificationsDowntime';

import styles from './HomeNotifications.module.scss';

const HomeNotifications = () => {
  const { i18n } = useTranslation();
  const { data: notifications, loading } = useWebNotificationsDowntime(
    i18n.language,
  );
  return (
    !loading &&
    notifications.length > 0 &&
    notifications.map((notification) => {
      return (
        <Widget
          key={notification.title}
          distance="m"
          size="m"
          widgetClass={styles.container}
        >
          <WarningTriangleIcon className={styles.icon} />
          <section className={styles.textContainer}>
            <h4>{notification.title}</h4>
            <p dangerouslySetInnerHTML={{ __html: notification.message }}></p>
          </section>
        </Widget>
      );
    })
  );
};

export default HomeNotifications;
