import React from 'react';

import { formatDate } from 'utils/dateUtils';
import { formatMoneyWithCurrency } from 'utils/moneyUtils';
import HistoryRecordWrapper from 'components/History/HistoryRecordWrapper';
import HistoryRecordHeader from 'components/History/HistoryRecordHeader';
import HistoryRecordItem from 'components/History/HistoryRecordItem';
import MeterIconText from 'components/Labels/MeterIconText';
import InvoiceTypeLabel from 'components/InvoiceTypeLabel/InvoiceTypeLabel';

import styles from './MyBillsHistoryTabContent.module.scss';

const MyBillsHistoryTabContent = ({ invoice }) => {
  return (
    <HistoryRecordWrapper>
      <HistoryRecordHeader
        meterName={
          <MeterIconText
            meterNo={invoice?.MeterNo}
            commodity={invoice?.Commodity}
            friendlyName={invoice?.FriendlyName}
          />
        }
        text={formatMoneyWithCurrency(invoice?.grandTotal)}
      />
      <HistoryRecordItem
        text={formatDate(invoice?.issueDate)}
        value={
          <InvoiceTypeLabel
            invoiceType={invoice.invoiceType}
            css={styles['invoiceType-text']}
          />
        }
      />
    </HistoryRecordWrapper>
  );
};
export default MyBillsHistoryTabContent;
