import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';

import { trackBillHistoryEvent } from 'utils/product/tracking/events';
import { getTrackingEnergyType } from 'utils/product/tracking/trackingLabels';
import Pager from 'components/Pager/Pager';
import { ReactComponent as EmptyBillsHistory } from 'assets/icons/EmptyBillsHistory.svg';
import MyBillsHistoryTabContent from 'components/MyBillsHistoryTabContent/MyBillsHistoryTabContent';
import EmptyState from 'components/History/EmptyState';
import HistoryMeterChips from 'components/Chip/HistoryMeterChips';
import TabPanel from 'components/Tabs/TabPanel';
import { isEmptyString } from 'utils/stringUtils';
import { getCommodityName } from 'utils/product/meterUtils';
import useInvoicesHistory from 'data/useInvoicesHistory';
import { useAuthUser } from 'utils/AuthUser';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';

import styles from './MyBillsHistoryWidget.module.scss';

const MyBillsHistoryTabPanel = ({ value, meters, metersLoading }) => {
  const pagerLimit = 8;
  const { t } = useTranslation(['translation', 'bills']);
  const navigate = useNavigate();
  const location = useLocation();
  const { invoiceNo = null } = useParams();
  const shouldNavigate = useRef(invoiceNo === null); // Using a ref to track whether navigation occurred

  const [limit, setLimit] = useState(pagerLimit);
  const [chosenMeter, setchosenMeter] = useState(
    isEmptyString(location?.state?.chosenMeter)
      ? 'all'
      : location?.state?.chosenMeter,
  );

  const { user } = useAuthUser();

  const { data: invoices, loading: invoicesLoading } = useInvoicesHistory(
    chosenMeter === 'all' ? user?.TaxNo : null,
    chosenMeter?.MeterSupplyNo,
    chosenMeter?.SupplyType,
    chosenMeter?.Commodity,
    meters,
    meters?.length === 0,
  );

  const handleLimitChange = (limit) => {
    setLimit(limit);
    trackBillHistoryEvent('bill_history_view_more');
  };

  useEffect(() => {
    if (!invoicesLoading && shouldNavigate.current === true) {
      navigate(
        invoices?.length > 0
          ? `/bills/${getCommodityName(invoices[0].Commodity)}/${
              invoices[0].invoiceNo
            }`
          : '/bills',
        {
          state: {
            chosenMeter,
            isLastInvoice:
              invoices?.length > 0 ? invoices[0]?.IsLastInvoice : false,
          },
        },
      );
      shouldNavigate.current = false;
    }
  }, [invoices, navigate, chosenMeter, invoicesLoading]);

  useEffect(() => {
    const _chosenMeter = isEmptyString(location?.state?.chosenMeter)
      ? 'all'
      : location?.state?.chosenMeter;
    setchosenMeter(_chosenMeter);
  }, [location?.state]);

  const handlechosenMeter = (meter) => {
    const tempMeter = meter !== 'all' ? meter : 'all';
    setchosenMeter(tempMeter);
    trackBillHistoryEvent(
      'bill_history_filter_selected',
      getTrackingEnergyType(meter.Commodity),
    );
    shouldNavigate.current = true;
  };

  return (
    <TabPanel
      value={value}
      index={0}
      idPrefix="invoices"
      innerClass={styles['tabPanel-innerContainer']}
    >
      <>
        <HistoryMeterChips
          meters={meters}
          handlechosenMeter={handlechosenMeter}
          chosenMeter={chosenMeter?.Id ?? chosenMeter}
        />
        <WidgetSkeleton distance="s" show={metersLoading || invoicesLoading}>
          {invoices?.length === 0 && (
            <EmptyState
              Icon={<EmptyBillsHistory />}
              text={t('bills:bills-history-empty')}
            />
          )}

          {invoices?.length > 0 &&
            invoices?.slice(0, limit).map((invoice, index) => (
              <NavLink
                className={({ isActive }) =>
                  `${styles.link} ${isActive ? styles.selectedLink : ''}`
                }
                to={`/bills/${getCommodityName(invoice.Commodity)}/${
                  invoice.invoiceNo
                }`}
                state={{ chosenMeter, isLastInvoice: invoice.IsLastInvoice }}
                key={`invoice-${invoice.id}`}
                onClick={() => {
                  trackBillHistoryEvent(
                    'bill_history_bill_selected',
                    getTrackingEnergyType(invoice.Commodity),
                  );
                }}
              >
                <MyBillsHistoryTabContent invoice={invoice} />
              </NavLink>
            ))}

          <Pager
            totalCount={invoices?.length}
            onClick={handleLimitChange}
            containerClass={styles['moreButton-container']}
          />
        </WidgetSkeleton>
      </>
    </TabPanel>
  );
};
export default MyBillsHistoryTabPanel;
