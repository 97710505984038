import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getAcquiredVibes } from 'utils/product/loyalty/vibesUtils';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import SeeMoreWidget from 'components/SeeMoreWidget/SeeMoreWidget';
import MyVibeLabel from 'components/LoyaltyVibes/MyVibeLabel';
import MyVibesExplanation from 'components/LoyaltyVibes/MyVibesExplanation';
import { ReactComponent as UpArrow } from 'assets/icons/ArrowUpIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/ArrowDownIcon.svg';
import { trackLoyaltyPageVibesEvent } from 'utils/product/tracking/loyaltyEvents';

import styles from './MyVibes.module.scss';
import Collapse from 'components/Collapse/Collapse';

const MyVibes = ({ vibes, showExplanation = false, containerClass = '' }) => {
  const { t } = useTranslation(['translation', 'loyalty']);
  const myVibes = getAcquiredVibes(vibes);
  const [openDetails, setOpenDetails] = useState(false);
  const toggleOpenDetails = useCallback(() => {
    setOpenDetails((current) => !current);
  }, []);

  const progressBarValues = myVibes.map((x) => ({
    key: x.key,
    percentage: x.percentage,
    color: x.color,
  }));

  return (
    vibes && (
      <section className={containerClass}>
        <ProgressBar values={progressBarValues} />
        <section className={styles.myVibesPercentages}>
          {myVibes.map((x) => (
            <MyVibeLabel key={x?.key} vibe={x} />
          ))}
        </section>
        {showExplanation && (
          <>
            <Collapse open={openDetails}>
              <MyVibesExplanation vibes={myVibes} />
            </Collapse>
            <SeeMoreWidget
              containerClass={styles.more}
              onClick={() => {
                if (!openDetails) {
                  trackLoyaltyPageVibesEvent(
                    'loyalty_myvibes_active_vibe_unfold_selected'
                  );
                }
                toggleOpenDetails();
              }}
              Icon={
                openDetails ? (
                  <UpArrow fontSize="large" />
                ) : (
                  <DownArrow fontSize="large" />
                )
              }
              content={
                openDetails
                  ? t('translation:see-less')
                  : t('loyalty:landing.see-vibes-info')
              }
            />
          </>
        )}
      </section>
    )
  );
};
export default MyVibes;
