import React from 'react';
import Helmet from 'react-helmet';

const HelmetComponent = ({ head, children }) => {
  const { title = '', metaTags = [] } = head || {};
  return (
    <Helmet>
      <title>{title ? `${title} | myΗΡΩΝ` : 'myΗΡΩΝ'}</title>
      {metaTags.map((metaTag) => (
        <meta
          name={metaTag.name}
          content={metaTag.content}
          key={metaTag.name}
          data-react-helmet="true"
        />
      ))}
      {children}
    </Helmet>
  );
};

export default HelmetComponent;
