import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import BillsWidget from 'components/BillsWidget/BillsWidget';
import IconText from 'components/IconText/IconText';
import BillsAnalysis from 'components/BillsAnalysis/BillsAnalysis';
import BillDetailViewBanners from 'components/BillDetailViewBanners/BillDetailViewBanners';
import { PromptDialog } from 'components/PromptDialogs/PromptDialog';
import MeterIconText from 'components/Labels/MeterIconText';
import { ReactComponent as BillsEmpty } from 'assets/icons/BillIcon.svg';
import { ReactComponent as EmptyBillsHistory } from 'assets/icons/EmptyBillsHistory.svg';
import { useAuthUser } from 'utils/AuthUser';
import useInvoicesHistory from 'data/useInvoicesHistory';
import useInvoiceDetails from 'data/useInvoiceDetails';
import useDialog from 'hooks/useDialog';
import commodities from 'utils/product/enums/commodities';
import { checkIfInvoiceBelongsToMeter } from 'utils/product/billUtils';
import { convertLastInvoiceToIdocsInvoice } from 'utils/product/invoiceUtils';

import styles from './Bills.module.scss';

const MainArea = ({ meters, loading, handleEbillChange, commodity }) => {
  const { t } = useTranslation(['bills', 'homepage']);
  const location = useLocation();
  const chosenMeter = location?.state?.chosenMeter ?? 'all';
  const { user } = useAuthUser();
  const navigate = useNavigate();
  const [invoiceData, setInvoiceData] = useState();
  const [meter, setMeter] = useState();
  const { invoiceNo = null } = useParams();
  const errorDialog = useDialog();
  const { loading: invoiceLoading, data: serviceData } = useInvoiceDetails(
    invoiceNo,
    commodity,
  );

  useEffect(() => {
    if (serviceData) {
      const _invoiceData = serviceData;
      setInvoiceData(_invoiceData);
      const _meter = _invoiceData
        ? meters?.find((meter) =>
            checkIfInvoiceBelongsToMeter(_invoiceData, commodity, meter),
          )
        : null;
      setMeter(_meter);
      return;
    }
    if (commodity === commodities.energy) {
      const _meter = meters?.find(
        (meter) => meter.LastInvoice?.invoiceNo === invoiceNo,
      );
      if (_meter) {
        setMeter(_meter);
        setInvoiceData(convertLastInvoiceToIdocsInvoice(_meter));
        return;
      }
    }
    setMeter(null);
    setInvoiceData(null);
  }, [serviceData, commodity, meters, invoiceNo]);

  const { data: invoices, loading: invoicesLoading } = useInvoicesHistory(
    chosenMeter === 'all' ? user?.TaxNo : null,
    chosenMeter?.MeterSupplyNo,
    chosenMeter?.SupplyType,
    chosenMeter?.Commodity,
    meters,
    meters?.length === 0,
  );

  const invoice =
    invoiceLoading || invoicesLoading
      ? null
      : {
          ...invoiceData,
          IsLastInvoice: invoices?.find(
            (_invoice) => _invoice.invoiceNo === invoiceData?.invoiceNo,
          )?.IsLastInvoice,
        };

  const showEmptyState = (meter, invoice) => !meter || !invoice;

  return (
    <>
      <WidgetSkeleton
        distance="xs"
        show={loading || invoiceLoading || invoicesLoading}
        heightInPixels={488}
        title={<WidgetHeader title={t('homepage:my-bills.title')} />}
      >
        {showEmptyState(meter, invoice) ? (
          <Widget
            size="s"
            title={<WidgetHeader title={t('homepage:my-bills.title')} />}
          >
            <IconText
              Icon={<BillsEmpty width={32} height={32} />}
              content={t('homepage:bills-empty.title')}
              gap="xs"
            />
          </Widget>
        ) : (
          <>
            <WidgetHeader
              title={
                <MeterIconText
                  iconClassName={styles.billTitleIcon}
                  meterNo={meter?.MeterNo}
                  commodity={meter?.Commodity}
                  friendlyName={meter?.FriendlyName}
                  contentClass={styles.billTitle}
                  containerClass={styles.billTitleContainer}
                />
              }
            />
            <BillsWidget meter={meter} invoice={invoice} />
          </>
        )}
      </WidgetSkeleton>
      <BillDetailViewBanners
        invoice={invoice}
        meter={meter}
        loading={loading}
        handleEbillChange={handleEbillChange}
      />
      <WidgetSkeleton distance="xs" show={loading} heightInPixels={300}>
        {!showEmptyState(meter, invoice) && (
          <BillsAnalysis invoice={invoice} commodity={meter?.Commodity} />
        )}
      </WidgetSkeleton>
      <PromptDialog
        icon={<EmptyBillsHistory width={64} height={64} />}
        header={t('bills:invoice-unavailable.header')}
        message={t('bills:invoice-unavailable.message')}
        open={errorDialog.isOpen}
        showCloseButton={false}
        showPrimaryAction={true}
        primaryActionHandler={() => {
          errorDialog.close();
          navigate('/', { replace: true });
        }}
      />
    </>
  );
};

export default MainArea;
