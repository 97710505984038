import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import indexOf from 'lodash/indexOf';
import merge from 'lodash/merge';
import get from 'lodash/get';
import {
  newClientSteps,
  newClientFlow,
  existingClientFlow,
  existingClientSteps,
} from 'utils/product/onboardingConfig';
import { getObjectValues } from 'utils/objectUtils';
import { getNextSteps } from 'utils/product/onboarding/steps';
import { getInitialData } from 'utils/product/onboarding/helpers';
import { trackEContractEvent } from 'utils/product/tracking/onboardingEvents';

export default function useUniqueOnboardingFlow(
  isShop = false,
  user = null,
  program = null
) {
  const navigate = useNavigate();
  const location = useLocation();
  const { programType, energyType, programName } = useParams();
  const baseUrl = isShop
    ? `/shop/${programType}/${energyType}/${programName}`
    : newClientFlow['whatsNew'];

  const [flowActive, setFlowActive] = useState(true);

  const paths = isShop
    ? getObjectValues(existingClientFlow).map(
        (x) => `/shop/${programType}/${energyType}/${programName}${x}`
      )
    : getObjectValues(newClientFlow);

  const [nextRouteData, setNextRouteData] = useState({
    ...merge(getInitialData(isShop, user, program), location.state),
    isShop,
  });

  const getPageStep = useCallback(
    (path) => {
      try {
        const index = indexOf(paths, path);
        return { index: index, path: path };
      } catch (error) {
        return { index: undefined, path: null };
      }
    },
    [paths]
  );

  useEffect(() => {
    if (!flowActive) return;
    const { index } = getPageStep(location.pathname);
    setStep(index);
  }, [location.pathname, getPageStep, flowActive]);

  const [step, setStep] = useState(() => getPageStep(location.pathname).index);

  useEffect(() => {
    if (flowActive) {
      const hasInvalidState =
        //  step === -1 ||
        (isShop && !program) ||
        (!isShop && step > 1 && !nextRouteData.energyType);
      if (hasInvalidState) {
        navigate(baseUrl, { state: nextRouteData });
        return;
      }
    }
  }, [
    flowActive,
    step,
    baseUrl,
    program,
    isShop,
    paths,
    navigate,
    nextRouteData,
  ]);

  const getNextStep = (currentStep, data) => {
    const nextStepsMapping = getNextSteps(data);
    const steps = isShop ? existingClientSteps : newClientSteps;
    const nextStep = get(nextStepsMapping, currentStep, steps.login);

    return nextStep;
  };

  const incrementStep = (data) => {
    trackEContractEvent(step, data, isShop);
    const nextIndex = getNextStep(step, data);
    const newStepPath = paths[nextIndex];
    const steps = isShop ? existingClientSteps : newClientSteps;
    if (newStepPath !== location.pathname) {
      if (step === steps.newGasSupplySuccess) {
        navigate(newStepPath, { replace: true, state: data });
        return;
      }
      navigate(newStepPath, { state: data });
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const goForward = (data) => {
    incrementStep(data);
  };

  return {
    step,
    nextRouteData,
    setNextRouteData,
    setFlowActive,
    goBack,
    goForward,
  };
}
