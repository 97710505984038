import React from 'react';
import { useTranslation } from 'react-i18next';

import UnauthorizedLayout from 'layouts/UnauthorizedLayout';
import Cookies from 'components/Cookies/Cookies';

const CookiesPolicy = () => {
  const { t } = useTranslation(['onboarding']);
  return (
    <UnauthorizedLayout
      head={{
        title: t(`onboarding:cookies-policy`),
        metaTags: [
          {
            name: 'title',
            content: t(`onboarding:cookies-policy`),
          },
        ],
      }}
    >
      <Cookies />
    </UnauthorizedLayout>
  );
};

export default CookiesPolicy;
