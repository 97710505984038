import Big from 'big.js';
import { formatMoney } from 'utils/moneyUtils';
import { fixDecimalString } from 'utils/numberUtils';

const calculateYearlyDiscount = (amount, config) =>
  Big(amount).times(config.annualMultiplier);

const calculateTotalDiscount = (amount, config) =>
  calculateYearlyDiscount(amount, config).times(config.sumMultiplier);

const calculateContribution = (amount, config) =>
  calculateYearlyDiscount(amount, config).div(config.outputFactor);

const calculateBenefits = (data, config) => {
  const amount = fixDecimalString(data);
  return {
    yearlyDiscount: formatMoney(calculateYearlyDiscount(amount, config)),
    totalDiscount: formatMoney(calculateTotalDiscount(amount, config)),
    contribution: formatMoney(calculateContribution(amount, config)),
  };
};

export {
  calculateTotalDiscount,
  calculateYearlyDiscount,
  calculateContribution,
  calculateBenefits,
};
