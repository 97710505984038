import useSWR from 'swr';
import { postFetcher } from 'api/wave';
import endpoints from 'utils/product/enums/endpoints';
import { gameErrorCodes, gamesStatuses } from 'utils/product/enums/gameEnums';
import { getObjectValues } from 'utils/objectUtils';

const useCheckGameSession = (_customerId, miniGameId) => {
  const { data, error, mutate } = useSWR(
    miniGameId &&
      _customerId && [
        `${endpoints.loyalty.games}/${miniGameId}/sessions/check`,
        _customerId,
      ],
    ([url, _customerId]) =>
      postFetcher(url, {
        customerId: _customerId,
      }),
    { dedupingInterval: 2000 },
  );

  const loading = !data && !error;

  const responseError = data?.errors?.length > 0 ? data?.errors[0] : null;

  const limitErrors = getObjectValues(gameErrorCodes);
  const gameLimitReached = limitErrors.indexOf(responseError?.code) > -1;
  const hasOpenSession = responseError?.code === gamesStatuses?.openedGame;
  const hasInsufficientAmount =
    responseError?.code === gamesStatuses?.insufficientAmount;

  return {
    loading,
    error:
      error || (!hasOpenSession && !hasInsufficientAmount && responseError),
    data,
    mutate,
    hasOpenSession,
    gameLimitReached,
  };
};

export default useCheckGameSession;
