import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useDialog from 'hooks/useDialog';
import { trackRequestsEvent } from 'utils/product/tracking/events';
import {
  linksForRequestsCategories,
  requestStatuses,
  requestTypes,
} from 'utils/product/enums/requests';
import commodities from 'utils/product/enums/commodities';
import RightArrowLinkElement from 'components/RequestsHome/RightArrowLinkElement';
import MetersListDialog from 'components/RequestsHome/MetersListDialog';
import PowerDisconnectDialog from 'components/Requests/Moving/PowerDisconnectDialog';
import RequestStatus from 'components/RequestsWidget/RequestStatus';
import UnavailableRequestDialog from './UnavailableRequestDialog';

import styles from './AvailableRequestsList.module.scss';

const AvailableRequestsList = ({
  requests,
  meters,
  showMeterDialog = false,
}) => {
  const { t } = useTranslation(['requests']);
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const electricityMeters = meters
    ? meters.filter((x) => x.Commodity === commodities.energy)
    : [];
  const [currentMeterId, setCurrentMeterId] = useState(
    electricityMeters.length === 1 ? electricityMeters[0].Id : null
  );
  const metersDialog = useDialog();
  const powerDialog = useDialog();
  const unavailableDialog = useDialog();

  useEffect(() => {
    if (showMeterDialog) metersDialog.show();
  }, [showMeterDialog, metersDialog]);

  const closeDialog = () => {
    metersDialog.close();
    if (showMeterDialog) navigate(-1);
  };

  const closePowerDialog = () => {
    powerDialog.close();
    if (showMeterDialog) navigate(-1);
  };

  const onRequestSelected = (code, message) => {
    trackRequestsEvent('request_selection', message);
    if (
      code !== requestTypes.usageChange &&
      code !== requestTypes.movingRequest
    )
      return;
    if (electricityMeters.length === 0) {
      unavailableDialog.show();
      return;
    }
    if (code === requestTypes.usageChange) {
      navigate(`${pathname}${linksForRequestsCategories[code]}`);
      return;
    }
    if (code === requestTypes.movingRequest) {
      metersDialog.show();
    }
  };

  const onMeterSelected = () => {
    metersDialog.close();
    powerDialog.show();
  };

  const onPowerDisconnect = (value) => {
    powerDialog.close();
    const meter = electricityMeters.find((x) => x.Id === currentMeterId);

    navigate(
      `/requests${linksForRequestsCategories[requestTypes.movingRequest]}`,
      { state: { keepSameProgram: value, meter } }
    );
  };

  return requests ? (
    <>
      {requests.map((request) => {
        const { message, code, requestStatus, requestStatusText } =
          request || {};

        return message &&
          code &&
          linksForRequestsCategories.hasOwnProperty(code) ? (
          <RightArrowLinkElement
            key={code}
            message={message}
            description={
              requestStatus && requestStatus === requestStatuses.pending ? (
                <p className={styles.statusParagraph}>
                  <RequestStatus val={requestStatus} width="24" height="24" />
                  <span className={styles.span}>{requestStatusText}</span>
                </p>
              ) : null
            }
            link={
              code === requestTypes.movingRequest ||
              code === requestTypes.usageChange
                ? null
                : `${pathname}${linksForRequestsCategories[code]}`
            }
            onClick={(event) => onRequestSelected(code, message)}
          />
        ) : null;
      })}
      <MetersListDialog
        headerText={t(`requests:moving.meter-dialog-title`)}
        open={metersDialog.isOpen}
        closeDialog={closeDialog}
        clickHandler={onMeterSelected}
        setSelectedMeter={setCurrentMeterId}
        meters={electricityMeters}
      />
      <PowerDisconnectDialog
        open={powerDialog.isOpen}
        closeDialog={closePowerDialog}
        onSelect={onPowerDisconnect}
      />
      <UnavailableRequestDialog
        open={unavailableDialog.isOpen}
        closeDialog={unavailableDialog.close}
      />
    </>
  ) : null;
};

export default AvailableRequestsList;
