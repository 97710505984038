import React from 'react';

import styles from './CmsHtmlField.module.scss';

const CmsHtmlField = ({ value, className = '', ...rest }) => {
  return (
    <div
      className={`${styles.container} ${className}`}
      dangerouslySetInnerHTML={{ __html: value }}
      {...rest}
    />
  );
};

export default CmsHtmlField;
