import React from 'react';
import { useTranslation } from 'react-i18next';

import WidgetPrice from 'components/WidgetPrice/WidgetPrice';

import styles from './EnaOverview.module.scss';

const EnaOverview = ({ enaDetails }) => {
  const { t } = useTranslation(['ena', 'translation']);

  return (
    <>
      {enaDetails && (
        <section className={styles.container}>
          <article className={styles.details}>
            <p className={styles.header}>
              {t(
                `ena:analysis.${
                  enaDetails.FirstSolarisDepositHasOccured === true
                    ? 'header'
                    : 'no-payment-header'
                }`
              )}
            </p>
          </article>
          {enaDetails.FirstSolarisDepositHasOccured === true && (
            <article className={styles.discount}>
              <WidgetPrice number={Math.abs(enaDetails.TotalDiscount ?? 0)} />
              <p className={styles.caption}>{t('ena:home.total-discount')}</p>
            </article>
          )}
        </section>
      )}
    </>
  );
};

export default EnaOverview;
