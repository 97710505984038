import getLocalizedEnumData from 'utils/product/enums/getLocalizedEnumData';
import { isBetween } from 'utils/dateUtils';

const cmsOnboardingPromotionTransformation = (data) => {
  const promotion = data?.data?.[0]?.subdocs?.promotions?.content;
  return promotion;
};

export const cmsGeneralConfigTransformation = (data, language = 'el') => {
  const configurationData = data?.data?.[0]?.content || null;
  if (!configurationData) return {};

  const onboardingPromotion = cmsOnboardingPromotionTransformation(data);

  const {
    termsUrls,
    pageUrls,
    paymentUrls,
    GetRequestAvailableCategories,
    RequestHistoryCategories,
    GetAvailableRequestStatuses,
    successfulPaymentUrl = process.env.REACT_APP_PAYMENTGATEWAY_SUCCESS_URL,
    failedPaymentUrl = process.env.REACT_APP_PAYMENTGATEWAY_FAILURE_URL,
    successfulRecurringUrl = process.env
      .REACT_APP_PAYMENTGATEWAY_RECURRINGSUCCESS_URL,
    failedRecurringUrl = process.env
      .REACT_APP_PAYMENTGATEWAY_RECURRINGFAILURE_URL,
    failedRecurringInternalUrl = process.env
      .REACT_APP_PAYMENTGATEWAY_RECURRINGERROR_URL,
    paymentGatewayUrl = process.env.REACT_APP_PAYMENTGATEWAY_URL,
    recurringPaymentGatewayUrl = process.env
      .REACT_APP_RECURRINGPAYMENTGATEWAY_URL,

    ...rest
  } = configurationData || {};

  return {
    availableRequestCategories: getLocalizedEnumData(
      GetRequestAvailableCategories,
      language,
    ),
    requestHistoryCategories: getLocalizedEnumData(
      RequestHistoryCategories,
      language,
    ),
    availableRequestStatuses: getLocalizedEnumData(
      GetAvailableRequestStatuses,
      language,
    ),
    generalTermsUrl: termsUrls?.generalTerms || '',
    gdprTermsUrl: termsUrls?.gdprTerms || '',
    paymentTermsUrl: termsUrls?.paymentTerms || '',
    paymentUrls,
    successfulPaymentUrl,
    failedPaymentUrl,
    successfulRecurringUrl,
    failedRecurringUrl,
    failedRecurringInternalUrl,
    paymentGatewayUrl,
    recurringPaymentGatewayUrl,
    onboardingPromotion,
    ...rest,
  };
};

export const cmsWebDowntimeTransformation = (data) => {
  const downtime_web = data?.data?.[0]?.subdocs?.downtime_web || null;
  if (!downtime_web) return {};

  const { content: downTimeWeb = {} } = downtime_web || {};
  const ignoreDowntime = process.env.REACT_APP_IGNORE_DOWNTIME === 'true';

  const hasDownTime =
    !ignoreDowntime &&
    downTimeWeb &&
    isBetween(new Date(), downTimeWeb.fromDate, downTimeWeb.toDate);

  return {
    downTimeWeb,
    hasDownTime,
  };
};

export const cmsWebDowntimeNotificationsTransformation = (data) => {
  const notifications_web = data?.data?.[0]?.subdocs?.notifications_web?.map(
    (notification) => notification?.content,
  );
  if (!notifications_web) return [];

  const notificationsToShow = notifications_web
    ?.filter((notification) =>
      isBetween(new Date(), notification.fromDate, notification.toDate),
    )
    ?.map((notification) => ({
      ...notification,
      title: notification.header,
      message: notification.message,
    }));

  return notificationsToShow;
};
