import React, { useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
import { ReactComponent as ArrowUp } from 'assets/icons/PopoverArrowUp.svg';

import styles from './IconPopover.module.scss';

export default function IconPopover({
  id,
  icon,
  content,
  contentClass = '',
  buttonClass = '',
  popoverClass = '',
  arrowClass,
  placement = 'bottom',
  clickHandler,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = React.useRef(null);
  const [arrowRef, setArrowRef] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);

    if (typeof clickHandler === 'function') clickHandler();
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? id : undefined;
  const modifiers = {
    flip: {
      enabled: false,
    },
    preventOverflow: {
      enabled: true,
      boundariesElement: 'window',
    },
    arrow: {
      enabled: true,
      element: arrowRef,
    },
  };
  const handleClickAway = () => {
    setAnchorEl(null);
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={styles.container}>
        <Button
          ref={anchorRef}
          aria-describedby={popoverId}
          aria-label={popoverId}
          onClick={handleClick}
          className={`${styles.button} ${buttonClass}`}
        >
          {icon}
        </Button>
        <Popper
          id={popoverId}
          open={open}
          anchorEl={anchorRef.current}
          modifiers={modifiers}
          className={`${popoverClass}`}
          placement={placement}
        >
          <div className={styles.arrow} ref={setArrowRef}>
            <ArrowUp />
          </div>
          <section
            className={`${styles.content} ${contentClass}`}
            dangerouslySetInnerHTML={{ __html: content }}
          ></section>
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
