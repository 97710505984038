import React from 'react';
import { Routes, Route } from 'react-router-dom';

import QuestionnaireDialog from 'components/Loyalty/QuestionnaireDialog';
import LoyaltyLanding from 'pages/loyalty/LoyaltyLanding';
import { LoyaltyWhatsNew, LoyaltyInfoDialog } from './LoyaltyPromptDialogs';
import Homepage from 'pages/homepage/Homepage';
import { loyaltyPaths } from 'utils/product/loyalty/config';
import LoyaltyEbill from 'components/LoyaltyEbill/LoyaltyEbill';
import PendingBills from 'components/HomeBillWidget/PendingBills';
import LoyaltyConsumption from 'components/LoyaltyConsumptions/LoyaltyConsumption';
import AuthorizedLoyalty from './AuthorizedLoyalty';
import AuthorizedNonLoyalty from './AuthorizedNonLoyalty';
import LoyaltyDebitPayment from 'components/LoyaltyDebitPayment/LoyaltyDebitPayment';
import LoyaltyConsentDialog from './LoyaltyConsentDialog';
import LoyaltyFAQsDialog from './LoyaltyFAQsDialog';

const LoyaltyRouter = () => {
  return (
    <>
      <Routes>
        <Route
          path={`${loyaltyPaths.landing}`}
          element={<AuthorizedLoyalty component={<LoyaltyLanding />} />}
        />
        <Route
          path={`${loyaltyPaths.pendingBills}`}
          element={<AuthorizedLoyalty component={<PendingBills />} />}
        />
        <Route
          path={`${loyaltyPaths.debitPayment}`}
          element={<AuthorizedLoyalty component={<LoyaltyDebitPayment />} />}
        />
        <Route
          path={`${loyaltyPaths.consumptions}`}
          element={<AuthorizedLoyalty component={<LoyaltyConsumption />} />}
        />
        <Route path={`${loyaltyPaths.eBill}`} element={<LoyaltyEbill />} />
        <Route
          path={`${loyaltyPaths.prompt}`}
          element={<Homepage component={<LoyaltyWhatsNew />} />}
        />
        {/* <Route
          index
          path={`${loyaltyPaths.promptDialog}`}
          element={<Homepage component={<LoyaltyInfoDialog />} />}
        /> */}
        <Route
          path={`${loyaltyPaths.consent}`}
          element={
            <AuthorizedNonLoyalty
              component={<Homepage component={<LoyaltyConsentDialog />} />}
            />
          }
        />
        <Route
          path={`${loyaltyPaths.faqs}`}
          element={
            <AuthorizedNonLoyalty
              component={<Homepage component={<LoyaltyFAQsDialog />} />}
            />
          }
        />
        <Route
          path={`${loyaltyPaths.landing}/${loyaltyPaths.faqs}`}
          element={
            <AuthorizedLoyalty
              component={
                <LoyaltyLanding
                  component={
                    <LoyaltyFAQsDialog
                      closeDialogRedirection={`/loyalty/${loyaltyPaths.landing}`}
                    />
                  }
                />
              }
            />
          }
        />

        <Route
          path={`${loyaltyPaths.questionnaire}`}
          element={<Homepage component={<QuestionnaireDialog />} />}
        />
        <Route
          path={`${loyaltyPaths.offerFlow}/:stepName`}
          element={<Homepage component={<QuestionnaireDialog />} />}
        />

        <Route
          path="*"
          element={<Homepage component={<LoyaltyInfoDialog />} />}
        />
      </Routes>
    </>
  );
};

export default LoyaltyRouter;
