import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CopyIcon } from 'assets/icons/CopyIcon.svg';
import { ReactComponent as DashedBorder } from 'assets/icons/DashedBorder.svg';
import { IconButton } from 'components/Buttons/Buttons';
import CouponExpiredIcon from 'assets/icons/CouponExpiredIcon.png';
import CouponDate from './CouponDate';
import QRCode from 'react-qr-code';
import GoogleWallet from './GoogleWallet';
import AppleWallet from './AppleWallet';
import HtmlRenderer from 'components/CmsHtmlField/CmsHtmlField';

import styles from './CouponWidget.module.scss';

const CouponCodeDisplay = ({ isActive, pin, code }) => {
  const { t } = useTranslation(['profile']);

  if (!isActive)
    return (
      <img
        src={CouponExpiredIcon}
        className={styles.expiredIcon}
        alt="expired"
      />
    );

  if (pin)
    return (
      <div className={styles.pinContainer}>
        <p>{t('my-coupons.pin')}</p>
        <p className={styles.pinCode}>{pin}</p>
      </div>
    );

  return (
    <QRCode size={80} value={code} bgColor="transparent" fgColor="#FFFFFF" />
  );
};

const CouponWidget = ({ data, customerId, isIos, isAndroid, isPC }) => {
  const { t } = useTranslation(['profile']);

  const {
    title,
    description,
    link,
    code,
    pin,
    userId,
    createdAt,
    active: isActiveCoupon,
  } = data || {};

  const getStatusCircle = (coupon) =>
    coupon?.active ? 'active' : coupon?.lastRedeemedAt ? 'redeemed' : 'expired';

  const getExpirationDateDescription = (coupon) =>
    coupon?.active
      ? t('my-coupons.active-until')
      : coupon?.lastRedeemedAt
        ? t('my-coupons.redeemed')
        : t('my-coupons.expired');

  const getExpirationDate = (coupon) =>
    coupon?.lastRedeemedAt || coupon?.expiresAt;

  return (
    <article className={styles.couponWidgetContainer}>
      <div className={styles.infoContainer}>
        <p className={styles.title}>{title}</p>
        {description && (
          <HtmlRenderer value={description} className={styles.description} />
        )}
        {link && <HtmlRenderer value={link} className={styles.description} />}
      </div>
      <DashedBorder className={styles.grayLine} />
      <div className={styles.contentContainer}>
        <div className={styles['contentContainer-codeAndIcon']}>
          <p
            className={isActiveCoupon ? styles.activeCode : styles.expiredCode}
          >
            {code}
          </p>
          {isActiveCoupon && (
            <IconButton
              aria-label="copy"
              onClick={() => {
                navigator.clipboard.writeText(code);
              }}
            >
              <CopyIcon height="28px" width="28px" />
            </IconButton>
          )}
        </div>
        <CouponCodeDisplay isActive={isActiveCoupon} code={code} pin={pin} />
        <p className={styles.userId}>
          {t('my-coupons.userId')} {userId}
        </p>
        {isActiveCoupon && (
          <div className={styles['addToWallet-container']}>
            {(isPC || isIos) && (
              <AppleWallet couponCode={code} customerId={customerId} />
            )}

            {(isPC || isAndroid) && (
              <GoogleWallet couponCode={code} customerId={customerId} />
            )}
          </div>
        )}
      </div>
      <div className={styles.datesContainer}>
        <CouponDate description={t('my-coupons.received')} date={createdAt} />
        <CouponDate
          statusCircle={getStatusCircle(data)}
          description={getExpirationDateDescription(data)}
          date={getExpirationDate(data)}
        />
      </div>
    </article>
  );
};

export default CouponWidget;
