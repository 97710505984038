const consumptionStates = {
  clearing: 0,
  prediction: 1,
  register: 2,
  registered: 3,
  clearingGas: 4,
  mediumVoltage: 5,
};

export default consumptionStates;
