import React from 'react';

import styles from './MyVibesExplanation.module.scss';

const MyVibesExplanation = ({ vibes = [] }) => {
  return vibes.map((vibe) => (
    <div className={styles.container} key={vibe.key}>
      <div className={styles.icon}>
        <img src={vibe.imageUrl} alt={vibe.key} width={32} height={32} />
      </div>

      <div>
        <p className={styles.header}>
          <b>{vibe.title}</b>
        </p>
        <p>{vibe.description}</p>
      </div>
    </div>
  ));
};
export default MyVibesExplanation;
