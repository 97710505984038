import React from 'react';
import { formatDate } from 'utils/dateUtils';
import { useTranslation } from 'react-i18next';

import { dateFormats, meterPaymentMethods } from 'utils/product/config';
import MeterIconText from 'components/Labels/MeterIconText';
import HistoryRecordWrapper from 'components/History/HistoryRecordWrapper';
import HistoryRecordHeader from 'components/History/HistoryRecordHeader';
import HistoryRecordItem from 'components/History/HistoryRecordItem';
import { formatMoneyWithCurrency } from 'utils/moneyUtils';

const MyBillsHistoryPaymentsTabContent = ({ payment }) => {
  const selectedPaymentMethod = meterPaymentMethods.find(
    (x) => x.value === payment.PaymentMethod
  );
  const { t } = useTranslation(['bills', 'homepage']);

  return (
    <HistoryRecordWrapper>
      <HistoryRecordHeader
        meterName={
          <MeterIconText
            meterNo={payment?.MeterNo}
            commodity={payment?.Commodity}
            friendlyName={payment?.FriendlyName}
          />
        }
        text={formatMoneyWithCurrency(payment?.Amount)}
      />
      <HistoryRecordItem
        text={formatDate(payment.PaymentDate, dateFormats.dateHalfYear)}
        value={
          selectedPaymentMethod?.icon === 'card'
            ? t('paymentType-card')
            : t('paymentType-money')
        }
      />
    </HistoryRecordWrapper>
  );
};
export default MyBillsHistoryPaymentsTabContent;
