import React from 'react';
import { useTranslation } from 'react-i18next';

import { getLVConsumptionInfo } from 'utils/product/consumptionUtils';
import { ConsumptionIndications } from 'components/Consumptions/Home/ConsumptionInfoElements';

import styles from './ConsumptionClearing.module.scss';

const ConsumptionClearing = ({ meter }) => {
  const { t } = useTranslation(['consumptions']);
  const consumptionInfo = getLVConsumptionInfo(meter);
  return (
    <section className={styles.container}>
      <section className={styles.info}>
        <ConsumptionIndications
          {...consumptionInfo}
          dividedSize="xxxl"
          dividedUnitSize="xxl"
        />
      </section>
      <p>{t(`consumptions:overview.clearing-explanation`)}</p>
    </section>
  );
};
export default ConsumptionClearing;
