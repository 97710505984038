import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import EbillElectricityDialog from './EbillElectricityDialog';
import EbillGasDialog from './EbillGasDialog';
import { getRewardPoints } from 'utils/product/loyalty/pointsUtils';
import RewardDialog from 'components/Loyalty/RewardDialog';
import { getWaveId } from 'utils/product/loyalty/pointsUtils';
import { useAuthUser } from 'utils/AuthUser';
import { useCollectionOptions } from 'data/useCollectionOptions';
import { collectionOptionTypes } from 'utils/product/enums/cmsEnums';
import useDialog from 'hooks/useDialog';
import { SuccessDialog } from 'components/PromptDialogs/PromptDialog';
import { receivePoints } from 'api/loyalty';
import { logWaveServiceFailure } from 'utils/product/logs';
import {
  isEbillRewarded,
  isMeterEligibleForReward,
} from 'utils/product/loyalty/ebillUtils';
import commodities from 'utils/product/enums/commodities';
import { mutateLoyaltyHistory } from 'data/useLoyaltyHistory';
import { mutateLoyaltyPoints } from 'data/useRewardSchemeAccounts';
import { trackEbillEvent } from 'utils/product/tracking/loyaltyEvents';
import { getTrackingEnergyType } from 'utils/product/tracking/trackingLabels';
import { EbillSuccessMessage } from './EbillSuccessMessage';

const EbillDialog = ({ open, closeDialog, mutate, meter, loyaltyHistory }) => {
  const { t } = useTranslation(['bills', 'homepage', 'loyalty']);
  const rewardDialog = useDialog();
  const { isLoyaltyCustomer, loyaltyCustomerId } = useAuthUser();
  const { collectionOptionsByType, loading: collectionOptionsLoading } =
    useCollectionOptions();
  const successDialog = useDialog();
  const [rewardPoints, setRewardPoints] = useState();
  const [ebillData, setEbillData] = useState();

  const waveId = getWaveId(
    collectionOptionsByType,
    collectionOptionTypes.E_BILL
  );

  const receiveLoyaltyPoints = (meterId) => {
    const postData = {
      customer: {
        customerId: loyaltyCustomerId,
      },
      type: 'CUSTOM',
      ruleId: waveId,
      items: [{ id: meterId }],
    };

    if (waveId) {
      return receivePoints(postData)
        .then((response) => {
          mutate();
          mutateLoyaltyHistory(loyaltyCustomerId);
          mutateLoyaltyPoints(loyaltyCustomerId);
          setRewardPoints(getRewardPoints(response?.data, waveId));
          rewardDialog.show();
          trackEbillEvent('ebill_success_with_loyalty_points', '', {
            energy_type: getTrackingEnergyType(meter?.Commodity),
          });
        })
        .catch((error) => {
          closeDialog();
        });
    }
    logWaveServiceFailure(
      'Loyalty | Ebill reward failed - empty waveId',
      loyaltyCustomerId,
      waveId,
      postData
    );
    closeDialog();
  };

  const handleOnSuccessActivation = (data) => {
    setEbillData(data);
    if (
      isLoyaltyCustomer &&
      isMeterEligibleForReward(meter) &&
      !isEbillRewarded(meter?.Id, loyaltyHistory)
    ) {
      return receiveLoyaltyPoints(meter?.Id);
    } else {
      successDialog.show();
    }
  };

  const handleOnSuccessDeactivation = () => {
    mutate();
  };

  if (!meter) return null;

  return (
    <>
      {meter?.Commodity === commodities.energy ? (
        <EbillElectricityDialog
          open={open}
          closeDialog={closeDialog}
          onSuccessActivation={handleOnSuccessActivation}
          onSuccessDeactivation={handleOnSuccessDeactivation}
          meter={meter}
        />
      ) : (
        <EbillGasDialog
          open={open}
          closeDialog={closeDialog}
          onSuccessActivation={handleOnSuccessActivation}
          onSuccessDeactivation={handleOnSuccessDeactivation}
          meter={meter}
        />
      )}
      <RewardDialog
        open={rewardDialog.isOpen}
        closeDialog={() => {
          mutate();
          rewardDialog.close();
        }}
        points={rewardPoints}
        icon={null}
        header={t(`loyalty:collection-success.E_BILL.header`)}
        message={t(`loyalty:collection-success.E_BILL.message`)}
        subMessage={
          <EbillSuccessMessage
            isEdit={ebillData?.isEdit}
            email={ebillData?.email}
            phone={ebillData?.phone}
          />
        }
        loading={collectionOptionsLoading}
      />
      <SuccessDialog
        open={successDialog.isOpen}
        closeDialog={() => {
          mutate();
          successDialog.close();
        }}
        header={
          ebillData?.isEdit
            ? t('translation:completion')
            : t('management:ebill.success-activation-header')
        }
      >
        <EbillSuccessMessage
          isEdit={ebillData?.isEdit}
          email={ebillData?.email}
          phone={ebillData?.phone}
        />
      </SuccessDialog>
    </>
  );
};

export default EbillDialog;
