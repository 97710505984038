import React from 'react';

import styles from './LabelValue.module.scss';

const LabelValue = ({ label, value }) => {
  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      <p className={styles.value}>{value}</p>
    </div>
  );
};

export default LabelValue;
