import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BubbleContext } from 'components/HowToWizardBubble/BubbleContext';
import useUniqueFlow from 'hooks/useUniqueFlow';
import { trackWizardEvent } from 'utils/product/tracking/events';
import getWindowDimensions from 'utils/getWindowDimensions';

import styles from './BubbleContent.module.scss';

const BubbleContent = ({ step, header, content, side = 'bottom' }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);
  const { currentSlideIndex, nextIndex } = useContext(BubbleContext);
  const { width } = getWindowDimensions();

  const { incrementStep } = useUniqueFlow();

  const handleNextClick = () => {
    nextIndex();
    trackWizardEvent('login_home_wizard_continue');
  };

  const handleClose = () => {
    step < 3 ? navigate('/') : incrementStep();
    trackWizardEvent('login_home_wizard_close');
  };

  useEffect(() => {
    if (width < 1200) incrementStep();
  }, [width, incrementStep]);

  // LOYALTY
  // TODO: change when loyalty is back
  const numberOfSteps = 3;
  return (
    <>
      {currentSlideIndex === step && (
        <div className={styles[side]}>
          <h4 className={styles.header}>
            {header}
            <div>
              ({currentSlideIndex}/{numberOfSteps})
            </div>
          </h4>
          <p className={styles.content}>{content}</p>
          {(side === 'bottom' || side === 'bottomFirst') && step < 3 && (
            <div className={styles['end-bottom']}>
              <p className={styles.next} onClick={handleClose}>
                {t('translation:close')}
              </p>
              <button className={styles.wizardButton} onClick={handleNextClick}>
                {t('translation:continue')}
              </button>
            </div>
          )}
          {(side === 'right' || step === 3) && (
            <div className={styles[`end-${side}`]}>
              <p className={styles.next} onClick={handleClose}>
                {t('translation:close')}
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BubbleContent;
