import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import SingleColumnLayout from 'layouts/SingleColumnLayout';
import IconText from 'components/IconText/IconText';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import LoyaltyDebitPaymentWidget from './LoyaltyDebitPaymentWidget';
import { useCollectionOptions } from 'data/useCollectionOptions';
import { getPointsOfCategory } from 'utils/product/loyalty/pointsUtils';
import { formatMoney } from 'utils/moneyUtils';
import { collectionOptionTypes } from 'utils/product/enums/cmsEnums';
import { ReactComponent as EnergyIcon } from 'assets/icons/LoyaltyEnergyIcon.svg';

import styles from './LoyaltyDebitPayment.module.scss';

const LoyaltyDebitPayment = () => {
  const { t, i18n } = useTranslation(['loyalty']);
  const { collectionOptionsByType, loading } = useCollectionOptions(
    i18n.language
  );
  const debitPaymentPoints = getPointsOfCategory(
    collectionOptionsByType,
    collectionOptionTypes.DEBIT_PAYMENT
  );
  return (
    <SingleColumnLayout
      head={{
        title: t('loyalty:collection-options.DEBIT_PAYMENT.title'),
        metaTags: [
          {
            name: 'title',
            content: t('loyalty:collection-options.DEBIT_PAYMENT.title'),
          },
        ],
      }}
    >
      <WidgetSkeleton
        heightInPixels="500"
        show={loading}
        title={
          <WidgetHeader
            title={t('loyalty:collection-options.DEBIT_PAYMENT.title')}
          />
        }
      >
        <Widget
          background="transparent"
          title={
            <WidgetHeader
              title={t('loyalty:collection-options.DEBIT_PAYMENT.title')}
            />
          }
        >
          <p>{t('loyalty:collection-options.DEBIT_PAYMENT.message')}</p>

          <IconText
            containerClass={styles.topIconSection}
            content={
              <Trans
                i18nKey={'loyalty:collection-options.DEBIT_PAYMENT.reward'}
                values={{
                  points: formatMoney(debitPaymentPoints, false),
                }}
                components={{
                  bold: <b />,
                  yellow: <span className={styles['warning-text']}></span>,
                }}
              />
            }
            Icon={<EnergyIcon width={24} height={24} />}
            gap="xs"
          />
          <LoyaltyDebitPaymentWidget />
        </Widget>
      </WidgetSkeleton>
    </SingleColumnLayout>
  );
};

export default LoyaltyDebitPayment;
