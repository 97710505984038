const contestParticipationStatuses = {
  default: 'DEFAULT',
  winner: 'WINNER',
  runnerUp: 'RUNNER-UP',
  rejected: 'REJECTED',
};

const winnerStatuses = {
  pending: 'PENDING',
  accepted: 'ACCEPTED',
  rejected: 'REJECTED',
  rejectedAutomatically: 'REJECTED_AUTOMATICALLY',
};

const contestUserStatus = {
  active: 'ACTIVE',
  winner: 'WON',
  completed: 'COMPLETED',
  loser: 'LOSER',
};

const contestStatuses = {
  available: 'AVAILABLE',
  notAvailable: 'NOT_AVAILABLE',
  draft: 'DRAFT',
  expired: 'EXPIRED',
};

const contestDrawStatuses = {
  NOTSTARTED: 'notStarted',
  RUNNING: 'running',
  COMPLETED: 'completed',
};

export {
  contestParticipationStatuses,
  contestUserStatus,
  contestStatuses,
  winnerStatuses,
  contestDrawStatuses,
};
