import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Skeletons/Loader';
import { Chart } from 'react-google-charts';
import { useForm } from 'react-hook-form';
import Big from 'big.js';

import { useLiveStatsGraph } from 'data/liveStats';
import { formatDate, addDays, isValidDate } from 'utils/dateUtils';
import { dateFormats } from 'utils/product/config';
import { trackEnaEvent } from 'utils/product/tracking/events';
import RadioButton from 'components/RadioButtonsGroup/RadioButton';
import RadioGroup from 'components/RadioButtonsGroup/RadioGroup';
import { DatePicker } from 'components/Inputs/DatePicker';

import styles from './LiveStatsGraph.module.scss';

const LiveStatsGraph = ({ powerFactor }) => {
  const { t } = useTranslation(['ena', 'translation']);
  const {
    formState: { errors },
    control,
    register,
  } = useForm();
  const now = new Date();
  const [dateFrom, setDateFrom] = useState(now);
  const [dateTo, setDateTo] = useState(now);
  const [date, setDate] = useState(now);
  const [range, setRange] = useState('1');
  const { data, maxValue, loading } = useLiveStatsGraph(
    powerFactor,
    dateFrom,
    dateTo
  );

  useEffect(() => {
    const isValid = isValidDate(date);
    if (isValid) {
      const _dateFrom = addDays(date, -new Big(range).toNumber() + 1);
      setDateFrom(_dateFrom);
      setDateTo(date);
    }
  }, [date, range]);

  return (
    <section className={styles.container}>
      <form>
        <DatePicker
          name={`liveStatsDate`}
          label={t('ena:live-stats.chart-date')}
          value={date}
          onChange={(data) => {
            setDate(data);
            trackEnaEvent('ENA_live_stats_day');
          }}
          required={true}
          disableFuture={true}
          views={['date']}
          openTo="date"
          disableToolbar={false}
          customToolbar={true}
          maxDate={now}
          initialFocusedDate={now}
          className={styles.picker}
          errors={errors}
          control={control}
        />
        <section className={styles.chartContainer}>
          <Loader show={loading} height={250}>
            <Chart
              width={'100%'}
              height={'250px'}
              chartType="LineChart"
              loader={<div>Loading Chart</div>}
              data={[
                ['time', 'value'],
                ...data.map((x) => [
                  range === '1'
                    ? formatDate(x.time, dateFormats.time)
                    : formatDate(x.time, dateFormats.dateTimeNoYear),
                  x.value,
                ]),
              ]}
              options={{
                vAxis: {
                  minValue: 0,
                  textColor: '#FFFFFF',
                  viewWindow: {
                    min: 0,
                    max: maxValue,
                  },
                },
                hAxis: {
                  textColor: '#FFFFFF',
                },
                legend: 'none',
                colors: ['#FF4700'],
                backgroundColor: '#323870',
                chartArea: {
                  left: 48,
                  top: 10,
                  width: '90%',
                  height: '75%',
                },
              }}
            />
          </Loader>
        </section>
        <RadioGroup
          containerClass={styles.radios}
          control={control}
          name="range"
          value={range}
          handleChange={(event) => {
            setRange(event.target.value);
          }}
        >
          <RadioButton
            value="1"
            label={`1 ${t('ena:live-stats.day').toLowerCase()}`}
            register={register}
            name="range"
            className={styles.radio}
          />
          <RadioButton
            value="7"
            label={`7 ${t('ena:live-stats.days')}`}
            register={register}
            name="range"
            className={styles.radio}
          />
          <RadioButton
            value="30"
            label={`30 ${t('ena:live-stats.days')}`}
            register={register}
            name="range"
            className={styles.radio}
          />
        </RadioGroup>
      </form>
    </section>
  );
};

export default LiveStatsGraph;
