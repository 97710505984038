import React from 'react';
import { useTranslation } from 'react-i18next';

import TwoColumnsLayout from 'layouts/TwoColumnsLayout';
import RightSidebar from './RightSidebar';
import MainArea from './MainArea';

const Faqs = () => {
  const { t } = useTranslation(['homepage']);
  return (
    <TwoColumnsLayout
      head={{
        title: t('homepage:FAQs.title'),
        metaTags: [{ name: 'title', content: t('homepage:FAQs.title') }],
      }}
      main={<MainArea />}
      right={<RightSidebar />}
    />
  );
};

export default Faqs;
