import { useCmsContent } from './useCmsContent';

const usePromotion = (language, promotionType) => {
  const { data, loading, error } = useCmsContent(
    'promotions,for_you',
    language,
    false,
    '[or][schema_in]',
    { query: `filter[or][content.tags.el-GR_in]=${promotionType}` }
  );

  const promotion =
    data && !loading
      ? data?.data.find((x) => x.content.tags?.indexOf(promotionType) > -1)
      : {};

  const forYouItem =
    data && !loading
      ? data?.data.find(
          (x) =>
            x.content.cxmEntity?.id === promotion?.content?.discoveryItem?.id
        )
      : {};

  return {
    loading: loading,
    error: error,
    data: promotion,
    forYouItem: forYouItem,
  };
};
export { usePromotion };
