import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import Widget from 'components/Widget/Widget';
import { PrimaryButton } from 'components/Buttons/Buttons';
import styles from './FallbackViews.module.scss';

const FallbackWidget = ({
  error,
  resetErrorBoundary,
  header,
  message,
  buttonText,
  clickHandler,
  redirectToHome = false,
  ...rest
}) => {
  return (
    <Widget {...rest}>
      <h4>{header}</h4>
      <p>{message}</p>
      {redirectToHome ? (
        <PrimaryButton component={Link} to="/">
          {buttonText}
        </PrimaryButton>
      ) : (
        <PrimaryButton
          onClick={() => {
            if (typeof clickHandler === 'function') {
              clickHandler();
            }
          }}
        >
          {buttonText}
        </PrimaryButton>
      )}
    </Widget>
  );
};

const DefaultFallback = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation(['translation', 'onboarding']);
  return (
    <section className={cn(styles['default-wrapper'])}>
      <FallbackWidget
        header="myΗΡΩΝ"
        message={t('translation:genericError')}
        buttonText={t('onboarding:changePassword.return')}
        distance="m"
        widgetClass={styles['default-container']}
        clickHandler={() => {
          //resetErrorBoundary();
          window.location = '/';
        }}
      />
    </section>
  );
};

export { DefaultFallback, FallbackWidget };
