import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Accordion from 'components/Accordion/Accordion';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import { useLoyaltyFAQs } from 'data/useLoyaltyFAQs';
import CmsHtmlField from 'components/CmsHtmlField/CmsHtmlField';

import styles from './LoyaltyConsentDialog.module.scss';

const LoyaltyFAQsDialog = ({ closeDialogRedirection = '/' }) => {
  const [open, setOpen] = useState(true);
  const { i18n } = useTranslation(['loyalty']);
  const navigate = useNavigate();
  const { data: faqsContent, loading } = useLoyaltyFAQs(i18n.language);

  const accordionClasses = {
    root: styles['accordion'],
  };
  const accordionSummaryClasses = {
    root: styles['accordion-summary'],
    expanded: styles['accordion-summary-expanded'],
    content: styles['accordion-summaryContent'],
    expandIcon: styles['accordion-expandIcon'],
  };

  const accordionDetailsClasses = {
    root: styles['accordion-details'],
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <DialogOverlay
      open={open}
      closeDialog={() => {
        setOpen(false);
        navigate(closeDialogRedirection);
      }}
      contentClass={styles.dialogContent}
      scroll="body"
      showBackButton
      showCloseButton
      goBack={goBack}
      dialogHeader={faqsContent?.title}
    >
      <WidgetSkeleton heightInPixels={400} distance="s" show={loading}>
        {faqsContent?.faqs?.map((item) => (
          <Accordion
            key={item?.header}
            classes={accordionClasses}
            summaryClasses={accordionSummaryClasses}
            detailsClasses={accordionDetailsClasses}
            details={<CmsHtmlField value={item?.message} />}
            summary={item?.header}
            horizontalLineClass={styles.accordionLine}
          />
        ))}
      </WidgetSkeleton>
    </DialogOverlay>
  );
};

export default LoyaltyFAQsDialog;
