import api from './api';

const setMeter = (meter) => {
  return api.post('/meter/set', meter);
};

const setMeterFriendlyName = (meters, accountId) => {
  return api.post('/meter/friendlyName', {
    modelList: meters,
    AccountId: accountId,
  });
};

const setMeterEbilling = (meterId, ebill, commodity) => {
  return api.get(
    `/meter/ebilling?meterId=${meterId}&ebill=${ebill}&commodity=${commodity}`,
  );
};

const getMeterRepresantationPdf = (meterId, accountId) => {
  return api.get(
    `/meter/representationPdf?meterId=${meterId}&accountId=${accountId}`,
    {
      responseType: 'blob',
    },
  );
};

const getRecurringPaymentStatus = (meterId, commodity) => {
  return api.get(
    `/meter/standingorder?MeterId=${meterId}&Commodity=${commodity}`,
  );
};

const getMeterTerminationPdf = (meterId, accountId) => {
  return api.get(
    `/meter/termination?meterId=${meterId}&accountId=${accountId}`,
    {
      responseType: 'blob',
    },
  );
};

const meterDebitCardDeletion = (meterId, creditId) => {
  return api.delete('/meter/card', {
    data: {
      MeterId: meterId,
      CreditId: creditId,
    },
  });
};

export {
  setMeter,
  setMeterFriendlyName,
  getMeterRepresantationPdf,
  getMeterTerminationPdf,
  setMeterEbilling,
  getRecurringPaymentStatus,
  meterDebitCardDeletion,
};
