import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput } from 'components/Inputs/TextInput';
import Checkbox from 'components/Checkbox/Checkbox';
import PostalCodeInput from 'components/Inputs/PostalCodeInput';

import styles from './BillAddress.module.scss';

const BillAddress = ({
  defaultValues = {},
  onUpdate = () => {},
  isGasProgram = false,
}) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { t } = useTranslation(['newClient']);
  const baseObjectName = isGasProgram ? 'gasProgram' : 'energyProgram';

  const handleChange = (event) => {
    setValue(`${baseObjectName}.standingOrder`, event.target.checked);
    onUpdate({
      [baseObjectName]: {
        ...defaultValues[baseObjectName],
        standingOrder: event.target.checked,
      },
    });
  };
  const handlePostalCodeChange = ({ postalCodeId }) => {
    setValue(`${baseObjectName}.billToPostalCodeId`, postalCodeId);
    onUpdate({
      [baseObjectName]: {
        ...defaultValues[baseObjectName],
        billToPostalCodeId: postalCodeId,
      },
    });
  };
  return (
    <>
      <article className={styles.container}>
        <h3 className={styles.header}>{t(`newClient:billAddress.header`)}</h3>
        <TextInput
          register={register}
          errors={errors}
          name={`${baseObjectName}.billToAddress`}
          label={t(`newClient:sharedDetails.street`)}
          defaultValue={
            defaultValues[baseObjectName]?.billToAddress ??
            defaultValues[baseObjectName]?.meterAddress
          }
        />
        <TextInput
          register={register}
          errors={errors}
          name={`${baseObjectName}.billToAddressNo`}
          label={t(`newClient:sharedDetails.streetNo`)}
          defaultValue={
            defaultValues[baseObjectName]?.billToAddressNo ??
            defaultValues[baseObjectName]?.meterAddressNo
          }
        />
        <PostalCodeInput
          register={register}
          errors={errors}
          postalCodeName={`${baseObjectName}.billToPostalCode`}
          postalCodeValue={
            defaultValues[baseObjectName]?.billToPostalCode ??
            defaultValues[baseObjectName]?.meterPostalCode
          }
          postalCodeIdName={`${baseObjectName}.billToPostalCodeId`}
          postalCodeIdValue={
            defaultValues[baseObjectName]?.billToPostalCodeId ??
            defaultValues[baseObjectName]?.meterPostalCodeId
          }
          onChangeHandler={handlePostalCodeChange}
        />
      </article>
      <article className={styles.container}>
        <Checkbox
          value={defaultValues[baseObjectName]?.standingOrder ?? false}
          changeHandler={handleChange}
          name={`${baseObjectName}.standingOrder`}
          label={t(`newClient:eBill.standingOrder`)}
          register={register}
          labelClass={styles.checkbox}
        />
      </article>
    </>
  );
};

export default BillAddress;
