import uniqBy from 'lodash/uniqBy';

export const getPostalOffices = (code, postalOffices) => {
  const _officesForCode = postalOffices?.filter(
    (x) => x.Code.length === 5 && x.Code === `${code}`
  );
  return uniqBy(_officesForCode, (x) => x.City);
};

export const maxLengthPattern = (maxDigits) =>
  new RegExp(`^0*[0-9]{0,${maxDigits}}$`);

export const arrayFiltering = (array, arrayFields, searchValue) => {
  if (!searchValue) return array;

  const matches = (value, term) =>
    value?.toLowerCase()?.includes(term?.toLowerCase());

  const itemMatches = (fields, term) => (item) =>
    fields?.some((field) => matches(item?.[field], term));

  return array?.filter(itemMatches(arrayFields, searchValue));
};
