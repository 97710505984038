import React from 'react';

function TabPanel({
  children,
  value,
  index,
  idPrefix,
  containerClass = '',
  innerClass = '',
}) {
  return (
    <section
      role="tabpanel"
      hidden={value !== index}
      id={`${idPrefix}-tabpanel-${index}`}
      aria-labelledby={`${idPrefix}-${index}`}
      className={containerClass}
    >
      {value === index && <article className={innerClass}>{children}</article>}
    </section>
  );
}

export default TabPanel;
