import React from 'react';

import Widget from 'components/Widget/Widget';

import styles from './SidebarLinkMenu.module.scss';

const SidebarLinkMenu = ({
  size,
  distance,
  title,
  children,
  widgetClass = '',
}) => {
  return (
    <Widget
      size={size}
      distance={distance}
      widgetClass={widgetClass ? widgetClass : styles.sidebarLinkWidget}
      title={title}
    >
      <section>{children}</section>
    </Widget>
  );
};

export default SidebarLinkMenu;
