import React from 'react';
import { useTranslation } from 'react-i18next';

import SidebarLinkMenu from 'components/SidebarElements/SidebarLinkMenu';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import SidebarLinkElement from 'components/SidebarElements/SidebarLinkElement';
import WidgetLine from 'components/WidgetLine/WidgetLine';
import { trackProfileEvent } from 'utils/product/tracking/events';
import { useAuthUser } from 'utils/AuthUser';
import { accountHasSolarMeters } from 'utils/product/accountUtils';

const ManagementRightSideBar = () => {
  const { t } = useTranslation(['management']);
  const baseName = 'management:managementRightSidebar';
  const { user } = useAuthUser();
  const hasSolarMeters = accountHasSolarMeters(user);

  return (
    <>
      <SidebarLinkMenu
        distance="s"
        title={<WidgetHeader title={t(`${baseName}.important-info-header`)} />}
      >
        <SidebarLinkElement
          target={`contact-details`}
          content={t(`${baseName}.contract-info`)}
          onClick={() => trackProfileEvent('management_personal_info')}
        />
        <WidgetLine />
        <SidebarLinkElement
          target={`meters`}
          content={t(`${baseName}.supplies`)}
          onClick={() => trackProfileEvent('management_supplies')}
        />
        <WidgetLine />
        {hasSolarMeters && (
          <>
            <SidebarLinkElement
              target={`photovoltaic`}
              content={t(`${baseName}.solarMeters`)}
              onClick={() => trackProfileEvent('management_photovoltaic')}
            />
            <WidgetLine />
          </>
        )}
        <SidebarLinkElement
          target={`contracts`}
          content={t(`${baseName}.contracts`)}
          onClick={() => trackProfileEvent('management_contracts')}
        />
      </SidebarLinkMenu>
      <SidebarLinkMenu
        distance="s"
        title={
          <WidgetHeader title={t(`${baseName}.accounts-and-payments-header`)} />
        }
      >
        <SidebarLinkElement
          target={`ebill`}
          content={t(`${baseName}.eBill`)}
          onClick={() => trackProfileEvent('management_eBill')}
        />
        <WidgetLine />
        <SidebarLinkElement
          target={`direct-debit-payment`}
          content={t(`${baseName}.standing-order`)}
          onClick={() => trackProfileEvent('management_standing-order')}
        />
      </SidebarLinkMenu>
    </>
  );
};

export default ManagementRightSideBar;
