import React from 'react';
import cn from 'classnames';

import styles from './TextArrow.module.scss';

const TextArrow = ({ Icon, content, weight, contentClass }) => {
  return (
    <>
      <div
        className={cn(
          {
            [styles[`text-${weight}`]]: weight !== undefined,
            [styles[`text-regular`]]: weight === undefined,
          },
          contentClass
        )}
      >
        {content}
      </div>
      {Icon && <div className={styles['icon-right']}>{Icon}</div>}
    </>
  );
};

export default TextArrow;
