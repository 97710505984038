import React from 'react';

import { ReactComponent as DownArrow } from 'assets/icons/ArrowDownIcon.svg';
import Select from '@material-ui/core/Select';

import styles from './StandardSelect.module.scss';

const modalUnderSelectInputProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

const menuProps = {
  ...modalUnderSelectInputProps,
};
const StandardSelect = React.forwardRef(
  (
    {
      IconComponent = DownArrow,
      classes = styles,
      defaultValue,
      children,
      MenuProps = menuProps,
      ...rest
    },
    ref
  ) => {
    return (
      <Select
        IconComponent={IconComponent}
        classes={classes}
        MenuProps={MenuProps}
        defaultValue={defaultValue}
        ref={ref}
        {...rest}
      >
        {children}
      </Select>
    );
  }
);

export default StandardSelect;
