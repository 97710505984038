import React from 'react';

import styles from './SolarisCalculatorResult.module.scss';

const SolarisCalculatorResult = ({ label, value, containerClass = '' }) => {
  return (
    <article className={`${styles.result} ${containerClass}`}>
      <span>{label}</span>
      <b>{value}</b>
    </article>
  );
};

export default SolarisCalculatorResult;
