import React from 'react';
import {
  RouterProvider,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import AuthorizedRoute from 'utils/AuthorizedRoute';
import UnauthorizedRoute from 'utils/UnauthorizedRoute';
import AuthorizedLayout from 'layouts/AuthorizedLayout';
import Login from 'pages/onboarding/Login/Login';
import CreateUser from 'pages/onboarding/CreateUser/CreateUser';
import ForgotPassword from 'pages/onboarding/ForgotPassword/ForgotPassword';
import ChangePassword from 'pages/onboarding/ChangePassword/ChangePassword';
import UserRegistrationBlank from 'pages/onboarding/CreateUser/UserRegistrationBlank';
import UserCreated from 'pages/onboarding/CreateUser/UserCreated';
import OnboardingRouter from 'components/OnboardingNewClient/OnboardingRouter';
import CookiesPolicy from 'pages/unauthorizedInfo/CookiesPolicy';

import CmsTerms from 'pages/unauthorizedInfo/CmsTerms';
import ComingSoon from 'components/ComingSoon/ComingSoon';
import PaymentResult from 'components/Payment/PaymentResult';
import TermsRenderer from 'components/Terms/TermsRenderer';
import LanguageRoute from './LanguageRoute';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="en/*" element={<LanguageRoute lang="en" />} />
      <Route path="el/*" element={<LanguageRoute lang="el" />} />
      <Route path="under-maintenance" element={<ComingSoon />} />
      <Route path="cookies" element={<CookiesPolicy />} />
      <Route path="terms/:slug" element={<CmsTerms />} />
      <Route path="terms-content/:slug" element={<TermsRenderer />} />
      <Route
        path="recurring-payment/success"
        element={<PaymentResult isSuccess={true} />}
      />
      <Route
        path="recurring-payment/failure"
        element={<PaymentResult isSuccess={false} />}
      />
      <Route
        path="/recurring-payment/internal-error"
        element={<PaymentResult isSuccess={false} />}
      />
      <Route
        path="payment/success"
        element={<PaymentResult isSuccess={true} />}
      />
      <Route
        path="payment/failure"
        element={<PaymentResult isSuccess={false} />}
      />
      <Route
        path="login"
        element={<UnauthorizedRoute component={<Login />} />}
      />
      <Route
        path="create-user"
        element={<UnauthorizedRoute component={<CreateUser />} />}
      />
      <Route path="user-created" element={<UserCreated />} />
      <Route
        path="changePassword"
        element={<UnauthorizedRoute component={<ChangePassword />} />}
      />
      <Route
        path="e-contract/*"
        element={<UnauthorizedRoute component={<OnboardingRouter />} />}
      />
      <Route
        path="forgot-password"
        element={<UnauthorizedRoute component={<ForgotPassword />} />}
      />
      <Route path="userRegistration" element={<UserRegistrationBlank />} />
      <Route
        path="/*"
        element={<AuthorizedRoute component={<AuthorizedLayout />} />}
      />
    </>,
  ),
);

// Normalize all paths to not have trailing slashes even if they
// matched <Route> with one:
const Router = ({ children }) => (
  <RouterProvider router={router}>{children}</RouterProvider>
);

export default Router;
