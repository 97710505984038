import { useContestParticipations } from 'data/useContestParticipations';
import { useAuthUser } from 'utils/AuthUser';
import { getUserProfileContestsData } from 'utils/product/profileContestUtils';
import { useForYouSchema } from './useForYouSchema';

const useMyContestsInfo = (language) => {
  const { accountId } = useAuthUser();

  const {
    data: forYouItems,
    loading: forYouLoading,
    error: forYouError,
  } = useForYouSchema(language);

  const {
    data: participations,
    loading: participationsLoading,
    error: errorParticipations,
  } = useContestParticipations(accountId);

  const loading = participationsLoading || forYouLoading;

  const results = loading
    ? []
    : getUserProfileContestsData(participations, forYouItems);

  return {
    loading: loading,
    error: errorParticipations || forYouError,
    data: results,
  };
};

export { useMyContestsInfo };
