import React from 'react';

import StandardSelect from 'components/Selects/StandardSelect';
import StandardSelectOption from 'components/Selects/StandardSelectOption';
import SelectFormControl from 'components/Selects/SelectFormControl';
import SelectLabel from 'components/Selects/SelectLabel';

import useLocalizedFriendlyName from 'hooks/useLocalizedFriendlyName';
import MeterIconText from 'components/Labels/MeterIconText';

const MetersListDialogSelect = ({ label, handleChange, value, meters }) => {
  const { getDisplayName } = useLocalizedFriendlyName();

  const getMeterText = (meter) => {
    const { MeterNo, FriendlyName } = meter || {};
    return getDisplayName(FriendlyName, MeterNo);
  };

  return (
    <SelectFormControl>
      <SelectLabel>{label}</SelectLabel>
      <StandardSelect
        onChange={handleChange}
        value={value}
        defaultValue={value}
        renderValue={(_value) =>
          getMeterText(meters?.find((x) => x.Id === _value))
        }
      >
        {meters &&
          meters.map((meter) => {
            const { MeterNo, Id } = meter || {};
            return (
              MeterNo && (
                <StandardSelectOption key={`${Id}-selectOption`} value={Id}>
                  <MeterIconText
                    meterNo={meter?.MeterNo}
                    commodity={meter?.Commodity}
                    friendlyName={meter?.FriendlyName}
                    weight="regular"
                  />
                </StandardSelectOption>
              )
            );
          })}
      </StandardSelect>
    </SelectFormControl>
  );
};

export default MetersListDialogSelect;
