import commodities from 'utils/product/enums/commodities';
import {
  meterStatusesElectricity,
  meterStatusesGas,
} from '../enums/meterStatuses';

export const ALLOWED_METER_STATUSES_EL = [
  meterStatusesElectricity.active,
  meterStatusesElectricity.pending,
  meterStatusesElectricity.new,
];

export const ALLOWED_METER_STATUSES_GAS = [
  meterStatusesGas.active,
  meterStatusesGas.pending,
  meterStatusesGas.new,
];

export const findAlreadyActivatedEbillWithoutReward = (
  meters,
  rewardsLoyaltyHistory
) => {
  const metersWithEbill = meters?.filter((meter) => meter.Ebill);

  return metersWithEbill?.map((meter) => {
    const rewarded = rewardsLoyaltyHistory?.find((reward) => {
      return reward?.items?.find((item) => item.id === meter.Id);
    });

    return { ...meter, rewarded: !!rewarded };
  });
};

export const getIdsOfAlreadyActivatedEbillWithoutReward = (meters) => {
  return meters
    ?.filter((meter) => !meter?.rewarded)
    ?.map((meter) => ({ id: meter.Id }));
};

export const findNotActivatedEbillWithoutReward = (
  meters,
  rewardsLoyaltyHistory
) => {
  const metersWithOutEbill = meters?.filter((meter) => !meter.Ebill);

  return metersWithOutEbill?.filter(
    (meter) =>
      !rewardsLoyaltyHistory?.find((reward) => {
        return reward?.items?.find((item) => item.id === meter.Id);
      })
  );
};

export const isEbillRewarded = (meterId, rewardsLoyaltyHistory) => {
  if (!rewardsLoyaltyHistory) return true;

  return !!rewardsLoyaltyHistory?.find((reward) => {
    return reward?.items?.find((item) => item?.id === meterId);
  });
};

export const getLengthOfMetersEligibleForEbill = (meters) => {
  return meters?.filter(
    (meter) =>
      meter.Commodity === commodities.energy ||
      meter.Commodity === commodities.gas
  ).length;
};

export const isMeterEligibleForEbill = (meter) => {
  const status = meter?.MeterStatus;
  const isElectricityMeter = meter.Commodity === commodities.energy;
  const isGasMeter = meter.Commodity === commodities.gas;

  return (
    meter &&
    ((isElectricityMeter && ALLOWED_METER_STATUSES_EL.indexOf(status) > -1) ||
      (isGasMeter && ALLOWED_METER_STATUSES_GAS.indexOf(status) > -1))
  );
};

export const isMeterEligibleForReward = (meter) => {
  const status = meter?.MeterStatus;
  const isElectricityMeter = meter.Commodity === commodities.energy;
  const isGasMeter = meter.Commodity === commodities.gas;

  return (
    meter &&
    ((isElectricityMeter && ALLOWED_METER_STATUSES_EL.indexOf(status) > -1) ||
      (isGasMeter && ALLOWED_METER_STATUSES_GAS.indexOf(status) > -1))
  );
};
