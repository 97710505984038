import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';

import { useCollectionOptions } from 'data/useCollectionOptions';
import { collectionOptionTypes } from 'utils/product/enums/cmsEnums';
import { useAuthUser } from 'utils/AuthUser';
import CollectionOptionRewardDialog from './CollectionOptionRewardDialog';
import useDialog from 'hooks/useDialog';
import BirthdayDialog from './BirthdayDialog';
import CollectionOptionWidget from './CollectionOptionWidget';
import { isCmsItemEnabled } from 'utils/product/cmsUtils';
import {
  trackBirthdayEvent,
  trackLoyaltyPageEvent,
} from 'utils/product/tracking/loyaltyEvents';

const CollectionOptions = () => {
  const { t, i18n } = useTranslation(['translation', 'loyalty']);
  const navigate = useNavigate();
  const rewardDialog = useDialog();
  const birthdayDialog = useDialog();
  const [selectedType, setSelectedType] = useState();
  const { loyaltyCustomer, mutateLoyaltyCustomer } = useAuthUser();

  const { data, loading } = useCollectionOptions(i18n.language);
  const urls = {
    BILL_PAYMENT: '/loyalty/pending-bills',
    SHOP: '/shop/home',
    E_BILL: '/loyalty/activate-ebill',
    REGISTER_CONSUMPTION: '/loyalty/consumptions',
    DIRECT_DEBIT_PAYMENT: '/loyalty/debit-payment',
  };

  const onCollectionOptionClicked = (type) => {
    setSelectedType(type);
    trackLoyaltyPageEvent('loyalty_get_points_action_selected', '', {
      loyalty_action_type: type,
    });

    if (!!type) {
      if (!!urls[type]) {
        navigate(urls[type]);
        return;
      }
      if (type === collectionOptionTypes.REGISTER_LOYALTY) {
        rewardDialog.show();
        return;
      }
      if (type === collectionOptionTypes.PARTY_GIFTS) {
        if (loyaltyCustomer.dateOfBirth) {
          rewardDialog.show();
          trackBirthdayEvent('birthday_already_submitted');
          return;
        }
        birthdayDialog.show();
        return;
      }
      if (type === collectionOptionTypes.PARTY_GIFTS) {
        birthdayDialog.show();
      }
    }
  };

  return (
    <>
      <WidgetSkeleton
        distance="m"
        show={loading}
        title={<WidgetHeader title={t('loyalty:landing.collection-options')} />}
      >
        <Widget
          background="transparent"
          title={
            <WidgetHeader title={t('loyalty:landing.collection-options')} />
          }
        >
          {!loading &&
            data.map(
              (x) =>
                !x.tileIsHidden &&
                isCmsItemEnabled(x) && (
                  <CollectionOptionWidget
                    title={x.title}
                    type={x.type}
                    image={x.image}
                    description={x.description}
                    handleClick={onCollectionOptionClicked}
                    key={x.type}
                  />
                )
            )}
        </Widget>
      </WidgetSkeleton>
      {rewardDialog.isOpen && (
        <CollectionOptionRewardDialog
          open={rewardDialog.isOpen}
          closeDialog={rewardDialog.close}
          type={selectedType}
        />
      )}
      {birthdayDialog.isOpen && (
        <BirthdayDialog
          open={birthdayDialog.isOpen}
          closeDialog={() => {
            birthdayDialog.close();
            mutateLoyaltyCustomer();
          }}
          type={selectedType}
        />
      )}
    </>
  );
};
export default CollectionOptions;
