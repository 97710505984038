import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import Pager from 'components/Pager/Pager';
import IconText from 'components/IconText/IconText';
import EmptyState from 'components/History/EmptyState';
import HistoryEventDescription from './HistoryEventDescription';
import { ReactComponent as EmptyStateIcon } from 'assets/icons/EnergyEmptyState.svg';
import { ReactComponent as LoyaltyCreditIcon } from 'assets/icons/LoyaltyCreditIcon.svg';
import { ReactComponent as LoyaltyDebitIcon } from 'assets/icons/LoyaltyDebitIcon.svg';
import { formatMoney } from 'utils/moneyUtils';
import { formatDate } from 'utils/dateUtils';
import { dateFormats } from 'utils/product/config';
import {
  historyEventStatuses,
  historyEventTypes,
} from 'utils/product/enums/waveEnums';

import styles from './HistoryWidgetContent.module.scss';

const HistoryWidgetContent = ({ historyData, historyDataLength }) => {
  const { t } = useTranslation(['translation', 'loyalty']);

  const pagerLimit = 4;
  const [limit, setLimit] = useState(pagerLimit);
  const handleLimitChange = (limit) => {
    setLimit(limit);
  };

  const getCreditAmountClassName = (item) =>
    `creditAmount${
      item?.status === historyEventStatuses.PENDING ? '-pending' : ''
    }`;

  return (
    <Widget
      distance="s"
      title={<WidgetHeader title={t('loyalty:landing.history.title')} />}
      widgetClass={styles.widget}
    >
      {historyData?.length === 0 && (
        <EmptyState
          Icon={<EmptyStateIcon width={72} height={72} />}
          text={t('loyalty:landing.history.empty')}
          containerClass={styles['empty-state']}
        />
      )}
      {historyData?.length > 0 && (
        <>
          {historyData.slice(0, limit).map((item) => (
            <div className={styles.panel} key={item.key}>
              <IconText
                Icon={
                  item.transaction.type === historyEventTypes.DEBIT ? (
                    <LoyaltyDebitIcon />
                  ) : (
                    <LoyaltyCreditIcon />
                  )
                }
                containerClass={styles.iconSection}
                iconClass={styles.icon}
                gap="s"
                content={
                  <div className={styles.detailsContainer}>
                    <p className={styles.heading}>
                      {formatDate(item.createdAt, dateFormats.dayFullMonthYear)}
                    </p>
                    <p className={styles.subheading}>
                      <HistoryEventDescription item={item} />
                    </p>
                    {item?.status === historyEventStatuses.PENDING && (
                      <p className={styles.pointsDisclaimer}>
                        {t('loyalty:history.pointsDisclaimer')}
                      </p>
                    )}
                  </div>
                }
              />
              <p
                className={
                  styles[
                    `${
                      item.transaction.type === historyEventTypes.DEBIT
                        ? 'debitAmount'
                        : getCreditAmountClassName(item)
                    }`
                  ]
                }
              >
                {item.transaction.type === historyEventTypes.DEBIT ? '-' : '+'}
                {formatMoney(item.amount, false)}
              </p>
            </div>
          ))}
          <Pager
            totalCount={historyDataLength}
            onClick={handleLimitChange}
            containerClass={styles['moreButton-container']}
            pagerMin={pagerLimit}
          />
        </>
      )}
    </Widget>
  );
};

export default HistoryWidgetContent;
