import useSWR from 'swr';
import { fetcher } from 'api/api';
import { useAuthUser } from 'utils/AuthUser';

export default function useData(url, options = {}, skipApi = false) {
  const { user } = useAuthUser();

  const skip = !user?.Id || skipApi;
  const {
    data,
    error,
    mutate,
    isLoading: loading,
    isValidating: validating,
  } = useSWR(skip ? null : url, fetcher, options);

  return {
    validating,
    loading: loading || !user.Id,
    error,
    data,
    mutate,
  };
}
