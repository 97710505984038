import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/dateUtils';

import getRequestsHistory from 'utils/product/requests/getRequestsHistory';
import useRequests from 'data/useRequests';
import { ReactComponent as EmptyRequestsIcon } from 'assets/icons/EmptyRequestsIcon.svg';
import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import WidgetSkeleton from 'components/Skeletons/WidgetSkeleton';
import styles from './RequestsSidebar.module.scss';

const RequestsSidebar = () => {
  const { t, i18n } = useTranslation(['requests']);
  const baseName = 'requests:requests';
  const {
    requests,
    loading: requestsLoading,
    requestHistoryCategories,
    requestStatuses,
  } = useRequests(i18n.language);

  const requestsToDisplay = getRequestsHistory(
    requests,
    requestStatuses,
    requestHistoryCategories
  );

  return (
    <WidgetSkeleton
      distance="s"
      show={requestsLoading}
      title={<WidgetHeader title={t(`${baseName}.history`)} />}
    >
      <Widget
        size="m"
        distance="m"
        title={<WidgetHeader title={t(`${baseName}.history`)} />}
      >
        {requestsToDisplay.length > 0 ? (
          requestsToDisplay.map((request) => {
            const { _typeDescription, RequestDate } = request || {};

            const date = RequestDate && formatDate(RequestDate);
            return _typeDescription && date ? (
              <section key={request.RequestId} className={styles.section}>
                <span className={styles.span}>{_typeDescription}</span>
                <span>{date}</span>
              </section>
            ) : null;
          })
        ) : (
          <section className={styles.emptySection}>
            <EmptyRequestsIcon />
            <p>{t(`requests:noRequestsSection.no-requests`)}</p>
          </section>
        )}
      </Widget>
    </WidgetSkeleton>
  );
};

export default RequestsSidebar;
