import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { AppContext } from 'utils/AppContext';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import { CountUp } from 'components/CountUp/CountUp';
import { ReactComponent as EnergyIcon } from 'assets/icons/LoyaltyEnergyIcon.svg';
import Widget from 'components/Widget/Widget';
import IconText from 'components/IconText/IconText';
import { formatMoney } from 'utils/moneyUtils';

import styles from './MyEnergyPointsWidget.module.scss';

const MyEnergyPointsWidget = ({
  points,
  type = 'banner-small',
  distance = 'xs',
  page,
}) => {
  const { t } = useTranslation(['loyalty']);
  const { getLoyaltyPointsAnimated, setLoyaltyPointsAnimated } =
    React.useContext(AppContext);

  return (
    <Widget
      title={
        type === 'banner-small' && (
          <WidgetHeader title={t('loyalty:vibes-profile.header')} />
        )
      }
      distance={distance}
      widgetClass={styles.widget}
      containerClass={styles['widget-container']}
    >
      <div className={cn(styles['points-container'], styles[`type-${type}`])}>
        <IconText
          Icon={
            <EnergyIcon
              width={type === 'banner' ? 40 : 32}
              height={type === 'banner' ? 40 : 32}
            />
          }
          content={type === 'banner-small' ? '' : t('loyalty:energyLabel')}
          contentClass={styles.text}
          containerClass={styles.iconTextClass}
          gap={'s'}
        />
        <p>
          {getLoyaltyPointsAnimated(page) !== points ? (
            <CountUp
              startNumber={getLoyaltyPointsAnimated(page)}
              endNumber={points}
              onEnd={() => setLoyaltyPointsAnimated(page, points)}
            />
          ) : (
            formatMoney(points, false)
          )}
        </p>
      </div>
    </Widget>
  );
};

export default MyEnergyPointsWidget;
