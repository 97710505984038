import React from 'react';
import { formatMoneyWithCurrency } from 'utils/moneyUtils';

const EuroContainer = ({ content, containerClass, outterClass = '' }) => {
  return (
    <div className={outterClass}>
      <p className={containerClass}>{formatMoneyWithCurrency(content)}</p>
    </div>
  );
};

export default EuroContainer;
