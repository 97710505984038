import React from 'react';

import MeterLabel from 'components/Labels/MeterLabel';
import Widget from 'components/Widget/Widget';

import styles from './OverlaySection.module.scss';

const OverlaySection = ({ children, content, labelType, show = true }) => {
  return show ? (
    <Widget widgetClass={styles['meterNaming-widget']} distance="m">
      <MeterLabel
        labelClass={styles.labelContainer}
        size="s"
        type={labelType}
        prefix={content}
      />

      {children}
    </Widget>
  ) : null;
};

export default OverlaySection;
