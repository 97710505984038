import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from 'utils/moneyUtils';
import Widget from 'components/Widget/Widget';
import IconText from 'components/IconText/IconText';

import styles from './YellowsWidget.module.scss';

const YellowsWidget = ({ amount, distance = 'xs' }) => {
  const { t } = useTranslation(['loyalty']);

  return (
    amount > 0 && (
      <Widget
        distance={distance}
        widgetClass={styles.widget}
        containerClass={styles['widget-container-result']}
      >
        <div className={`${styles['points-container']}`}>
          <IconText
            content={t('bills:payment-flow.yellows')}
            contentClass={styles.text}
            containerClass={styles.iconTextClass}
          />
          <h3>{formatNumber(amount)}</h3>
        </div>
      </Widget>
    )
  );
};

export default YellowsWidget;
