export const meterStatusesElectricity = {
  new: 0,
  checked: 1,
  pending: 2,
  active: 3,
  terminated: 4,
  cancelled: 5,
  rejected: 6,
};

export const meterStatusesGas = {
  new: 1,
  pending: 3,
  active: 4,
  cancelled: 5,
  rejected: 6,
  terminated: 7,
};
