import PersonalDetails from 'components/OnboardingNewClient/Personal/PersonalDetails';
import EmergencyContact from 'components/OnboardingNewClient/Personal/EmergencyContact';
import CompanyDetails from 'components/OnboardingNewClient/Company/CompanyDetails';
import LegalRepresentative from 'components/OnboardingNewClient/Company/LegalRepresentative';
import PrimaryContact from 'components/OnboardingNewClient/Company/PrimaryContact';
import BillAddress from 'components/OnboardingNewClient/Shared/BillAddress';
import MeterAddress from 'components/OnboardingNewClient/Shared/MeterAddress';
import Ebill from 'components/OnboardingNewClient/Shared/Ebill';
import ContactPreferences from 'components/OnboardingNewClient/Shared/ContactPreferences';
import PersonalDataPreferences from 'components/OnboardingNewClient/Shared/PersonalDataPreferences';
import MeterDetails from 'components/OnboardingNewClient/Shared/MeterDetails';
import NightMeter from 'components/OnboardingNewClient/ElectricityFlow/NightMeter';
import UserCapacity from 'components/OnboardingNewClient/GasFlow/UserCapacity';
import NewGasSupply from 'components/OnboardingNewClient/GasFlow/NewGasSupply';
import NewGasSupplySuccess from 'components/OnboardingNewClient/GasFlow/NewGasSupplySuccess';
import CompanyLegalDetails from 'components/OnboardingNewClient/GasFlow/CompanyLegalDetails';
import Identification from 'components/OnboardingNewClient/Shared/Identification';
import Documents from 'components/OnboardingNewClient/Shared/Documents';
import ExistingElectricitySupply from 'components/OnboardingNewClient/CombinedFlow/ExistingElectricitySupply';

export const firstComponents = {
  customerDetails: PersonalDetails,
  emergencyContact: EmergencyContact,
  companyDetails: CompanyDetails,
  legalRepresentative: LegalRepresentative,
  primaryContact: PrimaryContact,
};

export const lastComponents = {
  contactPreferences: ContactPreferences,
  personalDataPreferences: PersonalDataPreferences,
  identification: Identification,
  documents: Documents,
};

export const getComponents = (defaultValues) => {
  const middleComponents = {
    meterDetails: MeterDetails,
    nightMeter: NightMeter,
    newGasSupply: NewGasSupply,
    existingElectricitySupply: ExistingElectricitySupply,
    newGasSupplySuccess: NewGasSupplySuccess,
    meterAddress: MeterAddress,
    userCapacity: UserCapacity,
    companyLegalDetails: CompanyLegalDetails,
    eBill: Ebill,
    billAddress: BillAddress,
  };
  return {
    ...firstComponents,
    ...middleComponents,
    ...lastComponents,
  };
};
