import Big from 'big.js';
import settlementStatuses from 'utils/product/enums/settlementStatuses';

import {
  addDays,
  isEqual,
  isWeekend,
  parseDate,
  startOfDay,
  formatDate,
} from 'utils/dateUtils';
import { dateFormats } from './config';

export const getSettlementsSum = (installments) => {
  return installments
    ? installments.reduce(
        (accumulator, current) =>
          accumulator.plus(new Big(current.InstallmentAmmount ?? 0)),
        new Big(0),
      )
    : 0;
};

export const createSettlementAnalysis = (settlement) => {
  const installmentsToReview = [
    {
      InstallmentDueDate: settlement.InAdvanceDate,
      InstallmentAmmount: settlement.InAdvanceAmmount,
      InstallmentNo: 0,
    },
    ...settlement.Installments.map((item) => {
      return { ...item, InstallmentDueDate: item.InstallmentDate };
    }),
  ];
  return installmentsToReview;
};

export const getInstallmentDate = (
  depositDate,
  installmentNo,
  frequencyInDays,
  holidays,
) => {
  let installmentDate = addDays(depositDate, frequencyInDays * installmentNo);

  while (!isDateAvailableForInstallment(installmentDate, holidays)) {
    installmentDate = addDays(installmentDate, 1);
  }
  return installmentDate;
};

export const createSettlementsRequest = ({
  totalAmount,
  installmentFrequency,
  inAdvanceAmount,
  inAdvanceDate,
  installmentsCount,
  holidays,
}) => {
  const depositDate = startOfDay(inAdvanceDate);
  const depositAmount = new Big(inAdvanceAmount);
  const amountAfterDeposit = new Big(totalAmount).minus(depositAmount);
  const installmentAmount = amountAfterDeposit
    .div(installmentsCount)
    .round(0, 0);

  const remainingAmount = amountAfterDeposit.mod(installmentsCount);
  const lastInstallmentAmount = installmentAmount.plus(remainingAmount);

  const installmentsArray = Array.from(
    Array(installmentsCount ?? 0).keys(),
  ).map((x) => x + 1);

  const installments = installmentsArray.map((installmentNo) => {
    return {
      InstallmentNo: installmentNo,
      InstallmentAmmount: (installmentNo === installmentsCount
        ? lastInstallmentAmount
        : installmentAmount
      ).toNumber(),
      InstallmentDate: formatDate(
        getInstallmentDate(
          depositDate,
          installmentNo,
          installmentFrequency,
          holidays,
        ),
        dateFormats.full,
      ),
    };
  });

  if (installments.find((x) => x.InstallmentAmmount <= 0)) {
    return null;
  }

  const proposedSettlement = {
    DepositAmmount: totalAmount,
    InAdvanceAmmount: depositAmount.toNumber(),
    InAdvanceDate: formatDate(depositDate, dateFormats.full),
    Installments: installments,
  };

  return proposedSettlement;
};

export const transformSettlement = (meterId, settlement) => {
  if (!settlement) return null;

  let installmentToPay = -1;
  const result = {
    ...settlement,
    Installments: settlement.Installments
      ? settlement.Installments.map((x, index) => {
          if (installmentToPay < 0 && x.Status === settlementStatuses.pending) {
            installmentToPay = index;
          }
          return {
            ...x,
            _key: `installment_${index}`,
            IsPayable: installmentToPay === index,
          };
        })
      : [],
  };

  return result;
};

export const isDateAvailableForInstallment = (date, holidays = []) => {
  const _isWeekend = isWeekend(date);
  if (_isWeekend) return false;

  const _date = startOfDay(parseDate(date));
  const isHoliday = holidays.some((x) => {
    const _parsedHoliday = parseDate(x, false, dateFormats.date);
    return isEqual(_date, _parsedHoliday);
  });
  return !isHoliday;
};
