import React from 'react';
import { useTranslation } from 'react-i18next';

import Accordion from 'components/Accordion/Accordion';

import styles from './FAQsAccordionContent.module.scss';

const FAQsAccordionContent = ({ energyType }) => {
  const { t, ready } = useTranslation(['FAQs']);
  const faqs = ready ? t(`${energyType}`, { returnObjects: true }) : [];

  return (
    <>
      {faqs.map((packet, index) => (
        <article key={index}>
          <h3 className={styles.sectionHeader}>{packet.sectionTitle}</h3>
          <section className={styles.container}>
            {t(`${energyType}.${index}.questionsAnswers`, {
              returnObjects: true,
            }).map((content, pacindex) => (
              <Accordion
                key={pacindex}
                summary={content.question}
                details={
                  <div
                    dangerouslySetInnerHTML={{ __html: content.answer }}
                  ></div>
                }
                detailsClasses={{ root: styles.details }}
              />
            ))}
          </section>
        </article>
      ))}
    </>
  );
};

export default FAQsAccordionContent;
