import React from 'react';

import WhatsNewWrapper from 'components/WhatsNew/WhatsNewWrapper';
import WhatsNewImage from 'components/WhatsNew/WhatsNewImage';
import WhatsNewInfo from 'components/WhatsNew/WhatsNewInfo';
import WhatsNewDots from 'components/WhatsNew/WhatsNewDots';
import whatsNewTypes from 'utils/product/enums/whatsNewTypes';

const WhatsNewB2B = () => (
  <WhatsNewWrapper>
    <WhatsNewImage type={whatsNewTypes.b2b} />
    <WhatsNewDots />
    <WhatsNewInfo type={whatsNewTypes.b2b} />
  </WhatsNewWrapper>
);
export default WhatsNewB2B;
