import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Widget from 'components/Widget/Widget';
import { ReactComponent as EnergyIcon } from 'assets/icons/LoyaltyEnergyIcon.svg';
import { CountUp } from 'components/CountUp/CountUp';
import IconText from 'components/IconText/IconText';
import { formatMoney } from 'utils/moneyUtils';

import styles from './EnergyPointsWidget.module.scss';

const EnergyPointsWidget = ({
  points,
  distance = 'xs',
  containerClass = '',
  animateLoyaltyPoints = true,
}) => {
  const { t } = useTranslation(['loyalty']);
  const loyaltyPoints = Math.floor(points);

  return (
    <Widget
      title=""
      distance={distance}
      widgetClass={styles.widget}
      containerClass={cn(
        styles['widget-container'],
        styles['widget-container-info'],
        containerClass
      )}
    >
      <div className={cn(styles['points-container'], styles[`type-info`])}>
        <IconText
          Icon={<EnergyIcon width={32} height={32} />}
          content={t('loyalty:energyLabel')}
          contentClass={styles.text}
          containerClass={styles.iconTextClass}
          gap="xs"
        />
        <p>
          {animateLoyaltyPoints ? (
            <CountUp endNumber={loyaltyPoints} />
          ) : (
            formatMoney(loyaltyPoints, false)
          )}
        </p>
      </div>
    </Widget>
  );
};

EnergyPointsWidget.propTypes = {
  points: PropTypes.number,
};

export default EnergyPointsWidget;
