import React from 'react';

import IconText from 'components/IconText/IconText';

import styles from './SidebarElement.module.scss';

const SidebarElement = ({ Icon, content, iconWeight, iconRight }) => {
  return (
    <div className={styles.container}>
      <IconText
        Icon={Icon}
        content={content}
        weight={iconWeight}
        iconRight={iconRight}
      />
    </div>
  );
};

export default SidebarElement;
