import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import EbillWidget from 'components/EbillWidget/EbillWidget';
import RecurringPaymentWidget from 'components/Payment/RecurringPaymentWidget';
import WidgetsSlider from 'pages/bills/WidgetsSlider';
import AlertWidget from 'components/AlertWidget/AlertWidget';
import {
  showEbillBanner,
  showRecurringBanner,
  showRecurringWarning,
  isStandingOrderCardExpiresSoon,
} from 'utils/product/invoiceUtils';
import useStandingOrderMeterInfo from 'data/useStandingOrderMeterInfo';
import { directDebitCardStatus } from 'utils/product/enums/directDebitCardStatus';

import styles from './BillDetailViewBanners.module.scss';

const BillDetailViewBanners = ({
  invoice,
  meter,
  loading,
  handleEbillChange,
}) => {
  const { t } = useTranslation('bills');
  const location = useLocation();
  const navigate = useNavigate();

  const { data: standingOrderCardDetails } = useStandingOrderMeterInfo(
    meter?.Id,
    meter?.Commodity,
    !meter?.Id,
  );

  const cardExpired =
    !standingOrderCardDetails?.Credit &&
    !standingOrderCardDetails?.Bank &&
    standingOrderCardDetails?.StatusCode === directDebitCardStatus.expired;

  const inactiveRecurring =
    !standingOrderCardDetails?.Credit &&
    !standingOrderCardDetails?.Bank &&
    standingOrderCardDetails?.StatusCode === directDebitCardStatus.inactive;

  const cardExpiresSoon = isStandingOrderCardExpiresSoon(
    standingOrderCardDetails?.Expirymonth,
    standingOrderCardDetails?.Expiryyear,
  );

  const displayEbillBanner = showEbillBanner({
    loading,
    meter,
    invoice,
    location,
  });

  const displayRecurringBanner = showRecurringBanner({
    loading,
    meter,
    invoice,
    location,
    inactiveRecurring: inactiveRecurring || cardExpired,
  });

  const displayRecurringWarning = showRecurringWarning({
    loading,
    meter,
    cardExpiredOrExpiresSoon: cardExpiresSoon || cardExpired,
  });

  const handleGoToCardEdit = () => {
    navigate('/management/direct-debit-payment/edit-card', {
      state: { meter, previousPath: location?.pathname },
    });
  };

  return (
    <>
      {displayRecurringWarning && (
        <AlertWidget
          header={
            cardExpired
              ? t('recurring-payment.expired-header')
              : t('recurring-payment.expires-soon-header')
          }
          message={
            cardExpired
              ? t('recurring-payment.expired-message')
              : t('recurring-payment.expires-soon-message')
          }
          onAlertClick={handleGoToCardEdit}
          showRightArrow
        />
      )}
      {(displayEbillBanner || displayRecurringBanner) && (
        <WidgetsSlider
          containerClass={styles.sliderContainer}
          sliderClass={styles.bannersContainer}
        >
          {displayEbillBanner && (
            <EbillWidget meter={meter} handleEbillChange={handleEbillChange} />
          )}
          {displayRecurringBanner && <RecurringPaymentWidget meter={meter} />}
        </WidgetsSlider>
      )}
    </>
  );
};

export default BillDetailViewBanners;
