import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/dateUtils';

export const Coupon = ({ coupon, ...props }) => {
  const { t } = useTranslation(['translation', 'offers']);
  const maxCouponCodeLength = 10;

  const trimmedCouponCode = `${coupon?.code?.substring(
    0,
    maxCouponCodeLength,
  )}${coupon?.code?.length > maxCouponCodeLength ? '...' : ''}`;

  return (
    coupon && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={253}
        height={116}
        {...props}
      >
        <defs>
          <radialGradient
            id="a"
            cx="50%"
            cy="50%"
            r="146.115%"
            fx="50%"
            fy="50%"
            gradientTransform="matrix(0 1 -1.7329 0 1.366 0)"
          >
            <stop offset="0%" stopColor="#5824A8" />
            <stop offset="100%" stopColor="#2C1158" />
          </radialGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
          <path
            fill="url(#a)"
            stroke="#FFF"
            strokeOpacity={0.499}
            strokeWidth={2}
            d="M241-1a8.972 8.972 0 0 1 6.364 2.636A8.972 8.972 0 0 1 250 8v33.332c-2.42.773-4.488 2.8-5.921 5.542-1.33 2.543-2.111 5.704-2.111 9.126 0 3.422.781 6.583 2.11 9.126 1.434 2.742 3.501 4.769 5.923 5.542L250 104a8.972 8.972 0 0 1-2.636 6.364A8.972 8.972 0 0 1 241 113H8a8.972 8.972 0 0 1-6.364-2.636A8.972 8.972 0 0 1-1 104V70.668c2.421-.774 4.488-2.801 5.922-5.542 1.329-2.544 2.11-5.704 2.11-9.126 0-3.422-.781-6.582-2.11-9.126-1.434-2.741-3.5-4.768-5.922-5.542V8a8.972 8.972 0 0 1 2.636-6.364A8.972 8.972 0 0 1 8-1Z"
            transform="translate(2 2)"
          />
          {!!coupon.expiresAt && (
            <>
              <text
                fill="#FFF"
                fontFamily="Manrope-Regular, Manrope"
                fontSize={12}
                transform="translate(71 66)"
              >
                <tspan x={18} y={13}>
                  {t(`offers:acquisition-flow.coupon-active`)}{' '}
                  {formatDate(coupon.expiresAt)}
                </tspan>
              </text>

              <circle
                cx={5}
                cy={5}
                r={5}
                fill="#0DB916"
                transform="translate(71 69)"
              />
            </>
          )}
          <text
            fill="#FFD953"
            fontFamily="Manrope-Bold, Manrope"
            fontSize={20}
            fontWeight="bold"
            transform="translate(2 2)"
          >
            <tspan x={83} y={55}>
              {trimmedCouponCode}
            </tspan>
          </text>
          <path
            stroke="#FFF"
            strokeDasharray="3,6"
            strokeLinecap="square"
            strokeOpacity={0.5}
            strokeWidth={2}
            d="M55 6.5v103"
          />
        </g>
      </svg>
    )
  );
};
