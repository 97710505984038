import accountCategoryTypes from 'utils/product/enums/accountCategoryTypes';
import { requestTypes } from 'utils/product/enums/requests';

const getPersonalDataDefaultValues = (account) => ({
  fullName: `${account.FirstName} ${account.LastName}`,
  address: `${account.Address ?? ''} ${account.AddressNo ?? ''} ${
    account.City ?? ''
  } ${account.PostalCode ?? ''} ${account.Prefecture ?? ''}`,
  taxId: account.TaxNo,
  idPassport: account.IdOrPassportNo ?? '',
  placeOfIssue: account.PlaceOfIssue ?? '',
  taxOffice: account.TaxOffice ?? '',
  mobile: account.Mobile ?? '',
  phone: account.Phone ?? '',
  email: account.Email ?? '',
  meterId: '',
  meterAddress: '',
});

const getBusinessDataDefaultValues = (account) => ({
  companyName: `${account.CompanyName ?? ''}`,
  companyAddress: `${account.Address ?? ''} ${account.AddressNo ?? ''} ${
    account.City ?? ''
  } ${account.PostalCode ?? ''} ${account.Prefecture ?? ''}`,
  taxId: account.TaxNo,
  idPassport: account.IdOrPassportNo ?? '',
  placeOfIssue: account.PlaceOfIssue ?? '',
  taxOffice: account.TaxOffice ?? '',
  mobile: account.Mobile ?? '',
  phone: account.Phone ?? '',
  email: account.Email ?? '',
  legalContact: account.LegalContact ?? '',
  meterId: '',
  meterAddress: '',
});

const getDefaultValues = (type, accountType, account) => {
  if (!account) return {};

  if (type === requestTypes.personalDataChange) {
    if (accountType === accountCategoryTypes.home) {
      return {
        ...getPersonalDataDefaultValues(account),
        contactFullName: '',
        contactPhone: '',
        contactEmail: '',
      };
    }
    return {
      ...getBusinessDataDefaultValues(account),
    };
  }
  return accountType === accountCategoryTypes.home
    ? { ...getPersonalDataDefaultValues(account) }
    : { ...getBusinessDataDefaultValues(account) };
};

export default getDefaultValues;
