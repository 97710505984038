import shopEnergyTypes from './enums/shopEnergyTypes';

const cmsTransformGuaranteeProgram = (program) => {
  const { guaranteeSectionTitle, choices: guaranteeProgramChoices } = program;

  const choices = guaranteeProgramChoices?.map((choice) => ({
    title: choice.choiceTitle,
    value: choice.value,
    isSelected: choice.isSelected,
  }));

  return {
    title: guaranteeSectionTitle,
    choices,
  };
};

const cmsTransformBasicSection = (program) => {
  const { basicSectionTitle, basicSectionDescription } = program;

  return {
    title: basicSectionTitle,
    description: basicSectionDescription,
  };
};

const cmsTransformCost = (program) => {
  const { costSectionTitle, charges, notes } = program;

  return { title: costSectionTitle, charges, notes };
};

const cmsTransformPrivileges = (program) => {
  const { privilegesSectionTitle, privileges } = program;

  return { title: privilegesSectionTitle, privileges };
};

const cmsTransformActions = (program) => {
  const { buy, change, interested } = program;

  return { buy, change, interested };
};

const cmsTransformMoreInfo = (program) => {
  const { moreInfoDescription } = program;

  return { description: moreInfoDescription };
};

const cmsTransformsCalculator = (program) => {
  const { calculatorDescription } = program;

  return { description: calculatorDescription };
};

const cmsTransformProgramSections = (program) => {
  const { isGuaranteeProgram, energyType, charges } = program;

  const packageHasCharges =
    energyType !== shopEnergyTypes.service && charges?.length > 0;

  const serviceHasCharges =
    energyType === shopEnergyTypes.service && charges?.length > 0;

  const sections = {
    basic: cmsTransformBasicSection(program),
    ...(packageHasCharges && { cost: cmsTransformCost(program) }),
    additionalPriviledges: cmsTransformPrivileges(program),
    moreInfo: cmsTransformMoreInfo(program),
    ...(isGuaranteeProgram && {
      guaranteeProgram: cmsTransformGuaranteeProgram(program),
    }),
    actions: cmsTransformActions(program),
    ...(serviceHasCharges && {
      servicesCost: cmsTransformCost(program),
    }),
  };

  return sections;
};

export const cmsTransformation = (data) => {
  const shopContent = data?.data?.map((program) => program?.content) || [];

  const includedInvoiceLabel = data?.included;

  if (!shopContent || !shopContent.length) return [];

  const transformedShopContent = shopContent.reduce((accumulator, program) => {
    const {
      overviewFirstLine,
      overviewSecondLine,
      overviewThirdLine,
      smallImage,
      largeImage,
      hasCalculator,
      isEna,
      invoiceLabel,
      agreedPowerCondition,
      agreedPowerValue,
      ...rest
    } = program || {};

    const invoiceLabelContent =
      includedInvoiceLabel?.[invoiceLabel?.schema]?.[invoiceLabel?.id]?.content;

    const hasAgreedPower = agreedPowerCondition && agreedPowerValue;

    accumulator.push({
      smallImageUrl: smallImage?.url,
      imageUrl: largeImage?.url,
      info1: overviewFirstLine,
      info2: overviewSecondLine,
      info3: overviewThirdLine,
      sections: cmsTransformProgramSections(program),
      ...(hasCalculator && {
        calculator: cmsTransformsCalculator(program),
      }),
      ...(hasAgreedPower && {
        agreedPower: {
          condition: agreedPowerCondition,
          value: agreedPowerValue,
        },
      }),
      ...(isEna && {
        skipCss: true,
      }),
      isEna,
      label: invoiceLabelContent,
      ...rest,
    });

    return accumulator;
  }, []);

  return transformedShopContent;
};
