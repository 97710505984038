import React from 'react';

import { formatDate } from 'utils/dateUtils';
import { dateFormats } from 'utils/product/config';

import styles from './PeriodSpan.module.scss';

const PeriodSpan = ({ periodStart, periodEnd, spanClass = 'span' }) => {
  return periodStart ? (
    <>
      <span className={styles[spanClass]}>
        <span>
          {periodStart ? formatDate(periodStart, dateFormats.dayFullMonth) : ''}
        </span>
        <span>
          &nbsp;
          {periodEnd
            ? `- ${formatDate(periodEnd, dateFormats.dayFullMonth)}`
            : ''}
        </span>
      </span>
    </>
  ) : null;
};

export default PeriodSpan;
