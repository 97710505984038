import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useError from 'hooks/useError';
import useUniqueFlow from 'hooks/useUniqueFlow';
import { useAuthUser } from 'utils/AuthUser';
import {
  trackGuestEvent,
  trackLoginEvent,
} from 'utils/product/tracking/events';
import { trackRegistrationFbEvent } from 'utils/product/tracking/fbEvents';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import { EmailInput } from 'components/Inputs/EmailInput';
import { PasswordInput } from 'components/Inputs/PasswordInput';
import { LocalisedErrorBox } from 'components/MessageBox/MessageBox';

import styles from './LoginWidgetContent.module.scss';

const LoginWidgetContent = () => {
  const { t } = useTranslation(['onboarding']);
  const baseName = 'onboarding:loginWidgetContent';
  const generalName = 'onboarding:general';
  const { login } = useAuthUser();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loginLoading, setLoginLoading] = useState(false);

  const { error, handleError, clearError } = useError();
  const navigate = useNavigate();
  const navigation = useNavigation();

  const clearLocationHistoryState = useCallback(() => {
    const state = { ...navigation.state };
    delete state.error;
    navigate(navigation.location, { state });
  }, [navigation.location, navigation.state, navigate]);

  const { incrementStep, setFlowActive } = useUniqueFlow();

  const onSubmit = async ({ loginEmail, loginPassword }) => {
    trackGuestEvent('loginscreen_login_selection');
    trackRegistrationFbEvent();
    setLoginLoading(true);
    setFlowActive(false);
    login({
      email: loginEmail,
      password: loginPassword,
    })
      .then(() => {
        clearError();
        setFlowActive(true);
        incrementStep();
        trackLoginEvent('login_success');
      })
      .catch((error) => {
        trackLoginEvent('login_fail');
        handleError(error, 'login');
        setLoginLoading(false);
      });
  };

  useEffect(() => {
    const { error } = navigation.state || {};
    if (error !== undefined) {
      handleError(error, 'confirmUser');
      clearLocationHistoryState();
    }
  }, [clearLocationHistoryState, navigation.state, handleError]);

  return (
    <>
      <h1 className={styles.header}>{t(`${baseName}.welcome`)}</h1>

      <LocalisedErrorBox
        open={error !== null}
        closeBox={clearError}
        {...(error || {})}
        distanceTop=""
      />
      <form
        className={styles.container}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <EmailInput
          register={register}
          errors={errors}
          label={t(`${generalName}.email`)}
          name="loginEmail"
        />
        <PasswordInput
          name="loginPassword"
          register={register}
          errors={errors}
          label={t(`${generalName}.password`)}
        />
        <section className={styles.submit}>
          <PrimaryButtonLoader
            loading={loginLoading}
            disabled={loginLoading}
            type="submit"
          >
            {t(`${baseName}.login`)}
          </PrimaryButtonLoader>
        </section>
      </form>
      <section className={styles.section}>
        <Link
          className={styles.link}
          to="/forgot-password"
          onClick={() => {
            trackLoginEvent('login_forgot_password');
          }}
        >
          {t(`${baseName}.forgot-password`)}
        </Link>
        <Link
          to="/create-user"
          className={styles.bottomLink}
          onClick={() => {
            trackGuestEvent('loginscreen_create_user');
          }}
        >
          {t(`${baseName}.create-user`)}
        </Link>
      </section>
    </>
  );
};

export default LoginWidgetContent;
