import React from 'react';
import { formatNumber } from 'utils/moneyUtils';
import { getNumberParts } from 'utils/numberUtils';

import styles from './WidgetPrice.module.scss';

const WidgetPrice = ({ number, numberClassname = 'number-big-b', type }) => {
  const { integerPart, decimalPart } = getNumberParts(number);
  return (
    <section className={styles.container}>
      <div
        className={
          type ? styles[`number-big-${type}`] : styles[numberClassname]
        }
      >
        {formatNumber(integerPart)}
      </div>
      <div className={styles['number-small']}>,{decimalPart}€</div>
    </section>
  );
};

export default WidgetPrice;
