import React from 'react';

import MeterLabel from './MeterLabel';

const MeterLabelWrapper = ({ meter, ...props }) => {
  const { FullAddress, IsLargeAccount } = meter || {};

  return (
    <MeterLabel
      suffix={IsLargeAccount && FullAddress ? `- ${FullAddress}` : ''}
      {...props}
    />
  );
};

export default MeterLabelWrapper;
