import React from 'react';
import { useTranslation } from 'react-i18next';

import useDialog from 'hooks/useDialog';
import paymentTypes from 'utils/product/enums/paymentTypes';
import { trackHomeCustomerSupportEvent } from 'utils/product/tracking/events';

import SidebarLinkElement from 'components/SidebarElements/SidebarLinkElement';
import SidebarButtonElement from 'components/SidebarElements/SidebarButtonElement';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import { ReactComponent as Shop } from 'assets/icons/ShopsIcon.svg';
import { ReactComponent as Faqs } from 'assets/icons/FaqsIcon.svg';
import { ReactComponent as Bill } from 'assets/icons/BillIcon.svg';
import SidebarLinkMenu from 'components/SidebarElements/SidebarLinkMenu';
import PaymentWidget from 'components/Payment/PaymentWidget';
import WidgetLine from 'components/WidgetLine/WidgetLine';

const CustomerCareWidget = () => {
  const { t } = useTranslation(['homepage']);

  const paymentDialog = useDialog();

  return (
    <>
      <SidebarLinkMenu
        title={<WidgetHeader title={t('homepage:customer-care.title')} />}
      >
        <SidebarLinkElement
          target="/faqs"
          Icon={<Faqs width="24" />}
          content={t('homepage:FAQs.title')}
          onClick={() => trackHomeCustomerSupportEvent('quick_actions_FAQ')}
        />
        <WidgetLine />
        <SidebarButtonElement
          Icon={<Bill width="24" />}
          content={t('homepage:third-party-bill.title')}
          onClick={() => {
            paymentDialog.show();
            trackHomeCustomerSupportEvent('quick_actions_payment_3rd');
          }}
        />
        <WidgetLine />
        <SidebarLinkElement
          target="/stores"
          Icon={<Shop width="24" />}
          content={t('homepage:physical-stores.title')}
          onClick={() => trackHomeCustomerSupportEvent('quick_actions_shops')}
        />
      </SidebarLinkMenu>
      <PaymentWidget
        open={paymentDialog.isOpen}
        closeDialog={paymentDialog.close}
        openDialog={paymentDialog.show}
        paymentType={paymentTypes.thirdParty}
      />
    </>
  );
};
export default CustomerCareWidget;
