import React from 'react';
import CountUpModule from 'react-countup';

export const CountUp = ({
  startNumber = 0,
  endNumber,
  separator = '.',
  duration = 2,
  ...rest
}) => {
  return (
    <CountUpModule
      start={startNumber}
      end={endNumber}
      separator={separator}
      duration={duration}
      preserveValue
      {...rest}
    />
  );
};
