import React from 'react';

import Loader from 'components/Skeletons/Loader';
import ShopCard from 'components/Shop/Overview/ShopCard';
import { ReactComponent as LoyaltyCreditIcon } from 'assets/icons/LoyaltyCreditIcon.svg';
import { useAuthUser } from 'utils/AuthUser';

import styles from './ShopCategory.module.scss';

const ShopCategory = ({
  title,
  programs,
  loading,
  clickHandler,
  loyaltyReward,
}) => {
  const { isLoyaltyCustomer } = useAuthUser();
  return (
    <section className={styles.categoryContainer}>
      <Loader
        show={loading}
        variant="text"
        height={40}
        width={190}
        className={styles.textLoading}
      >
        {programs && programs.length > 0 && (
          <div className={styles.titleAndIcon}>
            <h3>{title}</h3>
            {isLoyaltyCustomer && loyaltyReward && <LoyaltyCreditIcon />}
          </div>
        )}
      </Loader>

      <article className={styles['categoryContainer-items']}>
        <Loader
          show={loading}
          height={328}
          width={344}
          className={styles.cardLoading}
        >
          {programs.map((item) => (
            <ShopCard
              program={item}
              key={item.packageId || item.title}
              clickHandler={clickHandler}
            />
          ))}
        </Loader>
        <Loader
          show={loading}
          height={328}
          width={344}
          className={styles['cardLoading--2']}
        />
        <Loader
          show={loading}
          height={328}
          width={344}
          className={styles['cardLoading--3']}
        />
      </article>
    </section>
  );
};

export default ShopCategory;
