import React from 'react';
import { useTranslation } from 'react-i18next';

import { PromptDialog } from 'components/PromptDialogs/PromptDialog';

import styles from './PowerDisconnectDialog.module.scss';

const PowerDisconnectDialog = ({ open = true, closeDialog, onSelect }) => {
  const { t } = useTranslation(['requests']);

  const handleClick = (value) => {
    onSelect(value);
  };
  return (
    <PromptDialog
      open={open}
      closeDialog={closeDialog}
      header={t(`requests:moving.disconnect-power-title`)}
      primaryActionText={t(`requests:moving.disconnect-yes`)}
      primaryActionHandler={(event) => handleClick(true)}
      showSecondaryAction={true}
      secondaryActionText={t(`requests:moving.disconnect-no`)}
      secondaryActionHandler={(event) => handleClick(false)}
      dialogClass={styles.dialog}
      actionsClass={styles.actions}
    />
  );
};

export default PowerDisconnectDialog;
