import React from 'react';

import { ReactComponent as Trophy } from 'assets/icons/SimpleTrophyIcon.svg';
import ContestWidget from './ContestWidget';
import EmptyStateWidget from 'components/Widget/EmptyStateWidget';

import styles from './ContestsTab.module.scss';

const ContestsTab = ({ data, emptyStateText }) => {
  return (
    <div className={styles.tabContainer}>
      {data?.map((contest) => {
        return (
          <ContestWidget
            key={contest?.id}
            imageUrl={contest?.image}
            url={contest?.slug}
            title={contest?.title}
            status={contest?.participationStatus}
            endDate={contest?.endDate}
            expirationLabelColor={contest?.expirationLabelColor}
            participations={contest?.participationsCount}
          />
        );
      })}
      {data?.length === 0 && (
        <EmptyStateWidget
          icon={<Trophy width="72" height="72" />}
          text={emptyStateText}
          isFullWidth
        />
      )}
    </div>
  );
};

export default ContestsTab;
