import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Big from 'big.js';

import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import ParticipationSlider from './ParticipationSlider';
import ParticipationConfirm from './ParticipationConfirm';
import { ErrorDialog } from 'components/PromptDialogs/PromptDialog';
import { formatMoney } from 'utils/moneyUtils';
import useDialog from 'hooks/useDialog';

import styles from './ContestMultipleParticipationsSliderDialog.module.scss';

const ContestMultipleParticipationsSliderDialog = ({
  open,
  closeDialog = () => {},
  availableLoyaltyPoints,
  hasInsufficientPoints,
  participationsCount,
  contestInfo,
  onSuccess,
  onError,
}) => {
  const { t } = useTranslation(['translation', 'offers']);
  const confirmationDialog = useDialog();
  const errorDialog = useDialog();
  const sliderSteps = 5;

  const {
    redeemPoints,
    participationsPerParticipantLimit: participationsLimit,
  } = contestInfo;

  const [selectedPoints, setSelectedPoints] = useState(redeemPoints);
  const onSliderChange = useCallback((value) => {
    setSelectedPoints(value);
  }, []);

  const remainingParticipations = participationsLimit
    ? participationsLimit - participationsCount
    : participationsLimit;

  const numberOfParticipations = new Big(selectedPoints)
    .div(redeemPoints)
    .toNumber();

  return (
    <>
      <DialogOverlay
        open={open}
        closeDialog={closeDialog}
        dialogHeader={t(`offers:contest-participation.title`)}
        alignActions="right"
        dialogActions={
          <section className={styles['dialog-actions']}>
            <span className={styles['dialog-actions-text']}>
              <Trans
                i18nKey={'offers:contest-participation.redeem'}
                values={{
                  count: numberOfParticipations,
                }}
                components={{
                  bold: <b />,
                }}
              />
            </span>
            <PrimaryButtonLoader
              onClick={() => {
                closeDialog();
                confirmationDialog.show();
              }}
            >
              {t(`offers:contest-participation.apply`)}
            </PrimaryButtonLoader>
          </section>
        }
        dialogClass={styles.dialog}
        titleClass={styles.title}
        contentClass={styles.dialogContent}
      >
        <section className={styles['available-points']}>
          <Trans
            i18nKey={'offers:contest-participation.message'}
            values={{
              points: formatMoney(availableLoyaltyPoints, false),
            }}
            components={{
              bold: <b />,
            }}
          />
          {hasInsufficientPoints && (
            <p className={styles['history-points']}>
              {t(`offers:contest-participation.historyPoints`)}
            </p>
          )}
          {!!remainingParticipations &&
            remainingParticipations < sliderSteps && (
              <p className={styles['remaining-participations']}>
                <Trans
                  i18nKey={
                    'offers:contest-participation.remainingParticipations'
                  }
                  values={{
                    count: remainingParticipations,
                  }}
                />
              </p>
            )}
        </section>
        <ParticipationSlider
          participationPoints={redeemPoints}
          loyaltyPoints={availableLoyaltyPoints}
          remainingParticipations={remainingParticipations}
          selectedPoints={selectedPoints}
          onChange={onSliderChange}
        />
      </DialogOverlay>
      {confirmationDialog.isOpen && (
        <ParticipationConfirm
          contest={contestInfo}
          open={confirmationDialog.isOpen}
          closeDialog={confirmationDialog.close}
          pointsToRedeem={selectedPoints}
          participations={numberOfParticipations}
          onSuccess={onSuccess}
          onError={onError}
        />
      )}
      <ErrorDialog
        open={errorDialog.isOpen}
        closeDialog={errorDialog.close}
        header={t(`offers:acquisition-flow.points-error-title`)}
        message={t(`offers:acquisition-flow.points-error-message`)}
      />
    </>
  );
};

export default ContestMultipleParticipationsSliderDialog;
