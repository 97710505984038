import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import atLeastOneCategorySelected from 'utils/product/requests/atLeastOneCategorySelected';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from 'components/Checkbox/Checkbox';

import styles from './BillingCategories.module.scss';
import { FormHelperText } from '@material-ui/core';

const BillingCategories = () => {
  const { t } = useTranslation(['requests']);
  const {
    register,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
  } = useFormContext();

  const [billingGroup, setBillingGroup] = useState([
    { key: 'consumption', value: false },
    { key: 'powerBilling', value: false },
    { key: 'energyBilling', value: false },
    { key: 'againstInvoice', value: false },
  ]);
  const [othersGroup, setOthersGroup] = useState([
    { key: 'squareMeters', value: false },
    { key: 'areaPrice', value: false },
    { key: 'oldness', value: false },
    { key: 'tapFactor', value: false },
    { key: 'daysFactor', value: false },
  ]);
  const [state, setState] = React.useState({
    consumption: false,
    powerBilling: false,
    energyBilling: false,
    againstInvoice: false,
    squareMeters: false,
    areaPrice: false,
    oldness: false,
    tapFactor: false,
    daysFactor: false,
  });

  const onBillingChange = (event, index) => {
    const copy = [...billingGroup];
    copy.splice(index, 1, {
      key: event.target.name,
      value: event.target.checked,
    });
    setBillingGroup(copy);
    setValue(event.target.name, event.target.checked);

    handleChange(event);
  };

  const onOthersChange = (event, index) => {
    const copy = [...othersGroup];
    copy.splice(index, 1, {
      key: event.target.name,
      value: event.target.checked,
    });
    setOthersGroup(copy);

    setValue(event.target.name, event.target.checked);
    handleChange(event);
  };

  const handleChange = (event) => {
    const newState = { ...state, [event.target.name]: event.target.checked };
    setState(newState);

    if (atLeastOneCategorySelected(newState)) clearErrors('atLeastOne');
    else
      setError('atLeastOne', {
        type: 'manual',
        message: t(`requests:billing.at-least-one`),
      });
  };

  return (
    <section className={styles.container}>
      <FormControl>
        <FormGroup>
          <FormLabel className={styles.title}>
            <h3>{t(`requests:billing.title-1`)}</h3>
          </FormLabel>
          {billingGroup.map(({ key, value }, index) => (
            <Checkbox
              key={key}
              value={value}
              changeHandler={(e) => onBillingChange(e, index)}
              name={key}
              label={t(`requests:billing.${key}`)}
              register={register}
              labelClass={styles.checkbox}
            />
          ))}
          <FormLabel className={styles.title2}>
            <h3>{t(`requests:billing.title-2`)}</h3>
          </FormLabel>
          {othersGroup.map(({ key, value }, index) => (
            <Checkbox
              key={key}
              value={value}
              changeHandler={(e) => onOthersChange(e, index)}
              name={key}
              label={t(`requests:billing.${key}`)}
              register={register}
              labelClass={styles.checkbox}
            />
          ))}
        </FormGroup>

        {!!errors['atLeastOne'] && (
          <FormHelperText className={styles.error}>
            {t(`requests:billing.at-least-one`)}
          </FormHelperText>
        )}
      </FormControl>
    </section>
  );
};

export default BillingCategories;
