import React from 'react';
import { useTranslation } from 'react-i18next';

import { RegisterOwnReadingsLayout } from 'components/Consumptions/HomeStates/ConsumptionLayouts';
import {
  ConsumptionAnalysisButton,
  EnergyReportsButton,
} from 'components/Consumptions/Home/ConsumptionInfoElements';

const StateMediumVoltage = ({ meter }) => {
  const { t } = useTranslation(['translation', 'consumptions']);

  return (
    <RegisterOwnReadingsLayout>
      {{
        message: <p>{t('consumptions:b2b.energy-reports-message')}</p>,
        secondaryButton: <ConsumptionAnalysisButton meterNo={meter.MeterNo} />,
        primaryButton: <EnergyReportsButton meter={meter} />,
      }}
    </RegisterOwnReadingsLayout>
  );
};

export default StateMediumVoltage;
