import React from 'react';

import settlementStatuses from 'utils/product/enums/settlementStatuses';

import { ReactComponent as SuccessIcon } from 'assets/icons/CheckGreen.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/ErrorIcon.svg';

const InstallmentStatusIcon = ({ installment }) => {
  if (installment.Status === settlementStatuses.completed)
    return <SuccessIcon width={32} height={32} />;
  if (installment.Status === settlementStatuses.broken)
    return <ErrorIcon width={32} height={32} alt="broken" />;
  return null;
};

export default InstallmentStatusIcon;
