import React from 'react';
import CmsHtmlField from 'components/CmsHtmlField/CmsHtmlField';

import styles from './ProgramExtras.module.scss';

const ProgramExtras = ({ moreInfo, clickHandler }) => {
  return (
    <section className={styles.container}>
      {moreInfo.title && <h2 className={styles.title}>{moreInfo.title}</h2>}
      {moreInfo.description && (
        <CmsHtmlField
          value={moreInfo.description}
          onClick={() => (clickHandler ? clickHandler() : null)}
        />
      )}
    </section>
  );
};
export default ProgramExtras;
