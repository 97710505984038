import React from 'react';

import useDialog from 'hooks/useDialog';
import useCounter from 'hooks/useCounter';
import { trackOnboardingEvent } from 'utils/product/tracking/events';
import useUniqueOnboardingFlow from 'hooks/useUniqueOnboardingFlow';
import pages from 'utils/product/enums/pages';
import WhatsNewDialogOverlay from 'components/WhatsNew/WhatsNewDialogOverlay';
import WhatsNewImage from 'components/WhatsNew/WhatsNewImage';
import WhatsNewInfo from 'components/WhatsNew/WhatsNewInfo';
import WhatsNewDots from 'components/WhatsNew/WhatsNewDots';
import AppDownloadButtons from 'components/AppDownload/AppDownloadButtons';

import { whatsNewOnboardingSlidesCount } from 'utils/product/config';
import whatsNewTypes from 'utils/product/enums/whatsNewTypes';
import shopEnergyTypes from 'utils/product/enums/shopEnergyTypes';

import styles from './WhatsNewNewClient.module.scss';

const WhatsNewNewClient = () => {
  const { goForward, nextRouteData } = useUniqueOnboardingFlow();

  const {
    number: currentSlideIndex,
    increaseNumber: nextIndex,
    checkIfNumberIsMax: isLastSlideShown,
  } = useCounter(0, whatsNewOnboardingSlidesCount - 1);

  const dialog = useDialog(true);

  const handleClick = () => {
    trackOnboardingEvent('onboarding_whatsnew_continue', currentSlideIndex + 1);
    if (isLastSlideShown()) {
      dialog.close();
      goForward({ nextRouteData, energyType: shopEnergyTypes.electricity });
      return;
    }
    nextIndex();
  };

  return (
    <section>
      <WhatsNewDialogOverlay
        open={dialog.isOpen}
        closeDialog={dialog.close}
        handleClick={handleClick}
        dialogClass={styles.dialog}
        contentClass={styles.dialogContent}
        actionsClass={styles.dialogActions}
        dialogFooter={
          currentSlideIndex === 0 ? (
            <AppDownloadButtons
              containerClass={styles.footer}
              source={pages.onboarding}
            />
          ) : null
        }
      >
        <WhatsNewImage
          pageIndex={currentSlideIndex}
          type={whatsNewTypes.newClient}
        />
        <WhatsNewDots
          dotsCount={whatsNewOnboardingSlidesCount}
          pageIndex={currentSlideIndex}
        />
        <WhatsNewInfo
          pageIndex={currentSlideIndex}
          type={whatsNewTypes.newClient}
        />
      </WhatsNewDialogOverlay>
    </section>
  );
};

export default WhatsNewNewClient;
