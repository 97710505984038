import React from 'react';

import LoginWidget from 'pages/onboarding/LoginWidget';
import OnboardingLayout from 'layouts/OnboardingLayout';
import ChangePasswordContent from './ChangePasswordContent';

import styles from './ChangePassword.module.scss';

const ChangePassword = () => {
  return (
    <OnboardingLayout>
      <LoginWidget containerClass={styles.widgetContainer}>
        <ChangePasswordContent />
      </LoginWidget>
    </OnboardingLayout>
  );
};

export default ChangePassword;
