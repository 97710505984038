import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput } from 'components/Inputs/TextInput';
import { TelephoneInput } from 'components/Inputs/TelephoneInput';
import { TaxpayerIdInput } from 'components/Inputs/TaxpayerIdInput';
import PostalCodeInput from 'components/Inputs/PostalCodeInput';
import { LocalisedErrorBox } from 'components/MessageBox/MessageBox';
import styles from './NewGasSupply.module.scss';

const NewGasSupply = ({
  defaultValues = {},
  onUpdate = () => {},
  gasRequestError,
  clearGasRequestError,
}) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { t } = useTranslation(['newClient']);

  const handleChange = ({ postalCodeId, area }) => {
    setValue(`newGasSupply.PostalCodeId`, postalCodeId);
    setValue(`newGasSupply.PostalCodeArea`, area);
    onUpdate({
      newGasSupply: {
        PostalCodeId: postalCodeId,
        PostalCodeArea: area,
      },
    });
  };
  return (
    <>
      <LocalisedErrorBox
        open={gasRequestError !== null}
        closeBox={clearGasRequestError}
        {...(gasRequestError || {})}
        distanceTop=""
      />
      <article className={styles.container}>
        <h3 className={styles.header}>
          {t(`newClient:customerDetails.header`)}
        </h3>
        <TextInput
          register={register}
          errors={errors}
          name="newGasSupply.FirstName"
          label={t(`newClient:sharedDetails.firstName`)}
          defaultValue={defaultValues?.newGasSupply?.FirstName}
          required={true}
        />
        <TextInput
          register={register}
          errors={errors}
          name="newGasSupply.LastName"
          label={t(`newClient:sharedDetails.lastName`)}
          defaultValue={defaultValues?.newGasSupply?.LastName}
          required={true}
        />
        <TaxpayerIdInput
          register={register}
          errors={errors}
          name="newGasSupply.TaxNo"
          label={t(`newClient:sharedDetails.taxNo`)}
          defaultValue={defaultValues?.newGasSupply?.TaxNo}
          required={true}
        />
        <TelephoneInput
          register={register}
          errors={errors}
          name="newGasSupply.Phone"
          label={t(`newClient:newGasSupply.contact-number`)}
          defaultValue={defaultValues?.newGasSupply?.Phone}
          required={true}
        />
      </article>
      <article className={styles.containerBottom}>
        <h3 className={styles.header}>{t(`newClient:newGasSupply.address`)}</h3>
        <TextInput
          register={register}
          errors={errors}
          name="newGasSupply.Address"
          label={t(`newClient:sharedDetails.street`)}
          defaultValue={defaultValues?.newGasSupply?.Address}
          required={true}
        />
        <TextInput
          register={register}
          errors={errors}
          name="newGasSupply.AddressNo"
          label={t(`newClient:sharedDetails.streetNo`)}
          defaultValue={defaultValues?.newGasSupply?.AddressNo}
          required={true}
        />
        <PostalCodeInput
          register={register}
          errors={errors}
          postalCodeName="newGasSupply.PostalCode"
          postalCodeValue={defaultValues?.newGasSupply?.PostalCode}
          postalCodeIdName="newGasSupply.PostalCodeId"
          postalCodeIdValue={defaultValues?.newGasSupply?.PostalCodeId}
          onChangeHandler={handleChange}
          required={true}
        />
      </article>
    </>
  );
};

export default NewGasSupply;
