import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import useEnum from 'data/useEnum';
import accountCategories from 'utils/product/enums/accountCategories';
import { ControlledSelect } from 'components/Selects/ControlledSelect';
import StandardSelectOption from 'components/Selects/StandardSelectOption';
import { HiddenFormInput } from 'components/Inputs/HiddenFormInput';

import styles from './CompanyLegalDetails.module.scss';
import { useEffect } from 'react';

const CompanyLegalDetails = ({ defaultValues = {}, onUpdate }) => {
  const {
    formState: { errors },
    setValue,
    register,
  } = useFormContext();
  const { t } = useTranslation(['newClient', 'translation']);
  const { LegalOrgs: legalOrgs = [], loading: legalOrgsLoading } =
    useEnum('LegalOrgs');
  const { Legalforms: legalForms = [], loading: legalFormsLoading } =
    useEnum('Legalforms');
  const handleLegalFormChange = (e) => {
    const legalFormId = e.target.value;
    setValue(`legalForm`, legalFormId);
    onUpdate({
      legalForm: legalFormId,
    });
  };
  const handleLegalOrgChange = (e) => {
    const legalOrgId = e.target.value;
    setValue(`legalOrg`, legalOrgId);
    onUpdate({
      legalOrg: legalOrgId,
    });
  };

  useEffect(() => {
    if (defaultValues.customerCategory === `${accountCategories.private}`) {
      setValue(
        'legalForm',
        legalForms.find((x) => x.IsDefault === true)?.LegalformId ?? ''
      );
    }
  }, [legalForms, defaultValues.customerCategory, setValue]);

  return (
    <>
      {defaultValues.customerCategory !== `${accountCategories.private}` &&
        get(defaultValues, `gasProgram.meterNo`, '').indexOf(10) === 0 && (
          <article className={styles.container}>
            <h3 className={styles.header}>
              {t(`newClient:businessDetails.header`)}
            </h3>

            <ControlledSelect
              label={t(`newClient:companyLegal.legalForm`)}
              value={get(defaultValues, `legalForm`)}
              name={`legalForm`}
              errors={errors}
              onChange={handleLegalFormChange}
              defaultValue={get(defaultValues, `legalForm`)}
              autoWidth={true}
              MenuProps={{
                classes: { list: styles.selectMenu },
              }}
              loading={legalFormsLoading}
            >
              <StandardSelectOption
                key="default"
                value=""
                style={{ display: 'none' }}
              >
                -
              </StandardSelectOption>
              {legalForms.map((item) => {
                return (
                  <StandardSelectOption
                    key={item.LegalformId}
                    value={item.LegalformId}
                  >
                    {item.LegalformName}
                  </StandardSelectOption>
                );
              })}
            </ControlledSelect>

            <ControlledSelect
              label={t(`newClient:companyLegal.legalOrg`)}
              value={get(defaultValues, `legalOrg`)}
              name={`legalOrg`}
              errors={errors}
              onChange={handleLegalOrgChange}
              defaultValue={get(defaultValues, `legalOrg`)}
              autoWidth={true}
              MenuProps={{
                classes: { list: styles.selectMenu },
              }}
              loading={legalOrgsLoading}
            >
              <StandardSelectOption
                key="default"
                value=""
                style={{ display: 'none' }}
              >
                -
              </StandardSelectOption>
              {legalOrgs.map((item) => {
                return (
                  <StandardSelectOption
                    key={item.LegalOrgId}
                    value={item.LegalOrgId}
                  >
                    {item.LegalOrgName}
                  </StandardSelectOption>
                );
              })}
            </ControlledSelect>
          </article>
        )}
      {defaultValues.customerCategory === `${accountCategories.private}` && (
        <HiddenFormInput
          register={register}
          name={'legalForm'}
          defaultValue={
            legalForms.find((x) => x.IsDefault === true)?.LegalformId ?? ''
          }
        />
      )}
    </>
  );
};

export default CompanyLegalDetails;
