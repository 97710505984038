import React from 'react';
import { getDayName, formatDate, parseDate } from 'utils/dateUtils';

import { isEmptyString } from 'utils/stringUtils';
import NotificationWidget from 'components/Notifications/NotificationWidget';

import styles from './NotificationsGroup.module.scss';

const NotificationsGroup = ({ groupKey, group, handleClick }) => {
  const date = isEmptyString(groupKey) ? null : parseDate(groupKey);
  const dateString = date ? `${getDayName(date)}, ${formatDate(groupKey)}` : '';

  const notifications = group;

  return (
    <article key={groupKey} className={styles['notification-group']}>
      <h4>{dateString}</h4>
      {notifications?.map((notification) => {
        return (
          <div
            className={styles['widget-group']}
            key={
              notification?.NotificationsHistoryId || notification?.LoyaltyId
            }
          >
            {notification?.IsRead === false && <div className={styles.vl} />}

            <NotificationWidget
              notification={notification}
              handleClick={handleClick}
            />
          </div>
        );
      })}
    </article>
  );
};

export default NotificationsGroup;
