import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { TextButton, PrimaryButtonLoader } from 'components/Buttons/Buttons';
import { ReactComponent as ArrowDown } from 'assets/icons/ArrowDownBlueIcon.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/ArrowUpBlueIcon.svg';
import { RedeemPointsLabel, VibeLabel } from 'components/Loyalty/LoyaltyLabels';
import LabelsWrapper from 'components/LabelsWrapper/LabelsWrapper';
import CmsHtmlField from 'components/CmsHtmlField/CmsHtmlField';

import styles from './OfferElements.module.scss';

const ToggleOfferButton = ({ clickHandler }) => {
  const { t } = useTranslation(['offers']);
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={styles.buttonContainer}>
      <TextButton
        className={styles.button}
        onClick={() => {
          setExpanded(!expanded);
          clickHandler();
        }}
      >
        {t(`offers:offerBottomSection.read-more`)}
        {expanded === false ? (
          <ArrowDown className={styles.icon} />
        ) : (
          <ArrowUp className={styles.icon} />
        )}
      </TextButton>
    </div>
  );
};

const OfferTitle = ({ title, headerClass }) => {
  return <h3 className={`${styles.heading} ${headerClass}`}>{title}</h3>;
};

const OfferDescription = ({ description }) => {
  return <CmsHtmlField className={styles.fullParagraph} value={description} />;
};

const ExternalLinkButton = ({ clickHandler, text, url, ...rest }) => {
  return (
    <a
      className={styles.externalCtaButton}
      href={url}
      target="_blank"
      rel="noreferrer"
      onClick={clickHandler}
      {...rest}
    >
      {text}
    </a>
  );
};

const InternalLinkButton = ({ clickHandler, text, url, ...rest }) => {
  return (
    <Link
      className={styles.internalCtaButton}
      to={new URL(url).pathname}
      onClick={clickHandler}
      {...rest}
    >
      {text}
    </Link>
  );
};

const CustomButton = ({ clickHandler, text, ...rest }) => {
  return (
    <PrimaryButtonLoader
      className={styles.customCtaButton}
      onClick={clickHandler}
      loaderClass={styles.ctaButtonLoader}
      {...rest}
    >
      {text}
    </PrimaryButtonLoader>
  );
};

const ExternalLink = ({ text, url, linkClass, ...rest }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className={`${styles.link} ${linkClass}`}
      {...rest}
    >
      {text}
    </a>
  );
};

const OfferLabels = ({ vibe, redeemPoints }) => {
  return (
    <LabelsWrapper>
      <VibeLabel vibe={vibe} />
      <RedeemPointsLabel
        points={redeemPoints}
        labelClass={vibe ? undefined : styles['pointsLabel--single']}
      />
    </LabelsWrapper>
  );
};

export {
  ToggleOfferButton,
  OfferTitle,
  OfferDescription,
  ExternalLinkButton,
  InternalLinkButton,
  CustomButton,
  ExternalLink,
  OfferLabels,
};
